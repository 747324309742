import React, { useState, useEffect } from "react";

import { ID_PF, ID_PFAE, ID_PM } from "../../../../../utils/constantes";
import RadioInput from "../../../../basics/radioButton";

import ObligadoSolidarioPFAE_PM from "./ObligadoSolidarioPFAE_PM";
import ObligadoSolidarioPF_PM from "./ObligadoSolidarioPF_PM";
import ObligadoSolidarioPM_PM from "./ObligadoSolidarioPM_PM";

const ObligadoSolidarioPM = ({
  modeloPm,
  setModeloPm,
  setObligadosSolidariosInformacionBasica,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  axiosData,
  mesesDomicilo,
  aniosDomicilio,
  anios,
  setLoading,
  NUM_OS,
  seccionActual,
  datosOs,
  setDatosOs,
  setUltimoPasoSolicitud,
}) => {
  const [idTipoPersonaOS, setIdTipoPersonaOS] = useState();

  const personTypes = [
    { title: "Persona física", rfc: 13, id: ID_PF },
    {
      title: "Persona física con actividad empresarial",
      rfc: 13,
      id: ID_PFAE,
    },
    { title: "Persona moral", rfc: 12, id: ID_PM },
  ];

  useEffect(() => {
    setIdTipoPersonaOS(datosOs.idTipoPersonaOs);
  }, []);

  return (
    <>
      <div className="container">
        <h2 className="text-center">Obligado solidario {NUM_OS}</h2>
        <p className="p--texto_justify">En esta sección deberás agregar la información de la persona que será tu aval para el crédito. Esta persona debe de ser diferente al solicitante, y en caso de que el solicitante no tenga un bien inmueble que respalde el crédito, el obligado solidario deberá presentarlo</p>
        <h3>Tipo de persona</h3>
        <div className="d-flex justify-content-center gap-3 my-5">
          {personTypes.map((person, index) => {
            return (
              <div className="d-flex gap-2" key={index}>
                <RadioInput
                  style={{ padding: "0" }}
                  checked={idTipoPersonaOS === person.id}
                  onChange={() => {
                    setIdTipoPersonaOS(person.id);
                  }}
                />
                <label className="text_lightGray">{person.title}</label>
              </div>
            );
          })}
        </div>
        {idTipoPersonaOS === ID_PF && (
          <ObligadoSolidarioPF_PM
            modeloPm={modeloPm}
            axiosData={axiosData}
            aniosDomicilio={aniosDomicilio}
            mesesDomicilo={mesesDomicilo}
            NUM_OS={NUM_OS}
            idTipoPersonaOS={idTipoPersonaOS}
            setObligadosSolidariosInformacionBasica={
              setObligadosSolidariosInformacionBasica
            }
            setStyleHr={setStyleHr}
            handleSeccionPyme={handleSeccionPyme}
            handleRegresarSeccionPyme={handleRegresarSeccionPyme}
            setModeloPm={setModeloPm}
            setLoading={setLoading}
            seccionActual={seccionActual}
            datosOs={datosOs}
            setDatosOs={setDatosOs}
            setUltimoPasoSolicitud={setUltimoPasoSolicitud}
          />
        )}
        {idTipoPersonaOS === ID_PFAE && (
          <ObligadoSolidarioPFAE_PM
            modeloPm={modeloPm}
            axiosData={axiosData}
            aniosDomicilio={aniosDomicilio}
            mesesDomicilo={mesesDomicilo}
            anios={anios}
            NUM_OS={NUM_OS}
            idTipoPersonaOS={idTipoPersonaOS}
            setObligadosSolidariosInformacionBasica={
              setObligadosSolidariosInformacionBasica
            }
            setStyleHr={setStyleHr}
            handleSeccionPyme={handleSeccionPyme}
            handleRegresarSeccionPyme={handleRegresarSeccionPyme}
            setModeloPm={setModeloPm}
            setLoading={setLoading}
            seccionActual={seccionActual}
            datosOs={datosOs}
            setDatosOs={setDatosOs}
            setUltimoPasoSolicitud={setUltimoPasoSolicitud}
          />
        )}
        {idTipoPersonaOS === ID_PM && (
          <ObligadoSolidarioPM_PM
            modeloPm={modeloPm}
            axiosData={axiosData}
            anios={anios}
            NUM_OS={NUM_OS}
            idTipoPersonaOS={idTipoPersonaOS}
            setStyleHr={setStyleHr}
            handleSeccionPyme={handleSeccionPyme}
            handleRegresarSeccionPyme={handleRegresarSeccionPyme}
            setObligadosSolidariosInformacionBasica={
              setObligadosSolidariosInformacionBasica
            }
            setLoading={setLoading}
            seccionActual={seccionActual}
            datosOs={datosOs}
            setDatosOs={setDatosOs}
            setUltimoPasoSolicitud={setUltimoPasoSolicitud}
          />
        )}
      </div>
    </>
  );
};

export default ObligadoSolidarioPM;
