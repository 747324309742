import React from "react";
import Select from "react-select";
import ErrorMessage from "../../helpers/ErrorMessage/ErrorMessage";
import {
  selectStyles,
  selectStylesDouble,
} from "../../../styles/select-styles";
import "./SelectWithError.css";
import { isEmpty } from "../../../functions/fieldFunctions";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const SelectWithError = ({
  label = "",
  placeholder = "",
  name = "",
  value = "",
  onChange = () => {},
  onBlur = () => {},
  options,
  isDisabled = false,
  menuPlacement = "bottom",
  showErrorMessage = false,
  errorMessage = "",
  isDouble = false,
  width = "425px",
  isResumenCrediticio = false,
  isCotizador = false,
  tooltip = "",
}) => {
  return (
    <div className="select__container" style={{ width }}>
      <div className="d-flex gap-2 w-100 align-items-center">
        <div
          className={
            isResumenCrediticio
              ? "selectInputContTemporalResumenCreiditicio"
              : "selectInputContTemporal"
          }
        >
          {
            value?.value >= 0 || value?.value !== ""
                ? <span className="select-label-no-null">{label} </span>
                : <span className="select-label">{label}</span>
          }
          <Select
            isSearchable={false}
            placeholder={placeholder}
            styles={isDouble ? selectStylesDouble : selectStyles}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            isDisabled={isDisabled}
            menuPlacement={menuPlacement}
            error={showErrorMessage}
          />
        </div>
        {!isEmpty(tooltip) && (
            <div className={
              isCotizador
                ? "input__tooltip_cotizador top"
                : "input__tooltip top"
             }>
              <AiOutlineQuestionCircle className={
                  isCotizador
                    ? "input__tooltip_cotizador"
                    : "input__tooltip"
                } />
              <span className="tiptext">{tooltip}</span>
            </div>
          )}
        </div>
      {showErrorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default SelectWithError;
