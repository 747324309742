import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  validateValue,
  textToUpperCase,
  validarTextoConPunto,
  validarTextoConEnies,
} from "../../../../functions/fieldFunctions";
import RadioInput from "../../../basics/radioButton";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import InputWithErrorNumeroCliente from "../../../basics/InputWithError/InputWithErrorNumeroCliente";
import { validarRFCTipoPersona } from "../../../../utils/validaciones";
import {
  ID_PM,
  ID_PFAE,
  PANTALLA_INFORMACION_EMPRESA,
} from "../../../../utils/constantes";
import {
  sessionStorageAutoServicio,
  sessionStorageContinuarSolicitud,
  sessionStorageDatosOperacion,
  localStorageToken,
  localStorageRFC,
} from "../../../../config/Variables";
import { PrimaryButton, DisabledButton } from "../../../basics/Buttons/Buttons";
import Popup from "../../../tools/Popup";

import {
  api_obtener_cliente_banorte,
  api_obtener_cliente_campania,
  api_obtener_no_cliente_banorte,
  api_validar_cliente_campania,
} from "../../../../api/api_servicios_rest_crediactivo";

import { generarToken } from "../../../../utils/tokenAutoservicio";
import {
  sweetAlertInfo,
  sweetAlertWarning,
} from "../../../campos/components/alertas/alertas";

const InformacionGeneral = ({
  setSteps,
  setOfertaCampanias,
  setLoadingInicio,
  setRfc,
  setCurp,
  actividad,
  setActividadNegocio,
  setPopupRfcOcupado,
  setPopupRfcData,
  popupRfcOcupado,
  popupRfcData,
  setIdTipoPersona,
  setEdadCliente,
  setGenero,
  setFechaNacimientoStr,
  setEmail,
}) => {
  const [codigoCampania, setCodigoCampania] = useState("");
  const [clienteBanorteCampania, setClienteBanorteCampania] = useState(false);

  const formikInf = useFormik({
    initialValues: initialValuesInf(clienteBanorteCampania),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchemaInf()),
    onSubmit: (formData, actions) => {
      setLoadingInicio(true);
      actions.setSubmitting(false);
      verificarDatosCliente(formData);
    },
  });

  const verificarDatosCliente = async (data) => {
    if (formikInf.values.clienteBanorte !== false) {
      if (clienteBanorteCampania) {
        await obtenerClienteBanorteCampania(data);
      } else {
        await obtenerClienteBanorte(data);
      }
    } else {
      await obtenerNoClienteBanorte(data);
    }

    sessionStorage.setItem(sessionStorageContinuarSolicitud, false);
  };

  const obtenerNoClienteBanorte = async (dataCliente) => {
    try {
      const response = await api_obtener_no_cliente_banorte(dataCliente);

      if (response.data.success) {
        if (codigoCampania === "1") {
          setSteps(4);
        }
        if (response.data.informacionClienteCampania) {
          setOfertaCampanias(
            response.data.informacionClienteCampania.datosOfertaCampania
          );
          setSteps(4);
          setLoadingInicio(false);
          return;
        } else {
          let data = response.data.respuestaDatosTemporales;

          if (data.success) {
            sessionStorage.setItem(
              sessionStorageAutoServicio,
              data.idDatosTemporalesAutoservicio
            );

            localStorage.setItem(
              localStorageToken,
              response.data.tokenPymeAutoservicio
            );

            localStorage.setItem(localStorageRFC, dataCliente.rfc);

            if (data.curp) {
              setCurp(data.curp);
            }

            if (data.idActividad) {
              let actividaObj = actividad.filter(
                (e) => e.id === data.idActividad
              );
              if (actividaObj) {
                setActividadNegocio({
                  ...actividaObj[0],
                  value: actividaObj[0].detalleClave,
                  label: actividaObj[0].detalleClave,
                });
              }
            }

            if (data.edad) {
              setEdadCliente(data.edad);
            }

            if (data.fechaNacimiento) {
              setFechaNacimientoStr(data.fechaNacimiento);
            }

            if (data.correoElectronico) {
              setEmail(data.correoElectronico);
            }
            setLoadingInicio(false);
            setSteps(PANTALLA_INFORMACION_EMPRESA);
          } else {
            setLoadingInicio(false);
            sweetAlertWarning(data?.mensaje);
          }
        }
      } else {
        setLoadingInicio(false);
        sweetAlertInfo(response?.data?.mensaje);
      }
    } catch (error) {
      console.error(error);
      setLoadingInicio(false);
    }
  };

  const obtenerClienteBanorte = async (dataCliente) => {
    try {
      const response = await api_obtener_cliente_banorte(dataCliente);
      const data = response.data;
      if (data.success) {
        if (data.tipoDeSolicitante === ID_PFAE) {
          if (data.curp) {
            setCurp(data.curp);
          }

          if (data.edad) {
            setEdadCliente(data.edad);
          }

          if (data.fechaNacimiento) {
            setFechaNacimientoStr(data.fechaNacimiento);
          }
        }

        if (data.idActividad) {
          const actividaObj = actividad.filter(
            (e) => e.id === data.idActividad
          );
          if (actividaObj) {
            setActividadNegocio({
              ...actividaObj[0],
              value: actividaObj[0].detalleClave,
              label: actividaObj[0].detalleClave,
            });
          }
        }

        if (data.correoElectronico) {
          setEmail(data.correoElectronico);
        }

        sessionStorage.setItem(
          sessionStorageAutoServicio,
          data.idDatosTemporalesAutoservicio
        );

        localStorage.setItem(localStorageToken, data.tokenPymeAutoservicio);

        localStorage.setItem(localStorageRFC, dataCliente.rfc);

        setLoadingInicio(false);
        setSteps(PANTALLA_INFORMACION_EMPRESA);
      } else {
        setLoadingInicio(false);
        sweetAlertInfo(data?.mensaje);
      }
    } catch (error) {
      console.error(error);
      setLoadingInicio(false);
    }
  };

  const obtenerClienteBanorteCampania = async (data) => {
    await obtenerDatosCampania(data);
    try {
      const response = await api_validar_cliente_campania(data);
      if (response.data.success) {
        if (codigoCampania === "1") {
          // FINAL PAGE
          // setSteps(PANTALLA_VALIDACION_NUMERO_CORREO);
          return;
        }
        if (response.data.informacionClienteCampania) {
          setOfertaCampanias(
            response.data.informacionClienteCampania.datosOfertaCampania
          );
          return;
        } else {
          // setSteps(PANTALLA_INFORMACION_EMPRESA);
        }
      } else {
        setPopupRfcOcupado(true);
        setPopupRfcData(response.data);
      }
      setLoadingInicio(false);
    } catch (error) {
      console.error(error);
      setLoadingInicio(false);
    }
  };

  const obtenerDatosCampania = async (data) => {
    try {
      const response = await api_obtener_cliente_campania(data);
      if (response.data.success) {
        let dataCliente = response.data;
        if (dataCliente.tipoDeSolicitante === ID_PFAE) {
          if (dataCliente.curp) {
            setCurp(dataCliente.curp);
          }

          if (data.edad) {
            setEdadCliente(data.edad);
          }
          if (data.fechaNacimiento) {
            setFechaNacimientoStr(data.fechaNacimiento);
          }
          if (data.genero) {
            setGenero(data.genero);
          }
        }

        if (data.idActividad) {
          let actividaObj = actividad.filter((e) => e.id === data.idActividad);
          if (actividaObj) {
            setActividadNegocio({
              ...actividaObj[0],
              value: actividaObj[0].detalleClave,
              label: actividaObj[0].detalleClave,
            });
          }
        }

        sessionStorage.setItem(
          sessionStorageAutoServicio,
          dataCliente.idDatosTemporalesAutoservicio
        );
      } else {
        sweetAlertInfo(response?.data?.mensaje);
        setLoadingInicio(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingInicio(false);
    }
  };

  useEffect(() => {
    if (
      formikInf.values.tipoDeSolicitante === "" ||
      formikInf.values.tipoDeSolicitante !== null
    ) {
      formikInf.setFieldValue("tipoDeSolicitante", ID_PFAE);
      setIdTipoPersona(ID_PFAE);
    }
  }, []);

  return (
    <>
      <Popup show={popupRfcOcupado}>
        <div
          className="bgc-gray-opacity"
          style={{
            height: "100vh",
            width: "100%",
            display: "grid",
            placeContent: "center",
          }}
        >
          <div
            className="my-shadow radius bgc-white black"
            style={{
              padding: "24px",
              width: "80vw",
              maxWidth: "1300px",
              minWidth: "700px",
              zIndex: "2",
            }}
          >
            <h1>Información de solicitud</h1>
            <hr />
            <h3>Ya existe una solicitud en trámite para este cliente</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">UQR</th>
                  <th scope="col">Estatus</th>
                  <th scope="col">Fecha</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {
                      popupRfcData?.informacionDeSolicitudEnTramite
                        ?.registradaPor
                    }
                  </td>
                  <td>
                    {
                      popupRfcData?.informacionDeSolicitudEnTramite
                        ?.statusDeSolicitud
                    }
                  </td>
                  <td>
                    {
                      popupRfcData?.informacionDeSolicitudEnTramite
                        ?.fechaActualizacion
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          onClick={() => setPopupRfcOcupado(false)}
          style={{
            top: "0",
            position: "absolute",
            height: "100vh",
            width: "100vw",
            zIndex: "1",
          }}
        />
      </Popup>

      <div className="bgc-white bgc-white-div" style={{ paddingTop: "60px" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formikInf.handleSubmit();
          }}
          className="form-width"
        >
          <h1 className="text-center text-h1">Información general</h1>
          <p className="text-center f-s-2 text_gray">¿Eres cliente Banorte?</p>
          <div className="div-cliente-banorte checkbox--container">
            <div className="checkbox-item--container">
              <RadioInput
                style={{ padding: "0" }}
                required={true}
                checked={formikInf.values.clienteBanorte}
                onChange={() => {
                  formikInf.setFieldValue("clienteBanorte", true);
                }}
              />
              <label className="text_lightGray">Si</label>
            </div>
            <div className="checkbox-item--container">
              <RadioInput
                style={{ padding: "0" }}
                required={true}
                checked={formikInf.values.clienteBanorte ? false : true}
                onClick={() => {
                  formikInf.setFieldValue("clienteBanorte", false);
                }}
              />
              <label className="text_lightGray">No</label>
            </div>
          </div>
          <div className="div-cliente-banorte regimen--container">
            <p className="text-center f-s-2 text_gray">
              <span className="span-regimen-fiscal">Régimen fiscal</span>
            </p>
            <div
              className="div-cliente-banorte "
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                margin: "2rem auto",
                gap: "3rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <RadioInput
                  style={{ padding: "0" }}
                  required={true}
                  checked={
                    formikInf.values.tipoDeSolicitante == ID_PM ? true : false
                  }
                  onClick={() => {
                    formikInf.setFieldValue("tipoDeSolicitante", ID_PM);
                    setIdTipoPersona(ID_PM);
                  }}
                />
                <label className="text_lightGray">Persona Moral</label>
              </div>
              <div
                style={{
                  display: "flex",
                  maxWidth: "110px",
                  gap: "10px",
                }}
              >
                <RadioInput
                  style={{ padding: "0" }}
                  required={true}
                  checked={
                    formikInf.values.tipoDeSolicitante == ID_PFAE ? true : false
                  }
                  onClick={() => {
                    formikInf.setFieldValue("tipoDeSolicitante", ID_PFAE);
                    setIdTipoPersona(ID_PFAE);
                  }}
                />
                <label className="text_lightGray">
                  Persona Física con Actividad Empresarial
                </label>
              </div>
            </div>
          </div>
          <div className="form--container">
            {formikInf.values.tipoDeSolicitante == ID_PFAE ? (
              <>
                <InputWithError
                  value={formikInf.values.nombre}
                  onChange={(e) => {
                    let newErrors = formikInf.errors;
                    delete newErrors?.nombre;
                    formikInf.setErrors(newErrors);

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formikInf.setFieldValue("nombre", e.target.value);
                    }
                  }}
                  label="Nombre *"
                  InputProps={{ maxLength: 30 }}
                  showErrorMessage={formikInf.errors.nombre}
                  errorMessage={formikInf.errors.nombre}
                />
                <InputWithError
                  label="Apellido paterno *"
                  value={formikInf.values.apellidoPaterno}
                  onChange={(e) => {
                    let newErrors = formikInf.errors;
                    delete newErrors?.apellidoPaterno;
                    formikInf.setErrors(newErrors);

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formikInf.setFieldValue(
                        "apellidoPaterno",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 20 }}
                  showErrorMessage={formikInf.errors.apellidoPaterno}
                  errorMessage={formikInf.errors.apellidoPaterno}
                />
                <InputWithError
                  label="Apellido materno"
                  value={formikInf.values.apellidoMaterno}
                  onChange={(e) => {
                    let newErrors = formikInf.errors;
                    delete newErrors?.apellidoMaterno;
                    formikInf.setErrors(newErrors);

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formikInf.setFieldValue(
                        "apellidoMaterno",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 20 }}
                  showErrorMessage={formikInf.errors.apellidoMaterno}
                  errorMessage={formikInf.errors.apellidoMaterno}
                />
                <InputWithError
                  label="RFC *"
                  value={formikInf.values.rfc}
                  onChange={(e) => {
                    let newErrors = formikInf.errors;
                    delete newErrors?.rfc;
                    formikInf.setErrors(newErrors);

                    if (validateValue(e.target.value, "alphanumeric")) {
                      const newValue = textToUpperCase(e.target.value);
                      formikInf.setFieldValue("rfc", newValue);
                      setRfc(newValue);
                    }
                  }}
                  InputProps={{ maxLength: 13 }}
                  showErrorMessage={formikInf.errors.rfc}
                  errorMessage={formikInf.errors.rfc}
                />
              </>
            ) : (
              <>
                <InputWithError
                  label="Razón social *"
                  value={formikInf.values.razonSocial}
                  onChange={(e) => {
                    let newErrors = formikInf.errors;
                    delete newErrors?.razonSocial;
                    formikInf.setErrors(newErrors);

                    if (validarTextoConPunto(e.target.value, "alphanumeric")) {
                      formikInf.setFieldValue("razonSocial", e.target.value);
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                  showErrorMessage={formikInf.errors.razonSocial}
                  errorMessage={formikInf.errors.razonSocial}
                />
                <InputWithError
                  label="RFC *"
                  value={formikInf.values.rfc}
                  onChange={(e) => {
                    let newErrors = formikInf.errors;
                    delete newErrors?.rfc;
                    formikInf.setErrors(newErrors);

                    if (validateValue(e.target.value, "alphanumeric")) {
                      const newValue = textToUpperCase(e.target.value);
                      formikInf.setFieldValue("rfc", newValue);
                      setRfc(newValue);
                    }
                  }}
                  InputProps={{ maxLength: 12 }}
                  showErrorMessage={formikInf.errors.rfc}
                  errorMessage={formikInf.errors.rfc}
                />
              </>
            )}
            {formikInf.values.clienteBanorte === true && (
              <InputWithErrorNumeroCliente
                label="Número de cliente Banorte *"
                value={formikInf.values.numeroCliente}
                onChange={(e) => {
                  let newErrors = formikInf.errors;
                  delete newErrors?.numeroCliente;
                  formikInf.setErrors(newErrors);

                  if (validateValue(e.target.value, "number")) {
                    formikInf.setFieldValue("numeroCliente", e.target.value);
                  }
                }}
                tooltip="Este dato lo puedes encontrar dentro de tu estado de cuenta Banorte, o dentro de tu banca electrónica"
                showErrorMessage={formikInf.errors?.numeroCliente}
                errorMessage={formikInf.errors?.numeroCliente}
              />
            )}
          </div>
          <div className="button--container">
            {!formikInf.isSubmitting ? (
              <PrimaryButton type="submit">Siguiente</PrimaryButton>
            ) : (
              <DisabledButton>Siguiente</DisabledButton>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default InformacionGeneral;

export function initialValuesInf(clienteBanorteCampania) {
  return {
    clienteBanorte: false,
    showChequesBanorte: false,
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    rfc: "",
    razonSocial: "",
    numeroCliente: "",
    codigoCampania: "",
    tipoDeSolicitante: ID_PFAE,
    tipoDeParticipanteId: 1,
    isCodigoCampania: clienteBanorteCampania,
    idDatosOperacion: sessionStorage.getItem(sessionStorageDatosOperacion),
    token: generarToken(),
  };
}

export function validationSchemaInf() {
  return {
    clienteBanorte: Yup.boolean(),
    isCodigoCampania: Yup.boolean(),
    showChequeBanorte: Yup.boolean(),
    tipoDeSolicitante: Yup.string(),
    nombre: Yup.string().when(["tipoDeSolicitante"], {
      is: (tipoDeSolicitante) =>
        parseInt(tipoDeSolicitante) === parseInt(ID_PFAE),
      then: Yup.string()
        .required("Dato requerido.")
        .test("nombre", "Dato requerido.", (item, testContext) => {
          return testContext.parent.nombre !== undefined
            ? testContext.parent.nombre.trim().length > 0
            : false;
        }),
    }),
    apellidoPaterno: Yup.string().when(["tipoDeSolicitante"], {
      is: (tipoDeSolicitante) => parseInt(tipoDeSolicitante) === ID_PFAE,
      then: Yup.string()
        .required("Dato requerido.")
        .test("apellidoPaterno", "Dato requerido.", (item, testContext) => {
          return testContext.parent.apellidoPaterno !== undefined
            ? testContext.parent.apellidoPaterno.trim().length > 0
            : false;
        }),
    }),
    rfc: Yup.string()
      .required("Dato requerido.")
      .test("rfc", "RFC inválido.", (item, testContext) => {
        return testContext.parent.rfc !== undefined
          ? testContext.parent.rfc.trim().length > 0
            ? validarRFCTipoPersona(
                testContext.parent.rfc.trim(),
                testContext.parent.tipoDeSolicitante
              )
            : false
          : false;
      }),
    razonSocial: Yup.string().when(["tipoDeSolicitante"], {
      is: (tipoDeSolicitante) => parseInt(tipoDeSolicitante) === ID_PM,
      then: Yup.string()
        .required("Dato requerido.")
        .test("razonSocial", "Dato requerido.", (item, testContext) => {
          return testContext.parent.razonSocial !== undefined
            ? testContext.parent.razonSocial.trim().length > 0
            : false;
        }),
    }),
    numeroCliente: Yup.string().when("clienteBanorte", {
      is: true,
      then: Yup.string().required("Dato requerido."),
    }),
    codigoCampania: Yup.string().when(["clienteBanorte", "isCodigoCampania"], {
      is: (clienteBanorte, isCodigoCampania) =>
        clienteBanorte === true && isCodigoCampania === true,
      then: Yup.string()
        .required("Dato requerido.")
        .test("codigoCampania", "Dato requerido.", (item, testContext) => {
          return testContext.parent.codigoCampania !== undefined
            ? testContext.parent.codigoCampania.trim().length > 0
            : false;
        }),
    }),
  };
}
