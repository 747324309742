import React from "react";
import { BiCopy } from "react-icons/bi";
import "./NavbarHistorialCrediticio.css";

const NavBarHistorialCrediticio = ({ folioSolicitud }) => {
  const copyToClipboard = (text) => {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  };

  return (
    <>
      <div className="container">
        <div className="mb-3 navbarhistorialcrediticio">
          <div className="w-100">
            <p className="mt-3 text-center navbar-titulo">
              Puedes regresar en cualquier momento para subir tus documentos
              usando tu número de celular
            </p>

            <div className="navbar-copiar-folio">
              <div className="border-red navbar--folio">
                <span style={{ fontWeight: "bold" }}>FOLIO: </span>
                <span className="color-red">{folioSolicitud}</span>
              </div>
              <div className="navbar--copy" hidden>
                <BiCopy />
                <span
                  className="pointer"
                  onClick={() => copyToClipboard(folioSolicitud)}
                >
                  Copiar folio a portapapeles
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBarHistorialCrediticio;
