import React, { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as UploadIcon } from "../../../../../icons/Subir.svg";
import CrossIcon from "../../../../../icons/crossIcon.png";
import Dropzone from "react-dropzone";
import { BiFileBlank } from "react-icons/bi";
import Swal from "sweetalert2";
import { api_uploadCargaDocumentos } from "../../../../../api/api_servicios_rest";
import { OS_CERO } from "../../../../../utils/constantes";
import {
  sweetAlertError,
  sweetAlertWarning,
} from "../../../../helpers/alertas/alertas";

const CargarDocumentoPrincipalAccionista = ({
  documentosPA,
  idSolicitud,
  setLoading,
  obtenerPestaniaSgteCargaDocumentos,
  idCliente,
}) => {
  const formik = useFormik({
    initialValues: initialValues(),
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      obtenerPestaniaSgteCargaDocumentos(idCliente, OS_CERO);
    },
  });

  const [loadingBar, setLoadingBar] = useState({
    loading: false,
    item: null,
  });

  const handleAddFiles = async (files, index, documento) => {
    setLoadingBar({ loading: true, item: index });
    setLoading(true);
    let newArray = [];
    for (let x = 0; x < files.length; x++) {
      URL.createObjectURL(files[0]);
      newArray.push(URL.createObjectURL(files[x]));
    }
    let formdata = new FormData();
    formdata.append("archivo", files[0], files[0].name);
    formdata.append("tipoDocumento", documento.nombreMapeo);
    formdata.append("idSolicitud", idSolicitud);
    formdata.append("idCliente", idCliente);
    formdata.append("tipoDeParticipante", "principalAccionista");

    let cadena = documento.nombreMapeo + ".idDocumento";

    await uploadDocumento(formdata, cadena, index, files[0], documento.id);
  };

  const uploadDocumento = async (
    formDataDocumentos,
    cadena,
    pos,
    file,
    idDocumento
  ) => {
    try {
      const response = await api_uploadCargaDocumentos(formDataDocumentos);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          formik.setFieldValue(cadena, idDocumento);
          formik.setFieldValue(`files.${pos}`, file);
        } else {
          sweetAlertWarning(data?.mensaje);
        }
      } else {
        sweetAlertError("Ocurrió un error al cargar documento");
      }
      setLoading(false);
    } catch (error) {
      console.error("error", error);
      setLoading(false);
    }
  };

  return (
    <>
      <form name="form-solicitante" onSubmit={formik.handleSubmit}>
        <h1 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3 text-center">
          Documentos PA
        </h1>
        <div
          style={{
            display: "grid",
            gap: "1rem",
          }}
        >
          {documentosPA &&
            documentosPA.map((item, i) => {
              return (
                <Fragment key={item.nombre}>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "700px",
                      margin: "auto",
                      display: "grid",
                      gap: ".5rem",
                    }}
                  >
                    <span
                      style={{
                        margin: "0 0 0 5%",
                      }}
                    >
                      {item?.nombre}
                    </span>
                    <Dropzone
                      key={`${i}${JSON.stringify(item)}`}
                      noKeyboard
                      onDrop={(acceptedFiles) => {
                        handleAddFiles(acceptedFiles, i, item);
                      }}
                    >
                      {(props) => (
                        <div {...props.getRootProps()}>
                          <div
                            className={`${
                              formik.values?.files[i]
                                ? "documentUploaded"
                                : "documentEmpty"
                            } cargaInput ${
                              loadingBar?.loading &&
                              loadingBar?.item === i &&
                              "loader-carga"
                            }`}
                          >
                            <div>
                              {formik?.values?.files[i] ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                  }}
                                >
                                  <BiFileBlank width={40} height={40} />
                                  {formik?.values?.files[i]?.name}
                                </div>
                              ) : (
                                <>
                                  <UploadIcon /> Suba o arrastre el documento
                                </>
                              )}
                            </div>
                            {formik.values?.files[i] && (
                              <>
                                <img
                                  style={{
                                    objectFit: "cover",
                                    width: "20px",
                                    width: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "relative",
                                    zIndex: "200",
                                  }}
                                  src={CrossIcon}
                                  onClick={() => {
                                    formik.setFieldValue(`files.${i}`, null);
                                  }}
                                ></img>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {item.nombreMapeo === "comprobanteDeDomicilio" && (
                      <div className="col-8">
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "row",
                            justifyContent: "flex-end",
                            color: "red",
                          }}
                        >
                          {formik.errors?.comprobanteDeDomicilio
                            ?.idDocumento ? (
                            <p>
                              {
                                formik.errors?.comprobanteDeDomicilio
                                  ?.idDocumento
                              }
                            </p>
                          ) : null}
                        </div>
                      </div>
                    )}
                    {item.nombreMapeo === "buroInternoPortal" && (
                      <div className="col-8">
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "row",
                            justifyContent: "flex-end",
                            color: "red",
                          }}
                        >
                          {formik.errors?.buroInternoPortal?.idDocumento ? (
                            <p>
                              {formik.errors?.buroInternoPortal?.idDocumento}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            })}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            margin: "1rem 0",
          }}
        >
          <button className="button-primary">Enviar documentos</button>
        </div>
      </form>
    </>
  );
};

export default CargarDocumentoPrincipalAccionista;

function initialValues() {
  return {
    comprobanteDeDomicilio: {},
    files: [],
  };
}

function validationSchema() {
  return {
    comprobanteDeDomicilio: Yup.object().shape({
      idDocumento: Yup.number().required("Dato requerido."),
    }),
    buroInternoPortal: Yup.object().shape({
      idDocumento: Yup.number().required("Dato requerido."),
    }),
  };
}
