import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  PANTALLA_VALIDACION_NUMERO_CORREO,
  ANIOS_NEGOCIO_MENOR_2_ANIOS,
  ID_PFAE,
} from "../../../../utils/constantes";
import CheckBoxInput from "../../../basics/checkBox";
import {
  validateValue,
  textToUpperCase,
} from "../../../../functions/fieldFunctions";
import { api_actualizar_datos_cliente_autoservicio_empresa } from "../../../../api/api_servicios_rest_crediactivo";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../basics/SelectWithError/SelectWithError";
import { sessionStorageAutoServicio } from "../../../../config/Variables";
import { pathAvisoPrivacidadCrediactivo } from "../../../../config/Path";
import { sweetAlertError } from "../../../campos/components/alertas/alertas";

const InformacionEmpresa = ({
  setSteps,
  rfc,
  curp,
  actividadNegocio,
  setActividadNegocio,
  actividad,
  aniosNegocio,
  genero,
  edadCliente,
  fechaNacimientoStr,
  idTipoPersona,
  setLoadingInicio,
}) => {
  const [disabledCurp, setDisabledCurp] = useState(false);

  const formikEmp = useFormik({
    initialValues: initialValuesEmp(idTipoPersona, curp, rfc),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchemaEmp()),
    onSubmit: (formData) => {
      actualizarDatosTemporalesAutoServicioEmpresa(formData);
    },
  });

  const obtenerDatosByCurp = (curp) => {
    if (curp.length === 18) {
      let m = curp.match(/^\w{4}(\w{2})(\w{2})(\w{2})/);
      let anyoCliente = parseInt(m[1], 10) + 1900;
      if (anyoCliente < 1950) anyoCliente += 100;
      let mesCliente = parseInt(m[2], 10) - 1;
      let diaCliente = parseInt(m[3], 10);
      let cumpleanos = new Date(anyoCliente, mesCliente, diaCliente);
      let hoy = new Date();
      let edad = hoy.getFullYear() - cumpleanos.getFullYear();
      let mEdad = hoy.getMonth() - cumpleanos.getMonth();
      let generoCliente = curp[10] === "H" ? 1 : 2;
      mesCliente = mesCliente + 1;
      if (mEdad < 0 || (mEdad === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }

      formikEmp.setFieldValue("idGenero", generoCliente);
      formikEmp.setFieldValue(
        "fechaNacimientoStr",
        diaCliente + "-" + mesCliente + "-" + anyoCliente
      );
      formikEmp.setFieldValue("edad", edad);
    }
  };

  const actualizarDatosTemporalesAutoServicioEmpresa = async (formData) => {
    try {
      const response = await api_actualizar_datos_cliente_autoservicio_empresa(
        formData
      );

      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setSteps(PANTALLA_VALIDACION_NUMERO_CORREO);
          return;
        }
        sweetAlertError(data?.mensaje);
      } else {
        sweetAlertError("Error al actualizar datos del cliente");
      }
      setLoadingInicio(false);
    } catch (error) {
      console.error(error);
      setLoadingInicio(false);
    }
  };

  useEffect(() => {
    if (actividadNegocio) {
      formikEmp.setFieldValue("idActividad", actividadNegocio.id);
    }

    if (curp !== undefined && curp !== null && curp.trim().length > 0) {
      formikEmp.setFieldValue("curp", curp);
      formikEmp.setFieldValue("idGenero", genero);
      formikEmp.setFieldValue("fechaNacimientoStr", fechaNacimientoStr);
      formikEmp.setFieldValue("edad", edadCliente);
      setDisabledCurp(true);
    }
  }, []);

  return (
    <div
      className="bgc-white"
      style={{
        padding: "24px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
      }}
    >
      <form
        style={{
          width: "100%",
          display: "grid",
          placeItems: "center",
          gap: "1rem",
        }}
      >
        <h1 className="text-center" style={{ marginBottom: "24px" }}>
          Información de tu empresa
        </h1>
        {idTipoPersona === ID_PFAE && (
          <InputWithError
            value={formikEmp.values.curp}
            onChange={(e) => {
              let newErrors = formikEmp.errors;
              delete newErrors?.curp;
              delete newErrors?.rfc;
              formikEmp.setErrors(newErrors);

              if (validateValue(e.target.value, "alphanumeric")) {
                const newValue = textToUpperCase(e.target.value);
                formikEmp.setFieldValue("curp", newValue);
              }
            }}
            onBlur={(e) => {
              if (e.target.value.length === 18) {
                obtenerDatosByCurp(e.target.value);
              }
            }}
            InputProps={{ maxLength: 18 }}
            label="CURP *"
            showErrorMessage={formikEmp.errors.curp || formikEmp.errors.rfc}
            errorMessage={
              formikEmp.errors.curp !== undefined
                ? formikEmp.errors.curp
                : formikEmp.errors.rfc !== undefined
                ? formikEmp.errors.rfc
                : ""
            }
            disabled={disabledCurp}
          />
        )}

        <SelectWithError
          label="Actividad de tu negocio *"
          value={actividadNegocio}
          onChange={(e) => {
            let newErrors = formikEmp.errors;
            delete newErrors?.idActividad;
            formikEmp.setErrors(newErrors);

            formikEmp.setFieldValue("idActividad", e.id);
            setActividadNegocio(e);
          }}
          options={actividad.map((e) => {
            return {
              ...e,
              value: e.id,
              label: e.detalleClave,
            };
          })}
          showErrorMessage={formikEmp.errors.idActividad}
          errorMessage={formikEmp.errors.idActividad}
        />
        <SelectWithError
          label="Años de tu negocio *"
          value={{
            label: formikEmp?.values?.anioNegocioObj?.nombre,
            value: formikEmp?.values?.anioNegocioObj?.nombre,
          }}
          onChange={(e) => {
            let newErrors = formikEmp.errors;
            delete newErrors?.idAnioNegocio;
            formikEmp.setErrors(newErrors);

            formikEmp.setFieldValue("idAnioNegocio", e.id);
            formikEmp.setFieldValue("anioNegocioObj", e);
          }}
          options={aniosNegocio.map((e) => {
            return {
              ...e,
              value: e.id,
              label: e.nombre,
            };
          })}
          showErrorMessage={formikEmp.errors.idAnioNegocio}
          errorMessage={formikEmp.errors.idAnioNegocio}
        />
        <div
          style={{
            width: "100%",
            margin: "0rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckBoxInput
            checked={formikEmp.values?.privacyCheck}
            onChange={(e) => {
              formikEmp.setFieldValue("privacyCheck", e.target.checked);
            }}
            required={true}
            typeOfCheck="normal"
          />
          <span>Acepto el&nbsp;</span>
          <Link to={pathAvisoPrivacidadCrediactivo} target="_blank" download>
            <span style={{ color: "#EB0029" }}>aviso de privacidad</span>
          </Link>
        </div>
        <div
          style={{
            width: "100%",
            margin: "-1rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="text__center text_red">
            {formikEmp.errors?.privacyCheck}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            margin: "2rem 0",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            className="button-primary"
            onClick={(e) => {
              e.preventDefault();
              formikEmp.handleSubmit();
            }}
          >
            Siguiente
          </button>
        </div>
      </form>
    </div>
  );
};

export default InformacionEmpresa;

export function initialValuesEmp(idTipoPersona, curp, rfc) {
  let idDatosTemp = parseInt(
    sessionStorage.getItem(sessionStorageAutoServicio)
  );
  return {
    idDatosTemporalesAutoservicio: idDatosTemp,
    idTipoPersona: idTipoPersona,
    privacyCheck: false,
    idActividad: "",
    idTipoMotivoCredito: "",
    idAnioNegocio: "",
    curp: curp ? curp : "",
    edad: "",
    fechaNacimientoStr: "",
    idGenero: "",
    idTipoMotivoCreditoObj: [{ nombre: undefined }],
    anioNegocioObj: [{ nombre: undefined }],
    rfc: rfc ? rfc : "",
  };
}

export function validationSchemaEmp() {
  return {
    privacyCheck: Yup.boolean().oneOf([true], "Dato requerido."),
    idActividad: Yup.string().required("Dato requerido."),
    idAnioNegocio: Yup.string()
      .required("Dato requerido.")
      .test("idAnioNegocio", "Años de tu negocio no permitido.", (value) => {
        if (value !== undefined && value !== null && value !== "") {
          if (parseInt(value) !== ANIOS_NEGOCIO_MENOR_2_ANIOS) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }),
    edad: Yup.string(),
    curp: Yup.string().when(["idTipoPersona"], {
      is: (idTipoPersona) => parseInt(idTipoPersona) === ID_PFAE,
      then: Yup.string()
        .required("Dato requerido.")
        .min(18, "Por favor ingrese 18 caracteres.")
        .matches(
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          "El CURP es incorrecto."
        )
        .test(
          "curp",
          "La edad debe ser mayor a 25 y menor a 75 años.",
          (item, testContext) => {
            if (
              testContext.parent.edad !== undefined &&
              testContext.parent.edad !== null &&
              testContext.parent.edad !== ""
            ) {
              if (
                parseInt(testContext.parent.edad) <= 25 ||
                parseInt(testContext.parent.edad) > 75
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          }
        ),
    }),
    rfc: Yup.string().when(["idTipoPersona"], {
      is: (idTipoPersona) => parseInt(idTipoPersona) === ID_PFAE,
      then: Yup.string()
        .required("Dato requerido.")
        .test(
          "rfc",
          "Fecha de nacimiento diferente en RFC y CURP.",
          (item, testContext) => {
            if (
              testContext.parent.curp !== undefined &&
              testContext.parent.curp !== null &&
              testContext.parent.curp !== ""
            ) {
              let fechaCurp = testContext.parent.curp.substring(4, 10);
              let fechaRfc = testContext.parent.rfc.substring(4, 10);
              if (fechaCurp === fechaRfc) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        ),
    }),
  };
}
