import {
  ID_PF,
  ID_PFAE,
  ID_PM,
  KEY_PF,
  KEY_PFAE,
  KEY_PM,
  OS_CINCO,
  OS_CUATRO,
  OS_DOS,
  OS_TRES,
  OS_UNO,
} from "../../utils/constantes";

export const obtenerDatosOblidadoSolidario = (
  listObligadosSolidarios,
  cantidadOsSolicitudConyuge,
  setDatosOSUno,
  setDatosOSDos,
  setDatosOSTres,
  setDatosOSCuatro,
  setDatosOSCinco,
  setCantidadConyugeOS
) => {
  for (let i = 0; i < listObligadosSolidarios.length; i++) {
    let datosOs = [];
    let tipoPersonaOs = "";
    let conyuge = [];
    let estadoCivilId = "";
    switch (listObligadosSolidarios[i].tipoPersonaKey) {
      case KEY_PM:
        tipoPersonaOs = ID_PM;
        datosOs = listObligadosSolidarios[i].obligadoSolidarioPM;
        break;
      case KEY_PFAE:
        tipoPersonaOs = ID_PFAE;
        datosOs = listObligadosSolidarios[i].obligadoSolidario;
        conyuge = listObligadosSolidarios[i].obligadoSolidario.conyuge;
        estadoCivilId =
          listObligadosSolidarios[i].obligadoSolidario?.generales
            ?.estadoCivilId;
        break;
      case KEY_PF:
        tipoPersonaOs = ID_PF;
        datosOs = listObligadosSolidarios[i].obligadoSolidario;
        conyuge = listObligadosSolidarios[i].obligadoSolidario.conyuge;
        estadoCivilId =
          listObligadosSolidarios[i].obligadoSolidario?.generales
            ?.estadoCivilId;
        break;
    }

    switch (listObligadosSolidarios[i].numero) {
      case OS_UNO:
        setDatosOSUno({
          datosOS: datosOs,
          idTipoPersonaOs: tipoPersonaOs,
          conyuge: conyuge,
          idEstadoCivilOs: estadoCivilId,
        });
        break;
      case OS_DOS:
        setDatosOSDos({
          datosOS: datosOs,
          idTipoPersonaOs: tipoPersonaOs,
          conyuge: conyuge,
          idEstadoCivilOs: estadoCivilId,
        });
        break;
      case OS_TRES:
        setDatosOSTres({
          datosOS: datosOs,
          idTipoPersonaOs: tipoPersonaOs,
          conyuge: conyuge,
          idEstadoCivilOs: estadoCivilId,
        });
        break;
      case OS_CUATRO:
        setDatosOSCuatro({
          datosOS: datosOs,
          idTipoPersonaOs: tipoPersonaOs,
          conyuge: conyuge,
          idEstadoCivilOs: estadoCivilId,
        });
        break;
      case OS_CINCO:
        setDatosOSCinco({
          datosOS: datosOs,
          idTipoPersonaOs: tipoPersonaOs,
          conyuge: conyuge,
          idEstadoCivilOs: estadoCivilId,
        });
        break;
    }

    if (listObligadosSolidarios[i]?.tipoPersonaKey !== KEY_PM) {
      if (
        listObligadosSolidarios[i]?.obligadoSolidario?.conyuge !== null &&
        listObligadosSolidarios[i]?.obligadoSolidario?.conyuge?.generales
          ?.esObligadoSolidario
      ) {
        cantidadOsSolicitudConyuge = cantidadOsSolicitudConyuge + 1;
      }
    }
  }

  setCantidadConyugeOS(cantidadOsSolicitudConyuge);
};
