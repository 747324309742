import React from "react";
import FormMenuPFAE from "./FormMenuPFAE";
import FormMenuPM from "./FormMenuPM";

const FormMenu = ({
  tipoPersona,
  subMenuSelected,
  mostrarSeccionPfae,
  mostrarSeccionPm,
  cantidadObligadosSolidarios,
  cantidadConyugeOS,
  datosOSCuatro,
  datosOSDos,
  datosOSTres,
  datosOSUno,
  seccion,
  setSeccion,
  eliminarOSPfae,
  eliminarOSPm,
  setSubMenuSelected,
  setCantidadObligadosSolidarios,
  conyugeOS,
  disabledBtnAgregarOs,
  setDisabledBtnAgregarOs,
}) => {
  return (
    <div className="container navigation navigation-select formulario--menu-os">
      {tipoPersona === "PFAE" && (
        <FormMenuPFAE
          tipoPersona={tipoPersona}
          subMenuSelected={subMenuSelected}
          seccion={seccion}
          setSeccion={setSeccion}
          mostrarSeccionPfae={mostrarSeccionPfae}
          cantidadObligadosSolidarios={cantidadObligadosSolidarios}
          setSubMenuSelected={setSubMenuSelected}
          datosOSUno={datosOSUno}
          datosOSDos={datosOSDos}
          datosOSTres={datosOSTres}
          datosOSCuatro={datosOSCuatro}
          cantidadConyugeOS={cantidadConyugeOS}
          eliminarOSPfae={eliminarOSPfae}
          setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
          conyugeOS={conyugeOS}
          disabledBtnAgregarOs={disabledBtnAgregarOs}
          setDisabledBtnAgregarOs={setDisabledBtnAgregarOs}
        />
      )}
      {tipoPersona === "PM" && (
        <FormMenuPM
          tipoPersona={tipoPersona}
          subMenuSelected={subMenuSelected}
          seccion={seccion}
          setSeccion={setSeccion}
          mostrarSeccionPm={mostrarSeccionPm}
          cantidadObligadosSolidarios={cantidadObligadosSolidarios}
          datosOSUno={datosOSUno}
          datosOSDos={datosOSDos}
          datosOSTres={datosOSTres}
          setSubMenuSelected={setSubMenuSelected}
          eliminarOSPm={eliminarOSPm}
          cantidadConyugeOS={cantidadConyugeOS}
          setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
          disabledBtnAgregarOs={disabledBtnAgregarOs}
          setDisabledBtnAgregarOs={setDisabledBtnAgregarOs}
        />
      )}
    </div>
  );
};

export default FormMenu;
