export const initialValues = (ofertaCampanias, oferta) => {
  return {
    numeroDeOferta: {
      numero: "",
    },
    personalizada1: [
      {
        idCampania: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].tasaVariable
          : "",
      },
    ],
    personalizada2: [
      {
        idCampania: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].tasaVariable
          : "",
      },
    ],
    personalizada3: [
      {
        idCampania: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].tasaVariable
          : "",
      },
    ],
    personalizada4: [
      {
        idCampania: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].tasaVariable
          : "",
      },
    ],
    personalizada5: [
      {
        idCampania: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].tasaVariable
          : "",
      },
    ],
  };
};
