import React, { useState } from "react";

import "remixicon/fonts/remixicon.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./components/helpers/Navbar";
import Formulario from "./components/campos/Formulario";
import Cotizador from "./components/cotizador/cotizador";
import Prospeccion from "./components/Prospeccion/Prospeccion";
import NavbarWebView from "./components/helpers/NavbarWebView";

import NavbarCrediactivo from "./crediactivo/helpers/Navbar";
import FormularioCrediactivo from "./crediactivo/campos/Formulario";
import CotizadorCrediactivo from "./crediactivo/cotizador/cotizador";
import ProspeccionCrediactivo from "./crediactivo/Prospeccion/Prospeccion";

import CotizadorProceso from "./crediactivo/cotizador/cotizador_proceso";
import Navbar_proceso from "./crediactivo/helpers/Navbar_proceso";

const App = () => {
  const [phase, setPhase] = useState(0);
  const [steps, setSteps] = useState(0);
  const [webView, setWebView] = useState(false);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/cotizador" />} />
        <Route
          path="/cotizador"
          element={
            <>
              <Navbar phone={true} steps={1} phase={1} setWebView={setWebView} />
              <Cotizador webView={webView} />
            </>
          }
        />
        <Route
          path="/prospeccion"
          element={
            <>
              <Navbar phone={true} steps={2} phase={2} setWebView={setWebView} />
              <Prospeccion webView={webView} />
            </>
          }
        />
        <Route
          path="/formulario"
          element={
            <>
              <Navbar phone={true} steps={2} phase={2} setWebView={setWebView} />
              <Formulario webView={webView} />
            </>
          }
        />
        <Route
          path="/cotizadorBanorte"
          element={
            <>
              <NavbarWebView phone={true} steps={1} phase={1} setWebView={setWebView} />
              <Cotizador webView={webView} />
            </>
          }
        />
        <Route
          path="/prospeccionBanorte"
          element={
            <>
              <NavbarWebView phone={true} steps={2} phase={2} setWebView={setWebView} />
              <Prospeccion webView={webView} />
            </>
          }
        />
        <Route
          path="/formularioBanorte"
          element={
            <>
              <NavbarWebView phone={true} steps={2} phase={2} setWebView={setWebView} />
              <Formulario webView={webView} />
            </>
          }
        />
        <Route
          path="/crediactivo"
          element={
            <>
              <NavbarCrediactivo phone={true} steps={1} phase={1} />
              <CotizadorCrediactivo />
            </>
          }
        />
        <Route
          path="/prospeccionCrediactivo"
          element={
            <>
              <NavbarCrediactivo phone={true} steps={2} phase={2} />
              <ProspeccionCrediactivo />
            </>
          }
        />
        <Route
          path="/formularioCrediactivo"
          element={
            <>
              <NavbarCrediactivo phone={false} steps={2} phase={phase} />
              <FormularioCrediactivo />
            </>
          }
        />
        <Route
          path="/crediactivo_origin_2405202312252976"
          element={
            <>
              <Navbar_proceso phone={true} steps={1} phase={1} />
              <CotizadorProceso />
            </>
          }
        />
      </Routes>

    </>
  );
};

export default App;
