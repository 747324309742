import React, { useState} from "react";
import {
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  KEY_PM,
  KEY_PFAE,
} from "../../../../../utils/constantes";
import ConsultaPorNipSolicitantePM from "./ConsultaPorNipSolicitantePM";
import ConsultaPorNipSolicitantePFAE from "../../PFAE/ConsultaPorNip/ConsultaPorNipSolicitantePFAE";
import ConsultaPorNipPAPM from "./ConsultaPorNipPAPM";
import ConsultaPorNipPAConyugePM from "./ConsultaPorNipPAConyugePM";
import ConsultaPorNipOS1PM from "./ConsultaPorNipOS1PM";
import ConsultaPorNipOS2PMPM from "./ConsultaPorNipOS2PMPM";
import ConsultaPorNipOS3CPM from "./ConsultaPorNipOS3CPM";
import ConsultaPorNipSolicitanteConyugePFAE from "../../PFAE/ConsultaPorNip/ConsultaPorNipSolicitanteConyugePFAE";
import ConsultaPorNipOS1PFAE from "../../PFAE/ConsultaPorNip/ConsultaPorNipOS1PFAE";
import ConsultaPorNipOS2PMPFAE from "../../PFAE/ConsultaPorNip/ConsultaPorNipOS2PMPFAE";
import ConsultaPorNipOS3CPFAE from "../../PFAE/ConsultaPorNip/ConsultaPorNipOS3CPFAE";

const ConsultaPorNip = ({
  tipoPersona,
  principalAccionista,
  selectedConsultaNip,
  setSelectedConsultaNip,
  selectedConsultaNipPFAE,
  setSelectedConsultaNipPFAE,
  obligadosSolidarios,
  modeloDatos,
  setLoading,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  principalAccionistaConyuge,
  setContadorOS,
  contadorOS,
  idEstadoCivilSolicitante,
  setShowDecreto,
  setUltimoPasoSolicitud,
}) => {
  const [showNipConyuge, setShowNipConyuge] = useState(false);

  return (
    <>
      {tipoPersona === KEY_PM && (
        <>
          <div className="container navigation div-menu-participantes-historial d-flex justify-content-around navigation-select">
            <>
              <p
                className={`${
                  selectedConsultaNip === 1 
                  ? "bold tab__selected text__red pointer tab"
                  : null
                } pointer p-2 mb-0`}
                onClick={() => setSelectedConsultaNip(1)}
                style={{ padding: "8px 16px" }}
              >
                Solicitante
              </p>
              <p
                className={`${
                  selectedConsultaNip === 2 &&
                  "bold tab__selected text__red pointer tab"
                } pointer p-2 mb-0`}
                onClick={() => setSelectedConsultaNip(2)}
                style={{ padding: "8px 16px" }}
              >
                Representante legal
              </p>
              {principalAccionista?.generales?.estadoCivilId ===
                ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              principalAccionista?.conyuge?.idConyuge ? (
                <p
                  className={`${
                    selectedConsultaNip === 3 ||
                    selectedConsultaNip ===
                      principalAccionista?.conyuge?.idConyuge
                      ? "bgc-red radius strong-shadow"
                      : ""
                  } pointer`}
                  style={{ padding: "8px 16px" }}
                >
                  Conyuge PA
                </p>
              ) : (
                <></>
              )}
              {obligadosSolidarios.map((item, index) => {
                return (
                  <>
                    {item?.obligadoSolidario?.generales?.idCliente !==
                      undefined && (
                      <p
                        className={`${
                          selectedConsultaNip ===
                          item?.obligadoSolidario?.generales?.idCliente
                            ? "bgc-red radius strong-shadow"
                            : ""
                        } pointer`}
                        style={{ padding: "8px 16px" }}
                      >
                        OS{index + 1}
                      </p>
                    )}
                    {item?.obligadoSolidarioPM?.generales?.idCliente !==
                      undefined && (
                      <p
                        className={`${
                          selectedConsultaNip ===
                          item?.obligadoSolidarioPM?.generales?.idCliente
                            ? "bgc-red radius strong-shadow"
                            : ""
                        } pointer`}
                        style={{ padding: "8px 16px" }}
                      >
                        OS{index + 1}
                      </p>
                    )}

                    {item?.obligadoSolidario?.conyuge.generales?.idCliente !==
                      undefined && (
                      <p
                        className={`${
                          selectedConsultaNip ===
                          item?.obligadoSolidario?.conyuge?.generales?.idCliente
                            ? "bgc-red radius strong-shadow"
                            : ""
                        } pointer`}
                        style={{ padding: "8px 16px" }}
                      >
                        Cónyuge OS{index + 1}
                      </p>
                    )}
                  </>
                );
              })}
            </>
          </div>
          {selectedConsultaNip === 1 && (
            <ConsultaPorNipSolicitantePM
              modeloDatos={modeloDatos}
              setLoading={setLoading}
              setSelectedConsultaNip={setSelectedConsultaNip}
              handleRegresarSeccionPyme={handleRegresarSeccionPyme}
            />
          )}

          {selectedConsultaNip === 2 && (
            <ConsultaPorNipPAPM
              idCliente={principalAccionista?.generales?.idCliente}
              setLoading={setLoading}
              modeloDatos={modeloDatos}
              handleRegresarSeccionPyme={handleRegresarSeccionPyme}
              setShowDecreto={setShowDecreto}
              setUltimoPasoSolicitud={setUltimoPasoSolicitud}
            />
          )}
          {selectedConsultaNip === principalAccionista?.conyuge?.idConyuge &&
            principalAccionista?.generales?.estadoCivilId ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL && (
              <ConsultaPorNipPAConyugePM
                idCliente={principalAccionista?.conyuge?.idConyuge}
                setLoading={setLoading}
                setSelectedConsultaNip={setSelectedConsultaNip}
                modeloDatos={modeloDatos}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                obligadosSolidarios={obligadosSolidarios}
                setContadorOS={setContadorOS}
                contadorOS={contadorOS}
              />
            )}
          {obligadosSolidarios !== undefined &&
            obligadosSolidarios.map((item, index) => {
              return (
                <>
                  {selectedConsultaNip ===
                    item?.obligadoSolidario?.generales?.idCliente && (
                    <ConsultaPorNipOS1PM
                      idCliente={item?.obligadoSolidario?.generales?.idCliente}
                      setLoading={setLoading}
                      setSelectedConsultaNip={setSelectedConsultaNip}
                      modeloDatos={modeloDatos}
                      setStyleHr={setStyleHr}
                      handleSeccionPyme={handleSeccionPyme}
                      setContadorOS={setContadorOS}
                      contadorOS={contadorOS}
                    />
                  )}
                  {selectedConsultaNip ===
                    item?.obligadoSolidarioPM?.generales?.idCliente && (
                    <ConsultaPorNipOS2PMPM
                      idCliente={
                        item?.obligadoSolidarioPM?.generales?.idCliente
                      }
                      setLoading={setLoading}
                      setSelectedConsultaNip={setSelectedConsultaNip}
                      modeloDatos={modeloDatos}
                      setStyleHr={setStyleHr}
                      handleSeccionPyme={handleSeccionPyme}
                      obligadosSolidarios={obligadosSolidarios}
                      setContadorOS={setContadorOS}
                      contadorOS={contadorOS}
                    />
                  )}
                  {selectedConsultaNip ===
                    item?.obligadoSolidario?.conyuge?.generales?.idCliente && (
                    <ConsultaPorNipOS3CPM
                      idCliente={
                        item?.obligadoSolidario?.conyuge?.generales?.idCliente
                      }
                      setLoading={setLoading}
                      setSelectedConsultaNip={setSelectedConsultaNip}
                      modeloDatos={modeloDatos}
                      setStyleHr={setStyleHr}
                      handleSeccionPyme={handleSeccionPyme}
                      obligadosSolidarios={obligadosSolidarios}
                      setContadorOS={setContadorOS}
                      contadorOS={contadorOS}
                    />
                  )}
                </>
              );
            })}
        </>
      )}
      {tipoPersona === KEY_PFAE && (
        <>
          <div className="container navigation div-menu-participantes-historial d-flex justify-content-around navigation-select">
            <>
              <p
                className={`${
                  selectedConsultaNipPFAE === 1                    
                  ? "bold tab__selected text__red pointer tab"
                  : null
                } pointer p-2 mb-0`}
                onClick={() => setSelectedConsultaNipPFAE(1)}
                style={{ padding: "8px 16px" }}
              >
                Solicitante
              </p>
              {idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL && showNipConyuge && (
                <p
                  className={`${
                    selectedConsultaNipPFAE === 2
                      ? "bgc-red radius strong-shadow"
                      : ""
                  } pointer`}
                  style={{ padding: "8px 16px" }}
                >
                  Cónyuge Solicitante
                </p>
              )}
              {obligadosSolidarios.map((item, index) => {
                return (
                  <>
                    {item?.obligadoSolidario?.generales?.idCliente !==
                      undefined && (
                      <p
                        className={`${
                          selectedConsultaNipPFAE ===
                          item?.obligadoSolidario?.generales?.idCliente
                            ? "bgc-red radius strong-shadow"
                            : ""
                        } pointer`}
                        style={{ padding: "8px 16px" }}
                      >
                        OS{index + 1}
                      </p>
                    )}
                    {item?.obligadoSolidarioPM?.generales?.idCliente !==
                      undefined && (
                      <p
                        className={`${
                          selectedConsultaNipPFAE ===
                          item?.obligadoSolidarioPM?.generales?.idCliente
                            ? "bgc-red radius strong-shadow"
                            : ""
                        } pointer`}
                        style={{ padding: "8px 16px" }}
                      >
                        OS{index + 1}
                      </p>
                    )}

                    {item?.obligadoSolidario?.conyuge.generales?.idCliente !==
                      undefined && (
                      <p
                        className={`${
                          selectedConsultaNipPFAE ===
                          item?.obligadoSolidario?.conyuge?.generales?.idCliente
                            ? "bgc-red radius strong-shadow"
                            : ""
                        } pointer`}
                        style={{ padding: "8px 16px" }}
                      >
                        Cónyuge OS{index + 1}
                      </p>
                    )}
                  </>
                );
              })}
            </>
          </div>
          {selectedConsultaNipPFAE === 1 && (
            <ConsultaPorNipSolicitantePFAE
              modeloDatos={modeloDatos}
              setLoading={setLoading}
              setSelectedConsultaNipPFAE={setSelectedConsultaNipPFAE}          
              handleRegresarSeccionPyme={handleRegresarSeccionPyme}
              setShowDecreto={setShowDecreto}
              setUltimoPasoSolicitud={setUltimoPasoSolicitud}
            />
          )}

          {selectedConsultaNipPFAE === 2 && (
            <ConsultaPorNipSolicitanteConyugePFAE
              idCliente={principalAccionista?.generales?.idCliente}
              setLoading={setLoading}
              setSelectedConsultaNipPFAE={setSelectedConsultaNipPFAE}
              modeloDatos={modeloDatos}
              setStyleHr={setStyleHr}
              handleSeccionPyme={handleSeccionPyme}
              principalAccionistaConyuge={principalAccionistaConyuge}
              setContadorOS={setContadorOS}
              contadorOS={contadorOS}
            />
          )}
          {obligadosSolidarios !== undefined &&
            obligadosSolidarios.map((item, index) => {
              return (
                <>
                  {selectedConsultaNipPFAE ===
                    item?.obligadoSolidario?.generales?.idCliente && (
                    <ConsultaPorNipOS1PFAE
                      idCliente={item?.obligadoSolidario?.generales?.idCliente}
                      setLoading={setLoading}
                      setSelectedConsultaNipPFAE={setSelectedConsultaNipPFAE}
                      modeloDatos={modeloDatos}
                      setStyleHr={setStyleHr}
                      handleSeccionPyme={handleSeccionPyme}
                      setContadorOS={setContadorOS}
                      contadorOS={contadorOS}
                    />
                  )}
                  {selectedConsultaNipPFAE ===
                    item?.obligadoSolidarioPM?.generales?.idCliente && (
                    <ConsultaPorNipOS2PMPFAE
                      idCliente={
                        item?.obligadoSolidarioPM?.generales?.idCliente
                      }
                      setLoading={setLoading}
                      setSelectedConsultaNipPFAE={setSelectedConsultaNipPFAE}
                      modeloDatos={modeloDatos}
                      setStyleHr={setStyleHr}
                      handleSeccionPyme={handleSeccionPyme}
                      obligadosSolidarios={obligadosSolidarios}
                      setContadorOS={setContadorOS}
                      contadorOS={contadorOS}
                    />
                  )}
                  {selectedConsultaNipPFAE ===
                    item?.obligadoSolidario?.conyuge?.generales?.idCliente && (
                    <ConsultaPorNipOS3CPFAE
                      idCliente={
                        item?.obligadoSolidario?.conyuge?.generales?.idCliente
                      }
                      setLoading={setLoading}
                      setSelectedConsultaNipPFAE={setSelectedConsultaNipPFAE}
                      modeloDatos={modeloDatos}
                      setStyleHr={setStyleHr}
                      handleSeccionPyme={handleSeccionPyme}
                      obligadosSolidarios={obligadosSolidarios}
                      setContadorOS={setContadorOS}
                      contadorOS={contadorOS}
                    />
                  )}
                </>
              );
            })}
        </>
      )}
    </>
  );
};

export default ConsultaPorNip;
