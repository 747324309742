import { useState, useEffect } from "react";
import axios from "axios";
import { path } from "../../../../config/Path";

export const useFetchSelectData = () => {
  const [dataActividad, setDataActividad] = useState(null);
  const [dataTipoMotivoCredito, setDataTipoMotivoCredito] = useState(null);
  const [dataAniosNegocio, setDataAniosNegocio] = useState(null);

  useEffect(() => {

    axios.get(`${path}/rest/consultaCatalogos/actividad`).then((res) => {
      setDataActividad(res.data);
    });

    axios
      .get(`${path}/rest/consultaCatalogos/tipoMotivosCredito`)
      .then((res) => {
        setDataTipoMotivoCredito(res.data);
      });

    axios.get(`${path}/rest/consultaCatalogos/aniosNegocio`).then((res) => {
      setDataAniosNegocio(res.data);
    });
  }, []);

  return { dataActividad, dataTipoMotivoCredito, dataAniosNegocio };
};
