export const sessionStorageAutoServicio       = "token-autoservicio";
export const sessionStorageDatosOperacion     = "token-datos-operacion";
export const sessionStorageIdSolicitud        = "token-id-solicitud";
export const sessionStorageContinuarSolicitud = "token-bandera-solicitud";
export const sessionStorageSucursalCliente    = "token-sucursal-cliente";
export const sessionStorageNomSucursalCliente = "token-nombre-sucursal-cliente";
export const sessionStorageMontoMinimoCredito = "token-monto-minimo";
export const sessionStorageMontoMaximoCredito = "token-monto-maximo";
export const sessionTasaAnualAts              = "token-tasa-anual";


export const localStorageToken                = "token-autoservicio-pyme";
export const localStorageRFC                  = "rfc-cliente-pyme";