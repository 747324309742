import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { selectStylesDouble } from "../../../../../styles/select-styles";
import CheckBoxInput from "../../../../basics/checkBox";
import {
  validateValue,
  validDate,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../../functions/fieldFunctions";
import "moment/locale/es";
import ErrorMessage from "../../../../helpers/ErrorMessage/ErrorMessage";

import "react-datetime/css/react-datetime.css";
import {
  api_direccion_by_cp,
  api_save_principal_accionista_conyuge_PM,
} from "../../../../../api/api_servicios_rest";

import {
  NACIONALIDAD_MEXICAN0,
  ID_PF,
  ID_PFAE,
  SECCION_PA_CONYUGE_PM,
  TIPO_VIVIENDA_EDIFICIO,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  TIPO_DEPARTAMENTO_APARTAMENTO,
  ID_CALCULO_RFC_NOMBRE,
  ID_CALCULO_RFC_APP,
  ID_CALCULO_RFC_APM,
  ID_CALCULO_RFC_FECHANAC,
  ID_CALCULO_RFC_GENERO,
  ID_CALCULO_RFC_ESTADONAC,
  ID_PAIS_MEXICO,
} from "../../../../../utils/constantes";
import { mensajeMostrarUsuario } from "../../../../../utils/mensajesServicios";

import {
  fechaActualYYYMMDD,
  fechaMaxMayorEdadYYYMMDD,
  fechaMinEdadYYYMMDD,
  dependeintesMajor23,
  tiempoViviendaAnterior,
  tiempoViviendaActual,
  generaCurp,
  generaRFC,
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
  compareDatesOrigin,
  validarRFCTipoPersona,
} from "../../../../../utils/validaciones";

import {
  notSpecialCaracters,
  textToUpperCase,
} from "../../../../../functions/fieldFunctions";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import SelectWithErrorActividad from "../../../../basics/SelectWithError/SelectWithErrorActividad";
import SelectWithErrorTooltipCompuesto from "../../../../basics/SelectWithError/SelectWithErrorTooltipCompuesto";
import { obtenerGenerarRfcCurpDPAC } from "./helpers/obligadoSolidarioHelper";
import { sweetAlertError } from "../../../../helpers/alertas/alertas";

const DatosPrincipalAccionistaConyuge = ({
  modeloPm,
  setModeloPm,
  axiosData,
  aniosDomicilio,
  mesesDomicilo,
  anios,
  Swal,
  esObligadoSolidario,
  esObligadoSolidarioConyugeBD,
  setEsObligadoSolidarioConyugeBD,
  idTipoPersonaPAConyuge,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setObligadosSolidariosInformacionBasica,
  setLoading,
  setCantidadConyugeOS,
  cantidadConyugeOS,
}) => {
  const { innerWidth, innerHeight } = window;
  const [coloniasCP, setColoniasCP] = useState([]);
  const [startDateFechaNacimiento, setStartDateFechaNacimiento] = useState("");
  const [startDateFechaSHCP, setStartDateFechaSHCP] = useState("");
  const [totalDependientes, setTotalDependientes] = useState("");
  const [totalDependientesMenores23, setTotalDependientesMenores23] =
    useState("");

  const formik = useFormik({
    initialValues: initialValues(
      modeloPm,
      idTipoPersonaPAConyuge,
      esObligadoSolidario,
      axiosData
    ),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(
      validationSchema(
        esObligadoSolidario,
        idTipoPersonaPAConyuge,
        totalDependientesMenores23,
        totalDependientes
      )
    ),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      formik.setFieldValue(
        "principalAccionista.conyuge.generales.esObligadoSolidario",
        esObligadoSolidario
      );
      formik.setFieldValue(
        "principalAccionista.conyuge.generales.tipoPersonaId",
        idTipoPersonaPAConyuge
      );
      salvarDatosConyugePA(formData);
    },
  });

  const obtenerDireccionByCp = async (codigoPostal) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK") {
        if (response.data.municipio !== undefined) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "principalAccionista.conyuge.direccion.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue(
            "dataObj.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response?.data?.municipio?.id
            )
          );
          formik.setFieldValue(
            "principalAccionista.conyuge.direccion.estadoId",
            response.data.estado.id
          );
          formik.setFieldValue(
            "dataObj.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response?.data?.estado?.id
            )
          );
          formik.setFieldValue(
            "principalAccionista.conyuge.direccion.colonia",
            response.data.asentamientos[0]
          );
        } else {
          limpiarDireccionByCp();
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue(
      "principalAccionista.conyuge.direccion.municipioId",
      ""
    );
    formik.setFieldValue("dataObj.municipioObj", "");
    formik.setFieldValue("principalAccionista.conyuge.direccion.estadoId", "");
    formik.setFieldValue("dataObj.estadoObj", "");
    formik.setFieldValue("principalAccionista.conyuge.direccion.colonia", "");
  };

  const generarRfcCurp = (idValorCalculoRFC, valueCalculoRFC) => {
    const {
      nombre,
      apellidoMaterno,
      apellidoPaterno,
      estadoNacimientoId,
      fechaNacimiento,
      generoId,
    } = obtenerGenerarRfcCurpDPAC(idValorCalculoRFC, valueCalculoRFC, formik);

    if (
      nombre &&
      nombre.length > 0 &&
      apellidoPaterno &&
      apellidoPaterno.length > 0 &&
      generoId &&
      estadoNacimientoId &&
      fechaNacimiento &&
      fechaNacimiento.length > 0
    ) {
      var idEstado = Number(estadoNacimientoId);
      var persona = {
        nombre: nombre,
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
        genero: generoId === 1 ? "H" : "M",
        idEstado: idEstado - 1,
        fechaNacimiento: fechaNacimiento,
      };

      let curp = generaCurp(persona);
      if (curp && curp.length === 18) {
        formik.setFieldValue(
          "principalAccionista.conyuge.generales.curp",
          curp
        );
      }

      if (apellidoMaterno && apellidoMaterno.length > 0) {
        const rfc = generaRFC(persona);
        if (rfc) {
          formik.setFieldValue(
            "principalAccionista.conyuge.generales.rfc",
            rfc
          );
        }
      }
    }
  };

  const salvarDatosConyugePA = async (formData) => {
    try {
      const response = await api_save_principal_accionista_conyuge_PM(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPm({
            ...modeloPm,
            idCliente: data.clienteId,
            principalAccionista: {
              ...modeloPm.principalAccionista,
              idPrincipalAccionista: data.principalAccionistaId,
              conyuge: data.conyugeModelo,
            },
          });

          if (
            data.principalAccionistaConyugeEsObligadoSolidario &&
            esObligadoSolidarioConyugeBD === false
          ) {
            setCantidadConyugeOS(cantidadConyugeOS + 1);
          }

          if (
            data.principalAccionistaConyugeEsObligadoSolidario === false &&
            esObligadoSolidarioConyugeBD === true
          ) {
            setCantidadConyugeOS(cantidadConyugeOS - 1);
          }

          setEsObligadoSolidarioConyugeBD(
            data.principalAccionistaConyugeEsObligadoSolidario
          );

          setObligadosSolidariosInformacionBasica(
            data.obligadosSolidariosInformacionBasica
          );
          setStyleHr("block");
          setLoading(false);
          handleSeccionPyme(SECCION_PA_CONYUGE_PM);
        } else {
          setLoading(false);
          const mensajeError = mensajeMostrarUsuario(data.mensaje);
          sweetAlertError(mensajeError);
        }
      } else {
        setLoading(false);
        sweetAlertError(
          "Ocurrió un error al guardar cónyuge del principal accionista PM"
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modeloPm?.principalAccionista?.conyuge && 
        modeloPm?.principalAccionista?.conyuge?.idConyuge != null) {
      setStartDateFechaNacimiento(
        modeloPm?.principalAccionista?.conyuge?.generales?.fechaNacimiento
          .split("-")
          .reverse()
          .join("-")
      );

      if (
        modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
          ?.fechaAltaShcp !== undefined &&
        modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
          ?.fechaAltaShcp !== null
      ) {
        setStartDateFechaSHCP(
          modeloPm?.principalAccionista?.conyuge?.datosDelNegocio?.fechaAltaShcp
            .split("-")
            .reverse()
            .join("-")
        );
      }

      if (modeloPm?.principalAccionista?.conyuge?.direccion !== null) {
        obtenerDireccionByCp(
          modeloPm?.principalAccionista?.direccion?.codigoPostal
        );

        if (modeloPm?.principalAccionista?.conyuge.direccion?.piso == null) {
          formik.setFieldValue(
            "principalAccionista.conyuge.direccion.piso",
            ""
          );
        }

        if (
          modeloPm?.principalAccionista?.conyuge.direccion?.numeroInterior ==
          null
        ) {
          formik.setFieldValue(
            "principalAccionista.conyuge.direccion.numeroInterior",
            ""
          );
        }
      }

      let calidadMigratoria = modeloPm?.principalAccionista?.conyuge
        ?.generales?.calidadMigratoria
        ? modeloPm?.principalAccionista?.conyuge?.generales?.calidadMigratoria
        : "";
      formik.setFieldValue(
        "principalAccionista.conyuge.generales.calidadMigratoria",
        calidadMigratoria
      );

      let dependientesMajor23Opc = modeloPm?.principalAccionista?.conyuge
        ?.generales?.dependientesEconomicosMenores23
        ? axiosData?.generales?.dependientesMenores23Anios?.filter(
          (e) =>
            e.id ===
            modeloPm?.principalAccionista?.conyuge?.generales
              ?.dependientesEconomicosMenores23
        )
        : "";
      let dependientesEconomicoOpc = modeloPm?.principalAccionista?.conyuge
        ?.generales?.dependientesEconomicos
        ? axiosData?.generales?.dependientes?.filter(
          (e) =>
            e.id ===
            modeloPm?.principalAccionista?.conyuge?.generales
              ?.dependientesEconomicos
        )
        : "";
      setTotalDependientesMenores23(
        dependientesMajor23Opc !== ""
          ? dependientesMajor23Opc[0].descripcion
          : 0
      );
      setTotalDependientes(
        dependientesEconomicoOpc !== ""
          ? dependientesEconomicoOpc[0].descripcion
          : 0
      );

      if (
        modeloPm?.principalAccionista?.conyuge?.generales
          ?.esObligadoSolidario !== esObligadoSolidario
      ) {
        formik.setFieldValue(
          "principalAccionista.conyuge.generales.esObligadoSolidario",
          esObligadoSolidario
        );

        if (esObligadoSolidario) {
          formik.setFieldValue(
            "principalAccionista.conyuge.generales.tipoPersonaId",
            idTipoPersonaPAConyuge
          );
        } else {
          formik.setFieldValue(
            "principalAccionista.conyuge.generales.tipoPersonaId",
            ""
          );
        }
      }

      if (
        modeloPm?.principalAccionista?.conyuge?.generales
          ?.nombreOtraEmpresaPfae === null
      ) {
        formik.setFieldValue(
          "principalAccionista.conyuge.generales.nombreOtraEmpresaPfae",
          ""
        );
      }

      if (
        modeloPm?.principalAccionista?.conyuge?.generales
          ?.nombreOtraEmpresa === null
      ) {
        formik.setFieldValue(
          "principalAccionista.conyuge.generales.nombreOtraEmpresa",
          ""
        );
      }

      if (
        modeloPm?.principalAccionista?.conyuge?.generales
          ?.paisResidenciaFiscal2Id === null
      ) {
        formik.setFieldValue(
          "principalAccionista.conyuge.generales.paisResidenciaFiscal2Id",
          ""
        );
      }

      if (
        modeloPm?.principalAccionista?.conyuge?.generales?.numeroIdFiscal2 ==
        null
      ) {
        formik.setFieldValue(
          "principalAccionista.conyuge.generales.numeroIdFiscal2",
          ""
        );
      }
    } else {
      formik.setFieldValue(
        "principalAccionista.conyuge.generales.estadoCivilId",
        ESTADO_CIVIL_SOCIEDAD_CONYUGAL
      );
      formik.setFieldValue(
        "dataObj.estadoCivilObj",
        axiosData?.generales?.estadoCivilOpciones?.filter(
          (e) => e.id === ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        )
      );
    }
  }, []);

  useEffect(() => {
    if (idTipoPersonaPAConyuge !== null) {
      formik.setFieldValue(
        "principalAccionista.conyuge.generales.tipoPersonaId",
        idTipoPersonaPAConyuge
      );
    }
  }, [idTipoPersonaPAConyuge]);

  useEffect(() => {
    if (esObligadoSolidario) {
      formik.setFieldValue(
        "principalAccionista.conyuge.generales.esObligadoSolidario",
        esObligadoSolidario
      );
    }
  }, [esObligadoSolidario]);

  return (
    <>
      <form name="form-pa-conyuge" onSubmit={formik.handleSubmit}>
        <div>
          <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3 ">
            Información general
          </h2>
          <FormLayout>
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales?.nombre
              }
              errorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales?.nombre
              }
              label="Nombre *"
              name="principalAccionista.conyuge.generales.nombre"
              value={
                formik.values?.principalAccionista?.conyuge?.generales?.nombre
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.conyuge !== undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales !==
                    undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales?.nombre
                ) {
                  delete newErrors?.principalAccionista?.conyuge?.generales
                    ?.nombre;
                  formik.setErrors(newErrors);
                }

                if ((validarTextoConEnies(e.target.value), "text")) {
                  formik.setFieldValue(
                    "principalAccionista.conyuge.generales.nombre",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_NOMBRE, e.target.value);
              }}
              InputProps={{ maxLength: 30 }}
            />
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.apellidoPaterno
              }
              errorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.apellidoPaterno
              }
              label="Apellido paterno *"
              name="principalAccionista.conyuge.generales.apellidoPaterno"
              value={
                formik.values?.principalAccionista?.conyuge?.generales
                  ?.apellidoPaterno
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.conyuge !== undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales !==
                    undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales
                    ?.apellidoPaterno
                ) {
                  delete newErrors?.principalAccionista?.conyuge?.generales
                    ?.apellidoPaterno;
                  formik.setErrors(newErrors);
                }

                if ((validarTextoConEnies(e.target.value), "text")) {
                  formik.setFieldValue(
                    "principalAccionista.conyuge.generales.apellidoPaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APP, e.target.value);
              }}
              InputProps={{ maxLength: 20 }}
            />
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.apellidoMaterno
              }
              errorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.apellidoMaterno
              }
              label="Apellido materno"
              name="principalAccionista.conyuge.generales.apellidoMaterno"
              value={
                formik.values?.principalAccionista?.conyuge?.generales
                  ?.apellidoMaterno
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.conyuge !== undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales !==
                    undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales
                    ?.apellidoMaterno
                ) {
                  delete newErrors?.principalAccionista?.conyuge?.generales
                    ?.apellidoMaterno;
                  formik.setErrors(newErrors);
                }

                if ((validarTextoConEnies(e.target.value), "text")) {
                  formik.setFieldValue(
                    "principalAccionista.conyuge.generales.apellidoMaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APM, e.target.value);
              }}
              InputProps={{ maxLength: 20 }}
            />
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.fechaNacimiento
              }
              errorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.fechaNacimiento
              }
              type="date"
              label="Fecha de nacimiento *"
              name="principalAccionista.conyuge.generales.fechaNacimiento"
              value={startDateFechaNacimiento}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.conyuge !== undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales !==
                    undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales
                    ?.fechaNacimiento
                ) {
                  delete newErrors?.principalAccionista?.conyuge?.generales
                    ?.fechaNacimiento;
                  formik.setErrors(newErrors);
                }

                if (validDate(e.target.value)) {
                  let array = e.target.value.split("-").reverse().join("-");
                  formik.setFieldValue(
                    "principalAccionista.conyuge.generales.fechaNacimiento",
                    array
                  );
                  setStartDateFechaNacimiento(e.target.value);
                  generarRfcCurp(ID_CALCULO_RFC_FECHANAC, array);
                }
              }}
              InputProps={{
                shrink: true,
                max: fechaMaxMayorEdadYYYMMDD(),
                min: fechaMinEdadYYYMMDD(),
              }}
            />
            <SelectWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.paisNacimientoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.paisNacimientoId
              }
              value={{
                label: formik?.values?.dataObj?.paisNacimientoObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.paisNacimientoObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.conyuge !== undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales !==
                    undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales
                    ?.paisNacimientoId
                ) {
                  delete newErrors?.principalAccionista?.conyuge?.generales
                    ?.paisNacimientoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.conyuge.generales.paisNacimientoId",
                  e.id
                );
                formik.setFieldValue("dataObj.paisNacimientoObj", [e]);
              }}
              label="País de nacimiento *"
              options={axiosData?.generales?.paises?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
            />
            {formik.values?.principalAccionista?.conyuge?.generales?.paisNacimientoId !== "" &&
              parseInt(
                formik.values?.principalAccionista?.conyuge?.generales?.paisNacimientoId) === ID_PAIS_MEXICO && (
              <SelectWithError
                showErrorMessage={
                  formik.errors?.principalAccionista?.conyuge?.generales
                    ?.estadoNacimientoId
                }
                errorMessage={
                  formik.errors?.principalAccionista?.conyuge?.generales
                    ?.estadoNacimientoId
                }
                label="Estado de nacimiento *"
                name="principalAccionista.conyuge.generales.estadoNacimientoId"
                value={{
                  label:
                    formik?.values?.dataObj?.estadoNacimientoObj?.[0]?.nombre,
                  value:
                    formik?.values?.dataObj?.estadoNacimientoObj?.[0]?.nombre,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.conyuge !== undefined &&
                    newErrors?.principalAccionista?.conyuge?.generales !==
                      undefined &&
                    newErrors?.principalAccionista?.conyuge?.generales
                      ?.estadoNacimientoId
                  ) {
                    delete newErrors?.principalAccionista?.conyuge?.generales
                      ?.estadoNacimientoId;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue(
                    "principalAccionista.conyuge.generales.estadoNacimientoId",
                    e.id
                  );
                  formik.setFieldValue("dataObj.estadoNacimientoObj", [e]);
                  generarRfcCurp(ID_CALCULO_RFC_ESTADONAC, e.id);
                }}
                options={axiosData?.generales?.estados?.map((item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                })}
              />
            )}

            <SelectWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales?.generoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales?.generoId
              }
              value={{
                label: formik?.values?.principalAccionista?.conyuge?.generales
                  ?.generoId
                  ? formik?.values?.principalAccionista?.conyuge?.generales
                      ?.generoId == 2
                    ? "Femenino"
                    : "Masculino"
                  : "",
                value: formik?.values?.principalAccionista?.conyuge?.generales
                  ?.generoId
                  ? formik?.values?.principalAccionista?.conyuge?.generales
                      ?.generoId == 2
                    ? "Femenino"
                    : "Masculino"
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.conyuge !== undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales !==
                    undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales?.generoId
                ) {
                  delete newErrors?.principalAccionista?.conyuge?.generales
                    ?.generoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.conyuge.generales.generoId",
                  e.id
                );
                generarRfcCurp(ID_CALCULO_RFC_GENERO, e.id);
              }}
              label="Género *"
              options={axiosData?.generales?.generoOpciones?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
            />
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales?.rfc
              }
              errorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales?.rfc
              }
              label="RFC *"
              name="principalAccionista.conyuge.generales.rfc"
              value={
                formik.values?.principalAccionista?.conyuge?.generales?.rfc
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.conyuge !== undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales !==
                    undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales?.rfc
                ) {
                  delete newErrors?.principalAccionista?.conyuge?.generales
                    ?.rfc;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "principalAccionista.conyuge.generales.rfc",
                    newValue
                  );
                }
              }}
              InputProps={{ maxLength: 13 }}
            />
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales?.curp
              }
              errorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales?.curp
              }
              label="CURP *"
              name="principalAccionista.conyuge.generales.curp"
              value={
                formik.values?.principalAccionista?.conyuge?.generales?.curp
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.conyuge !== undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales !==
                    undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales?.curp
                ) {
                  delete newErrors?.principalAccionista?.conyuge?.generales
                    ?.curp;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "principalAccionista.conyuge.generales.curp",
                    newValue
                  );
                }
              }}
              InputProps={{ maxLength: 18 }}
            />
            {esObligadoSolidario === true && idTipoPersonaPAConyuge !== null && (
              <InputWithError
                showErrorMessage={
                  formik.errors?.principalAccionista?.conyuge?.generales
                    ?.folioVerificacionId
                }
                errorMessage={
                  formik.errors?.principalAccionista?.conyuge?.generales
                    ?.folioVerificacionId
                }
                label="Número de identificación oficial *"
                name="principalAccionista.conyuge.generales.folioVerificacionId"
                value={
                  formik.values?.principalAccionista?.conyuge?.generales
                    ?.folioVerificacionId
                }
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.conyuge !== undefined &&
                    newErrors?.principalAccionista?.conyuge?.generales !==
                      undefined &&
                    newErrors?.principalAccionista?.conyuge?.generales
                      ?.folioVerificacionId
                  ) {
                    delete newErrors?.principalAccionista?.conyuge?.generales
                      ?.folioVerificacionId;
                    formik.setErrors(newErrors);
                  }

                  if (validateValue(e.target.value, "alphanumeric")) {
                    formik.setFieldValue(
                      "principalAccionista.conyuge.generales.folioVerificacionId",
                      e.target.value
                    );
                  }
                }}
                InputProps={{ maxLength: 18 }}
                tooltip="Captura el número de folio de tu identificación, si es INE lo encontrarás en la parte posterior de la misma, después de las letras IDMEX"
              />
            )}
            <SelectWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.nacionalidadId
              }
              errorMessage={
                formik.errors?.principalAccionista?.conyuge?.generales
                  ?.nacionalidadId
              }
              menuPlacement="top"
              name="principalAccionista.conyuge.generales.nacionalidadId"
              value={{
                label: formik?.values?.dataObj?.nacionalidadObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.nacionalidadObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.conyuge !== undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales !==
                    undefined &&
                  newErrors?.principalAccionista?.conyuge?.generales
                    ?.nacionalidadId
                ) {
                  delete newErrors?.principalAccionista?.conyuge?.generales
                    ?.nacionalidadId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.conyuge.generales.nacionalidadId",
                  e.id
                );
                formik.setFieldValue("dataObj.nacionalidadObj", [e]);
              }}
              label="Nacionalidad *"
              options={axiosData?.generales?.nacionalidades?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
            />
            {esObligadoSolidario === true && idTipoPersonaPAConyuge !== null && (
              <>
                {formik.values?.principalAccionista?.conyuge?.generales
                  ?.nacionalidadId !== "" &&
                  parseInt(
                    formik.values?.principalAccionista?.conyuge?.generales
                      ?.nacionalidadId
                  ) !== NACIONALIDAD_MEXICAN0 && (
                    <InputWithError
                      label="Calidad migratoria *"
                      name="principalAccionista.conyuge.generales.calidadMigratoria"
                      value={
                        formik.values?.principalAccionista?.conyuge?.generales
                          ?.calidadMigratoria
                      }
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.principalAccionista !== undefined &&
                          newErrors?.principalAccionista?.conyuge !==
                            undefined &&
                          newErrors?.principalAccionista?.conyuge?.generales !==
                            undefined &&
                          newErrors?.principalAccionista?.conyuge?.generales
                            ?.calidadMigratoria
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.generales?.calidadMigratoria;
                          formik.setErrors(newErrors);
                        }

                        if (validarTextoConEnies(e.target.value, "text")) {
                          formik.setFieldValue(
                            "principalAccionista.conyuge.generales.calidadMigratoria",
                            e.target.value
                          );
                        }
                      }}
                      InputProps={{ maxLength: 180 }}
                    />
                  )}
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.estadoCivilId
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.estadoCivilId
                  }
                  name="principalAccionista.conyuge.generales.estadoCivilId"
                  value={{
                    label: formik?.values?.dataObj?.estadoCivilObj?.[0]?.nombre,
                    value: formik?.values?.dataObj?.estadoCivilObj?.[0]?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales
                        ?.estadoCivilId
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.generales
                        ?.estadoCivilId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "principalAccionista.conyuge.generales.estadoCivilId",
                      e.id
                    );
                    formik.setFieldValue("dataObj.estadoCivilObj", [e]);
                  }}
                  label="Estado civil *"
                  options={axiosData?.generales?.estadoCivilOpciones?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.nombre,
                        value: item.nombre,
                      };
                    }
                  )}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.nivelEducativoId
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.nivelEducativoId
                  }
                  label="Nivel académico *"
                  name="principalAccionista.conyuge.generales.nivelEducativoId"
                  value={{
                    label:
                      formik?.values?.dataObj?.nivelEducativoObj?.[0]?.nombre,
                    value:
                      formik?.values?.dataObj?.nivelEducativoObj?.[0]?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales
                        ?.nivelEducativoId
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.generales
                        ?.nivelEducativoId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "principalAccionista.conyuge.generales.nivelEducativoId",
                      e.id
                    );
                    formik.setFieldValue("dataObj.nivelEducativoObj", [e]);
                  }}
                  options={axiosData?.generales?.nivelEducativoOpciones?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.nombre,
                        value: item.nombre,
                      };
                    }
                  )}
                />
                <InputWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.profesion
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.profesion
                  }
                  name="principalAccionista.conyuge.generales.profesion"
                  value={
                    formik.values?.principalAccionista?.conyuge?.generales
                      ?.profesion
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales
                        ?.profesion
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.generales
                        ?.profesion;
                      formik.setErrors(newErrors);
                    }

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "principalAccionista.conyuge.generales.profesion",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                  label="Ocupación o profesión *"
                />
                <InputWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.correoElectronico
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.correoElectronico
                  }
                  label="Correo electrónico *"
                  name="principalAccionista.conyuge.generales.correoElectronico"
                  value={
                    formik.values?.principalAccionista?.conyuge?.generales
                      ?.correoElectronico
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales
                        ?.correoElectronico
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.generales
                        ?.correoElectronico;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "principalAccionista.conyuge.generales.correoElectronico",
                      e.target.value
                    );
                  }}
                  type="email"
                  InputProps={{ maxLength: 50 }}
                />
                <InputWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.telefono
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.telefono
                  }
                  label="Teléfono *"
                  name="principalAccionista.conyuge.generales.telefono"
                  value={
                    formik.values?.principalAccionista?.conyuge?.generales
                      ?.telefono
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales
                        ?.telefono
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.generales
                        ?.telefono;
                      formik.setErrors(newErrors);
                    }

                    let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                      e.target.value,
                      formik.values?.principalAccionista?.conyuge?.generales
                        ?.telefono
                    );
                    formik.setFieldValue(
                      "principalAccionista.conyuge.generales.telefono",
                      numTelefono
                    );
                  }}
                  type="tel"
                  InputProps={{ maxLength: 12 }}
                />
                <InputWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.celular
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.celular
                  }
                  label="Celular *"
                  name="principalAccionista.conyuge.generales.celular"
                  value={
                    formik.values?.principalAccionista?.conyuge?.generales
                      ?.celular
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales
                        ?.celular
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.generales
                        ?.celular;
                      formik.setErrors(newErrors);
                    }

                    let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                      e.target.value,
                      formik.values?.principalAccionista?.conyuge?.generales
                        ?.celular
                    );
                    formik.setFieldValue(
                      "principalAccionista.conyuge.generales.celular",
                      numTelefono
                    );
                  }}
                  type="tel"
                  InputProps={{ maxLength: 12 }}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.dependientesEconomicosMenores23
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.dependientesEconomicosMenores23
                  }
                  label="Cantidad de dependientes menores de 23 años *"
                  name="principalAccionista.conyuge.generales.dependientesEconomicosMenores23"
                  value={{
                    label:
                      formik?.values?.dataObj
                        ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion,
                    value:
                      formik?.values?.dataObj
                        ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales
                        ?.dependientesEconomicosMenores23
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.generales
                        ?.dependientesEconomicosMenores23;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "principalAccionista.conyuge.generales.dependientesEconomicosMenores23",
                      e.id
                    );
                    formik.setFieldValue(
                      "dataObj.dependientesEconomicosMenores23Obj",
                      [e]
                    );
                    setTotalDependientesMenores23(e.descripcion);
                  }}
                  options={axiosData?.generales?.dependientesMenores23Anios?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.descripcion,
                        value: item.descripcion,
                      };
                    }
                  )}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.dependientesEconomicos
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.generales
                      ?.dependientesEconomicos
                  }
                  name="principalAccionista.conyuge.generales.dependientesEconomicos"
                  value={{
                    label:
                      formik?.values?.dataObj?.dependientesEconomicosObj?.[0]
                        ?.descripcion,
                    value:
                      formik?.values?.dataObj?.dependientesEconomicosObj?.[0]
                        ?.descripcion,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.generales
                        ?.dependientesEconomicos
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.generales
                        ?.dependientesEconomicos;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "principalAccionista.conyuge.generales.dependientesEconomicos",
                      e.id
                    );
                    formik.setFieldValue("dataObj.dependientesEconomicosObj", [
                      e,
                    ]);
                    setTotalDependientes(e.descripcion);
                  }}
                  label="Cantidad total de dependientes *"
                  options={axiosData?.generales?.dependientes?.map((item) => {
                    return {
                      ...item,
                      label: item.descripcion,
                      value: item.descripcion,
                    };
                  })}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "25px",
                  }}
                >
                  <div
                    style={{
                      display: window.innerWidth < 700 && "flex",
                    }}
                  >
                    <CheckBoxInput
                      name="principalAccionista.conyuge.generales.esAccionistaOtraEmpresa"
                      state=""
                      checked={
                        formik.values?.principalAccionista?.conyuge?.generales
                          ?.esAccionistaOtraEmpresa
                      }
                      onChange={(e) =>
                        formik.setFieldValue(
                          "principalAccionista.conyuge.generales.esAccionistaOtraEmpresa",
                          !formik.values?.principalAccionista?.conyuge
                            ?.generales?.esAccionistaOtraEmpresa
                        )
                      }
                      typeOfCheck="normal"
                      id="obligadoSolidarioOtraEmpresa"
                    />
                    <label
                      className="form-check-label lable-margin"
                      htmlFor="obligadoSolidarioOtraEmpresa"
                    >
                      El obligado es accionista u obligado de otra empresa
                    </label>
                  </div>
                  {formik.values?.principalAccionista?.conyuge?.generales
                    ?.esAccionistaOtraEmpresa && (
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <InputWithError
                        name="principalAccionista.conyuge.generales.nombreOtraEmpresa"
                        value={
                          formik.values?.principalAccionista?.conyuge?.generales
                            ?.nombreOtraEmpresa
                            ? formik.values?.principalAccionista?.conyuge
                                ?.generales?.nombreOtraEmpresa
                            : ""
                        }
                        onChange={(e) => {
                          let newErrors = formik.errors;
                          if (
                            newErrors?.principalAccionista !== undefined &&
                            newErrors?.principalAccionista?.conyuge !==
                              undefined &&
                            newErrors?.principalAccionista?.conyuge
                              ?.generales !== undefined &&
                            newErrors?.principalAccionista?.conyuge?.generales
                              ?.nombreOtraEmpresa
                          ) {
                            delete newErrors?.principalAccionista?.conyuge
                              ?.generales?.nombreOtraEmpresa;
                            formik.setErrors(newErrors);
                          }

                          if (validarTextoConEnies(e.target.value, "text")) {
                            formik.setFieldValue(
                              "principalAccionista.conyuge.generales.nombreOtraEmpresa",
                              e.target.value
                            );
                          }
                        }}
                        label="Nombre de la empresa o PFAE *"
                        InputProps={{ maxLength: 180 }}
                        showErrorMessage={
                          formik.errors?.principalAccionista?.conyuge?.generales
                            ?.nombreOtraEmpresa
                        }
                        errorMessage={
                          formik.errors?.principalAccionista?.conyuge?.generales
                            ?.nombreOtraEmpresa
                        }
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: window.innerWidth < 700 && "flex",
                    }}
                  >
                    <CheckBoxInput
                      name="principalAccionista.conyuge.generales.esObligadoSolidarioOtraEmpresaPfae"
                      state=""
                      checked={
                        formik.values?.principalAccionista?.conyuge?.generales
                          ?.esObligadoSolidarioOtraEmpresaPfae
                      }
                      onChange={(e) =>
                        formik.setFieldValue(
                          "principalAccionista.conyuge.generales.esObligadoSolidarioOtraEmpresaPfae",
                          !formik.values?.principalAccionista?.conyuge
                            ?.generales?.esObligadoSolidarioOtraEmpresaPfae
                        )
                      }
                      typeOfCheck="normal"
                      id="obligadoSolidarioOtraEmpresaPfae"
                    />
                    <label
                      className="form-check-label lable-margin"
                      htmlFor="obligadoSolidarioOtraEmpresaPfae"
                    >
                      Es obligado o fiador de otra empresa o PFAE
                    </label>
                  </div>
                  {formik.values?.principalAccionista?.conyuge?.generales
                    ?.esObligadoSolidarioOtraEmpresaPfae && (
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <InputWithError
                        name="principalAccionista.conyuge.generales.nombreOtraEmpresaPfae"
                        label="Nombre de la empresa o PFAE *"
                        value={
                          formik.values?.principalAccionista?.conyuge?.generales
                            ?.nombreOtraEmpresaPfae
                            ? formik.values?.principalAccionista?.conyuge
                                ?.generales?.nombreOtraEmpresaPfae
                            : ""
                        }
                        onChange={(e) => {
                          let newErrors = formik.errors;
                          if (
                            newErrors?.principalAccionista !== undefined &&
                            newErrors?.principalAccionista?.conyuge !==
                              undefined &&
                            newErrors?.principalAccionista?.conyuge
                              ?.generales !== undefined &&
                            newErrors?.principalAccionista?.conyuge?.generales
                              ?.nombreOtraEmpresaPfae
                          ) {
                            delete newErrors?.principalAccionista?.conyuge
                              ?.generales?.nombreOtraEmpresaPfae;
                            formik.setErrors(newErrors);
                          }

                          if (validarTextoConEnies(e.target.value, "text")) {
                            formik.setFieldValue(
                              "principalAccionista.conyuge.generales.nombreOtraEmpresaPfae",
                              e.target.value
                            );
                          }
                        }}
                        InputProps={{ maxLength: 180 }}
                        showErrorMessage={
                          formik.errors?.principalAccionista?.conyuge?.generales
                            ?.nombreOtraEmpresaPfae
                        }
                        errorMessage={
                          formik.errors?.principalAccionista?.conyuge?.generales
                            ?.nombreOtraEmpresaPfae
                        }
                      />
                    </div>
                  )}
                </div>
                <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3">
                  Información de domicilio
                </h2>
                <InputWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.calle
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.calle
                  }
                  label="Calle *"
                  name="principalAccionista.conyuge.direccion.calle"
                  value={
                    formik.values?.principalAccionista?.conyuge?.direccion
                      ?.calle
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion?.calle
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.direccion
                        ?.calle;
                      formik.setErrors(newErrors);
                    }

                    if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                      formik.setFieldValue(
                        "principalAccionista.conyuge.direccion.calle",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 24 }}
                />
                <InputWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.numeroExterior
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.numeroExterior
                  }
                  label="Número exterior *"
                  name="principalAccionista.conyuge.direccion.numeroExterior"
                  value={
                    formik.values?.principalAccionista?.conyuge?.direccion
                      ?.numeroExterior
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion
                        ?.numeroExterior
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.direccion
                        ?.numeroExterior;
                      formik.setErrors(newErrors);
                    }

                    if (notSpecialCaractersConGuion(e.target.value)) {
                      formik.setFieldValue(
                        "principalAccionista.conyuge.direccion.numeroExterior",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 7 }}
                />
                <InputWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.numeroInterior
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.numeroInterior
                  }
                  label="Número interior"
                  name="principalAccionista.conyuge.direccion.numeroInterior"
                  value={
                    formik.values?.principalAccionista?.conyuge?.direccion
                      ?.numeroInterior
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion
                        ?.numeroInterior
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.direccion
                        ?.numeroInterior;
                      formik.setErrors(newErrors);
                    }

                    if (validateValue(e.target.value, "alphanumeric")) {
                      formik.setFieldValue(
                        "principalAccionista.conyuge.direccion.numeroInterior",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 2 }}
                  tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu “Número exterior” separados por un guion Ejemplo: 318-123B"
                  required={
                    formik.values.principalAccionista.conyuge.direccion
                      .tipoViviendaId === parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                    formik.values.principalAccionista.conyuge.direccion
                      .tipoViviendaId ===
                      parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
                  }
                />
                <InputWithError
                  required={
                    formik.values.principalAccionista.conyuge.direccion
                      .tipoViviendaId === parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                    formik.values.principalAccionista.conyuge.direccion
                      .tipoViviendaId ===
                      parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
                  }
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion?.piso
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion?.piso
                  }
                  label="Piso"
                  name="principalAccionista.conyuge.direccion.piso"
                  value={
                    formik.values?.principalAccionista?.conyuge?.direccion?.piso
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion?.piso
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.direccion
                        ?.piso;
                      formik.setErrors(newErrors);
                    }

                    if (validateValue(e.target.value, "alphanumeric")) {
                      formik.setFieldValue(
                        "principalAccionista.conyuge.direccion.piso",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 2 }}
                />
                <InputWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.codigoPostal
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.codigoPostal
                  }
                  name="principalAccionista.conyuge.direccion.codigoPostal"
                  value={
                    formik.values?.principalAccionista?.conyuge?.direccion
                      ?.codigoPostal
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion
                        ?.codigoPostal
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.direccion
                        ?.codigoPostal;
                      formik.setErrors(newErrors);
                    }

                    if (
                      e.target.value.length <= 5 &&
                      validateValue(e.target.value, "number")
                    ) {
                      formik.setFieldValue(
                        "principalAccionista.conyuge.direccion.codigoPostal",
                        e.target.value
                      );
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value.length === 5) {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista?.conyuge !== undefined &&
                        newErrors?.principalAccionista?.conyuge?.direccion !==
                          undefined
                      ) {
                        if (
                          newErrors?.principalAccionista?.conyuge?.direccion
                            ?.colonia
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.direccion?.colonia;
                        }
                        if (
                          newErrors?.principalAccionista?.conyuge?.direccion
                            ?.municipioId
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.direccion?.municipioId;
                        }
                        if (
                          newErrors?.principalAccionista?.conyuge?.direccion
                            ?.estadoId
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.direccion?.estadoId;
                        }
                        formik.setErrors(newErrors);
                      }

                      obtenerDireccionByCp(e.target.value);
                    } else {
                      limpiarDireccionByCp();
                    }
                  }}
                  InputProps={{ maxLength: 5 }}
                  label="CP *"
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.colonia
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.colonia
                  }
                  name="principalAccionista.conyuge.direccion.colonia"
                  value={{
                    label:
                      formik?.values?.principalAccionista?.conyuge?.direccion
                        ?.colonia,
                    value:
                      formik?.values?.principalAccionista?.conyuge?.direccion
                        ?.colonia,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "principalAccionista.conyuge.direccion.colonia",
                      e.label
                    );
                  }}
                  label="Colonia *"
                  options={coloniasCP?.map((item) => {
                    return {
                      ...item,
                      label: item,
                      value: item,
                    };
                  })}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.municipioId
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.municipioId
                  }
                  name="principalAccionista.conyuge.direccion.municipioId"
                  value={{
                    label: formik?.values?.dataObj?.municipioObj?.[0]?.nombre,
                    value: formik?.values?.dataObj?.municipioObj?.[0]?.nombre,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "principalAccionista.conyuge.direccion.municipioId",
                      e.id
                    );
                    formik.setFieldValue("dataObj.municipioObj", [e]);
                  }}
                  isDisabled={true}
                  placeholder="Delegación/Municipio *"
                  label="Delegación/Municipio *"
                  options={axiosData?.generales?.municipios?.map((item) => {
                    return {
                      ...item,
                      label: item.nombre,
                      value: item.nombre,
                    };
                  })}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.estadoId
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.estadoId
                  }
                  name="principalAccionista.conyuge.direccion.estadoId"
                  value={{
                    label: formik?.values?.dataObj?.estadoObj?.[0]?.nombre,
                    value: formik?.values?.dataObj?.estadoObj?.[0]?.nombre,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "principalAccionista.conyuge.direccion.estadoId",
                      e.id
                    );
                    formik.setFieldValue("dataObj.estadoObj", [e]);
                  }}
                  placeholder="Estado *"
                  label="Estado *"
                  isDisabled={true}
                  options={axiosData?.generales?.estados?.map((item) => {
                    return {
                      ...item,
                      label: item.nombre,
                      value: item.nombre,
                    };
                  })}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.tipoDomicilioId
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.tipoDomicilioId
                  }
                  name="principalAccionista.conyuge.direccion.tipoDomicilioId"
                  value={{
                    label:
                      formik?.values?.dataObj?.tipoDomicilioObj?.[0]?.nombre,
                    value:
                      formik?.values?.dataObj?.tipoDomicilioObj?.[0]?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion
                        ?.tipoDomicilioId
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.direccion
                        ?.tipoDomicilioId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "principalAccionista.conyuge.direccion.tipoDomicilioId",
                      e.id
                    );
                    formik.setFieldValue("dataObj.tipoDomicilioObj", [e]);
                  }}
                  label="Tipo de domicilio *"
                  options={axiosData?.domicilio?.tipoDeDomicilioOpciones?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.nombre,
                        value: item.nombre,
                      };
                    }
                  )}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.tipoViviendaId
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.conyuge?.direccion
                      ?.tipoViviendaId
                  }
                  isSearchable={false}
                  name="principalAccionista.conyuge.direccion.tipoViviendaId"
                  value={{
                    label:
                      formik?.values?.dataObj?.tipoViviendaObj?.[0]?.nombre,
                    value:
                      formik?.values?.dataObj?.tipoViviendaObj?.[0]?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista?.conyuge !== undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion !==
                        undefined &&
                      newErrors?.principalAccionista?.conyuge?.direccion
                        ?.tipoViviendaId
                    ) {
                      delete newErrors?.principalAccionista?.conyuge?.direccion
                        ?.tipoViviendaId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "principalAccionista.conyuge.direccion.tipoViviendaId",
                      e.id
                    );
                    formik.setFieldValue("dataObj.tipoViviendaObj", [e]);
                  }}
                  label="Tipo de vivienda *"
                  options={axiosData?.domicilio?.tipoDeViviendaOpciones?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.nombre,
                        value: item.nombre,
                      };
                    }
                  )}
                />
                <div
                  style={{
                    width: innerWidth < 700 ? "100%" : "55%",
                    minWidth: "290px",
                    maxWidth: "425px",
                  }}
                >
                  <span>Antigüedad en el domicilio actual *</span>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "10px",
                      flexDirection: innerWidth < 700 ? "column" : "row",
                    }}
                  >
                    <div
                      style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                      className="selectInputCont"
                    >
                      {formik.values?.principalAccionista?.conyuge?.direccion
                        ?.tiempoViviendaAnios ? (
                        <span className="select-label-no-null">Años *</span>
                      ) : (
                        <span className="select-label">Años *</span>
                      )}
                      <Select
                        isSearchable={false}
                        styles={selectStylesDouble}
                        menuPlacement="top"
                        name="principalAccionista.conyuge.direccion.tiempoViviendaAnios"
                        value={{
                          label:
                            formik.values?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaAnios,
                          value:
                            formik.values?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaAnios,
                        }}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "principalAccionista.conyuge.direccion.tiempoViviendaAnios",
                            e.value
                          )
                        }
                        placeholder="Años *"
                        options={aniosDomicilio?.map((el, index) => {
                          return { label: index, value: index };
                        })}
                      />
                    </div>
                    <div
                      style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                      className="selectInputCont"
                    >
                      {formik.values?.principalAccionista?.conyuge?.direccion
                        ?.tiempoViviendaMeses ? (
                        <span className="select-label-no-null">Meses *</span>
                      ) : (
                        <span className="select-label">Meses *</span>
                      )}
                      <Select
                        isSearchable={false}
                        styles={selectStylesDouble}
                        menuPlacement="top"
                        name="principalAccionista.conyuge.direccion.tiempoViviendaMeses"
                        value={{
                          label:
                            formik.values?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaMeses,
                          value:
                            formik.values?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaMeses,
                        }}
                        onChange={(e) => {
                          let newErrors = formik.errors;
                          if (
                            newErrors?.principalAccionista !== undefined &&
                            newErrors?.principalAccionista?.conyuge !==
                              undefined &&
                            newErrors?.principalAccionista?.conyuge
                              ?.direccion !== undefined &&
                            newErrors?.principalAccionista?.conyuge?.direccion
                              ?.tiempoViviendaMeses
                          ) {
                            delete newErrors?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaMeses;
                            formik.setErrors(newErrors);
                          }

                          formik.setFieldValue(
                            "principalAccionista.conyuge.direccion.tiempoViviendaMeses",
                            e.value
                          );
                        }}
                        placeholder="Meses *"
                        options={mesesDomicilo?.map((el, index) => {
                          return { label: index, value: index };
                        })}
                      />
                    </div>
                  </div>
                  {formik.errors?.principalAccionista?.conyuge?.direccion
                    ?.tiempoViviendaMeses && (
                    <ErrorMessage
                      message={
                        formik.errors?.principalAccionista?.conyuge?.direccion
                          ?.tiempoViviendaMeses
                      }
                      width={55}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: innerWidth < 700 ? "100%" : "55%",
                    minWidth: "290px",
                    maxWidth: "425px",
                  }}
                >
                  <label>Antigüedad en el domicilio anterior *</label>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "10px",
                      flexDirection: innerWidth < 700 ? "column" : "row",
                    }}
                  >
                    <div
                      style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                      className="selectInputCont"
                    >
                      {formik.values?.principalAccionista?.conyuge?.direccion
                        ?.tiempoViviendaAntAnios ? (
                        <span className="select-label-no-null">Años *</span>
                      ) : (
                        <span className="select-label">Años *</span>
                      )}
                      <Select
                        isSearchable={false}
                        styles={selectStylesDouble}
                        menuPlacement="top"
                        name="principalAccionista.conyuge.direccion.tiempoViviendaAntAnios"
                        value={{
                          label:
                            formik.values?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaAntAnios,
                          value:
                            formik.values?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaAntAnios,
                        }}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "principalAccionista.conyuge.direccion.tiempoViviendaAntAnios",
                            e.value
                          )
                        }
                        placeholder="años *"
                        options={mesesDomicilo?.map((el, index) => {
                          return { label: index, value: index };
                        })}
                      />
                    </div>
                    <div
                      style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                      className="selectInputCont"
                    >
                      {formik.values?.principalAccionista?.conyuge?.direccion
                        ?.tiempoViviendaAntMeses ? (
                        <span className="select-label-no-null">Meses *</span>
                      ) : (
                        <span className="select-label">Meses *</span>
                      )}
                      <Select
                        isSearchable={false}
                        styles={selectStylesDouble}
                        menuPlacement="top"
                        name="principalAccionista.conyuge.direccion.tiempoViviendaAntMeses"
                        value={{
                          label:
                            formik.values?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaAntMeses,
                          value:
                            formik.values?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaAntMeses,
                        }}
                        onChange={(e) => {
                          let newErrors = formik.errors;
                          if (
                            newErrors?.principalAccionista !== undefined &&
                            newErrors?.principalAccionista?.conyuge !==
                              undefined &&
                            newErrors?.principalAccionista?.conyuge
                              ?.direccion !== undefined &&
                            newErrors?.principalAccionista?.conyuge?.direccion
                              ?.tiempoViviendaAntMeses
                          ) {
                            delete newErrors?.principalAccionista?.conyuge
                              ?.direccion?.tiempoViviendaAntMeses;
                            formik.setErrors(newErrors);
                          }

                          formik.setFieldValue(
                            "principalAccionista.conyuge.direccion.tiempoViviendaAntMeses",
                            e.value
                          );
                        }}
                        placeholder="Meses *"
                        options={mesesDomicilo?.map((el, index) => {
                          return { label: index, value: index };
                        })}
                      />
                    </div>
                  </div>
                  {formik.errors?.principalAccionista?.conyuge?.direccion
                    ?.tiempoViviendaAntMeses && (
                    <ErrorMessage
                      message={
                        formik.errors?.principalAccionista?.conyuge?.direccion
                          ?.tiempoViviendaAntMeses
                      }
                      width={55}
                    />
                  )}
                </div>
                {idTipoPersonaPAConyuge === ID_PFAE && (
                  <>
                    <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3">
                      Información del negocio
                    </h2>
                    <SelectWithError
                      showErrorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.importaExportaId
                      }
                      errorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.importaExportaId
                      }
                      label="¿Realizas actividades de importación o exportación? *"
                      name="principalAccionista.conyuge.datosDelNegocio.importaExportaId"
                      value={{
                        label:
                          formik?.values?.dataObj?.importaExportaObj?.[0]
                            ?.nombre,
                        value:
                          formik?.values?.dataObj?.importaExportaObj?.[0]
                            ?.nombre,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.principalAccionista !== undefined &&
                          newErrors?.principalAccionista?.conyuge !==
                            undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio !== undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.importaExportaId
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.importaExportaId;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "principalAccionista.conyuge.datosDelNegocio.importaExportaId",
                          e.id
                        );
                        formik.setFieldValue("dataObj.importaExportaObj", [e]);
                      }}
                      options={axiosData?.domicilioFiscal?.importaExportaOpciones?.map(
                        (item) => {
                          return {
                            ...item,
                            label: item.nombre,
                            value: item.nombre,
                          };
                        }
                      )}
                    />
                    <SelectWithErrorTooltipCompuesto
                      showErrorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.giroEmpresarialId
                      }
                      errorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.giroEmpresarialId
                      }
                      name="principalAccionista.conyuge.datosDelNegocio.giroEmpresarialId"
                      value={{
                        label:
                          formik?.values?.dataObj?.giroEmpresarialObj?.[0]
                            ?.nombre,
                        value:
                          formik?.values?.dataObj?.giroEmpresarialObj?.[0]
                            ?.nombre,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.principalAccionista !== undefined &&
                          newErrors?.principalAccionista?.conyuge !==
                            undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio !== undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.giroEmpresarialId
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.giroEmpresarialId;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "principalAccionista.conyuge.datosDelNegocio.giroEmpresarialId",
                          e.id
                        );
                        formik.setFieldValue("dataObj.giroEmpresarialObj", [e]);
                      }}
                      label="Giro *"
                      options={axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.map(
                        (item) => {
                          return {
                            ...item,
                            label: item.nombre,
                            value: item.nombre,
                          };
                        }
                      )}
                      tooltipA="Comercio: Supermercados, tiendas, farmacias, ferreterías, etc."
                      tooltipB="Industria: Manufacturera, constructoras, textil, alimenticia, etc."
                      tooltipC="Servicios: Escuelas, hospitales, despachos, transporte, médicos, dentista, restaurantes, etc."
                    />
                    <SelectWithError
                      showErrorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.sectorId
                      }
                      errorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.sectorId
                      }
                      name="principalAccionista.conyuge.datosDelNegocio.sectorId"
                      value={{
                        label: formik?.values?.dataObj?.sectorObj?.[0]?.nombre,
                        value: formik?.values?.dataObj?.sectorObj?.[0]?.nombre,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.principalAccionista !== undefined &&
                          newErrors?.principalAccionista?.conyuge !==
                            undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio !== undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.sectorId
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.sectorId;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "principalAccionista.conyuge.datosDelNegocio.sectorId",
                          e.id
                        );
                        formik.setFieldValue("dataObj.sectorObj", [e]);
                      }}
                      label="Sector *"
                      options={axiosData?.domicilioFiscal?.sectorOpciones?.map(
                        (item) => {
                          return {
                            ...item,
                            label: item.nombre,
                            value: item.nombre,
                          };
                        }
                      )}
                    />
                    <SelectWithErrorActividad
                      menuPlacement="top"
                      showErrorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.actividadId
                      }
                      errorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.actividadId
                      }
                      name="principalAccionista.conyuge.datosDelNegocio.actividadId"
                      value={{
                        label:
                          formik?.values?.dataObj?.actividadObj?.[0]
                            ?.detalleClave,
                        value:
                          formik?.values?.dataObj?.actividadObj?.[0]
                            ?.detalleClave,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.principalAccionista !== undefined &&
                          newErrors?.principalAccionista?.conyuge !==
                            undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio !== undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.actividadId
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.actividadId;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "principalAccionista.conyuge.datosDelNegocio.actividadId",
                          e.id
                        );
                        formik.setFieldValue("dataObj.actividadObj", [e]);
                      }}
                      label="Actividad *"
                      options={axiosData?.domicilioFiscal?.actividadOpciones?.map(
                        (item) => {
                          return {
                            ...item,
                            label: item.detalleClave,
                            value: item.detalleClave,
                          };
                        }
                      )}
                      tooltip="Selecciona la actividad que mas se asemeje a tu alta en hacienda. En caso de que tengas varias actividades, selecciona la preponderante."
                    />
                    <InputWithError
                      showErrorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.fechaAltaShcp
                      }
                      errorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.fechaAltaShcp
                      }
                      name="principalAccionista.conyuge.datosDelNegocio.fechaAltaShcp"
                      locale="es"
                      value={startDateFechaSHCP}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.principalAccionista !== undefined &&
                          newErrors?.principalAccionista?.conyuge !==
                            undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio !== undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.fechaAltaShcp
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.fechaAltaShcp;
                          formik.setErrors(newErrors);
                        }

                        let array = e.target.value
                          .split("-")
                          .reverse()
                          .join("-");
                        formik.setFieldValue(
                          "principalAccionista.conyuge.datosDelNegocio.fechaAltaShcp",
                          array
                        );
                        setStartDateFechaSHCP(e.target.value);
                      }}
                      label="Fecha de alta en SHCP *"
                      InputProps={{ shrink: true, max: fechaActualYYYMMDD() }}
                      type="date"
                    />
                    <InputWithError
                      showErrorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.numeroSucursales
                      }
                      errorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.numeroSucursales
                      }
                      name="principalAccionista.conyuge.datosDelNegocio.numeroSucursales"
                      value={
                        formik.values?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.numeroSucursales
                      }
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.principalAccionista !== undefined &&
                          newErrors?.principalAccionista?.conyuge !==
                            undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio !== undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.numeroSucursales
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.numeroSucursales;
                          formik.setErrors(newErrors);
                        }

                        if (validateValue(e.target.value, "number")) {
                          formik.setFieldValue(
                            "principalAccionista.conyuge.datosDelNegocio.numeroSucursales",
                            e.target.value
                          );
                        }
                      }}
                      label="Número de sucursales *"
                    />
                    <SelectWithError
                      showErrorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.aniosExperienciaSector
                      }
                      errorMessage={
                        formik.errors?.principalAccionista?.conyuge
                          ?.datosDelNegocio?.aniosExperienciaSector
                      }
                      label="Años de experiencia en el sector *"
                      name="principalAccionista.conyuge.datosDelNegocio.aniosExperienciaSector"
                      value={{
                        label:
                          formik?.values?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.aniosExperienciaSector,
                        value:
                          formik?.values?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.aniosExperienciaSector,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.principalAccionista !== undefined &&
                          newErrors?.principalAccionista?.conyuge !==
                            undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio !== undefined &&
                          newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.aniosExperienciaSector
                        ) {
                          delete newErrors?.principalAccionista?.conyuge
                            ?.datosDelNegocio?.aniosExperienciaSector;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "principalAccionista.conyuge.datosDelNegocio.aniosExperienciaSector",
                          e.label
                        );
                      }}
                      menuPlacement="top"
                      options={anios?.map((item, index) => {
                        return {
                          ...item,
                          label: index + 1,
                          value: index + 1,
                        };
                      })}
                    />
                  </>
                )}
              </>
            )}
          </FormLayout>
        </div>
        <div className="button-siguiente" style={{ margin: "2rem 0" }}>
          <button
            className="button-secondary"
            onClick={(e) => {
              e.preventDefault();
              handleRegresarSeccionPyme(SECCION_PA_CONYUGE_PM);
            }}
          >
            Atrás
          </button>
          <button
            className="button-primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Siguiente
          </button>
        </div>
      </form>
    </>
  );
};

export default DatosPrincipalAccionistaConyuge;

function initialValues(
  modeloPm,
  idTipoPersona,
  esObligadoSolidario,
  axiosData
) {
  if (
    modeloPm?.principalAccionista?.idPrincipalAccionista != null &&
    modeloPm.principalAccionista?.conyuge?.idConyuge !== null &&
    modeloPm.principalAccionista?.conyuge?.generales !== null
  ) {
    return {
      idCliente: modeloPm.idCliente,
      principalAccionista: {
        idPrincipalAccionista:
          modeloPm?.principalAccionista?.idPrincipalAccionista,
        conyuge: modeloPm?.principalAccionista?.conyuge,
      },
      dataObj: {
        paisNacimientoObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.paisNacimientoId
          ? axiosData?.generales?.paises?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.paisNacimientoId
            )
          : "",
        estadoNacimientoObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.estadoNacimientoId
          ? axiosData?.generales?.estados?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.estadoNacimientoId
            )
          : "",
        nacionalidadObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.nacionalidadId
          ? axiosData?.generales?.nacionalidades?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.nacionalidadId
            )
          : "",
        estadoCivilObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.estadoCivilId
          ? axiosData?.generales?.estadoCivilOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales?.estadoCivilId
            )
          : "",
        dependientesEconomicosMenores23Obj: modeloPm?.principalAccionista
          ?.generales?.dependientesEconomicosMenores23
          ? axiosData?.generales?.dependientesMenores23Anios?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.dependientesEconomicosMenores23
            )
          : "",
        dependientesEconomicosObj: modeloPm?.principalAccionista?.conyuge
          ?.generales?.dependientesEconomicos
          ? axiosData?.generales?.dependientes?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.dependientesEconomicos
            )
          : "",
        nivelEducativoObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.nivelEducativoId
          ? axiosData?.generales?.nivelEducativoOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.nivelEducativoId
            )
          : "",
        municipioObj: modeloPm?.principalAccionista?.conyuge?.direccion
          ?.municipioId
          ? axiosData?.generales?.municipios?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.direccion?.municipioId
            )
          : "",
        estadoObj: modeloPm?.principalAccionista?.conyuge?.direccion?.estadoId
          ? axiosData?.generales?.estados?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.direccion?.estadoId
            )
          : "",
        tipoDomicilioObj: modeloPm?.principalAccionista?.conyuge?.direccion
          ?.tipoDomicilioId
          ? axiosData?.domicilio?.tipoDeDomicilioOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.direccion
                  ?.tipoDomicilioId
            )
          : "",
        tipoViviendaObj: modeloPm?.principalAccionista?.conyuge?.direccion
          ?.tipoViviendaId
          ? axiosData?.domicilio?.tipoDeViviendaOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.direccion
                  ?.tipoViviendaId
            )
          : "",
        importaExportaObj: modeloPm?.principalAccionista?.conyuge
          ?.datosDelNegocio?.importaExportaId
          ? axiosData?.domicilioFiscal?.importaExportaOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
                  ?.importaExportaId
            )
          : "",
        sectorObj: modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
          ?.sectorId
          ? axiosData?.domicilioFiscal?.sectorOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
                  ?.sectorId
            )
          : "",
        giroEmpresarialObj: modeloPm?.principalAccionista?.conyuge
          ?.datosDelNegocio?.giroEmpresarialId
          ? axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
                  ?.giroEmpresarialId
            )
          : "",
        actividadObj: modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
          ?.actividadId
          ? axiosData?.domicilioFiscal?.actividadOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
                  ?.actividadId
            )
          : "",
      },
    };
  } else {
    return {
      idCliente: modeloPm.idCliente,
      principalAccionista: {
        idPrincipalAccionista:
          modeloPm.principalAccionista.idPrincipalAccionista,
        conyuge: {
          generales: {
            tipoPersonaId: idTipoPersona,
            esObligadoSolidario: esObligadoSolidario,
            relacionRamaGobierno: false,
            paisResidenciaFiscal2: false,
            residenciaUsa: false,
            esAccionistaOtraEmpresa: false,
            esObligadoSolidarioOtraEmpresaPfae: false,
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            fechaNacimiento: "",
            paisNacimientoId: "",
            estadoNacimientoId: "",
            generoId: "",
            calidadMigratoria: "",
            nacionalidadId: "",
            rfc: "",
            curp: "",
            folioVerificacionId: "",
            estadoCivilId: "",
            nivelEducativoId: "",
            profesion: "",
            correoElectronico: "",
            celular: "",
            dependientesEconomicosMenores23: "",
            dependientesEconomicos: "",
            numeroIdFiscal2: "",
            paisResidenciaFiscal2Id: "",
            nombreOtraEmpresa: "",
            nombreOtraEmpresaPfae: "",
          },
          direccion: {
            ineCertificada: true,
            aplicaIneComprobante: true,
            calle: "",
            numeroExterior: "",
            numeroInterior: "",
            piso: "",
            paisId: ID_PAIS_MEXICO,
            codigoPostal: "",
            colonia: "",
            tipoDomicilioId: "",
            tipoViviendaId: "",
            tiempoViviendaAnios: "",
            tiempoViviendaMeses: "",
            tiempoViviendaAntAnios: "",
            tiempoViviendaAntMeses: "",
          },
          datosDelNegocio: {
            importaExportaId: "",
            sectorId: "",
            giroEmpresarialId: "",
            actividadId: "",
            aniosExperienciaSector: "",
            numeroSucursales: "",
            fechaAltaShcp: "",
          },
        },
      },
      dataObj: {
        paisNacimientoObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.paisNacimientoId
          ? axiosData?.generales?.paises?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.paisNacimientoId
            )
          : "",
        estadoNacimientoObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.estadoNacimientoId
          ? axiosData?.generales?.estados?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.estadoNacimientoId
            )
          : "",
        nacionalidadObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.nacionalidadId
          ? axiosData?.generales?.nacionalidades?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.nacionalidadId
            )
          : "",
        estadoCivilObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.estadoCivilId
          ? axiosData?.generales?.estadoCivilOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales?.estadoCivilId
            )
          : "",
        dependientesEconomicosMenores23Obj: modeloPm?.principalAccionista
          ?.generales?.dependientesEconomicosMenores23
          ? axiosData?.generales?.dependientesMenores23Anios?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.dependientesEconomicosMenores23
            )
          : "",
        dependientesEconomicosObj: modeloPm?.principalAccionista?.conyuge
          ?.generales?.dependientesEconomicos
          ? axiosData?.generales?.dependientes?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.dependientesEconomicos
            )
          : "",
        nivelEducativoObj: modeloPm?.principalAccionista?.conyuge?.generales
          ?.nivelEducativoId
          ? axiosData?.generales?.nivelEducativoOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.generales
                  ?.nivelEducativoId
            )
          : "",
        municipioObj: modeloPm?.principalAccionista?.conyuge?.direccion
          ?.municipioId
          ? axiosData?.generales?.municipios?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.direccion?.municipioId
            )
          : "",
        estadoObj: modeloPm?.principalAccionista?.conyuge?.direccion?.estadoId
          ? axiosData?.generales?.estados?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.direccion?.estadoId
            )
          : "",
        tipoDomicilioObj: modeloPm?.principalAccionista?.conyuge?.direccion
          ?.tipoDomicilioId
          ? axiosData?.domicilio?.tipoDeDomicilioOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.direccion
                  ?.tipoDomicilioId
            )
          : "",
        tipoViviendaObj: modeloPm?.principalAccionista?.conyuge?.direccion
          ?.tipoViviendaId
          ? axiosData?.domicilio?.tipoDeViviendaOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.direccion
                  ?.tipoViviendaId
            )
          : "",
        importaExportaObj: modeloPm?.principalAccionista?.conyuge
          ?.datosDelNegocio?.importaExportaId
          ? axiosData?.domicilioFiscal?.importaExportaOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
                  ?.importaExportaId
            )
          : "",
        sectorObj: modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
          ?.sectorId
          ? axiosData?.domicilioFiscal?.sectorOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
                  ?.sectorId
            )
          : "",
        giroEmpresarialObj: modeloPm?.principalAccionista?.conyuge
          ?.datosDelNegocio?.giroEmpresarialId
          ? axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
                  ?.giroEmpresarialId
            )
          : "",
        actividadObj: modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
          ?.actividadId
          ? axiosData?.domicilioFiscal?.actividadOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.conyuge?.datosDelNegocio
                  ?.actividadId
            )
          : "",
      },
    };
  }
}

function validationSchema(
  esObligadoSolidario,
  idTipoPersonaPAConyuge,
  totalDependientesMenores23,
  totalDependientes
) {
  if (esObligadoSolidario === true) {
    if (idTipoPersonaPAConyuge === ID_PFAE) {
      return {
        principalAccionista: Yup.object().shape({
          conyuge: Yup.object().shape({
            generales: Yup.object().shape({
              nombre: Yup.string()
                .required("Dato requerido.")
                .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
              apellidoPaterno: Yup.string()
                .required("Dato requerido.")
                .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
              apellidoMaterno: Yup.string().matches(
                /^[aA-zZÑñ\s]+$/,
                "Solo se permiten letras "
              ),
              fechaNacimiento: Yup.string()
                .required("Dato requerido.")
                .test(
                  "fechaNacimiento",
                  "Debe ser mayor a 18 años y menor a 70 años.",
                  (value) =>
                    compareDatesOrigin(
                      fechaMaxMayorEdadYYYMMDD(),
                      fechaMinEdadYYYMMDD(),
                      value
                    )
                ),
              paisNacimientoId: Yup.number().required("Dato requerido."),
              estadoNacimientoId: Yup.string().when("paisNacimientoId", {
                is: (value) => value === ID_PAIS_MEXICO,
                then: Yup.string().required("Dato requerido."),
              }),
              generoId: Yup.number().required("Dato requerido."),
              rfc: Yup.string()
                .required("Dato requerido.")
                .test("rfc", "RFC inválido.", (item, testContext) => {
                  return testContext.parent.rfc !== undefined
                    ? testContext.parent.rfc.trim().length > 0
                      ? validarRFCTipoPersona(
                          testContext.parent.rfc.trim(),
                          ID_PFAE
                        )
                      : false
                    : false;
                }),
              curp: Yup.string().required("Dato requerido."),
              folioVerificacionId: Yup.string().required("Dato requerido."),
              nacionalidadId: Yup.number().required("Dato requerido."),
              calidadMigratoria: Yup.string().when("nacionalidadId", {
                is: (value) => value !== NACIONALIDAD_MEXICAN0,
                then: Yup.string().required("Dato requerido."),
              }),
              estadoCivilId: Yup.number().required("Dato requerido."),
              nivelEducativoId: Yup.number().required("Dato requerido."),
              profesion: Yup.string().required("Dato requerido."),
              correoElectronico: Yup.string()
                .email("Correo eléctronico inválido")
                .required("Dato requerido."),
              telefono: Yup.string()
                .required("Dato requerido.")
                .test(
                  "telefono",
                  "Teléfono capturado de forma incorrecta.",
                  (item, testContext) => {
                    return validarNumeroTelefono(testContext.parent.telefono);
                  }
                ),
              celular: Yup.string()
                .required("Dato requerido.")
                .test(
                  "celular",
                  "Celular capturado de forma incorrecta.",
                  (item, testContext) => {
                    return validarNumeroTelefono(testContext.parent.celular);
                  }
                ),
              dependientesEconomicosMenores23:
                Yup.number().required("Dato requerido."),
              dependientesEconomicos: Yup.number()
                .required("Dato requerido.")
                .test(
                  "dependientesEconomicos",
                  "Debe ser Mayor o igual al número de dependientes menores de 23 años.",
                  (item, testContext) => {
                    return dependeintesMajor23(
                      totalDependientesMenores23,
                      totalDependientes
                    );
                  }
                ),
              nombreOtraEmpresa: Yup.string().when("esAccionistaOtraEmpresa", {
                is: true,
                then: Yup.string().required("Este campo es obligatorio."),
              }),
              nombreOtraEmpresaPfae: Yup.string().when(
                "esObligadoSolidarioOtraEmpresaPfae",
                {
                  is: true,
                  then: Yup.string().required("Este campo es obligatorio."),
                }
              ),
            }),
            direccion: Yup.object().shape({
              calle: Yup.string().required("Dato requerido."),
              numeroExterior: Yup.string().required("Dato requerido."),
              numeroInterior: Yup.string().when("tipoViviendaId", {
                is: TIPO_VIVIENDA_EDIFICIO,
                then: Yup.string().required("Dato requerido."),
                otherwise: Yup.string().when("tipoViviendaId", {
                  is: TIPO_DEPARTAMENTO_APARTAMENTO,
                  then: Yup.string().required("Dato requerido."),
                }),
              }),
              piso: Yup.string().when("tipoViviendaId", {
                is: TIPO_VIVIENDA_EDIFICIO,
                then: Yup.string().required("Dato requerido."),
                otherwise: Yup.string().when("tipoViviendaId", {
                  is: TIPO_DEPARTAMENTO_APARTAMENTO,
                  then: Yup.string().required("Dato requerido."),
                }),
              }),
              paisId: Yup.string().required("Dato requerido."),
              codigoPostal: Yup.string().required("Dato requerido."),
              colonia: Yup.string().required("Dato requerido."),
              municipioId: Yup.string().required("Dato requerido."),
              estadoId: Yup.string().required("Dato requerido."),
              tipoDomicilioId: Yup.string().required("Dato requerido."),
              tipoViviendaId: Yup.string().required("Dato requerido."),
              tiempoViviendaAnios: Yup.number().required("Dato requerido."),
              tiempoViviendaMeses: Yup.number()
                .required("Dato requerido.")
                .test(
                  "tiempoViviendaMeses",
                  "Antigüedad en el Domicilio Actual, debe ser mayor a cero.",
                  (item, testContext) => {
                    return tiempoViviendaActual(
                      testContext.parent.tiempoViviendaMeses,
                      testContext.parent.tiempoViviendaAnios
                    );
                  }
                ),
              tiempoViviendaAntAnios: Yup.number().required("Dato requerido."),
              tiempoViviendaAntMeses: Yup.number()
                .required("Dato requerido.")
                .test(
                  "tiempoViviendaAntMeses",
                  "Dato requerido.",
                  (item, testContext) => {
                    return tiempoViviendaAnterior(
                      testContext.parent.tiempoViviendaAntMeses,
                      testContext.parent.tiempoViviendaAntAnios
                    );
                  }
                ),
            }),
            datosDelNegocio: Yup.object().shape({
              importaExportaId: Yup.number().required("Dato requerido."),
              sectorId: Yup.string().required("Dato requerido."),
              giroEmpresarialId: Yup.string().required("Dato requerido."),
              actividadId: Yup.string().required("Dato requerido."),
              aniosExperienciaSector: Yup.string().required("Dato requerido."),
              numeroSucursales: Yup.number()
                .required("Dato requerido.")
                .min(0, "Por favor ingrese un valor mayor igual a 0.")
                .max(9999, "Por favor no ingrese más de 9,999 sucursales."),
              fechaAltaShcp: Yup.string().required("Dato requerido."),
            }),
          }),
        }),
      };
    } else {
      return {
        principalAccionista: Yup.object().shape({
          conyuge: Yup.object().shape({
            generales: Yup.object().shape({
              nombre: Yup.string()
                .required("Dato requerido.")
                .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
              apellidoPaterno: Yup.string()
                .required("Dato requerido.")
                .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
              apellidoMaterno: Yup.string().matches(
                /^[aA-zZÑñ\s]+$/,
                "Solo se permiten letras "
              ),
              fechaNacimiento: Yup.string()
                .required("Dato requerido.")
                .test(
                  "fechaNacimiento",
                  "Debe ser mayor a 18 años y menor a 70 años.",
                  (value) =>
                    compareDatesOrigin(
                      fechaMaxMayorEdadYYYMMDD(),
                      fechaMinEdadYYYMMDD(),
                      value
                    )
                ),
              paisNacimientoId: Yup.number().required("Dato requerido."),
              estadoNacimientoId: Yup.string().when("paisNacimientoId", {
                is: (value) => value === ID_PAIS_MEXICO,
                then: Yup.string().required("Dato requerido."),
              }),
              generoId: Yup.number().required("Dato requerido."),
              rfc: Yup.string()
                .required("Dato requerido.")
                .test("rfc", "RFC inválido.", (item, testContext) => {
                  return testContext.parent.rfc !== undefined
                    ? testContext.parent.rfc.trim().length > 0
                      ? validarRFCTipoPersona(
                          testContext.parent.rfc.trim(),
                          ID_PF
                        )
                      : false
                    : false;
                }),
              curp: Yup.string().required("Dato requerido."),
              folioVerificacionId: Yup.string().required("Dato requerido."),
              nacionalidadId: Yup.number().required("Dato requerido."),
              calidadMigratoria: Yup.string().when("nacionalidadId", {
                is: (value) => value !== NACIONALIDAD_MEXICAN0,
                then: Yup.string().required("Dato requerido."),
              }),
              estadoCivilId: Yup.number().required("Dato requerido."),
              nivelEducativoId: Yup.number().required("Dato requerido."),
              profesion: Yup.string().required("Dato requerido."),
              correoElectronico: Yup.string()
                .email("Correo eléctronico inválido")
                .required("Dato requerido."),
              telefono: Yup.string()
                .required("Dato requerido.")
                .test(
                  "telefono",
                  "Teléfono capturado de forma incorrecta.",
                  (item, testContext) => {
                    return validarNumeroTelefono(testContext.parent.telefono);
                  }
                ),
              celular: Yup.string()
                .required("Dato requerido.")
                .test(
                  "celular",
                  "Celular capturado de forma incorrecta.",
                  (item, testContext) => {
                    return validarNumeroTelefono(testContext.parent.celular);
                  }
                ),
              dependientesEconomicosMenores23:
                Yup.number().required("Dato requerido."),
              dependientesEconomicos: Yup.number()
                .required("Dato requerido.")
                .test(
                  "dependientesEconomicos",
                  "Debe ser Mayor o igual al número de dependientes menores de 23 años.",
                  (item, testContext) => {
                    return dependeintesMajor23(
                      totalDependientesMenores23,
                      totalDependientes
                    );
                  }
                ),
              nombreOtraEmpresa: Yup.string().when("esAccionistaOtraEmpresa", {
                is: true,
                then: Yup.string().required("Este campo es obligatorio."),
              }),
              nombreOtraEmpresaPfae: Yup.string().when(
                "esObligadoSolidarioOtraEmpresaPfae",
                {
                  is: true,
                  then: Yup.string().required("Este campo es obligatorio."),
                }
              ),
            }),
            direccion: Yup.object().shape({
              calle: Yup.string().required("Dato requerido."),
              numeroExterior: Yup.string().required("Dato requerido."),
              numeroInterior: Yup.string().when("tipoViviendaId", {
                is: TIPO_VIVIENDA_EDIFICIO,
                then: Yup.string().required("Dato requerido."),
                otherwise: Yup.string().when("tipoViviendaId", {
                  is: TIPO_DEPARTAMENTO_APARTAMENTO,
                  then: Yup.string().required("Dato requerido."),
                }),
              }),
              piso: Yup.string().when("tipoViviendaId", {
                is: TIPO_VIVIENDA_EDIFICIO,
                then: Yup.string().required("Dato requerido."),
                otherwise: Yup.string().when("tipoViviendaId", {
                  is: TIPO_DEPARTAMENTO_APARTAMENTO,
                  then: Yup.string().required("Dato requerido."),
                }),
              }),
              paisId: Yup.string().required("Dato requerido."),
              codigoPostal: Yup.string().required("Dato requerido."),
              colonia: Yup.string().required("Dato requerido."),
              municipioId: Yup.string().required("Dato requerido."),
              estadoId: Yup.string().required("Dato requerido."),
              tipoDomicilioId: Yup.string().required("Dato requerido."),
              tipoViviendaId: Yup.string().required("Dato requerido."),
              tiempoViviendaAnios: Yup.number().required("Dato requerido."),
              tiempoViviendaMeses: Yup.number()
                .required("Dato requerido.")
                .test(
                  "tiempoViviendaMeses",
                  "Antigüedad en el Domicilio Actual, debe ser mayor a cero.",
                  (item, testContext) => {
                    return tiempoViviendaActual(
                      testContext.parent.tiempoViviendaMeses,
                      testContext.parent.tiempoViviendaAnios
                    );
                  }
                ),
              tiempoViviendaAntAnios: Yup.number().required("Dato requerido."),
              tiempoViviendaAntMeses: Yup.number()
                .required("Dato requerido.")
                .test(
                  "tiempoViviendaAntMeses",
                  "Dato requerido.",
                  (item, testContext) => {
                    return tiempoViviendaAnterior(
                      testContext.parent.tiempoViviendaAntMeses,
                      testContext.parent.tiempoViviendaAntAnios
                    );
                  }
                ),
            }),
          }),
        }),
      };
    }
  } else {
    return {
      principalAccionista: Yup.object().shape({
        conyuge: Yup.object().shape({
          generales: Yup.object().shape({
            nombre: Yup.string()
              .required("Dato requerido.")
              .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
            apellidoPaterno: Yup.string()
              .required("Dato requerido.")
              .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
            apellidoMaterno: Yup.string().matches(
              /^[aA-zZÑñ\s]+$/,
              "Solo se permiten letras "
            ),
            fechaNacimiento: Yup.string()
              .required("Dato requerido.")
              .test(
                "fechaNacimiento",
                "Debe ser mayor a 18 años y menor a 70 años.",
                (value) =>
                  compareDatesOrigin(
                    fechaMaxMayorEdadYYYMMDD(),
                    fechaMinEdadYYYMMDD(),
                    value
                  )
              ),
            paisNacimientoId: Yup.number().required("Dato requerido."),
            estadoNacimientoId: Yup.string().when("paisNacimientoId", {
              is: (value) => value === ID_PAIS_MEXICO,
              then: Yup.string().required("Dato requerido."),
            }),
            generoId: Yup.number().required("Dato requerido."),
            rfc: Yup.string()
              .required("Dato requerido.")
              .test("rfc", "RFC inválido.", (item, testContext) => {
                return testContext.parent.rfc !== undefined
                  ? testContext.parent.rfc.trim().length > 0
                    ? validarRFCTipoPersona(
                        testContext.parent.rfc.trim(),
                        ID_PF
                      )
                    : false
                  : false;
              }),
            curp: Yup.string().required("Dato requerido."),
            nacionalidadId: Yup.number().required("Dato requerido."),
          }),
        }),
      }),
    };
  }
}
