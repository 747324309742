import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputDocument from "../../../../basics/InputDocument/InputDocument";
import {
  api_validar_documentos_cargados,
  api_eliminar_documentos,
  api_obtener_documentos_cargados,
  api_uploadDocumento,
} from "../../../../../api/api_servicios_rest_crediactivo";
import {
  FORMATOS_PERMITIDOS,
  MENSAJE_FORMATOS_PERMITIDOS,
  TIPO_DOCUMENTO_AUTORIZACION_BURO,
  TIPO_DOCUMENTO_AVISO_DE_PRIVACIDAD_REPRESENTANTE_LEGAL,
  TIPO_DOCUMENTO_CONSTANCIA_SITUACION_FISCAL,
  TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL,
  TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_FRENTE,
  TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_REVERSO,
  SECCION_CARGA_DOCUMENTOS,
  TIPO_DOCUMENTO_COMPROBANTE_DOMICILIO,
  TIPO_DOCUMENTO_COMPROBANTE_DOMICILIO_FISCAL,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  MAXIMO_BYTES_DOCUMENTO,
  LEYENDA_MAX_MB,
} from "../../../../../utils/constantes";
import ErrorMessage from "../../../../helpers/ErrorMessage/ErrorMessage";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";
import { obtenerNombreParticipante } from "../../../../../utils/nombreParticipante";
import {
  sweetAlertDocuments,
  sweetAlertError,
  sweetAlertWarning,
  sweetAlertWithConfirmedCrediactivo,
  sweetAlertSucessWithConfirmedCrediactivo,
} from "../../alertas/alertas";

const CargarDocumentoSolicitantePFAE = ({
  setLoading,
  modeloDatos,
  handleRegresarSeccionPyme,
}) => {
  const [nombreSolicitante, setNombreSolicitante] = useState("");
  const [mostrarDrop, setMostrarDrop] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(modeloDatos),
    validateOnBlur: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      setLoading(true);
      enviarSolicitudFabricaPyme(formData)
        .then(() => setLoading(false))
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    let nombreParticipante;
    nombreParticipante = obtenerNombreParticipante(
      modeloDatos?.generales?.nombre +
        " " +
        modeloDatos?.generales?.apellidoPaterno
    );
    setNombreSolicitante(nombreParticipante);
    obtenerDocumentosBySolicitud(modeloDatos?.idCliente);
  }, []);

  const obtenerDocumentosBySolicitud = (idCliente) => {
    try {
      async function obtenerDocumentosCargados() {
        let datosCliente = {
          idCliente: idCliente,
        };
        setLoading(true);
        const response = await api_obtener_documentos_cargados(datosCliente);
        if (response?.data?.success) {
          const arrayDocumentos = response?.data?.documentos;
          if (arrayDocumentos.length > 0) {
            arrayDocumentos.map((doc, index) =>
              formik.setFieldValue(`documents.${doc.tipoDeDocumento}`, [
                {
                  name: doc.nombreDocumento,
                },
              ])
            );
          }
          setMostrarDrop(true);
        } else if (response?.data?.success === false) {
          sweetAlertWarning(
            "Ocurrió un error al obtener los documentos cargados"
          );
        }
      }

      obtenerDocumentosCargados()
        .then(() => setLoading(false))
        .catch((e) => {
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const eliminarDocumentos = async (idCliente, nombreMapeo) => {
    setLoading(true);
    let datosDocumento = {
      idCliente: idCliente,
      tipoNombreMapeo: nombreMapeo,
    };

    try {
      const response = await api_eliminar_documentos(datosDocumento);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data?.success && data?.tipoDeDocumento !== false) {
          switch (data?.tipoDeDocumento) {
            case "autorizacionBC":
              delete formik.values.documents.autorizacionBC;
              break;
            case "identificacionOficial":
              delete formik.values.documents.identificacionOficial;
              break;
            case "identificacionOficialReverso":
              delete formik.values.documents.identificacionOficialReverso;
              break;
            case "constanciaSituacionFiscal":
              delete formik.values.documents.constanciaSituacionFiscal;
              break;
            case "avisoPrivacidad":
              delete formik.values.documents.avisoPrivacidad;
              break;
            case "comprobanteDeDomicilioFiscal":
              delete formik.values.documents.comprobanteDeDomicilioFiscal;
              break;
            case "comprobanteDeDomicilio":
              delete formik.values.documents.comprobanteDeDomicilio;
              break;

            default:
              break;
          }
          setLoading(false);
          obtenerDocumentosBySolicitud(modeloDatos.idCliente);
        } else {
          setLoading(false);
          if (data?.success === false) {
            sweetAlertWarning(data?.mensaje);
          }
        }
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const enviarSolicitudFabricaPyme = async (formData) => {
    try {
      const response = await api_validar_documentos_cargados(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setLoading(false);
          if (data.documentosCargados) {
            sweetAlertSucessWithConfirmedCrediactivo(
              "Los documentos de la solicitud han sido registrados correctamente."
            );
          } else {
            sweetAlertError(data?.mensaje);
          }
        } else {
          setLoading(false);
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data?.mensaje) {
            sweetAlertWithConfirmedCrediactivo(data?.mensaje);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
      } else {
        setLoading(false);
        sweetAlertWarning("Ocurrió un error al validar documentos cargados.");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleFiles = (
    files,
    idSolicitud,
    idCliente,
    tipoDeParticipante,
    tipoDocumento
  ) => {
    let newArray = [];
    const maxFile = MAXIMO_BYTES_DOCUMENTO;
    setLoading(true);
    for (let x = 0; x < files.length; x++) {
      URL.createObjectURL(files[0]);
      newArray.push(URL.createObjectURL(files[x]));
    }
    if (files[0].size < maxFile) {
      const extensionFile = files[0]?.path.substr(
        files[0]?.path?.lastIndexOf(".") + 1 - files[0]?.path?.length
      );
      if (FORMATOS_PERMITIDOS.indexOf(extensionFile) > -1) {
        uploadDocumento(
          files[0],
          idSolicitud,
          idCliente,
          tipoDeParticipante,
          tipoDocumento
        )
          .then((r) => setLoading(false))
          .catch((e) => console.error(e));
      } else {
        setLoading(false);
        sweetAlertWarning(MENSAJE_FORMATOS_PERMITIDOS);
      }
    } else {
      setLoading(false);
      sweetAlertWarning(
        "El archivo es demasiado grande " +
          convertToMb(files[0]?.size) +
          ". Tamaño máximo de archivo " +
          LEYENDA_MAX_MB +
          "."
      );
    }
  };

  const convertToMb = (bytes) => {
    var mb = bytes / 1000000;
    return mb.toFixed(2) + "MB";
  };

  const uploadDocumento = async (
    e,
    idSolicitud,
    idCliente,
    tipoDeParticipante,
    tipoDocumento
  ) => {
    try {
      let formdata = new FormData();
      formdata.append("archivo", e, e.name);
      formdata.append("tipoDocumento", tipoDocumento);
      formdata.append("tipoDeParticipante", tipoDeParticipante);
      formdata.append("idSolicitud", idSolicitud);
      formdata.append("idCliente", idCliente);
      const response = await api_uploadDocumento(formdata);
      if (response?.data?.success) {
        if (tipoDocumento === TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL) {
          if (
            response?.data?.successIne === false &&
            response?.data?.validacionOCR === false
          ) {
            if (
              response?.data?.servicio?.noIne === false ||
              response?.data?.servicio?.errorOCR ||
              response?.data?.servicio?.errorClasificador ||
              response?.data?.servicio?.errorIne
            ) {
              setLoading(false);
              sweetAlertWarning(response?.data?.mensaje);
            } else {
              setLoading(false);
              sweetAlertDocuments(
                "/img/ine-no.png",
                "Este documento no es una INE/IFE",
                "Por favor sigue estas recomendaciones para que podamos validar tu identificación <br>" +
                  '<ul style="font-size: 14px; text-align: justify;">' +
                  '<li style="margin-top: 2%;">No colocar dos INES dentro de un documento pdf.</li>' +
                  '<li style="margin-top: 2%;">Coloca la credencial lo más centrada posible en la imagen.</li>' +
                  '<li style="margin-top: 2%;">No uses fondos del mismo color que la identificación.</li>' +
                  '<li style="margin-top: 2%;">De preferencia no uses flash. En caso de usarlo no deberá reflejarse en la imagen.</li>' +
                  "</ul>",
                response?.data?.mensaje
              );
            }
          } else {
            if (response?.data?.validacionOCR.ok === false) {
              setLoading(false);
              sweetAlertWarning(response?.data?.validacionOCR.message);
            } else {
              let usrAg = navigator.userAgent;
              if (usrAg.indexOf("Edg") > -1) {
                setMostrarDrop(false);
                pintarDocumentosDropzone(tipoDocumento, e);
                setMostrarDrop(true);
              } else {
                pintarDocumentosDropzone(tipoDocumento, e);
              }
            }
          }
        } else {
          let usrAg = navigator.userAgent;
          if (usrAg.indexOf("Edg") > -1) {
            setMostrarDrop(false);
            pintarDocumentosDropzone(tipoDocumento, e);
            setMostrarDrop(true);
          } else {
            pintarDocumentosDropzone(tipoDocumento, e);
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const pintarDocumentosDropzone = (tipoDocumento, e) => {
    if (tipoDocumento in formik?.values?.documents) {
      formik.setFieldValue(`documents.${tipoDocumento}`, [
        ...formik.values.documents[tipoDocumento],
        {
          name: e.name,
        },
      ]);
    } else {
      formik.setFieldValue(`documents.${tipoDocumento}`, [
        {
          name: e.name,
        },
      ]);
    }
  };

  const documentsType = [
    {
      tipoDeParticipante: "comprobanteDeDomicilioFiscal",
      tipoDeDocumento: TIPO_DOCUMENTO_COMPROBANTE_DOMICILIO_FISCAL,
      title: "Comprobante de domicilio del negocio *",
      documentList: formik?.values?.documents?.comprobanteDeDomicilioFiscal,
    },
    {
      tipoDeParticipante: "avisoPrivacidad",
      tipoDeDocumento: TIPO_DOCUMENTO_AVISO_DE_PRIVACIDAD_REPRESENTANTE_LEGAL,
      title: "Aviso de privacidad representante legal *",
      documentList: formik?.values?.documents?.avisoPrivacidad,
    },
    {
      tipoDeParticipante: "identificacionOficial",
      tipoDeDocumento: TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_FRENTE,
      title: "Identificación oficial representante legal frente *",
      documentList: formik?.values?.documents?.identificacionOficial,
    },
    {
      tipoDeParticipante: "identificacionOficialReverso",
      tipoDeDocumento: TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_REVERSO,
      title: "Identificación oficial representante legal reverso *",
      documentList: formik?.values?.documents?.identificacionOficialReverso,
    },
    {
      tipoDeParticipante: "comprobanteDeDomicilio",
      tipoDeDocumento: TIPO_DOCUMENTO_COMPROBANTE_DOMICILIO,
      title: "Comprobante de domicilio personal *",
      documentList: formik?.values?.documents?.comprobanteDeDomicilio,
    },
  ];

  useEffect(() => {
    let newDocuments = [];
    const documentsFlat = Object.values(formik.values.documents).flat();

    documentsFlat.forEach((document) => {
      const [name, number, id] = document.name.split("_");

      const file = documentsType.filter(
        (file) => file.tipoDeParticipante === name
      );
      newDocuments.push({ ...file, file: document.name });
    });
  }, [formik.values.documents]);

  return (
    <>
      {mostrarDrop && (
        <form
          name="form-solicitante-consulta-tradicional-pm"
          onSubmit={formik.handleSubmit}
        >
          <h1 style={{ margin: "2rem 0px" }} className="f-s-3">
            Documentos solicitante PFAE - {nombreSolicitante}
          </h1>
          <div className="d-flex flex-column justify-content-center align-items-center gap-2">
            {documentsType.map((document, index) => {
              return (
                <InputDocument
                  key={index}
                  onDrop={(acceptedFiles) => {
                    handleFiles(
                      acceptedFiles,
                      formik?.values?.idSolicitud,
                      formik?.values?.idCliente,
                      formik?.values?.tipoDeParticipante,
                      document.tipoDeParticipante,
                      document.tipoDeDocumento
                    );
                  }}
                  className={`${
                    document.documentList ? "documentUploaded" : "documentEmpty"
                  } cargaInput ${document.documentList && "loader-carga"}`}
                  label={document.title}
                  documentList={
                    document.documentList ? document.documentList : []
                  }
                  formik={formik}
                  eliminarDocumentos={eliminarDocumentos}
                  nombreMapeo={document.tipoDeParticipante}
                  disabled={document.documentList ? true : false}
                />
              );
            })}
            {formik.errors?.autorizacion && (
              <ErrorMessage message={formik.errors?.autorizacion} width={55} />
            )}
          </div>
          <div
            className="div-button-cargar"
            style={{
              margin: "2rem 0",
            }}
          >
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                handleRegresarSeccionPyme(SECCION_CARGA_DOCUMENTOS);
              }}
            >
              Atrás
            </SecondaryButton>
            <PrimaryButton type="submit">Enviar documentos</PrimaryButton>
          </div>
        </form>
      )}
    </>
  );
};

export default CargarDocumentoSolicitantePFAE;

function initialValues(modeloDatos) {
  return {
    participanteSolicitanteId: modeloDatos.idCliente,
    idSolicitud: modeloDatos.idSolicitud,
    idCliente: modeloDatos?.idCliente,
    tipoDeParticipante: "solicitante",
    autorizacion: true,
    contadorOS: 0,
    numParticipante: 1,
    files: [],
    documents: {},
  };
}

function validationSchema() {
  return {
    autorizacion: Yup.boolean().oneOf([true], "Debe autorizar consulta"),
  };
}
