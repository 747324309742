import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#323e48",
  },
});

function InputText({
  value,
  onChange,
  onBlur,
  label,
  type,
  placeholder,
  helperText = "",
  error = false,
  InputProps,
  InputLabelProps,
  disabled = false,
}) {
  return (
    <CssTextField
      value={value}
      onChange={onChange}
      lang="es"
      onBlur={onBlur}
      id="filled-basic"
      variant="filled"
      label={label}
      type={type}
      placeholder={placeholder}
      style={{
        width: "100%",
        zIndex: "0",
      }}
      helperText={helperText}
      error={error}
      InputLabelProps={InputLabelProps}
      inputProps={InputProps}
      disabled={disabled}
    />
  );
}

export default InputText;
