import Swal from "sweetalert2";

// alertas crediactivo

export const sweetAlertSuccess = (text = "") => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "success",
    title: "Operación exitosa.",
    text,
    confirmButtonColor: "#d33",
    confirmButtonText: "Aceptar",
  });
};

export const sweetAlertError = (text = "") => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "error",
    title: "Oops...",
    text,
    confirmButtonColor: "#d33",
    confirmButtonText: "Aceptar",
  });
};

export const sweetAlertErrorWithHtml = (htmlMensaje) => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "error",
    title: "",
    html: htmlMensaje,
    confirmButtonColor: "#d33",
    confirmButtonText: "Aceptar",
  });
};

export const sweetAlertWarning = (text = "") => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "warning",
    title: "¡Atención!",
    text,
    confirmButtonColor: "#d33",
    confirmButtonText: "Aceptar",
  });
};

export const sweetAlertWithConfirmed = (text = "", webView) => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "error",
    title: "Oops...",
    text,
    confirmButtonText: "Aceptar",
    confirmButtonColor: "#d33",
  }).then((result) => {
    if (result.isConfirmed) {
      if (webView) {
        window.location.replace("/cotizadorBanorte");
      } else {
        window.location.replace("/cotizador");
      }
    }
  });
};

export const sweetAlertWithConfirmedCrediactivo = (text = "", webView) => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "error",
    title: "Oops...",
    text,
    confirmButtonText: "Aceptar",
    confirmButtonColor: "#d33",
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.replace("/crediactivo");
    }
  });
};

export const sweetAlertInfo = (text = "") => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "info",
    title: "",
    text: "" + text + "",
    confirmButtonColor: "#d33",
    confirmButtonText: "Aceptar",
  });
};

export const sweetAlertInfoWithHtml = (htmlMensaje) => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "info",
    title: "",
    html: htmlMensaje,
    confirmButtonColor: "#d33",
    confirmButtonText: "Aceptar",
  });
};

export const sweetAlertDocuments = (imageUrl, title, html, text = "") => {
  Swal.fire({
    allowOutsideClick: false,
    imageUrl,
    imageWidth: 200,
    imageHeight: 120,
    title,
    html,
    text,
    confirmButtonColor: "#d33",
    confirmButtonText: "Aceptar",
  });
};

export const sweetAlertObligadoSolidario = async () => {
  return await Swal.fire({
    allowOutsideClick: false,
    icon: "success",
    title: "Operación exitosa. ¿Desea agregar otro obligado solidario?",
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Si",
    denyButtonText: `No`,
    confirmButtonColor: "#dc3545",
    denyButtonColor: "#adb5bd",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
};

export const sweetAlertConfirmed = async (title) => {
  return await Swal.fire({
    icon: "success",
    title,
    confirmButtonText: "Si",
    confirmButtonColor: "#d33",
    showCloseButton: true,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
};


export const sweetAlertSucessWithConfirmedCrediactivo = (text = "") => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "success",
    title: "Operación exitosa.",
    text,
    confirmButtonColor: "#d33",
    confirmButtonText: "Aceptar",
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.replace("/crediactivo");
    }
  });
};
