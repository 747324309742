import React, { useEffect, useState } from "react";
import Dot from "../Dot/Dot";
import "./ProgressBar.css";
import {
  SECCION_HISTORIAL_CREDITICIO,
  SECCION_GENERALES,
  SECCION_OBLIGADO_SOLIDARIO,
  SECCION_SOLICITANTE_CONYUGE,
  SECCION_PRINCIPAL_ACCIONISTA,
  SECCION_PA_CONYUGE_PM,
  ID_PFAE,
  ID_PM
} from "../../../utils/constantes";

const StepProgressBar = ({
  subMenuSelected,
  setSubMenuSelected,
  setFormSection,
  setSeccion,
  ultimoPasoUsuario,
  conyugeOS,
  idTipoPersona
}) => {
  const [enabledSteps, setEnabledSteps] = useState(1);

  useEffect(() => {
    validarValorEnabledSteps();
  }, [ultimoPasoUsuario]);

  const validarValorEnabledSteps = () => {
    if (ultimoPasoUsuario === SECCION_HISTORIAL_CREDITICIO) {
      setEnabledSteps(3);
    } else {
      if (ultimoPasoUsuario === SECCION_PRINCIPAL_ACCIONISTA ||
          ultimoPasoUsuario === SECCION_OBLIGADO_SOLIDARIO) {
        setEnabledSteps(2)
      } else {
        if (idTipoPersona === ID_PM &&
          ultimoPasoUsuario === SECCION_PA_CONYUGE_PM) {
          setEnabledSteps(2);
        } else {
          if (idTipoPersona === ID_PFAE &&
            ultimoPasoUsuario === SECCION_SOLICITANTE_CONYUGE &&
            conyugeOS) {
            setEnabledSteps(2);
          }
        }
      }
    }

  }

  return (
    <div className="navbar--progress-bar container navigation container-style">
      <div className="stepper-wrapper">
        <>
          <div className="stepper-item">
            <div className="step-counter">
              <Dot
                onClick={() => {
                  setSubMenuSelected("generales");
                  setFormSection("Generales");
                  setSeccion(SECCION_GENERALES);
                }}
                content={1}
                active={subMenuSelected === "generales" ? true : false}
                hasBeenActivated={enabledSteps >= 1 ? true : false}
              />
            </div>
          </div>

          <div className="stepper-item active">
            <div className="step-counter">
              <Dot
                onClick={() => {
                  setSubMenuSelected("oSolidario");
                  setFormSection("Obligados Solidarios");
                  if(idTipoPersona === ID_PFAE){
                    setSeccion(conyugeOS ? SECCION_SOLICITANTE_CONYUGE: SECCION_OBLIGADO_SOLIDARIO);
                  }else{
                    setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
                  }                  
                }}
                content={2}
                active={subMenuSelected === "oSolidario" ? true : false}
                hasBeenActivated={enabledSteps >= 2 ? true : false}
              />
            </div>
          </div>

          <div className="stepper-item">
            <div className="step-counter">
              <Dot
                onClick={() => {
                  setSubMenuSelected("historialcrediticio");
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  setFormSection("Historial Crediticio");
                }}
                content={3}
                active={
                  subMenuSelected === "historialcrediticio" ? true : false
                }
                hasBeenActivated={enabledSteps >= 3 ? true : false}
              />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default StepProgressBar;
