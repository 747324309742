import React, { useEffect } from "react";
import InputText from "../../../../basics/input";
import NumberFormat from "react-number-format";
import ErrorMessage from "../../../../helpers/ErrorMessage/ErrorMessage";
import { isEmpty } from "../../../../../functions/fieldFunctions";
import "../../../../basics/InputWithError/InputWithError.css";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const DatosBancoMesPFAE = ({ numeroBanco, numeroMes, formik }) => {
  let textosMes = [
    "- Captura la información de tu último estado de cuenta",
    "- Captura la información de un corte atrás de tu estado de cuenta",
    "- Captura la información de dos cortes atrás de tu estado de cuenta",
    "- Captura la información de tres cortes atrás de tu estado de cuenta",
    "- Captura la información de cuatro cortes atrás de tu estado de cuenta",
    "- Captura la información de cinco cortes atrás de tu estado de cuenta",
  ]

  useEffect(() => {
    formik.setFieldValue(
      `datosEconomicos.bancos.${numeroBanco}.bancoMes.${numeroMes}.numeroMes`,
      numeroMes + 1
    );
  }, []);

  return (
    <>
      <div className="col-12">
        <h4 className="f-s-2">Mes {numeroMes + 1} {textosMes[numeroMes]}
          <div className="input__tooltip_titulo top">
            <AiOutlineQuestionCircle className="input__tooltip_titulo" />
            <span className="tiptext">Esta información se encuentra en la carátula del estado de cuenta</span>
          </div>
        </h4>
      </div>
      <div className="month-layout">
        <div className="input__container">
          <NumberFormat
            label="Saldo promedio de la cuenta de cheques *"
            name={`datosEconomicos.bancos.${numeroBanco}.bancoMes.${numeroMes}.saldoPromedioCuentaCheques`}
            value={
              formik.values?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                numeroMes
              ]?.saldoPromedioCuentaCheques
            }
            onValueChange={(e) => {
              let newErrors = formik.errors;
              if(newErrors?.datosEconomicos !== undefined &&   
                newErrors?.datosEconomicos?.bancos !== undefined &&
                newErrors?.datosEconomicos?.bancos.length !== 0 &&                
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes !== undefined &&
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes.length !== 0 && 
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[numeroMes]?.saldoPromedioCuentaCheques){
                delete newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[numeroMes]?.saldoPromedioCuentaCheques;
                formik.setErrors(newErrors);
              }
              formik.setFieldValue(
                `datosEconomicos.bancos.${numeroBanco}.bancoMes.${numeroMes}.saldoPromedioCuentaCheques`,
                e.value
              );
            }}
            type="text"
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            customInput={InputText}
            allowNegative={false}
            error={
              !isEmpty(
                formik.errors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                  numeroMes
                ]?.saldoPromedioCuentaCheques
              )
            }
          />
          <div>
            <div className="text-red">
              {formik?.errors?.datosEconomicos &&
                formik?.errors?.datosEconomicos?.bancos &&
                formik.errors?.datosEconomicos?.bancos.length > 0 &&
                formik.errors?.datosEconomicos?.bancos[numeroBanco] !==
                  undefined &&
                formik.errors?.datosEconomicos?.bancos[numeroBanco].bancoMes !==
                  undefined &&
                formik.errors?.datosEconomicos?.bancos[numeroBanco].bancoMes
                  .length > 0 && (
                  <ErrorMessage
                    message={
                      formik.errors?.datosEconomicos?.bancos[numeroBanco]
                        ?.bancoMes[numeroMes]?.saldoPromedioCuentaCheques
                    }
                    width={55}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="input__container">
          <NumberFormat
            label="Saldo inicial *"
            name={`datosEconomicos.bancos.${numeroBanco}.bancoMes.${numeroMes}.saldoInicial`}
            value={
              formik.values?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                numeroMes
              ]?.saldoInicial
            }
            onValueChange={(e) => {
              let newErrors = formik.errors;
              if(newErrors?.datosEconomicos !== undefined &&   
                newErrors?.datosEconomicos?.bancos !== undefined &&
                newErrors?.datosEconomicos?.bancos.length !== 0 &&                
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes !== undefined &&
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes.length !== 0 && 
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[numeroMes]?.saldoInicial){
                delete newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                  numeroMes
                ]?.saldoInicial;
               formik.setErrors(newErrors);
              }
              formik.setFieldValue(
                `datosEconomicos.bancos.${numeroBanco}.bancoMes.${numeroMes}.saldoInicial`,
                e.value
              );
            }}
            type="text"
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            customInput={InputText}
            allowNegative={false}
            error={
              !isEmpty(
                formik.errors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                  numeroMes
                ]?.saldoInicial
              )
            }
          />
          <div>
            <div className="text-red">
              {formik?.errors?.datosEconomicos &&
                formik?.errors?.datosEconomicos?.bancos &&
                formik.errors?.datosEconomicos?.bancos.length > 0 &&
                formik.errors?.datosEconomicos?.bancos[numeroBanco] !==
                  undefined &&
                formik.errors?.datosEconomicos?.bancos[numeroBanco].bancoMes !==
                  undefined &&
                formik.errors?.datosEconomicos?.bancos[numeroBanco].bancoMes
                  .length > 0 && (
                  <ErrorMessage
                    message={
                      formik.errors?.datosEconomicos?.bancos[numeroBanco]
                        ?.bancoMes[numeroMes]?.saldoInicial
                    }
                    width={55}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="input__container">
          <NumberFormat
            label="Total de retiros *"
            name={`datosEconomicos.bancos.${numeroBanco}.bancoMes.${numeroMes}.totalRetiros`}
            value={
              formik.values?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                numeroMes
              ]?.totalRetiros
            }
            onValueChange={(e) => {
              let newErrors = formik.errors;
              if(newErrors?.datosEconomicos !== undefined &&   
                newErrors?.datosEconomicos?.bancos !== undefined &&
                newErrors?.datosEconomicos?.bancos.length !== 0 &&                
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes !== undefined &&
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes.length !== 0 && 
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[numeroMes]?.totalRetiros){
                delete newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                  numeroMes
                ]?.totalRetiros;
                formik.setErrors(newErrors);
              }
              formik.setFieldValue(
                `datosEconomicos.bancos.${numeroBanco}.bancoMes.${numeroMes}.totalRetiros`,
                e.value
              );
            }}
            type="text"
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            customInput={InputText}
            allowNegative={false}
            error={
              !isEmpty(
                formik.errors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                  numeroMes
                ]?.totalRetiros
              )
            }
          />
          <div>
            <div className="text-red">
              {formik?.errors?.datosEconomicos &&
                formik?.errors?.datosEconomicos?.bancos &&
                formik.errors?.datosEconomicos?.bancos.length > 0 &&
                formik.errors?.datosEconomicos?.bancos[numeroBanco] !==
                  undefined &&
                formik.errors?.datosEconomicos?.bancos[numeroBanco].bancoMes !==
                  undefined &&
                formik.errors?.datosEconomicos?.bancos[numeroBanco].bancoMes
                  .length > 0 && (
                  <ErrorMessage
                    message={
                      formik.errors?.datosEconomicos?.bancos[numeroBanco]
                        ?.bancoMes[numeroMes]?.totalRetiros
                    }
                    width={55}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="input__container">
          <NumberFormat
            label="Total de depósitos *"
            name={`datosEconomicos.bancos.${numeroBanco}.bancoMes.${numeroMes}.totalDepositos`}
            value={
              formik.values?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                numeroMes
              ]?.totalDepositos
            }
            onValueChange={(e) => {
              let newErrors = formik.errors;
              if(newErrors?.datosEconomicos !== undefined &&   
                newErrors?.datosEconomicos?.bancos !== undefined &&
                newErrors?.datosEconomicos?.bancos.length !== 0 &&                
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes !== undefined &&
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes.length !== 0 && 
                newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[numeroMes]?.totalDepositos){
                delete newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                  numeroMes
                ]?.totalDepositos;
              }
              formik.setErrors(newErrors);
              formik.setFieldValue(
                `datosEconomicos.bancos.${numeroBanco}.bancoMes.${numeroMes}.totalDepositos`,
                e.value
              );
            }}
            type="text"
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            customInput={InputText}
            allowNegative={false}
            error={
              !isEmpty(
                formik.errors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                  numeroMes
                ]?.totalDepositos
              )
            }
          />
          {formik?.errors?.datosEconomicos &&
            formik?.errors?.datosEconomicos?.bancos &&
            formik.errors?.datosEconomicos?.bancos.length > 0 &&
            formik.errors?.datosEconomicos?.bancos[numeroBanco] !== undefined &&
            formik.errors?.datosEconomicos?.bancos[numeroBanco].bancoMes !==
              undefined &&
            formik.errors?.datosEconomicos?.bancos[numeroBanco].bancoMes
              .length > 0 && (
              <ErrorMessage
                message={
                  formik.errors?.datosEconomicos?.bancos[numeroBanco]?.bancoMes[
                    numeroMes
                  ]?.totalDepositos
                }
                width={55}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default DatosBancoMesPFAE;
