import React from "react";
import ErrorMessage from "../../helpers/ErrorMessage/ErrorMessage";
import { isEmpty } from "../../../functions/fieldFunctions";
import InputText from "../input";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import "./InputWithError.css";

const InputWithError = ({
  name = "",
  label = "",
  value = "",
  onChange = () => {},
  onBlur = () => {},
  style,
  type = "text",
  InputProps,
  InputLabelProps = {},
  showErrorMessage = false,
  errorMessage = "",
  required = false,
  tooltip = "",
  disabled = false,
}) => {
  return (
    <div className="input__container">
      <div className="d-flex gap-2 w-500 align-items-center">
        <InputText
          error={showErrorMessage}
          name={name}
          label={required ? `${label} *` : label}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          style={style}
          type={type}
          InputProps={InputProps}
          disabled={disabled}
          InputLabelProps={InputLabelProps}                        

        />
        {!isEmpty(tooltip) && (
          <div className="input__tooltip top">
            <AiOutlineQuestionCircle className="input__tooltip" />
            <span className="tiptext">{tooltip}</span>
          </div>
        )}
      </div>
      {showErrorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default InputWithError;
