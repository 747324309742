import React, { useState, useEffect } from "react";
import "./Submenu.css";
import { BiChevronRight } from "react-icons/bi";

const Submenu = ({ steps }) => {
  const [action, setAction] = useState("");

  //Lista de titulos del submenu. Key: step - Value: titulo
  const SUBMENU_TITLES = {
    1: "Cotizador",
    2: "Registro",
    3: "Formulario",
    4: "Historial",
    5: "Decreto",
    6: "Documentos",
  };

  //Obtiene el titulo del submenu dependiendo del "step" en el que este
  useEffect(() => {
    if (steps !== null) {
      setAction(SUBMENU_TITLES[steps]);
    }
  }, [steps]);

  return (
    <div className="navbar--submenu">
      <p className="mb-0">Inicio</p>
      <BiChevronRight />
      <p className="mb-0">Crediactivo</p>
      <BiChevronRight style={{ fill: "#eb0029" }} />
      <p className="mb-0 text-red" id="accionCrediactivo">{action}</p>
    </div>
  );
};

export default Submenu;
