import React, { useEffect, useState } from "react";
import { useTransition } from "react-spring";
import CargaDocumentos from "./CargaDocumentos/CargaDocumentosPM";
import HistorialCrediticio from "./../HistorialCrediticio";
import DatosGeneralesPM from "./DatosGeneralesPM";
import DatosGeneralesRepresentanteLegalPM from "./DatosGeneralesRepresentanteLegalPM";
import DatosGeneralesDomicilioNegocioPM from "./DatosGeneralesDomicilioNegocioPM";
import DatosGeneralesInfoNegocioPM from "./DatosGeneralesInfoNegocioPM";
import DatosGeneralesEconomicosPM from "./DatosEconomicosPM/DatosGeneralesEconomicosPM";
import SociosPrincipalesPM from "./SociosPrincipalesPM/SociosPrincipalesPM";
import PrincipalAccionistaPM from "./ObligadoSolidario/PrincipalAccionistaPM";
import DatosPatrimonioPrincipalPM from "./DatosPatrimonioPM/DatosPatrimonioPrincipalPM";
import DatosGeneralesDomicilioComercialPM from "./DatosGeneralesDomicilioComercialPM";
import PrincipalAccionistaConyuge from "./ObligadoSolidario/PrincipalAccionistaConyuge";
import ObligadoSolidarioConyuge from "./ObligadoSolidario/ObligadoSolidarioConyuge";
import ObligadoSolidarioPM from "./ObligadoSolidario/ObligadoSolidarioPM";

import {
  OS_UNO,
  OS_DOS,
  OS_TRES,
  OS_CUATRO,
  SECCION_GENERALES,
  SECCION_REPRESENTANTE_LEGAL,
  SECCION_ECONOMICOS,
  SECCION_DOMICILIO,
  SECCION_DOMICILIO_COMERCIAL,
  SECCION_OBLIGADO_SOLIDARIO,
  SECCION_CONYUGE_OS_UNO,
  SECCION_OS_DOS,
  SECCION_CONYUGE_OS_DOS,
  SECCION_OS_TRES,
  SECCION_CONYUGE_OS_TRES,
  SECCION_OS_CUATRO,
} from "../../../../utils/constantes";

const FormularioPersonaMoral = ({
  animated,
  transitionOne,
  transitionTwo,
  transitionThree,
  transitionFour,
  transitionFive,
  transitionSix,
  transitionSeven,
  transitionEight,
  transitionNine,
  transitionTen,
  transitionEleven,
  transitionTwelve,
  transitionThirteen,
  transitionFourtheen,
  transitionDiesiseis,
  transitionDiesisiete,
  transitionDiesiocho,
  transitionDiecinueve,
  transitionVeinte,
  transitionVeintidos,
  transitionVeintitres,
  transitionBancoOne,
  transitionBancoTwo,
  transitionBancoThree,
  animateHeight,
  cantidadBancos,
  handleAddBancos,
  animatedHeightBoxUnoRef,
  animatedHeightBoxDosRef,
  animatedHeightBoxTresRef,
  refScrollUpBancos,
  axiosData,
  modeloPm,
  anios,
  cantidadSocios,
  setCantidadSocios,
  aniosDomicilio,
  mesesDomicilo,
  handlePesos,
  setShowResult,
  tipoPersona,
  cantidadObligadosSolidarios,
  setCantidadObligadosSolidarios,
  setModeloPm,
  setTotalNominaMensual,
  setFolioSolicitud,
  setStyleHr,
  setObligadosSolidariosInformacionBasica,
  setImporteRentaFiscal,
  setVentasAnuales,
  domicilioFiscalIgual,
  setDomicilioFiscalIgual,
  setRegresarCotizador,
  regresarCotizador,
  setLoading,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  datosOSUno,
  setDatosOSUno,
  datosOSDos,
  setDatosOSDos,
  datosOSTres,
  setDatosOSTres,
  datosOSCuatro,
  setDatosOSCuatro,
  setCantidadConyugeOS,
  cantidadConyugeOS,
  setUltimoPasoSolicitud,
  setResultadoEvaluacionRiesgos,
  webView,
}) => {
  // ANIMATION TOGGLE
  const [transitionSocioUnoToggle, setTransitionSocioUnoToggle] =
    useState(false);
  const [transitionSocioDosToggle, setTransitionSocioDosToggle] =
    useState(false);
  const [transitionSocioTresToggle, setTransitionSocioTresToggle] =
    useState(false);
  const [transitionSocioCuatroToggle, setTransitionSocioCuatroToggle] =
    useState(false);
  const [transitionSocioCincoToggle, setTransitionSocioCincoToggle] =
    useState(false);

  var transitionConfig = {
    from: {
      opacity: 0,
      position: "relative",
      transform: "translateX(-100%)",
      pointerEvents: "all",
    },
    enter: {
      opacity: 1,
      position: "relative",
      transform: "translateX(0%)",
      pointerEvents: "all",
    },
    leave: {
      opacity: 0,
      position: "relative",
      transform: "translateX(100%)",
      pointerEvents: "none",
    },
  };

  // TRANSITIONS
  const transitionSocioUno = useTransition(
    transitionSocioUnoToggle,
    transitionConfig
  );
  const transitionSocioDos = useTransition(
    transitionSocioDosToggle,
    transitionConfig
  );
  const transitionSocioTres = useTransition(
    transitionSocioTresToggle,
    transitionConfig
  );
  const transitionSocioCuatro = useTransition(
    transitionSocioCuatroToggle,
    transitionConfig
  );
  const transitionSocioCinco = useTransition(
    transitionSocioCincoToggle,
    transitionConfig
  );

  useEffect(() => {
    if (parseInt(cantidadSocios) >= 1) setTransitionSocioUnoToggle(true);
    else setTransitionSocioUnoToggle(false);
    if (parseInt(cantidadSocios) >= 2) setTransitionSocioDosToggle(true);
    else setTransitionSocioDosToggle(false);
    if (parseInt(cantidadSocios) >= 3) setTransitionSocioTresToggle(true);
    else setTransitionSocioTresToggle(false);
    if (parseInt(cantidadSocios) >= 4) setTransitionSocioCuatroToggle(true);
    else setTransitionSocioCuatroToggle(false);
    if (parseInt(cantidadSocios) >= 5) setTransitionSocioCincoToggle(true);
    else setTransitionSocioCincoToggle(false);
  }, [cantidadSocios]);

  const handleChangeFormularioPM = (event, seccion) => {
    if (event.target.value !== undefined) {
      let valor = event.target.value;

      switch (event.target.name) {
        case "rentaMensual":
          valor = Number(valor.replace(/\D/g, ""));
          handlePesos(event, setImporteRentaFiscal);
          break;
        case "totalNominaMensual":
          valor = Number(valor.replace(/\D/g, ""));
          handlePesos(event, setTotalNominaMensual);
          break;
        case "ventasAnuales":
          valor = Number(valor.replace(/\D/g, ""));
          handlePesos(event, setVentasAnuales);
          break;

        default:
          break;
      }

      switch (seccion) {
        case SECCION_GENERALES:
          setModeloPm({
            ...modeloPm,
            generales: {
              ...modeloPm.generales,
              [event.target.name]: valor,
            },
          });
          break;
        case SECCION_REPRESENTANTE_LEGAL:
          setModeloPm({
            ...modeloPm,
            representanteLegal: {
              ...modeloPm.representanteLegal,
              [event.target.name]: valor,
            },
          });
          break;
        case SECCION_DOMICILIO:
          setModeloPm({
            ...modeloPm,
            direccion: {
              ...modeloPm.direccion,
              [event.target.name]: valor,
            },
          });
          break;
        case SECCION_ECONOMICOS:
          setModeloPm({
            ...modeloPm,
            datosEconomicos: {
              ...modeloPm.datosEconomicos,
              [event.target.name]: valor,
            },
          });
          break;
        case SECCION_DOMICILIO_COMERCIAL:
          setModeloPm({
            ...modeloPm,
            domicilioComercial: {
              ...modeloPm.domicilioComercial,
              [event.target.name]: valor,
            },
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {transitionOne(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPm?.generales !== undefined && (
                <DatosGeneralesPM
                  modeloPm={modeloPm}
                  setModeloPm={setModeloPm}
                  handleChangeFormularioPM={handleChangeFormularioPM}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  anios={anios}
                  setFolioSolicitud={setFolioSolicitud}
                  setStyleHr={setStyleHr}
                  setRegresarCotizador={setRegresarCotizador}
                  regresarCotizador={regresarCotizador}
                  setLoading={setLoading}
                  webView={webView}
                />
              )}
            </animated.div>
          )
      )}
      {transitionTwo(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPm?.representanteLegal !== undefined && (
                <DatosGeneralesRepresentanteLegalPM
                  modeloPm={modeloPm}
                  setModeloPm={setModeloPm}
                  handleChangeFormularioPM={handleChangeFormularioPM}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  setStyleHr={setStyleHr}
                  setLoading={setLoading}
                  webView={webView}
                />
              )}
            </animated.div>
          )
      )}
      {transitionThree(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPm?.direccion !== undefined && (
                <DatosGeneralesDomicilioNegocioPM
                  modeloPm={modeloPm}
                  setModeloPm={setModeloPm}
                  axiosData={axiosData}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  setStyleHr={setStyleHr}
                  setLoading={setLoading}
                  webView={webView}
                />
              )}
            </animated.div>
          )
      )}
      {transitionDiecinueve(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPm?.domicilioComercial !== undefined && (
                <DatosGeneralesDomicilioComercialPM
                  modeloPm={modeloPm}
                  setModeloPm={setModeloPm}
                  axiosData={axiosData}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  anios={anios}
                  domicilioFiscalIgual={domicilioFiscalIgual}
                  setDomicilioFiscalIgual={setDomicilioFiscalIgual}
                  setLoading={setLoading}
                  webView={webView}
                />
              )}
            </animated.div>
          )
      )}
      {transitionFour(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPm?.datosDelNegocio !== undefined && (
                <DatosGeneralesInfoNegocioPM
                  modeloPm={modeloPm}
                  setModeloPm={setModeloPm}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  axiosData={axiosData}
                  anios={anios}
                  setStyleHr={setStyleHr}
                  setLoading={setLoading}
                  webView={webView}
                />
              )}
            </animated.div>
          )
      )}
      {transitionFive(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPm?.datosEconomicos !== undefined && (
                <DatosGeneralesEconomicosPM
                  axiosData={axiosData}
                  modeloPm={modeloPm}
                  animatedHeightBoxUnoRef={animatedHeightBoxUnoRef}
                  transitionBancoOne={transitionBancoOne}
                  refScrollUpBancos={refScrollUpBancos}
                  transitionBancoTwo={transitionBancoTwo}
                  animateHeight={animateHeight}
                  animatedHeightBoxDosRef={animatedHeightBoxDosRef}
                  transitionBancoThree={transitionBancoThree}
                  animatedHeightBoxTresRef={animatedHeightBoxTresRef}
                  cantidadBancos={cantidadBancos}
                  handleAddBancos={handleAddBancos}
                  setModeloPm={setModeloPm}
                  setStyleHr={setStyleHr}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  setLoading={setLoading}
                  webView={webView}
                />
              )}
            </animated.div>
          )
      )}
      {transitionSix(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPm?.sociosPrincipales !== undefined && (
                <SociosPrincipalesPM
                  cantidadSocios={cantidadSocios}
                  setCantidadSocios={setCantidadSocios}
                  transitionSocioUno={transitionSocioUno}
                  transitionSocioDos={transitionSocioDos}
                  transitionSocioTres={transitionSocioTres}
                  transitionSocioCuatro={transitionSocioCuatro}
                  transitionSocioCinco={transitionSocioCinco}
                  modeloPm={modeloPm}
                  setModeloPm={setModeloPm}
                  setStyleHr={setStyleHr}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  setLoading={setLoading}
                  webView={webView}
                />
              )}
            </animated.div>
          )
      )}
      {transitionSeven(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPm?.principalAccionista !== undefined && (
                <PrincipalAccionistaPM
                  modeloPm={modeloPm}
                  setModeloPm={setModeloPm}
                  setObligadosSolidariosInformacionBasica={
                    setObligadosSolidariosInformacionBasica
                  }
                  setStyleHr={setStyleHr}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  axiosData={axiosData}
                  mesesDomicilo={mesesDomicilo}
                  aniosDomicilio={aniosDomicilio}
                  anios={anios}
                  setLoading={setLoading}
                  webView={webView}
                />
              )}
            </animated.div>
          )
      )}
      {transitionEight(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioPM
                modeloPm={modeloPm}
                setModeloPm={setModeloPm}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                mesesDomicilo={mesesDomicilo}
                aniosDomicilio={aniosDomicilio}
                anios={anios}
                setLoading={setLoading}
                NUM_OS={OS_UNO}
                seccionActual={SECCION_OBLIGADO_SOLIDARIO}
                datosOs={datosOSUno}
                setDatosOs={setDatosOSUno}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                webView={webView}
              />
            </animated.div>
          )
      )}
      {transitionNine(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioConyuge
                modeloPm={modeloPm}
                axiosData={axiosData}
                aniosDomicilio={aniosDomicilio}
                mesesDomicilo={mesesDomicilo}
                anios={anios}
                setModeloPm={setModeloPm}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                num_os={OS_UNO}
                setLoading={setLoading}
                seccionActual={SECCION_CONYUGE_OS_UNO}
                datosOs={datosOSUno}
                setDatosOs={setDatosOSUno}
                setCantidadConyugeOS={setCantidadConyugeOS}
                cantidadConyugeOS={cantidadConyugeOS}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                webView={webView}
              />
            </animated.div>
          )
      )}
      {transitionTen(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioPM
                modeloPm={modeloPm}
                setModeloPm={setModeloPm}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                mesesDomicilo={mesesDomicilo}
                aniosDomicilio={aniosDomicilio}
                anios={anios}
                setLoading={setLoading}
                NUM_OS={OS_DOS}
                seccionActual={SECCION_OS_DOS}
                datosOs={datosOSDos}
                setDatosOs={setDatosOSDos}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                webView={webView}
              />
            </animated.div>
          )
      )}
      {transitionEleven(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioConyuge
                modeloPm={modeloPm}
                axiosData={axiosData}
                aniosDomicilio={aniosDomicilio}
                mesesDomicilo={mesesDomicilo}
                anios={anios}
                setModeloPm={setModeloPm}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                num_os={OS_DOS}
                setLoading={setLoading}
                seccionActual={SECCION_CONYUGE_OS_DOS}
                datosOs={datosOSDos}
                setDatosOs={setDatosOSDos}
                setCantidadConyugeOS={setCantidadConyugeOS}
                cantidadConyugeOS={cantidadConyugeOS}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                webView={webView}
              />
            </animated.div>
          )
      )}
      {transitionTwelve(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioPM
                modeloPm={modeloPm}
                setModeloPm={setModeloPm}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                mesesDomicilo={mesesDomicilo}
                aniosDomicilio={aniosDomicilio}
                anios={anios}
                setLoading={setLoading}
                NUM_OS={OS_TRES}
                seccionActual={SECCION_OS_TRES}
                datosOs={datosOSTres}
                setDatosOs={setDatosOSTres}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                webView={webView}
              />
            </animated.div>
          )
      )}
      {transitionThirteen(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioConyuge
                modeloPm={modeloPm}
                axiosData={axiosData}
                aniosDomicilio={aniosDomicilio}
                mesesDomicilo={mesesDomicilo}
                anios={anios}
                setModeloPm={setModeloPm}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                num_os={OS_TRES}
                setLoading={setLoading}
                seccionActual={SECCION_CONYUGE_OS_TRES}
                datosOs={datosOSTres}
                setDatosOs={setDatosOSTres}
                setCantidadConyugeOS={setCantidadConyugeOS}
                cantidadConyugeOS={cantidadConyugeOS}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                webView={webView}
              />
            </animated.div>
          )
      )}
      {transitionFourtheen(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioPM
                modeloPm={modeloPm}
                setModeloPm={setModeloPm}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                mesesDomicilo={mesesDomicilo}
                aniosDomicilio={aniosDomicilio}
                anios={anios}
                setLoading={setLoading}
                NUM_OS={OS_CUATRO}
                seccionActual={SECCION_OS_CUATRO}
                datosOs={datosOSCuatro}
                setDatosOs={setDatosOSCuatro}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                webView={webView}
              />
            </animated.div>
          )
      )}
      {transitionDiesiseis(
        (style, visible) =>
          visible === true && (
            <animated.div style={style}>
              <DatosPatrimonioPrincipalPM
                modeloPm={modeloPm}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                setModeloPm={setModeloPm}
                setStyleHr={setStyleHr}
                setLoading={setLoading}
                webView={webView}
              />
            </animated.div>
          )
      )}
      {transitionDiesisiete(
        (style, visible) =>
          visible === true && (
            <animated.div style={style}>
              <HistorialCrediticio
                modeloDatos={modeloPm}
                setLoading={setLoading}
                tipoPersona={tipoPersona}
                setStyleHr={setStyleHr}
                setShowResult={setShowResult}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
                webView={webView}
              />
            </animated.div>
          )
      )}
      {transitionDiesiocho(
        (style, visible) =>
          visible === true && (
            <animated.div style={style}>
              <CargaDocumentos
                setShowResult={setShowResult}
                tipoPersona={tipoPersona}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                modeloPm={modeloPm}
                setLoading={setLoading}
              />
            </animated.div>
          )
      )}
      {transitionVeinte(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPm?.principalAccionista !== undefined &&
                modeloPm?.principalAccionista?.conyuge !== undefined && (
                  <PrincipalAccionistaConyuge
                    modeloPm={modeloPm}
                    axiosData={axiosData}
                    aniosDomicilio={aniosDomicilio}
                    mesesDomicilo={mesesDomicilo}
                    anios={anios}
                    setModeloPm={setModeloPm}
                    setStyleHr={setStyleHr}
                    handleSeccionPyme={handleSeccionPyme}
                    handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                    setObligadosSolidariosInformacionBasica={
                      setObligadosSolidariosInformacionBasica
                    }
                    setLoading={setLoading}
                    setCantidadConyugeOS={setCantidadConyugeOS}
                    cantidadConyugeOS={cantidadConyugeOS}
                  />
                )}
            </animated.div>
          )
      )}

      {transitionVeintidos(
        (style, visible) =>
          visible && <animated.div style={style}></animated.div>
      )}
      {transitionVeintitres(
        (style, visible) =>
          visible && <animated.div style={style}></animated.div>
      )}
    </>
  );
};

export default FormularioPersonaMoral;
