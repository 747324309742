import React, { useState } from "react";
import {
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  ID_PM,
  SECCION_HISTORIAL_SOLICITANTE,
  SECCION_HISTORIAL_SOLICITANTE_COYUGE,
  SECCION_HISTORIAL_CREDITICIO
} from "../../../../../utils/constantes";
import ConsultaTradicionalSolicitantePFAE from "./ConsultaTradicionalSolicitantePFAE";
import ConsultaTradicionalOSPFAE from "./ConsultaTradicionalOSPFAE";
import ConsultaTradicionalOSPFAEPM from "./ConsultaTradicionalOSPFAEPM";
import ConsultaTradicionalConyugeOSPFAEPFAE from "./ConsultaTradicionalConyugeOSPFAEPFAE";
import ConsultaTradicionalSolicitanteConyugePFAE from "./ConsultaTradicionalSolicitanteConyugePFAE";
import '../../PM/ConsultaTradicional/consultaTradicional.css'

const ConsultaTradicionalPFAE = ({
  tipoPersona,
  modeloDatos,
  setLoading,
  selectedConsultaTradicionalPFAE,
  setSelectedConsultaTradicionalPFAE,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setStyleHr,
  setShowResult,
  setContadorOS,
  obligadosSolidarios,
  idEstadoCivilSolicitante,
  setResultadoEvaluacionRiesgos,
  docPendientesSolicitante,
  docPendientesSolicitanteConyuge,
  webView,
}) => {
  const [documento, setDocumento] = useState(modeloDatos?.idCliente);

  const obtenerAnteriorOSHistorialPFAE = (idCliente, numOS) => {
    let obligadoSolidarioAnterior = null;
    let retrocederSolicitud = true;

    if (obligadosSolidarios.length > 0) {
      let obligadoSolidarioActual = obligadosSolidarios?.filter(
        (e) => e.numero === numOS
      );

      if (numOS == 1) {
        if (parseInt(obligadoSolidarioActual[0].tipoPersonaKey) !== ID_PM) {
          if (
            obligadoSolidarioActual[0]?.obligadoSolidario
              ?.idObligadoSolidario == idCliente
          ) {
            if (
              idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              modeloDatos?.conyuge?.generales?.esObligadoSolidario
            ) {
              if (docPendientesSolicitanteConyuge) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE_COYUGE;
              } else {
                if (docPendientesSolicitante) {
                  obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                } else {
                  obligadoSolidarioAnterior = null;
                }
              }
            } else {
              if (docPendientesSolicitante) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
              } else {
                obligadoSolidarioAnterior = null;
              }
            }
          } else {
            if (
              obligadoSolidarioActual[0]?.obligadoSolidario?.conyuge
                ?.idConyuge == idCliente
            ) {
              if (obligadoSolidarioActual[0]?.obligadoSolidario?.documentosPendientes) {
                obligadoSolidarioAnterior = obligadoSolidarioActual[0]?.obligadoSolidario?.idObligadoSolidario;
              } else {
                if (
                  idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                  modeloDatos?.conyuge?.generales?.esObligadoSolidario
                ) {
                  if (docPendientesSolicitanteConyuge) {
                    obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE_COYUGE;
                  } else {
                    if (docPendientesSolicitante) {
                      obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                    } else {
                      obligadoSolidarioAnterior = null;
                    }
                  }
                } else {
                  if (docPendientesSolicitante) {
                    obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                  } else {
                    obligadoSolidarioAnterior = null;
                  }
                }
              }

            }
          }
        } else {
          if (
            idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
            modeloDatos?.conyuge?.generales?.esObligadoSolidario
          ) {
            if (docPendientesSolicitanteConyuge) {
              obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE_COYUGE;
            } else {
              if (docPendientesSolicitante) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
              } else {
                obligadoSolidarioAnterior = null;
              }
            }
          } else {
            if (docPendientesSolicitante) {
              obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
            } else {
              obligadoSolidarioAnterior = null;
            }
          }
        }
      } else {
        if (parseInt(obligadoSolidarioActual[0].tipoPersonaKey) !== ID_PM) {
          if (
            obligadoSolidarioActual[0]?.obligadoSolidario?.conyuge?.idConyuge ==
            idCliente
          ) {
            if (obligadoSolidarioActual[0]?.obligadoSolidario?.documentosPendientes) {
              obligadoSolidarioAnterior = obligadoSolidarioActual[0]?.obligadoSolidario?.idObligadoSolidario;
            } else {
              retrocederSolicitud = false;
            }
          } else {
            retrocederSolicitud = false;
          }
        } else {
          retrocederSolicitud = false;
        }

        if (retrocederSolicitud === false) {
          let retrocederOS = true;
          let indice = 1
          let numOsIndice;
          do {
            let anteriorObligadoSolidario = obligadosSolidarios?.filter(
              (e) => e.numero === numOS - indice
            );

            if (parseInt(anteriorObligadoSolidario[0].tipoPersonaKey) !== ID_PM) {
              if (
                anteriorObligadoSolidario[0]?.obligadoSolidario?.generales?.estadoCivilId == ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                anteriorObligadoSolidario[0]?.obligadoSolidario?.conyuge.generales?.esObligadoSolidario
              ) {
                if (anteriorObligadoSolidario[0]?.obligadoSolidario?.conyuge?.documentosPendientes) {
                  obligadoSolidarioAnterior = anteriorObligadoSolidario[0].obligadoSolidario?.conyuge?.idConyuge;
                } else {
                  if (anteriorObligadoSolidario[0]?.obligadoSolidario?.documentosPendientes) {
                    obligadoSolidarioAnterior = anteriorObligadoSolidario[0]?.obligadoSolidario?.idObligadoSolidario;
                  } else {
                    retrocederOS = false;
                  }
                }
              } else {
                if (anteriorObligadoSolidario[0]?.obligadoSolidario?.documentosPendientes) {
                  obligadoSolidarioAnterior = anteriorObligadoSolidario[0]?.obligadoSolidario?.idObligadoSolidario;
                } else {
                  retrocederOS = false;
                }
              }
            } else {
              if (anteriorObligadoSolidario[0]?.obligadoSolidarioPM?.documentosPendientes) {
                obligadoSolidarioAnterior = anteriorObligadoSolidario[0]?.obligadoSolidarioPM?.idObligadoSolidario;
              } else {
                retrocederOS = false;
              }
            }
            indice = indice + 1;
            numOsIndice = numOS - indice;
          } while (obligadoSolidarioAnterior === null && numOsIndice > 0);

          if (retrocederOS === false && obligadoSolidarioAnterior === null) {

            if (
              idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              modeloDatos?.conyuge?.generales?.esObligadoSolidario
            ) {
              if (docPendientesSolicitanteConyuge) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE_COYUGE;
              } else {
                if (docPendientesSolicitante) {
                  obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                } else {
                  obligadoSolidarioAnterior = null;
                }
              }
            } else {
              if (docPendientesSolicitante) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
              } else {
                obligadoSolidarioAnterior = null;
              }
            }
          }
        }
      }
    }
    return obligadoSolidarioAnterior;
  };


  const redireccionBtnAtrasHistorialPFAE = (osAnterior) => {
    if (osAnterior !== null) {
      setSelectedConsultaTradicionalPFAE(osAnterior);
    } else {
      handleRegresarSeccionPyme(SECCION_HISTORIAL_CREDITICIO);
    }
  }

  return (
    <>
      <div className="container navigation navigation-select formulario--menu-os">
        <>
          {docPendientesSolicitante && (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                className={`${selectedConsultaTradicionalPFAE ===
                    SECCION_HISTORIAL_SOLICITANTE
                    ? "bold tab__selected text__red pointer tab"
                    : null
                  } pointer p-2 mb-0`}
                onClick={() => {
                  setSelectedConsultaTradicionalPFAE(
                    SECCION_HISTORIAL_SOLICITANTE
                  );
                  setDocumento(modeloDatos?.idCliente);
                }}
              >
                Solicitante
              </p>
            </div>
          )}
          {(idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
            modeloDatos?.conyuge?.generales?.esObligadoSolidario &&
            docPendientesSolicitanteConyuge) && (
              <p
                style={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={`${selectedConsultaTradicionalPFAE ===
                    SECCION_HISTORIAL_SOLICITANTE_COYUGE ||
                    selectedConsultaTradicionalPFAE ===
                    modeloDatos?.conyuge?.idConyuge
                    ? "bold tab__selected text__red pointer tab"
                    : null
                  } pointer p-2 mb-0`}
                onClick={() =>
                  setSelectedConsultaTradicionalPFAE(
                    SECCION_HISTORIAL_SOLICITANTE_COYUGE
                  )
                }
              >
                Cónyuge solicitante
              </p>
            )}
          {obligadosSolidarios.map((item, index) => {
            return (
              <div key={index} className="obligados_solidarios_flex">
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {(item?.obligadoSolidario?.idObligadoSolidario !== undefined &&
                    item?.obligadoSolidario?.documentosPendientes) && (
                      <p
                        style={{
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className={`${selectedConsultaTradicionalPFAE ===
                            item?.obligadoSolidario?.idObligadoSolidario
                            ? "bold tab__selected text__red pointer tab"
                            : null
                          } pointer p-2 mb-0`}
                        onClick={() =>
                          setSelectedConsultaTradicionalPFAE(
                            item?.obligadoSolidario?.idObligadoSolidario
                          )
                        }
                      >
                        Obligado solidario {index + 1}
                      </p>
                    )}
                  {(item?.obligadoSolidarioPM?.idObligadoSolidario !== undefined &&
                    item?.obligadoSolidarioPM?.documentosPendientes) && (
                      <p
                        className={`${selectedConsultaTradicionalPFAE ===
                            item?.obligadoSolidarioPM?.idObligadoSolidario
                            ? "bold tab__selected text__red pointer tab"
                            : null
                          } pointer p-2 mb-0`}
                        onClick={() =>
                          setSelectedConsultaTradicionalPFAE(
                            item?.obligadoSolidarioPM?.idObligadoSolidario
                          )
                        }
                      >
                        Obligado solidario {index + 1}
                      </p>
                    )}
                </div>

                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {(item?.obligadoSolidario?.conyuge.idConyuge !== undefined &&
                    item?.obligadoSolidario?.conyuge.generales?.esObligadoSolidario &&
                    item?.obligadoSolidario?.conyuge.documentosPendientes) && (
                      <p
                        className={`${selectedConsultaTradicionalPFAE ===
                            item?.obligadoSolidario?.conyuge?.idConyuge
                            ? "bold tab__selected text__red pointer tab"
                            : null
                          } pointer p-2 mb-0`}
                        onClick={() =>
                          setSelectedConsultaTradicionalPFAE(
                            item?.obligadoSolidario?.conyuge?.idConyuge
                          )
                        }
                      >
                        Cónyuge obligado solidario {index + 1}
                      </p>
                    )}
                </div>
              </div>
            );
          })}
        </>
      </div>
      {selectedConsultaTradicionalPFAE ===
        SECCION_HISTORIAL_SOLICITANTE && (
          <ConsultaTradicionalSolicitantePFAE
            documento={documento}
            setStyleHr={setStyleHr}
            setLoading={setLoading}
            modeloDatos={modeloDatos}
            setContadorOS={setContadorOS}
            setShowResult={setShowResult}
            handleSeccionPyme={handleSeccionPyme}
            handleRegresarSeccionPyme={handleRegresarSeccionPyme}
            setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
            setSelectedConsultaTradicionalPFAE={
              setSelectedConsultaTradicionalPFAE
            }
            webView={webView}
          />
        )}
      {selectedConsultaTradicionalPFAE ===
        SECCION_HISTORIAL_SOLICITANTE_COYUGE ||
        selectedConsultaTradicionalPFAE ===
        modeloDatos?.conyuge?.idConyuge ? (
        <ConsultaTradicionalSolicitanteConyugePFAE
          setLoading={setLoading}
          setStyleHr={setStyleHr}
          modeloDatos={modeloDatos}
          setContadorOS={setContadorOS}
          setShowResult={setShowResult}
          handleSeccionPyme={handleSeccionPyme}
          idCliente={modeloDatos?.conyuge?.idConyuge}
          setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
          selectedConsultaTradicionalPFAE={modeloDatos?.conyuge?.idConyuge}
          setSelectedConsultaTradicionalPFAE={
            setSelectedConsultaTradicionalPFAE
          }
          docPendientesSolicitante={docPendientesSolicitante}
          redireccionBtnAtrasHistorialPFAE={redireccionBtnAtrasHistorialPFAE}
          webView={webView}
        />
      ) : (
        <></>
      )}
      {obligadosSolidarios !== undefined &&
        obligadosSolidarios.map((item, index) => {
          return (
            <div key={index}>
              {selectedConsultaTradicionalPFAE ===
                item?.obligadoSolidario?.idObligadoSolidario && (
                  <ConsultaTradicionalOSPFAE
                    numOS={index + 1}
                    setLoading={setLoading}
                    setStyleHr={setStyleHr}
                    modeloDatos={modeloDatos}
                    setContadorOS={setContadorOS}
                    setShowResult={setShowResult}
                    tipoPersonaKey={item.tipoPersonaKey}
                    handleSeccionPyme={handleSeccionPyme}
                    idCliente={item?.obligadoSolidario?.idObligadoSolidario}
                    setResultadoEvaluacionRiesgos={
                      setResultadoEvaluacionRiesgos
                    }
                    selectedConsultaTradicionalPFAE={
                      selectedConsultaTradicionalPFAE
                    }
                    setSelectedConsultaTradicionalPFAE={
                      setSelectedConsultaTradicionalPFAE
                    }
                    obtenerAnteriorOSHistorial={obtenerAnteriorOSHistorialPFAE}
                    nombreOs={
                      item?.obligadoSolidario?.generales?.nombre +
                      " " +
                      item?.obligadoSolidario?.generales?.apellidoPaterno
                    }
                    redireccionBtnAtrasHistorialPFAE={redireccionBtnAtrasHistorialPFAE}
                    webView={webView}
                  />
                )}
              {selectedConsultaTradicionalPFAE ===
                item?.obligadoSolidarioPM?.idObligadoSolidario && (
                  <ConsultaTradicionalOSPFAEPM
                    numOS={index + 1}
                    setLoading={setLoading}
                    setStyleHr={setStyleHr}
                    modeloDatos={modeloDatos}
                    setShowResult={setShowResult}
                    setContadorOS={setContadorOS}
                    handleSeccionPyme={handleSeccionPyme}
                    idCliente={item?.obligadoSolidarioPM?.idObligadoSolidario}
                    setResultadoEvaluacionRiesgos={
                      setResultadoEvaluacionRiesgos
                    }
                    selectedConsultaTradicionalPFAE={
                      selectedConsultaTradicionalPFAE
                    }
                    setSelectedConsultaTradicionalPFAE={
                      setSelectedConsultaTradicionalPFAE
                    }
                    obtenerAnteriorOSHistorial={obtenerAnteriorOSHistorialPFAE}
                    razonSocialOs={
                      item?.obligadoSolidarioPM?.generales?.razonSocial
                    }
                    redireccionBtnAtrasHistorialPFAE={redireccionBtnAtrasHistorialPFAE}
                    webView={webView}
                  />
                )}

              {selectedConsultaTradicionalPFAE ===
                item?.obligadoSolidario?.conyuge.idConyuge && (
                  <ConsultaTradicionalConyugeOSPFAEPFAE
                    numOS={index + 1}
                    setLoading={setLoading}
                    setStyleHr={setStyleHr}
                    setContadorOS={setContadorOS}
                    modeloDatos={modeloDatos}
                    setShowResult={setShowResult}
                    handleSeccionPyme={handleSeccionPyme}
                    idCliente={item?.obligadoSolidario?.conyuge?.idConyuge}
                    setResultadoEvaluacionRiesgos={
                      setResultadoEvaluacionRiesgos
                    }
                    selectedConsultaTradicionalPFAE={
                      selectedConsultaTradicionalPFAE
                    }
                    setSelectedConsultaTradicionalPFAE={
                      setSelectedConsultaTradicionalPFAE
                    }
                    obtenerAnteriorOSHistorial={obtenerAnteriorOSHistorialPFAE}
                    nombreOs={
                      item?.obligadoSolidario?.conyuge?.generales?.nombre +
                      " " +
                      item?.obligadoSolidario?.conyuge?.generales
                        ?.apellidoPaterno
                    }
                    redireccionBtnAtrasHistorialPFAE={redireccionBtnAtrasHistorialPFAE}
                    webView={webView}
                  />
                )}
            </div>
          );
        })}
    </>
  );
};
export default ConsultaTradicionalPFAE;
