import Swal from "sweetalert2";
import {
  localStorageToken
} from "../config/Variables";

import {  
  api_salvar_token_autoservicio,
} from "../api/api_servicios_rest";

function random() {
  return Math.random().toString(36).substr(2); // Eliminar `0.`
};

export function generarToken() {
  return random() + random() ; // Para hacer el token más largo
};


export async function generarTokenAutoservicio(rfcCliente) {    
  let data = {
    rfcCliente: rfcCliente, //DEFAULT
    tokenAutoservicio: generarToken(),     
  };

  const res = await api_salvar_token_autoservicio(data);
  console.log("res", res);

  if (res.statusText === "OK") {
    const data = res.data;

    if (data) {
      localStorage.setItem(
        localStorageToken,
        data.tokenPymeAutoservicio
      );    
    }
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Ocurrió un error al generar token autoservicio",
      confirmButtonColor: "#d33",
      confirmButtonText: "Aceptar",
    });
  }
}

