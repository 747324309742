import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api_validar_nip_PM } from "../../../../../api/api_servicios_rest";
import {
  PARTICIPANTE_SOLICITANTE,
  SECCION_HISTORIAL_CREDITICIO,
} from "../../../../../utils/constantes";
import CheckBoxInput from "../../../../basics/checkBox";
import { validateValue } from "../../../../../functions/fieldFunctions";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";

const ConsultaPornipPM = ({
  setLoading,
  setSelectedConsultaNip,
  handleRegresarSeccionPyme,
  modeloDatos,
}) => {
  const formik = useFormik({
    initialValues: initialValues(modeloDatos),
    validateOnBlur: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      formData.participante = PARTICIPANTE_SOLICITANTE;
      setLoading(true);
      validarNipPM(formData)
        .then((data) => setLoading(false))
        .catch((e) => console.error(e));
    },
  });

  async function validarNipPM(formData) {
    setSelectedConsultaNip(2);
  }

  return (
    <>
      <div className="row container">
        <form
          name="form-consulta-nip-solicitante"
          onSubmit={formik.handleSubmit}
        >
          <div className="row container">
            <div className="col-12 col-sm-12 mb-3">
              <h2
                style={{ margin: "2rem 0 1rem 0" }}
                className="f-s-5 text-center"
              >
                AUTORIZACIÓN DE CONSULTA
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckBoxInput
                state=""
                className="form-check-input"
                type="checkbox"
                name="check"
                checked={formik.values.check}
                onChange={(e) =>
                  formik.setFieldValue("check", !formik.values.check)
                }
                value=""
                id="solicitante-autorizacion"
                typeOfCheck="normal"
              />
              <label className="form-check-label label-ca" htmlFor="final">
                Por este conducto autorizo expresamente a Banco Mercantil del
                Norte, S.A., Institución de Banca Múltiple, Grupo Financiero
                Banorte, para que por conducto de sus funcionarios facultados
                lleve a cabo investigaciones, sobre mi comportamiento crediticio
                en las sociedades de información crediticia que estime
                conveniente. Así mismo, declaro que conozco la naturaleza y
                alcance de la información que se solicitará, del uso que Banco
                Mercantil del Norte, S.A., Institución de Banca Múltiple, Grupo
                Financiero Banorte, hará de tal información y de que ésta podrá
                realizar consultas periódicas de mi historial crediticio,
                consintiendo que esta autorización se encuentre vigente por un
                periodo de 3 años contados a partir de la fecha de su expedición
                y en todo caso durante el tiempo que mantengamos relación
                jurídica.
              </label>              
            </div>
           
          </div>
          <br/>
          <div
            style={{
              width: "100%",
              margin: "-1rem 0",
              display: "flex",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <span className="text__center text_red">
              {formik.errors?.check}
            </span>
          </div>
          <div
            style={{
              display: "grid",
              placeItems: "center",
              gap: ".5rem",
              margin: "1rem 0 0 0",
            }}
          >
            <InputWithError
              name="nip"
              value={formik?.values?.nip}
              onChange={(e) => {
                if (validateValue(e.target.value, "number")) {
                  formik.setFieldValue("nip", e.target.value);
                }
              }}
              InputProps={{ maxLength: 5 }}
              label="Ingresa tu NIP de verificación *"
              showErrorMessage={formik.errors?.nip}
              errorMessage={formik.errors?.nip}
            />
          </div>

          <div className="d-flex justify-content-center gap-2 my-3">
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                handleRegresarSeccionPyme(SECCION_HISTORIAL_CREDITICIO);
              }}
            >
              Atrás
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={formik.isSubmitting}>
              Siguiente
            </PrimaryButton>
          </div>
        </form>
      </div>
    </>
  );
};
export default ConsultaPornipPM;

function initialValues(modeloDatos) {
  return {
    idClienteSolicitante: modeloDatos?.idCliente,
    idCliente: modeloDatos?.idCliente,
    check: false,
    nip: "",
    participante: "",
    tipoSolicitud: "PM",
    numParticipante: 1,
  };
}

function validationSchema() {
  return {
    nip: Yup.string().required("Dato requerido."),
    check: Yup.boolean().oneOf([true], "Debe autorizar consulta."),
  };
}
