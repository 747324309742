import axios from "axios";
import {
  api_catalogos_datos_operacion,
  api_get_cat_simple,
  api_limites_monto_credito,
  api_obtener_municipio_por_estado,
  api_obtener_sucursales_by_estado_and_municipio,
  api_save_datosOperacion,
} from "../../../api/api_servicios_rest";
import {
  sessionStorageDatosOperacion,
  sessionStorageMontoMaximoCredito,
  sessionStorageMontoMinimoCredito,
} from "../../../config/Variables";
import {
  sweetAlertError,
  sweetAlertWarning,
} from "../../helpers/alertas/alertas";

export const optionsRevolvente = [
  {
    meses: 12,
    mensualidad:
      "Por cada 100 mil pesos que dispongas de tu línea, al mes deberás pagar intereses por $1,657 pesos. /n *Interés calculado con una tasa de referencia del 19.76%",
    card: "1",
  },
];

export const obtenerLimiteMonto = async () => {
  const objLimiteMonto = {
    tasaAnual: 0,
    iva: 0,
    montoMinimoDeCredito: 0,
    montoMaximoDeCredito: 0,
    montoMinimoFormat: 0,
    montoMaximoFormat: 0,
    valorFactura: 0,
  };

  try {
    const response = await api_limites_monto_credito();
    if (response.statusText === "OK") {
      const { data } = response;
      if (data) {
        sessionStorage.setItem(
          sessionStorageMontoMinimoCredito,
          data.montoMinimoDeCredito
        );
        sessionStorage.setItem(
          sessionStorageMontoMaximoCredito,
          data.montoMaximoDeCredito
        );
        objLimiteMonto.tasaAnual = data?.tasaAnual;
        objLimiteMonto.iva = data?.iva;
        const montoMin = data.montoMinimoDeCredito;
        const montoMax = data.montoMaximoDeCredito;

        if (montoMin && montoMax) {
          const montoMinString =
            "$" + parseFloat(montoMin).toLocaleString("es-MX");
          const montoMaxString =
            "$" + parseFloat(montoMax).toLocaleString("es-MX");

          objLimiteMonto.montoMinimoDeCredito = parseFloat(montoMin);
          objLimiteMonto.montoMaximoDeCredito = parseFloat(montoMax);
          objLimiteMonto.montoMinimoFormat = montoMinString;
          objLimiteMonto.montoMaximoFormat = montoMaxString;
          objLimiteMonto.valorFactura = montoMinString;
        }
      }
      return objLimiteMonto;
    } else {
      sweetAlertError("Ocurrió un error al obtener límites del monto crédito");
    }
  } catch (error) {
    console.log(error);
  }
};

export const obtenerCatCreditoSimple = async (valorFactura) => {
  const datosCredito = {
    letPlazo: 18,
    valorFactura: valorFactura.split("$")[1].replace(/,/g, ""),
  };

  try {
    const response = await api_get_cat_simple(datosCredito);
    if (response.statusText === "OK") {
      const data = response?.data;
      const cat = data?.cat * 100;
      return cat;
    } else {
      sweetAlertWarning("Ocurrió un error al obtener el cat del crédito");
    }
  } catch (error) {
    console.log(error);
  }
};

export const calculoMensualidad = (montoCredito, plazo, tasaAnualAts) => {
  if (montoCredito !== "") {
    var tasaConIva;
    var tasaAnual;
    var pagoCalculo;
    let interesAnual = tasaAnualAts;
    montoCredito = montoCredito.toString().replaceAll(",", "");
    if (Number.isNaN(montoCredito) === false) {
      montoCredito = parseFloat(montoCredito);
      tasaConIva = interesAnual * 1.16;
      tasaAnual = tasaConIva / 12;
      pagoCalculo =
        parseFloat(montoCredito) /
        ((1 - (1 + tasaAnual) ** -plazo) / tasaAnual);
      pagoCalculo = parseFloat(pagoCalculo.toFixed(2));
      pagoCalculo = pagoCalculo.toLocaleString("es-MX");
      return pagoCalculo;
    }
  } else {
    return 0.0;
  }
};

export const siguientePaso = async (webView, infoData) => {
  const response = await api_save_datosOperacion(infoData);
  if (response.statusText === "OK") {
    const data = response.data;
    if (data.success) {
      sessionStorage.setItem(
        sessionStorageDatosOperacion,
        data.datosDeOperacionId
      );

      if (webView) {
        return "/prospeccionBanorte";
      } else {
        return "/prospeccion";
      }
    } else {
      sweetAlertWarning(data.mensaje);
    }
  } else {
    sweetAlertWarning("Ocurrió un error al guardar datos cliente");
  }
};

export const obtenerMunicipiosPorEstado = async (idEstado) => {
  const dataConsulta = {
    idEstado: idEstado,
  };

  try {
    const response = await api_obtener_municipio_por_estado(dataConsulta);
    const data = response.data;
    if (data.success) {
      const { municipios } = data;
      return municipios;
    }
  } catch (error) {
    console.log(error);
    sweetAlertError("Ocurrió un error al consultar municipios");
  }
};

export const obtenerSucursalesPorEstadoMunicipio = async (dataConsulta) => {
  try {
    const response = await api_obtener_sucursales_by_estado_and_municipio(
      dataConsulta
    );
    const data = response.data;
    if (data.success && data.sucursales.length > 0) {
      return { suscursales: data.sucursales, flag: true };
    }
    sweetAlertError(data.mensaje);
  } catch (error) {
    console.log(error);
    sweetAlertError("Ocurrio un error");
  }
};

export const obtenerTablaAmortizacion = async (path, plazo, valorFactura) => {
  var config = {
    method: "post",
    url: `${path}/rest/obtenerTablaAmortizacion?plazo=${plazo}&monto=${parseInt(
      valorFactura.split("$")[1].replace(/,/g, "")
    )}`,
    headers: {},
  };

  try {
    const response = await axios(config);
    if (response) {
      return response.data.resumenSimulacion;
    }
  } catch (error) {
    console.log(error);
  }
};

export const catalogosDatosOperacion = async () => {
  try {
    const response = await api_catalogos_datos_operacion();
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
