import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { path } from "../../config/Path";
import Popup from "../tools/Popup";
import "./Cotizador.css";
import { isEmpty } from "../../functions/fieldFunctions";
import {
  api_catalogos_datos_operacion,
  api_obtener_municipio_por_estado,
  api_obtener_sucursales_by_estado_and_municipio,
} from "../../api/api_servicios_rest_crediactivo";
import { PrimaryButton } from "../basics/Buttons/Buttons";
import { selectStyles } from "../../styles/select-styles";
import IconClose from "./../../icons/crossIcon.png";
import { InputAdornment, TextField } from "@mui/material";
import SelectWithError from "../basics/SelectWithError/SelectWithError";
import SelectWithErrorTooltipCompuesto from "../basics/SelectWithError/SelectWithErrorTooltipCompuesto";
import CreditType from "./CreditType/CreditType";
import RadioInput from "../basics/radioButton";
import {
  TIPO_CREDITO_REVOLVENTE,
  TIPO_TASA_VARIABLE,
  TIPO_DESTINO_CAPITAL_TRABAJO,
} from "../../utils/constantes";
import {
  handlePesosReturn,
  obtenerCatCreditoSimple,
  obtenerLimiteMontoHelper,
  obtenertablaAmortizacion,
  optionsRevolvente,
  siguienteHelper,
} from "./helpers/cotizadorHelper";
import { sweetAlertError } from "../campos/components/alertas/alertas";

const Cotizador = () => {
  const navigate = useNavigate();
  const [montoMinimoDeCredito, setMontoMinimoDeCredito] = useState(0);
  const [montoMaximoDeCredito, setMontoMaximoDeCredito] = useState(0);
  const [montoMinimoFormat, setMontoMinimoFormat] = useState("");
  const [montoMaximoFormat, setMontoMaximoFormat] = useState("");
  const [cat, setCat] = useState("");

  let cantidadMin = parseInt(montoMinimoDeCredito);

  const [valorFactura, setValorFactura] = useState("$200,000");

  // TRIGGERS
  const [showTabla, setShowTabla] = useState(false);
  const [tablaData, setTablaData] = useState([]);
  const [plazo, setPlazo] = useState();
  const [sucursales, setSucursales] = useState([]);
  const [tasaAnualAts, setTasaAnual] = useState(0.0);
  const [tasaIva, setIva] = useState(0.0);
  const [showTablaSucursales, setShowTablaSucursales] = useState(true);
  const [numCR, setNumCR] = useState("");
  const [nombreSucursal, setNombreSucursal] = useState();
  const [mensajeErrorTipoCredito, setMensajeErrorTipoCredito] = useState(null);
  const [mensajeErrorTipoTasa, setMensajeErrorrTipoTasa] = useState("");
  const [mensajeErrorDestinoCredito, setMensajeErrorDestinoCredito] =
    useState("");
  const [mensajeErrorSucursal, setMensajeErrorSucursal] = useState("");
  const [mensajeErrorPlazo, setMensajeErrorPlazo] = useState("");
  const [mensajeErrorEstado, setMensajeErrorEstado] = useState(null);
  const [mensajeErrorMunicipio, setMensajeErrorMunicipio] = useState(null);
  const [showSelect, setShowSelect] = useState(false);

  const [loading, setLoading] = useState(false);

  // AXIOS
  const [axiosData, setAxiosData] = useState();

  const [tipoCredito, setTipoCredito] = useState("");
  const [tipoTasa, setTipoTasa] = useState({
    label: "",
    value: "",
  });
  const [destinoCredito, setDestinoCredito] = useState({
    label: "",
    value: "",
  });
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [municipios, setMunicipios] = useState([]);

  // CARDS SELECTED
  const [selectedCard, setSelectedCard] = useState("");

  const obtenerCat = async () => {
    let letPlazo = 18;
    const datosCredito = {
      letPlazo: letPlazo,
      valorFactura: valorFactura.split("$")[1].replace(/,/g, ""),
    };
    const response = await obtenerCatCreditoSimple(datosCredito);
    if (response) {
      setCat(response);
    }
  };

  useEffect(() => {
    const obtenerLimiteMonto = async () => {
      const response = await obtenerLimiteMontoHelper();
      if (response) {
        const {
          iva,
          tasaAnual,
          montoMaximoCredito,
          montoMinimoCredito,
          montoMaximoFormat,
          montoMinimoFormat,
          valorFactura,
        } = response;

        setTasaAnual(tasaAnual);
        setIva(iva);
        setMontoMinimoDeCredito(montoMinimoCredito);
        setMontoMaximoDeCredito(montoMaximoCredito);
        setMontoMinimoFormat(montoMinimoFormat);
        setMontoMaximoFormat(montoMaximoFormat);
        setValorFactura(valorFactura);
      }
    };

    obtenerLimiteMonto();
  }, []);

  useEffect(() => {
    const tablaAmortizacion = async () => {
      if (showTabla) {
        let letPlazo;
        if (tipoCredito.id.toString() === "2") {
          letPlazo = 12;
        } else if (tipoCredito.id.toString() === "1") {
          if (selectedCard.toString() === "1") letPlazo = 18;
          else if (selectedCard.toString() === "2") letPlazo = 24;
          else if (selectedCard.toString() === "3") letPlazo = 36;
        }

        setPlazo(letPlazo);
        const response = await obtenertablaAmortizacion(
          path,
          letPlazo,
          valorFactura
        );
        setTablaData(response.resumenSimulacion);
      }
    };

    tablaAmortizacion();
  }, [showTabla]);

  useEffect(() => {
    if (selectedCard !== "" && showTabla === false) {
      let letPlazo;

      if (tipoCredito?.id?.toString() === "2") {
        letPlazo = 12;
      } else if (tipoCredito?.id?.toString() === "1") {
        if (selectedCard.toString() === "1") letPlazo = 18;
        else if (selectedCard.toString() === "2") letPlazo = 24;
        else if (selectedCard.toString() === "3") letPlazo = 36;
      }

      setPlazo(letPlazo);
      console.log("letPlazo", letPlazo);
    }
  }, [selectedCard, tipoCredito]);

  useEffect(() => {
    const obtenerCatalogosDatosOperacion = async () => {
      try {
        const response = await api_catalogos_datos_operacion();
        setAxiosData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    obtenerCatalogosDatosOperacion();
  }, []);

  const handlePesos = (e, handleChange) => {
    let { value } = e.target;

    if (value) {
      value = (Number(value.replace(/\D/g, "")) || "")
        .toLocaleString()
        .replaceAll(".", ",");
      handleChange(value);
    } else {
      handleChange("");
    }

    value = "$" + value;
    handleChange(value);
  };

  const handleRangeBar = (value, handleChange) => {
    if (value.toString() === "0") {
      handleChange(montoMinimoFormat);
      return;
    }
    value = "$" + value;
    if (value) {
      value = (Number(value.replace(/\D/g, "")) || "")
        .toLocaleString()
        .replaceAll(".", ",");
      handleChange(value);
    } else {
      handleChange("$0");
    }
    value = "$" + value;
    handleChange(value);
  };

  const validarDatosOperacionNotNulos = async () => {
    let datosValidos = true;

    if (selectedCard === "") {
      setMensajeErrorPlazo("Dato requerido.");
      datosValidos = false;
    }

    if (numCR === "") {
      setMensajeErrorSucursal("Datos requeridos.");
      datosValidos = false;
    }

    if (tipoCredito === "") {
      setMensajeErrorTipoCredito("Dato requerido.");
      datosValidos = false;
    }

    if (tipoTasa.value === "") {
      setMensajeErrorrTipoTasa("Dato requerido.");
      datosValidos = false;
    }

    if (destinoCredito.value === "") {
      setMensajeErrorDestinoCredito("Dato requerido.");
      datosValidos = false;
    }

    if (datosValidos) {
      const data = {
        numeroSucursal: numCR, //DEFAULT
        tipoDestinoCreditoId: destinoCredito?.id,
        montoCreditoFloat: valorFactura.split("$")[1] + ".00",
        tipoGarantiId: 1, //DEFAULT
        plazo: plazo,
        tipoTasaId: tipoTasa?.id,
        tipoOperacionId: 1, //DEFAULT
        tipoProgramaId: 2, //DEFAULT
        tipoDeCreditoId: tipoCredito?.id,
        tasaAnual: tasaAnualAts * 100, //DEFAULT
        iva: tasaIva, //DEFAULT
        relacionPrimerGrado: false, //DEFAULT
        diferimiento: false, //DEFAULT
      };
      const response = await siguienteHelper(data);
      if (response) {
        navigate(response);
      }
    }
  };

  const obtenerMunicipiosByEstado = async (idEstado) => {
    const dataConsulta = {
      idEstado: idEstado,
    };

    try {
      const response = await api_obtener_municipio_por_estado(dataConsulta);
      const data = response.data;
      if (data.success) {
        setMunicipios(data.municipios);
        setMunicipio("");
        setMensajeErrorMunicipio("");
        return;
      }
      sweetAlertError("Ocurrió un error al consultar municipios");
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerSucursalesByEstadoAndMunicipio = async (idMunicipio) => {
    const dataConsulta = {
      idEstado: estado.id,
      idMunicipio: idMunicipio,
    };
    try {
      const response = await api_obtener_sucursales_by_estado_and_municipio(
        dataConsulta
      );
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setSucursales(data.sucursales);
          setShowTablaSucursales(true);
          setMensajeErrorSucursal("");
          return;
        }
        sweetAlertError(data?.mensaje);
        return;
      }
      sweetAlertError("Ocurrió un error al obtener sucursales");
    } catch (error) {
      console.error(error);
    }
  };

  const handleBlurMontoCredito = (value) => {
    //El valor viene con un signo de $, asi que se lo quitamos
    const [sign, mount] = value.split("$");

    //Eliminamos todas las comas de la cantidad
    const integer = mount.replaceAll(",", "");

    //Parseamos el numero a entero
    const number = parseInt(integer);

    if (number < montoMinimoDeCredito || number > montoMaximoDeCredito) {
      if (number < montoMinimoDeCredito) {
        setValorFactura(montoMinimoFormat);
      } else {
        setValorFactura(montoMaximoFormat);
      }
    }
  };

  const optionsSimple = [
    {
      meses: 18,
      mensualidad: "/ Mensualidad estimada - CAT " + cat + "%",
      card: "1",
    },
    {
      meses: 24,
      mensualidad: "/ Mensualidad estimada - CAT " + cat + "%",
      card: "2",
    },
    {
      meses: 36,
      mensualidad: "/ Mensualidad estimada - CAT " + cat + "%",
      card: "3",
    },
  ];

  return (
    <div className="cotizador--container">
      <Popup show={showTabla}>
        <div className="bgc-gray-opacity tabla-amortizacion--container">
          <div className="my-shadow radius bgc-white black tabla-amortizacion--table">
            <img
              onClick={() => setShowTabla(false)}
              className="tabla-amortizacion--close"
              src={IconClose}
            ></img>
            <h1>Tabla de amortización estimada</h1>
            <h4 className="text-start f-s-2">
              Calculada con una tasa de interés anual de referencia de 15.2%
            </h4>
            <hr />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Periodo</th>
                  <th scope="col">Saldo capital</th>
                  <th scope="col">Intereses</th>
                  <th scope="col">Capital</th>
                  <th scope="col">Iva</th>
                  <th scope="col">Pago total</th>
                </tr>
              </thead>
              <tbody>
                {tablaData.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <tr>
                        <td>{item.periodo}</td>
                        <td>{item.saldoCap}</td>
                        <td>{item.intereses}</td>
                        <td>{item.capital}</td>
                        <td>{item.iva}</td>
                        <td>{item.pagoTotal}</td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-end gap-2 my-3">
              <PrimaryButton
                onClick={() => {
                  setShowTabla(false);
                }}
              >
                Cerrar
              </PrimaryButton>
            </div>
          </div>
        </div>
        <div
          onClick={() => setShowTabla(false)}
          className="tabla-amortizacion--background"
        />
      </Popup>
      <div>
        <h1 className="text-center f-s-4">
          Bienvenido, ahora puedes realizar una cotización{" "}
        </h1>
        <h1 className="text-center f-s-4">
          de tu crédito llenando los siguientes datos:{" "}
        </h1>
      </div>
      <br />
      <h1 className="text-center f-s-4">Monto del crédito</h1>
      <form
        className="form--container"
        onSubmit={(e) => {
          validarDatosOperacionNotNulos();
          e.preventDefault();
        }}
      >
        <div className="form--cotizador">
          <div>
            <div className="d-flex justify-content-center">
              <TextField
                className="cotizador--monto"
                InputLabelProps={{ shrink: false }}
                label={" "}
                onChange={(e) => {
                  handlePesos(e, setValorFactura);
                }}
                onBlur={(e) => {
                  handleBlurMontoCredito(e.target.value);
                }}
                value={valorFactura}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">MXN</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="cotizador--cotizadorInput">
              <p>${montoMinimoDeCredito.toLocaleString("es-MX")}</p>
              <input
                onChange={(e) => {
                  handleRangeBar(
                    cantidadMin +
                      29000 *
                        (Math.round(parseInt(e.target.value)) === 99
                          ? 100
                          : Math.round(parseInt(e.target.value))),
                    setValorFactura
                  );
                }}
                value={
                  (parseInt(valorFactura.split("$")[1].replace(/,/g, "")) -
                    cantidadMin) /
                  29000
                }
                type="range"
                className="form-range range"
                id="range-input"
              />
              <p>${montoMaximoDeCredito.toLocaleString("es-MX")}</p>
            </div>
            <div className="cotizador--form">
              <SelectWithErrorTooltipCompuesto
                value={tipoCredito}
                styles={selectStyles}
                onChange={(e) => {
                  setTipoCredito(e);
                  setMensajeErrorTipoCredito("");
                  setMensajeErrorrTipoTasa("");
                  setMensajeErrorDestinoCredito("");
                  setSelectedCard("1");
                  if (e.id == parseInt(TIPO_CREDITO_REVOLVENTE)) {
                    let ts = axiosData?.tipoTasa?.filter(
                      (e) => e.id === TIPO_TASA_VARIABLE
                    );
                    setTipoTasa({
                      ...ts[0],
                      label: ts[0].nombre,
                      value: ts[0].nombre,
                    });

                    let dc = axiosData?.destinoCredito?.filter(
                      (e) => e.id === TIPO_DESTINO_CAPITAL_TRABAJO
                    );

                    setDestinoCredito({
                      ...dc[0],
                      label: dc[0].nombre,
                      value: dc[0].nombre,
                    });
                    setShowSelect(true);
                  } else {
                    setShowSelect(false);
                    setTipoTasa({
                      label: "",
                      value: "",
                    });
                    setDestinoCredito({
                      label: "",
                      value: "",
                    });
                    obtenerCat();
                  }
                }}
                options={axiosData?.tipoCredito?.map((e) => {
                  return {
                    ...e,
                    label:
                      e.id == 1
                        ? e.nombre + " (plazo de 18 a 36 meses)"
                        : e.nombre + " (plazo 12 meses)",
                    value:
                      e.id == 1
                        ? e.nombre + " (plazo de 18 a 36 meses)"
                        : e.nombre + " (plazo 12 meses)",
                  };
                })}
                label="Tipo de crédito *"
                isCotizador={true}
                tooltipA="Express simple: Se depositará la totalidad de la línea de crédito autorizada en tu cuenta de cheques. El pago de las amortizaciones será igual durante el plazo del crédito"
                tooltipB="Express revolvente: Se autorizará una línea de crédito, misma que puede ser utilizada cuando tú lo requieras, ya sea de forma total o parcial. El pago mensual se calculará de acuerdo al monto y número de días de la disposición"
                errorMessage={mensajeErrorTipoCredito}
                showErrorMessage={mensajeErrorTipoCredito}
              />
              <SelectWithErrorTooltipCompuesto
                isSearchable={false}
                value={tipoTasa}
                styles={selectStyles}
                onChange={(e) => {
                  setTipoTasa(e);
                  setMensajeErrorrTipoTasa("");
                }}
                required
                label="Tipo de tasa *"
                options={axiosData?.tipoTasa?.map((e) => {
                  return { ...e, label: e.nombre, value: e.nombre };
                })}
                isDisabled={showSelect}
                isCotizador={true}
                tooltipA="Tasa fija: Es el tipo de tasa que no sufre cambios a lo largo de la vida del crédito."
                tooltipB="Tasa variable: Este tipo de tasa puede variar dependiendo del valor de la tasa de referencia TIIE (tasa de interés interbancaria de equilibrio), así como términos y condiciones del contrato"
                errorMessage={mensajeErrorTipoTasa}
                showErrorMessage={mensajeErrorTipoTasa}
              />
              <SelectWithErrorTooltipCompuesto
                isSearchable={false}
                value={destinoCredito}
                styles={selectStyles}
                onChange={(e) => {
                  setDestinoCredito(e);
                  setMensajeErrorDestinoCredito("");
                }}
                required
                label="Destino del crédito *"
                options={axiosData?.destinoCredito?.map((e) => {
                  return { ...e, label: e.nombre, value: e.nombre };
                })}
                isDisabled={showSelect}
                isCotizador={true}
                tooltipA="Activo fijo: Compra o adquisición de bienes inmuebles o maquinaria y equipo"
                tooltipB="Capital de trabajo: Compra de insumos y mercancías, financiar el pago de tu nómina, pago de servicios de impuestos o hacer frente a cualquier imprevisto y eventualidades de tu negocio"
                errorMessage={mensajeErrorDestinoCredito}
                showErrorMessage={mensajeErrorDestinoCredito}
              />
            </div>
          </div>
        </div>
        {tipoCredito?.id?.toString() !== "" &&
        tipoCredito?.id?.toString() === "2" ? (
          <>
            {optionsRevolvente.map((item, index) => {
              return (
                <CreditType
                  key={index}
                  meses={item.meses}
                  mensualidad={item.mensualidad}
                  tipoDeCredito={tipoCredito}
                  tasaAnualAts={tasaAnualAts}
                  monto={handlePesosReturn(
                    parseInt(valorFactura.split("$")[1].replace(/,/g, ""))
                      .toString()
                      .split(".")[0],
                    item.meses,
                    tasaAnualAts
                  )}
                  onClickCard={() => {
                    setMensajeErrorPlazo("");
                    setSelectedCard(item.card);
                  }}
                  onClickIcon={() => {
                    setSelectedCard(item.card);
                    setShowTabla(true);
                  }}
                />
              );
            })}
            {selectedCard === "" && (
              <>
                <div className="error--container">
                  <span className="text__center text_red">
                    {mensajeErrorPlazo}
                  </span>
                </div>
              </>
            )}
          </>
        ) : (
          tipoCredito?.id?.toString() === "1" && (
            <>
              <h2 className="text-center f-s-3">Selecciona una opción</h2>
              {optionsSimple.map((item, index) => (
                <CreditType
                  key={index}
                  meses={item.meses}
                  mensualidad={item.mensualidad}
                  card={item.card}
                  tipoDeCredito={tipoCredito}
                  monto={handlePesosReturn(
                    parseInt(valorFactura.split("$")[1].replace(/,/g, ""))
                      .toString()
                      .split(".")[0],
                    item.meses,
                    tasaAnualAts
                  )}
                  onClickCard={() => {
                    setMensajeErrorPlazo("");
                    setSelectedCard(item.card);
                  }}
                  onClickIcon={() => {
                    setSelectedCard(item.card);
                    setShowTabla(true);
                  }}
                  selectedCard={selectedCard}
                />
              ))}
              {selectedCard === "" && (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <span className="text__center text_red">
                      {mensajeErrorPlazo}
                    </span>
                  </div>
                </>
              )}
            </>
          )
        )}
        <>
          <div className="container">
            <h2 className="text-center f-s-3">
              Elige la sucursal más cerca de tí
            </h2>
            <div className="card cotizador--sucursales gap-2">
              <SelectWithError
                value={estado}
                onChange={(e) => {
                  setEstado(e);
                  setMunicipio("");
                  setMunicipios([]);
                  obtenerMunicipiosByEstado(e.id);
                }}
                options={axiosData?.estados?.map((e) => {
                  return { ...e, label: e.nombre, value: e.nombre };
                })}
                label="Estado *"
                errorMessage={mensajeErrorEstado}
                showErrorMessage={mensajeErrorEstado}
              />
              <SelectWithError
                value={municipio}
                onChange={(e) => {
                  setMunicipio(e);
                  setNumCR("");
                  setNombreSucursal("");
                  setSucursales([]);
                  obtenerSucursalesByEstadoAndMunicipio(e.id);
                }}
                options={municipios?.map((e) => {
                  return { ...e, label: e.nombre, value: e.nombre };
                })}
                label="Municipio *"
                errorMessage={mensajeErrorMunicipio}
                showErrorMessage={mensajeErrorMunicipio}
              />
            </div>
            {!isEmpty(numCR) && (
              <div className="sucursales--sucursal">
                <h4 className="text_center">{nombreSucursal}</h4>

                <PrimaryButton
                  onClick={(e) => {
                    setShowTablaSucursales(true);
                  }}
                >
                  Cambiar de sucursal
                </PrimaryButton>
              </div>
            )}
          </div>
          {showTablaSucursales === true && (
            <>
              <div className="container sucursales--list">
                {sucursales.map((item, index) => {
                  return (
                    <div className="sucursales--card" key={index}>
                      <div>
                        {numCR == item.numeroDeSucursal ? (
                          <>
                            <span className="sucursal--name__active">
                              {item.nombre}
                            </span>
                            <br />
                            <span className="sucursal--direccion__active">
                              {item.direccion} <br />
                              COLONIA {item.colonia} <br />
                              C.P. {item.codigoPostal}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="sucursal--name">
                              {item.nombre}
                            </span>
                            <br />
                            <span className="sucursal--direccion">
                              {item.direccion} <br />
                              COLONIA {item.colonia} <br />
                              C.P. {item.codigoPostal}
                            </span>
                          </>
                        )}
                      </div>
                      <div>
                        <RadioInput
                          style={{ padding: "0" }}
                          checked={
                            numCR == item.numeroDeSucursal ? true : false
                          }
                          onChange={() => {
                            setNumCR(item.numeroDeSucursal);
                            setNombreSucursal(item.nombre);
                            setShowTablaSucursales(false);
                            setMensajeErrorSucursal("");
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                {!isEmpty(mensajeErrorSucursal) && (
                  <div className="error--container">
                    <span className="text__center text_red">
                      {mensajeErrorSucursal}
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </>
        <div className="sucursales--terminos">
          <div className="sucursales--terminos__button">
            <button type="submit" className="button-primary">
              Siguiente
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Cotizador;
