import axios from "axios";
import { path } from "../config/Path";
import {
  localStorageToken,
} from "../config/Variables";

const obtenerTokendeLocalStorage = () => {
  let header = {
    headers: {
      Authorization: localStorage.getItem(
          localStorageToken
      ),    
    }      
  };
  return header;
};

export const api_save_datos_cliente_autoservicio = (datosCliente) => {
  let rutaServicio = path + "/rest/salvarClienteAutoservicio";
  return axios
    .post(rutaServicio, datosCliente)
    .then((res) => res)
    .catch((err) => err);
};

export const api_actualizar_datos_cliente_autoservicio = (datosCliente) => {
  let rutaServicio = path + "/rest/actualizarDatosClienteAutoservicio";
  return axios
    .post(rutaServicio, datosCliente)
    .then((res) => res)
    .catch((err) => err);
};

export const api_actualizar_datos_cliente_autoservicio_empresa = (datosCliente) => {
  let rutaServicio = path + "/rest/actualizarDatosClienteAutoservicioEmpresa";
  return axios
    .post(rutaServicio, datosCliente)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datosOperacion = (datosOperacion) => {
  let rutaServicio = path + "/rest/salvarDatosDeOperacion";
  return axios
    .post(rutaServicio, datosOperacion)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_detalle_cliente = (idsDetalle) => {
  const idDatosTemporalesAutoservicio =
    idsDetalle.idDatosTemporalesAutoservicio;
  const idDatosOperacion = idsDetalle.idDatosOperacion;
  let rutaServicio =
      path +
    "/rest/obtenerDatosCliente?idDatosTemporalesAutoservicio=" +
    idDatosTemporalesAutoservicio +
    "&idDatosOperacion=" +
    idDatosOperacion;
  return axios
    .post(rutaServicio)
    .then((res) => res)
    .catch((err) => err);
};

export const api_load_tablaAmortizacion = (datosCredito) => {
  const plazo = datosCredito.plazo;
  const monto = datosCredito.monto;
  let rutaServicio =
      path +
    "/rest/obtenerTablaAmortizacion?plazo=" +
    plazo +
    "&monto=" +
    monto;
  return axios
    .post(rutaServicio)
    .then((res) => res)
    .catch((err) => err);
};

export const api_direccion_by_cp = (cp) => {
  let rutaServicio =
      path + "/rest/obtenerDireccionPorCp?codigoPostal=" + cp;
  return axios
    .post(rutaServicio)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_generales_PFAE = (pfae) => {
  let rutaServicio = path + "/rest/crediactivo/salvarDatosGeneralesPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_domicilio_PFAE = (pfae) => {
  let rutaServicio = path + "/rest/salvarDatosDomicilioPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_domicilio_fical_PFAE = (pfae) => {
  let rutaServicio = path + "/rest/salvarDomicilioFiscalPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_domicilio_comercial_PFAE = (pfae) => {
  let rutaServicio = path + "/rest/salvarDomicilioComercialPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_economicos_PFAE = (pfae) => {
  let rutaServicio = path + "/rest/salvarDatosEconomicosPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_generales_PM = (pm) => {
  let rutaServicio = path + "/rest/crediactivo/salvarDatosGeneralesPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_contacto_empresa_PM = (pm) => {
  let rutaServicio = path + "/rest/crediactivo/salvarDatosContactosEmpresaPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_domicilio_negocio_PM = (pm) => {
  let rutaServicio = path + "/rest/salvarDomicilioNegocioPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_negocio_PM = (pm) => {
  let rutaServicio = path + "/rest/salvarDatosNegocioPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_domicilio_comercial_PM = (pm) => {
  let rutaServicio = path + "/rest/salvarDomicilioComercialPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_economicos_PM = (pm) => {
  let rutaServicio = path + "/rest/salvarDatosEconomicosPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_socios_principales_PM = (pm) => {
  let rutaServicio = path + "/rest/salvarDatosSociosPrincipalesPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_principal_accionista_PM = (pm) => {
  let rutaServicio = path + "/rest/crediactivo/salvarDatosPrincipalAccionistaPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_os_pfae_pm = (pm) => {
  let rutaServicio = path + "/rest/salvarDatosObligadoSolidarioPfaePM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_os_pm_pm = (pm) => {
  let rutaServicio = path + "/rest/salvarDatosObligadoSolidarioPmPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_os_pfae_pfae = (pfae) => {
  let rutaServicio = path + "/rest/salvarDatosObligadoSolidarioPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_os_pm_pfae = (pfae) => {
  let rutaServicio = path + "/rest/salvarDatosObligadoSolidarioPmPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_principal_accionista_conyuge_PM = (pm) => {
  let rutaServicio = path + "/rest/salvarDatosPrincipalAccionistaConyugePM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_os_pfae_conyuge_pm = (pm) => {
  let rutaServicio = path + "/rest/salvarDatosObligadoSolidarioPfaeConyugePM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_conyuge_pfae = (pfae) => {
  let rutaServicio = path + "/rest/salvarDatosConyugePFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_os_pfae_conyuge_pfae = (pfae) => {
  let rutaServicio =
    path + "/rest/salvarDatosObligadoSolidarioConyugePFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
      .post(rutaServicio, pfae, header)
      .then((res) => res)
      .catch((err) => err);
};

export const api_save_patrimonio_principal_pm = (pm) => {
  let rutaServicio =
    path + "/rest/salvarDatosPatrimonioPrincipalCaptacionPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
      .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_patrimonio_principal_pfae = (pfae) => {
  let rutaServicio =
    path + "/rest/salvarDatosPatrimonioPrincipalCaptacionPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_os_soliictud = (datosCliente) => {
  let rutaServicio = path + "/rest/obtenerObligadosSolidariosSolicitud";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, datosCliente, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_documentos_soliictud_pm = () => {
  let rutaServicio =
    path + "/rest/consultaCatalogos/documentosSolicitudPM";
  return axios
    .get(rutaServicio)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_documentos_soliictud_pfae = () => {
  let rutaServicio =
    path + "/rest/consultaCatalogos/documentosSolicitudPFAE";
  return axios
    .get(rutaServicio)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_campania_oferta = (campaniaOferta) => {
  let rutaServicio = path + "/rest/salvarDatosOfertaSeleccionada";
  return axios
    .post(rutaServicio, campaniaOferta)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datosOperacion_campania = (datosOperacion) => {
  let rutaServicio = path + "/rest/salvarDatosDeOperacionCampanias";
  return axios
    .post(rutaServicio, datosOperacion)
    .then((res) => res)
    .catch((err) => err);
};

export const api_consulta_autenticada_evaluar_cliente = (datosConsulta) => {
  let rutaServicio =
    path + "/rest/consultaAutenticadaAndEvaluarCliente";
  return axios
    .post(rutaServicio, datosConsulta)
    .then((res) => res)
    .catch((err) => err);
};

export const api_eliminar_os_solicitud_pm = (datosOS) => {
  let rutaServicio = path + "/rest/eliminarObligadoSolidarioPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, datosOS, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_eliminar_os_solicitud_pfae = (datosOS) => {
  let rutaServicio = path + "/rest/eliminarObligadoSolidarioPFAE";
  let  header = obtenerTokendeLocalStorage(); 
  return axios
    .post(rutaServicio, datosOS, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_uploadDocumento = async (formdata) => {
  let rutaServicio = path + "/rest/crediactivo/cargaDocumentosAtsCrediactivo";
  let  header = obtenerTokendeLocalStorage(); 
  return axios
    .post(rutaServicio, formdata, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_uploadDocumentExample = async (formdata) => {
  let rutaServicio = path + "/rest/uploadDocumentExample";
  return axios
    .post(rutaServicio, formdata)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_solicitud_existente = async (formdata) => {
  let rutaServicio = path + "/rest/crediactivo/obtenerSolicitudAtsCrediactivo";
  return axios
    .post(rutaServicio, formdata)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_detalle_solicitud_crediactivo = (formdata) => {
  let rutaServicio = path + "/rest/crediactivo/obtenerDetalleSolicitudAutoservicio";
  let head = obtenerTokendeLocalStorage();

  return axios
    .post(rutaServicio, formdata, head)
    .then((res) => res)
    .catch((err) => err);
};

export const api_validar_nip_PM = (formdata) => {
  let rutaServicio = path + "/rest/crediactivo/validarNipPM";
  return axios
    .post(rutaServicio, formdata)
    .then((res) => res)
    .catch((err) => err);
};

export const api_validar_nip_PFAE = (formdata) => {
  let rutaServicio = path + "/rest/crediactivo/validarNipPFAE";
  return axios
    .post(rutaServicio, formdata)
    .then((res) => res)
    .catch((err) => err);
};

export const api_consulta_tradicional_PM = (formdata) => {
  let rutaServicio = path + "/rest/validarConsultaTradicionalPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, formdata, header)
    .then((res) => res)
    .catch((err) => err);
};
export const api_consulta_tradicional_PFAE = (formdata) => {
  let rutaServicio = path + "/rest/validarConsultaTradicionalPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, formdata, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_uploadCargaDocumentos = async (formdata) => {
  let rutaServicio = path + "/rest/cargaDocumentosAutoservicio";
  return axios
    .post(rutaServicio, formdata)
    .then((res) => res)
    .catch((err) => err);
};

export const api_validar_etapa_uno = (idCliente) => {
  let rutaServicio =
    path + "/rest/avanzarValidacionDocumental?idCliente=" + idCliente;
  return axios
    .post(rutaServicio)
    .then((res) => res)
    .catch((err) => err);
};

export const api_avanzar_historial_crediticio = (idSolicitud) => {
  let rutaServicio =
    path +
    "/rest/avanzarHistorialCrediticio?idSolicitud=" +
    idSolicitud;
  return axios
    .post(rutaServicio, idSolicitud)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_documentos_cargados = (datosCliente) => {
  let rutaServicio = path + "/rest/crediactivo/obtenerDocumentosCargados";
  return axios
    .post(rutaServicio, datosCliente)
    .then((res) => res)
    .catch((err) => err);
};

export const api_actualizar_datos_resumen = (datosResumenCredito) => {
  let rutaServicio = path + "/rest/actualizarDatosResumen";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, datosResumenCredito, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_eliminar_documentos = (datosDocumentos) => {
  let rutaServicio = path + "/rest/crediactivo/eliminarDocumentos";
  return axios
    .post(rutaServicio, datosDocumentos)
    .then((res) => res)
    .catch((err) => err);
};

export const api_limites_monto_credito = () => {
  let rutaServicio =
    path + "/rest/crediactivo/consultaCatalogos/limitesCrediactivoMontoCredito";
  return axios
    .get(rutaServicio)
    .then((res) => res)
    .catch((err) => err);
};

export const api_catalogos_personales = () => {
  let rutaServicio =
      path + "/rest/consultaCatalogos/catalogosPersonales";
  return axios
      .get(rutaServicio)
      .then((res) => res)
      .catch((err) => err);
};

export const api_anios_negocio = () => {
  let rutaServicio =
      path + "/rest/consultaCatalogos/aniosNegocio";
  return axios
      .get(rutaServicio)
      .then((res) => res)
      .catch((err) => err);
};

export const api_catalogos_datos_operacion = () => {
  let rutaServicio =
      path + "/rest/consultaCatalogos/catalogosDatosOperacion";
  return axios
      .get(rutaServicio)
      .then((res) => res)
      .catch((err) => err);
};

export const api_obtener_no_cliente_banorte = (data) => {
  let rutaServicio =
      path + "/rest/validarRfcSolicitantePorSolicitud";
  return axios
      .post(rutaServicio, data)
      .then((res) => res)
      .catch((err) => err);
};

export const api_obtener_cliente_banorte = (data) => {
  let rutaServicio =
      path + "/rest/validarClienteBanorteRFCAndNumeroCliente";
  return axios
      .post(rutaServicio, data)
      .then((res) => res)
      .catch((err) => err);
};

export const api_obtener_cliente_campania = (data) => {
  let rutaServicio =
      path + "/rest/validarRfcSolicitantePorSolicitud";
  return axios
      .post(rutaServicio, data)
      .then((res) => res)
      .catch((err) => err);
};

export const api_validar_cliente_campania = (data) => {
  let rutaServicio =
      path + "/rest/validarCampaniaPorRfc";
  return axios
      .post(rutaServicio, data)
      .then((res) => res)
      .catch((err) => err);
};

export const api_enviar_codigo = (formData) => {
  let rutaServicio =
      path + "/rest/enviarMensajeCodigo";
  return axios
      .post(rutaServicio, formData)
      .then((res) => res)
      .catch((err) => err);
};

export const api_validar_codigo = (datosTelefono) => {
  let rutaServicio =
      path + "/rest/validarCodigo";
  return axios
      .post(rutaServicio, datosTelefono)
      .then((res) => res)
      .catch((err) => err);
};

export const api_obtener_municipio_por_estado = (datosConsulta) => {
  let rutaServicio = path + "/rest/consultaCatalogos/obtenerMunicipiosByEstado";
  return axios
    .post(rutaServicio, datosConsulta)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_sucursales_by_estado_and_municipio = (datosConsulta) => {
  let rutaServicio = path + "/rest/consultaCatalogos/obtenerSucursalesByEstadoAndMunicipio";
  return axios
    .post(rutaServicio, datosConsulta)
    .then((res) => res)
    .catch((err) => err);
};

export const api_salvar_token_autoservicio = (datosToken) => {
  let rutaServicio = path + "/rest/api_salvar_token_autoservicio";
  return axios
    .post(rutaServicio, datosToken)
    .then((res) => res)
    .catch((err) => err);
};

export const api_get_cat_simple = (datosCredito) => {
  let rutaServicio = `${path}/rest/obtenerCatCreditoSimple?plazo=${datosCredito?.letPlazo}&monto=${parseInt(
      datosCredito?.valorFactura
  )}`;
  return axios
      .post(rutaServicio)
      .then((res) => res)
      .catch((err) => err);
}

export const api_guardar_token_autoservicio = (datosToken) => {
  let rutaServicio = path + "/rest/guardarTokenAutoservicio";
  return axios
    .post(rutaServicio, datosToken)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_clave_ciec_PFAE = (pfae) => {
  let rutaServicio = path + "/rest/crediactivo/salvarDatosClaveCiecPFAE";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pfae, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_save_datos_clave_ciec_PM = (pm) => {
  let rutaServicio = path + "/rest/crediactivo/salvarDatosClaveCiecPM";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, pm, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_avanzar_recabar_documentos = (formdata) => {
  let rutaServicio = path + "/rest/crediactivo/avanzarRecabarDocumentos";
  return axios
    .post(rutaServicio, formdata)
    .then((res) => res)
    .catch((err) => err);
};

export const api_validar_documentos_cargados = (formdata) => {
  let rutaServicio = path + "/rest/crediactivo/validarDocumentosCargados";
  let  header = obtenerTokendeLocalStorage();
  return axios
    .post(rutaServicio, formdata, header)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_folio_solicitud_sms = async (formdata) => {
  let rutaServicio = path + "/rest/crediactivo/obtenerFolioSms";
  return axios
    .post(rutaServicio, formdata)
    .then((res) => res)
    .catch((err) => err);
};

export const api_obtener_folio_solicitud_llamada = async (formdata) => {
  let rutaServicio = path + "/rest/crediactivo/obtenerFolioLlamada";
  return axios
    .post(rutaServicio, formdata)
    .then((res) => res)
    .catch((err) => err);
};