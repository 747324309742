import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import OfertaPersonalizada from "./ofertaPersonalizada";
import {
  OFERTA_PERSONALIZADA_1,
  OFERTA_PERSONALIZADA_2,
  OFERTA_PERSONALIZADA_3,
  OFERTA_PERSONALIZADA_4,
  OFERTA_PERSONALIZADA_5,
  PANTALLA_VALIDACION_NUMERO_CORREO,
} from "../../../utils/constantes";
import { api_save_datos_campania_oferta } from "../../../api/api_servicios_rest";
import { sweetAlertError } from "../../campos/components/alertas/alertas";

const OfertaCampanias = ({
  handleChangeOferta,
  oferta,
  selectedOferta,
  setSteps,
  ofertaCampanias,
  steps,
  setOfertaAceptada,
  ofertaAceptada,
  setIdOfertaSeleccionada,
}) => {
  const formik = useFormik({
    initialValues: initialValues(ofertaCampanias, oferta),
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: false,
    onSubmit: (formData) => {
      formData.numeroDeOferta.numero = oferta;
      salvarOfertaSeleccionada(formData);
    },
  });

  const salvarOfertaSeleccionada = async(formData) => {
    const response = await api_save_datos_campania_oferta(formData);
    if (response.statusText === "OK") {
      const data = response.data;
      if (data.success) {
        setSteps(PANTALLA_VALIDACION_NUMERO_CORREO);
        setOfertaAceptada(true);
        setIdOfertaSeleccionada(data.idOfertaSeleccionada);
        return;
      }
      sweetAlertError("Ocurrió un error al guardar la oferta seleccionada");
    }
  }

  const ofertasPersonalizada1 = formik?.values?.personalizada1?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_1}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  const ofertasPersonalizada2 = formik?.values?.personalizada2?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_2}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  const ofertasPersonalizada3 = formik?.values?.personalizada3?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_3}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  const ofertasPersonalizada4 = formik?.values?.personalizada4?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_4}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  const ofertasPersonalizada5 = formik?.values?.personalizada5?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_5}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  useEffect(() => {
    if (steps === 2) setSteps(2);
  }, [steps]);

  return (
    <>
      <div className="bgc-white contenedor-ofertas">
        <div className="flecha-carrusel-ofertas">
          <i
            onClick={() => handleChangeOferta(false)}
            className="ri-arrow-left-s-line pointer icono-flecha"
          ></i>
        </div>
        <div className="ancho-80">
          <div className="row">
            <h1 className="text-center text-ofertas">
              ¡Tenemos ofertas para ti!
            </h1>
          </div>
          <form name={"oferta-campania"} onSubmit={formik.handleSubmit}>
            <input type="hidden" value={formik?.value?.selectedOferta} />
            <div className="row">
              {oferta === 0 || oferta === 1
                ? ofertasPersonalizada1
                : oferta === 2
                ? ofertasPersonalizada2
                : oferta === 3
                ? ofertasPersonalizada3
                : oferta === 4
                ? ofertasPersonalizada4
                : ofertasPersonalizada5}
            </div>
            <div className="row">
              <div className="campo-oferta-campania-tomar-oferta">
                <button className="bgc-red" type="submit">
                  Tomar esta oferta{" "}
                </button>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="campo-oferta-campania-text text-center">
              <span className="text-span-ofertas">
                Oferta {selectedOferta + 1}/
                {Object.keys(ofertaCampanias).length}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="campo-oferta-campania-no text-center">
              <span
                onClick={() => setSteps(2)}
                className="color-gray-2p pointer text-sin-oferta"
              >
                No quiero ninguna de estas ofertas
              </span>
            </div>
          </div>
        </div>
        <div className="flecha-carrusel-ofertas">
          <i
            onClick={() => handleChangeOferta(true)}
            className="ri-arrow-right-s-line pointer icono-flecha"
          ></i>
        </div>
      </div>
    </>
  );
};

export default OfertaCampanias;

function initialValues(ofertaCampanias) {
  return {
    numeroDeOferta: {
      numero: "",
    },
    personalizada1: [
      {
        idCampania: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada1
          ? ofertaCampanias?.personalizada1[2].tasaVariable
          : "",
      },
    ],
    personalizada2: [
      {
        idCampania: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada2
          ? ofertaCampanias?.personalizada2[2].tasaVariable
          : "",
      },
    ],
    personalizada3: [
      {
        idCampania: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada3
          ? ofertaCampanias?.personalizada3[2].tasaVariable
          : "",
      },
    ],
    personalizada4: [
      {
        idCampania: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada4
          ? ofertaCampanias?.personalizada4[2].tasaVariable
          : "",
      },
    ],
    personalizada5: [
      {
        idCampania: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].idOferta
          : "",
        monto: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].monto
          : "",
        plazo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[0].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].idOferta
          : "",
        monto: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].monto
          : "",
        plazo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[1].tasaVariable
          : "",
      },
      {
        idCampania: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].idCampania
          : "",
        idOferta: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].idOferta
          : "",
        monto: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].monto
          : "",
        plazo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].plazo
          : "",
        comisionApertura: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].comisionApertura
          : "",
        catFijo: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].catFijo
          : "",
        catVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].catVariable
          : "",
        tasaFija: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].catFijo
          : "",
        tasaVariable: ofertaCampanias?.personalizada5
          ? ofertaCampanias?.personalizada5[2].tasaVariable
          : "",
      },
    ],
  };
}
