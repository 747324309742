import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "moment/locale/es";
import "react-datetime/css/react-datetime.css";

import {
  TIPO_LOCAL_RENTA,
  SECCION_DOMICILIO_FISCAL,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../../utils/constantes";

import {
  api_save_datos_domicilio_fical_PFAE,
  api_direccion_by_cp,
} from "../../../../api/api_servicios_rest_crediactivo";
import {
  valorMinimo,
  rangoValorValido,
  fechaActualYYYMMDD,
} from "../../../../utils/validaciones";
import {
  validateValue,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../functions/fieldFunctions";

import { mensajeMostrarUsuario } from "../../../../utils/mensajesServicios";
import FormLayout from "../../../helpers/FormLayout/FormLayout";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../basics/SelectWithError/SelectWithError";
import SelectWithErrorTooltipCompuesto from "../../../basics/SelectWithError/SelectWithErrorTooltipCompuesto";
import SelectWithErrorActividad from "../../../basics/SelectWithError/SelectWithErrorActividad";
import SelectWithErrorRepresentanteLegal from "../../../basics/SelectWithError/SelectWithErrorRepresentanteLegal";
import NumberWithError from "../../../basics/NumberWithError/NumberWithError";
import {
  SecondaryButton,
  PrimaryButton,
} from "../../../basics/Buttons/Buttons";
import {
  sweetAlertError,
  sweetAlertWithConfirmedCrediactivo,
} from "../alertas/alertas";

const DatosGeneralesDomicilioFiscalPFAE = ({
  modeloPfae,
  setModeloPfae,
  axiosData,
  anios,
  setLoading,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
}) => {
  const [coloniasCP, setColoniasCP] = useState([]);
  const [domicilioIgual, setDomicilioIgual] = useState(false);
  const MAX_VAL_TOTAL = 100000;
  const MAX_VAL = 9999;
  const MIN_VAL = 0;

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value >= MIN_VAL && value <= MAX_VAL) return true;
    return false;
  };

  const withValueCapMax = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL_TOTAL) return true;
    return false;
  };

  const formik = useFormik({
    initialValues: initialValues(modeloPfae, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosDomicilioFiscalPFAE(formData);
    },
  });

  const obtenerDireccionByCp = async (codigoPostal, domicilioIgual) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK") {
        if (response.data.municipio) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "domicilioFiscal.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue(
            "domicilioFiscal.estadoId",
            response.data.estado.id
          );

          if (
            modeloPfae.domicilioFiscal.colonia &&
            modeloPfae.domicilioFiscal.colonia !==
              formik.values.domicilioFiscal.colonia
          ) {
            formik.setFieldValue(
              "domicilioFiscal.colonia",
              modeloPfae.domicilioFiscal.colonia
            );
          } else {
            if (domicilioIgual) {
              formik.setFieldValue(
                "domicilioFiscal.colonia",
                modeloPfae.direccion.colonia
              );
            } else {
              formik.setFieldValue(
                "domicilioFiscal.colonia",
                response.data.asentamientos[0]
              );
            }
          }

          formik.setFieldValue(
            "domicilioFiscal.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response.data.municipio.id
            )
          );
          formik.setFieldValue(
            "domicilioFiscal.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response.data.estado.id
            )
          );

          let newErrors = formik.errors;
          delete newErrors?.domicilioFiscal?.municipioId;
          delete newErrors?.domicilioFiscal?.estadoId;
          delete newErrors?.domicilioFiscal?.colonia;
          formik.setErrors(newErrors);
        } else {
          limpiarDireccionByCp();
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue("domicilioFiscal.municipioId", "");
    formik.setFieldValue("domicilioFiscal.municipioObj", "");
    formik.setFieldValue("domicilioFiscal.estadoId", "");
    formik.setFieldValue("domicilioFiscal.estadoObj", "");
    formik.setFieldValue("domicilioFiscal.colonia", "");
  };

  const handleChangeDomicilioFiscal = (event) => {
    if (event.target.value) {
      const valor = JSON.parse(event.target.value);
      setDomicilioIgual(valor);
      if (valor) {
        setearDatosDomicilioFiscalSolictudPFAE(valor);
      } else {
        setearVacioDatosDomicilioFiscal();
      }

      formik.setFieldValue("domicilioFiscal.igualDomicilio", valor);
    }
  };

  const setearDatosDomicilioFiscalSolictudPFAE = (domicilioIgual) => {
    if (modeloPfae.domicilioFiscal.codigoPostal) {
      obtenerDireccionByCp(
        modeloPfae.domicilioFiscal.codigoPostal,
        domicilioIgual
      );
    } else {
      if (
        modeloPfae.direccion.colonia !== null &&
        modeloPfae.direccion.colonia !== ""
      ) {
        const colonias = [modeloPfae.direccion.colonia];
        setColoniasCP(colonias);

        formik.setFieldValue(
          "domicilioFiscal.municipioObj",
          axiosData?.generales?.municipios?.filter(
            (e) => e.id === modeloPfae.direccion.municipioId
          )
        );
        formik.setFieldValue(
          "domicilioFiscal.estadoObj",
          axiosData?.generales?.estados?.filter(
            (e) => e.id === modeloPfae.direccion.estadoId
          )
        );
      }
    }

    formik.setFieldValue("domicilioFiscal.calle", modeloPfae.direccion.calle);
    formik.setFieldValue(
      "domicilioFiscal.numeroExterior",
      modeloPfae.direccion.numeroExterior
    );
    formik.setFieldValue(
      "domicilioFiscal.numeroInterior",
      modeloPfae.direccion.numeroInterior
    );
    formik.setFieldValue(
      "domicilioFiscal.codigoPostal",
      modeloPfae.direccion.codigoPostal
    );
    formik.setFieldValue(
      "domicilioFiscal.colonia",
      modeloPfae.direccion.colonia
    );
    formik.setFieldValue(
      "domicilioFiscal.municipioId",
      modeloPfae.direccion.municipioId
    );
    formik.setFieldValue(
      "domicilioFiscal.estadoId",
      modeloPfae.direccion.estadoId
    );

    let newErrors = formik.errors;
    delete newErrors?.domicilioFiscal?.calle;
    delete newErrors?.domicilioFiscal?.numeroExterior;
    delete newErrors?.domicilioFiscal?.numeroInterior;
    delete newErrors?.domicilioFiscal?.codigoPostal;
    delete newErrors?.domicilioFiscal?.colonia;
    delete newErrors?.domicilioFiscal?.municipioId;
    delete newErrors?.domicilioFiscal?.estadoId;
    formik.setErrors(newErrors);

    setModeloPfae({
      ...modeloPfae,
      domicilioFiscal: {
        ...modeloPfae.domicilioFiscal,
        calle: modeloPfae.direccion.calle,
        numeroExterior: modeloPfae.direccion.numeroExterior,
        numeroInterior: modeloPfae.direccion.numeroInterior,
        codigoPostal: modeloPfae.direccion.codigoPostal,
        colonia: modeloPfae.direccion.colonia,
        municipioId: modeloPfae.direccion.municipioId,
        estadoId: modeloPfae.direccion.estadoId,
      },
    });
  };

  const setearVacioDatosDomicilioFiscal = () => {
    modeloPfae.domicilioFiscal.calle = "";
    modeloPfae.domicilioFiscal.numeroExterior = "";
    modeloPfae.domicilioFiscal.numeroInterior = "";
    modeloPfae.domicilioFiscal.codigoPostal = "";
    modeloPfae.domicilioFiscal.colonia = "";
    modeloPfae.domicilioFiscal.municipioId = "";
    modeloPfae.domicilioFiscal.estadoId = "";
  };

  const salvarDatosDomicilioFiscalPFAE = async (formData) => {
    try {
      const response = await api_save_datos_domicilio_fical_PFAE(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPfae({
            ...modeloPfae,
            idCliente: data.clienteId,
            domicilioFiscal: data.domicilioFiscal,
          });

          handleSeccionPyme(SECCION_DOMICILIO_FISCAL);
          formik.setFieldValue(
            "domicilioFiscal.idDatosDelNegocio",
            data.domicilioFiscalId
          );
          setearDatosDomicilioFiscalPFAE(formData);
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data.mensaje) {
            sweetAlertWithConfirmedCrediactivo(data?.mensaje);
          } else {
            const mensajeError = mensajeMostrarUsuario(data.mensaje);

            sweetAlertError(mensajeError);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setearDatosDomicilioFiscalPFAE = (formData) => {
    modeloPfae.domicilioFiscal.idDatosDelNegocio =
      formData.domicilioFiscal.calle;
    modeloPfae.domicilioFiscal.calle = formData.domicilioFiscal.calle;
    modeloPfae.domicilioFiscal.numeroExterior =
      formData.domicilioFiscal.numeroExterior;
    modeloPfae.domicilioFiscal.numeroInterior =
      formData.domicilioFiscal.numeroInterior;
    modeloPfae.domicilioFiscal.codigoPostal =
      formData.domicilioFiscal.codigoPostal;
    modeloPfae.domicilioFiscal.colonia = formData.domicilioFiscal.colonia;
    modeloPfae.domicilioFiscal.municipioId =
      formData.domicilioFiscal.municipioId;
    modeloPfae.domicilioFiscal.estadoId = formData.domicilioFiscal.estadoId;
  };

  useEffect(() => {
    if (modeloPfae.domicilioFiscal) {
      if (modeloPfae.domicilioFiscal.codigoPostal !== null) {
        obtenerDireccionByCp(modeloPfae.domicilioFiscal.codigoPostal);
        if (modeloPfae.domicilioFiscal.colonia) {
          formik.setFieldValue(
            "domicilioFiscal.colonia",
            modeloPfae.domicilioFiscal.colonia
          );
        }

        setDomicilioIgual(modeloPfae.domicilioFiscal.igualDomicilio);
      }
    }
  }, []);

  return (
    <>
      <form name="form-domicilio-comercial" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Información de domicilio fiscal</h2>
          <FormLayout>
            <SelectWithErrorRepresentanteLegal
              label="¿Es el mismo que el domicilio? *"
              name="domicilioFiscal.igualDomicilio"
              value={
                domicilioIgual
                  ? { label: "Si", value: true }
                  : { label: "No", value: false }
              }
              onChange={(e) => {
                const event = { ...e, target: { value: e.value } };
                handleChangeDomicilioFiscal(event);
              }}
              options={[
                { label: "Si", value: true },
                { label: "No", value: false },
              ]}
            />
            <InputWithError
              label="Calle *"
              name="domicilioFiscal.calle"
              value={formik.values?.domicilioFiscal?.calle}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.calle
                ) {
                  delete newErrors?.domicilioFiscal?.calle;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                  formik.setFieldValue("domicilioFiscal.calle", e.target.value);
                }
              }}
              InputProps={{ maxLength: 24 }}
              showErrorMessage={formik.errors?.domicilioFiscal?.calle}
              errorMessage={formik.errors?.domicilioFiscal?.calle}
              disabled={domicilioIgual}
            />
            <InputWithError
              label="Número exterior *"
              name="domicilioFiscal.numeroExterior"
              value={formik.values?.domicilioFiscal?.numeroExterior}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.numeroExterior
                ) {
                  delete newErrors?.domicilioFiscal?.numeroExterior;
                  formik.setErrors(newErrors);
                }
                if (notSpecialCaractersConGuion(e.target.value)) {
                  formik.setFieldValue(
                    "domicilioFiscal.numeroExterior",
                    e.target.value
                  );
                }
              }}
              showErrorMessage={formik.errors?.domicilioFiscal?.numeroExterior}
              errorMessage={formik.errors?.domicilioFiscal?.numeroExterior}
              disabled={domicilioIgual}
            />
            <InputWithError
              label="Número interior"
              name="domicilioFiscal.numeroInterior"
              value={formik.values?.domicilioFiscal?.numeroInterior}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.numeroInterior
                ) {
                  delete newErrors?.domicilioFiscal?.numeroInterior;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "domicilioFiscal.numeroInterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu  “Número exterior” separados por un guion Ejemplo: 318-123B"
              showErrorMessage={formik.errors?.domicilioFiscal?.numeroInterior}
              errorMessage={formik.errors?.domicilioFiscal?.numeroInterior}
              disabled={domicilioIgual}
            />
            <InputWithError
              label="CP *"
              name="domicilioFiscal.codigoPostal"
              value={formik.values?.domicilioFiscal?.codigoPostal}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.codigoPostal
                ) {
                  delete newErrors?.domicilioFiscal?.codigoPostal;
                  formik.setErrors(newErrors);
                }
                if (
                  e.target.value.length <= 5 &&
                  validateValue(e.target.value, "number")
                ) {
                  formik.setFieldValue(
                    "domicilioFiscal.codigoPostal",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length === 5) {
                  let newErrors = formik.errors;
                  if (newErrors?.domicilioFiscal !== undefined) {
                    if (newErrors?.domicilioFiscal?.colonia) {
                      delete newErrors?.domicilioFiscal?.colonia;
                    }
                    if (newErrors?.domicilioFiscal?.municipioId) {
                      delete newErrors?.domicilioFiscal?.municipioId;
                    }
                    if (newErrors?.domicilioFiscal?.estadoId) {
                      delete newErrors?.domicilioFiscal?.estadoId;
                    }
                    formik.setErrors(newErrors);
                  }
                  obtenerDireccionByCp(e.target.value);
                } else {
                  limpiarDireccionByCp();
                }
              }}
              InputProps={{ maxLength: 5 }}
              showErrorMessage={formik.errors?.domicilioFiscal?.codigoPostal}
              errorMessage={formik.errors?.domicilioFiscal?.codigoPostal}
              disabled={domicilioIgual}
            />
            <SelectWithError
              label="Colonia *"
              name="domicilioFiscal.colonia"
              value={{
                label: formik?.values?.domicilioFiscal?.colonia,
                value: formik?.values?.domicilioFiscal?.colonia,
              }}
              onChange={(e) => {
                formik.setFieldValue("domicilioFiscal.colonia", e.label);
              }}
              options={coloniasCP?.map((e) => {
                return { ...e, label: e, value: e };
              })}
              isDisabled={false}
              showErrorMessage={formik.errors?.domicilioFiscal?.colonia}
              errorMessage={formik.errors?.domicilioFiscal?.colonia}
            />
            <SelectWithError
              label="Alcaldía / Municipio *"
              name="domicilioFiscal.municipioId"
              value={{
                label: formik?.values?.domicilioFiscal?.municipioObj[0]?.nombre,
                value: formik?.values?.domicilioFiscal?.municipioObj[0]?.nombre,
              }}
              onChange={(e) => {
                formik.setFieldValue("domicilioFiscal.municipioId", e.id);
                formik.setFieldValue("domicilioFiscal.municipioObj", [e]);
              }}
              isDisabled={true}
              options={axiosData?.generales?.municipios?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.domicilioFiscal?.municipioId}
              errorMessage={formik.errors?.domicilioFiscal?.municipioId}
            />
            <SelectWithError
              label="Estado *"
              name="domicilioFiscal.estadoId"
              value={{
                label: formik?.values?.domicilioFiscal?.estadoObj[0]?.nombre,
                value: formik?.values?.domicilioFiscal?.estadoObj[0]?.nombre,
              }}
              isDisabled={true}
              onChange={(e) => {
                formik.setFieldValue("domicilioFiscal.estadoId", e.id);
                formik.setFieldValue("domicilioFiscal.estadoObj", [e]);
              }}
              options={axiosData?.generales?.estados?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.domicilioFiscal?.estadoId}
              errorMessage={formik.errors?.domicilioFiscal?.estadoId}
            />

            <SelectWithErrorTooltipCompuesto
              label="Giro *"
              name="domicilioFiscal.giroEmpresarialId"
              value={{
                label:
                  formik?.values?.domicilioFiscal?.giroEmpresarialObj[0]
                    ?.nombre,
                value:
                  formik?.values?.domicilioFiscal?.giroEmpresarialObj[0]
                    ?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.giroEmpresarialId
                ) {
                  delete newErrors?.domicilioFiscal?.giroEmpresarialId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("domicilioFiscal.giroEmpresarialId", e.id);
                formik.setFieldValue("domicilioFiscal.giroEmpresarialObj", [e]);
              }}
              options={axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              tooltipA="Comercio: Supermercados, tiendas, farmacias, ferreterías, etc."
              tooltipB="Industria: Manufacturera, constructoras, textil, alimenticia, etc."
              tooltipC="Servicios: Escuelas, hospitales, despachos, transporte, médicos, dentista, restaurantes, etc."
              showErrorMessage={
                formik.errors?.domicilioFiscal?.giroEmpresarialId
              }
              errorMessage={formik.errors?.domicilioFiscal?.giroEmpresarialId}
            />
            <SelectWithError
              label="Sector *"
              name="domicilioFiscal.sectorId"
              value={{
                label: formik?.values?.domicilioFiscal?.sectorObj[0]?.nombre,
                value: formik?.values?.domicilioFiscal?.sectorObj[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.sectorId
                ) {
                  delete newErrors?.domicilioFiscal?.sectorId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("domicilioFiscal.sectorId", e.id);
                formik.setFieldValue("domicilioFiscal.sectorObj", [e]);
              }}
              options={axiosData?.domicilioFiscal?.sectorOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={formik.errors?.domicilioFiscal?.sectorId}
              errorMessage={formik.errors?.domicilioFiscal?.sectorId}
            />
            <SelectWithErrorActividad
              label="Actividad *"
              name="domicilioFiscal.actividadRiesgoApoloId"
              isSearchable={false}
              value={{
                label:
                  formik?.values?.domicilioFiscal?.actividadRiesgoObj[0]
                    ?.detalleClave,
                value:
                  formik?.values?.domicilioFiscal?.actividadRiesgoObj[0]
                    ?.detalleClave,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.actividadRiesgoApoloId
                ) {
                  delete newErrors?.domicilioFiscal?.actividadRiesgoApoloId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "domicilioFiscal.actividadRiesgoApoloId",
                  e.id
                );
                formik.setFieldValue("domicilioFiscal.actividadRiesgoObj", [e]);
              }}
              options={axiosData?.domicilioFiscal?.actividadRiesgoApoloOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.detalleClave,
                    value: item.detalleClave,
                  };
                }
              )}
              tooltip="Selecciona la actividad que mas se asemeje a tu alta en hacienda. En caso de que tengas varias actividades, selecciona la preponderante."
              showErrorMessage={
                formik.errors?.domicilioFiscal?.actividadRiesgoApoloId
              }
              errorMessage={
                formik.errors?.domicilioFiscal?.actividadRiesgoApoloId
              }
            />
            <InputWithError
              label="Fecha alta de SHCP *"
              name="domicilioFiscal.fechaAltaShcp"
              type="date"
              value={formik.values?.domicilioFiscal?.fechaAltaShcpInput}
              InputProps={{ max: fechaActualYYYMMDD() }}
              InputLabelProps={{shrink: true}}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.fechaAltaShcp
                ) {
                  delete newErrors?.domicilioFiscal?.fechaAltaShcp;
                  formik.setErrors(newErrors);
                }
                let array = e.target.value.split("-").reverse().join("-");
                formik.setFieldValue("domicilioFiscal.fechaAltaShcp", array);
                formik.setFieldValue(
                  "domicilioFiscal.fechaAltaShcpInput",
                  e.target.value
                );
              }}
              showErrorMessage={formik.errors?.domicilioFiscal?.fechaAltaShcp}
              errorMessage={formik.errors?.domicilioFiscal?.fechaAltaShcp}
            />
            <SelectWithError
              label="Años de experiencia en el sector *"
              name="domicilioFiscal.aniosExperienciaSector"
              value={{
                label: formik?.values?.domicilioFiscal?.aniosExperienciaSector,
                value: formik?.values?.domicilioFiscal?.aniosExperienciaSector,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.aniosExperienciaSector
                ) {
                  delete newErrors?.domicilioFiscal?.aniosExperienciaSector;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "domicilioFiscal.aniosExperienciaSector",
                  e.value
                );
              }}
              options={anios?.map((item, index) => {
                return {
                  ...item,
                  label: index + 1,
                  value: index + 1,
                };
              })}
              showErrorMessage={
                formik.errors?.domicilioFiscal?.aniosExperienciaSector
              }
              errorMessage={
                formik.errors?.domicilioFiscal?.aniosExperienciaSector
              }
            />
            <SelectWithError
              label="¿Realizas actividades de importación o exportación? *"
              name="domicilioFiscal.importaExportaId"
              value={{
                label:
                  formik?.values?.domicilioFiscal?.importarExportarObj[0]
                    .nombre,
                value:
                  formik?.values?.domicilioFiscal?.importarExportarObj[0]
                    .nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.importaExportaId
                ) {
                  delete newErrors?.domicilioFiscal?.importaExportaId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("domicilioFiscal.importaExportaId", e.id);
                formik.setFieldValue("domicilioFiscal.importarExportarObj", [
                  e,
                ]);
              }}
              options={axiosData.domicilioFiscal.importaExportaOpciones.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.domicilioFiscal?.importaExportaId
              }
              errorMessage={formik.errors?.domicilioFiscal?.importaExportaId}
            />
            <NumberWithError
              label="Total de empleados *"
              name="domicilioFiscal.totalEmpleados"
              value={formik.values?.domicilioFiscal?.totalEmpleados}
              onValueChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.totalEmpleados
                ) {
                  delete newErrors?.domicilioFiscal?.totalEmpleados;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("domicilioFiscal.totalEmpleados", e.value);
              }}
              decimalScale={0}
              isAllowed={withValueCapMax}
              prefix={""}
              InputProps={{ maxLength: 3 }}
              showErrorMessage={formik.errors?.domicilioFiscal?.totalEmpleados}
              errorMessage={formik.errors?.domicilioFiscal?.totalEmpleados}
            />
            <NumberWithError
              label="Número de sucursales *"
              name="domicilioFiscal.numeroSucursales"
              value={formik.values?.domicilioFiscal?.numeroSucursales}
              onValueChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.numeroSucursales
                ) {
                  delete newErrors?.domicilioFiscal?.numeroSucursales;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "domicilioFiscal.numeroSucursales",
                  e.value
                );
              }}
              decimalScale={0}
              prefix={""}
              isAllowed={withValueCap}
              showErrorMessage={
                formik.errors?.domicilioFiscal?.numeroSucursales
              }
              errorMessage={formik.errors?.domicilioFiscal?.numeroSucursales}
            />
            <SelectWithError
              label="Propiedad de local *"
              name="domicilioFiscal.tipoLocalId"
              value={{
                label: formik?.values?.domicilioFiscal?.tipoLocalObj[0]?.nombre,
                value: formik?.values?.domicilioFiscal?.tipoLocalObj[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.tipoLocalId
                ) {
                  delete newErrors?.domicilioFiscal?.tipoLocalId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("domicilioFiscal.tipoLocalId", e.id);
                formik.setFieldValue("domicilioFiscal.tipoLocalObj", [e]);
              }}
              options={axiosData?.domicilioFiscal?.tipoLocalOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={formik.errors?.domicilioFiscal?.tipoLocalId}
              errorMessage={formik.errors?.domicilioFiscal?.tipoLocalId}
            />
            {parseInt(formik.values?.domicilioFiscal?.tipoLocalId) ===
              TIPO_LOCAL_RENTA && (
              <>
                <NumberWithError
                  label="Importe renta mensual *"
                  name="domicilioFiscal.rentaMensual"
                  value={formik.values?.domicilioFiscal?.rentaMensual}
                  onValueChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.domicilioFiscal !== undefined &&
                      newErrors?.domicilioFiscal?.rentaMensual
                    ) {
                      delete newErrors?.domicilioFiscal?.rentaMensual;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "domicilioFiscal.rentaMensual",
                      e.value
                    );
                  }}
                  decimalScale={2}
                  showErrorMessage={
                    formik.errors?.domicilioFiscal?.rentaMensual
                  }
                  errorMessage={formik.errors?.domicilioFiscal?.rentaMensual}
                />
                <SelectWithError
                  label="Años rentando *"
                  name="domicilioFiscal.aniosRentado"
                  menuPlacement="top"
                  value={{
                    label: formik?.values?.domicilioFiscal?.aniosRentado,
                    value: formik?.values?.domicilioFiscal?.aniosRentado,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.domicilioFiscal !== undefined &&
                      newErrors?.domicilioFiscal?.aniosRentado
                    ) {
                      delete newErrors?.domicilioFiscal?.aniosRentado;
                      formik.setErrors(newErrors);
                    }
                    formik.setFieldValue(
                      "domicilioFiscal.aniosRentado",
                      e.value
                    );
                  }}
                  options={anios?.map((item, index) => {
                    return {
                      ...item,
                      label: index + 1,
                      value: index + 1,
                    };
                  })}
                  showErrorMessage={
                    formik.errors?.domicilioFiscal?.aniosRentado
                  }
                  errorMessage={formik.errors?.domicilioFiscal?.aniosRentado}
                />
              </>
            )}
            <SelectWithError
              label="Años de antigüedad en tu actividad *"
              menuPlacement="top"
              showErrorMessage={
                formik.errors?.domicilioFiscal?.antiguedadActividad
              }
              errorMessage={formik.errors?.domicilioFiscal?.antiguedadActividad}
              name="domicilioFiscal.antiguedadActividad"
              value={{
                label: formik?.values?.domicilioFiscal?.antiguedadActividad,
                value: formik?.values?.domicilioFiscal?.antiguedadActividad,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.antiguedadActividad
                ) {
                  delete newErrors?.domicilioFiscal?.antiguedadActividad;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "domicilioFiscal.antiguedadActividad",
                  e.value
                );
              }}
              options={anios?.map((item, index) => {
                return {
                  ...item,
                  label: index + 1,
                  value: index + 1,
                };
              })}
            />
            <NumberWithError
              label="Pago mensual estimado de tu nómina *"
              name="domicilioFiscal.totalNominaMensual"
              value={formik.values?.domicilioFiscal?.totalNominaMensual}
              onValueChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioFiscal !== undefined &&
                  newErrors?.domicilioFiscal?.totalNominaMensual
                ) {
                  delete newErrors?.domicilioFiscal?.totalNominaMensual;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "domicilioFiscal.totalNominaMensual",
                  e.value
                );
              }}
              showErrorMessage={
                formik.errors?.domicilioFiscal?.totalNominaMensual
              }
              errorMessage={formik.errors?.domicilioFiscal?.totalNominaMensual}
            />
            <div className="d-flex gap-2">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(SECCION_DOMICILIO_FISCAL);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit">Siguiente</PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default DatosGeneralesDomicilioFiscalPFAE;

function initialValues(modeloPfae, axiosData) {
  let domicilioFiscal = modeloPfae.domicilioFiscal;
  return {
    idCliente: modeloPfae.idCliente,
    domicilioFiscal: {
      idDatosDelNegocio: domicilioFiscal.idDatosDelNegocio,
      fechaAltaShcp: domicilioFiscal.fechaAltaShcp
        ? domicilioFiscal.fechaAltaShcp
        : "",
      fechaAltaShcpInput: domicilioFiscal.fechaAltaShcp
        ? domicilioFiscal.fechaAltaShcp.split("-").reverse().join("-")
        : "",
      calle: domicilioFiscal.calle ? domicilioFiscal.calle : "",
      numeroExterior: domicilioFiscal.numeroExterior
        ? domicilioFiscal.numeroExterior
        : "",
      numeroInterior: domicilioFiscal.numeroInterior
        ? domicilioFiscal.numeroInterior
        : "",
      codigoPostal: domicilioFiscal.codigoPostal
        ? domicilioFiscal.codigoPostal
        : "",
      colonia: "",
      municipioId: domicilioFiscal.municipioId
        ? domicilioFiscal.municipioId
        : "",
      municipioObj: domicilioFiscal.municipioId
        ? axiosData?.generales?.municipios?.filter(
            (e) => e.id === domicilioFiscal?.municipioId
          )
        : [{ nombre: undefined }],
      estadoId: domicilioFiscal.estadoId ? domicilioFiscal.estadoId : "",
      estadoObj: domicilioFiscal.estadoId
        ? axiosData?.generales?.estados?.filter(
            (e) => e.id === domicilioFiscal.estadoId
          )
        : [{ nombre: undefined }],
      giroEmpresarialId: domicilioFiscal.giroEmpresarialId
        ? domicilioFiscal.giroEmpresarialId
        : "",
      giroEmpresarialObj: domicilioFiscal?.giroEmpresarialId
        ? axiosData?.domicilioFiscal?.giroEmpresarialOpciones.filter(
            (e) => e.id === domicilioFiscal.giroEmpresarialId
          )
        : [{ nombre: undefined }],
      aniosExperienciaSector: domicilioFiscal.aniosExperienciaSector
        ? domicilioFiscal.aniosExperienciaSector
        : "",
      aniosRentado: domicilioFiscal.aniosRentado
        ? domicilioFiscal.aniosRentado
        : "",
      antiguedadActividad: domicilioFiscal.antiguedadActividad
        ? domicilioFiscal.antiguedadActividad
        : "",
      sectorId: domicilioFiscal.sectorId ? domicilioFiscal.sectorId : "",
      sectorObj: domicilioFiscal.sectorId
        ? axiosData?.domicilioFiscal?.sectorOpciones.filter(
            (e) => e.id === domicilioFiscal?.sectorId
          )
        : [{ nombre: undefined }],
      actividadId: domicilioFiscal.actividadId
        ? domicilioFiscal.actividadId
        : "",
      actividadRiesgoApoloId: domicilioFiscal.actividadRiesgoApoloId
        ? domicilioFiscal.actividadRiesgoApoloId
        : "",
      actividadObj: axiosData?.domicilioFiscal?.actividadOpciones.filter(
        (e) => e.id === domicilioFiscal.actividadId
      ),
      actividadRiesgoObj:
        axiosData?.domicilioFiscal?.actividadRiesgoApoloOpciones.filter(
          (e) => e.id === domicilioFiscal.actividadRiesgoApoloId
        ),
      tipoLocalId: domicilioFiscal.tipoLocalId
        ? domicilioFiscal.tipoLocalId
        : "",
      tipoLocalObj: domicilioFiscal.tipoLocalId
        ? axiosData?.domicilioFiscal?.tipoLocalOpciones?.filter(
            (e) => e.id === domicilioFiscal.tipoLocalId
          )
        : [{ nombre: undefined }],
      importaExportaId: domicilioFiscal.importaExportaId
        ? domicilioFiscal.importaExportaId
        : "",
      importarExportarObj: domicilioFiscal.importaExportaId
        ? axiosData.domicilioFiscal.importaExportaOpciones.filter(
            (e) => e.id === domicilioFiscal.importaExportaId
          )
        : [{ nombre: undefined }],
      totalEmpleados: domicilioFiscal.totalEmpleados
        ? domicilioFiscal.totalEmpleados
        : "",
      numeroSucursales: domicilioFiscal.numeroSucursales
        ? domicilioFiscal.numeroSucursales
        : "",
      rentaMensual: domicilioFiscal.rentaMensual
        ? domicilioFiscal.rentaMensual
        : "",
      totalNominaMensual: domicilioFiscal.totalNominaMensual
        ? domicilioFiscal.totalNominaMensual
        : "",
      igualDomicilio: domicilioFiscal.igualDomicilio
        ? domicilioFiscal.igualDomicilio
        : false,
    },
  };
}

function validationSchema() {
  return {
    domicilioFiscal: Yup.object().shape({
      calle: Yup.string().required("Dato requerido."),
      numeroExterior: Yup.string().required("Dato requerido."),
      codigoPostal: Yup.string().required("Dato requerido."),
      colonia: Yup.string().required("Dato requerido."),
      municipioId: Yup.string().required("Dato requerido."),
      estadoId: Yup.string().required("Dato requerido."),
      fechaAltaShcp: Yup.string().required("Dato requerido."),
      giroEmpresarialId: Yup.string().required("Dato requerido."),
      sectorId: Yup.string().required("Dato requerido."),
      actividadId: Yup.string().required("Dato requerido."),
      actividadRiesgoApoloId: Yup.string().required("Dato requerido."),
      aniosExperienciaSector: Yup.string().required("Dato requerido."),
      importaExportaId: Yup.number().required("Dato requerido."),
      totalEmpleados: Yup.string()
        .required("Dato requerido.")
        .test(
          "totalEmpleados",
          "Por favor ingrese un valor entre 1 y 100,000",
          (item, testContext) => {
            return rangoValorValido(
              testContext.parent.totalEmpleados,
              100000,
              1
            );
          }
        ),
      numeroSucursales: Yup.string()
        .required("Dato requerido.")
        .test(
          "numeroSucursales",
          "Por favor ingrese un valor entre 0 y 9999",
          (item, testContext) => {
            return rangoValorValido(
              testContext.parent.numeroSucursales,
              9999,
              0
            );
          }
        ),
      aniosRentado: Yup.string().when("tipoLocalId", {
        is: TIPO_LOCAL_RENTA.toString(),
        then: Yup.string().required("Dato requerido."),
      }),
      rentaMensual: Yup.string().when("tipoLocalId", {
        is: TIPO_LOCAL_RENTA.toString(),
        then: Yup.string()
          .required("Dato requerido.")
          .test(
            "rentaMensual",
            "Por favor ingrese un valor mayor o igual a $5,000.00",
            (item, testContext) => {
              return valorMinimo(testContext.parent.rentaMensual, 5000);
            }
          ),
      }),
      tipoLocalId: Yup.string().required("Dato requerido."),
      antiguedadActividad: Yup.string().required("Dato requerido."),
      totalNominaMensual: Yup.string()
        .required("Dato requerido.")
        .test(
          "totalNominaMensual",
          "Por favor ingrese un valor mayor o igual a 5,000.00",
          (item, testContext) => {
            return valorMinimo(testContext.parent.totalNominaMensual, 5000);
          }
        ),
    }),
  };
}
