import React, { useState, useEffect } from "react";
import {
  SECCION_CARGA_DOCUMENTOS_SOLICITANTE,
  SECCION_CARGA_DOCUMENTOS_PA
} from "../../../../../utils/constantes";
import Popup from "../../../../tools/Popup";
import CargarDocumentoSolicitantePM from "../../PM/CargaDocumentos/CargarDocumentoSolicitantePM";
import CargarDocumentoPrincipalAccionista from "../../PM/CargaDocumentos/CargarDocumentoPrincipalAccionista";

const CargaDocumentosPM = ({modeloPm, setLoading, handleRegresarSeccionPyme}) => {
  const [selectedCargaDocumentos, setSelectedCargaDocumentos] = useState(SECCION_CARGA_DOCUMENTOS_SOLICITANTE);
  
  useEffect(() => {
    var p = document.getElementById('accionCrediactivo');
    p.innerHTML = 'Carga de documentos';
  }, []);

  return (
    <>
      <div className="container navigation div-menu-participantes-historial navigation-select">
        <>
          <p
            className={`${selectedCargaDocumentos ===
              SECCION_CARGA_DOCUMENTOS_SOLICITANTE
                ? "bold tab__selected text__red pointer tab"
                : null
              } pointer p-2 mb-0`}
            onClick={() => {
              setSelectedCargaDocumentos(
                SECCION_CARGA_DOCUMENTOS_SOLICITANTE
              );
            }}
          >
            Solicitante
          </p>
          <p
            className={`${selectedCargaDocumentos === SECCION_CARGA_DOCUMENTOS_PA &&
              "bold tab__selected text__red pointer tab"
              } pointer p-2 mb-0`}
            onClick={() =>
              setSelectedCargaDocumentos(SECCION_CARGA_DOCUMENTOS_PA)
            }
          >
            Representante legal
          </p>
        </>
      </div>
      {selectedCargaDocumentos === SECCION_CARGA_DOCUMENTOS_SOLICITANTE && (
          <CargarDocumentoSolicitantePM
            setLoading={setLoading}
            modeloDatos={modeloPm}
            handleRegresarSeccionPyme={handleRegresarSeccionPyme}
            setSelectedCargaDocumentos={
              setSelectedCargaDocumentos
            }
          />
        )
      }

      {selectedCargaDocumentos === SECCION_CARGA_DOCUMENTOS_PA && (
        <CargarDocumentoPrincipalAccionista
          setLoading={setLoading}
          modeloDatos={modeloPm}
          setSelectedCargaDocumentos={setSelectedCargaDocumentos}
          nombrePA={modeloPm.principalAccionista?.generales?.nombre + " " + modeloPm.principalAccionista?.generales?.apellidoPaterno}
        />
        )
      }
      

    </>
  );
};
export default CargaDocumentosPM;
