export const selectStyles = {
  menuList: (base) => ({
    ...base,
    color: "var(--gray )",
    background: "rgba(0, 0, 0, 0.06)",
    maxHeight: "300px",
    boxShadow: "none",
  }),
  container: (provided) => ({
    ...provided,
    minWidth: "100%",
    width: "100%",
    borderBottomLeftRadius: "4px",
    marginTop: "2%",
  }),
  control: (base, state) => ({
    ...base,
    outline: "none",
    margin: "5px 0",
    minWidth: "100%",
    background: "rgba(0, 0, 0, 0.06)",
    minHeight: "55px",
    border: "none",
    borderBottom: state.selectProps.error
      ? "3px solid red"
      : "0.1rem solid rgba(0, 0, 0, 0.42)",
    borderBottomLeftRadius: "4px",
    boxShadow: "none",
    padding: "15px 0 0 0",
    "&:hover": {
      borderColor: "none !important",
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#5B6670" : isFocused ? "#dee2e6" : null,
    fontFamily: "Gotham",
    fontWeight: "medium",
    fontSize: "14px",
    color: isSelected ? "#fff" : "#5B6670",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "hsl(0, 0%, 80%)",
    outline: "none",
    position: "relative",
  }),
  indicatorSeparator: () => ({
    display: "none",
    position: "relative",
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? "#A2A9AD" : "#5B6670",
  }),
};

export const selectStylesRe = {
  menuList: (base) => ({
    ...base,
    color: "var(--gray )",
    background: "rgba(0, 0, 0, 0.06)",
    maxHeight: "300px",
    boxShadow: "none",
  }),
  container: (provided) => ({
    ...provided,
    minWidth: "100%",
    width: "100%",
    borderBottomLeftRadius: "4px",
    paddingTop: "3%",
  }),
  control: (base, state) => ({
    ...base,
    outline: "none",
    margin: "5px 0",
    minWidth: "100%",
    background: "rgba(0, 0, 0, 0.06)",
    minHeight: "55px",
    border: "none",
    borderBottom: state.selectProps.error
      ? "3px solid red"
      : "0.1rem solid rgba(0, 0, 0, 0.42)",
    borderBottomLeftRadius: "4px",
    boxShadow: "none",
    padding: "15px 0 0 0",
    "&:hover": {
      borderColor: "none !important",
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#5B6670" : isFocused ? "#dee2e6" : null,
    fontFamily: "Gotham",
    fontWeight: "medium",
    fontSize: "14px",
    color: isSelected ? "#fff" : "#5B6670",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "hsl(0, 0%, 80%)",
    outline: "none",
    position: "relative",
  }),
  indicatorSeparator: () => ({
    display: "none",
    position: "relative",
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? "#A2A9AD" : "#5B6670",
  }),
};

export const selectStylesRepresentanteLegal = {
  menuList: (base) => ({
    ...base,
    color: "var(--gray )",
    background: "rgba(0, 0, 0, 0.06)",
    maxHeight: "300px",
    boxShadow: "none",
  }),
  container: (provided) => ({
    ...provided,
    minWidth: "100%",
    width: "100%",
    borderBottomLeftRadius: "4px",
  }),
  control: (base, state) => ({
    ...base,
    outline: "none",
    margin: "5px 0",
    minWidth: "100%",
    background: "rgba(0, 0, 0, 0.06)",
    minHeight: "55px",
    border: "none",
    borderBottom: state.selectProps.error
      ? "3px solid red"
      : "0.1rem solid rgba(0, 0, 0, 0.42)",
    borderBottomLeftRadius: "4px",
    boxShadow: "none",
    padding: "15px 0 0 0",
    "&:hover": {
      borderColor: "none !important",
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#5B6670" : isFocused ? "#dee2e6" : null,
    fontFamily: "Gotham",
    fontWeight: "medium",
    fontSize: "14px",
    color: isSelected ? "#fff" : "#5B6670",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "hsl(0, 0%, 80%)",
    outline: "none",
    position: "relative",
  }),
  indicatorSeparator: () => ({
    display: "none",
    position: "relative",
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? "#A2A9AD" : "#5B6670",
  }),
};

export const selectStylesDouble = {
  menuList: (base) => ({
    ...base,
    color: "var(--gray)",
    background: "rgba(0, 0, 0, 0.06)",
    maxHeight: "300px",
    boxShadow: "none",
  }),
  container: (provided) => ({
    ...provided,
    minWidth: "100%",
    width: "100%",
    borderBottomLeftRadius: "4px",
  }),
  control: (base, state) => ({
    ...base,
    outline: "none",
    margin: "5px 0",
    minWidth: "50%",
    background: "rgba(0, 0, 0, 0.06)",
    minHeight: "58.5px",
    border: "none",
    borderBottom: state.selectProps.error
      ? "3px solid red"
      : "0.1rem solid rgba(0, 0, 0, 0.42)",
    borderBottomLeftRadius: "4px",
    padding: "15px 0 0 0",
    "&:hover": {
      borderColor: "none !important",
      boxShadow: "none !important",
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#5B6670" : isFocused ? "#dee2e6" : null,
    fontFamily: "Gotham",
    fontWeight: "medium",
    fontSize: "14px",
    color: isSelected ? "#fff" : "#5B6670",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "hsl(0, 0%, 80%)",
    outline: "none",
    position: "relative",
  }),
  indicatorSeparator: () => ({
    display: "none",
    position: "relative",
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? "#A2A9AD" : "#5B6670",
  }),
};
