import React from "react";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import { validateValue } from "../../../../../functions/fieldFunctions";

const DatosPatrimonioPFAE = ({ numPatrimonio, formik }) => {
  return (
    <>
      <h3 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3">
        Patrimonio {numPatrimonio + 1}
      </h3>
      <InputWithError
        abel="Ubicación *"
        name={`patrimonioPrincipal.patrimonios.${numPatrimonio}.ubicacion`}
        value={
          formik.values?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.ubicacion
        }
        onChange={(e) => {
          if (validateValue(e.target.value, "number")) {
            formik.setFieldValue(
              `patrimonioPrincipal.patrimonios.${numPatrimonio}.ubicacion`,
              e.target.value
            );
            formik.setFieldValue(
              `patrimonioPrincipal.patrimonios.${numPatrimonio}.numeroPatrimonio`,
              numPatrimonio + 1
            );
          }
        }}
        InputProps={{ maxLength: 500 }}
        errorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.ubicacion
        }
        showErrorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.ubicacion
        }
      />

      <InputWithError
        label="Superficie M2 *"
        name={`patrimonioPrincipal.patrimonios.${numPatrimonio}.superficeMetrosCuadrados`}
        value={
          formik.values?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.superficeMetrosCuadrados
        }
        onChange={(e) => {
          if (validateValue(e.target.value, "number")) {
            formik.setFieldValue(
              `patrimonioPrincipal.patrimonios.${numPatrimonio}.superficeMetrosCuadrados`,
              e.target.value
            );
          }
        }}
        className="input-with-formulario"
        InputProps={{ maxLength: 500 }}
        errorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.superficeMetrosCuadrados
        }
        showErrorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.superficeMetrosCuadrados
        }
      />
      <InputWithError
        label="Descripción del inmueble *"
        name={`patrimonioPrincipal.patrimonios.${numPatrimonio}.descripcionInmueble`}
        value={
          formik.values?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.descripcionInmueble
        }
        onChange={(e) => {
          if (validateValue(e.target.value, "text")) {
            formik.setFieldValue(
              `patrimonioPrincipal.patrimonios.${numPatrimonio}.descripcionInmueble`,
              e.target.value
            );
          }
        }}
        InputProps={{ maxLength: 180 }}
        errorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.descripcionInmueble
        }
        showErrorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.descripcionInmueble
        }
      />
      <InputWithError
        name={`patrimonioPrincipal.patrimonios.${numPatrimonio}.valorPredial`}
        value={
          formik.values?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.valorPredial
        }
        onChange={(e) => {
          if (validateValue(e.target.value, "number")) {
            formik.setFieldValue(
              `patrimonioPrincipal.patrimonios.${numPatrimonio}.valorPredial`,
              e.target.value
            );
          }
        }}
        label="Valor predial *"
        errorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.valorPredial
        }
        showErrorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.valorPredial
        }
      />
      <InputWithError
        name={`patrimonioPrincipal.patrimonios.${numPatrimonio}.datosRegistro`}
        value={
          formik.values?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.datosRegistro
        }
        onChange={(e) => {
          if (validateValue(e.target.value, "text")) {
            formik.setFieldValue(
              `patrimonioPrincipal.patrimonios.${numPatrimonio}.datosRegistro`,
              e.target.value
            );
          }
        }}
        InputProps={{ maxLength: 180 }}
        label="Datos de registro *"
        errorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.datosRegistro
        }
        showErrorMessage={
          formik.errors?.patrimonioPrincipal?.patrimonios[numPatrimonio]
            ?.datosRegistro
        }
      />
    </>
  );
};

export default DatosPatrimonioPFAE;
