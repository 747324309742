import React, { useEffect, useState } from "react";
import {
  PANTALLA_CONSULTA_POR_NIP,
  PANTALLA_CONSULTA_TRADICIONAL,
  PANTALLA_CONSULTA_AUTENTICADA,
  CASADO_SOCIEDAD_CONYUGAL,
  PASO_SOLICITUD_EVALUACION,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../utils/constantes";
import { api_obtener_os_solicitud } from "../../../api/api_servicios_rest";
import ConsultaPorNip from "./PM/ConsultaPorNip/ConsultaPorNip";
import DecretoSolicitud from "./DecretoSolicitud";
import {
  sweetAlertError,
  sweetAlertWithConfirmedCrediactivo,
} from "./alertas/alertas";

const HistorialCrediticio = ({
  modeloDatos,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setStyleHr,
  setLoading,
  setShowResult,
  cantidadObligadosSolidarios,
  tipoPersona,
  setResultadoEvaluacionRiesgos,
  setFormSection,
  ultimoPasoSolicitud,
  setUltimoPasoSolicitud,
}) => {
  const [consultaAutenticada, setConsultaAutenticada] = useState(false);
  const [consultaTradicional, setConsultaTradicional] = useState(false);
  const [consultaPorPin, setConsultaPorPin] = useState(false);
  const [contadorOS, setContadorOS] = useState(0);

  const [selectedConsultaNip, setSelectedConsultaNip] = useState(1);
  const [selectedConsultaAutenticada, setSelectedConsultaAutenticada] =
    useState(1);
  const [selectedConsultaTradicional, setSelectedConsultaTradicional] =
    useState(1);
  const [selectedConsultaNipPFAE, setSelectedConsultaNipPFAE] = useState(1);
  const [selectedConsultaAutenticadaPFAE, setSelectedConsultaAutenticadaPFAE] =
    useState(1);
  const [selectedConsultaTradicionalPFAE, setSelectedConsultaTradicionalPFAE] =
    useState(1);
  const [options, setOptions] = useState([]);
  const [documentosSolicud, setDocumentosSolicud] = useState([]);
  const [obligadosSolidarios, setObligadosSolidarios] = useState([]);
  const [principalAccionista, setPrincipalAccionista] = useState();
  const [principalAccionistaConyuge, setPrincipalAccionistaConyuge] =
    useState();
  const [numOSSalto, setNumOSSalto] = useState();
  const [idEstadoCivilSolicitante, setIdEstadoCivilSolicitante] = useState();
  const [showDecreto, setShowDecreto] = useState(false);

  const obtenerObligadosSolidariosSolicitud = async () => {
    const datosCliente = {
      idCliente: modeloDatos.idCliente,
    };
    try {
      const response = await api_obtener_os_solicitud(datosCliente);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          modeloDatos.obligadosSolidarios = data.obligadosSolidarios;
          setObligadosSolidarios(data.obligadosSolidarios);
          setPrincipalAccionista(data.principalAccionista);
          setIdEstadoCivilSolicitante(data.idEstadoCivilSolicitante);
          if (
            data?.principalAccionista?.generales?.estadoCivilId ==
            CASADO_SOCIEDAD_CONYUGAL
          ) {
            setPrincipalAccionistaConyuge(
              data?.principalAccionista?.conyuge?.idConyuge
            );
            setNumOSSalto(4);
          } else {
            setNumOSSalto(3);
          }
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data.mensaje) {
            sweetAlertWithConfirmedCrediactivo(data?.mensaje);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    let letOptions = [];

    for (let i = 0; i < 4; i++) {
      letOptions.push(i);
    }
    setOptions(letOptions);
    obtenerObligadosSolidariosSolicitud()
      .then((r) => setLoading(false))
      .catch((e) => console.error(e));
  }, [cantidadObligadosSolidarios]);

  useEffect(() => {
    var p = document.getElementById("accionCrediactivo");
    if (showDecreto) {
      setFormSection("Resultado pre evaluación");
      p.innerHTML = "Pre evaluación";
    } else {
      if (ultimoPasoSolicitud >= PASO_SOLICITUD_EVALUACION) {
        setFormSection("Resultado pre evaluación");
        setShowDecreto(true);
      } else {
        p.innerHTML = "Historial crediticio";
      }
    }
  }, [showDecreto]);

  return (
    <>
      {showDecreto === false ? (
        <ConsultaPorNip
          tipoPersona={tipoPersona}
          principalAccionista={principalAccionista}
          selectedConsultaNip={selectedConsultaNip}
          setSelectedConsultaNip={setSelectedConsultaNip}
          selectedConsultaNipPFAE={selectedConsultaNipPFAE}
          setSelectedConsultaNipPFAE={setSelectedConsultaNipPFAE}
          obligadosSolidarios={obligadosSolidarios}
          modeloDatos={modeloDatos}
          setLoading={setLoading}
          setStyleHr={setStyleHr}
          handleSeccionPyme={handleSeccionPyme}
          handleRegresarSeccionPyme={handleRegresarSeccionPyme}
          principalAccionistaConyuge={principalAccionistaConyuge}
          setContadorOS={setContadorOS}
          contadorOS={contadorOS}
          idEstadoCivilSolicitante={idEstadoCivilSolicitante}
          setShowDecreto={setShowDecreto}
          setUltimoPasoSolicitud={setUltimoPasoSolicitud}
        />
      ) : (
        <DecretoSolicitud
          handleSeccionPyme={handleSeccionPyme}
          handleRegresarSeccionPyme={handleRegresarSeccionPyme}
          modeloDatos={modeloDatos}
          setLoading={setLoading}
          tipoPersona={tipoPersona}
        />
      )}
    </>
  );
};

export default HistorialCrediticio;
