import React from "react";
import "./ErrorMessage.css";

const ErrorMessage = ({ message, width = "100%" }) => {
  return (
    <div className="error-container" style={{ width: `${width}%` }}>
      <span className="text_red">{message}</span>
    </div>
  );
};

export default ErrorMessage;
