import React, { useEffect, useState } from "react";
import "./DecretoSolicitud.css";
import { PrimaryButton, SecondaryButton } from "../../basics/Buttons/Buttons";
import { SECCION_HISTORIAL_CREDITICIO } from "../../../utils/constantes";
import { api_avanzar_recabar_documentos } from "../../../api/api_servicios_rest_crediactivo";
import { sweetAlertWarning } from "./alertas/alertas";
import {
  KEY_PFAE
} from "../../../utils/constantes";

const DecretoSolicitud = ({
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  modeloDatos,
  setLoading,
  tipoPersona,
}) => {
  const [nombreCliente, setNombreCliente] = useState("");

  useEffect(() => {
    let nombreCliente = "";
    if(tipoPersona == KEY_PFAE){
      nombreCliente =  modeloDatos?.generales?.nombre + " " + modeloDatos?.generales?.apellidoPaterno;
    } else{
      nombreCliente = modeloDatos?.generales?.razonSocial;
    }    
    
    setNombreCliente(nombreCliente);
  }, []);

  const mensajeDescreto = [
    {
      leyendaPrincipal:
        "Puedes obtener un Crédito Simple para que lo utilices en lo que necesites para tu negocio, estás son las características del crédito:",
      leyendaSecundaria:
        "Si deseas obtener tu crédito da clic en Continuar y avanza con los siguientes pasos",
    },
    {
      leyendaPrincipal: "Hemos analizado la información de tu perfil",
      leyendaSecundaria:
        "Por el momento no fue posible autorizar tu solicitud, te recomendamos intentar en 3 meses, sin embargo tenemos más soluciones que pueden ayudar a desarrollar tu negocio, visita www.circulopymebanorte.com",
    },
    {
      leyendaPrincipal: "En breve nos comunicaremos contigo",
      leyendaSecundaria:
        "Espera un correo con tu oferta en la próxima hora. Si no recibe una respuesta, comunícate con nostros al 5566094379",
    },
  ];

  let mensajeBtnAprobado = "Mensaje Aprobado";
  let mensajeBtnRechazado = "Mensaje Rechazado";
  let mensajeBtnProceso = "Mensaje En proceso";

  // 1 Aprobado, 2 Rechazada, 3 En Proceso
  let estatusSolicitud = 1;

  const verificarAvanceSolicitud = () => {
    if (estatusSolicitud == 1) {
      const datosCredito = {
        idSolicitud: modeloDatos.idSolicitud,
        idCliente: modeloDatos.idCliente,
      };
      avanzarRecabarDocumentos(datosCredito);
    }
  };

  const avanzarRecabarDocumentos = async (formData) => {
    try {
      const response = await api_avanzar_recabar_documentos(formData);
      if (response?.statusText === "OK") {
        const data = response?.data;
        if (data?.success) {
          setLoading(false);
          handleSeccionPyme(SECCION_HISTORIAL_CREDITICIO);
        } else {
          setLoading(false);
          sweetAlertWarning(data?.mensaje);
        }
      } else {
        setLoading(false);
        sweetAlertWarning(response?.data?.mensaje);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bgc-white bgc-white-div">
      <div className="bgc-red">
        <p className="text-center decreto-mensaje">
          {estatusSolicitud == 1
            ? mensajeBtnAprobado
            : estatusSolicitud == 2
            ? mensajeBtnRechazado
            : mensajeBtnProceso}
        </p>
      </div>

      <span className="text-center f-s-2 decreto-felicitacion">
        {" "}
        {estatusSolicitud == 1
          ? "¡Felicidades, "+nombreCliente+"!"
          : "¡Gracias, "+nombreCliente+"!"}{" "}
      </span>

      <div className="decreto-leyenda-principal">
        <p className="text-center f-s-3 decreto-felicitacion">
          {" "}
          {estatusSolicitud == 1
            ? mensajeDescreto[0].leyendaPrincipal
            : estatusSolicitud == 2
            ? mensajeDescreto[1].leyendaPrincipal
            : mensajeDescreto[2].leyendaPrincipal}{" "}
        </p>
      </div>

      <div className="decreto-container-resultado">
        {estatusSolicitud == 1 && (
          <>
            <div className="decreto-datos-contenido">
              <p className="text-left"> Monto pre aprobado </p>
              <p className="text-left"> Plazo </p>
              <p className="text-left"> Tasa desde </p>
              <p className="text-left"> Comisión por apertura </p>
              <p className="text-left"> Fecha de resolución </p>

              <p className="text-center decreto-leyenda-secundaria">
                {" "}
                {mensajeDescreto[0].leyendaSecundaria}
              </p>
            </div>

            <div className="d-flex justify-content-center gap-3 mb-3">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(SECCION_HISTORIAL_CREDITICIO);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                onClick={(e) => {
                  verificarAvanceSolicitud();
                }}
              >
                Continuar
              </PrimaryButton>
            </div>
          </>
        )}

        {estatusSolicitud == 2 && (
          <>
            <div className="decreto-datos-contenido">
              <p className="text-center decreto-mensaje-datos">
                {" "}
                Gracias {nombreCliente}, tu solicitud fue revisada
              </p>

              <p className="text-center decreto-leyenda-secundaria">
                {" "}
                {mensajeDescreto[1].leyendaSecundaria}
              </p>
            </div>

            <div className="button--container">
              <PrimaryButton type="submit">Cerrar sesión</PrimaryButton>
            </div>

            <p className="text-left decreto-fecha_resolucion">
              {" "}
              Fecha de resolución{" "}
            </p>
          </>
        )}

        {estatusSolicitud == 3 && (
          <>
            <div className="decreto-datos-contenido">
              <p className="text-center decreto-mensaje-datos">
                {" "}
                Estamos analizando tu información
              </p>

              <p className="text-center decreto-leyenda-secundaria">
                {" "}
                {mensajeDescreto[2].leyendaSecundaria}
              </p>
            </div>

            <div className="button--container">
              <PrimaryButton type="submit">Cerrar sesión</PrimaryButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DecretoSolicitud;
