import React from "react";
import "../Cotizador.css";
import { BsEye } from "react-icons/bs";
import {TIPO_CREDITO_REVOLVENTE, TIPO_CREDITO_SIMPLE} from "../../../utils/constantes";

const CreditType = ({
  card,
  meses,
  mensualidad,
  monto,
  tipoDeCredito,
  tasaAnualAts,
  onClickCard = () => {},
  onClickIcon = () => {},
  selectedCard,
}) => {
  return (
    <div className="container">
      <div
        className={
          selectedCard === card
            ? "card cardSelected shadow-md"
            : "card shadow-md"
        }
        onClick={onClickCard}
      >        
        {
          tipoDeCredito?.id?.toString() == TIPO_CREDITO_REVOLVENTE && (
            <>
              <div className="card--meses">
                <span>{meses}</span>
                <span className="card--meses__title">meses</span>
              </div>
              <span className="line-separation-card" />
              <div className="card--meses--monto-revolvente">
                <span className="card--meses__title">Por cada 100 mil pesos que dispongas de tu línea, al mes deberás<br/>
                pagar intereses por $1,657 pesos.<br/>
                *Interés calculado con una tasa de referencia anual del {tasaAnualAts * 100}%
                </span>
              </div>
              <span className="line-separation-card" hidden />
              <div className="card--info card--info__icon" hidden>
                <span onClick={onClickIcon} className="pointer">
                <BsEye size={window.innerWidth <= 991 ? 18:28} className="cotizador-img-tabla"/> 
                  Tabla de amortización
                </span>
              </div>
            </>
          )
        }
        {
          tipoDeCredito?.id?.toString() == TIPO_CREDITO_SIMPLE && (
            <>
              <div className="card--meses">
                <span>{meses}</span>
                <span className="card--meses__title">meses</span>
              </div>
              <span className="line-separation-card" />
              <div className="card--meses">
                <span>${monto}</span>
                <span className="card--meses__title">{mensualidad}</span>
              </div>
              <span className="line-separation-card" />
              <div className="card--info card--info__icon">
                <span onClick={onClickIcon} className="pointer">
                  <BsEye size={28} />
                  Tabla de amortización
                </span>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default CreditType;
