import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api_save_datos_contacto_empresa_PM } from "../../../../api/api_servicios_rest_crediactivo";
import {
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
} from "../../../../utils/validaciones";
import {
  SECCION_REPRESENTANTE_LEGAL,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../../utils/constantes";
import { validarTextoConEnies } from "../../../../functions/fieldFunctions";
import FormLayout from "../../../helpers/FormLayout/FormLayout";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import SelectWithErrorRepresentanteLegal from "../../../basics/SelectWithError/SelectWithErrorRepresentanteLegal";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../basics/Buttons/Buttons";
import {
  sweetAlertError,
  sweetAlertWithConfirmedCrediactivo,
} from "../alertas/alertas";

const DatosGeneralesRepresentanteLegalPM = ({
  modeloPm,
  setModeloPm,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setStyleHr,
  setLoading,
  setActualizarPA,
}) => {
  const formik = useFormik({
    initialValues: initialValues(modeloPm),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosContactosEmpresaPM(formData);
    },
  });

  const handleChangeIgualRepresentanteLegal = async (event) => {
    if (event.target.value !== undefined) {
      let valor = JSON.parse(event.target.value);
      if (valor) {
        let nombreCompleto =
          formik.values?.representanteLegal?.nombre +
          " " +
          formik.values?.representanteLegal?.apellidoPaterno +
          " " +
          formik.values?.representanteLegal?.apellidoMaterno;
        await formik.setFieldValue(
          "contactoDeLaEmpresa.nombre",
          nombreCompleto
        );
        await formik.setFieldValue(
          "contactoDeLaEmpresa.cargo",
          formik.values?.representanteLegal?.cargo
        );
        await formik.setFieldValue(
          "contactoDeLaEmpresa.telefono",
          formik.values?.representanteLegal?.telefono
        );

        let newErrors = formik.errors;
        delete newErrors?.contactoDeLaEmpresa?.nombre;
        delete newErrors?.contactoDeLaEmpresa?.cargo;
        delete newErrors?.contactoDeLaEmpresa?.telefono;
        formik.setErrors(newErrors);
      } else {
        await formik.setFieldValue("contactoDeLaEmpresa.nombre", "");
        await formik.setFieldValue("contactoDeLaEmpresa.cargo", "");
        await formik.setFieldValue("contactoDeLaEmpresa.telefono", "");
      }
    }
  };

  const validarIgualRepresentanteLegal = (valor, seccionNombre) => {
    let nombreCompleto;
    if (formik.values?.contactoDeLaEmpresa?.igualRepresentanteLegal) {
      switch (seccionNombre) {
        case "nombre":
          nombreCompleto =
            valor +
            " " +
            formik.values?.representanteLegal?.apellidoPaterno +
            " " +
            formik.values?.representanteLegal?.apellidoMaterno;
          break;
        case "app":
          nombreCompleto =
            formik.values?.representanteLegal?.nombre +
            " " +
            valor +
            " " +
            formik.values?.representanteLegal?.apellidoMaterno;
          break;
        case "apm":
          nombreCompleto =
            formik.values?.representanteLegal?.nombre +
            " " +
            formik.values?.representanteLegal?.apellidoPaterno +
            " " +
            valor;
          break;
      }

      if (formik.values?.contactoDeLaEmpresa?.nombre !== nombreCompleto) {
        formik.setFieldValue(
          "contactoDeLaEmpresa.igualRepresentanteLegal",
          false
        );
      } else {
        formik.setFieldValue(
          "contactoDeLaEmpresa.igualRepresentanteLegal",
          true
        );
      }
    }
  };

  const salvarDatosContactosEmpresaPM = async (formData) => {
    try {
      const response = await api_save_datos_contacto_empresa_PM(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          if (data.actualizarPa) {
            setModeloPm({
              ...modeloPm,
              idCliente: data.clienteId,
              representanteLegal: data.representanteLegal,
              contactoDeLaEmpresa: data.contactoDeLaEmpresa,
              principalAccionista: data.modeloPa,
            });
            modeloPm.principalAccionista.generales.rfc = "";
          } else {
            setModeloPm({
              ...modeloPm,
              idCliente: data.clienteId,
              representanteLegal: data.representanteLegal,
              contactoDeLaEmpresa: data.contactoDeLaEmpresa,
            });
          }

          setActualizarPA(data.actualizarPa);

          setStyleHr("block");
          handleSeccionPyme(SECCION_REPRESENTANTE_LEGAL);
          formik.setFieldValue(
            "representanteLegal.idContactoDeLaEmpresa",
            data.representanteLegalId
          );
          formik.setFieldValue(
            "contactoDeLaEmpresa.idContactoDeLaEmpresa",
            data.contactoEmpresaId
          );
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data.mensaje) {
            sweetAlertWithConfirmedCrediactivo(data?.mensaje);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertError(
          "Ocurrió un error al guardar datos contacto empresa PM"
        );
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <form name="form-representante-legal" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Representante legal</h2>
          <FormLayout>
            <InputWithError
              label="Nombre *"
              name="representanteLegal.nombre"
              value={formik.values?.representanteLegal?.nombre}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.representanteLegal !== undefined &&
                  newErrors?.representanteLegal?.nombre
                ) {
                  delete newErrors?.representanteLegal?.nombre;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "representanteLegal.nombre",
                    e.target.value
                  );
                  validarIgualRepresentanteLegal(e.target.value, "nombre");
                }
              }}
              InputProps={{ maxLength: 30 }}
              showErrorMessage={formik.errors?.representanteLegal?.nombre}
              errorMessage={formik.errors?.representanteLegal?.nombre}
            />
            <InputWithError
              label="Apellido paterno *"
              name="representanteLegal.apellidoPaterno"
              value={formik.values?.representanteLegal?.apellidoPaterno}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.representanteLegal !== undefined &&
                  newErrors?.representanteLegal?.apellidoPaterno
                ) {
                  delete newErrors?.representanteLegal?.apellidoPaterno;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "representanteLegal.apellidoPaterno",
                    e.target.value
                  );
                  validarIgualRepresentanteLegal(e.target.value, "app");
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.representanteLegal?.apellidoPaterno
              }
              errorMessage={formik.errors?.representanteLegal?.apellidoPaterno}
            />
            <InputWithError
              label="Apellido materno"
              name="representanteLegal.apellidoMaterno"
              value={formik.values?.representanteLegal?.apellidoMaterno}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.representanteLegal !== undefined &&
                  newErrors?.representanteLegal?.apellidoMaterno
                ) {
                  delete newErrors?.representanteLegal?.apellidoMaterno;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "representanteLegal.apellidoMaterno",
                    e.target.value
                  );
                  validarIgualRepresentanteLegal(e.target.value, "apm");
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.representanteLegal?.apellidoMaterno
              }
              errorMessage={formik.errors?.representanteLegal?.apellidoMaterno}
            />
            <InputWithError
              label="Cargo *"
              name="representanteLegal.cargo"
              value={formik.values?.representanteLegal?.cargo}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.representanteLegal !== undefined &&
                  newErrors?.representanteLegal?.cargo
                ) {
                  delete newErrors?.representanteLegal?.cargo;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "representanteLegal.cargo",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 50 }}
              showErrorMessage={formik.errors?.representanteLegal?.cargo}
              errorMessage={formik.errors?.representanteLegal?.cargo}
            />
            <InputWithError
              name="representanteLegal.telefono"
              value={formik.values?.representanteLegal?.telefono}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.representanteLegal !== undefined &&
                  newErrors?.representanteLegal?.telefono
                ) {
                  delete newErrors?.representanteLegal?.telefono;
                  formik.setErrors(newErrors);
                }
                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.representanteLegal?.telefono
                );
                formik.setFieldValue(
                  "representanteLegal.telefono",
                  numTelefono
                );
              }}
              type="tel"
              label="Teléfono *"
              InputProps={{ maxLength: 12 }}
              disabled={true}
              showErrorMessage={formik.errors?.representanteLegal?.telefono}
              errorMessage={formik.errors?.representanteLegal?.telefono}
            />
            <h2
              style={{
                textAlign: "left",
                width: "100%",
              }}
              className="f-s-3"
            >
              Contacto con la empresa
            </h2>
            <SelectWithErrorRepresentanteLegal
              label="Mismos datos que el representante legal"
              name="contactoDeLaEmpresa.igualRepresentanteLegal"
              value={
                formik.values?.contactoDeLaEmpresa?.igualRepresentanteLegal
                  ? { label: "Si", value: true }
                  : { label: "No", value: false }
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.contactoDeLaEmpresa !== undefined &&
                  newErrors?.contactoDeLaEmpresa?.igualRepresentanteLegal
                ) {
                  delete newErrors?.contactoDeLaEmpresa
                    ?.igualRepresentanteLegal;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "contactoDeLaEmpresa.igualRepresentanteLegal",
                  e.value
                );
                const event = { ...e, target: { value: e.value } };
                handleChangeIgualRepresentanteLegal(event);
              }}
              options={[
                { label: "Si", value: true },
                { label: "No", value: false },
              ]}
            />
            <InputWithError
              label="Nombre completo *"
              name="contactoDeLaEmpresa.nombre"
              value={formik.values?.contactoDeLaEmpresa?.nombre}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.contactoDeLaEmpresa !== undefined &&
                  newErrors?.contactoDeLaEmpresa?.nombre
                ) {
                  delete newErrors?.contactoDeLaEmpresa?.nombre;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "contactoDeLaEmpresa.nombre",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 70 }}
              showErrorMessage={formik.errors?.contactoDeLaEmpresa?.nombre}
              errorMessage={formik.errors?.contactoDeLaEmpresa?.nombre}
            />
            <InputWithError
              label="Cargo *"
              name="contactoDeLaEmpresa.cargo"
              value={formik.values?.contactoDeLaEmpresa?.cargo}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.contactoDeLaEmpresa !== undefined &&
                  newErrors?.contactoDeLaEmpresa?.cargo
                ) {
                  delete newErrors?.contactoDeLaEmpresa?.cargo;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "contactoDeLaEmpresa.cargo",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 50 }}
              showErrorMessage={formik.errors?.contactoDeLaEmpresa?.cargo}
              errorMessage={formik.errors?.contactoDeLaEmpresa?.cargo}
            />
            <InputWithError
              label="Teléfono *"
              name="contactoDeLaEmpresa.telefono"
              value={formik.values?.contactoDeLaEmpresa?.telefono}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.contactoDeLaEmpresa !== undefined &&
                  newErrors?.contactoDeLaEmpresa?.telefono
                ) {
                  delete newErrors?.contactoDeLaEmpresa?.telefono;
                  formik.setErrors(newErrors);
                }
                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.contactoDeLaEmpresa?.telefono
                );
                formik.setFieldValue(
                  "contactoDeLaEmpresa.telefono",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={formik.errors?.contactoDeLaEmpresa?.telefono}
              errorMessage={formik.errors?.contactoDeLaEmpresa?.telefono}
            />
            <div className="d-flex gap-2">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(SECCION_REPRESENTANTE_LEGAL);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit" disabled={formik.isSubmitting}>
                Siguiente
              </PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default DatosGeneralesRepresentanteLegalPM;

function initialValues(modeloPm) {
  let igualDatos = false;
  if (modeloPm.representanteLegal.idContactoDeLaEmpresa != null) {
    if (modeloPm.contactoDeLaEmpresa) {
      let nombreRepresentante =
        modeloPm.representanteLegal.nombre +
        " " +
        modeloPm.representanteLegal.apellidoPaterno +
        " " +
        modeloPm.representanteLegal.apellidoMaterno;
      if (
        nombreRepresentante === modeloPm.contactoDeLaEmpresa.nombre &&
        modeloPm.representanteLegal.cargo ===
          modeloPm.contactoDeLaEmpresa.cargo &&
        modeloPm.representanteLegal.telefono ===
          modeloPm.contactoDeLaEmpresa.telefono
      ) {
        igualDatos = true;
      }
    }
  }

  return {
    idCliente: modeloPm.idCliente,
    representanteLegal: {
      idContactoDeLaEmpresa: modeloPm.representanteLegal.idContactoDeLaEmpresa,
      nombre: modeloPm.representanteLegal.nombre
        ? modeloPm.representanteLegal.nombre
        : "",
      apellidoPaterno: modeloPm.representanteLegal.apellidoPaterno
        ? modeloPm.representanteLegal.apellidoPaterno
        : "",
      apellidoMaterno: modeloPm.representanteLegal.apellidoMaterno
        ? modeloPm.representanteLegal.apellidoMaterno
        : "",
      cargo: modeloPm.representanteLegal.cargo
        ? modeloPm.representanteLegal.cargo
        : "",
      telefono: modeloPm.representanteLegal.telefono
        ? modeloPm.representanteLegal.telefono
        : "",
      codigoSmsBanorteId: modeloPm.representanteLegal.codigoSmsBanorteId
        ? modeloPm.representanteLegal.codigoSmsBanorteId
        : "",
    },
    contactoDeLaEmpresa: {
      igualRepresentanteLegal: igualDatos,
      idContactoDeLaEmpresa: modeloPm.contactoDeLaEmpresa.idContactoDeLaEmpresa,
      nombre: modeloPm.contactoDeLaEmpresa.nombre
        ? modeloPm.contactoDeLaEmpresa.nombre
        : "",
      cargo: modeloPm.contactoDeLaEmpresa.cargo
        ? modeloPm.contactoDeLaEmpresa.cargo
        : "",
      telefono: modeloPm.contactoDeLaEmpresa.telefono
        ? modeloPm.contactoDeLaEmpresa.telefono
        : "",
    },
  };
}

function validationSchema() {
  return {
    representanteLegal: Yup.object().shape({
      nombre: Yup.string().required("Dato requerido."),
      apellidoPaterno: Yup.string().required("Dato requerido."),
      cargo: Yup.string().required("Dato requerido."),
      telefono: Yup.string()
        .required("Dato requerido.")
        .test(
          "telefonoContacto",
          "Teléfono capturado de forma incorrecta.",
          (item, testContext) => {
            return validarNumeroTelefono(testContext.parent.telefono);
          }
        ),
    }),
    contactoDeLaEmpresa: Yup.object().shape({
      nombre: Yup.string().required("Dato requerido."),
      cargo: Yup.string().required("Dato requerido."),
      telefono: Yup.string()
        .required("Dato requerido.")
        .test(
          "telefonoContacto",
          "Teléfono capturado de forma incorrecta.",
          (item, testContext) => {
            return validarNumeroTelefono(testContext.parent.telefono);
          }
        ),
    }),
  };
}
