import React from "react";
import ErrorMessage from "../../helpers/ErrorMessage/ErrorMessage";
import InputText from "../input";
import NumberFormat from "react-number-format";
import "../InputWithError/InputWithError.css";

const NumberWithError = ({
  label,
  name,
  value,
  onValueChange = () => {},
  type,
  style,
  thousandSeparator = true,
  prefix = "$",
  decimalScale = 2,
  customInput = InputText,
  allowNegative = false,
  showErrorMessage = false,
  errorMessage = "",
  isAllowed,
}) => {
  return (
    <div className="input__container">
      <NumberFormat
        error={showErrorMessage}
        label={label}
        name={name}
        value={value}
        onValueChange={onValueChange}
        style={style}
        thousandSeparator={thousandSeparator}
        prefix={prefix}
        decimalScale={decimalScale}
        customInput={customInput}
        allowNegative={allowNegative}
        isAllowed={isAllowed}
        type={type}
      />

      {showErrorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default NumberWithError;
