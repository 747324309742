import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../../../helpers/ErrorMessage/ErrorMessage";
import {
  TIPO_COMPROBANTE_INGRESOS_OTRO,
  SECCION_ECONOMICOS,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../../../utils/constantes";
import { animated } from "react-spring";
import DatosBancoPM from "./DatosBancoPM";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import { api_save_datos_economicos_PM } from "../../../../../api/api_servicios_rest";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import SelectWithErrorWithSpan from "../../../../basics/SelectWithError/SelectWithErrorWithSpan";
import NumberWithErrorWithSpan from "../../../../basics/NumberWithError/NumberWithErrorWithSpan";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";
import "./DatosGeneralesEconomicosPM.css";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../../helpers/alertas/alertas";

const DatosGeneralesEconomicosPM = ({
  axiosData,
  modeloPm,
  animatedHeightBoxUnoRef,
  transitionBancoOne,
  refScrollUpBancos,
  transitionBancoTwo,
  animateHeight,
  animatedHeightBoxDosRef,
  transitionBancoThree,
  animatedHeightBoxTresRef,
  cantidadBancos,
  handleAddBancos,
  setModeloPm,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  Swal,
  setLoading,
  webView,
}) => {
  const [validateSubmit, setValidateSubmit] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(modeloPm, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosEconomicosPM(formData).then((response) => setLoading(false));
    },
  });

  const eliminarBancoErrorsArray = (reset) => {
    if (reset) {
      if (cantidadBancos == 3) {
        formik.setErrors({});
        let values = formik?.values?.datosEconomicos?.bancos;
        values.pop();
      } else if (cantidadBancos == 2) {
        formik.setErrors({});
        let values = formik?.values?.datosEconomicos?.bancos;
        values.pop();
      }
    }
  };

  const salvarDatosEconomicosPM = async (formData) => {
    try {
      const response = await api_save_datos_economicos_PM(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPm({
            ...modeloPm,
            idCliente: data.clienteId,
            datosEconomicos: data.datosEconomicos,
          });
          setStyleHr("block");
          handleSeccionPyme(SECCION_ECONOMICOS);
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO == data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al guardar datos económicos PM");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form name="form-economicos" onSubmit={formik.handleSubmit}>
        <div className="container-economicos">
          <h2 className="f-s-3">Datos económicos</h2>
          <p className="f-s-2">
            En esta sección deberás capturar la información bancaria como
            aparece en los estados de cuenta de tu negocio
          </p>
          <FormLayout>
            <div className="economicos--principales-campos-pm">
              <NumberWithErrorWithSpan
                label="Monto de tus ventas anuales *"
                name="datosEconomicos.ventasAnuales"
                value={formik.values?.datosEconomicos?.ventasAnuales}
                onValueChange={(values) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.datosEconomicos !== undefined &&
                    newErrors?.datosEconomicos?.ventasAnuales
                  ) {
                    delete newErrors?.datosEconomicos?.ventasAnuales;
                    formik.setErrors(newErrors);
                  }
                  formik.setFieldValue(
                    "datosEconomicos.ventasAnuales",
                    values?.value
                  );
                }}
                type="text"
                allowNegative={false}
                showErrorMessage={formik.errors?.datosEconomicos?.ventasAnuales}
                errorMessage={formik.errors?.datosEconomicos?.ventasAnuales}
              />
              <SelectWithErrorWithSpan
                label="Tipo de comprobante de ingresos *"
                name="datosEconomicos.tipoDeComprobanteDeIngresosId"
                value={{
                  label:
                    formik?.values?.datosEconomicos
                      ?.tipoDeComprobanteDeIngresosObj[0]?.nombre,
                  value:
                    formik?.values?.datosEconomicos
                      ?.tipoDeComprobanteDeIngresosObj[0]?.nombre,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (Object.entries(newErrors).length !== 0) {
                    if (
                      newErrors?.datosEconomicos !== undefined &&
                      newErrors?.datosEconomicos?.tipoDeComprobanteDeIngresosId
                    ) {
                      delete newErrors?.datosEconomicos
                        ?.tipoDeComprobanteDeIngresosId;
                      formik.setErrors(newErrors);
                    }
                  }
                  formik.setFieldValue(
                    "datosEconomicos.tipoDeComprobanteDeIngresosId",
                    e.id
                  );
                  formik.setFieldValue(
                    "datosEconomicos.tipoDeComprobanteDeIngresosObj",
                    [e]
                  );
                }}
                options={axiosData.catalogosPfaeDatosEconomicos.tipoDeComprobateDeIngresosOpciones.map(
                  (item) => {
                    return {
                      ...item,
                      label: item.nombre,
                      value: item.nombre,
                    };
                  }
                )}
                showErrorMessage={
                  formik.errors?.datosEconomicos?.tipoDeComprobanteDeIngresosId
                }
                errorMessage={
                  formik.errors?.datosEconomicos?.tipoDeComprobanteDeIngresosId
                }
              />
            </div>
            <div className="position-relative">
              <div ref={refScrollUpBancos} />
              {transitionBancoOne(
                (style, visible) =>
                  visible && (
                    <animated.div style={style}>
                      <DatosBancoPM
                        numeroBanco={0}
                        animateHeight={animateHeight}
                        animatedHeightBoxRef={animatedHeightBoxUnoRef}
                        axiosData={axiosData}
                        formik={formik}
                        setValidateSubmit={setValidateSubmit}
                      />
                    </animated.div>
                  )
              )}
              {transitionBancoTwo(
                (style, visible) =>
                  visible && (
                    <animated.div style={style}>
                      <DatosBancoPM
                        numeroBanco={1}
                        animateHeight={animateHeight}
                        animatedHeightBoxRef={animatedHeightBoxDosRef}
                        axiosData={axiosData}
                        formik={formik}
                        setValidateSubmit={setValidateSubmit}
                      />
                    </animated.div>
                  )
              )}
              {transitionBancoThree(
                (style, visible) =>
                  visible && (
                    <animated.div style={style}>
                      <DatosBancoPM
                        numeroBanco={2}
                        animateHeight={animateHeight}
                        animatedHeightBoxRef={animatedHeightBoxTresRef}
                        axiosData={axiosData}
                        formik={formik}
                        setValidateSubmit={setValidateSubmit}
                      />
                    </animated.div>
                  )
              )}
            </div>
          </FormLayout>
        </div>
        <div className="container d-flex justify-content-center">
          <div className="mb-2 d-flex gap-2">
            {cantidadBancos >= 2 && (
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleAddBancos(false);
                  eliminarBancoErrorsArray(true);
                }}
              >
                Eliminar Banco
              </SecondaryButton>
            )}
            {cantidadBancos <= 2 && (
              <PrimaryButton onClick={() => handleAddBancos(true)}>
                Agregar Banco
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center gap-2">
          <SecondaryButton
            onClick={(e) => {
              e.preventDefault();
              handleRegresarSeccionPyme(SECCION_ECONOMICOS);
            }}
          >
            Atrás
          </SecondaryButton>
          <PrimaryButton type="submit">Siguiente</PrimaryButton>
        </div>
      </form>
    </>
  );
};

export default DatosGeneralesEconomicosPM;

function initialValues(modeloPm, axiosData, resetFormik) {
  let bancos = modeloPm.datosEconomicos.bancos;

  if (bancos == null || bancos.length == undefined || bancos.length == 0) {
    return {
      idCliente: modeloPm.idCliente,
      datosEconomicos: {
        idDatosEconomicosDelNegocio:
          modeloPm.datosEconomicos.idDatosEconomicosDelNegocio,
        fuenteIngresos: modeloPm.datosEconomicos.fuenteIngresos
          ? modeloPm.datosEconomicos.fuenteIngresos
          : "No aplica",
        ventasAnuales: modeloPm.datosEconomicos.ventasAnuales
          ? modeloPm.datosEconomicos.ventasAnuales
          : "",
        tipoDeComprobanteDeIngresosId: modeloPm.datosEconomicos
          .tipoDeComprobanteDeIngresosId
          ? modeloPm.datosEconomicos.tipoDeComprobanteDeIngresosId
          : "",
        tipoDeComprobanteDeIngresosObj: modeloPm.datosEconomicos
          .tipoDeComprobanteDeIngresosId
          ? axiosData?.catalogosPfaeDatosEconomicos?.tipoDeComprobateDeIngresosOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.datosEconomicos?.tipoDeComprobanteDeIngresosId
            )
          : [{ nombre: "" }],
        otroComprobante: modeloPm.datosEconomicos.otroComprobante
          ? modeloPm.datosEconomicos.otroComprobante
          : "",
        bancos: [
          {
            numeroBanco: 0,
            bancoId: "",
            bancoMes: [
              {
                numeroMes: 0,
                saldoPromedioCuentaCheques: "",
                saldoInicial: "",
                totalRetiros: "",
                totalDepositos: "",
              },
            ],
          },
        ],
      },
    };
  } else {
    return {
      idCliente: modeloPm.idCliente,
      datosEconomicos: {
        idDatosEconomicosDelNegocio:
          modeloPm.datosEconomicos.idDatosEconomicosDelNegocio,
        fuenteIngresos: modeloPm.datosEconomicos.fuenteIngresos,
        ventasAnuales: modeloPm.datosEconomicos.ventasAnuales,
        tipoDeComprobanteDeIngresosId:
          modeloPm.datosEconomicos.tipoDeComprobanteDeIngresosId,
        tipoDeComprobanteDeIngresosObj: modeloPm.datosEconomicos
          .tipoDeComprobanteDeIngresosId
          ? axiosData?.catalogosPfaeDatosEconomicos?.tipoDeComprobateDeIngresosOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.datosEconomicos?.tipoDeComprobanteDeIngresosId
            )
          : [{ nombre: "" }],
        otroComprobante: modeloPm.datosEconomicos.otroComprobante
          ? modeloPm.datosEconomicos.otroComprobante
          : "",
        bancos: modeloPm.datosEconomicos.bancos,
      },
    };
  }
}

function validationSchema() {
  return {
    datosEconomicos: Yup.object().shape({
      ventasAnuales: Yup.number().required("Dato requerido."),
      tipoDeComprobanteDeIngresosId: Yup.number().required("Dato requerido."),
      otroComprobante: Yup.string().when("tipoDeComprobanteDeIngresosId", {
        is: TIPO_COMPROBANTE_INGRESOS_OTRO,
        then: Yup.string().required("Dato requerido."),
      }),
      bancos: Yup.array().of(
        Yup.object().shape({
          numeroBanco: Yup.number().required("Dato requerido."),
          bancoId: Yup.number().required("Dato requerido."),
          bancoMes: Yup.array().of(
            Yup.object().shape({
              numeroMes: Yup.number().required("Dato requerido."),
              saldoPromedioCuentaCheques:
                Yup.number().required("Dato requerido."),
              saldoInicial: Yup.number().required("Dato requerido."),
              totalRetiros: Yup.number().required("Dato requerido."),
              totalDepositos: Yup.number().required("Dato requerido."),
            })
          ),
        })
      ),
    }),
  };
}
