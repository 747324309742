import React from "react";
import {
  SECCION_GENERALES,
  SECCION_OBLIGADO_SOLIDARIO,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  SECCION_CONYUGE_OS_UNO,
  SECCION_CONYUGE_OS_DOS,
  SECCION_CONYUGE_OS_TRES,
  SECCION_ECONOMICOS,
  SECCION_SOCIOS,
  SECCION_OS_DOS,
  SECCION_OS_TRES,
  SECCION_OS_CUATRO,
  OS_MAX_PM,
  SECCION_REPRESENTANTE_LEGAL,
  SECCION_INFORMACION_NEGOCIO,
  SECCION_DOMICILIO_COMERCIAL_PM,
  SECCION_DOMICILIO_NEGOCIO,
  SECCION_PRINCIPAL_ACCIONISTA,
  SECCION_PA_CONYUGE_PM,
} from "../../../../utils/constantes";
import {
  DisabledButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../basics/Buttons/Buttons";

const FormMenuPM = ({
  tipoPersona,
  subMenuSelected,
  seccion,
  setSeccion,
  cantidadObligadosSolidarios,
  datosOSUno,
  datosOSDos,
  datosOSTres,
  setSubMenuSelected,
  eliminarOSPm,
  cantidadConyugeOS,
  setCantidadObligadosSolidarios,
  mostrarSeccionPm,
  disabledBtnAgregarOs,
  setDisabledBtnAgregarOs,
}) => {
  return (
    <>
      {tipoPersona === "PM" && subMenuSelected === "generales" && (
        <>
          <span
            style={{ padding: "8px 16px" }}
            className={`${
              seccion === SECCION_GENERALES &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => setSeccion(SECCION_GENERALES)}
          >
            General
          </span>
          <span
            style={{ padding: "8px 16px" }}
            className={`${
              seccion === SECCION_REPRESENTANTE_LEGAL &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPm.representanteLegal) {
                setSeccion(SECCION_REPRESENTANTE_LEGAL);
              }
            }}
          >
            Representante legal
          </span>
          <span
            style={{ padding: "8px 16px" }}
            className={`${
              seccion === SECCION_DOMICILIO_NEGOCIO &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPm.domicilioNegocio) {
                setSeccion(SECCION_DOMICILIO_NEGOCIO);
              }
            }}
          >
            Domicilio del negocio
          </span>
          <span
            style={{ padding: "8px 16px" }}
            className={`${
              seccion === SECCION_DOMICILIO_COMERCIAL_PM &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPm.domicilioComercial) {
                setSeccion(SECCION_DOMICILIO_COMERCIAL_PM);
              }
            }}
          >
            Domicilio comercial
          </span>
          <span
            style={{ padding: "8px 16px" }}
            className={`${
              seccion === SECCION_INFORMACION_NEGOCIO &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPm.informacionNegocio) {
                setSeccion(SECCION_INFORMACION_NEGOCIO);
              }
            }}
          >
            Info. del negocio
          </span>
          <span
            style={{ padding: "8px 16px" }}
            className={`${
              seccion === SECCION_ECONOMICOS &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPm.economicos) {
                setSeccion(SECCION_ECONOMICOS);
              }
            }}
          >
            Económicos
          </span>
          <span
            style={{ padding: "8px 16px" }}
            className={`${
              seccion === SECCION_SOCIOS &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPm.socios) {
                setSeccion(SECCION_SOCIOS);
              }
            }}
          >
            Socios
          </span>
        </>
      )}

      {tipoPersona === "PM" && subMenuSelected === "oSolidario" && (
        <>
          <span
            className={`${
              seccion === SECCION_PRINCIPAL_ACCIONISTA &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
              setSubMenuSelected("oSolidario");
            }}
          >
            Principal accionista
          </span>
          {mostrarSeccionPm.principalAccionistaConyuge && (
            <span
              className={`${
                seccion === SECCION_PA_CONYUGE_PM &&
                "radius bold tab__selected text__red"
              } pointer tab`}
              onClick={() => {
                setSeccion(SECCION_PA_CONYUGE_PM);
                setSubMenuSelected("oSolidario");
              }}
            >
              Principal accionista cónyuge
            </span>
          )}
          {cantidadObligadosSolidarios === 1 && (
            <>
              <span
                className={`${
                  seccion === SECCION_OBLIGADO_SOLIDARIO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => {
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                  setSubMenuSelected("oSolidario");
                }}
              >
                Obligado solidario 1
              </span>
              {datosOSUno.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              (datosOSUno?.conyuge?.idConyuge !== null || datosOSUno?.showConyugeOS === true) && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_UNO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_UNO)}
                  >
                    Obligado solidario 1 cónyuge
                  </span>
                )}
            </>
          )}
          {cantidadObligadosSolidarios === 2 && (
            <>
              <span
                className={`${
                  seccion === SECCION_OBLIGADO_SOLIDARIO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => {
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                  setSubMenuSelected("oSolidario");
                }}
              >
                Obligado solidario 1
              </span>
              {datosOSUno.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSUno?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_UNO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_UNO)}
                  >
                    Obligado solidario 1 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_DOS &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_DOS)}
              >
                Obligado solidario 2
              </span>
              {datosOSDos.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              (datosOSDos?.conyuge?.idConyuge !== null || datosOSDos?.showConyugeOS === true) && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_DOS &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_DOS)}
                  >
                    Obligado solidario 2 cónyuge
                  </span>
                )}
            </>
          )}
          {cantidadObligadosSolidarios === 3 && (
            <>
              <span
                className={`${
                  seccion === SECCION_OBLIGADO_SOLIDARIO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => {
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                  setSubMenuSelected("oSolidario");
                }}
              >
                Obligado solidario 1
              </span>
              {datosOSUno.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSUno?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_UNO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_UNO)}
                  >
                    Obligado solidario 1 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_DOS &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_DOS)}
              >
                Obligado solidario 2
              </span>
              {datosOSDos.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSDos?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_DOS &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_DOS)}
                  >
                    Obligado solidario 2 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_TRES &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_TRES)}
              >
                Obligado solidario 3
              </span>
              {datosOSTres.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              (datosOSTres?.conyuge?.idConyuge !== null || datosOSTres?.showConyugeOS === true) && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_TRES &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_TRES)}
                  >
                    Obligado solidario 3 cónyuge
                  </span>
                )}
            </>
          )}

          {cantidadObligadosSolidarios === 4 && (
            <>
              <span
                className={`${
                  seccion === SECCION_OBLIGADO_SOLIDARIO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => {
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                  setSubMenuSelected("oSolidario");
                }}
              >
                Obligado solidario 1
              </span>
              {datosOSUno.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSUno?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_UNO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_UNO)}
                  >
                    Obligado solidario 1 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_DOS &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_DOS)}
              >
                Obligado solidario 2
              </span>
              {datosOSDos.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSDos?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_DOS &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_DOS)}
                  >
                    Obligado solidario 2 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_TRES &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_TRES)}
              >
                Obligado solidario 3
              </span>
              {datosOSTres.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSTres?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_TRES &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_TRES)}
                  >
                    Obligado solidario 3 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_CUATRO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_CUATRO)}
              >
                Obligado solidario 4
              </span>
            </>
          )}

          {cantidadObligadosSolidarios > 0 && (
            <span
              className="pointer-btn tab"
              onClick={() => {
                eliminarOSPm(cantidadObligadosSolidarios);
                setDisabledBtnAgregarOs(false);
              }}
            >
              Eliminar obligado solidario
            </span>
          )}

          {cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                marginBottom: "12px",
              }}
            >
              {!disabledBtnAgregarOs ? (
                <span
                  className="text__red pointer-btn tab"
                  onClick={(e) => {
                    e.preventDefault();
                    setCantidadObligadosSolidarios(
                      cantidadObligadosSolidarios + 1
                    );
                    setDisabledBtnAgregarOs(true);
                  }}
                >
                  Agregar obligado solidario
                </span>
              ) : (
                <span className="text__red pointer tab">Agregar obligado solidario</span>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FormMenuPM;
