/**
 * Función que se cambia un texto a mayusculas
 * @param {*} value Valor a convertir
 * @return Valor convertido a mayusculas
 */
export const textToUpperCase = (value) => {
  return value.toUpperCase();
};

/**
 * Función que verifica si un texto contiene solamente letras
 * @param {*} value Valor a verificar
 * @return True o false
 */
export const onlyText = (value) => {
  const regex = /^[a-zA-Z\s]*$/g;

  if (value.match(regex)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Función que verifica si un texto contiene solamente letras incluyendo Ññ
 * @param {*} value Valor a verificar
 * @return True o false
 */
 export const onlyTextAndEnie = (value) => {
  const regex = /^[a-zA-ZÑñ\s]*$/g;

  if (value.match(regex)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Función que verifica si un texto contiene solamente numeros
 * @param {*} value Valor a verificar
 * @return True o false
 */
export const onlyNumbers = (value) => {
  var regex = /^\d*$/;

  if (value.match(regex)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Función que valida datos sobre la fecha
 * @param {*} date Fecha a verificar
 * @return True o false
 */
export const validDate = (date) => {
  let isDateValid;

  const today = new Date();
  const dateSelected = new Date(date);

  //Validamos que la fecha no sea mayor al dia actual
  if (dateSelected > today) {
    isDateValid = false;
  } else {
    isDateValid = true;
  }

  //Verificamos que la persona tenga mas de 18 anios
  const diffTime = Math.abs(today - dateSelected);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  //6570 dias son 18 anios. Debe tener mas de 6570
  if (diffDays < 6570) {
    isDateValid = false;
  }

  return isDateValid;
};

/**
 * Función que valida un RFC de una PM
 * @param {*} value Valor a verificar
 * @return True o false
 */
export const isPmRFCValid = (value) => {
  let regex =
    /^([A-ZÑ\x26]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/;
  return regex.test(value);
};

/**
 * Función que valida un RFC de una PFAE
 * @param {*} value Valor a verificar
 * @return True o false
 */
export const isPfaeRFCValid = (value) => {
  let regex =
    /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$/;
  return regex.test(value);
};

/**
 * Función que valida si un string contiene caracteres especiales
 * @param {*} value Valor a verificar
 * @return True o false
 */
export const notSpecialCaracters = (value) => {
  let regex = /^[A-Za-z0-9\s]*$/;
  return regex.test(value);
};

export const letrasNumerosyPunto = (value) => {
  let regex = /^[A-Za-z0-9Ññ\s.]*$/;
  return regex.test(value);
};

export const letrasNumerosAndEnie = (value) => {
  let regex = /^[A-Za-z0-9Ññ\s]*$/;
  return regex.test(value);
};

/**
 * Función que valida si un CUPR es valido
 * @param {*} curp Valor a verificar
 * @return True o false
 */
export const isCurpValid = (curp) => {
  const curpRegExp =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
  return curpRegExp.test(curp);
};

/**
 * Función que valida si el valor esta vacio
 * @param {*} value Valor a verificar
 * @return True o false
 */
export const isEmpty = (value) => {
  try {
    if (typeof value === "string") {
      return (
        value === null ||
        value === undefined ||
        value.length === 0 ||
        (typeof value === "string" && value.trim() === 0)
      );
    } else if (
      typeof value === "object" &&
      value !== null &&
      value !== undefined &&
      !(value instanceof File)
    ) {
      if (Array.isArray(value)) return value.length === 0;
      else return Object.keys(value).length === 0;
    } else {
      return value === null || value === undefined;
    }
  } catch (error) {
    return value === null || value === undefined;
  }
};

/**
 * Función que valida un valor segun el tipo de campo.
 * @param {*} value Valor a verificar
 * @param {*} type Tipo de campo a verificar
 * @return True o false
 */
export const validateValue = (value, type) => {
  let isValid = false;

  if (type === "text") {
    if (notSpecialCaracters(value) && onlyText(value)) {
      isValid = true;
    }
  }

  if (type === "alphanumeric") {
    if (notSpecialCaracters(value)) {
      isValid = true;
    }
  }

  if (type === "number") {
    if (onlyNumbers(value)) {
      isValid = true;
    }
  }

  return isValid;
};

export const validarTextoConPunto = (value, type) => {
  let isValid = false;
  if (type === "alphanumeric") {
    if (letrasNumerosyPunto(value)) {
      isValid = true;
    }
  }
  return isValid;
};


export const validarTextoConEnies = (value, type) => {
  let isValid = false;
  if (type === "text") {
    if (letrasNumerosAndEnie(value) && onlyTextAndEnie(value)) {
      isValid = true;
    }
  }
  if (type === "alphanumeric") {
    if (letrasNumerosAndEnie(value)) {
      isValid = true;
    }
  }
  return isValid;
};

export const notSpecialCaractersConGuion = (value) => {
  let regex = /^[A-Za-z0-9-\s]*$/;
  return regex.test(value);
};