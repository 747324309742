import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import {
  SECCION_ECONOMICOS,
  TIPO_COMPROBANTE_INGRESOS_OTRO,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../../../utils/constantes";
import { animated } from "react-spring";
import DatosBancoPFAE from "./DatosBancoPFAE";
import { api_save_datos_economicos_PFAE } from "../../../../../api/api_servicios_rest";
import "./DatosEconomicosPFAE.css";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import SelectWithErrorWithSpan from "../../../../basics/SelectWithError/SelectWithErrorWithSpan";
import NumberWithErrorWithSpan from "../../../../basics/NumberWithError/NumberWithErrorWithSpan";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../../helpers/alertas/alertas";
import Swal from "sweetalert2";

const DatosEconomicosPFAE = ({
  axiosData,
  modeloPfae,
  transitionBancoOne,
  transitionBancoTwo,
  transitionBancoThree,
  refScrollUpBancos,
  animateHeight,
  animatedHeightBoxUnoRef,
  animatedHeightBoxDosRef,
  animatedHeightBoxTresRef,
  cantidadBancos,
  handleAddBancos,
  setModeloPfae,
  setStyleHr,
  setLoading,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setConyugeOS,
  setCantidadObligadosSolidarios,
  cantidadObligadosSolidarios,
  webView,
}) => {
  const formik = useFormik({
    initialValues: initialValues(modeloPfae, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosEconomicosPFAE(formData).then((r) => setLoading(false));
    },
  });

  const salvarDatosEconomicosPFAE = async (formData) => {
    try {
      const response = await api_save_datos_economicos_PFAE(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPfae({
            ...modeloPfae,
            idCliente: data.clienteId,
            datosEconomicos: data.datosEconomicos,
          });
          setStyleHr("block");
          if (
            parseInt(modeloPfae.generales.estadoCivilId) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
            modeloPfae?.conyuge?.idConyuge === null
          ) {
            Swal.fire({
              title: "¿El cónyuge participa como un Obligado solidario?",
              showDenyButton: true,
              confirmButtonText: "Si",
              denyButtonText: "No",
              confirmButtonColor: "#dc3545",
              denyButtonColor: "#adb5bd",
              showCloseButton: true,
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                setConyugeOS(true);
                modeloPfae.conyuge.generales.esObligadoSolidario = true;
                handleSeccionPyme(SECCION_ECONOMICOS);
              } else if (result.isDenied) {
                setConyugeOS(false);
                handleSeccionPyme(SECCION_ECONOMICOS);
              }
            });
          } else {
            handleSeccionPyme(SECCION_ECONOMICOS);
            if (cantidadObligadosSolidarios === 0) {
              setCantidadObligadosSolidarios(1);
            }
          }
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al guardar datos económicos PFAE");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const eliminarBancoErrorsArray = (reset) => {
    if (reset) {
      if (cantidadBancos === 3) {
        formik.setErrors({});
        let values = formik?.values?.datosEconomicos?.bancos;
        values.pop();
      } else if (cantidadBancos === 2) {
        formik.setErrors({});
        let values = formik?.values?.datosEconomicos?.bancos;
        values.pop();
      }
    }
  };

  return (
    <>
      <form
        id="form-datosEconomico-pfae"
        name="form-economicos"
        onSubmit={formik.handleSubmit}
      >
        <div className="">
          <h2 className="f-s-3">Datos económicos</h2>
          <p className="p--texto_justify">
            En esta sección deberás capturar la información bancaria como
            aparece en tus estados de cuenta donde recibes los ingresos de tu
            negocio
          </p>
          <FormLayout>
            <div className="economicos--principales-campos-pm">
              <NumberWithErrorWithSpan
                label="Monto de tus ventas anuales *"
                name="datosEconomicos.ventasAnuales"
                value={formik.values?.datosEconomicos?.ventasAnuales}
                onValueChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.datosEconomicos !== undefined &&
                    newErrors?.datosEconomicos?.ventasAnuales
                  ) {
                    delete newErrors?.datosEconomicos?.ventasAnuales;
                    formik.setErrors(newErrors);
                  }
                  formik.setFieldValue(
                    "datosEconomicos.ventasAnuales",
                    e.value
                  );
                }}
                showErrorMessage={formik.errors?.datosEconomicos?.ventasAnuales}
                errorMessage={formik.errors?.datosEconomicos?.ventasAnuales}
              />
              <SelectWithErrorWithSpan
                label="Tipo de comprobante de ingresos *"
                name="datosEconomicos.tipoDeComprobanteDeIngresosId"
                value={{
                  label:
                    formik?.values?.datosEconomicos
                      ?.tipoDeComprobanteDeIngresosObj[0]?.nombre,
                  value:
                    formik?.values?.datosEconomicos
                      ?.tipoDeComprobanteDeIngresosObj[0]?.nombre,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.datosEconomicos !== undefined &&
                    newErrors?.datosEconomicos?.tipoDeComprobanteDeIngresosId
                  ) {
                    delete newErrors?.datosEconomicos
                      ?.tipoDeComprobanteDeIngresosId;
                    formik.setErrors(newErrors);
                  }
                  formik.setFieldValue(
                    "datosEconomicos.tipoDeComprobanteDeIngresosId",
                    e.id
                  );
                  formik.setFieldValue(
                    "datosEconomicos.tipoDeComprobanteDeIngresosObj",
                    [e]
                  );
                }}
                options={axiosData?.catalogosPfaeDatosEconomicos?.tipoDeComprobateDeIngresosOpciones?.map(
                  (item) => {
                    return { ...item, label: item.nombre, value: item.nombre };
                  }
                )}
                showErrorMessage={
                  formik.errors?.datosEconomicos?.tipoDeComprobanteDeIngresosId
                }
                errorMessage={
                  formik.errors?.datosEconomicos?.tipoDeComprobanteDeIngresosId
                }
              />
            </div>
            <div style={{ position: "relative" }}>
              <div ref={refScrollUpBancos} />
              {transitionBancoOne(
                (style, visible) =>
                  visible && (
                    <animated.div style={style}>
                      <DatosBancoPFAE
                        numeroBanco={0}
                        animateHeight={animateHeight}
                        animatedHeightBoxRef={animatedHeightBoxUnoRef}
                        axiosData={axiosData}
                        formik={formik}
                      />
                    </animated.div>
                  )
              )}
              {transitionBancoTwo(
                (style, visible) =>
                  visible && (
                    <animated.div style={style}>
                      <DatosBancoPFAE
                        numeroBanco={1}
                        animateHeight={animateHeight}
                        animatedHeightBoxRef={animatedHeightBoxDosRef}
                        axiosData={axiosData}
                        formik={formik}
                      />
                    </animated.div>
                  )
              )}
              {transitionBancoThree(
                (style, visible) =>
                  visible && (
                    <animated.div style={style}>
                      <DatosBancoPFAE
                        numeroBanco={2}
                        animateHeight={animateHeight}
                        animatedHeightBoxRef={animatedHeightBoxTresRef}
                        axiosData={axiosData}
                        formik={formik}
                      />
                    </animated.div>
                  )
              )}
            </div>
          </FormLayout>
        </div>
        <div className="container d-flex justify-content-center align-items-center">
          <div className="row">
            <div className="d-flex gap-3 mb-4">
              {cantidadBancos >= 2 && (
                <SecondaryButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddBancos(false);
                    eliminarBancoErrorsArray(true);
                  }}
                >
                  Eliminar Banco
                </SecondaryButton>
              )}
              {cantidadBancos <= 2 && (
                <PrimaryButton
                  onClick={() => handleAddBancos(true)}
                  type="button"
                >
                  Agregar Banco
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center gap-3 mb-3">
          <SecondaryButton
            onClick={(e) => {
              e.preventDefault();
              handleRegresarSeccionPyme(SECCION_ECONOMICOS);
            }}
          >
            Atrás
          </SecondaryButton>
          <PrimaryButton type="submit">Siguiente</PrimaryButton>
        </div>
      </form>
    </>
  );
};

export default DatosEconomicosPFAE;

function initialValues(modeloPfae, axiosData) {
  let bancos = modeloPfae.datosEconomicos.bancos;
  if (bancos == null || bancos.length == undefined || bancos.length == 0) {
    return {
      idCliente: modeloPfae.idCliente,
      datosEconomicos: {
        idDatosEconomicosDelNegocio:
          modeloPfae.datosEconomicos.idDatosEconomicosDelNegocio,
        fuenteIngresos: modeloPfae.datosEconomicos.fuenteIngresos
          ? modeloPfae.datosEconomicos.fuenteIngresos
          : "No aplica",
        ventasAnuales: modeloPfae.datosEconomicos.ventasAnuales
          ? modeloPfae.datosEconomicos.ventasAnuales
          : "",
        tipoDeComprobanteDeIngresosId: modeloPfae.datosEconomicos
          .tipoDeComprobanteDeIngresosId
          ? modeloPfae.datosEconomicos.tipoDeComprobanteDeIngresosId
          : "",
        tipoDeComprobanteDeIngresosObj: modeloPfae.datosEconomicos
          .tipoDeComprobanteDeIngresosId
          ? axiosData?.catalogosPfaeDatosEconomicos?.tipoDeComprobateDeIngresosOpciones?.filter(
              (e) =>
                e.id ===
                modeloPfae?.datosEconomicos?.tipoDeComprobanteDeIngresosId
            )
          : [{ nombre: "" }],
        otroComprobante: modeloPfae.datosEconomicos.otroComprobante
          ? modeloPfae.datosEconomicos.otroComprobante
          : "",
        bancos: [
          {
            numeroBanco: 0,
            bancoId: "",
            bancoMes: [
              {
                numeroMes: 0,
                saldoPromedioCuentaCheques: "",
                saldoInicial: "",
                totalRetiros: "",
                totalDepositos: "",
              },
            ],
          },
        ],
      },
    };
  } else {
    return {
      idCliente: modeloPfae.idCliente,
      datosEconomicos: {
        idDatosEconomicosDelNegocio:
          modeloPfae.datosEconomicos.idDatosEconomicosDelNegocio,
        fuenteIngresos: modeloPfae.datosEconomicos.fuenteIngresos,
        ventasAnuales: modeloPfae.datosEconomicos.ventasAnuales,
        tipoDeComprobanteDeIngresosId:
          modeloPfae.datosEconomicos.tipoDeComprobanteDeIngresosId,
        tipoDeComprobanteDeIngresosObj: modeloPfae.datosEconomicos
          .tipoDeComprobanteDeIngresosId
          ? axiosData?.catalogosPfaeDatosEconomicos?.tipoDeComprobateDeIngresosOpciones?.filter(
              (e) =>
                e.id ===
                modeloPfae?.datosEconomicos?.tipoDeComprobanteDeIngresosId
            )
          : [{ nombre: "" }],
        otroComprobante: modeloPfae.datosEconomicos.otroComprobante
          ? modeloPfae.datosEconomicos.otroComprobante
          : "",
        bancos: modeloPfae.datosEconomicos.bancos,
      },
    };
  }
}

function validationSchema() {
  return {
    datosEconomicos: Yup.object().shape({
      ventasAnuales: Yup.number().required("Dato requerido."),
      tipoDeComprobanteDeIngresosId: Yup.number().required("Dato requerido."),
      otroComprobante: Yup.string().when("tipoDeComprobanteDeIngresosId", {
        is: TIPO_COMPROBANTE_INGRESOS_OTRO,
        then: Yup.string().required("Dato requerido."),
      }),
      bancos: Yup.array().of(
        Yup.object().shape({
          numeroBanco: Yup.number().required("Dato requerido."),
          bancoId: Yup.number().required("Dato requerido."),
          bancoMes: Yup.array().of(
            Yup.object().shape({
              numeroMes: Yup.number().required("Dato requerido."),
              saldoPromedioCuentaCheques:
                Yup.number().required("Dato requerido."),
              saldoInicial: Yup.number().required("Dato requerido."),
              totalRetiros: Yup.number().required("Dato requerido."),
              totalDepositos: Yup.number().required("Dato requerido."),
            })
          ),
        })
      ),
    }),
  };
}
