import {
  ID_CALCULO_RFC_APM,
  ID_CALCULO_RFC_APP,
  ID_CALCULO_RFC_ESTADONAC,
  ID_CALCULO_RFC_FECHANAC,
  ID_CALCULO_RFC_GENERO,
  ID_CALCULO_RFC_NOMBRE,
} from "../../../../../../utils/constantes";

export const generarRfcCurpSC = (
  idValorCalculoRFC,
  valueCalculoRFC,
  formik
) => {
  const objRfcCurp = {
    nombre: "",
    apellidoMaterno: "",
    apellidoPaterno: "",
    fechaNacimiento: "",
    generoId: 0,
    estadoNacimientoId: 0,
  };

  switch (idValorCalculoRFC) {
    case ID_CALCULO_RFC_NOMBRE:
      objRfcCurp.nombre = valueCalculoRFC;
      objRfcCurp.apellidoPaterno =
        formik.values?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId = formik.values?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APP:
      objRfcCurp.nombre = formik.values?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno = valueCalculoRFC;
      objRfcCurp.apellidoMaterno =
        formik.values?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId = formik.values?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APM:
      objRfcCurp.nombre = formik.values?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno = valueCalculoRFC;
      objRfcCurp.fechaNacimiento =
        formik.values?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId = formik.values?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_FECHANAC:
      objRfcCurp.nombre = formik.values?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento = valueCalculoRFC;
      objRfcCurp.generoId = formik.values?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_GENERO:
      objRfcCurp.nombre = formik.values?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId = valueCalculoRFC;
      objRfcCurp.estadoNacimientoId =
        formik.values?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_ESTADONAC:
      objRfcCurp.nombre = formik.values?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId = formik.values?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId = valueCalculoRFC;
      break;

    default:
      break;
  }

  return objRfcCurp;
};

export const generarRfcCurpOSPF_PFAE = (
  idValorCalculoRFC,
  valueCalculoRFC,
  formik
) => {
  const objRfcCurp = {
    nombre: "",
    apellidoMaterno: "",
    apellidoPaterno: "",
    fechaNacimiento: "",
    generoId: 0,
    estadoNacimientoId: 0,
  };

  switch (idValorCalculoRFC) {
    case ID_CALCULO_RFC_NOMBRE:
      objRfcCurp.nombre = valueCalculoRFC;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APP:
      objRfcCurp.nombre = formik.values?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno = valueCalculoRFC;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APM:
      objRfcCurp.nombre = formik.values?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno = valueCalculoRFC;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_FECHANAC:
      objRfcCurp.nombre = formik.values?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento = valueCalculoRFC;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_GENERO:
      objRfcCurp.nombre = formik.values?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId = valueCalculoRFC;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_ESTADONAC:
      objRfcCurp.nombre = formik.values?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId = valueCalculoRFC;
      break;

    default:
      break;
  }

  return objRfcCurp;
};

export const generarRfcCurpOSC = (
  idValorCalculoRFC,
  valueCalculoRFC,
  formik
) => {
  const objRfcCurp = {
    nombre: "",
    apellidoMaterno: "",
    apellidoPaterno: "",
    fechaNacimiento: "",
    generoId: 0,
    estadoNacimientoId: 0,
  };

  switch (idValorCalculoRFC) {
    case ID_CALCULO_RFC_NOMBRE:
      objRfcCurp.nombre = valueCalculoRFC;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APP:
      objRfcCurp.nombre =
        formik.values?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno = valueCalculoRFC;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APM:
      objRfcCurp.nombre =
        formik.values?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno = valueCalculoRFC;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_FECHANAC:
      objRfcCurp.nombre =
        formik.values?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento = valueCalculoRFC;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_GENERO:
      objRfcCurp.nombre =
        formik.values?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId = valueCalculoRFC;
      objRfcCurp.estadoNacimientoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_ESTADONAC:
      objRfcCurp.nombre =
        formik.values?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId = valueCalculoRFC;
      break;

    default:
      break;
  }

  return objRfcCurp;
};
