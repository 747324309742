import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  api_save_datos_domicilio_comercial_PM,
  api_direccion_by_cp,
} from "../../../../api/api_servicios_rest_crediactivo";
import {
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
} from "../../../../utils/validaciones";
import {
  SECCION_DOMICILIO_COMERCIAL_PM,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../../utils/constantes";
import {
  validateValue,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../functions/fieldFunctions";
import FormLayout from "../../../helpers/FormLayout/FormLayout";
import SelectWithError from "../../../basics/SelectWithError/SelectWithError";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../basics/Buttons/Buttons";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import SelectWithErrorRepresentanteLegal from "../../../basics/SelectWithError/SelectWithErrorRepresentanteLegal";
import {
  sweetAlertError,
  sweetAlertWithConfirmedCrediactivo,
} from "../alertas/alertas";

const DatosGeneralesDomicilioComercialPM = ({
  modeloPm,
  setModeloPm,
  axiosData,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  anios,
  domicilioFiscalIgual,
  setDomicilioFiscalIgual,
  setLoading,
}) => {
  const [coloniasCP, setColoniasCP] = useState([]);

  const formik = useFormik({
    initialValues: initialValues(modeloPm, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosDomicilioComercialPM(formData);
    },
  });

  const setearVacioDatosDomicilioComercial = () => {
    modeloPm.domicilioComercial.calle = "";
    modeloPm.domicilioComercial.numeroExterior = "";
    modeloPm.domicilioComercial.numeroInterior = "";
    modeloPm.domicilioComercial.codigoPostal = "";
    modeloPm.domicilioComercial.colonia = "";
    modeloPm.domicilioComercial.municipioId = "";
    modeloPm.domicilioComercial.estadoId = "";
  };

  const obtenerDireccionByCp = async (codigoPostal) => {
    try {
      if (codigoPostal) {
        const response = await api_direccion_by_cp(codigoPostal);
        if (response.statusText === "OK") {
          if (response.data.municipio !== undefined) {
            setColoniasCP(response.data.asentamientos);
            formik.setFieldValue(
              "domicilioComercial.municipioId",
              response.data.municipio.id
            );
            formik.setFieldValue(
              "domicilioComercial.municipioObj",
              axiosData?.generales?.municipios?.filter(
                (e) => e.id === response.data.municipio.id
              )
            );
            formik.setFieldValue(
              "domicilioComercial.estadoId",
              response.data.estado.id
            );
            formik.setFieldValue(
              "domicilioComercial.estadoObj",
              axiosData?.generales?.estados?.filter(
                (e) => e.id === response.data.estado.id
              )
            );
            formik.setFieldValue(
              "domicilioComercial.colonia",
              response.data.asentamientos[0]
            );
          } else {
            limpiarDireccionByCp();
          }
        } else {
          sweetAlertError(
            "Ocurrió un error al obtener dirección por código postal"
          );
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue("domicilioComercial.municipioId", "");
    formik.setFieldValue("domicilioComercial.municipioObj", "");
    formik.setFieldValue("domicilioComercial.estadoId", "");
    formik.setFieldValue("domicilioComercial.estadoObj", "");
    formik.setFieldValue("domicilioComercial.colonia", "");
  };

  const handleChangeDomicilioFiscal = (event) => {
    if (event.target.value !== undefined) {
      let valor = JSON.parse(event.target.value);
      setDomicilioFiscalIgual(valor);
      if (valor) {
        setearDatosDomicilioComercialSolictudPM();
      } else {
        setearVacioDatosDomicilioComercial();
      }

      formik.setFieldValue("domicilioComercial.igualDomicilioFiscal", valor);
    }
  };

  const setearDatosDomicilioComercialSolictudPM = () => {
    if (modeloPm.direccion.codigoPostal !== null) {
      obtenerDireccionByCp(modeloPm.direccion.codigoPostal);
    } else {
      const colonias = [modeloPm.direccion.colonia];
      setColoniasCP(colonias);

      formik.setFieldValue(
        "domicilioComercial.municipioObj",
        axiosData?.generales?.municipios?.filter(
          (e) => e.id === modeloPm.direccion.municipioId
        )
      );
      formik.setFieldValue(
        "domicilioComercial.estadoObj",
        axiosData?.generales?.estados?.filter(
          (e) => e.id === modeloPm.direccion.estadoId
        )
      );
    }

    formik.setFieldValue("domicilioComercial.calle", modeloPm.direccion.calle);
    formik.setFieldValue(
      "domicilioComercial.numeroExterior",
      modeloPm.direccion.numeroExterior
    );
    formik.setFieldValue(
      "domicilioComercial.numeroInterior",
      modeloPm.direccion.numeroInterior
    );
    formik.setFieldValue(
      "domicilioComercial.codigoPostal",
      modeloPm.direccion.codigoPostal
    );
    formik.setFieldValue(
      "domicilioComercial.colonia",
      modeloPm.direccion.colonia
    );
    formik.setFieldValue(
      "domicilioComercial.municipioId",
      modeloPm.direccion.municipioId
    );
    formik.setFieldValue(
      "domicilioComercial.estadoId",
      modeloPm.direccion.estadoId
    );

    setModeloPm({
      ...modeloPm,
      domicilioComercial: {
        ...modeloPm.domicilioComercial,
        calle: modeloPm.direccion.calle,
        numeroExterior: modeloPm.direccion.numeroExterior,
        numeroInterior: modeloPm.direccion.numeroInterior,
        codigoPostal: modeloPm.direccion.codigoPostal,
        colonia: modeloPm.direccion.colonia,
        municipioId: modeloPm.direccion.municipioId,
        estadoId: modeloPm.direccion.estadoId,
      },
    });
  };

  const salvarDatosDomicilioComercialPM = async (formData) => {
    try {
      const response = await api_save_datos_domicilio_comercial_PM(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPm({
            ...modeloPm,
            idCliente: data.clienteId,
            domicilioComercial: data.domicilioComercial,
          });
          handleSeccionPyme(SECCION_DOMICILIO_COMERCIAL_PM);
          formik.setFieldValue(
            "domicilioComercial.idDatosDelNegocio",
            data.domicilioComercialId
          );
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data.mensaje) {
            sweetAlertWithConfirmedCrediactivo(data?.mensaje);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertError(
          "Ocurrió un error al guardar datos domicilio comercial PFAE"
        );
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      modeloPm.domicilioComercial !== null &&
      modeloPm.domicilioComercial !== undefined
    ) {
      if (
        modeloPm.domicilioComercial.codigoPostal !== null &&
        modeloPm.domicilioComercial.codigoPostal !== undefined
      ) {
        obtenerDireccionByCp(modeloPm.domicilioComercial.codigoPostal);
      }

      setDomicilioFiscalIgual(modeloPm.domicilioComercial.igualDomicilioFiscal);
    }
  }, []);

  return (
    <>
      <form name="form-domicilio-comercial" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">
            Información de domicilio comercial
            <div className="input__tooltip_titulo top">
              <AiOutlineQuestionCircle className="input__tooltip_titulo" />
              <span className="tiptext">
                En caso de que tengas varias sucursales o domicilios
                comerciales, captura el principal
              </span>
            </div>
          </h2>
          <FormLayout>
            <SelectWithErrorRepresentanteLegal
              label="¿Es el mismo que el domicilio Fiscal?"
              name="domicilioComercial.igualDomicilioFiscal"
              value={
                domicilioFiscalIgual
                  ? { label: "Si", value: true }
                  : { label: "No", value: false }
              }
              onChange={(e) => {
                const event = { ...e, target: { value: e.value } };
                handleChangeDomicilioFiscal(event);
              }}
              options={[
                { label: "Si", value: true },
                { label: "No", value: false },
              ]}
            />
            <InputWithError
              label="Calle *"
              name="domicilioComercial.calle"
              value={formik.values?.domicilioComercial?.calle}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.calle
                ) {
                  delete newErrors?.domicilioComercial?.calle;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "domicilioComercial.calle",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 24 }}
              disabled={domicilioFiscalIgual}
              showErrorMessage={formik.errors?.domicilioComercial?.calle}
              errorMessage={formik.errors?.domicilioComercial?.calle}
            />
            <InputWithError
              label="Número exterior *"
              name="domicilioComercial.numeroExterior"
              value={formik.values?.domicilioComercial?.numeroExterior}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.numeroExterior
                ) {
                  delete newErrors?.domicilioComercial?.numeroExterior;
                  formik.setErrors(newErrors);
                }
                if (notSpecialCaractersConGuion(e.target.value)) {
                  formik.setFieldValue(
                    "domicilioComercial.numeroExterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 7 }}
              disabled={domicilioFiscalIgual}
              showErrorMessage={
                formik.errors?.domicilioComercial?.numeroExterior
              }
              errorMessage={formik.errors?.domicilioComercial?.numeroExterior}
            />
            <InputWithError
              label="Número interior"
              name="domicilioComercial.numeroInterior"
              value={formik.values?.domicilioComercial?.numeroInterior}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.numeroInterior
                ) {
                  delete newErrors?.domicilioComercial?.numeroInterior;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "domicilioComercial.numeroInterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu  “Número exterior” separados por un guion Ejemplo: 318-123B"
              disabled={domicilioFiscalIgual}
              showErrorMessage={
                formik.errors?.domicilioComercial?.numeroInterior
              }
              errorMessage={formik.errors?.domicilioComercial?.numeroInterior}
            />
            <InputWithError
              label="CP *"
              name="domicilioComercial.codigoPostal"
              value={formik.values?.domicilioComercial?.codigoPostal}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.codigoPostal
                ) {
                  delete newErrors?.domicilioComercial?.codigoPostal;
                  formik.setErrors(newErrors);
                }
                if (
                  e.target.value.length <= 5 &&
                  validateValue(e.target.value, "number")
                ) {
                  formik.setFieldValue(
                    "domicilioComercial.codigoPostal",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length === 5) {
                  let newErrors = formik.errors;
                  if (newErrors?.domicilioComercial !== undefined) {
                    if (newErrors?.domicilioComercial?.colonia) {
                      delete newErrors?.domicilioComercial?.colonia;
                    }
                    if (newErrors?.domicilioComercial?.municipioId) {
                      delete newErrors?.domicilioComercial?.municipioId;
                    }
                    if (newErrors?.domicilioComercial?.estadoId) {
                      delete newErrors?.domicilioComercial?.estadoId;
                    }
                    formik.setErrors(newErrors);
                  }
                  obtenerDireccionByCp(e.target.value);
                } else {
                  limpiarDireccionByCp();
                }
              }}
              InputProps={{ minLength: 5, maxLength: 5 }}
              disabled={domicilioFiscalIgual}
              showErrorMessage={formik.errors?.domicilioComercial?.codigoPostal}
              errorMessage={formik.errors?.domicilioComercial?.codigoPostal}
            />
            <SelectWithError
              label="Colonia *"
              name="domicilioComercial.colonia"
              value={{
                label: formik?.values?.domicilioComercial?.colonia,
                value: formik?.values?.domicilioComercial?.colonia,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.colonia
                ) {
                  delete newErrors?.domicilioComercial?.colonia;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("domicilioComercial.colonia", e.label);
              }}
              options={coloniasCP?.map((e) => {
                return { ...e, label: e, value: e };
              })}
              showErrorMessage={formik.errors?.domicilioComercial?.colonia}
              errorMessage={formik.errors?.domicilioComercial?.colonia}
            />
            <SelectWithError
              name="domicilioComercial.municipioId"
              label="Alcaldía / Municipio *"
              value={{
                label:
                  formik?.values?.domicilioComercial?.municipioObj[0]?.nombre,
                value:
                  formik?.values?.domicilioComercial?.municipioObj[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.municipioId
                ) {
                  delete newErrors?.domicilioComercial?.municipioId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("domicilioComercial.municipioId", e.id);
                formik.setFieldValue("domicilioComercial.municipioObj", [e]);
              }}
              isDisabled={true}
              options={axiosData?.generales?.municipios?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={formik.errors?.domicilioComercial?.municipioId}
              errorMessage={formik.errors?.domicilioComercial?.municipioId}
            />
            <SelectWithError
              menuPlacement="top"
              label="Estado *"
              name="domicilioComercial.estadoId"
              value={{
                label: formik?.values?.domicilioComercial?.estadoObj[0]?.nombre,
                value: formik?.values?.domicilioComercial?.estadoObj[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.estadoId
                ) {
                  delete newErrors?.domicilioComercial?.estadoId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("domicilioComercial.estadoId", e.id);
                formik.setFieldValue("domicilioComercial.estadoObj", [e]);
              }}
              idDisabled={true}
              options={axiosData?.generales?.estados?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={formik.errors?.domicilioComercial?.estadoId}
              errorMessage={formik.errors?.domicilioComercial?.estadoId}
            />
            <InputWithError
              label="Teléfono *"
              name="domicilioComercial.telefono"
              value={formik.values?.domicilioComercial?.telefono}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.telefono
                ) {
                  delete newErrors?.domicilioComercial?.telefono;
                  formik.setErrors(newErrors);
                }
                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.domicilioComercial?.telefono
                );
                formik.setFieldValue(
                  "domicilioComercial.telefono",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={formik.errors?.domicilioComercial?.telefono}
              errorMessage={formik.errors?.domicilioComercial?.telefono}
            />
            <SelectWithError
              label="Años de residencia en el domicilio comercial *"
              menuPlacement="top"
              name="domicilioComercial.tiempoResidencia"
              value={{
                label: formik?.values?.domicilioComercial?.tiempoResidencia,
                value: formik?.values?.domicilioComercial?.tiempoResidencia,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.tiempoResidencia
                ) {
                  delete newErrors?.domicilioComercial?.tiempoResidencia;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "domicilioComercial.tiempoResidencia",
                  e.value
                );
              }}
              options={anios?.map((item, index) => {
                return {
                  ...item,
                  label: index + 1,
                  value: index + 1,
                };
              })}
              showErrorMessage={
                formik.errors?.domicilioComercial?.tiempoResidencia
              }
              errorMessage={formik.errors?.domicilioComercial?.tiempoResidencia}
            />
            <div className="d-flex gap-2">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(SECCION_DOMICILIO_COMERCIAL_PM);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit" disabled={formik.isSubmitting}>
                Siguiente
              </PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default DatosGeneralesDomicilioComercialPM;

function initialValues(modeloPm, axiosData) {
  let domicilioComercial = modeloPm.domicilioComercial;
  return {
    idCliente: modeloPm.idCliente,
    direccion: {
      idDireccion: modeloPm.direccion.idDireccion,
    },
    domicilioComercial: {
      idDatosDelNegocio: domicilioComercial.idDatosDelNegocio
        ? domicilioComercial.idDatosDelNegocio
        : "",
      igualDomicilioFiscal: domicilioComercial.igualDomicilioFiscal
        ? domicilioComercial.igualDomicilioFiscal
        : false,
      calle: domicilioComercial.calle ? domicilioComercial.calle : "",
      numeroExterior: domicilioComercial.numeroExterior
        ? domicilioComercial.numeroExterior
        : "",
      numeroInterior: domicilioComercial.numeroInterior
        ? domicilioComercial.numeroInterior
        : "",
      codigoPostal: domicilioComercial.codigoPostal
        ? domicilioComercial.codigoPostal
        : "",
      colonia: domicilioComercial.colonia ? domicilioComercial.colonia : "",
      municipioId: domicilioComercial.municipioId
        ? domicilioComercial.municipioId
        : "",
      municipioObj: domicilioComercial.municipioId
        ? axiosData?.generales?.municipios.filter(
            (e) => e.id === domicilioComercial.municipioId
          )
        : [{ nombre: "" }],
      estadoId: domicilioComercial.estadoId ? domicilioComercial.estadoId : "",
      estadoObj: domicilioComercial.estadoId
        ? axiosData?.generales?.estados?.filter(
            (e) => e.id === domicilioComercial?.estadoId
          )
        : [{ nombre: "" }],
      tiempoResidencia: domicilioComercial.tiempoResidencia
        ? domicilioComercial.tiempoResidencia
        : "",
      telefono: domicilioComercial.telefono ? domicilioComercial.telefono : "",
    },
  };
}

function validationSchema() {
  return {
    domicilioComercial: Yup.object().shape({
      calle: Yup.string().required("Dato requerido."),
      numeroExterior: Yup.string().required("Dato requerido."),
      codigoPostal: Yup.string().required("Dato requerido."),
      colonia: Yup.string().required("Dato requerido."),
      municipioId: Yup.string().required("Dato requerido."),
      estadoId: Yup.string().required("Dato requerido."),
      telefono: Yup.string()
        .required("Dato requerido.")
        .test(
          "telefonoContacto",
          "Teléfono capturado de forma incorrecta.",
          (item, testContext) => {
            return validarNumeroTelefono(testContext.parent.telefono);
          }
        ),
      tiempoResidencia: Yup.string().required("Dato requerido."),
      igualDomicilioFiscal: Yup.string().required("Dato requerido."),
    }),
  };
}
