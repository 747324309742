import React, { useEffect, useState } from "react";

import DatosGeneralesPFAE from "./DatosGeneralesPFAE";
import DatosGeneralesDomicilioPFAE from "./DatosGeneralesDomicilioPFAE";
import DatosGeneralesDomicilioFiscalPFAE from "./DatosGeneralesDomicilioFiscalPFAE";
import DomicilioComercialPFAE from "./DomicilioComercialPFAE";
import DatosEconomicosPFAE from "./DatosEconomicosPFAE/DatosEconomicosPFAE";
import DatosPatrimonioPrincipalPFAE from "./DatosPatrimonioPFAE/DatosPatrimonioPrincipalPFAE";
import CargaDocumentos from "./CargaDocumentos/CargaDocumentosPFAE";
import HistorialCrediticio from "./../HistorialCrediticio";
import SolicitanteConyuge from "./ObligadoSolidario/SolicitanteConyuge";
import ObligadoSolidarioPFAE from "./ObligadoSolidario/ObligadoSolidarioPFAE";
import ObligadoSolidarioConyuge from "./ObligadoSolidario/ObligadoSolidarioConyuge";
import DatosCapturaClaveCIECPFAE from "./DatosCapturaClaveCIECPFAE";

import {
  OS_UNO,
  OS_DOS,
  OS_TRES,
  OS_CUATRO,
  OS_CINCO,
  NAME_INPUT_DATO_DOMICILIO_TIPO_DOMICILIO,
  TIPO_DOMICILIO_RENTA,
  NAME_INPUT_DATO_DOMICILIO_TIPO_VIVIENDA,
  SECCION_OS_DOS,
  SECCION_OS_TRES,
  SECCION_OS_CUATRO,
  SECCION_OBLIGADO_SOLIDARIO,
  SECCION_CONYUGE_OS_UNO,
  SECCION_CONYUGE_OS_DOS,
  SECCION_CONYUGE_OS_TRES,
  SECCION_CONYUGE_OS_CUATRO,
  SECCION_OS_CINCO,
} from "../../../../utils/constantes";

const FormularioPFAE = ({
  animated,
  importeRentaFiscal,
  totalNominaMensual,
  transitionOne,
  transitionTwo,
  transitionThree,
  transitionFour,
  transitionFive,
  transitionEight,
  transitionNine,
  transitionTen,
  transitionEleven,
  transitionTwelve,
  transitionThirteen,
  transitionFourtheen,
  transitionFivetheen,
  transitionDiesiseis,
  transitionDiesisiete,
  transitionDiesiocho,
  transitionVeinte,
  transitionVeintidos,
  modeloPfae,
  axiosData,
  aniosSector,
  anios,
  aniosDomicilio,
  mesesDomicilo,
  setShowResult,
  tipoPersona,
  cantidadObligadosSolidarios,
  setModeloPfae,
  setObligadosSolidariosInformacionBasica,
  setStyleHr,
  setFolioSolicitud,
  domicilioFiscalIgual,
  setDomicilioFiscalIgual,
  setLoading,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  datosOSUno,
  setDatosOSUno,
  datosOSDos,
  setDatosOSDos,
  datosOSTres,
  setDatosOSTres,
  datosOSCuatro,
  setDatosOSCuatro,
  datosOSCinco,
  setDatosOSCinco,
  setCantidadObligadosSolidarios,
  setCantidadConyugeOS,
  cantidadConyugeOS,
  setUltimoPasoSolicitud,
  setResultadoEvaluacionRiesgos,
  setConyugeOS,
  conyugeOS,
  setSeccion,
  setFormSection,
  ultimoPasoSolicitud
}) => {
  const [showInputImporteRenta, setShowInputImporteRenta] = useState(false);
  const [inputImporteRenta, setInputImporteRenta] = useState(0);

  const mostrarInputImporteRenta = (valor, name) => {
    if (name === NAME_INPUT_DATO_DOMICILIO_TIPO_DOMICILIO) {
      if (parseInt(valor) === TIPO_DOMICILIO_RENTA) {
        setInputImporteRenta(parseInt(valor));
        setShowInputImporteRenta(true);
      } else {
        setShowInputImporteRenta(false);
      }
    }
    if (name === NAME_INPUT_DATO_DOMICILIO_TIPO_VIVIENDA) {
      if (inputImporteRenta !== TIPO_DOMICILIO_RENTA) {
        setShowInputImporteRenta(false);
      }
    }
  };

  return (
    <>
      {transitionOne(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPfae?.generales !== undefined &&
                <DatosGeneralesPFAE
                  modeloPfae={modeloPfae}
                  axiosData={axiosData}
                  setModeloPfae={setModeloPfae}
                  setFolioSolicitud={setFolioSolicitud}
                  setStyleHr={setStyleHr}
                  setLoading={setLoading}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                />
              }
            </animated.div>
          )
      )}
      {transitionTwo(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPfae?.direccion !== undefined &&
                <DatosGeneralesDomicilioPFAE
                  modeloPfae={modeloPfae}
                  setModeloPfae={setModeloPfae}
                  axiosData={axiosData}
                  aniosDomicilio={aniosDomicilio}
                  showInputImporteRenta={showInputImporteRenta}
                  mostrarInputImporteRenta={mostrarInputImporteRenta}
                  mesesDomicilo={mesesDomicilo}
                  setLoading={setLoading}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                />
              }
            </animated.div>
          )
      )}
      {transitionThree(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPfae?.domicilioFiscal !== undefined &&
                <DatosGeneralesDomicilioFiscalPFAE
                  modeloPfae={modeloPfae}
                  setModeloPfae={setModeloPfae}
                  axiosData={axiosData}
                  aniosSector={aniosSector}
                  anios={anios}
                  importeRentaFiscal={importeRentaFiscal}
                  totalNominaMensual={totalNominaMensual}
                  setLoading={setLoading}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                />
              }
            </animated.div>
          )
      )}
      {transitionFour(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPfae?.domicilioComercial !== undefined &&
                <DomicilioComercialPFAE
                  modeloPfae={modeloPfae}
                  setModeloPfae={setModeloPfae}
                  axiosData={axiosData}
                  anios={anios}
                  domicilioFiscalIgual={domicilioFiscalIgual}
                  setDomicilioFiscalIgual={setDomicilioFiscalIgual}
                  setLoading={setLoading}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                />
              }
            </animated.div>
          )
      )}
      {transitionFive(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPfae?.datosEconomicos !== undefined &&
                <DatosCapturaClaveCIECPFAE
                  axiosData={axiosData}
                  modeloPfae={modeloPfae}
                  setModeloPfae={setModeloPfae}
                  setStyleHr={setStyleHr}
                  setLoading={setLoading}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  setConyugeOS={setConyugeOS}
                  setSeccion={setSeccion}
                  setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                  cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                />
              }
            </animated.div>
          )
      )}
      {transitionVeinte(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              {modeloPfae?.conyuge !== undefined &&
                <SolicitanteConyuge
                  modeloPfae={modeloPfae}
                  axiosData={axiosData}
                  aniosDomicilio={aniosDomicilio}
                  mesesDomicilo={mesesDomicilo}
                  anios={anios}
                  setModeloPfae={setModeloPfae}
                  setStyleHr={setStyleHr}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  setObligadosSolidariosInformacionBasica={
                    setObligadosSolidariosInformacionBasica
                  }
                  setLoading={setLoading}
                  setCantidadConyugeOS={setCantidadConyugeOS}
                  cantidadConyugeOS={cantidadConyugeOS}
                  conyugeOS={conyugeOS}
                  setConyugeOS={setConyugeOS}
                />
              }
            </animated.div>
          )
      )}
      {transitionEight(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioPFAE
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                mesesDomicilo={mesesDomicilo}
                aniosDomicilio={aniosDomicilio}
                anios={anios}
                setLoading={setLoading}
                NUM_OS={OS_UNO}
                seccionActual={SECCION_OBLIGADO_SOLIDARIO}
                datosOs={datosOSUno}
                setDatosOs={setDatosOSUno}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionNine(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioConyuge
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                axiosData={axiosData}
                aniosDomicilio={aniosDomicilio}
                mesesDomicilo={mesesDomicilo}
                anios={anios}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                num_os={OS_UNO}
                setLoading={setLoading}
                seccionActual={SECCION_CONYUGE_OS_UNO}
                datosOs={datosOSUno}
                setDatosOs={setDatosOSUno}
                setCantidadConyugeOS={setCantidadConyugeOS}
                cantidadConyugeOS={cantidadConyugeOS}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionTen(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioPFAE
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                mesesDomicilo={mesesDomicilo}
                aniosDomicilio={aniosDomicilio}
                anios={anios}
                setLoading={setLoading}
                NUM_OS={OS_DOS}
                seccionActual={SECCION_OS_DOS}
                datosOs={datosOSDos}
                setDatosOs={setDatosOSDos}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionEleven(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioConyuge
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                axiosData={axiosData}
                aniosDomicilio={aniosDomicilio}
                mesesDomicilo={mesesDomicilo}
                anios={anios}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                num_os={OS_DOS}
                setLoading={setLoading}
                seccionActual={SECCION_CONYUGE_OS_DOS}
                datosOs={datosOSDos}
                setDatosOs={setDatosOSDos}
                setCantidadConyugeOS={setCantidadConyugeOS}
                cantidadConyugeOS={cantidadConyugeOS}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionTwelve(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioPFAE
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                mesesDomicilo={mesesDomicilo}
                aniosDomicilio={aniosDomicilio}
                anios={anios}
                setLoading={setLoading}
                NUM_OS={OS_TRES}
                seccionActual={SECCION_OS_TRES}
                datosOs={datosOSTres}
                setDatosOs={setDatosOSTres}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionThirteen(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioConyuge
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                axiosData={axiosData}
                aniosDomicilio={aniosDomicilio}
                mesesDomicilo={mesesDomicilo}
                anios={anios}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                num_os={OS_TRES}
                setLoading={setLoading}
                seccionActual={SECCION_CONYUGE_OS_TRES}
                datosOs={datosOSTres}
                setDatosOs={setDatosOSTres}
                setCantidadConyugeOS={setCantidadConyugeOS}
                cantidadConyugeOS={cantidadConyugeOS}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionFourtheen(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioPFAE
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                mesesDomicilo={mesesDomicilo}
                aniosDomicilio={aniosDomicilio}
                anios={anios}
                setLoading={setLoading}
                NUM_OS={OS_CUATRO}
                seccionActual={SECCION_OS_CUATRO}
                datosOs={datosOSCuatro}
                setDatosOs={setDatosOSCuatro}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionFivetheen(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioConyuge
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                axiosData={axiosData}
                aniosDomicilio={aniosDomicilio}
                mesesDomicilo={mesesDomicilo}
                anios={anios}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                num_os={OS_CUATRO}
                setLoading={setLoading}
                seccionActual={SECCION_CONYUGE_OS_CUATRO}
                datosOs={datosOSCuatro}
                setDatosOs={setDatosOSCuatro}
                setCantidadConyugeOS={setCantidadConyugeOS}
                cantidadConyugeOS={cantidadConyugeOS}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionVeintidos(
        (style, visible) =>
          visible && (
            <animated.div style={style}>
              <ObligadoSolidarioPFAE
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                mesesDomicilo={mesesDomicilo}
                aniosDomicilio={aniosDomicilio}
                anios={anios}
                setLoading={setLoading}
                NUM_OS={OS_CINCO}
                seccionActual={SECCION_OS_CINCO}
                datosOs={datosOSCinco}
                setDatosOs={setDatosOSCinco}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionDiesiseis(
        (style, visible) =>
          visible === true && (
            <animated.div style={style}>
              <DatosPatrimonioPrincipalPFAE
                modeloPfae={modeloPfae}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                axiosData={axiosData}
                setModeloPfae={setModeloPfae}
                setStyleHr={setStyleHr}
                setLoading={setLoading}
              />
            </animated.div>
          )
      )}
      {transitionDiesisiete(
        (style, visible) =>
          visible === true && (
            <animated.div style={style}>
              <HistorialCrediticio
                modeloDatos={modeloPfae}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setStyleHr={setStyleHr}
                setShowResult={setShowResult}
                setLoading={setLoading}
                tipoPersona={tipoPersona}
                setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
                setFormSection={setFormSection}
                ultimoPasoSolicitud={ultimoPasoSolicitud}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
              />
            </animated.div>
          )
      )}
      {transitionDiesiocho(
        (style, visible) =>
          visible === true && (
            <animated.div style={style}>
              <CargaDocumentos
                modeloPfae={modeloPfae}
                setModeloPfae={setModeloPfae}
                setLoading={setLoading}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
              />
            </animated.div>
          )
      )}
    </>
  );
};

export default FormularioPFAE;
