import {
  ID_CALCULO_RFC_APM,
  ID_CALCULO_RFC_APP,
  ID_CALCULO_RFC_ESTADONAC,
  ID_CALCULO_RFC_FECHANAC,
  ID_CALCULO_RFC_GENERO,
  ID_CALCULO_RFC_NOMBRE,
} from "../../../../../../utils/constantes";

export const obtenerGenerarRfcCurp = (
  idValorCalculoRFC,
  valueCalculoRFC,
  formik
) => {
  const objRfcCurp = {
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    estadoNacimientoId: "",
    fechaNacimiento: "",
    generoId: "",
  };

  switch (idValorCalculoRFC) {
    case ID_CALCULO_RFC_NOMBRE:
      objRfcCurp.nombre = valueCalculoRFC;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APP:
      objRfcCurp.nombre = formik.values?.principalAccionista?.generales?.nombre;
      objRfcCurp.apellidoPaterno = valueCalculoRFC;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APM:
      objRfcCurp.nombre = formik.values?.principalAccionista?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno = valueCalculoRFC;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_FECHANAC:
      objRfcCurp.nombre = formik.values?.principalAccionista?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento = valueCalculoRFC;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_GENERO:
      objRfcCurp.nombre = formik.values?.principalAccionista?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.generales?.fechaNacimiento;
      objRfcCurp.generoId = valueCalculoRFC;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_ESTADONAC:
      objRfcCurp.nombre = formik.values?.principalAccionista?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.generales?.generoId;
      objRfcCurp.estadoNacimientoId = valueCalculoRFC;
      break;
  }

  return objRfcCurp;
};

export const obtenerGenerarRfcCurpOS_PF_PM = (
  idValorCalculoRFC,
  valueCalculoRFC,
  formik
) => {
  const objRfcCurp = {
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    estadoNacimientoId: "",
    fechaNacimiento: "",
    generoId: "",
  };
  switch (idValorCalculoRFC) {
    case ID_CALCULO_RFC_NOMBRE:
      objRfcCurp.nombre = valueCalculoRFC;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APP:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno = valueCalculoRFC;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APM:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno = valueCalculoRFC;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_FECHANAC:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento = valueCalculoRFC;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_GENERO:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId = valueCalculoRFC;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_ESTADONAC:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.generales?.generoId;
      objRfcCurp.estadoNacimientoId = valueCalculoRFC;
      break;
  }

  return objRfcCurp;
};

export const obtenerGenerarRfcCurpOs_Conyuge = (
  idValorCalculoRFC,
  valueCalculoRFC,
  formik
) => {
  const objRfcCurp = {
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    estadoNacimientoId: "",
    fechaNacimiento: "",
    generoId: "",
  };

  switch (idValorCalculoRFC) {
    case ID_CALCULO_RFC_NOMBRE:
      objRfcCurp.nombre = valueCalculoRFC;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APP:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno = valueCalculoRFC;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APM:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno = valueCalculoRFC;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_FECHANAC:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento = valueCalculoRFC;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_GENERO:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId = valueCalculoRFC;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_ESTADONAC:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.obligadoSolidario?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId = valueCalculoRFC;
      break;
  }

  return objRfcCurp;
};

export const obtenerGenerarRfcCurpDPAC = (
  idValorCalculoRFC,
  valueCalculoRFC,
  formik
) => {
  const objRfcCurp = {
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    estadoNacimientoId: "",
    fechaNacimiento: "",
    generoId: "",
  };

  switch (idValorCalculoRFC) {
    case ID_CALCULO_RFC_NOMBRE:
      objRfcCurp.nombre = valueCalculoRFC;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APP:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno = valueCalculoRFC;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_APM:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno = valueCalculoRFC;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_FECHANAC:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento = valueCalculoRFC;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_GENERO:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId = valueCalculoRFC;
      objRfcCurp.estadoNacimientoId =
        formik.values?.principalAccionista?.conyuge?.generales?.estadoNacimientoId;
      break;
    case ID_CALCULO_RFC_ESTADONAC:
      objRfcCurp.nombre =
        formik.values?.principalAccionista?.conyuge?.generales?.nombre;
      objRfcCurp.apellidoPaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoPaterno;
      objRfcCurp.apellidoMaterno =
        formik.values?.principalAccionista?.conyuge?.generales?.apellidoMaterno;
      objRfcCurp.fechaNacimiento =
        formik.values?.principalAccionista?.conyuge?.generales?.fechaNacimiento;
      objRfcCurp.generoId =
        formik.values?.principalAccionista?.conyuge?.generales?.generoId;
      objRfcCurp.estadoNacimientoId = valueCalculoRFC;
      break;
  }

  return objRfcCurp;
};
