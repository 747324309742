import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import "moment/locale/es";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import SelectWithErrorActividad from "../../../../basics/SelectWithError/SelectWithErrorActividad";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";
import { selectStylesDouble } from "../../../../../styles/select-styles";
import CheckBoxInput from "../../../../basics/checkBox";
import SelectWithErrorTooltipCompuesto from "../../../../basics/SelectWithError/SelectWithErrorTooltipCompuesto";

import {
  ID_PF,
  ID_PFAE,
  NACIONALIDAD_MEXICAN0,
  SECCION_PRINCIPAL_ACCIONISTA,
  TIPO_VIVIENDA_EDIFICIO,
  INE_CERTIFICADA,
  APLICA_INE_COMPROBANTE_OS_NO,
  TIPO_DEPARTAMENTO_APARTAMENTO,
  ID_CALCULO_RFC_NOMBRE,
  ID_CALCULO_RFC_APP,
  ID_CALCULO_RFC_APM,
  ID_CALCULO_RFC_GENERO,
  ID_CALCULO_RFC_ESTADONAC,
  ID_CALCULO_RFC_FECHANAC,
  ID_PAIS_MEXICO,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../../../utils/constantes";

import {
  api_direccion_by_cp,
  api_save_principal_accionista_PM,
} from "../../../../../api/api_servicios_rest_crediactivo";

import {
  dependeintesMajor23,
  fechaActualYYYMMDD,
  fechaMaxMayorEdadYYYMMDD,
  fechaMinEdadYYYMMDD,
  tiempoViviendaAnterior,
  tiempoViviendaActual,
  generaCurp,
  generaRFC,
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
  compareDatesOrigin,
  validarRFCTipoPersona,
} from "../../../../../utils/validaciones";
import {
  validateValue,
  textToUpperCase,
  isEmpty,
  validDate,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../../functions/fieldFunctions";

import { mensajeMostrarUsuario } from "../../../../../utils/mensajesServicios";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";
import { generarRfcCurpPM } from "./helpers/obligadoSolidarioHelper";
import {
  sweetAlertError,
  sweetAlertWithConfirmedCrediactivo,
} from "../../alertas/alertas";

const DatosPrincipalAccionistaPM = ({
  modeloPm,
  setModeloPm,
  axiosData,
  mesesDomicilo,
  aniosDomicilio,
  anios,
  idTipoPersonaPA,
  setStyleHr,
  setObligadosSolidariosInformacionBasica,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setLoading,
  setActualizarPA,
}) => {
  const { innerWidth } = window;

  const [coloniasCP, setColoniasCP] = useState([]);
  const [startDateFechaNacimiento, setStartDateFechaNacimiento] = useState("");
  const [startDateFechaSHCP, setStartDateFechaSHCP] = useState("");
  const [totalDependientes, setTotalDependientes] = useState("");
  const [totalDependientesMenores23, setTotalDependientesMenores23] =
    useState("");

  const formik = useFormik({
    initialValues: initialValues(modeloPm, idTipoPersonaPA, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(
      validationSchema(
        idTipoPersonaPA,
        totalDependientesMenores23,
        totalDependientes
      )
    ),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosPAPM(formData);
    },
  });

  const generarRfcCurp = (idValorCalculoRFC, valueCalculoRFC) => {
    const {
      nombre,
      apellidoMaterno,
      apellidoPaterno,
      fechaNacimiento,
      generoId,
      estadoNacimientoId,
    } = generarRfcCurpPM(idValorCalculoRFC, valueCalculoRFC, formik);

    if (
      nombre &&
      nombre.length > 0 &&
      apellidoPaterno &&
      apellidoPaterno.length > 0 &&
      generoId &&
      estadoNacimientoId &&
      fechaNacimiento &&
      fechaNacimiento.length > 0
    ) {
      const idEstado = Number(estadoNacimientoId);
      const persona = {
        nombre: nombre,
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
        genero: generoId === 1 ? "H" : "M",
        idEstado: idEstado - 1,
        fechaNacimiento: fechaNacimiento,
      };

      const curp = generaCurp(persona);
      if (curp && curp.length === 18) {
        formik.setFieldValue("principalAccionista.generales.curp", curp);
      }

      if (apellidoMaterno && apellidoMaterno.length > 0) {
        const rfc = generaRFC(persona);
        if (rfc) {
          formik.setFieldValue("principalAccionista.generales.rfc", rfc);
        }
      }
    }
  };

  const obtenerDireccionByCp = async (codigoPostal) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK") {
        if (response.data.municipio !== undefined) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "principalAccionista.direccion.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue(
            "dataObj.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response?.data?.municipio?.id
            )
          );
          formik.setFieldValue(
            "principalAccionista.direccion.estadoId",
            response.data.estado.id
          );
          formik.setFieldValue(
            "dataObj.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response?.data?.estado?.id
            )
          );
          formik.setFieldValue(
            "principalAccionista.direccion.colonia",
            response.data.asentamientos[0]
          );
        } else {
          limpiarDireccionByCp();
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue("principalAccionista.direccion.municipioId", "");
    formik.setFieldValue("principalAccionista.direccion.municipioObj", "");
    formik.setFieldValue("principalAccionista.direccion.estadoId", "");
    formik.setFieldValue("principalAccionista.direccion.estadoObj", "");
    formik.setFieldValue("principalAccionista.direccion.colonia", "");
  };

  const salvarDatosPAPM = async (datosPA) => {
    try {
      const response = await api_save_principal_accionista_PM(datosPA);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPm({
            ...modeloPm,
            idCliente: data.clienteId,
            principalAccionista: data.principalAccionistaModelo,
          });

          setObligadosSolidariosInformacionBasica(
            data.obligadosSolidariosInformacionBasica
          );
          setActualizarPA(false);
          setStyleHr("block");
          setLoading(false);
          handleSeccionPyme(SECCION_PRINCIPAL_ACCIONISTA);
        } else {
          setLoading(false);
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data.mensaje) {
            sweetAlertWithConfirmedCrediactivo(data?.mensaje);
          } else {
            const mensajeError = mensajeMostrarUsuario(data.mensaje);
            sweetAlertError(mensajeError);
          }
        }
      } else {
        setLoading(false);
        sweetAlertError(
          "Ocurrió un error al guardar del principal accionista PM"
        );
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      modeloPm?.principalAccionista?.idPrincipalAccionista !== null &&
      modeloPm.principalAccionista.generales.tipoPersonaId !== null
    ) {
      if (
        modeloPm?.principalAccionista?.generales?.fechaNacimiento !==
          undefined &&
        modeloPm?.principalAccionista?.generales?.fechaNacimiento !== null
      ) {
        setStartDateFechaNacimiento(
          modeloPm?.principalAccionista?.generales?.fechaNacimiento
            .split("-")
            .reverse()
            .join("-")
        );
      } else {
        formik.setFieldValue(
          "principalAccionista.generales.fechaNacimiento",
          ""
        );
      }

      if (modeloPm?.principalAccionista?.generales?.paisNacimientoId === null) {
        formik.setFieldValue(
          "principalAccionista.generales.paisNacimientoId",
          ""
        );
      }

      if (
        modeloPm?.principalAccionista?.generales?.estadoNacimientoId === null
      ) {
        formik.setFieldValue(
          "principalAccionista.generales.estadoNacimientoId",
          ""
        );
      }

      if (modeloPm?.principalAccionista?.generales?.generoId === null) {
        formik.setFieldValue("principalAccionista.generales.generoId", "");
      }

      if (
        modeloPm?.principalAccionista?.datosDelNegocio?.fechaAltaShcp !==
          undefined &&
        modeloPm?.principalAccionista?.datosDelNegocio?.fechaAltaShcp !== null
      ) {
        setStartDateFechaSHCP(
          modeloPm?.principalAccionista?.datosDelNegocio?.fechaAltaShcp
            .split("-")
            .reverse()
            .join("-")
        );
      }

      if (modeloPm?.principalAccionista?.direccion !== null) {
        obtenerDireccionByCp(
          modeloPm?.principalAccionista?.direccion?.codigoPostal
        );

        let piso = modeloPm?.principalAccionista?.direccion?.piso
          ? modeloPm?.principalAccionista?.direccion?.piso
          : "";
        formik.setFieldValue("principalAccionista.direccion.piso", piso);

        if (modeloPm?.principalAccionista?.direccion?.numeroInterior === null) {
          formik.setFieldValue(
            "principalAccionista.direccion.numeroInterior",
            ""
          );
        }
      }

      let calidadMigratoria = modeloPm?.principalAccionista?.generales
        ?.calidadMigratoria
        ? modeloPm?.principalAccionista?.generales?.calidadMigratoria
        : "";
      formik.setFieldValue(
        "principalAccionista.generales.calidadMigratoria",
        calidadMigratoria
      );

      let dependientesMajor23Opc = modeloPm?.principalAccionista?.generales
        ?.dependientesEconomicosMenores23
        ? axiosData?.generales?.dependientesMenores23Anios?.filter(
            (e) =>
              e.id ===
              modeloPm?.principalAccionista?.generales
                ?.dependientesEconomicosMenores23
          )
        : "";
      let dependientesEconomicoOpc = modeloPm?.principalAccionista?.generales
        ?.dependientesEconomicos
        ? axiosData?.generales?.dependientes?.filter(
            (e) =>
              e.id ===
              modeloPm?.principalAccionista?.generales?.dependientesEconomicos
          )
        : "";
      setTotalDependientesMenores23(
        dependientesMajor23Opc !== ""
          ? dependientesMajor23Opc[0].descripcion
          : 0
      );
      setTotalDependientes(
        dependientesEconomicoOpc !== ""
          ? dependientesEconomicoOpc[0].descripcion
          : 0
      );

      if (
        modeloPm?.principalAccionista?.generales?.nombreOtraEmpresaPfae === null
      ) {
        formik.setFieldValue(
          "principalAccionista.generales.nombreOtraEmpresaPfae",
          ""
        );
      }

      if (
        modeloPm?.principalAccionista?.generales?.nombreOtraEmpresa === null
      ) {
        formik.setFieldValue(
          "principalAccionista.generales.nombreOtraEmpresa",
          ""
        );
      }

      if (
        modeloPm?.principalAccionista?.generales?.paisResidenciaFiscal2Id ===
        null
      ) {
        formik.setFieldValue(
          "principalAccionista.generales.paisResidenciaFiscal2Id",
          ""
        );
      }

      if (modeloPm?.principalAccionista?.generales?.numeroIdFiscal2 === null) {
        formik.setFieldValue(
          "principalAccionista.generales.numeroIdFiscal2",
          ""
        );
      }

      if (
        modeloPm?.principalAccionista?.datosDelNegocio !== undefined &&
        modeloPm?.principalAccionista?.datosDelNegocio?.idDatosDelNegocio ===
          null
      ) {
        formik.setFieldValue(
          "principalAccionista.datosDelNegocio.importaExportaId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.datosDelNegocio.sectorId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.datosDelNegocio.giroEmpresarialId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.datosDelNegocio.actividadId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.datosDelNegocio.numeroSucursales",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.datosDelNegocio.aniosExperienciaSector",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.datosDelNegocio.fechaAltaShcp",
          ""
        );
      }
    }
  }, []);

  useEffect(() => {
    if (idTipoPersonaPA !== null) {
      formik.setFieldValue(
        "principalAccionista.generales.tipoPersonaId",
        idTipoPersonaPA
      );
    }
  }, [idTipoPersonaPA]);

  return (
    <>
      <form name="form-pa" onSubmit={formik.handleSubmit}>
        <div>
          <h2 className="f-s-3 text-center">Información general</h2>
          <FormLayout>
            <InputWithError
              name="principalAccionista.generales.nombre"
              label="Nombre *"
              value={
                formik.values?.principalAccionista?.generales?.nombre || ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.nombre
                ) {
                  delete newErrors?.principalAccionista?.generales?.nombre;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.generales.nombre",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_NOMBRE, e.target.value);
              }}
              InputProps={{ maxLength: 30 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.nombre
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.nombre
              }
              disabled
            />
            <InputWithError
              label="Apellido paterno *"
              name="principalAccionista.generales.apellidoPaterno"
              value={
                formik.values?.principalAccionista?.generales
                  ?.apellidoPaterno || ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.apellidoPaterno
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.apellidoPaterno;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.generales.apellidoPaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APP, e.target.value);
              }}
              InputProps={{ maxLength: 20 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.apellidoPaterno
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.apellidoPaterno
              }
              disabled
            />
            <InputWithError
              label="Apellido materno"
              name="principalAccionista.generales.apellidoMaterno"
              value={
                formik.values?.principalAccionista?.generales
                  ?.apellidoMaterno || ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.apellidoMaterno
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.apellidoMaterno;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.generales.apellidoMaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APM, e.target.value);
              }}
              InputProps={{ maxLength: 20 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.apellidoMaterno
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.apellidoMaterno
              }
              disabled
            />
            <InputWithError
              name="principalAccionista.generales.fechaNacimiento"
              type="date"
              label="Fecha de nacimiento *"
              value={startDateFechaNacimiento}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.fechaNacimiento
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.fechaNacimiento;
                  formik.setErrors(newErrors);
                }

                if (validDate(e.target.value)) {
                  let array = e.target.value.split("-").reverse().join("-");
                  formik.setFieldValue(
                    "principalAccionista.generales.fechaNacimiento",
                    array
                  );
                  setStartDateFechaNacimiento(e.target.value);
                  generarRfcCurp(ID_CALCULO_RFC_FECHANAC, array);
                }
              }}
              InputProps={{
                max: fechaMaxMayorEdadYYYMMDD(),
                min: fechaMinEdadYYYMMDD(),
              }}
              InputLabelProps={{ shrink: true }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.fechaNacimiento
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.fechaNacimiento
              }
            />
            <SelectWithError
              name="principalAccionista.generales.paisNacimientoId"
              label="País de nacimiento *"
              value={{
                label: formik?.values?.dataObj?.paisNacimientoObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.paisNacimientoObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.paisNacimientoId
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.paisNacimientoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.generales.paisNacimientoId",
                  e.id
                );
                formik.setFieldValue("dataObj.paisNacimientoObj", [e]);
              }}
              options={axiosData?.generales?.paises?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.paisNacimientoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.paisNacimientoId
              }
            />
            {formik.values?.principalAccionista?.generales?.paisNacimientoId !== "" &&
              parseInt(
                formik.values?.principalAccionista?.generales?.paisNacimientoId) === ID_PAIS_MEXICO && (
              <SelectWithError
                label="Estado de nacimiento *"
                name="principalAccionista.generales.estadoNacimientoId"
                value={{
                  label:
                    formik?.values?.dataObj?.estadoNacimientoObj?.[0]?.nombre,
                  value:
                    formik?.values?.dataObj?.estadoNacimientoObj?.[0]?.nombre,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.generales !== undefined &&
                    newErrors?.principalAccionista?.generales?.estadoNacimientoId
                  ) {
                    delete newErrors?.principalAccionista?.generales
                      ?.estadoNacimientoId;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue(
                    "principalAccionista.generales.estadoNacimientoId",
                    e.id
                  );
                  formik.setFieldValue("dataObj.estadoNacimientoObj", [e]);
                  generarRfcCurp(ID_CALCULO_RFC_ESTADONAC, e.id);
                }}
                options={axiosData?.generales?.estados?.map((item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                })}
                showErrorMessage={
                  formik.errors?.principalAccionista?.generales
                    ?.estadoNacimientoId
                }
                errorMessage={
                  formik.errors?.principalAccionista?.generales
                    ?.estadoNacimientoId
                }
              />
            )}
            
            <SelectWithError
              label="Género *"
              name="principalAccionista.generales.generoId"
              value={{
                label: formik?.values?.principalAccionista?.generales?.generoId
                  ? formik?.values?.principalAccionista?.generales?.generoId ===
                    2
                    ? "Femenino"
                    : "Masculino"
                  : "",
                value: formik?.values?.principalAccionista?.generales?.generoId
                  ? formik?.values?.principalAccionista?.generales?.generoId ===
                    2
                    ? "Femenino"
                    : "Masculino"
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.generoId
                ) {
                  delete newErrors?.principalAccionista?.generales?.generoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.generales.generoId",
                  e.id
                );
                generarRfcCurp(ID_CALCULO_RFC_GENERO, e.id);
              }}
              options={axiosData?.generales?.generoOpciones?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.generoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.generoId
              }
            />
            <InputWithError
              label="RFC *"
              name="principalAccionista.generales.rfc"
              value={formik.values?.principalAccionista?.generales?.rfc || ""}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.rfc
                ) {
                  delete newErrors?.principalAccionista?.generales?.rfc;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "principalAccionista.generales.rfc",
                    newValue
                  );
                }
              }}
              InputProps={{ maxLength: 13 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.rfc
              }
              errorMessage={formik.errors?.principalAccionista?.generales?.rfc}
            />
            <InputWithError
              label="CURP *"
              name="principalAccionista.generales.curp"
              value={formik.values?.principalAccionista?.generales?.curp || ""}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.curp
                ) {
                  delete newErrors?.principalAccionista?.generales?.curp;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "principalAccionista.generales.curp",
                    newValue
                  );
                  formik.setFieldValue(
                    "principalAccionista.generales.curp",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 18 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.curp
              }
              errorMessage={formik.errors?.principalAccionista?.generales?.curp}
            />
            <InputWithError
              label="Número de identificación oficial *"
              name="principalAccionista.generales.folioVerificacionId"
              value={
                formik.values?.principalAccionista?.generales
                  ?.folioVerificacionId || ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.folioVerificacionId
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.folioVerificacionId;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.generales.folioVerificacionId",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 18 }}
              tooltip="Captura el número de folio de tu identificación, si es INE lo encontrarás en la parte posterior de la misma, después de las letras IDMEX"
              showErrorMessage={
                formik.errors?.principalAccionista?.generales
                  ?.folioVerificacionId
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales
                  ?.folioVerificacionId
              }
            />
            <SelectWithError
              name="principalAccionista.generales.nacionalidadId"
              value={{
                label: formik?.values?.dataObj?.nacionalidadObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.nacionalidadObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.nacionalidadId
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.nacionalidadId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.generales.nacionalidadId",
                  e.id
                );
                formik.setFieldValue("dataObj.nacionalidadObj", [e]);
              }}
              label="Nacionalidad *"
              options={axiosData?.generales?.nacionalidades?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.nacionalidadId
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.nacionalidadId
              }
            />
            {parseInt(
              formik.values?.principalAccionista?.generales?.nacionalidadId
            ) !== NACIONALIDAD_MEXICAN0 && (
              <InputWithError
                label="Calidad migratoria *"
                name="principalAccionista.generales.calidadMigratoria"
                value={
                  formik.values?.principalAccionista?.generales
                    ?.calidadMigratoria || ""
                }
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.generales !== undefined &&
                    newErrors?.principalAccionista?.generales?.calidadMigratoria
                  ) {
                    delete newErrors?.principalAccionista?.generales
                      ?.calidadMigratoria;
                    formik.setErrors(newErrors);
                  }

                  if (validarTextoConEnies(e.target.value, "text")) {
                    formik.setFieldValue(
                      "principalAccionista.generales.calidadMigratoria",
                      e.target.value
                    );
                  }
                }}
                InputProps={{ maxLength: 180 }}
                showErrorMessage={
                  formik.errors?.principalAccionista?.generales
                    ?.calidadMigratoria
                }
                errorMessage={
                  formik.errors?.principalAccionista?.generales
                    ?.calidadMigratoria
                }
              />
            )}
            <InputWithError
              label="Correo electrónico *"
              name="principalAccionista.generales.correoElectronico"
              value={
                formik.values?.principalAccionista?.generales
                  ?.correoElectronico || ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.correoElectronico
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.correoElectronico;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.generales.correoElectronico",
                  e.target.value
                );
              }}
              InputProps={{ maxLength: 50 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.correoElectronico
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.correoElectronico
              }
            />
            <InputWithError
              label="Teléfono *"
              name="principalAccionista.generales.telefono"
              value={
                formik.values?.principalAccionista?.generales?.telefono || ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.telefono
                ) {
                  delete newErrors?.principalAccionista?.generales?.telefono;
                  formik.setErrors(newErrors);
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.principalAccionista?.generales?.telefono
                );
                formik.setFieldValue(
                  "principalAccionista.generales.telefono",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.telefono
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.telefono
              }
            />
            <InputWithError
              label="Celular *"
              name="principalAccionista.generales.celular"
              value={
                formik.values?.principalAccionista?.generales?.celular || ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.celular
                ) {
                  delete newErrors?.principalAccionista?.generales?.celular;
                  formik.setErrors(newErrors);
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.principalAccionista?.generales?.celular
                );
                formik.setFieldValue(
                  "principalAccionista.generales.celular",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.celular
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.celular
              }
              disabled
            />
            <SelectWithError
              name="principalAccionista.generales.estadoCivilId"
              label="Estado civil *"
              value={{
                label: formik?.values?.dataObj?.estadoCivilObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.estadoCivilObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.estadoCivilId
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.estadoCivilId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.generales.estadoCivilId",
                  e.id
                );
                formik.setFieldValue("dataObj.estadoCivilObj", [e]);
                setModeloPm({
                  ...modeloPm,
                  principalAccionista: {
                    ...modeloPm.principalAccionista,
                    generales: {
                      ...modeloPm.principalAccionista.generales,
                      estadoCivilId: e.id,
                    },
                  },
                });
              }}
              options={axiosData?.generales?.estadoCivilOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.estadoCivilId
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.estadoCivilId
              }
            />
            <SelectWithError
              name="principalAccionista.generales.dependientesEconomicosMenores23"
              value={{
                label:
                  formik?.values?.dataObj
                    ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion,
                value:
                  formik?.values?.dataObj
                    ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales
                    ?.dependientesEconomicosMenores23
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.dependientesEconomicosMenores23;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.generales.dependientesEconomicosMenores23",
                  e.id
                );
                formik.setFieldValue(
                  "dataObj.dependientesEconomicosMenores23Obj",
                  [e]
                );
                setTotalDependientesMenores23(e.descripcion);
              }}
              label="Cantidad de dependientes menores de 23 años *"
              options={axiosData?.generales?.dependientesMenores23Anios?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.descripcion,
                    value: item.descripcion,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales
                  ?.dependientesEconomicosMenores23
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales
                  ?.dependientesEconomicosMenores23
              }
            />
            <SelectWithError
              name="principalAccionista.generales.dependientesEconomicos"
              value={{
                label:
                  formik?.values?.dataObj?.dependientesEconomicosObj?.[0]
                    ?.descripcion,
                value:
                  formik?.values?.dataObj?.dependientesEconomicosObj?.[0]
                    ?.descripcion,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales
                    ?.dependientesEconomicos
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.dependientesEconomicos;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.generales.dependientesEconomicos",
                  e.id
                );
                formik.setFieldValue("dataObj.dependientesEconomicosObj", [e]);
                setTotalDependientes(e.descripcion);
              }}
              label="Cantidad total de dependientes *"
              options={axiosData?.generales?.dependientes?.map((item) => {
                return {
                  ...item,
                  label: item.descripcion,
                  value: item.descripcion,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales
                  ?.dependientesEconomicos
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales
                  ?.dependientesEconomicos
              }
            />
            <SelectWithError
              name="principalAccionista.generales.nivelEducativoId"
              label="Nivel académico *"
              value={{
                label: formik?.values?.dataObj?.nivelEducativoObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.nivelEducativoObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.nivelEducativoId
                ) {
                  delete newErrors?.principalAccionista?.generales
                    ?.nivelEducativoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.generales.nivelEducativoId",
                  e.id
                );
                formik.setFieldValue("dataObj.nivelEducativoObj", [e]);
              }}
              options={axiosData?.generales?.nivelEducativoOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.nivelEducativoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.nivelEducativoId
              }
            />
            <InputWithError
              label="Ocupación o profesión *"
              name="principalAccionista.generales.profesion"
              value={
                formik.values?.principalAccionista?.generales?.profesion || ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.generales !== undefined &&
                  newErrors?.principalAccionista?.generales?.profesion
                ) {
                  delete newErrors?.principalAccionista?.generales?.profesion;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.generales.profesion",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.generales?.profesion
              }
              errorMessage={
                formik.errors?.principalAccionista?.generales?.profesion
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="principalAccionista.generales.esAccionistaOtraEmpresa"
                  state=""
                  checked={
                    formik.values?.principalAccionista?.generales
                      ?.esAccionistaOtraEmpresa
                  }
                  onChange={() =>
                    formik.setFieldValue(
                      "principalAccionista.generales.esAccionistaOtraEmpresa",
                      !formik.values?.principalAccionista?.generales
                        ?.esAccionistaOtraEmpresa
                    )
                  }
                  typeOfCheck="normal"
                  id="accionistaEmpresa"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  className="form-check-label"
                  htmlFor="accionistaEmpresa"
                >
                  El Obligado es Accionista u Obligado de otra empresa
                </label>
              </div>
              {formik.values?.principalAccionista?.generales
                ?.esAccionistaOtraEmpresa && (
                <>
                  <InputWithError
                    label="Nombre de la empresa o PFAE *"
                    name="principalAccionista.generales.nombreOtraEmpresa"
                    state=""
                    value={
                      formik.values?.principalAccionista?.generales
                        ?.nombreOtraEmpresa
                        ? formik.values?.principalAccionista?.generales
                            ?.nombreOtraEmpresa
                        : ""
                    }
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista?.generales !==
                          undefined &&
                        newErrors?.principalAccionista?.generales
                          ?.nombreOtraEmpresa
                      ) {
                        delete newErrors?.principalAccionista?.generales
                          ?.nombreOtraEmpresa;
                        formik.setErrors(newErrors);
                      }

                      if (validarTextoConEnies(e.target.value, "text")) {
                        formik.setFieldValue(
                          "principalAccionista.generales.nombreOtraEmpresa",
                          e.target.value
                        );
                      }
                    }}
                    style={{ width: "100%" }}
                    type="text"
                    InputProps={{ maxLength: 180 }}
                    showErrorMessage={
                      !isEmpty(formik.errors) &&
                      formik.errors?.principalAccionista?.generales
                        ?.nombreOtraEmpresa
                    }
                    errorMessage={
                      !isEmpty(formik.errors)
                        ? formik.errors?.principalAccionista?.generales
                            ?.nombreOtraEmpresa
                        : null
                    }
                  />
                </>
              )}
              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="principalAccionista.generales.esObligadoSolidarioOtraEmpresaPfae"
                  state=""
                  checked={
                    formik.values?.principalAccionista?.generales
                      ?.esObligadoSolidarioOtraEmpresaPfae
                  }
                  onChange={() =>
                    formik.setFieldValue(
                      "principalAccionista.generales.esObligadoSolidarioOtraEmpresaPfae",
                      !formik.values?.principalAccionista?.generales
                        ?.esObligadoSolidarioOtraEmpresaPfae
                    )
                  }
                  typeOfCheck="normal"
                  id="fiadorPFAE"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  className="form-check-label"
                  htmlFor="fiadorPFAE"
                >
                  Es Obligado o Fiador de otra empresa o PFAE
                </label>
              </div>
              {formik.values?.principalAccionista?.generales
                ?.esObligadoSolidarioOtraEmpresaPfae && (
                <>
                  <InputWithError
                    label="Nombre de la empresa o PFAE *"
                    name="principalAccionista.generales.nombreOtraEmpresaPfae"
                    state=""
                    value={
                      formik.values?.principalAccionista?.generales
                        ?.nombreOtraEmpresaPfae
                        ? formik.values?.principalAccionista?.generales
                            ?.nombreOtraEmpresaPfae
                        : ""
                    }
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista?.generales !==
                          undefined &&
                        newErrors?.principalAccionista?.generales
                          ?.nombreOtraEmpresaPfae
                      ) {
                        delete newErrors?.principalAccionista?.generales
                          ?.nombreOtraEmpresaPfae;
                        formik.setErrors(newErrors);
                      }

                      if (validarTextoConEnies(e.target.value, "text")) {
                        formik.setFieldValue(
                          "principalAccionista.generales.nombreOtraEmpresaPfae",
                          e.target.value
                        );
                      }
                    }}
                    style={{ width: "100%" }}
                    type="text"
                    InputProps={{ maxLength: 180 }}
                    showErrorMessage={
                      !isEmpty(formik.errors) &&
                      formik.errors?.principalAccionista?.generales
                        ?.nombreOtraEmpresaPfae
                    }
                    errorMessage={
                      !isEmpty(formik.errors)
                        ? formik.errors?.principalAccionista?.generales
                            ?.nombreOtraEmpresaPfae
                        : null
                    }
                  />
                </>
              )}
            </div>
            <h2
              style={{ margin: "2rem 0 1rem 0" }}
              className="f-s-3 text-center"
            >
              Información de domicilio
            </h2>
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.calle
              }
              errorMessage={
                formik.errors?.principalAccionista?.direccion?.calle
              }
              label="Calle *"
              name="principalAccionista.direccion.calle"
              value={formik.values?.principalAccionista?.direccion?.calle || ""}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.direccion !== undefined &&
                  newErrors?.principalAccionista?.direccion?.calle
                ) {
                  delete newErrors?.principalAccionista?.direccion?.calle;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.direccion.calle",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 24 }}
            />
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.numeroExterior
              }
              errorMessage={
                formik.errors?.principalAccionista?.direccion?.numeroExterior
              }
              label="Número exterior *"
              name="principalAccionista.direccion.numeroExterior"
              value={
                formik.values?.principalAccionista?.direccion?.numeroExterior ||
                ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.direccion !== undefined &&
                  newErrors?.principalAccionista?.direccion?.numeroExterior
                ) {
                  delete newErrors?.principalAccionista?.direccion
                    ?.numeroExterior;
                  formik.setErrors(newErrors);
                }

                if (notSpecialCaractersConGuion(e.target.value)) {
                  formik.setFieldValue(
                    "principalAccionista.direccion.numeroExterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 7 }}
            />
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.numeroInterior
              }
              errorMessage={
                formik.errors?.principalAccionista?.direccion?.numeroInterior
              }
              name="principalAccionista.direccion.numeroInterior"
              label="Número interior"
              value={
                formik.values?.principalAccionista?.direccion?.numeroInterior ||
                ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.direccion !== undefined &&
                  newErrors?.principalAccionista?.direccion?.numeroInterior
                ) {
                  delete newErrors?.principalAccionista?.direccion
                    ?.numeroInterior;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.direccion.numeroInterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu “Número exterior” separados por un guion Ejemplo: 318-123B"
              required={
                formik.values.principalAccionista.direccion.tipoViviendaId ===
                  parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.principalAccionista.direccion.tipoViviendaId ===
                  parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
            />
            <InputWithError
              required={
                formik.values.principalAccionista.direccion.tipoViviendaId ===
                  parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.principalAccionista.direccion.tipoViviendaId ===
                  parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.piso
              }
              errorMessage={formik.errors?.principalAccionista?.direccion?.piso}
              label="Piso"
              name="principalAccionista.direccion.piso"
              value={formik.values?.principalAccionista?.direccion?.piso || ""}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.direccion !== undefined &&
                  newErrors?.principalAccionista?.direccion?.piso
                ) {
                  delete newErrors?.principalAccionista?.direccion?.piso;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.direccion.piso",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
            />
            <InputWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.codigoPostal
              }
              errorMessage={
                formik.errors?.principalAccionista?.direccion?.codigoPostal
              }
              label="CP *"
              name="principalAccionista.direccion.codigoPostal"
              value={
                formik.values?.principalAccionista?.direccion?.codigoPostal ||
                ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.direccion !== undefined &&
                  newErrors?.principalAccionista?.direccion?.codigoPostal
                ) {
                  delete newErrors?.principalAccionista?.direccion
                    ?.codigoPostal;
                  formik.setErrors(newErrors);
                }

                if (
                  e.target.value.length <= 5 &&
                  validateValue(e.target.value, "number")
                ) {
                  formik.setFieldValue(
                    "principalAccionista.direccion.codigoPostal",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length === 5) {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.direccion !== undefined
                  ) {
                    if (newErrors?.principalAccionista?.direccion?.colonia) {
                      delete newErrors?.principalAccionista?.direccion?.colonia;
                    }
                    if (
                      newErrors?.principalAccionista?.direccion?.municipioId
                    ) {
                      delete newErrors?.principalAccionista?.direccion
                        ?.municipioId;
                    }
                    if (newErrors?.principalAccionista?.direccion?.estadoId) {
                      delete newErrors?.principalAccionista?.direccion
                        ?.estadoId;
                    }
                    formik.setErrors(newErrors);
                  }
                  obtenerDireccionByCp(e.target.value);
                } else {
                  limpiarDireccionByCp();
                }
              }}
              InputProps={{ maxLength: 5 }}
            />
            <SelectWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.colonia
              }
              errorMessage={
                formik.errors?.principalAccionista?.direccion?.colonia
              }
              label="Colonia *"
              name="principalAccionista.direccion.colonia"
              value={{
                label: formik?.values?.principalAccionista?.direccion?.colonia,

                value: formik?.values?.principalAccionista?.direccion?.colonia,
              }}
              onChange={(e) => {
                formik.setFieldValue(
                  "principalAccionista.direccion.colonia",
                  e.label
                );
              }}
              options={coloniasCP?.map((item) => {
                return { ...item, label: item, value: item };
              })}
            />
            <SelectWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.municipioId
              }
              errorMessage={
                formik.errors?.principalAccionista?.direccion?.municipioId
              }
              label="Alcaldía / Municipio *"
              name="principalAccionista.direccion.municipioId"
              value={{
                label: formik?.values?.dataObj?.municipioObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.municipioObj?.[0]?.nombre,
              }}
              isDisabled={true}
              onChange={(e) => {
                formik.setFieldValue(
                  "principalAccionista.direccion.municipioId",
                  e.id
                );
                formik.setFieldValue("dataObj.municipioObj", [e]);
              }}
              options={axiosData?.generales?.municipios?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
            />
            <SelectWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.estadoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.direccion?.estadoId
              }
              name="principalAccionista.direccion.estadoId"
              value={{
                label: formik?.values?.dataObj?.estadoObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.estadoObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                formik.setFieldValue(
                  "principalAccionista.direccion.estadoId",
                  e.id
                );
                formik.setFieldValue("dataObj.estadoObj", [e]);
              }}
              label="Estado *"
              isDisabled={true}
              options={axiosData?.generales?.estados?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
            />
            <SelectWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.tipoDomicilioId
              }
              errorMessage={
                formik.errors?.principalAccionista?.direccion?.tipoDomicilioId
              }
              name="principalAccionista.direccion.tipoDomicilioId"
              value={{
                label: formik?.values?.dataObj?.tipoDomicilioObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.tipoDomicilioObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.direccion !== undefined &&
                  newErrors?.principalAccionista?.direccion?.tipoDomicilioId
                ) {
                  delete newErrors?.principalAccionista?.direccion
                    ?.tipoDomicilioId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.direccion.tipoDomicilioId",
                  e.id
                );
                formik.setFieldValue("dataObj.tipoDomicilioObj", [e]);
              }}
              label="Tipo de domicilio *"
              options={axiosData?.domicilio?.tipoDeDomicilioOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
            />
            <SelectWithError
              showErrorMessage={
                formik.errors?.principalAccionista?.direccion?.tipoViviendaId
              }
              errorMessage={
                formik.errors?.principalAccionista?.direccion?.tipoViviendaId
              }
              label="Tipo de vivienda *"
              name="principalAccionista.direccion.tipoViviendaId"
              value={{
                label: formik?.values?.dataObj?.tipoViviendaObj?.[0]?.nombre,
                value: formik?.values?.dataObj?.tipoViviendaObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.direccion !== undefined &&
                  newErrors?.principalAccionista?.direccion?.tipoViviendaId
                ) {
                  delete newErrors?.principalAccionista?.direccion
                    ?.tipoViviendaId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.direccion.tipoViviendaId",
                  e.id
                );
                formik.setFieldValue("dataObj.tipoViviendaObj", [e]);
              }}
              options={axiosData?.domicilio?.tipoDeViviendaOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
            />
            <div
              style={{
                width: innerWidth < 700 ? "100%" : "55%",
                minWidth: "290px",
                maxWidth: "425px",
              }}
            >
              <span>Antigüedad en el domicilio actual *</span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "10px",
                  flexDirection: innerWidth < 700 ? "column" : "row",
                }}
              >
                <div
                  style={{ minWidth: innerWidth > 700 && "45%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.principalAccionista?.direccion
                    ?.tiempoViviendaAnios ? (
                    <span className="select-label-no-null">Años *</span>
                  ) : (
                    <span className="select-label">Años *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    placeholder="Años *"
                    name="principalAccionista.direccion.tiempoViviendaAnios"
                    value={{
                      label:
                        formik?.values?.principalAccionista?.direccion
                          ?.tiempoViviendaAnios,
                      value:
                        formik?.values?.principalAccionista?.direccion
                          ?.tiempoViviendaAnios,
                    }}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "principalAccionista.direccion.tiempoViviendaAnios",
                        e.label
                      )
                    }
                    required
                    options={aniosDomicilio?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                  />
                </div>
                <div
                  style={{ minWidth: innerWidth > 700 && "45%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.principalAccionista?.direccion
                    ?.tiempoViviendaMeses ? (
                    <span className="select-label-no-null">Meses *</span>
                  ) : (
                    <span className="select-label">Meses *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="principalAccionista.direccion.tiempoViviendaMeses"
                    value={{
                      label:
                        formik?.values?.principalAccionista?.direccion
                          ?.tiempoViviendaMeses,
                      value:
                        formik?.values?.principalAccionista?.direccion
                          ?.tiempoViviendaMeses,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista?.direccion !==
                          undefined &&
                        newErrors?.principalAccionista?.direccion
                          ?.tiempoViviendaMeses
                      ) {
                        delete newErrors?.principalAccionista?.direccion
                          ?.tiempoViviendaMeses;
                        formik.setErrors(newErrors);
                      }

                      formik.setFieldValue(
                        "principalAccionista.direccion.tiempoViviendaMeses",
                        e.label
                      );
                    }}
                    placeholder="Meses *"
                    options={mesesDomicilo?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                  />
                </div>
              </div>
              {formik?.errors?.principalAccionista?.direccion
                ?.tiempoViviendaMeses ? (
                <div>
                  <span className="text__center text_red">
                    {
                      formik.errors?.principalAccionista?.direccion
                        ?.tiempoViviendaMeses
                    }
                  </span>
                </div>
              ) : null}
            </div>
            <div
              style={{
                width: innerWidth < 700 ? "100%" : "55%",
                minWidth: "290px",
                maxWidth: "425px",
              }}
            >
              <span>Antigüedad en el domicilio anterior *</span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "10px",
                  flexDirection: innerWidth < 700 ? "column" : "row",
                }}
              >
                <div
                  style={{ minWidth: innerWidth > 700 && "45%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.principalAccionista?.direccion
                    ?.tiempoViviendaAntAnios ? (
                    <span className="select-label-no-null">Años *</span>
                  ) : (
                    <span className="select-label">Años *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="principalAccionista.direccion.tiempoViviendaAntAnios"
                    value={{
                      label:
                        formik?.values?.principalAccionista?.direccion
                          ?.tiempoViviendaAntAnios,
                      value:
                        formik?.values?.principalAccionista?.direccion
                          ?.tiempoViviendaAntAnios,
                    }}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "principalAccionista.direccion.tiempoViviendaAntAnios",
                        e.label
                      )
                    }
                    placeholder="Años *"
                    options={aniosDomicilio?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                  />
                </div>
                <div
                  style={{ minWidth: innerWidth > 700 && "45%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.principalAccionista?.direccion
                    ?.tiempoViviendaAntMeses ? (
                    <span className="select-label-no-null">Meses *</span>
                  ) : (
                    <span className="select-label">Meses *</span>
                  )}

                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="principalAccionista.direccion.tiempoViviendaAntMeses"
                    value={{
                      label:
                        formik?.values?.principalAccionista?.direccion
                          ?.tiempoViviendaAntMeses,
                      value:
                        formik?.values?.principalAccionista?.direccion
                          ?.tiempoViviendaAntMeses,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista?.direccion !==
                          undefined &&
                        newErrors?.principalAccionista?.direccion
                          ?.tiempoViviendaAntMeses
                      ) {
                        delete newErrors?.principalAccionista?.direccion
                          ?.tiempoViviendaAntMeses;
                        formik.setErrors(newErrors);
                      }

                      formik.setFieldValue(
                        "principalAccionista.direccion.tiempoViviendaAntMeses",
                        e.label
                      );
                    }}
                    placeholder="Meses *"
                    options={mesesDomicilo?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                  />
                </div>
              </div>
              {formik?.errors?.principalAccionista?.direccion
                ?.tiempoViviendaAntMeses ? (
                <div>
                  <span className="text__center text_red">
                    {
                      formik.errors?.principalAccionista?.direccion
                        ?.tiempoViviendaAntMeses
                    }
                  </span>
                </div>
              ) : null}
            </div>
          </FormLayout>
          {idTipoPersonaPA === ID_PFAE && (
            <div
              style={{
                display: "grid",
                width: "100%",
                placeItems: "center",
                gap: "1rem 0",
              }}
            >
              <h2
                style={{ margin: "2rem 0 1rem 0" }}
                className="f-s-3 text-center"
              >
                Información del negocio
              </h2>
              <SelectWithError
                showErrorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.importaExportaId
                }
                errorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.importaExportaId
                }
                name="principalAccionista.datosDelNegocio.importaExportaId"
                value={{
                  label:
                    formik?.values?.dataObj?.importaExportaObj?.[0]?.nombre,
                  value:
                    formik?.values?.dataObj?.importaExportaObj?.[0]?.nombre,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio !==
                      undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio
                      ?.importaExportaId
                  ) {
                    delete newErrors?.principalAccionista?.datosDelNegocio
                      ?.importaExportaId;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue(
                    "principalAccionista.datosDelNegocio.importaExportaId",
                    e.id
                  );
                  formik.setFieldValue("dataObj.importaExportaObj", [e]);
                }}
                label="¿Realizas actividades de importación o exportación? *"
                options={axiosData?.domicilioFiscal?.importaExportaOpciones?.map(
                  (item) => {
                    return {
                      ...item,
                      label: item.nombre,
                      value: item.nombre,
                    };
                  }
                )}
              />
              <SelectWithErrorTooltipCompuesto
                showErrorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.giroEmpresarialId
                }
                errorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.giroEmpresarialId
                }
                name="principalAccionista.datosDelNegocio.giroEmpresarialId"
                value={{
                  label:
                    formik?.values?.dataObj?.giroEmpresarialObj?.[0]?.nombre,
                  value:
                    formik?.values?.dataObj?.giroEmpresarialObj?.[0]?.nombre,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio !==
                      undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio
                      ?.giroEmpresarialId
                  ) {
                    delete newErrors?.principalAccionista?.datosDelNegocio
                      ?.giroEmpresarialId;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue(
                    "principalAccionista.datosDelNegocio.giroEmpresarialId",
                    e.id
                  );
                  formik.setFieldValue("dataObj.giroEmpresarialObj", [e]);
                }}
                label="Giro *"
                options={axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.map(
                  (item) => {
                    return {
                      ...item,
                      label: item.nombre,
                      value: item.nombre,
                    };
                  }
                )}
                tooltipA="Comercio: Supermercados, tiendas, farmacias, ferreterías, etc."
                tooltipB="Industria: Manufacturera, constructoras, textil, alimenticia, etc."
                tooltipC="Servicios: Escuelas, hospitales, despachos, transporte, médicos, dentista, restaurantes, etc."
              />
              <SelectWithError
                showErrorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio?.sectorId
                }
                errorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio?.sectorId
                }
                name="principalAccionista.datosDelNegocio.sectorId"
                value={{
                  label: formik?.values?.dataObj?.sectorObj?.[0]?.nombre,
                  value: formik?.values?.dataObj?.sectorObj?.[0]?.nombre,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio !==
                      undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio?.sectorId
                  ) {
                    delete newErrors?.principalAccionista?.datosDelNegocio
                      ?.sectorId;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue(
                    "principalAccionista.datosDelNegocio.sectorId",
                    e.id
                  );
                  formik.setFieldValue("dataObj.sectorObj", [e]);
                }}
                label="Sector *"
                options={axiosData?.domicilioFiscal?.sectorOpciones?.map(
                  (item) => {
                    return {
                      ...item,
                      label: item.nombre,
                      value: item.nombre,
                    };
                  }
                )}
              />
              <SelectWithErrorActividad
                menuPlacement="top"
                showErrorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.actividadId
                }
                errorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.actividadId
                }
                name="principalAccionista.datosDelNegocio.actividadId"
                value={{
                  label:
                    formik?.values?.dataObj?.actividadObj?.[0]?.detalleClave,
                  value:
                    formik?.values?.dataObj?.actividadObj?.[0]?.detalleClave,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio !==
                      undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio?.actividadId
                  ) {
                    delete newErrors?.principalAccionista?.datosDelNegocio
                      ?.actividadId;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue(
                    "principalAccionista.datosDelNegocio.actividadId",
                    e.id
                  );
                  formik.setFieldValue("dataObj.actividadObj", [e]);
                }}
                label="Actividad *"
                options={axiosData?.domicilioFiscal?.actividadOpciones?.map(
                  (item) => {
                    return {
                      ...item,
                      label: item.detalleClave,
                      value: item.detalleClave,
                    };
                  }
                )}
                tooltip="Selecciona la actividad que mas se asemeje a tu alta en hacienda. En caso de que tengas varias actividades, selecciona la preponderante."
              />
              <InputWithError
                label="Fecha de alta en SHCP *"
                name="principalAccionista.datosDelNegocio.fechaAltaShcp"
                locale="es"
                type="date"
                InputProps={{ max: fechaActualYYYMMDD() }}
                InputLabelProps={{ shrink: true }}
                value={startDateFechaSHCP}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio !==
                      undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio
                      ?.fechaAltaShcp
                  ) {
                    delete newErrors?.principalAccionista?.datosDelNegocio
                      ?.fechaAltaShcp;
                    formik.setErrors(newErrors);
                  }

                  let array = e.target.value.split("-").reverse().join("-");
                  formik.setFieldValue(
                    "principalAccionista.datosDelNegocio.fechaAltaShcp",
                    array
                  );
                  setStartDateFechaSHCP(e.target.value);
                }}
                showErrorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.fechaAltaShcp
                }
                errorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.fechaAltaShcp
                }
              />
              <InputWithError
                showErrorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.numeroSucursales
                }
                errorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.numeroSucursales
                }
                name="principalAccionista.datosDelNegocio.numeroSucursales"
                value={
                  formik.values?.principalAccionista?.datosDelNegocio
                    ?.numeroSucursales || ""
                }
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio !==
                      undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio
                      ?.numeroSucursales
                  ) {
                    delete newErrors?.principalAccionista?.datosDelNegocio
                      ?.numeroSucursales;
                    formik.setErrors(newErrors);
                  }

                  if (validateValue(e.target.value, "number")) {
                    formik.setFieldValue(
                      "principalAccionista.datosDelNegocio.numeroSucursales",
                      e.target.value
                    );
                  }
                }}
                InputProps={{maxLength: 15}}
                label="Número de sucursales *"
              />
              <SelectWithError
                menuPlacement="top"
                showErrorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.aniosExperienciaSector
                }
                errorMessage={
                  formik.errors?.principalAccionista?.datosDelNegocio
                    ?.aniosExperienciaSector
                }
                label="Años de experiencia en el sector *"
                name="principalAccionista.datosDelNegocio.aniosExperienciaSector"
                value={{
                  label:
                    formik?.values?.principalAccionista?.datosDelNegocio
                      ?.aniosExperienciaSector,
                  value:
                    formik?.values?.principalAccionista?.datosDelNegocio
                      ?.aniosExperienciaSector,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio !==
                      undefined &&
                    newErrors?.principalAccionista?.datosDelNegocio
                      ?.aniosExperienciaSector
                  ) {
                    delete newErrors?.principalAccionista?.datosDelNegocio
                      ?.aniosExperienciaSector;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue(
                    "principalAccionista.datosDelNegocio.aniosExperienciaSector",
                    e.label
                  );
                }}
                options={anios?.map((item, index) => {
                  return {
                    ...item,
                    label: index + 1,
                    value: index + 1,
                  };
                })}
              />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center gap-2 my-3">
          <SecondaryButton
            onClick={(e) => {
              e.preventDefault();
              handleRegresarSeccionPyme(SECCION_PRINCIPAL_ACCIONISTA);
            }}
          >
            Atrás
          </SecondaryButton>
          <PrimaryButton type="submit" disabled={formik.isSubmitting}>
            Siguiente
          </PrimaryButton>
        </div>
      </form>
    </>
  );
};

export default DatosPrincipalAccionistaPM;

function initialValues(modeloPm, idTipoPersonaPA, axiosData) {
  if (
    modeloPm?.principalAccionista?.idPrincipalAccionista !== null &&
    modeloPm.principalAccionista.generales.tipoPersonaId !== null
  ) {
    return {
      idCliente: modeloPm.idCliente,
      idSolicitud: modeloPm.idSolicitud,
      principalAccionista: {
        idPrincipalAccionista:
          modeloPm?.principalAccionista?.idPrincipalAccionista,
        generales: modeloPm?.principalAccionista?.generales,
        direccion: modeloPm?.principalAccionista?.direccion,
        datosDelNegocio: modeloPm?.principalAccionista?.datosDelNegocio,
        banorteDatosDelCliente:
          modeloPm?.principalAccionista?.banorteDatosDelCliente,
        conyuge: modeloPm?.principalAccionista?.conyuge,
      },
      dataObj: {
        paisNacimientoObj: modeloPm?.principalAccionista?.generales
          ?.paisNacimientoId
          ? axiosData?.generales?.paises?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.paisNacimientoId
            )
          : "",
        estadoNacimientoObj: modeloPm?.principalAccionista?.generales
          ?.estadoNacimientoId
          ? axiosData?.generales?.estados?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.estadoNacimientoId
            )
          : "",
        nacionalidadObj: modeloPm?.principalAccionista?.generales
          ?.nacionalidadId
          ? axiosData?.generales?.nacionalidades?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.nacionalidadId
            )
          : "",
        estadoCivilObj: modeloPm?.principalAccionista?.generales?.estadoCivilId
          ? axiosData?.generales?.estadoCivilOpciones?.filter(
              (e) =>
                e.id === modeloPm?.principalAccionista?.generales?.estadoCivilId
            )
          : "",
        dependientesEconomicosMenores23Obj: modeloPm?.principalAccionista
          ?.generales?.dependientesEconomicosMenores23
          ? axiosData?.generales?.dependientesMenores23Anios?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales
                  ?.dependientesEconomicosMenores23
            )
          : "",
        dependientesEconomicosObj: modeloPm?.principalAccionista?.generales
          ?.dependientesEconomicos
          ? axiosData?.generales?.dependientes?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.dependientesEconomicos
            )
          : "",
        nivelEducativoObj: modeloPm?.principalAccionista?.generales
          ?.nivelEducativoId
          ? axiosData?.generales?.nivelEducativoOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.nivelEducativoId
            )
          : "",
        municipioObj: modeloPm?.principalAccionista?.direccion?.municipioId
          ? axiosData?.generales?.municipios?.filter(
              (e) =>
                e.id === modeloPm?.principalAccionista?.direccion?.municipioId
            )
          : "",
        estadoObj: modeloPm?.principalAccionista?.direccion?.estadoId
          ? axiosData?.generales?.estados?.filter(
              (e) => e.id === modeloPm?.principalAccionista?.direccion?.estadoId
            )
          : "",
        tipoDomicilioObj: modeloPm?.principalAccionista?.direccion
          ?.tipoDomicilioId
          ? axiosData?.domicilio?.tipoDeDomicilioOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.direccion?.tipoDomicilioId
            )
          : "",
        tipoViviendaObj: modeloPm?.principalAccionista?.direccion
          ?.tipoViviendaId
          ? axiosData?.domicilio?.tipoDeViviendaOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.direccion?.tipoViviendaId
            )
          : "",
        importaExportaObj: modeloPm?.principalAccionista?.datosDelNegocio
          ?.importaExportaId
          ? axiosData?.domicilioFiscal?.importaExportaOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.datosDelNegocio?.importaExportaId
            )
          : "",
        sectorObj: modeloPm?.principalAccionista?.datosDelNegocio?.sectorId
          ? axiosData?.domicilioFiscal?.sectorOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.datosDelNegocio?.sectorId
            )
          : "",
        giroEmpresarialObj: modeloPm?.principalAccionista?.datosDelNegocio
          ?.giroEmpresarialId
          ? axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.datosDelNegocio
                  ?.giroEmpresarialId
            )
          : "",
        actividadObj: modeloPm?.principalAccionista?.datosDelNegocio
          ?.actividadId
          ? axiosData?.domicilioFiscal?.actividadOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.datosDelNegocio?.actividadId
            )
          : "",
      },
    };
  } else {
    return {
      idCliente: modeloPm.idCliente,
      idSolicitud: modeloPm.idSolicitud,
      principalAccionista: {
        idPrincipalAccionista:
          modeloPm.principalAccionista.idPrincipalAccionista,
        generales: {
          tipoPersonaId: idTipoPersonaPA,
          relacionSolicitante: "",
          relacionRamaGobierno: false,
          paisResidenciaFiscal2: false,
          residenciaUsa: false,
          esAccionistaOtraEmpresa: false,
          esObligadoSolidarioOtraEmpresaPfae: false,
          nombre: modeloPm.representanteLegal.nombre,
          apellidoPaterno: modeloPm.representanteLegal.apellidoPaterno,
          apellidoMaterno: modeloPm.representanteLegal.apellidoMaterno,
          fechaNacimiento: "",
          paisNacimientoId: "",
          estadoNacimientoId: "",
          generoId: "",
          nacionalidadId: "",
          rfc: "",
          curp: "",
          folioVerificacionId: "",
          calidadMigratoria: "",
          estadoCivilId: "",
          nivelEducativoId: "",
          profesion: "",
          correoElectronico: "",
          celular: modeloPm.representanteLegal.telefono,
          dependientesEconomicosMenores23: "",
          dependientesEconomicos: "",
          numeroIdFiscal2: "",
          paisResidenciaFiscal2Id: "",
          nombreOtraEmpresa: "",
          nombreOtraEmpresaPfae: "",
        },
        direccion: {
          ineCertificada: INE_CERTIFICADA,
          aplicaIneComprobante: APLICA_INE_COMPROBANTE_OS_NO,
          calle: "",
          numeroExterior: "",
          numeroInterior: "",
          piso: "",
          paisId: ID_PAIS_MEXICO,
          codigoPostal: "",
          colonia: "",
          tipoDomicilioId: "",
          tipoViviendaId: "",
          tiempoViviendaAnios: "",
          tiempoViviendaMeses: "",
          tiempoViviendaAntAnios: "",
          tiempoViviendaAntMeses: "",
        },
        datosDelNegocio: {
          importaExportaId: "",
          sectorId: "",
          giroEmpresarialId: "",
          actividadId: "",
          aniosExperienciaSector: "",
          numeroSucursales: "",
          fechaAltaShcp: "",
        },
      },
      dataObj: {
        paisNacimientoObj: modeloPm?.principalAccionista?.generales
          ?.paisNacimientoId
          ? axiosData?.generales?.paises?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.paisNacimientoId
            )
          : "",
        estadoNacimientoObj: modeloPm?.principalAccionista?.generales
          ?.estadoNacimientoId
          ? axiosData?.generales?.estados?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.estadoNacimientoId
            )
          : "",
        nacionalidadObj: modeloPm?.principalAccionista?.generales
          ?.nacionalidadId
          ? axiosData?.generales?.nacionalidades?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.nacionalidadId
            )
          : "",
        estadoCivilObj: modeloPm?.principalAccionista?.generales?.estadoCivilId
          ? axiosData?.generales?.estadoCivilOpciones?.filter(
              (e) =>
                e.id === modeloPm?.principalAccionista?.generales?.estadoCivilId
            )
          : "",
        dependientesEconomicosMenores23Obj: modeloPm?.principalAccionista
          ?.generales?.dependientesEconomicosMenores23
          ? axiosData?.generales?.dependientesMenores23Anios?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales
                  ?.dependientesEconomicosMenores23
            )
          : "",
        dependientesEconomicosObj: modeloPm?.principalAccionista?.generales
          ?.dependientesEconomicos
          ? axiosData?.generales?.dependientes?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.dependientesEconomicos
            )
          : "",
        nivelEducativoObj: modeloPm?.principalAccionista?.generales
          ?.nivelEducativoId
          ? axiosData?.generales?.nivelEducativoOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.generales?.nivelEducativoId
            )
          : "",
        municipioObj: modeloPm?.principalAccionista?.direccion?.municipioId
          ? axiosData?.generales?.municipios?.filter(
              (e) =>
                e.id === modeloPm?.principalAccionista?.direccion?.municipioId
            )
          : "",
        estadoObj: modeloPm?.principalAccionista?.direccion?.estadoId
          ? axiosData?.generales?.estados?.filter(
              (e) => e.id === modeloPm?.principalAccionista?.direccion?.estadoId
            )
          : "",
        tipoDomicilioObj: modeloPm?.principalAccionista?.direccion
          ?.tipoDomicilioId
          ? axiosData?.domicilio?.tipoDeDomicilioOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.direccion?.tipoDomicilioId
            )
          : "",
        tipoViviendaObj: modeloPm?.principalAccionista?.direccion
          ?.tipoViviendaId
          ? axiosData?.domicilio?.tipoDeViviendaOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.direccion?.tipoViviendaId
            )
          : "",
        importaExportaObj: modeloPm?.principalAccionista?.datosDelNegocio
          ?.importaExportaId
          ? axiosData?.domicilioFiscal?.importaExportaOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.datosDelNegocio?.importaExportaId
            )
          : "",
        sectorObj: modeloPm?.principalAccionista?.datosDelNegocio?.sectorId
          ? axiosData?.domicilioFiscal?.sectorOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.datosDelNegocio?.sectorId
            )
          : "",
        giroEmpresarialObj: modeloPm?.principalAccionista?.datosDelNegocio
          ?.giroEmpresarialId
          ? axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.datosDelNegocio
                  ?.giroEmpresarialId
            )
          : "",
        actividadObj: modeloPm?.principalAccionista?.datosDelNegocio
          ?.actividadId
          ? axiosData?.domicilioFiscal?.actividadOpciones?.filter(
              (e) =>
                e.id ===
                modeloPm?.principalAccionista?.datosDelNegocio?.actividadId
            )
          : "",
      },
    };
  }
}

function validationSchema(
  idTipoPersonaPA,
  totalDependientesMenores23,
  totalDependientes
) {
  if (idTipoPersonaPA === ID_PFAE) {
    return {
      principalAccionista: Yup.object().shape({
        generales: Yup.object().shape({
          tipoPersonaId: Yup.number().required("Dato requerido."),
          nombre: Yup.string()
            .required("Dato requerido.")
            .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
          apellidoPaterno: Yup.string()
            .required("Dato requerido.")
            .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
          apellidoMaterno: Yup.string().matches(
            /^[aA-zZÑñ\s]+$/,
            "Solo se permiten letras "
          ),
          fechaNacimiento: Yup.string()
            .required("Dato requerido.")
            .test(
              "fechaNacimiento",
              "Debe ser mayor a 18 años y menor a 70 años.",
              (value) =>
                compareDatesOrigin(
                  fechaMaxMayorEdadYYYMMDD(),
                  fechaMinEdadYYYMMDD(),
                  value
                )
            ),
          paisNacimientoId: Yup.number().required("Dato requerido."),
          estadoNacimientoId: Yup.string().when("paisNacimientoId", {
            is: (value) => value === ID_PAIS_MEXICO,
            then: Yup.string().required("Dato requerido."),
          }),
          generoId: Yup.number().required("Dato requerido."),
          rfc: Yup.string()
            .required("Dato requerido.")
            .test("rfc", "RFC inválido.", (item, testContext) => {
              return testContext.parent.rfc !== undefined
                ? testContext.parent.rfc.trim().length > 0
                  ? validarRFCTipoPersona(
                      testContext.parent.rfc.trim(),
                      ID_PFAE
                    )
                  : false
                : false;
            }),
          curp: Yup.string().required("Dato requerido."),
          folioVerificacionId: Yup.string().required("Dato requerido."),
          nacionalidadId: Yup.number().required("Dato requerido."),
          calidadMigratoria: Yup.string().when("nacionalidadId", {
            is: (value) => value !== NACIONALIDAD_MEXICAN0,
            then: Yup.string().required("Dato requerido."),
          }),
          estadoCivilId: Yup.number().required("Dato requerido."),
          nivelEducativoId: Yup.number().required("Dato requerido."),
          profesion: Yup.string().required("Dato requerido."),
          correoElectronico: Yup.string()
            .email("Correo eléctronico inválido")
            .required("Dato requerido."),
          telefono: Yup.string()
            .required("Dato requerido.")
            .test(
              "telefono",
              "Teléfono capturado de forma incorrecta.",
              (item, testContext) => {
                return validarNumeroTelefono(testContext.parent.telefono);
              }
            ),
          celular: Yup.string()
            .required("Dato requerido.")
            .test(
              "celular",
              "Celular capturado de forma incorrecta.",
              (item, testContext) => {
                return validarNumeroTelefono(testContext.parent.celular);
              }
            ),
          dependientesEconomicosMenores23:
            Yup.number().required("Dato requerido."),
          dependientesEconomicos: Yup.number()
            .required("Dato requerido.")
            .test(
              "dependientesEconomicos",
              "Debe ser Mayor o igual al número de dependientes menores de 23 años.",
              (item, testContext) => {
                return dependeintesMajor23(
                  totalDependientesMenores23,
                  totalDependientes
                );
              }
            ),
          nombreOtraEmpresa: Yup.string().when("esAccionistaOtraEmpresa", {
            is: true,
            then: Yup.string().required("Este campo es obligatorio."),
          }),
          nombreOtraEmpresaPfae: Yup.string().when(
            "esObligadoSolidarioOtraEmpresaPfae",
            {
              is: true,
              then: Yup.string().required("Este campo es obligatorio."),
            }
          ),
        }),
        direccion: Yup.object().shape({
          calle: Yup.string().required("Dato requerido."),
          numeroExterior: Yup.string().required("Dato requerido."),
          numeroInterior: Yup.string().when("tipoViviendaId", {
            is: TIPO_VIVIENDA_EDIFICIO,
            then: Yup.string().required("Dato requerido."),
            otherwise: Yup.string().when("tipoViviendaId", {
              is: TIPO_DEPARTAMENTO_APARTAMENTO,
              then: Yup.string().required("Dato requerido."),
            }),
          }),
          piso: Yup.string().when("tipoViviendaId", {
            is: TIPO_VIVIENDA_EDIFICIO,
            then: Yup.string().required("Dato requerido."),
            otherwise: Yup.string().when("tipoViviendaId", {
              is: TIPO_DEPARTAMENTO_APARTAMENTO,
              then: Yup.string().required("Dato requerido."),
            }),
          }),
          codigoPostal: Yup.string().required("Dato requerido."),
          colonia: Yup.string().required("Dato requerido."),
          municipioId: Yup.string().required("Dato requerido."),
          estadoId: Yup.string().required("Dato requerido."),
          tipoDomicilioId: Yup.string().required("Dato requerido."),
          tipoViviendaId: Yup.string().required("Dato requerido."),
          tiempoViviendaAnios: Yup.number().required("Dato requerido."),
          tiempoViviendaMeses: Yup.number()
            .required("Dato requerido.")
            .test(
              "tiempoViviendaMeses",
              "Antigüedad en el Domicilio Actual, debe ser mayor a cero.",
              (item, testContext) => {
                return tiempoViviendaActual(
                  testContext.parent.tiempoViviendaMeses,
                  testContext.parent.tiempoViviendaAnios
                );
              }
            ),
          tiempoViviendaAntAnios: Yup.number().required("Dato requerido."),
          tiempoViviendaAntMeses: Yup.number()
            .required("Dato requerido.")
            .test(
              "tiempoViviendaAntMeses",
              "Dato requerido.",
              (item, testContext) => {
                return tiempoViviendaAnterior(
                  testContext.parent.tiempoViviendaAntMeses,
                  testContext.parent.tiempoViviendaAntAnios
                );
              }
            ),
        }),
        datosDelNegocio: Yup.object().shape({
          importaExportaId: Yup.number().required("Dato requerido."),
          sectorId: Yup.string().required("Dato requerido."),
          giroEmpresarialId: Yup.string().required("Dato requerido."),
          actividadId: Yup.string().required("Dato requerido."),
          aniosExperienciaSector: Yup.string().required("Dato requerido."),
          numeroSucursales: Yup.number()
            .required("Dato requerido.")
            .min(0, "Por favor ingrese un valor mayor igual a 0.")
            .max(9999, "Por favor no ingrese más de 9,999 sucursales."),
          fechaAltaShcp: Yup.string().required("Dato requerido."),
        }),
      }),
    };
  } else {
    return {
      principalAccionista: Yup.object().shape({
        generales: Yup.object().shape({
          tipoPersonaId: Yup.number().required("Dato requerido."),
          nombre: Yup.string()
            .required("Dato requerido.")
            .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
          apellidoPaterno: Yup.string()
            .required("Dato requerido.")
            .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
          apellidoMaterno: Yup.string().matches(
            /^[aA-zZÑñ\s]+$/,
            "Solo se permiten letras "
          ),
          fechaNacimiento: Yup.string()
            .required("Dato requerido.")
            .test(
              "fechaNacimiento",
              "Debe ser mayor a 18 años y menor a 70 años.",
              (value) =>
                compareDatesOrigin(
                  fechaMaxMayorEdadYYYMMDD(),
                  fechaMinEdadYYYMMDD(),
                  value
                )
            ),
          paisNacimientoId: Yup.number().required("Dato requerido."),
          estadoNacimientoId: Yup.string().when("paisNacimientoId", {
            is: (value) => value === ID_PAIS_MEXICO,
            then: Yup.string().required("Dato requerido."),
          }),
          generoId: Yup.number().required("Dato requerido."),
          rfc: Yup.string()
            .required("Dato requerido.")
            .test("rfc", "RFC inválido.", (item, testContext) => {
              return testContext.parent.rfc !== undefined
                ? testContext.parent.rfc.trim().length > 0
                  ? validarRFCTipoPersona(testContext.parent.rfc.trim(), ID_PF)
                  : false
                : false;
            }),
          curp: Yup.string().required("Dato requerido."),
          folioVerificacionId: Yup.string().required("Dato requerido."),
          nacionalidadId: Yup.number().required("Dato requerido."),
          calidadMigratoria: Yup.string().when("nacionalidadId", {
            is: (value) => value !== NACIONALIDAD_MEXICAN0,
            then: Yup.string().required("Dato requerido."),
          }),
          estadoCivilId: Yup.number().required("Dato requerido."),
          nivelEducativoId: Yup.number().required("Dato requerido."),
          profesion: Yup.string().required("Dato requerido."),
          correoElectronico: Yup.string()
            .email("Correo eléctronico inválido")
            .required("Dato requerido."),
          telefono: Yup.string()
            .required("Dato requerido.")
            .test(
              "telefono",
              "Teléfono capturado de forma incorrecta.",
              (item, testContext) => {
                return validarNumeroTelefono(testContext.parent.telefono);
              }
            ),
          celular: Yup.string()
            .required("Dato requerido.")
            .test(
              "celular",
              "Celular capturado de forma incorrecta.",
              (item, testContext) => {
                return validarNumeroTelefono(testContext.parent.celular);
              }
            ),
          dependientesEconomicosMenores23:
            Yup.number().required("Dato requerido."),
          dependientesEconomicos: Yup.number()
            .required("Dato requerido.")
            .test(
              "dependientesEconomicos",
              "Debe ser Mayor o igual al número de dependientes menores de 23 años.",
              (item, testContext) => {
                return dependeintesMajor23(
                  totalDependientesMenores23,
                  totalDependientes
                );
              }
            ),
          nombreOtraEmpresa: Yup.string().when("esAccionistaOtraEmpresa", {
            is: true,
            then: Yup.string().required("Este campo es obligatorio."),
          }),
          nombreOtraEmpresaPfae: Yup.string().when(
            "esObligadoSolidarioOtraEmpresaPfae",
            {
              is: true,
              then: Yup.string().required("Este campo es obligatorio."),
            }
          ),
        }),
        direccion: Yup.object().shape({
          calle: Yup.string().required("Dato requerido."),
          numeroExterior: Yup.string().required("Dato requerido."),
          numeroInterior: Yup.string().when("tipoViviendaId", {
            is: TIPO_VIVIENDA_EDIFICIO,
            then: Yup.string().required("Dato requerido."),
            otherwise: Yup.string().when("tipoViviendaId", {
              is: TIPO_DEPARTAMENTO_APARTAMENTO,
              then: Yup.string().required("Dato requerido."),
            }),
          }),
          piso: Yup.string().when("tipoViviendaId", {
            is: TIPO_VIVIENDA_EDIFICIO,
            then: Yup.string().required("Dato requerido."),
            otherwise: Yup.string().when("tipoViviendaId", {
              is: TIPO_DEPARTAMENTO_APARTAMENTO,
              then: Yup.string().required("Dato requerido."),
            }),
          }),
          paisId: Yup.string().required("Dato requerido."),
          codigoPostal: Yup.string().required("Dato requerido."),
          colonia: Yup.string().required("Dato requerido."),
          municipioId: Yup.string().required("Dato requerido."),
          estadoId: Yup.string().required("Dato requerido."),
          tipoDomicilioId: Yup.string().required("Dato requerido."),
          tipoViviendaId: Yup.string().required("Dato requerido."),
          tiempoViviendaAnios: Yup.number().required("Dato requerido."),
          tiempoViviendaMeses: Yup.number()
            .required("Dato requerido.")
            .test(
              "tiempoViviendaMeses",
              "Antigüedad en el Domicilio Actual, debe ser mayor a cero.",
              (item, testContext) => {
                return tiempoViviendaActual(
                  testContext.parent.tiempoViviendaMeses,
                  testContext.parent.tiempoViviendaAnios
                );
              }
            ),
          tiempoViviendaAntAnios: Yup.number().required("Dato requerido."),
          tiempoViviendaAntMeses: Yup.number()
            .required("Dato requerido.")
            .test(
              "tiempoViviendaAntMeses",
              "Dato requerido.",
              (item, testContext) => {
                return tiempoViviendaAnterior(
                  testContext.parent.tiempoViviendaAntMeses,
                  testContext.parent.tiempoViviendaAntAnios
                );
              }
            ),
        }),
      }),
    };
  }
}
