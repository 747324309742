import React, { useDeferredValue, useEffect, useState } from "react";

import DatosBancoMesPM from "./DatosBancoMesPM";
import { AnimatePresence, motion } from "framer-motion";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";

const DatosBancoPM = ({
  numeroBanco,
  animatedHeightBoxRef,
  axiosData,
  formik,
  setValidateSubmit,
}) => {
  const [showBank, setShowBank] = useState(true);

  useEffect(() => {
    formik.setFieldValue(
      `datosEconomicos.bancos.${numeroBanco}.numeroBanco`,
      numeroBanco + 1
    );

    if (
      formik?.values?.datosEconomicos?.bancos?.[numeroBanco]?.bancoId !==
      undefined  
      || formik?.values?.datosEconomicos?.bancos?.[numeroBanco]?.bancoId !== null
    ) {
      formik.setFieldValue(
        `datosEconomicos.bancos.${numeroBanco}.bancoObj`,
        axiosData?.catalogosPfaeDatosEconomicos.bancoOpciones?.filter(
          (e) => e.id === formik?.values?.datosEconomicos?.bancos?.[numeroBanco]?.bancoId
        )
      );
    } else {
      formik.setFieldValue(
        `datosEconomicos.bancos.${numeroBanco}.bancoObj`,
        ""
      );
    }
  }, []);

  return (
    <>
      <div className="month-father-layout w-100">
        <div className="w-100">
          <div
            className="shadow-sm month-banco"
            onClick={(e) => setShowBank(!showBank)}
          >
            <span className="f-s-3">Banco {numeroBanco + 1}</span>
            {showBank ? <BsChevronUp /> : <BsChevronDown />}
          </div>
        </div>
        <div ref={animatedHeightBoxRef} className="slow-transition h-100">
          <div>
            <div className="row">
              <div className="economicos--principales-campos">
                <SelectWithError
                  label="Selecciona tu banco *"
                  name={`datosEconomicos.bancos.${numeroBanco}.bancoId`}
                  value={{
                    label:
                      formik?.values?.datosEconomicos?.bancos[numeroBanco]
                        ?.bancoObj?.[0]?.nombreBanco || "",
                    value:
                      formik?.values?.datosEconomicos?.bancos[numeroBanco]
                        ?.bancoObj?.[0]?.nombreBanco || "",
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if(newErrors?.datosEconomicos !== undefined &&   
                      newErrors?.datosEconomicos.bancos !== undefined &&
                      newErrors?.datosEconomicos.bancos.length !== 0 &&                
                      newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoId){
                      delete newErrors?.datosEconomicos?.bancos[numeroBanco]?.bancoId;
                      formik.setErrors(newErrors);
                    }
                    formik.setFieldValue(
                      `datosEconomicos.bancos.${numeroBanco}.bancoId`,
                      e.id
                    );
                    formik.setFieldValue(
                      `datosEconomicos.bancos.${numeroBanco}.bancoObj`,
                      [e]
                    );
                  }}
                  options={axiosData.catalogosPfaeDatosEconomicos.bancoOpciones.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.nombreBanco,
                        nombre: item.nombreBanco,
                      };
                    }
                  )}
                  showErrorMessage={
                    formik?.errors?.datosEconomicos &&
                    formik?.errors?.datosEconomicos?.bancos.length > 0 &&
                    formik.errors?.datosEconomicos?.bancos[numeroBanco]?.bancoId
                  }
                  errorMessage={
                    formik.errors?.datosEconomicos?.bancos[numeroBanco]?.bancoId
                  }
                />
                <p className="p--texto_justify">Selecciona el banco en donde recibes los ingresos de tu negocio, en caso de que sean varios bancos, puedes capturar el principal y posteriormente agregar la información del banco secundario</p>
              </div>
              <AnimatePresence exitBeforeEnter>
                {showBank && (
                  <motion.div
                    initial={{ opacity: 0, scaleY: 0 }}
                    animate={{ opacity: 1, scaleY: 1 }}
                    exit={{ opacity: 0, scaleY: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <DatosBancoMesPM
                      numeroBanco={numeroBanco}
                      numeroMes={0}
                      formik={formik}
                      setValidateSubmit={setValidateSubmit}
                    />

                    <DatosBancoMesPM
                      numeroBanco={numeroBanco}
                      numeroMes={1}
                      formik={formik}
                      setValidateSubmit={setValidateSubmit}
                    />

                    <DatosBancoMesPM
                      numeroBanco={numeroBanco}
                      numeroMes={2}
                      formik={formik}
                      setValidateSubmit={setValidateSubmit}
                    />

                    <DatosBancoMesPM
                      numeroBanco={numeroBanco}
                      numeroMes={3}
                      formik={formik}
                      setValidateSubmit={setValidateSubmit}
                    />

                    <DatosBancoMesPM
                      numeroBanco={numeroBanco}
                      numeroMes={4}
                      formik={formik}
                      setValidateSubmit={setValidateSubmit}
                    />

                    <DatosBancoMesPM
                      numeroBanco={numeroBanco}
                      numeroMes={5}
                      formik={formik}
                      setValidateSubmit={setValidateSubmit}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatosBancoPM;
