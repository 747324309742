import React, { useEffect } from "react";
import { PANTALLA_VALIDACION_NUMERO_CORREO } from "../../../utils/constantes";

const OfertaPersonalizada = ({
  item,
  index,
  setSteps,
  oferta,
  steps,
  setOfertaAceptada,
  ofertaAceptada,
  formik,
}) => {
  useEffect(() => {
    if (steps === PANTALLA_VALIDACION_NUMERO_CORREO) {
      setSteps(PANTALLA_VALIDACION_NUMERO_CORREO);
    }
  }, [steps]);

  useEffect(() => {
    if (ofertaAceptada) setOfertaAceptada(true);
    else setOfertaAceptada(false);
  }, [ofertaAceptada]);

  return (
    <div className="col-sm-4 barra-columna">
      <input value={item.idOferta} type="hidden" />
      <input value={item.idCampania} type="hidden" />
      <div className="campo-oferta-campania">
        <span className="text-span-ofertas">Monto del crédito</span>
        <div className="no-wrap no-wrap-div-ofertas">
          <p className="color-red p-pago-mensual">
            {"$ " +
              (Number(item.monto?.toString().replace(/\D/g, "")) || "")
                .toLocaleString()
                .replaceAll(".", ",")}{" "}
            MXN
          </p>
          <input
            type="text"
            value={(Number(item.monto.toString().replace(/\D/g, "")) || "")
              .toLocaleString()
              .replaceAll(".", ",")}
            readOnly
            className="big-input color-red input-monto"
          />
        </div>
      </div>
      <div className="campo-oferta-campania">
        <span className="text-span-ofertas">Plazo</span>
        <div className="section-one-dinamic-width-100 no-wrap-div-ofertas">
          <p
            className="color-red"
            style={{
              marginBottom: "0px",
              fontWeight: "bold",
              width: "100%",
              fontSize: "12px",
            }}
          >
            {Number(item.plazo) + " meses"}
          </p>
          <input
            type="text"
            value={Number(item.plazo)}
            readOnly
            className="big-input color-red"
            style={{ fontWeight: "bold", width: "100%" }}
          />
        </div>
      </div>
      <div className="campo-oferta-campania">
        <span className="text-span-ofertas">Comison por apertura</span>
        <div className="section-one-dinamic-width-100 no-wrap-div-ofertas">
          <p className="color-red p-pago-mensual">
            {item.comisionApertura.toString()}
          </p>
          <input
            type="text"
            value={item.comisionApertura}
            readOnly
            className="big-input color-red input-pago-mensual"
          />
        </div>
      </div>
      <div className="campo-oferta-campania">
        <span className="text-span-ofertas">
          Cat {item.catFijo ? "Fijo" : "Variable"}
        </span>
        <span className="color-red span-valor-cat">
          {item.catFijo ? item.catFijo + " %" : item.catVariable + " %"}
        </span>
        <input
          type="text"
          value={item.catFijo ? item.catFijo : item.catVariable}
          readOnly
          className="big-input color-red input-pago-mensual"
        />
      </div>
      <div className="campo-oferta-campania">
        <span className="text-span-ofertas">
          Tasa {item.tasaFija ? "Fija" : "Variable"}
        </span>
        <span className="color-red span-valor-cat">
          {item.tasaFija ? item.tasaFija + " %" : item.tasaVariable + " %"}
        </span>
        <input
          type="text"
          value={item.tasaFija ? item.tasaFija : item.tasaVariable}
          readOnly
          className="big-input color-red input-pago-mensual"
        />
      </div>
    </div>
  );
};
export default OfertaPersonalizada;
