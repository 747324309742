import React from "react";
import CheckBoxInput from "../../../../basics/checkBox";
import { validateValue } from "../../../../../functions/fieldFunctions";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";

const CaptacionPM = ({ formik, axiosData }) => {
  return (
    <>
      <div
        style={{
          display: "grid",
          placeItems: "center",
          gap: "1rem",
        }}
      >
        <InputWithError
          label="Número de dictamen *"
          name="captacion.numeroDictamen"
          value={formik.values?.captacion?.numeroDictamen}
          onChange={(e) => {
            if (validateValue(e.target.value, "number")) {
              formik.setFieldValue("captacion.numeroDictamen", e.target.value);
            }
          }}
          InputProps={{ maxLength: 180 }}
          showErrorMessage={formik.errors?.captacion?.numeroDictamen}
          errorMessage={formik.errors?.captacion?.numeroDictamen}
        />
        <InputWithError
          label="Nombre del producto *"
          name="captacion.nombreProducto"
          value={formik.values?.captacion?.nombreProducto}
          onChange={(e) => {
            if (validateValue(e.target.value, "text")) {
              formik.setFieldValue("captacion.nombreProducto", e.target.value);
            }
          }}
          InputProps={{ maxLength: 180 }}
          showErrorMessage={formik.errors?.captacion?.nombreProducto}
          errorMessage={formik.errors?.captacion?.nombreProducto}
        />
        <SelectWithError
          showErrorMessage={formik.errors?.captacion?.regimenCuentaId}
          errorMessage={formik.errors?.captacion?.regimenCuentaId}
          label="Régimen de la cuenta: mancomunada o indistinta *"
          name="captacion.regimenCuentaId"
          value={{
            label: formik.values?.datosObj?.regimenCuentaObj?.[0]?.nombre,
            value: formik.values?.datosObj?.regimenCuentaObj?.[0]?.nombre,
          }}
          onChange={(e) => {
            formik.setFieldValue("captacion.regimenCuentaId", e.id);
            formik.setFieldValue("datosObj.regimenCuentaObj", [e]);
          }}
          options={axiosData?.catalogosPfaeDatosCaptacion?.regimenCuentaOpciones?.map(
            (item) => {
              return {
                ...item,
                label: item.nombre,
                value: item.nombre,
              };
            }
          )}
        />
        <InputWithError
          label="Nombre del apoderado mancomunado *"
          name="captacion.nombreApoderadoMancomunado"
          value={formik.values?.captacion?.nombreApoderadoMancomunado}
          onChange={(e) => {
            if (validateValue(e.target.value, "text")) {
              formik.setFieldValue(
                "captacion.nombreApoderadoMancomunado",
                e.target.value
              );
            }
          }}
          InputProps={{ maxLength: 180 }}
          showErrorMessage={
            formik.errors?.captacion?.nombreApoderadoMancomunado
          }
          errorMessage={formik.errors?.captacion?.nombreApoderadoMancomunado}
        />
        <InputWithError
          label="Nombre del apoderado mancomunado 2 *"
          name="captacion.nombreApoderadoMancomunado2"
          value={formik.values?.captacion?.nombreApoderadoMancomunado2}
          onChange={(e) => {
            if (validateValue(e.target.value, "text")) {
              formik.setFieldValue(
                "captacion.nombreApoderadoMancomunado2",
                e.target.value
              );
            }
          }}
          InputProps={{ maxLength: 180 }}
        />
        <InputWithError
          name="captacion.estatusFatcaCrs"
          value={formik.values?.captacion?.estatusFatcaCrs}
          onChange={(e) => {
            if (validateValue(e.target.value, "text")) {
              formik.setFieldValue("captacion.estatusFatcaCrs", e.target.value);
            }
          }}
          InputProps={{ maxLength: 180 }}
          label="Estatus FATCA CRS *"
          showErrorMessage={formik.errors?.captacion?.estatusFatcaCrs}
          errorMessage={formik.errors?.captacion?.estatusFatcaCrs}
        />
        <InputWithError
          showErrorMessage={formik.errors?.captacion?.cfdi}
          errorMessage={formik.errors?.captacion?.cfdi}
          label="CFDI *"
          name="captacion.cfdi"
          value={formik.values?.captacion?.cfdi}
          onChange={(e) => {
            if (validateValue(e.target.value, "text")) {
              formik.setFieldValue("captacion.cfdi", e.target.value);
            }
          }}
          InputProps={{ maxLength: 180 }}
        />
        <SelectWithError
          showErrorMessage={formik.errors?.captacion?.entregaCorrespondenciaId}
          errorMessage={formik.errors?.captacion?.entregaCorrespondenciaId}
          name="captacion.entregaCorrespondenciaId"
          value={{
            label:
              formik.values?.datosObj?.entregaCorrespondenciaObj?.[0]?.nombre,
            value:
              formik.values?.datosObj?.entregaCorrespondenciaObj?.[0]?.nombre,
          }}
          onChange={(e) => {
            formik.setFieldValue("captacion.entregaCorrespondenciaId", e.id);
            formik.setFieldValue("datosObj.entregaCorrespondenciaObj", [e]);
          }}
          label="Entrega de correspondencia *"
          options={axiosData?.catalogosPfaeDatosCaptacion?.entregaCorrespondenciaOpciones?.map(
            (item) => {
              return {
                ...item,
                label: item.nombre,
                value: item.nombre,
              };
            }
          )}
        />
        <InputWithError
          showErrorMessage={formik.errors?.captacion?.montoReferenciaInversion}
          errorMessage={formik.errors?.captacion?.montoReferenciaInversion}
          label="Monto de referencia de la inversión *"
          name="captacion.montoReferenciaInversion"
          value={formik.values?.captacion?.montoReferenciaInversion}
          onChange={(e) => {
            if (validateValue(e.target.value, "number")) {
              formik.setFieldValue(
                "captacion.montoReferenciaInversion",
                e.target.value
              );
            }
          }}
        />
        <div>
          <div>
            <CheckBoxInput
              state=""
              checked={
                formik.values?.captacion?.envioRecepcionTransferenciasFondosSpid
              }
              typeOfCheck="normal"
              defaultChecked={false}
              name="captacion.envioRecepcionTransferenciasFondosSpid"
              value={
                formik.values?.captacion?.envioRecepcionTransferenciasFondosSpid
              }
              onChange={(e) =>
                formik.setFieldValue(
                  "captacion.envioRecepcionTransferenciasFondosSpid",
                  !formik.values?.captacion
                    ?.envioRecepcionTransferenciasFondosSpid
                )
              }
              id="auno"
            />
            <label
              style={{ marginLeft: "8px" }}
              className="form-check-label"
              htmlFor="auno"
            >
              ¿Se requiere el envío y recepción de transferencias de fondos a
              través de SPID?
            </label>
          </div>
          <div>
            <CheckBoxInput
              state=""
              typeOfCheck="normal"
              name="captacion.requiereChequera"
              value={formik.values?.captacion?.requiereChequera}
              checked={formik.values?.captacion?.requiereChequera}
              onChange={(e) =>
                formik.setFieldValue(
                  "captacion.requiereChequera",
                  !formik.values?.captacion?.requiereChequera
                )
              }
              id="ados"
            />
            <label
              style={{ marginLeft: "8px" }}
              className="form-check-label"
              htmlFor="ados"
            >
              ¿Requiere Chequera?
            </label>
          </div>
          <div>
            <CheckBoxInput
              state=""
              checked={formik.values?.captacion?.requiereTarjetaDebito}
              name="captacion.requiereTarjetaDebito"
              value={formik.values?.captacion?.requiereTarjetaDebito}
              onChange={(e) =>
                formik.setFieldValue(
                  "captacion.requiereTarjetaDebito",
                  !formik.values?.captacion?.requiereTarjetaDebito
                )
              }
              typeOfCheck="normal"
              id="atres"
            />
            <label
              style={{ marginLeft: "8px" }}
              className="form-check-label"
              htmlFor="atres"
            >
              ¿Requiere Tarjeta de Débito?
            </label>
          </div>
        </div>

        <SelectWithError
          showErrorMessage={
            formik.errors?.captacion?.identificadorBancaElectronicaId
          }
          errorMessage={
            formik.errors?.captacion?.identificadorBancaElectronicaId
          }
          label="Identificador de banca electrónica *"
          name="captacion.identificadorBancaElectronicaId"
          value={{
            label:
              formik.values?.datosObj?.identificadorBancaElectronicaObj?.[0]
                ?.nombre,
            value:
              formik.values?.datosObj?.identificadorBancaElectronicaObj?.[0]
                ?.nombre,
          }}
          onChange={(e) => {
            formik.setFieldValue(
              "captacion.identificadorBancaElectronicaId",
              e.id
            );
            formik.setFieldValue("datosObj.identificadorBancaElectronicaObj", [
              e,
            ]);
          }}
          options={axiosData?.catalogosPfaeDatosCaptacion?.identificadorBancaElectronicaOpciones?.map(
            (item) => {
              return {
                ...item,
                label: item.nombre,
                value: item.nombre,
              };
            }
          )}
        />
        <div>
          <CheckBoxInput
            state=""
            name="captacion.requiereServicioBancaElectronica"
            value={formik.values?.captacion?.requiereServicioBancaElectronica}
            checked={formik.values?.captacion?.requiereServicioBancaElectronica}
            onChange={(e) =>
              formik.setFieldValue(
                "captacion.requiereServicioBancaElectronica",
                !formik.values?.captacion?.requiereServicioBancaElectronica
              )
            }
            typeOfCheck="normal"
            id="acuatro"
          />
          <label
            style={{ marginLeft: "8px" }}
            className="form-check-label"
            htmlFor="acuatro"
          >
            Servicio de Banca Electrónica
          </label>
        </div>
        <SelectWithError
          showErrorMessage={formik.errors?.captacion?.bancaElectronicaOpciones}
          errorMessage={formik.errors?.captacion?.bancaElectronicaOpciones}
          label="Banca Electrónica BEP o BEM *"
          name="captacion.bancaElectronicaId"
          value={{
            label: formik.values?.datosObj?.bancaElectronicaObj?.[0]?.nombre,
            value: formik.values?.datosObj?.bancaElectronicaObj?.[0]?.nombre,
          }}
          onChange={(e) => {
            formik.setFieldValue("captacion.bancaElectronicaId", e.id);
            formik.setFieldValue("datosObj.bancaElectronicaObj", [e]);
          }}
          options={axiosData?.catalogosPfaeDatosCaptacion?.bancaElectronicaOpciones?.map(
            (item) => {
              return {
                ...item,
                label: item.nombre,
                value: item.nombre,
              };
            }
          )}
        />
        <InputWithError
          showErrorMessage={formik.errors?.captacion?.plan}
          errorMessage={formik.errors?.captacion?.plan}
          label="Plan *"
          name="captacion.plan"
          value={formik.values?.captacion?.plan}
          onChange={(e) => {
            if (validateValue(e.target.value, "text")) {
              formik.setFieldValue("captacion.plan", e.target.value);
            }
          }}
          InputProps={{ maxLength: 180 }}
        />
        <InputWithError
          showErrorMessage={formik.errors?.captacion?.tipoCobro}
          errorMessage={formik.errors?.captacion?.tipoCobro}
          name="captacion.tipoCobro"
          value={formik.values?.captacion?.tipoCobro}
          onChange={(e) => {
            if (validateValue(e.target.value, "text")) {
              formik.setFieldValue("captacion.tipoCobro", e.target.value);
            }
          }}
          InputProps={{ maxLength: 180 }}
          label="Tipo cobro *"
        />
        <InputWithError
          showErrorMessage={formik.errors?.captacion?.cuentaCargo}
          errorMessage={formik.errors?.captacion?.cuentaCargo}
          label="Cuenta cargo *"
          name="captacion.cuentaCargo"
          value={formik.values?.captacion?.cuentaCargo}
          onChange={(e) => {
            if (validateValue(e.target.value, "number")) {
              formik.setFieldValue("captacion.cuentaCargo", e.target.value);
            }
          }}
          InputProps={{ maxLength: 180 }}
        />
        <InputWithError
          label="Operación mancomunada para banca electrónica"
          name="captacion.operacionMancomunadaBancaElectronica"
          value={formik.values?.captacion?.operacionMancomunadaBancaElectronica}
          onChange={(e) => {
            if (validateValue(e.target.value, "text")) {
              formik.setFieldValue(
                "captacion.operacionMancomunadaBancaElectronica",
                e.target.value
              );
            }
          }}
          InputProps={{ maxLength: 180 }}
        />
        <InputWithError
          label="Operación mancomunada para libramiento de cheques"
          name="captacion.operacionMancomunadaLibramientoCheques"
          value={
            formik.values?.captacion?.operacionMancomunadaLibramientoCheques
          }
          onChange={(e) => {
            if (validateValue(e.target.value, "number")) {
              formik.setFieldValue(
                "captacion.operacionMancomunadaLibramientoCheques",
                e.target.value
              );
            }
          }}
          InputProps={{ maxLength: 180 }}
        />
      </div>
    </>
  );
};

export default CaptacionPM;
