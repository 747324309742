import React, { useEffect, useState } from "react";
import { ID_PF, ID_PFAE, ID_PM } from "../../../../../utils/constantes";
import {
  textToUpperCase,
  validarTextoConPunto,
  validateValue,
  validarTextoConEnies,
} from "../../../../../functions/fieldFunctions";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import ErrorMessage from "../../../../helpers/ErrorMessage/ErrorMessage";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import NumberWithError from "../../../../basics/NumberWithError/NumberWithError";
import RadioInput from "../../../../basics/radioButton";

const SocioPrincipalPM = ({ formik, numSocio }) => {
  const [maxLengthRfc, setMaxLengthRfc] = useState(12);
  const personTypes = [
    { title: "Persona física", rfc: 13, id: ID_PF, icon: "ri-user-fill" },
    {
      title: "Persona física con actividad empresarial",
      rfc: 13,
      id: ID_PFAE,
      icon: "ri-user-2-fill",
    },
    { title: "Persona moral", rfc: 12, id: ID_PM, icon: "ri-building-4-line" },
  ];

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value >= 1 && value <= 100) return true;
    return false;
  };

  useEffect(() => {
    if (formik.values?.sociosPrincipales.length === 0) {
      formik.setFieldValue(
        `sociosPrincipales.${numSocio}.tipoDePersonaId`,
        ID_PF
      );
      formik.setFieldValue(`sociosPrincipales.${numSocio}.indice`, numSocio);
      formik.setFieldValue(`sociosPrincipales.${numSocio}.numeroEmpresa`, 1);
      formik.setFieldValue(
        `sociosPrincipales.${numSocio}.numeroSocio`,
        numSocio + 1
      );
    } else {
      if (
        formik.values?.sociosPrincipales[numSocio]?.tipoDePersonaId !== ID_PM
      ) {
        setMaxLengthRfc(13);
      }

      if (
        formik.values?.sociosPrincipales[numSocio]?.idSocioPrincipal == null
      ) {
        formik.setFieldValue(`sociosPrincipales.${numSocio}.indice`, numSocio);
        formik.setFieldValue(`sociosPrincipales.${numSocio}.numeroEmpresa`, 1);
        formik.setFieldValue(
          `sociosPrincipales.${numSocio}.numeroSocio`,
          numSocio + 1
        );
      }
    }
  }, []);

  return (
    <>
      <h3 className="f-s-3 my-2">Accionista {numSocio + 1}</h3>
      <div className="d-flex justify-content-center gap-4 my-4">
        {personTypes.map((person, index) => {
          return (
            <div className="d-flex flex-column align-items-center" key={index}>
              <RadioInput
                style={{ padding: "0" }}
                checked={
                  formik.values?.sociosPrincipales[numSocio]
                    ?.tipoDePersonaId === person.id
                }
                onChange={() => {
                  formik.setFieldValue(
                    `sociosPrincipales.${numSocio}.tipoDePersonaId`,
                    person.id
                  );
                  setMaxLengthRfc(person.rfc);
                }}
              />
              <label className="text_lightGray">{person.title}</label>
            </div>
          );
        })}
      </div>
      <FormLayout>
        <div className="d-flex flex-column gap-2">
          <InputWithError
            label={
              formik.values?.sociosPrincipales[numSocio]?.tipoDePersonaId ===
              ID_PM
                ? "Razón Social *"
                : "Nombre *"
            }
            name={`sociosPrincipales.${numSocio}.nombre`}
            value={formik.values?.sociosPrincipales[numSocio]?.nombre}
            onChange={(e) => {
              if (validarTextoConPunto(e.target.value, "alphanumeric")) {
                formik.setFieldValue(
                  `sociosPrincipales.${numSocio}.nombre`,
                  e.target.value
                );
                let newErrors = formik.errors;
                if (Object.entries(newErrors).length !== 0) {
                  if(newErrors?.sociosPrincipales !== undefined &&                  
                    newErrors?.sociosPrincipales.length !== 0 && 
                    newErrors?.sociosPrincipales[numSocio]?.nombre){
                    delete newErrors?.sociosPrincipales[numSocio]?.nombre;
                    formik.setErrors(newErrors);
                  }
                  
                }
              }
            }}
            InputProps={{ maxLength: 180 }}
          />
          {formik.errors?.sociosPrincipales &&
            formik.errors?.sociosPrincipales?.length > 0 &&
            formik.errors?.sociosPrincipales[numSocio] !== undefined &&
            formik.errors?.sociosPrincipales[numSocio]?.nombre && (
              <ErrorMessage
                message={formik.errors?.sociosPrincipales[numSocio]?.nombre}
                width={55}
              />
            )}
        </div>
        {formik.values?.sociosPrincipales[numSocio]?.tipoDePersonaId !==
          ID_PM && (
          <div className="d-flex flex-column gap-2">
            <InputWithError
              label="Apellido paterno *"
              name={`sociosPrincipales.${numSocio}.apellidoPaterno`}
              value={
                formik.values?.sociosPrincipales[numSocio]?.apellidoPaterno
              }
              onChange={(e) => {
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    `sociosPrincipales.${numSocio}.apellidoPaterno`,
                    e.target.value
                  );
                  let newErrors = formik.errors;
                  if (Object.entries(newErrors).length !== 0) {
                    if(newErrors?.sociosPrincipales !== undefined &&                  
                      newErrors?.sociosPrincipales.length !== 0 && 
                      newErrors?.sociosPrincipales[numSocio]?.apellidoPaterno){
                      delete newErrors?.sociosPrincipales[numSocio]
                        ?.apellidoPaterno;
                      formik.setErrors(newErrors);
                    }
                  }
                }
              }}
              InputProps={{ maxLength: 180 }}
            />
            {formik.errors?.sociosPrincipales &&
              formik.errors?.sociosPrincipales?.length > 0 &&
              formik.errors?.sociosPrincipales[numSocio] !== undefined &&
              formik.errors?.sociosPrincipales[numSocio]?.apellidoPaterno && (
                <ErrorMessage
                  message={
                    formik.errors?.sociosPrincipales[numSocio]?.apellidoPaterno
                  }
                  width={55}
                />
              )}
          </div>
        )}
        {formik.values?.sociosPrincipales[numSocio]?.tipoDePersonaId !==
          ID_PM && (
          <div className="d-flex flex-column gap-2">
            <InputWithError
              name={`sociosPrincipales.${numSocio}.apellidoMaterno`}
              value={
                formik.values?.sociosPrincipales[numSocio]?.apellidoMaterno
              }
              onChange={(e) => {
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    `sociosPrincipales.${numSocio}.apellidoMaterno`,
                    e.target.value
                  );
                  let newErrors = formik.errors;
                  if (Object.entries(newErrors).length !== 0) {
                    if(newErrors?.sociosPrincipales !== undefined &&                  
                      newErrors?.sociosPrincipales.length !== 0 && 
                      newErrors?.sociosPrincipales[numSocio]?.apellidoMaterno){
                      delete newErrors?.sociosPrincipales[numSocio]
                        ?.apellidoMaterno;
                      formik.setErrors(newErrors);
                    }
                  }
                }
              }}
              label="Apellido materno "
              InputProps={{ maxLength: 180 }}
            />
            {formik.errors?.sociosPrincipales &&
              formik.errors?.sociosPrincipales?.length > 0 &&
              formik.errors?.sociosPrincipales[numSocio] !== undefined &&
              formik.errors?.sociosPrincipales[numSocio]?.apellidoMaterno && (
                <ErrorMessage
                  message={
                    formik.errors?.sociosPrincipales[numSocio]?.apellidoMaterno
                  }
                  width={55}
                />
              )}
          </div>
        )}
        <div className="d-flex flex-column gap-2">
          <NumberWithError
            label="% accionario *"
            name={`sociosPrincipales.${numSocio}.porcentajeAccionario`}
            value={
              formik.values?.sociosPrincipales[numSocio]?.porcentajeAccionario
            }
            onValueChange={(e) => {
              let newErrors = formik.errors;
              if (Object.entries(newErrors).length !== 0) {
                if(newErrors?.sociosPrincipales !== undefined &&                  
                  newErrors?.sociosPrincipales.length !== 0 && 
                  newErrors?.sociosPrincipales[numSocio]?.porcentajeAccionario){
                  delete newErrors?.sociosPrincipales[numSocio]
                    ?.porcentajeAccionario;
                  formik.setErrors(newErrors);
                }
              }
              formik.setFieldValue(
                `sociosPrincipales.${numSocio}.porcentajeAccionario`,
                e.value
              );
            }}
            InputProps={{ maxLength: 3 }}
            decimalScale={0}
            isAllowed={withValueCap}
            type="text"
            prefix=""
          />
          {formik.errors?.sociosPrincipales &&
            formik.errors?.sociosPrincipales?.length > 0 &&
            formik.errors?.sociosPrincipales[numSocio] !== undefined &&
            formik.errors?.sociosPrincipales[numSocio]
              ?.porcentajeAccionario && (
              <ErrorMessage
                message={
                  formik.errors?.sociosPrincipales[numSocio]
                    ?.porcentajeAccionario
                }
                width={55}
              />
            )}
        </div>
        <div className="d-flex flex-column gap-2">
          <InputWithError
            state=""
            label="RFC *"
            name={`sociosPrincipales.${numSocio}.rfc`}
            value={formik.values?.sociosPrincipales[numSocio]?.rfc}
            onChange={(e) => {
              if (validateValue(e.target.value, "alphanumeric")) {
                const newValue = textToUpperCase(e.target.value);
                formik.setFieldValue(
                  `sociosPrincipales.${numSocio}.rfc`,
                  newValue
                );
                let newErrors = formik.errors;
                if (Object.entries(newErrors).length !== 0) {
                  if(newErrors?.sociosPrincipales !== undefined &&                  
                    newErrors?.sociosPrincipales.length !== 0 && 
                    newErrors?.sociosPrincipales[numSocio]?.rfc){
                    delete newErrors?.sociosPrincipales[numSocio]?.rfc;
                    formik.setErrors(newErrors);
                  }
                }
              }
            }}
            InputProps={{ maxLength: maxLengthRfc }}
          />
          {formik.errors?.sociosPrincipales &&
            formik.errors?.sociosPrincipales?.length > 0 &&
            formik.errors?.sociosPrincipales[numSocio] !== undefined &&
            formik.errors?.sociosPrincipales[numSocio]?.rfc && (
              <ErrorMessage
                message={formik.errors?.sociosPrincipales[numSocio]?.rfc}
                width={55}
              />
            )}
        </div>
        <div className="d-flex flex-column gap-2">
          <InputWithError
            label="Puesto en la empresa *"
            name={`sociosPrincipales.${numSocio}.puesto`}
            value={formik.values?.sociosPrincipales[numSocio]?.puesto}
            onChange={(e) => {
              let newErrors = formik.errors;
              if (Object.entries(newErrors).length !== 0) {
                if(newErrors?.sociosPrincipales !== undefined &&                  
                  newErrors?.sociosPrincipales.length !== 0 && 
                  newErrors?.sociosPrincipales[numSocio]?.puesto){
                  delete newErrors?.sociosPrincipales[numSocio]?.puesto;
                  formik.setErrors(newErrors);
                }
              }
              if (validarTextoConEnies(e.target.value, "text")) {
                formik.setFieldValue(
                  `sociosPrincipales.${numSocio}.puesto`,
                  e.target.value
                );
              }
            }}
            InputProps={{ maxLength: 180 }}
          />
          {formik.errors?.sociosPrincipales &&
            formik.errors?.sociosPrincipales?.length > 0 &&
            formik.errors?.sociosPrincipales[numSocio] !== undefined &&
            formik.errors?.sociosPrincipales[numSocio]?.puesto && (
              <ErrorMessage
                message={formik.errors?.sociosPrincipales[numSocio]?.puesto}
                width={55}
              />
            )}
        </div>
      </FormLayout>
    </>
  );
};

export default SocioPrincipalPM;
