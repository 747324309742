import React, { useState, useEffect } from "react";
import "moment/locale/es";
import "react-datetime/css/react-datetime.css";

import { ID_PFAE, ID_PF } from "../../../../../utils/constantes";

import DatosPrincipalAccionistaConyuge from "./DatosPrincipalAccionistaConyuge";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import SelectWithErrorRepresentanteLegal from "../../../../basics/SelectWithError/SelectWithErrorRepresentanteLegal";
import RadioInput from "../../../../basics/radioButton";
const PrincipalAccionistaConyuge = ({
  modeloPm,
  setModeloPm,
  axiosData,
  aniosDomicilio,
  mesesDomicilo,
  anios,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setObligadosSolidariosInformacionBasica,
  setLoading,
  setCantidadConyugeOS,
  cantidadConyugeOS,
}) => {
  const [principalAccionistaConyuge, setPrincipalAccionistaConyuge] = useState({
    tipoPersona: "",
    idTipoPersona: null,
  });
  const [esObligadoSolidarioConyuge, setEsObligadoSolidarioConyuge] =
    useState(false);
  const [esObligadoSolidarioConyugeBD, setEsObligadoSolidarioConyugeBD] =
    useState(false);

  useEffect(() => {
    if (modeloPm?.principalAccionista?.conyuge != undefined) {
      if (modeloPm.principalAccionista.conyuge.generales != null) {
        setEsObligadoSolidarioConyuge(
          modeloPm?.principalAccionista?.conyuge?.generales?.esObligadoSolidario
        );
        setEsObligadoSolidarioConyugeBD(
          modeloPm?.principalAccionista?.conyuge?.generales?.esObligadoSolidario
        );

        if (
          modeloPm.principalAccionista.conyuge.generales.tipoPersonaId !==
            undefined &&
          modeloPm.principalAccionista.conyuge.generales.tipoPersonaId !== null
        ) {
          if (
            modeloPm.principalAccionista.conyuge.generales.tipoPersonaId ==
            ID_PFAE
          ) {
            setPrincipalAccionistaConyuge({
              tipoPersona: "PFAE",
              idTipoPersona:
                modeloPm.principalAccionista.conyuge.generales.tipoPersonaId,
            });
          } else {
            setPrincipalAccionistaConyuge({
              tipoPersona: "PF",
              idTipoPersona:
                modeloPm.principalAccionista.conyuge.generales.tipoPersonaId,
            });
          }
        }
      }
    }
  }, []);

  const personTypes = [
    { title: "Persona física", rfc: 13, id: ID_PF, type: "PF" },
    {
      title: "Persona física con actividad empresarial",
      rfc: 13,
      id: ID_PFAE,
      type: "PFAE",
    },
  ];

  return (
    <>
      <div className="container">
        <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3 text-center">
          Cónyuge del Principal Accionista
        </h2>
        {esObligadoSolidarioConyuge === true && (
          <p className="p--texto_justify">En esta sección deberás agregar la información de la persona que será tu aval para el crédito. Esta persona debe de ser diferente al solicitante, y en caso de que el solicitante no tenga un bien inmueble que respalde el crédito, el obligado solidario deberá presentarlo</p>
        )}
        <FormLayout>
          <SelectWithErrorRepresentanteLegal
            label="¿El cónyuge funge como obligado solidario?"
            name="principalAccionista.conyuge.generales.esObligadoSolidario"
            value={
              esObligadoSolidarioConyuge
                ? { label: "Si", value: true }
                : { label: "No", value: false }
            }
            onChange={(e) => setEsObligadoSolidarioConyuge(e.value)}
            options={[
              { label: "Si", value: true },
              { label: "No", value: false },
            ]}
          />
        </FormLayout>
        {esObligadoSolidarioConyuge === true && (
          <>
            <h3 className="f-s-3 text-center mt-3">Tipo de persona</h3>
            <div className="d-flex justify-content-center gap-3 my-5">
              {personTypes.map((person) => {
                return (
                  <div className="d-flex gap-2">
                    <RadioInput
                      style={{ padding: "0" }}
                      checked={
                        principalAccionistaConyuge.idTipoPersona === person.id
                      }
                      onChange={() => {
                        setPrincipalAccionistaConyuge({
                          tipoPersona: person.type,
                          idTipoPersona: person.id,
                        });
                      }}
                    />
                    <label className="text_lightGray">{person.title}</label>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {esObligadoSolidarioConyuge !== "" && (
          <DatosPrincipalAccionistaConyuge
            modeloPm={modeloPm}
            axiosData={axiosData}
            aniosDomicilio={aniosDomicilio}
            mesesDomicilo={mesesDomicilo}
            anios={anios}
            esObligadoSolidario={esObligadoSolidarioConyuge}
            esObligadoSolidarioConyugeBD={esObligadoSolidarioConyugeBD}
            setEsObligadoSolidarioConyugeBD={
              setEsObligadoSolidarioConyugeBD
            }
            idTipoPersonaPAConyuge={
              principalAccionistaConyuge.idTipoPersona
            }
            setModeloPm={setModeloPm}
            setStyleHr={setStyleHr}
            handleSeccionPyme={handleSeccionPyme}
            handleRegresarSeccionPyme={handleRegresarSeccionPyme}
            setObligadosSolidariosInformacionBasica={
              setObligadosSolidariosInformacionBasica
            }
            setLoading={setLoading}
            setCantidadConyugeOS={setCantidadConyugeOS}
            cantidadConyugeOS={cantidadConyugeOS}
          />
        )}
      </div>
    </>
  );
};

export default PrincipalAccionistaConyuge;
