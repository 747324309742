import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import "./Tooltip.css";

const Tooltip = ({ text }) => {
  return (
    <div class="input__tooltip top">
      <AiOutlineQuestionCircle className="input__tooltip" />
      <span class="tiptext">{text}</span>
    </div>
  );
};

export default Tooltip;
