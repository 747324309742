import { FormControlLabel, Radio } from "@mui/material";
import React from "react";

const RadioInput = ({
  style = {},
  onChange = () => {},
  onClick = () => {},
  value = "",
  size,
  label,
  labelPosition,
  checked,
  labelStyles = {},
  name,
}) => {
  return (
    <>
      <FormControlLabel
        value={value}
        sx={{
          "&.MuiFormControlLabel-root": {
            ...labelStyles,
          },
        }}
        control={
          <Radio
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            onClick={onClick}
            style={{
              ...style,
            }}
            sx={{
              "&.Mui-checked": {
                color: "#eb0029",
              },
              "& .MuiSvgIcon-root": {
                fontSize: size,
              },
            }}
          />
        }
        label={label}
        labelPlacement={labelPosition}
      />
    </>
  );
};
export default RadioInput;
