import React, { useEffect } from "react";
import { useFormik } from "formik";
import OfertaPersonalizada from "./ofertaPersonalizada";
import {
  OFERTA_PERSONALIZADA_1,
  OFERTA_PERSONALIZADA_2,
  OFERTA_PERSONALIZADA_3,
  OFERTA_PERSONALIZADA_4,
  OFERTA_PERSONALIZADA_5,
  PANTALLA_VALIDACION_NUMERO_CORREO,
} from "../../../utils/constantes";
import { api_save_datos_campania_oferta } from "../../../api/api_servicios_rest";
import { sweetAlertError } from "../../helpers/alertas/alertas";
import { initialValues } from "../homeSections/registro/helpers/ofertaCampaniasHelper";

const OfertaCampanias = ({
  handleChangeOferta,
  oferta,
  selectedOferta,
  setSteps,
  ofertaCampanias,
  steps,
  Swal,
  setOfertaAceptada,
  ofertaAceptada,
  setIdOfertaSeleccionada,
}) => {
  const formik = useFormik({
    initialValues: initialValues(ofertaCampanias, oferta),
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: false,
    onSubmit: (formData) => {
      formData.numeroDeOferta.numero = oferta;
      salvarOfertaSeleccionada(formData);
    },
  });

  const salvarOfertaSeleccionada = async (formData) => {
    try {
      const response = await api_save_datos_campania_oferta(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setSteps(PANTALLA_VALIDACION_NUMERO_CORREO);
          setOfertaAceptada(true);
          setIdOfertaSeleccionada(data.idOfertaSeleccionada);
        } else {
          sweetAlertError("Ocurrió un error al guardar la oferta seleccionada");
        }
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ofertasPersonalizada1 = formik?.values?.personalizada1?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_1}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  const ofertasPersonalizada2 = formik?.values?.personalizada2?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_2}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  const ofertasPersonalizada3 = formik?.values?.personalizada3?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_3}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  const ofertasPersonalizada4 = formik?.values?.personalizada4?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_4}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  const ofertasPersonalizada5 = formik?.values?.personalizada5?.map(
    (item, index) => {
      return (
        <OfertaPersonalizada
          key={item.idOferta}
          item={item}
          index={index}
          setSteps={setSteps}
          oferta={OFERTA_PERSONALIZADA_5}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          formik={formik}
        />
      );
    }
  );

  useEffect(() => {
    if (steps === 2) setSteps(2);
  }, [steps]);

  return (
    <>
      <div className="bgc-white contenedor-ofertas">
        <div className="flecha-carrusel-ofertas">
          <i
            onClick={() => handleChangeOferta(false)}
            className="ri-arrow-left-s-line pointer icono-flecha"
          ></i>
        </div>
        <div className="ancho-80">
          <div className="row">
            <h1 className="text-center text-ofertas">
              ¡Tenemos ofertas para ti!
            </h1>
          </div>
          <form name={"oferta-campania"} onSubmit={formik.handleSubmit}>
            <input type="hidden" value={formik?.value?.selectedOferta} />
            <div className="row">
              {oferta === 0 || oferta === 1
                ? ofertasPersonalizada1
                : oferta === 2
                ? ofertasPersonalizada2
                : oferta === 3
                ? ofertasPersonalizada3
                : oferta === 4
                ? ofertasPersonalizada4
                : ofertasPersonalizada5}
            </div>
            <div className="row">
              <div className="campo-oferta-campania-tomar-oferta">
                <button className="bgc-red" type="submit">
                  Tomar esta oferta{" "}
                </button>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="campo-oferta-campania-text text-center">
              <span className="text-span-ofertas">
                Oferta {selectedOferta + 1}/
                {Object.keys(ofertaCampanias).length}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="campo-oferta-campania-no text-center">
              <span
                onClick={() => setSteps(2)}
                className="color-gray-2p pointer text-sin-oferta"
              >
                No quiero ninguna de estas ofertas
              </span>
            </div>
          </div>
        </div>
        <div className="flecha-carrusel-ofertas">
          <i
            onClick={() => handleChangeOferta(true)}
            className="ri-arrow-right-s-line pointer icono-flecha"
          ></i>
        </div>
      </div>
    </>
  );
};

export default OfertaCampanias;
