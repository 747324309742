import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  DisabledButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../basics/Buttons/Buttons";
import {
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
} from "../../../../utils/validaciones";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import Countdown, { zeroPad } from "react-countdown";
import {
  api_actualizar_datos_cliente_autoservicio,
  api_enviar_codigo,
  api_validar_codigo,
} from "../../../../api/api_servicios_rest";
import {
  sessionStorageAutoServicio,
  sessionStorageContinuarSolicitud,
} from "../../../../config/Variables";
import {
  sweetAlertError,
  sweetAlertWarning,
} from "../../../helpers/alertas/alertas";

const ValidacionDatos = ({ setLoadingInicio, isEmpty, setEmail, webView }) => {
  const navigate = useNavigate();

  // PHONE CHECKER INPUTS
  const [inputOne, setInputOne] = useState("");
  const [inputTwo, setInputTwo] = useState("");
  const [inputThree, setInputThree] = useState("");
  const [inputFour, setInputFour] = useState("");
  const [inputFive, setInputFive] = useState("");

  var inputOneRef = useRef();
  var inputTwoRef = useRef();
  var inputThreeRef = useRef();
  var inputFourRef = useRef();
  var inputFiveRef = useRef();

  const [reenvioCodigo, setReenvioCodigo] = useState(false);
  const [codigoEnviado, setCodigoEnviado] = useState(false);
  const [codigoLlamadaEnviado, setCodigoLlamadaEnviado] = useState(false);
  const [mostrarContador, setMostrarContador] = useState(false);
  const [mostrarLimite, setMostrarLimite] = useState(false);
  const [sid, setSid] = useState("");
  const [validarSms, setValidarSms] = useState("");
  const [codigoSmsBanorteDato, setCodigoSmsBanorte] = useState("");
  const [errorCodigoSms, setErrorCodigoSms] = useState("");
  const [valorInicialContador, setValorInicialContador] = useState("");
  const [intentosEnvioSms, setIntentosEnvioSms] = useState(0);

  const formikVal = useFormik({
    initialValues: initialValuesVal(),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchemaVal()),
    onSubmit: (formData) => {
      formikVal.setFieldValue("intentosEnvioSms", intentosEnvioSms);
      enviarCodigo(formData).then((response) => response);
    },
  });

  const enviarCodigo = async (formData) => {
    if (formikVal?.values?.numeroTelefono.length !== 12) return;

    try {
      const response = await api_enviar_codigo(formData);
        if (response.data.mensajeEnviado) {
          setSid(response.data.sid);
          setCodigoSmsBanorte(response.data.codigoBanorte);
          if(intentosEnvioSms==3){
            setCodigoLlamadaEnviado(true);
          }else{
            setCodigoEnviado(true);
          }
          setCodigoEnviado(true);
          setValidarSms(response.data.validarSms);
          setMostrarContador(true);
        } else {
          sweetAlertError(response.data.mensajeError);
        }
      if(intentosEnvioSms>3){
        setMostrarLimite(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verificarCodigoSMS = async () => {
    try {
      if (formikVal?.values?.numeroTelefono && formikVal?.values?.email) {
        const codigo =
          inputOneRef.current.value +
          inputTwoRef.current.value +
          inputThreeRef.current.value +
          inputFourRef.current.value +
          inputFiveRef.current.value;

        if (isEmpty(codigo)) {
          setErrorCodigoSms("Campo requerido.");
        } else {
          setLoadingInicio(true);
          const datosTelefono = {
            codigoSms: codigo,
            sid: sid,
            validarSms: validarSms,
          };
          const modeloClienteServicio = {
            idDatosTemporalesAutoservicio:
              formikVal?.values?.idDatosTemporalesAutoservicio,
            telefono: formikVal?.values?.numeroTelefono,
            correoElectronico: formikVal?.values?.email,
            codigoSmsBanorte: codigoSmsBanorteDato,
          };

          const response = await api_validar_codigo(datosTelefono);
          if (response?.data?.resultado === true) {
            sessionStorage.setItem(sessionStorageContinuarSolicitud, false);
            await actualizarDatosTemporalesAutoServicio(modeloClienteServicio);
          } else {
            setLoadingInicio(false);
            sweetAlertWarning("Código Incorrecto");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const actualizarDatosTemporalesAutoServicio = async (
    modeloClienteServicio
  ) => {
    try {
      const response = await api_actualizar_datos_cliente_autoservicio(
        modeloClienteServicio
      );
      if (response?.statusText === "OK") {
        const data = response?.data;
        if (data?.success) {
          sessionStorage.setItem(
            sessionStorageAutoServicio,
            data?.idDatosTemporalesAutoservicio
          );
          setLoadingInicio(false);

          if (webView) {
            navigate("/formularioBanorte");
          } else {
            navigate("/formulario");
          }
        } else {
          setLoadingInicio(false);
          sweetAlertWarning(data?.mensaje);
        }
      } else {
        setLoadingInicio(false);
        sweetAlertWarning("Error al actualizar datos del cliente");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkInputValid = (newValue, handle, nextElement) => {
    if (newValue === "") {
      handle(newValue);
      return;
    }

    const patern = /^[0-9]$/;

    if (patern.test(newValue)) {
      handle(newValue);
      if (nextElement === null) return;
      nextElement.current.focus();
    }
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setCodigoEnviado(false);
      setMostrarContador(false);
      return <span></span>;
    } else {
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  useEffect(() => {
    if (reenvioCodigo) {
      setReenvioCodigo(true);
    } else {
      setReenvioCodigo(false);
    }
  }, [reenvioCodigo]);

  const deleteCharacter = (e, inputBack) => {
    if (e.keyCode === 8) {
      if (e.target.value === "") {
        if (inputBack === null) return;
        inputBack.current.focus();
      }
    }
  };

  return (
    <div className="bgc-white bgc-white-div">
      <form className="bgc-white-form formVal--container">
        <h1 className="text-center bgc-white-h1 f-s-3">Validación de datos</h1>
        <InputWithError
          label="Correo electrónico *"
          value={formikVal.values.email}
          onChange={(e) => {
            let newErrors = formikVal.errors;
            delete newErrors?.email;
            formikVal.setErrors(newErrors);
            setEmail(e.target.value);
            formikVal.setFieldValue("email", e.target.value);
          }}
          type="email"
          InputProps={{ maxLength: 40 }}
          showErrorMessage={formikVal.errors.email}
          errorMessage={formikVal.errors.email}
        />
        <InputWithError
          label="Teléfono celular *"
          value={formikVal.values.numeroTelefono}
          onChange={(e) => {
            let newErrors = formikVal.errors;
            delete newErrors?.numeroTelefono;
            formikVal.setErrors(newErrors);

            let numTelefono = formatoTelefonoXX_XXXX_XXXX(
              e.target.value,
              formikVal?.values?.numeroTelefono
            );
            formikVal.setFieldValue("numeroTelefono", numTelefono);
          }}
          type="tel"
          InputProps={{ maxLength: 12 }}
          showErrorMessage={formikVal.errors.numeroTelefono}
          errorMessage={formikVal.errors.numeroTelefono}
        />
        {codigoEnviado ? (
          <DisabledButton>Enviar Código</DisabledButton>
        ) : (
          <SecondaryButton
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              formikVal.handleSubmit();
              setValorInicialContador(Date.now() + 60000);
              setIntentosEnvioSms(parseInt(intentosEnvioSms) + 1);
            }}
          >
            Enviar Código
          </SecondaryButton>
        )}

        <h3 className="f-s-3">Código SMS</h3>
        {codigoEnviado === true && (
          <p className="text-val-verification">
            Te acabamos de enviar un código de verificación por SMS, en caso de
            no recibirlo, es importante que verifiques que la configuración de
            bloqueo de SMS de tu teléfono no se encuentre activa.
          </p>
        )}
        {codigoLlamadaEnviado === true && (
          <p className="text-val-verification">
            Te acabamos de enviar un código de verificación por Llamada, en caso de
            no recibirlo, es importante que verifiques que la configuración de
            bloqueo de llamadas de tu teléfono no se encuentre activa.
          </p>
        )}
        {mostrarContador === true && (
          <Countdown date={valorInicialContador} renderer={renderer} />
        )}
        {mostrarLimite === true && (
          <p className="text-val-verification">
          Llego al limite de intentos, intentelo mas tarde.
          </p>
        )}
        <div className="no-wrap no-wrap-div">
          <input
            type="text"
            maxLength={1}
            ref={inputOneRef}
            onKeyDown={(e) => {
              deleteCharacter(e, null);
            }}
            onChange={(e) => {
              setErrorCodigoSms("");
              checkInputValid(e.target.value, setInputOne, inputTwoRef);
            }}
            value={inputOne}
            className="input-sapn-2"
          />
          <input
            type="text"
            maxLength={1}
            ref={inputTwoRef}
            onKeyDown={(e) => {
              deleteCharacter(e, inputOneRef);
            }}
            onChange={(e) => {
              checkInputValid(e.target.value, setInputTwo, inputThreeRef);
            }}
            value={inputTwo}
            className="input-sapn-2"
          />
          <input
            type="text"
            maxLength={1}
            ref={inputThreeRef}
            onKeyDown={(e) => {
              deleteCharacter(e, inputTwoRef);
            }}
            onChange={(e) => {
              checkInputValid(e.target.value, setInputThree, inputFourRef);
            }}
            value={inputThree}
            className="input-sapn-2"
          />
          <input
            type="text"
            maxLength={1}
            ref={inputFourRef}
            onKeyDown={(e) => {
              deleteCharacter(e, inputThreeRef);
            }}
            onChange={(e) => {
              checkInputValid(e.target.value, setInputFour, inputFiveRef);
            }}
            value={inputFour}
            className="input-sapn-2"
          />
          <input
            type="text"
            maxLength={1}
            ref={inputFiveRef}
            onKeyDown={(e) => {
              deleteCharacter(e, inputFourRef);
            }}
            onChange={(e) => {
              checkInputValid(e.target.value, setInputFive, null);
            }}
            value={inputFive}
            className="input-sapn-2"
          />
        </div>
        {errorCodigoSms ? (
          <span className="text-center text_red">{errorCodigoSms}</span>
        ) : null}
        <div className="div-width-100 buttonVal--container">
          {codigoEnviado ? (
            <PrimaryButton
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                verificarCodigoSMS();
              }}
            >
              Siguiente
            </PrimaryButton>
          ) : (
            <DisabledButton>Siguiente</DisabledButton>
          )}
        </div>
      </form>
    </div>
  );
};

export default ValidacionDatos;

export function initialValuesVal() {
  let idDatosTemp = parseInt(
    sessionStorage.getItem(sessionStorageAutoServicio)
  );
  return {
    idDatosTemporalesAutoservicio: idDatosTemp,
    numeroTelefono: "",
    email: "",
    intentosEnvioSms: "",
  };
}

export function validationSchemaVal() {
  return {
    numeroTelefono: Yup.string()
      .required("Dato requerido.")
      .test(
        "celular",
        "Celular capturado de forma incorrecta.",
        (item, testContext) => {
          return validarNumeroTelefono(testContext.parent.numeroTelefono);
        }
      ),
    email: Yup.string()
      .email("Correo eléctronico inválido")
      .required("Dato requerido."),
  };
}
