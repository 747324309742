import React, { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { BiCloudDownload } from "react-icons/bi";
import { PLAZO_CREDITO_REVOLVENTE, SECCION_ECONOMICOS } from "../../../../utils/constantes";

const ResumenInformacionCIEC = ({
  plazo,
  folioSolicitud,
  seccion
}) => {
  const [top, setTop] = useState(`${10}px`);  

  //Obtener el top del elemento en base al scroll que hace el usuario
  window.addEventListener("scroll", (e) => {
    let posicion = e.target.scrollingElement.scrollTop;
    let posicionMin = 460;
    if(plazo == PLAZO_CREDITO_REVOLVENTE){
      posicionMin = 200;
    }
    if(posicion > posicionMin){
      if(plazo == PLAZO_CREDITO_REVOLVENTE){
        posicion = posicion - 50;
      }else{
        posicion = posicion - 240;
      }
      setTop(`${posicion}px`);
    } else{      
      setTop(`10px`);
    }
  });

  return (
    <div className="card-cotizador-formulario" style={{ top }}>
      <h3 className="resumenCrediticio--titulo">Datos de la solicitud</h3>
      {folioSolicitud && (
        <>
          <h3>Folio: {folioSolicitud}</h3>
        </>
      )}

      <div className="resumenCrediactivo--buttons">
        <h5 className="resumenCrediticio--leyenda">En caso de que tengas alguna duda sobre el proceso, por favor comunícate al 800 890 2580 con tu folio {folioSolicitud}</h5>
      </div>

      {seccion == SECCION_ECONOMICOS  && (
        <>
          <h3>Información CIEC</h3>

          <h3>¿Qué es CIEC?</h3>
          <p className="p--texto_justify"> La CIEC o clave de identificación electrónica confidencial es una clave de acceso que está formada por tu RFC y una contraseña de tu elección. </p>
          <span className="pointer">
            <BiShow size="20px" /> &nbsp;
            Consulta        
          </span>
          <p className="p--texto_CIEC"> 
            Facturas emitidas y recibidas <br/>
            Constancia de situación Fiscal <br/>
            Declaraciones anuales
          </p>
          
          <hr/>

          <h3 className="h--texto_CIEC">¿Qué NO hacemos?</h3>
          <span className="pointer">
            <BiPencil size="20px" /> &nbsp; 
            Cambiar información     
          </span>
          <p className="p--texto_CIEC"> 
            Emitir, editar o cancelar facturas <br/>
            Modificaciones al RFC <br/>
            Presentación de declaraciones de impuestos 
          </p>
          <span className="pointer">
            <BiShow size="20px" /> &nbsp;
            Acceso a tus contraseñas        
          </span>
          <p className="p--texto_CIEC"> 
            Tus contraseñas se encuentras cifradas y protegidas por las disposiciones en materia de protección de datos.  
          </p>
        </>
      )}
    </div>
  );
};

export default ResumenInformacionCIEC;
