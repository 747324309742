export const obtenerNombreParticipante = (nombreParticipante) => {
    let listNombre = nombreParticipante.split(" ");
    let nombre =""

    if (listNombre.length > 0) {
      for (let i = 0; i < listNombre.length; i++) {
        nombre = nombre + listNombre[i].substring(0, 1).toUpperCase() + listNombre[i].substring(1).toLowerCase() + " "
      }
    } 

    return nombre = nombre.trim();
  };