import React, { useEffect, useState } from "react";
import {
  CASADO_SOCIEDAD_CONYUGAL,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  SECCION_HISTORIAL_PA_COYUGE,
  SECCION_HISTORIAL_PA,
  SECCION_HISTORIAL_SOLICITANTE_COYUGE,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  KEY_PM,
  ID_PM,
  KEY_PFAE,
} from "../../../utils/constantes";
import { api_obtener_os_solicitud } from "../../../api/api_servicios_rest";
import ConsultaTradicionalPM from "./PM/ConsultaTradicional/ConsultaTradicionalPM";
import ConsultaTradicionalPFAE from "./PFAE/ConsultaTradicional/ConsultaTradicionalPFAE";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../helpers/alertas/alertas";

const HistorialCrediticio = ({
  modeloDatos,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setStyleHr,
  setLoading,
  setShowResult,
  cantidadObligadosSolidarios,
  tipoPersona,
  setResultadoEvaluacionRiesgos,
  webView,
}) => {
  const [consultaAutenticada, setConsultaAutenticada] = useState(false);
  const [consultaTradicional, setConsultaTradicional] = useState(false);
  const [consultaPorPin, setConsultaPorPin] = useState(false);
  const [contadorOS, setContadorOS] = useState(0);

  const [selectedConsultaNip, setSelectedConsultaNip] = useState(1);
  const [selectedConsultaAutenticada, setSelectedConsultaAutenticada] =
    useState(1);
  const [selectedConsultaTradicional, setSelectedConsultaTradicional] =
    useState(1);
  const [selectedConsultaNipPFAE, setSelectedConsultaNipPFAE] = useState(1);
  const [selectedConsultaAutenticadaPFAE, setSelectedConsultaAutenticadaPFAE] =
    useState(1);
  const [selectedConsultaTradicionalPFAE, setSelectedConsultaTradicionalPFAE] =
    useState(1);
  const [options, setOptions] = useState([]);
  const [documentosSolicud, setDocumentosSolicud] = useState([]);
  const [obligadosSolidarios, setObligadosSolidarios] = useState([]);
  const [principalAccionista, setPrincipalAccionista] = useState();
  const [principalAccionistaConyuge, setPrincipalAccionistaConyuge] = useState();
  const [numOSSalto, setNumOSSalto] = useState();
  const [idEstadoCivilSolicitante, setIdEstadoCivilSolicitante] = useState();
  const [docPendientesSolicitante, setDocPendientesSolicitante] = useState();
  const [docPendientesSolicitanteConyuge, setDocPendientesSolicitanteConyuge] = useState();

  const obtenerObligadosSolidariosSolicitud = async () => {
    const datosCliente = {
      idCliente: modeloDatos.idCliente,
    };

    try {
      const response = await api_obtener_os_solicitud(datosCliente);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          modeloDatos.obligadosSolidarios = data.obligadosSolidarios;
          setObligadosSolidarios(data.obligadosSolidarios);
          setPrincipalAccionista(data.principalAccionista);
          setIdEstadoCivilSolicitante(data.idEstadoCivilSolicitante);
          if (
            data?.principalAccionista?.generales?.estadoCivilId ==
            CASADO_SOCIEDAD_CONYUGAL
          ) {
            setPrincipalAccionistaConyuge(data?.principalAccionista?.conyuge?.idConyuge);
            setNumOSSalto(4);
          } else {
            setNumOSSalto(3);
          }
          obtenerParticipanteDocPendiente(data);
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO == data.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerParticipanteDocPendiente = (datosParticipantes) => {
    var buscarSgteParticipante = false    
    setDocPendientesSolicitante(datosParticipantes.docPendientesSolicitante);
    setDocPendientesSolicitanteConyuge(datosParticipantes.docPendientesSolicitanteConyuge);
    if(tipoPersona === KEY_PM) {
      if (datosParticipantes.docPendientesSolicitante == false) {
        if (datosParticipantes.principalAccionista.documentosPendientes == false) {
          if (datosParticipantes.principalAccionista?.generales?.estadoCivilId === ESTADO_CIVIL_SOCIEDAD_CONYUGAL) {
            if (datosParticipantes.principalAccionista?.conyuge?.documentosPendientes == false) {
              buscarSgteParticipante = true;
            } else {
              setSelectedConsultaTradicional(SECCION_HISTORIAL_PA_COYUGE)
            }
          } else {
            buscarSgteParticipante = true;
          }
        } else {
          setSelectedConsultaTradicional(SECCION_HISTORIAL_PA);
        }

        if(buscarSgteParticipante === true){
          for(var i=0; i < datosParticipantes.obligadosSolidarios.length; i++){
            var item = datosParticipantes.obligadosSolidarios[i];
            if(parseInt(item.tipoPersonaKey) === ID_PM){
              if(item?.obligadoSolidarioPM?.idObligadoSolidarioo !== undefined &&
                item?.obligadoSolidarioPM?.documentosPendientes == true){
                setSelectedConsultaTradicional(item?.obligadoSolidarioPM?.idObligadoSolidario);
                break;
              }  
            }else{
              if(item?.obligadoSolidario?.idObligadoSolidario !== undefined &&
                item?.obligadoSolidario?.documentosPendientes == false){
                  if(item?.obligadoSolidario?.conyuge.idConyuge !==undefined &&
                    item?.obligadoSolidario?.conyuge.generales?.esObligadoSolidario &&
                    item?.obligadoSolidario?.conyuge.documentosPendientes == true){
                    setSelectedConsultaTradicional(
                      item?.obligadoSolidario?.conyuge?.idConyuge
                    )
                    break;
                  }
              } else{
                setSelectedConsultaTradicional(item?.obligadoSolidario?.idObligadoSolidario);
                break;
              }
            }  
              
          }
        }
      } else {
        setSelectedConsultaTradicional(1);
      }    
    }else{
      if (datosParticipantes.docPendientesSolicitante == false) {
        if (datosParticipantes.idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL) {
          if (datosParticipantes.docPendientesSolicitanteConyuge == false) {
            buscarSgteParticipante = true;
          } else {
            setSelectedConsultaTradicionalPFAE(SECCION_HISTORIAL_SOLICITANTE_COYUGE);
          }
        } else {
          buscarSgteParticipante = true;
        }
      } else{
        setSelectedConsultaTradicionalPFAE(1);
      } 
      
      if(buscarSgteParticipante === true){
        for(var i=0; i < datosParticipantes.obligadosSolidarios.length; i++){
          var item = datosParticipantes.obligadosSolidarios[i];
          if(parseInt(item.tipoPersonaKey) === ID_PM){
            if(item?.obligadoSolidarioPM?.idObligadoSolidario !== undefined &&
              item?.obligadoSolidarioPM?.documentosPendientes == true){
                setSelectedConsultaTradicionalPFAE(item?.obligadoSolidarioPM?.idObligadoSolidario);
              break;
            }  
          }else{
            if(item?.obligadoSolidario?.idObligadoSolidario !== undefined &&
              item?.obligadoSolidario?.documentosPendientes == false){
                if(item?.obligadoSolidario?.conyuge.idConyuge !== undefined &&
                  item?.obligadoSolidario?.conyuge.generales?.esObligadoSolidario &&
                  item?.obligadoSolidario?.conyuge.documentosPendientes == true){
                  setSelectedConsultaTradicionalPFAE(
                    item?.obligadoSolidario?.conyuge?.idConyuge
                  )
                  break;
                }
            } else{
              setSelectedConsultaTradicionalPFAE(item?.obligadoSolidario?.idObligadoSolidario);
              break;
            }
          }    
        }
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    let letOptions = [];

    for (let i = 0; i < 4; i++) {
      letOptions.push(i);
    }
    setOptions(letOptions);
    obtenerObligadosSolidariosSolicitud()
      .then((r) => setLoading(false))
      .catch((e) => console.error(e));
  }, [cantidadObligadosSolidarios]);

  return (
    <>
      {tipoPersona === KEY_PM && (
        <ConsultaTradicionalPM
          setLoading={setLoading}
          setStyleHr={setStyleHr}
          contadorOS={contadorOS}
          tipoPersona={tipoPersona}
          modeloDatos={modeloDatos}
          setShowResult={setShowResult}
          setContadorOS={setContadorOS}
          handleSeccionPyme={handleSeccionPyme}
          handleRegresarSeccionPyme={handleRegresarSeccionPyme}
          principalAccionista={principalAccionista}
          obligadosSolidarios={obligadosSolidarios}
          idEstadoCivilSolicitante={idEstadoCivilSolicitante}
          cantidadObligadosSolidarios={cantidadObligadosSolidarios}
          selectedConsultaTradicional={selectedConsultaTradicional}
          setSelectedConsultaTradicional={setSelectedConsultaTradicional}
          setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
          docPendientesSolicitante={docPendientesSolicitante}
          docPendientesSolicitanteConyuge={docPendientesSolicitanteConyuge}
          webView={webView}
        />
      )}

      {tipoPersona === KEY_PFAE && (
        <ConsultaTradicionalPFAE
          setLoading={setLoading}
          setStyleHr={setStyleHr}
          contadorOS={contadorOS}
          tipoPersona={tipoPersona}
          modeloDatos={modeloDatos}
          setShowResult={setShowResult}
          setContadorOS={setContadorOS}
          handleSeccionPyme={handleSeccionPyme}
          handleRegresarSeccionPyme={handleRegresarSeccionPyme}
          obligadosSolidarios={obligadosSolidarios}
          idEstadoCivilSolicitante={idEstadoCivilSolicitante}
          cantidadObligadosSolidarios={cantidadObligadosSolidarios}
          selectedConsultaTradicionalPFAE={selectedConsultaTradicionalPFAE}
          setSelectedConsultaTradicionalPFAE={setSelectedConsultaTradicionalPFAE}
          setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
          docPendientesSolicitante={docPendientesSolicitante}
          docPendientesSolicitanteConyuge={docPendientesSolicitanteConyuge}
          webView={webView}
        />
      )}
    </>
  );
};

export default HistorialCrediticio;
