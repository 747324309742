import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import ErrorMessage from "../../helpers/ErrorMessage/ErrorMessage";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#323e48",
  },
});

function InputTextAreaWithError({
  value = "",
  onChange = () => {},
  onBlur = () => {},
  label = "",
  type = "text",
  placeholder = "",
  required = false,
  helperText = "",
  error = false,
  showErrorMessage = false,
  errorMessage = "",
  InputProps,
}) {
  return (
    <>
      <div className="input__container">
        <div className="d-flex gap-2 w-500 align-items-center text-area-cie">
          <CssTextField
            multiline
            rows={5}
            maxRows={6}
            variant="filled"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            id="filled-basic"
            label={label}
            type={type}
            placeholder={placeholder}
            required={required}
            helperText={helperText}
            error={error}
            InputProps={InputProps}
            className="text-area-ciec"
          />
        </div>
        {showErrorMessage && <ErrorMessage message={errorMessage} />}
      </div>
    </>
  );
}
export default InputTextAreaWithError;
