import React, { useEffect, useState } from "react";
import CargarDocumentoSolicitantePM from "./CargarDocumentoSolicitantePM";
import CargarDocumentoPrincipalAccionista from "./CargarDocumentoPrincipalAccionista";
import CargarDocumentoPrincipalAccionistaConyuge from "./CargarDocumentoPrincipalAccionistaConyuge";
import CargarDocumentoOSPMPM from "./CargarDocumentoOSPMPM";
import CargarDocumentoOSPFAEPM from "./CargarDocumentoOSPFAEPM";
import CargarDocumentoOSPFAEPMConyuge from "./CargarDocumentoOSPFAEPMConyuge";

import {
  api_obtener_os_solicitud,
  api_obtener_documentos_solicitud_pm,
  api_validar_etapa_uno,
} from "../../../../../api/api_servicios_rest";

import {
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  KEY_PM,
  ID_PM,
  OS_CERO,
} from "../../../../../utils/constantes";
import { sweetAlertError } from "../../../../helpers/alertas/alertas";

const CargaDocumentosPM = ({
  setShowResult,
  tipoPersona,
  cantidadObligadosSolidarios,
  modeloPm,
  Swal,
  setLoading,
}) => {
  const [selectedDocumentos, setSelectedDocumentos] = useState(1);
  const [options, setOptions] = useState([]);
  const [documentosSolicud, setDocumentosSolicud] = useState([]);
  const [obligadosSolidarios, setObligadosSolidarios] = useState([]);
  const [principalAccionista, setPrincipalAccionista] = useState();
  const [numOSSalto, setNumOSSalto] = useState();

  useEffect(() => {
    setLoading(true);

    let letOptions = [];

    for (let i = 0; i < 4; i++) {
      letOptions.push(i);
    }
    setOptions(letOptions);

    obtenerDocumentosSolicitud();
    obtenerObligadosSolidariosSolicitud();
  }, [cantidadObligadosSolidarios]);

  const obtenerDocumentosSolicitud = async () => {
    try {
      const response = await api_obtener_documentos_solicitud_pm();
      if (response.statusText === "OK") {
        const data = response.data;
        setDocumentosSolicud(data);
      } else {
        sweetAlertError("Ocurrió un error al obtener documentos solicitud");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerObligadosSolidariosSolicitud = async () => {
    const datosCliente = {
      idCliente: modeloPm.idCliente,
    };
    try {
      const response = await api_obtener_os_solicitud(datosCliente);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          modeloPm.obligadosSolidarios = data.obligadosSolidarios;
          setObligadosSolidarios(data.obligadosSolidarios);
          setPrincipalAccionista(data.principalAccionista);
          if (data.principalAccionista?.generales?.estadoCivilId == 2) {
            setNumOSSalto(4);
          } else {
            setNumOSSalto(3);
          }
        } else {
          sweetAlertError("Ocurrió un error al obtener obligados solidarios");
        }
      } else {
        return false;
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const obtenerPestaniaSgteCargaDocumentos = (idCliente, numOS) => {
    let obligadoSolidarioSgte = null;
    let avanzarCargaDoc = false;
    let cambioStatus = false;
    let descartaOS = false;
    setLoading(true);

    if (numOS == OS_CERO) {
      if (modeloPm?.idCliente == idCliente) {
        setSelectedDocumentos(2);
        descartaOS = true;
      } else {
        if (
          principalAccionista?.idPrincipalAccionista == idCliente &&
          principalAccionista?.generales.estadoCivilId ===
            ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSelectedDocumentos(3);
          descartaOS = true;
        } else {
          obligadoSolidarioSgte = obligadosSolidarios?.filter(
            (e) => e.numero === numOS + 1
          );
        }
      }
    } else {
      if (obligadosSolidarios.length > 0) {
        let obligadoSolidarioActual = obligadosSolidarios?.filter(
          (e) => e.numero === numOS
        );

        if (parseInt(obligadoSolidarioActual[0].tipoPersonaKey) !== ID_PM) {
          if (
            obligadoSolidarioActual[0]?.obligadoSolidario
              ?.idObligadoSolidario == idCliente &&
            obligadoSolidarioActual[0]?.obligadoSolidario?.generales
              ?.estadoCivilId == ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
            obligadoSolidarioActual[0]?.obligadoSolidario?.conyuge.generales
              ?.esObligadoSolidario
          ) {
            setSelectedDocumentos(
              obligadoSolidarioActual[0]?.obligadoSolidario?.conyuge?.idConyuge
            );
            avanzarCargaDoc = true;
            descartaOS = true;
          }
        }
        if (avanzarCargaDoc == false) {
          obligadoSolidarioSgte = obligadosSolidarios?.filter(
            (e) => e.numero === numOS + 1
          );
        }
      } else {
        cambioStatus = true;
      }
    }

    if (obligadoSolidarioSgte != null && obligadoSolidarioSgte.length != 0) {
      if (parseInt(obligadoSolidarioSgte[0].tipoPersonaKey) !== ID_PM) {
        setSelectedDocumentos(
          obligadoSolidarioSgte[0]?.obligadoSolidario?.idObligadoSolidario
        );
      } else {
        setSelectedDocumentos(
          obligadoSolidarioSgte[0]?.obligadoSolidarioPM?.idObligadoSolidario
        );
      }
    } else {
      cambioStatus = true;
    }

    if (cambioStatus == true && descartaOS == false) {
      avanzarValidacionDocumental(modeloPm?.idCliente);
    } else {
      setLoading(false);
    }
  };

  const avanzarValidacionDocumental = async (idCliente) => {
    try {
      const response = await api_validar_etapa_uno(idCliente);
      if (response.statusText === "OK") {
        if (response.data.success) {
          if (response.data.validacionDocumentos.success) {
            setShowResult(true);
          } else {
            sweetAlertError(response.data.validacionDocumentos.mensaje);
          }
        } else {
          sweetAlertError(response?.data?.mensaje);
        }
      } else {
        sweetAlertError("Ocurrió un error al avanzar a validación etapa uno");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="container navigation"
        style={{
          overflow: "scroll",
          display: "flex",
          alignItems: "center",
          marginTop: "24px",
          gap: "24px",
        }}
      >
        {tipoPersona === KEY_PM && (
          <>
            <p
              className={`${
                selectedDocumentos === 1 ? "bgc-red radius strong-shadow" : ""
              } pointer`}
              onClick={() => setSelectedDocumentos(1)}
              style={{ padding: "8px 16px" }}
            >
              Solicitante
            </p>
            <p
              className={`${
                selectedDocumentos === 2 ? "bgc-red radius strong-shadow" : ""
              } pointer`}
              onClick={() => setSelectedDocumentos(2)}
              style={{ padding: "8px 16px" }}
            >
              PA
            </p>
            {principalAccionista?.generales?.estadoCivilId ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL && (
              <p
                className={`${
                  selectedDocumentos === 3 ? "bgc-red radius strong-shadow" : ""
                } pointer`}
                onClick={() => setSelectedDocumentos(3)}
                style={{ padding: "8px 16px" }}
              >
                Cónyuge PA
              </p>
            )}
            {obligadosSolidarios.map((item, index) => {
              return (
                <div
                  key={
                    item?.obligadoSolidario?.conyuge?.idConyuge
                      ? item?.obligadoSolidario?.conyuge?.idConyuge
                      : index
                  }
                >
                  {item?.obligadoSolidario?.idObligadoSolidario !==
                    undefined && (
                    <p
                      className={`${
                        selectedDocumentos ===
                        item?.obligadoSolidario?.idObligadoSolidario
                          ? "bgc-red radius strong-shadow"
                          : ""
                      } pointer`}
                      onClick={() => {
                        setSelectedDocumentos(
                          item?.obligadoSolidario?.idObligadoSolidario
                        );
                      }}
                      style={{ padding: "8px 16px" }}
                    >
                      OS{index + 1}
                    </p>
                  )}
                  {item?.obligadoSolidarioPM?.idObligadoSolidario !==
                    undefined && (
                    <p
                      className={`${
                        selectedDocumentos ===
                        item?.obligadoSolidarioPM?.idObligadoSolidario
                          ? "bgc-red radius strong-shadow"
                          : ""
                      } pointer`}
                      onClick={() => {
                        setSelectedDocumentos(
                          item?.obligadoSolidarioPM?.idObligadoSolidario
                        );
                      }}
                      style={{ padding: "8px 16px" }}
                    >
                      OS{index + 1}
                    </p>
                  )}

                  {item?.obligadoSolidario?.conyuge.idConyuge !== undefined &&
                    item?.obligadoSolidario?.conyuge.generales
                      ?.esObligadoSolidario && (
                      <p
                        className={`${
                          selectedDocumentos ===
                          item?.obligadoSolidario?.conyuge?.idConyuge
                            ? "bgc-red radius strong-shadow"
                            : ""
                        } pointer`}
                        onClick={() => {
                          setSelectedDocumentos(
                            item?.obligadoSolidario?.conyuge?.idConyuge
                          );
                        }}
                        style={{ padding: "8px 16px" }}
                      >
                        Cónyuge OS{index + 1}
                      </p>
                    )}
                </div>
              );
            })}
          </>
        )}
      </div>

      {selectedDocumentos === 1 && (
        <CargarDocumentoSolicitantePM
          idSolicitud={modeloPm?.idSolicitud}
          documentosSolicitante={documentosSolicud.documentosSolicitantePM}
          setLoading={setLoading}
          obtenerPestaniaSgteCargaDocumentos={
            obtenerPestaniaSgteCargaDocumentos
          }
          idCliente={modeloPm?.idCliente}
        />
      )}
      {selectedDocumentos === 2 && (
        <CargarDocumentoPrincipalAccionista
          idSolicitud={modeloPm?.idSolicitud}
          documentosPA={documentosSolicud.documentosObligadoSolidarioPFAE}
          setLoading={setLoading}
          obtenerPestaniaSgteCargaDocumentos={
            obtenerPestaniaSgteCargaDocumentos
          }
          idCliente={principalAccionista.idPrincipalAccionista}
        />
      )}
      {selectedDocumentos === 3 &&
        principalAccionista?.generales?.estadoCivilId ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL && (
          <CargarDocumentoPrincipalAccionistaConyuge
            idSolicitud={modeloPm?.idSolicitud}
            documentosConyugePA={
              documentosSolicud.documentosObligadoSolidarioPFAE
            }
            setLoading={setLoading}
            obtenerPestaniaSgteCargaDocumentos={
              obtenerPestaniaSgteCargaDocumentos
            }
            idCliente={principalAccionista?.conyuge?.idConyuge}
          />
        )}
      {obligadosSolidarios !== undefined &&
        obligadosSolidarios.map((item, index) => {
          return (
            <div
              key={
                item?.obligadoSolidario?.conyuge?.generales?.idCliente
                  ? item?.obligadoSolidario?.conyuge?.generales?.idCliente
                  : index
              }
            >
              {selectedDocumentos ===
                item?.obligadoSolidario?.generales?.idCliente && (
                <CargarDocumentoOSPFAEPM
                  documentosOS={
                    documentosSolicud.documentosObligadoSolidarioPFAE
                  }
                  numOS={item?.numero}
                  idSolicitud={modeloPm?.idSolicitud}
                  setLoading={setLoading}
                  obtenerPestaniaSgteCargaDocumentos={
                    obtenerPestaniaSgteCargaDocumentos
                  }
                  idCliente={item?.obligadoSolidario?.idObligadoSolidario}
                />
              )}
              {selectedDocumentos ===
                item?.obligadoSolidarioPM?.generales?.idCliente && (
                <CargarDocumentoOSPMPM
                  documentosOS={documentosSolicud.documentosObligadoSolidarioPM}
                  numOS={item?.numero}
                  idSolicitud={modeloPm?.idSolicitud}
                  setLoading={setLoading}
                  obtenerPestaniaSgteCargaDocumentos={
                    obtenerPestaniaSgteCargaDocumentos
                  }
                  idCliente={item?.obligadoSolidarioPM?.idObligadoSolidario}
                />
              )}

              {selectedDocumentos ===
                item?.obligadoSolidario?.conyuge.generales?.idCliente && (
                <CargarDocumentoOSPFAEPMConyuge
                  documentosConyugeOS={
                    documentosSolicud.documentosObligadoSolidarioPFAE
                  }
                  numOS={item?.numero}
                  idSolicitud={modeloPm?.idSolicitud}
                  setLoading={setLoading}
                  obtenerPestaniaSgteCargaDocumentos={
                    obtenerPestaniaSgteCargaDocumentos
                  }
                  idCliente={item?.obligadoSolidario?.conyuge?.idConyuge}
                />
              )}
            </div>
          );
        })}
    </>
  );
};

export default CargaDocumentosPM;
