import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  api_save_datos_domicilio_comercial_PFAE,
  api_direccion_by_cp,
} from "../../../../api/api_servicios_rest_crediactivo";
import {
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
} from "../../../../utils/validaciones";
import {
  SECCION_DOMICILIO_COMERCIAL,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../../utils/constantes";
import {
  validateValue,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../functions/fieldFunctions";
import FormLayout from "../../../helpers/FormLayout/FormLayout";
import SelectWithError from "../../../../components/basics/SelectWithError/SelectWithError";
import InputWithError from "../../../../components/basics/InputWithError/InputWithError";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../basics/Buttons/Buttons";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import SelectWithErrorRepresentanteLegal from "../../../basics/SelectWithError/SelectWithErrorRepresentanteLegal";
import {
  sweetAlertError,
  sweetAlertWithConfirmedCrediactivo,
} from "../alertas/alertas";

const DomicilioComercialPFAE = ({
  modeloPfae,
  setModeloPfae,
  axiosData,
  anios,
  domicilioFiscalIgual,
  setDomicilioFiscalIgual,
  setLoading,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
}) => {
  const [coloniasCP, setColoniasCP] = useState([]);

  const formik = useFormik({
    initialValues: initialValues(modeloPfae, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosDomicilioComercialPFAE(formData);
    },
  });

  const handleChangeDomicilioFiscal = (event) => {
    if (event.target.value !== undefined) {
      const valor = JSON.parse(event.target.value);
      setDomicilioFiscalIgual(valor);
      if (valor) {
        setearDatosDomicilioComercialSolictudPFAE(valor);
      } else {
        setearVacioDatosDomicilioComercial();
      }

      formik.setFieldValue("domicilioComercial.igualDomicilioFiscal", valor);
    }
  };

  const setearDatosDomicilioComercialSolictudPFAE = (domicilioFiscalIgual) => {
    if (modeloPfae.domicilioFiscal.codigoPostal !== null) {
      obtenerDireccionByCp(
        modeloPfae.domicilioFiscal.codigoPostal,
        domicilioFiscalIgual
      );
    }

    formik.setFieldValue(
      "domicilioComercial.calle",
      modeloPfae.domicilioFiscal.calle
    );
    formik.setFieldValue(
      "domicilioComercial.numeroExterior",
      modeloPfae.domicilioFiscal.numeroExterior
    );
    formik.setFieldValue(
      "domicilioComercial.numeroInterior",
      modeloPfae.domicilioFiscal.numeroInterior
    );
    formik.setFieldValue(
      "domicilioComercial.codigoPostal",
      modeloPfae.domicilioFiscal.codigoPostal
    );
    formik.setFieldValue(
      "domicilioComercial.colonia",
      modeloPfae.domicilioFiscal.colonia
    );
    formik.setFieldValue(
      "domicilioComercial.municipioId",
      modeloPfae.domicilioFiscal.municipioId
    );
    formik.setFieldValue(
      "domicilioComercial.estadoId",
      modeloPfae.domicilioFiscal.estadoId
    );

    let newErrors = formik.errors;
    delete newErrors?.domicilioComercial?.calle;
    delete newErrors?.domicilioComercial?.numeroExterior;
    delete newErrors?.domicilioComercial?.numeroInterior;
    delete newErrors?.domicilioComercial?.codigoPostal;
    delete newErrors?.domicilioComercial?.colonia;
    delete newErrors?.domicilioComercial?.municipioId;
    delete newErrors?.domicilioComercial?.estadoId;
    formik.setErrors(newErrors);

    setModeloPfae({
      ...modeloPfae,
      domicilioComercial: {
        ...modeloPfae.domicilioComercial,
        calle: modeloPfae.domicilioFiscal.calle,
        numeroExterior: modeloPfae.domicilioFiscal.numeroExterior,
        numeroInterior: modeloPfae.domicilioFiscal.numeroInterior,
        codigoPostal: modeloPfae.domicilioFiscal.codigoPostal,
        colonia: modeloPfae.domicilioFiscal.colonia,
        municipioId: modeloPfae.domicilioFiscal.municipioId,
        estadoId: modeloPfae.domicilioFiscal.estadoId,
      },
    });
  };

  const setearVacioDatosDomicilioComercial = () => {
    modeloPfae.domicilioComercial.calle = "";
    modeloPfae.domicilioComercial.numeroExterior = "";
    modeloPfae.domicilioComercial.numeroInterior = "";
    modeloPfae.domicilioComercial.codigoPostal = "";
    modeloPfae.domicilioComercial.colonia = "";
    modeloPfae.domicilioComercial.municipioId = "";
    modeloPfae.domicilioComercial.estadoId = "";
  };

  const obtenerDireccionByCp = async (codigoPostal, domicilioFiscalIgual) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK") {
        if (response.data.municipio !== undefined) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "domicilioComercial.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue(
            "domicilioComercial.estadoId",
            response.data.estado.id
          );
          formik.setFieldValue(
            "domicilioComercial.colonia",
            response.data.asentamientos[0]
          );

          if (modeloPfae.domicilioComercial.idDatosDelNegocio !== null) {
            formik.setFieldValue(
              "domicilioComercial.colonia",
              modeloPfae.domicilioComercial.colonia
            );
          } else {
            if (domicilioFiscalIgual) {
              formik.setFieldValue(
                "domicilioComercial.colonia",
                modeloPfae.domicilioFiscal.colonia
              );
            } else {
              formik.setFieldValue(
                "domicilioComercial.colonia",
                response.data.asentamientos[0]
              );
            }
          }

          formik.setFieldValue(
            "domicilioComercial.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response.data.municipio.id
            )
          );
          formik.setFieldValue(
            "domicilioComercial.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response.data.estado.id
            )
          );

          let newErrors = formik.errors;
          delete newErrors?.domicilioComercial?.municipioId;
          delete newErrors?.domicilioComercial?.estadoId;
          delete newErrors?.domicilioComercial?.colonia;
          formik.setErrors(newErrors);
        } else {
          limpiarDireccionByCp();
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue("domicilioComercial.municipioId", "");
    formik.setFieldValue("domicilioComercial.municipioObj", "");
    formik.setFieldValue("domicilioComercial.estadoId", "");
    formik.setFieldValue("domicilioComercial.estadoObj", "");
    formik.setFieldValue("domicilioComercial.colonia", "");
  };

  const salvarDatosDomicilioComercialPFAE = async (formData) => {
    try {
      const response = await api_save_datos_domicilio_comercial_PFAE(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPfae({
            ...modeloPfae,
            idCliente: data.clienteId,
            domicilioComercial: data.domicilioComercial,
          });
          handleSeccionPyme(SECCION_DOMICILIO_COMERCIAL);
          formik.setFieldValue(
            "domicilioComercial.idDatosDelNegocio",
            data.domicilioComercialId
          );
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data.mensaje) {
            sweetAlertWithConfirmedCrediactivo(data?.mensaje);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertError(
          "Ocurrió un error al guardar datos domicilio comercial PFAE"
        );
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      modeloPfae.domicilioComercial !== null &&
      modeloPfae.domicilioComercial !== undefined
    ) {
      if (
        modeloPfae.domicilioComercial.codigoPostal !== null &&
        modeloPfae.domicilioComercial.codigoPostal !== undefined
      ) {
        obtenerDireccionByCp(modeloPfae.domicilioComercial.codigoPostal);
      }

      setDomicilioFiscalIgual(
        modeloPfae.domicilioComercial.igualDomicilioFiscal
      );
    }
  }, []);

  return (
    <>
      <form name="form-domicilio-comercial" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">
            Información de domicilio comercial
            <div className="input__tooltip_titulo top">
              <AiOutlineQuestionCircle className="input__tooltip_titulo" />
              <span className="tiptext">
                En caso de que tengas varias sucursales o domicilios
                comerciales, captura el principal
              </span>
            </div>
          </h2>
          <FormLayout>
            <SelectWithErrorRepresentanteLegal
              label="¿Es el mismo que el domicilio fiscal? *"
              name="domicilioComercial.igualDomicilioFiscal"
              value={
                domicilioFiscalIgual
                  ? { label: "Si", value: true }
                  : { label: "No", value: false }
              }
              onChange={(e) => {
                const event = { ...e, target: { value: e.value } };
                handleChangeDomicilioFiscal(event);
              }}
              options={[
                { label: "Si", value: true },
                { label: "No", value: false },
              ]}
            />
            <InputWithError
              label="Calle *"
              name="domicilioComercial.calle"
              value={formik.values?.domicilioComercial?.calle}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.calle
                ) {
                  delete newErrors?.domicilioComercial?.calle;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "domicilioComercial.calle",
                    e.target.value
                  );
                }
              }}
              disabled={domicilioFiscalIgual}
              InputProps={{ maxLength: 24 }}
              showErrorMessage={formik.errors?.domicilioComercial?.calle}
              errorMessage={formik.errors?.domicilioComercial?.calle}
            />
            <InputWithError
              label="Número exterior *"
              name="domicilioComercial.numeroExterior"
              value={formik.values?.domicilioComercial?.numeroExterior}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.numeroExterior
                ) {
                  delete newErrors?.domicilioComercial?.numeroExterior;
                  formik.setErrors(newErrors);
                }
                if (notSpecialCaractersConGuion(e.target.value)) {
                  formik.setFieldValue(
                    "domicilioComercial.numeroExterior",
                    e.target.value
                  );
                }
              }}
              disabled={domicilioFiscalIgual}
              InputProps={{ maxLength: 7 }}
              showErrorMessage={
                formik.errors?.domicilioComercial?.numeroExterior
              }
              errorMessage={formik.errors?.domicilioComercial?.numeroExterior}
            />
            <InputWithError
              label="Número interior"
              name="domicilioComercial.numeroInterior"
              value={formik.values?.domicilioComercial?.numeroInterior}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.numeroInterior
                ) {
                  delete newErrors?.domicilioComercial?.numeroInterior;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "domicilioComercial.numeroInterior",
                    e.target.value
                  );
                }
              }}
              disabled={domicilioFiscalIgual}
              InputProps={{ maxLength: 2 }}
              tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu  “Número exterior” separados por un guion Ejemplo: 318-123B"
              showErrorMessage={
                formik.errors?.domicilioComercial?.numeroInterior
              }
              errorMessage={formik.errors?.domicilioComercial?.numeroInterior}
            />
            <InputWithError
              label="CP *"
              name="domicilioComercial.codigoPostal"
              value={formik.values?.domicilioComercial?.codigoPostal}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.codigoPostal
                ) {
                  delete newErrors?.domicilioComercial?.codigoPostal;
                  formik.setErrors(newErrors);
                }
                if (
                  validateValue(e.target.value, "number") &&
                  e.target.value.length <= 5
                ) {
                  formik.setFieldValue(
                    "domicilioComercial.codigoPostal",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length === 5) {
                  let newErrors = formik.errors;
                  if (newErrors?.domicilioComercial !== undefined) {
                    if (newErrors?.domicilioComercial?.colonia) {
                      delete newErrors?.domicilioComercial?.colonia;
                    }
                    if (newErrors?.domicilioComercial?.municipioId) {
                      delete newErrors?.domicilioComercial?.municipioId;
                    }
                    if (newErrors?.domicilioComercial?.estadoId) {
                      delete newErrors?.domicilioComercial?.estadoId;
                    }
                    formik.setErrors(newErrors);
                  }
                  obtenerDireccionByCp(e.target.value);
                } else {
                  limpiarDireccionByCp();
                }
              }}
              type="text"
              disabled={domicilioFiscalIgual}
              InputProps={{ maxLength: 5 }}
              showErrorMessage={formik.errors?.domicilioComercial?.codigoPostal}
              errorMessage={formik.errors?.domicilioComercial?.codigoPostal}
            />
            <SelectWithError
              label="Colonia *"
              name="domicilioComercial.colonia"
              value={{
                label: formik?.values?.domicilioComercial?.colonia,
                value: formik?.values?.domicilioComercial?.colonia,
              }}
              onChange={(e) => {
                formik.setFieldValue("domicilioComercial.colonia", e.label);
              }}
              options={coloniasCP?.map((e) => {
                return { ...e, label: e, value: e };
              })}
              showErrorMessage={formik.errors?.domicilioComercial?.colonia}
              errorMessage={formik.errors?.domicilioComercial?.colonia}
            />
            <SelectWithError
              label="Alcaldía / Municipio *"
              name="domicilioComercial.municipioId"
              value={{
                label:
                  formik?.values?.domicilioComercial?.municipioObj[0]?.nombre,
                value:
                  formik?.values?.domicilioComercial?.municipioObj[0]?.nombre,
              }}
              onChange={(e) => {
                formik.setFieldValue("domicilioComercial.municipioId", e.id);
                formik.setFieldValue("domicilioComercial.municipioObj", [e]);
              }}
              isDisabled={true}
              options={axiosData?.generales?.municipios?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.domicilioComercial?.municipioId}
              errorMessage={formik.errors?.domicilioComercial?.municipioId}
            />
            <SelectWithError
              label="Estado *"
              name="domicilioComercial.estadoId"
              value={{
                label: formik?.values?.domicilioComercial?.estadoObj[0]?.nombre,
                value: formik?.values?.domicilioComercial?.estadoObj[0]?.nombre,
              }}
              onChange={(e) => {
                formik.setFieldValue("domicilioComercial.estadoId", e.id);
                formik.setFieldValue("domicilioComercial.estadoObj", [e]);
              }}
              isDisabled={true}
              options={axiosData.generales.estados.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.domicilioComercial?.estadoId}
              errorMessage={formik.errors?.domicilioComercial?.estadoId}
            />
            <InputWithError
              label="Teléfono *"
              name="domicilioComercial.telefono"
              value={formik.values?.domicilioComercial?.telefono}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.telefono
                ) {
                  delete newErrors?.domicilioComercial?.telefono;
                  formik.setErrors(newErrors);
                }
                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.domicilioComercial?.telefono
                );
                formik.setFieldValue(
                  "domicilioComercial.telefono",
                  numTelefono
                );
              }}
              InputProps={{ maxLength: 12 }}
              type="tel"
              showErrorMessage={formik.errors?.domicilioComercial?.telefono}
              errorMessage={formik.errors?.domicilioComercial?.telefono}
            />
            <SelectWithError
              label="Años de residencia en el domicilio comercial *"
              menuPlacement="top"
              name="domicilioComercial.tiempoResidencia"
              value={{
                label: formik?.values?.domicilioComercial?.tiempoResidencia,
                value: formik?.values?.domicilioComercial?.tiempoResidencia,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.domicilioComercial !== undefined &&
                  newErrors?.domicilioComercial?.tiempoResidencia
                ) {
                  delete newErrors?.domicilioComercial?.tiempoResidencia;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "domicilioComercial.tiempoResidencia",
                    e.value
                  );
                }
              }}
              options={anios?.map((item, index) => {
                return {
                  ...item,
                  label: index + 1,
                  value: index + 1,
                };
              })}
              showErrorMessage={
                formik.errors?.domicilioComercial?.tiempoResidencia
              }
              errorMessage={formik.errors?.domicilioComercial?.tiempoResidencia}
            />
            <div className="d-flex gap-2">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(SECCION_DOMICILIO_COMERCIAL);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit">Siguiente</PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default DomicilioComercialPFAE;

function initialValues(modeloPfae, axiosData) {
  let domicilioComercial = modeloPfae.domicilioComercial;
  return {
    idCliente: modeloPfae.idCliente,
    domicilioFiscal: {
      idDatosDelNegocio: modeloPfae.domicilioFiscal.idDatosDelNegocio,
    },
    domicilioComercial: {
      idDatosDelNegocio: domicilioComercial.idDatosDelNegocio
        ? domicilioComercial.idDatosDelNegocio
        : "",
      igualDomicilioFiscal: domicilioComercial.igualDomicilioFiscal
        ? domicilioComercial.igualDomicilioFiscal
        : false,
      calle: domicilioComercial.calle ? domicilioComercial.calle : "",
      numeroExterior: domicilioComercial.numeroExterior
        ? domicilioComercial.numeroExterior
        : "",
      numeroInterior: domicilioComercial.numeroInterior
        ? domicilioComercial.numeroInterior
        : "",
      codigoPostal: domicilioComercial.codigoPostal
        ? domicilioComercial.codigoPostal
        : "",
      colonia: "",
      municipioId: domicilioComercial.municipioId
        ? domicilioComercial.municipioId
        : "",
      municipioObj: domicilioComercial.municipioId
        ? axiosData?.generales?.municipios.filter(
            (e) => e.id === domicilioComercial.municipioId
          )
        : [{ nombre: "" }],
      estadoId: domicilioComercial.estadoId ? domicilioComercial.estadoId : "",
      estadoObj: domicilioComercial.estadoId
        ? axiosData?.generales?.estados?.filter(
            (e) => e.id === domicilioComercial?.estadoId
          )
        : [{ nombre: "" }],
      tiempoResidencia: domicilioComercial.tiempoResidencia
        ? domicilioComercial.tiempoResidencia
        : "",
      telefono: domicilioComercial.telefono ? domicilioComercial.telefono : "",
    },
  };
}

function validationSchema() {
  return {
    domicilioComercial: Yup.object().shape({
      calle: Yup.string().required("Dato requerido."),
      numeroExterior: Yup.string().required("Dato requerido."),
      codigoPostal: Yup.string().required("Dato requerido."),
      colonia: Yup.string().required("Dato requerido."),
      municipioId: Yup.string().required("Dato requerido."),
      estadoId: Yup.string().required("Dato requerido."),
      telefono: Yup.string()
        .required("Dato requerido.")
        .test(
          "telefono",
          "Teléfono capturado de forma incorrecta.",
          (item, testContext) => {
            return validarNumeroTelefono(testContext.parent.telefono);
          }
        ),
      tiempoResidencia: Yup.string().required("Dato requerido."),
      igualDomicilioFiscal: Yup.string().required("Dato requerido."),
    }),
  };
}
