export const MENSAJE_ERROR_RFC = "Validación alta prospecto PFAE [RFC NO SON IGUALES CAPTURADO/CALCULADO]";
export const MENSAJE_ERROR_RFC_MOSTRAR = "RFC NO SON IGUALES CAPTURADO/CALCULADO";
export const MENSAJE_ERROR_RFC_CAP = "Actualizar prospecto PEPE validación [ ERROR RFC INFORMADO DIA DISTINTO FECNAC]";
export const MENSAJE_ERROR_RFC_CAP_MOSTRAR = "ERROR RFC INFORMADO DIA DISTINTO FECNAC";
export const MENSAJE_ERROR_INE = "Actualizar prospecto PEPE validación [Error por indisponibilidad de INE]";
export const MENSAJE_ERROR_INE_MOSTRAR = "Error por indisponibilidad de INE";
export const MENSAJE_ERROR_INE_PF = "Actualizar prospecto PF validación [Error por indisponibilidad del INE]";
export const MENSAJE_ERROR_INE_PF_MOSTRAR = "Error por indisponibilidad de INE";
export const MENSAJE_ERROR_IDENTIFICACION = "Validación alta prospecto PF [IDENTIFICACION INFORMADO YA ESTA RELACIONADO A UN CLIENTE EXISTENTE]";
export const MENSAJE_ERROR_IDENTIFICACION_MOSTRAR = "IDENTIFICACION INFORMADO YA ESTA RELACIONADO A UN CLIENTE EXISTENTE";
export const MENSAJE_ERROR_CURP = "Validación alta prospecto PFAE [EL CURP CAPTURADO YA EXISTE PARA UN CLIENTE BANORTE, FAVOR DE VALIDAR LOS DATOS Y VOLVER A INTENTAR]";
export const MENSAJE_ERROR_CURP_MOSTRAR = "EL CURP CAPTURADO YA EXISTE PARA UN CLIENTE BANORTE, FAVOR DE VALIDAR LOS DATOS Y VOLVER A INTENTAR";

export const MENSAJE_ERROR_FECHA_SHCP = "Actualizar prospecto PEPE validación [ ALTA SHCP DEBE SER MENOR A FECHA ACTUAL]";
export const MENSAJE_ERROR_FECHA_SHCP_MOSTRAR = "ALTA SHCP DEBE SER MENOR A FECHA ACTUAL";

export const MENSAJE_ERROR_GENERICO_MOSTRAR = "Error alta prospecto PFAE [Error IIB: Error genérico]";

export const mensajeMostrarUsuario = function (mensajeRecibido) {
    var mensajeMostrarUs = "";
    
    switch (mensajeRecibido) {
        case MENSAJE_ERROR_RFC:
            mensajeMostrarUs = MENSAJE_ERROR_RFC_MOSTRAR;
            break;
        case MENSAJE_ERROR_INE:
            mensajeMostrarUs = MENSAJE_ERROR_INE_MOSTRAR;
            break;
        case MENSAJE_ERROR_IDENTIFICACION:
            mensajeMostrarUs = MENSAJE_ERROR_IDENTIFICACION_MOSTRAR;
            break;
        case MENSAJE_ERROR_FECHA_SHCP:
            mensajeMostrarUs = MENSAJE_ERROR_FECHA_SHCP_MOSTRAR;
            break;
        case MENSAJE_ERROR_INE_PF:
            mensajeMostrarUs = MENSAJE_ERROR_INE_PF_MOSTRAR;
            break;
        case MENSAJE_ERROR_CURP:
            mensajeMostrarUs = MENSAJE_ERROR_CURP_MOSTRAR;
            break;
        case MENSAJE_ERROR_RFC_CAP:
            mensajeMostrarUs = MENSAJE_ERROR_RFC_CAP_MOSTRAR;
            break;
        default:
            mensajeMostrarUs = mensajeRecibido;
            break;
    }

    return mensajeMostrarUs;
  };
