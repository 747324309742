import React, { useState, useEffect } from "react";
import "moment/locale/es";
import "react-datetime/css/react-datetime.css";
import { ID_PFAE, ID_PF } from "../../../../../utils/constantes";
import DatosSolicitanteConyuge from "./DatosSolicitanteConyuge";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import SelectWithErrorRepresentanteLegal from "../../../../basics/SelectWithError/SelectWithErrorRepresentanteLegal";
import RadioInput from "../../../../basics/radioButton";

const SolicitanteConyuge = ({
  modeloPfae,
  setModeloPfae,
  axiosData,
  aniosDomicilio,
  mesesDomicilo,
  anios,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setObligadosSolidariosInformacionBasica,
  setLoading,
  setCantidadConyugeOS,
  cantidadConyugeOS,
  conyugeOS,
  setConyugeOS,
}) => {
  const [solicitanteConyuge, setSolicitanteConyuge] = useState({
    tipoPersona: "",
    idTipoPersona: null,
  });
  const [esObligadoSolidarioConyuge, setEsObligadoSolidarioConyuge] =
    useState(conyugeOS);
  const [esObligadoSolidarioConyugeBD, setEsObligadoSolidarioConyugeBD] =
    useState("");

  useEffect(() => {
    if (modeloPfae?.conyuge?.idConyuge !== null) {
      setEsObligadoSolidarioConyuge(
        modeloPfae?.conyuge?.generales?.esObligadoSolidario
      );
      setEsObligadoSolidarioConyugeBD(
        modeloPfae?.conyuge?.generales?.esObligadoSolidario
      );

      if (
        modeloPfae.conyuge.generales.tipoPersonaId !== undefined &&
        modeloPfae.conyuge.generales.tipoPersonaId !== null
      ) {
        if (modeloPfae.conyuge.generales.tipoPersonaId == ID_PFAE) {
          setSolicitanteConyuge({
            tipoPersona: "PFAE",
            idTipoPersona: modeloPfae.conyuge.generales.tipoPersonaId,
          });
        } else {
          setSolicitanteConyuge({
            tipoPersona: "PF",
            idTipoPersona: modeloPfae.conyuge.generales.tipoPersonaId,
          });
        }
      }
    } else {
      setEsObligadoSolidarioConyuge(conyugeOS);
      setModeloPfae({
        ...modeloPfae,
        conyuge: {
          ...modeloPfae.conyuge,
          generales: {
            ...modeloPfae.conyuge.generales,
            esObligadoSolidario: conyugeOS,
          },
        },
      });
    }
  }, []);

  const personTypes = [
    {
      title: "Persona física",
      rfc: 13,
      id: ID_PF,
      type: "PF",
    },
    {
      title: "Persona física con actividad empresarial",
      rfc: 13,
      id: ID_PFAE,
      type: "PFAE",
    },
  ];

  return (
    <>
      <div className="container">
        <h2 className="f-s-3 text-center">Cónyuge del solicitante</h2>
        {esObligadoSolidarioConyuge == true && (
          <p className="p--texto_justify">En esta sección deberás agregar la información de la persona que será tu aval para el crédito. Esta persona debe de ser diferente al solicitante, y en caso de que el solicitante no tenga un bien inmueble que respalde el crédito, el obligado solidario deberá presentarlo</p>
        )}
        <FormLayout>
          <SelectWithErrorRepresentanteLegal
            label="¿El cónyuge funge como obligado solidario? *"
            name="conyuge.generales.esObligadoSolidario"
            value={{
              label: esObligadoSolidarioConyuge ? "Si" : "No",
              value: esObligadoSolidarioConyuge,
            }}
            onChange={(e) => {
              setEsObligadoSolidarioConyuge(e.value);
              setModeloPfae({
                ...modeloPfae,
                conyuge: {
                  ...modeloPfae.conyuge,
                  generales: {
                    ...modeloPfae.conyuge.generales,
                    esObligadoSolidario: e.value,
                  },
                },
              });
            }}
            style={{ width: "55%" }}
            options={[
              { label: "Si", value: true },
              { label: "No", value: false },
            ]}
          />
        </FormLayout>
        {esObligadoSolidarioConyuge == true && (
          <>
            <h3>Tipo de persona</h3>
            <div className="d-flex justify-content-center gap-3">
              {personTypes.map((person) => {
                return (
                  <div className="d-flex flex-column align-items-center">
                    <div className="d-flex gap-2">
                      <RadioInput
                        style={{ padding: "0" }}
                        checked={solicitanteConyuge.idTipoPersona === person.id}
                        onChange={() => {
                          setSolicitanteConyuge({
                            tipoPersona: person.type,
                            idTipoPersona: person.id,
                          });
                        }}
                      />
                      <label className="text_lightGray">{person.title}</label>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {esObligadoSolidarioConyuge !== "" && (
          <>
            {(esObligadoSolidarioConyuge == false ||
              (esObligadoSolidarioConyuge == true &&
                solicitanteConyuge.idTipoPersona != null)) && (
              <DatosSolicitanteConyuge
                modeloPfae={modeloPfae}
                axiosData={axiosData}
                aniosDomicilio={aniosDomicilio}
                mesesDomicilo={mesesDomicilo}
                anios={anios}
                esObligadoSolidario={esObligadoSolidarioConyuge}
                esObligadoSolidarioConyugeBD={esObligadoSolidarioConyugeBD}
                setEsObligadoSolidarioConyugeBD={
                  setEsObligadoSolidarioConyugeBD
                }
                idTipoPersonaSolicitanteConyuge={
                  solicitanteConyuge.idTipoPersona
                }
                setModeloPfae={setModeloPfae}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                setLoading={setLoading}
                setCantidadConyugeOS={setCantidadConyugeOS}
                cantidadConyugeOS={cantidadConyugeOS}
                conyugeOS={conyugeOS}
                setConyugeOS={setConyugeOS}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SolicitanteConyuge;
