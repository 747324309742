import React from "react";
import Dropzone from "react-dropzone";
import { BiFileBlank } from "react-icons/bi";
import { GoTrashcan } from "react-icons/go";
import { ReactComponent as UploadIcon } from "../../../icons/Subir.svg";
import { isEmpty } from "../../../functions/fieldFunctions";
import Tooltip from "../../Tooltip/Tooltip";

const InputDocument = ({
  onDrop = () => {},
  className = "",
  label = "",
  documentList = [],
  formik,
  eliminarDocumentos = () => {},
  nombreMapeo,
  tooltip = "",
}) => {
  return (
    <div
      className="d-flex w-100 gap-1 align-items-center justify-content-center"
      style={{
        maxWidth: "700px",
      }}
    >
      <Dropzone onDrop={onDrop}>
        {(props) => (
          <div
            {...props.getRootProps({ className: "dropzone" })}
            className="w-100"
          >
            <div className={className}>
              <span className="title-document">
                {label} {!isEmpty(tooltip) && <Tooltip text={tooltip} />}
              </span>
              <div>
                {documentList.length > 0 ? (
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <input {...props.getInputProps()} />
                    <BiFileBlank width={40} height={40} />
                    <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
                      {documentList.map((e, index) => (
                        <span key={e.name + index}>{e.name}</span>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <input {...props.getInputProps()} />
                    <UploadIcon /> Suba o arrastre el documento jpeg, jpg, png,
                    pdf. 30 MB Tamaño Maxímo.
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      <GoTrashcan
        size="25px"
        style={{ cursor: documentList.length > 0 ? "pointer" : "" }}
        onClick={() => {
          if (documentList.length > 0) {
            eliminarDocumentos(formik.values?.idCliente, nombreMapeo);
          }
        }}
      />
    </div>
  );
};

export default InputDocument;
