import React, { Fragment, useEffect, useState } from "react";
import Popup from "../tools/Popup";


const Cotizador_proceso = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="cotizador--container">
      <h1 className="text-center f-s-3">Este sitio está en mantenimiento.</h1>
      <Popup show={loading}>
        <div className="bgc-gray-opacity popup--container">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <div className="popup--shadow" />
      </Popup>
    </div>
  );
};

export default Cotizador_proceso;
