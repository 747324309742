import curp from "curp";
import RfcFacil from "rfc-facil";
import { ID_PM, ID_PFAE, ID_PF } from "./constantes";

var ESTADOS = [
  "AS",
  "BC",
  "BS",
  "CC",
  "CS",
  "CH",
  "CL",
  "CM",
  "DF",
  "DG",
  "GT",
  "GR",
  "HG",
  "JC",
  "MC",
  "MN",
  "MS",
  "NT",
  "NL",
  "OC",
  "PL",
  "QT",
  "QR",
  "SP",
  "SL",
  "SR",
  "TC",
  "TS",
  "TL",
  "VZ",
  "YN",
  "ZS",
  "NE",
];

export const validarRFCTipoPersona = (rfc, tipoDePersonaId) => {
  var valid = false;
  var rfcValidLegth = true;
  if (rfc !== undefined && rfc.length > 0) {
    var tipoDePersonaId;
    if(tipoDePersonaId == ID_PM &&  rfc.trim().length == 12){
      tipoDePersonaId = rfc.trim().length == 12 ? ID_PM : ID_PFAE
    }else{
      tipoDePersonaId = parseInt(tipoDePersonaId);
    }

    switch (tipoDePersonaId) {
      case ID_PF:
      case ID_PFAE:
        if(rfc.trim().length == 13){
          var pfaeRfcRegExp =
            /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$/;
          valid = pfaeRfcRegExp.test(rfc);
        }        
        break;
      case ID_PM:
        if(rfc.trim().length == 12){
          var pmRfcRegExp =
            /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/;
          valid = pmRfcRegExp.test(rfc);
        }        
        break;
      default:
        valid = false;
        break;
    }
  }

  return valid;
};

export const pmFechaAltaSHCPVsRfc = (rfc, fechaAltaShcp) => {
  var valid = false;

  if (fechaAltaShcp && fechaAltaShcp.length > 0 && rfc && rfc.length > 0) {
    var fechaRFC =
      rfc.substring(7, 9) +
      "-" +
      rfc.substring(5, 7) +
      "-" +
      rfc.substring(3, 5);
    var fechaSHCP =
      fechaAltaShcp.split("-")[0] +
      "-" +
      fechaAltaShcp.split("-")[1] +
      "-" +
      fechaAltaShcp.split("-")[2].substring(2, 4);
    if (fechaSHCP != fechaRFC) {
      valid = false;
    } else {
      valid = true;
    }
  }

  return valid;
};

export const setearFechaFormik = (fecha) => {
  var fechaFormik = "";
  var d = fecha.date();
  var m = fecha.month() + 1;
  var yyyy = fecha.year();

  var mm = "" + (m < 10 ? "0" + m : m);
  var dd = "" + (d < 10 ? "0" + d : d);

  fechaFormik = dd + "-" + mm + "-" + yyyy;
  return fechaFormik;
};

export const fechaActualYYYMMDD = () => {
  var today = new Date();
  var d = today.getDate();
  var m = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  var mm = "" + (m < 10 ? "0" + m : m);
  var dd = "" + (d < 10 ? "0" + d : d);

  var fechayyymmdd = yyyy + "-" + mm + "-" + dd;
  return fechayyymmdd;
};

export const compareDates = (dateToValid) => {
  let fechaValida = false;

  if (dateToValid !== undefined) {
    var fechaRecibida = new Date(dateToValid);

    var fechaMin = new Date(fechaMinEdadYYYMMDD());
    var fechaMax = new Date(fechaMaxMayorEdadYYYMMDD());

    if (fechaRecibida >= fechaMin && fechaRecibida <= fechaMax)
      fechaValida = true;
  }

  return fechaValida;
};

export const compareDatesOrigin = (max, min, dateToValid) => {
  let isValid = false;

  if (dateToValid !== undefined) {
    const maxDate = parseInt(max.split("-")[0]);
    const minDate = parseInt(min.split("-")[0]);
    const date = parseInt(dateToValid.split("-")[2]);

    if (date >= minDate && date <= maxDate) isValid = true;
  }

  return isValid;
};

export const fechaMaxMayorEdadYYYMMDD = () => {
  var fechaHoy = new Date();

  var fechaMaxima = new Date(fechaHoy.getTime() - 24 * 60 * 60 * 1000 * 6574);

  var d = fechaMaxima.getDate();
  var m = fechaMaxima.getMonth() + 1;
  var yyyy = fechaMaxima.getFullYear();

  var mm = "" + (m < 10 ? "0" + m : m);
  var dd = "" + (d < 10 ? "0" + d : d);

  var fechayyymmdd = yyyy + "-" + mm + "-" + dd;
  return fechayyymmdd;
};

export const fechaMinEdadYYYMMDD = () => {
  var fechaHoy = new Date();

  var d = fechaHoy.getDate();
  var m = fechaHoy.getMonth() + 1;
  var yyyy = fechaHoy.getFullYear() - 70;

  var mm = "" + (m < 10 ? "0" + m : m);
  var dd = "" + (d < 10 ? "0" + d : d);

  var fechayyymmdd = yyyy + "-" + mm + "-" + dd;
  return fechayyymmdd;
};

export const obtenerFechaShcpByRfcInput = (rfc) => {
  var fechaRFC;
  var today = new Date();
  var anioActual = today.getFullYear();

  if (rfc && rfc.length > 0) {
    let anioRfc = parseInt("20" + rfc.substring(3, 5));
    if (anioRfc > anioActual) {
      anioRfc = "19" + rfc.substring(3, 5);
    }

    fechaRFC = anioRfc + "-" + rfc.substring(5, 7) + "-" + rfc.substring(7, 9);
  }

  return fechaRFC;
};

export const obtenerFechaShcpByRfc = (rfc) => {
  var fechaRFC;
  if (rfc && rfc.length > 0) {
    fechaRFC = parseInt(rfc.substring(3, 5)) < 23 ? "20" : "19";
    fechaRFC =
      fechaRFC +
      rfc.substring(3, 5) +
      "-" +
      rfc.substring(5, 7) +
      "-" +
      rfc.substring(7, 9);
  }

  return fechaRFC;
};

export const alphaNumericWithDash = (value) => {
  var valid = true;
  valid = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9*_-]*$/.test(value);
  return valid;
};

export const specialCharacters = (value) => {
  var formato =
    /[`!¡@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/·¬°¿?~áéíóúÁÉÍÓÚäëïöüÄËÏÖÜ]/;
  var checkRegex = true;
  if (formato.test(value)) {
    checkRegex = false;
  }
  return checkRegex;
};

export const dependeintesMajor23 = (depenEcon23Anios, totalDependientes) => {
  var valid = false;
  if (
    totalDependientes &&
    totalDependientes.length > 0 &&
    depenEcon23Anios &&
    depenEcon23Anios.length > 0
  ) {
    if (totalDependientes >= depenEcon23Anios) {
      valid = true;
    }
  }
  return valid;
};

export const compareDateMajorNow = (value) => {
  var valid = false;
  var ahora = new Date();
  if (value) {
    var datFechaSHCP = new Date(
      Number(value.split("-")[2]),
      Number(value.split("-")[1] - 1),
      Number(value.split("-")[0])
    );
    if (datFechaSHCP > ahora) {
      valid = false;
    } else {
      valid = true;
    }
  }

  valid = compareDateShcpVsNow(value);

  return valid;
};

export const compareDateShcpVsNow = (value) => {
  var valid = false;
  var ahora = new Date();
  if (value) {
    var datFechaSHCP =
      value.split("-")[0] +
      "-" +
      value.split("-")[1] +
      "-" +
      value.split("-")[2];
    var dayNow =
      this.getDayMonthFormat(ahora.getDate()) +
      "-" +
      this.getDayMonthFormat(ahora.getMonth() + 1) +
      "-" +
      ahora.getFullYear();
    if (datFechaSHCP == dayNow) {
      valid = false;
    } else {
      valid = true;
    }
  }
  return valid;
};

export const getDayMonthFormat = (value) => {
  var valueStr = "";
  if (value && value != null && this.isValidNumber(value)) {
    valueStr = value.toString();
    if (valueStr.length === 1) {
      valueStr = "0" + valueStr;
    }
  }
  return valueStr;
};

export const isValidNumber = function (value) {
  var validNumber = false;
  try {
    value = cleanValue(value);
    var valueFloat = parseFloat(value);
    if (!isNaN(valueFloat)) {
      validNumber = true;
    }
  } catch (error) {
    console.error(error);
  }
  return validNumber;
};

export const cleanValue = function (value) {
  if (value && typeof value === "string" && value.length > 0) {
    value = value.replace(/,/g, "");
  }
  return value;
};

export const tiempoViviendaAniosRequired = function (value, meses) {
  var anios = parseInt(value);
  var meses = parseInt(meses);
  var tiempoViviendaValidacion = false;
  if (anios + meses > 0) {
    tiempoViviendaValidacion = true;
  }

  return tiempoViviendaValidacion;
};

export const generaCurp = function (datosPersona) {
  let persona = curp.getPersona();
  persona.nombre = datosPersona.nombre;
  persona.apellidoPaterno = datosPersona.apellidoPaterno;
  persona.apellidoMaterno = datosPersona.apellidoMaterno;
  persona.genero = datosPersona.genero;
  persona.fechaNacimiento = datosPersona.fechaNacimiento;
  persona.estado = ESTADOS[datosPersona.idEstado];
  return curp.generar(persona);
};

export const validarCurp = function (curpString) {
  return curp.validar(curpString);
};

export const generaRFC = function (datosPersona) {
  let dd = datosPersona.fechaNacimiento.substring(0, 2);
  let mm = datosPersona.fechaNacimiento.substring(3, 5);
  let yyyy = datosPersona.fechaNacimiento.substring(6);
  const rfc = RfcFacil.forNaturalPerson({
    name: datosPersona.nombre,
    firstLastName: datosPersona.apellidoPaterno,
    secondLastName: datosPersona.apellidoMaterno,
    day: dd,
    month: mm,
    year: yyyy,
  });

  return rfc;
};

export const generaRFCPm = function (datosPersona) {
  let dd = datosPersona.fechaNacimiento.substring(0, 2);
  let mm = datosPersona.fechaNacimiento.substring(3, 5);
  let yyyy = datosPersona.fechaNacimiento.substring(6);
  const rfc = RfcFacil.forJuristicPerson({
    name: datosPersona.nombre,
    day: dd,
    month: mm,
    year: yyyy,
  });

  return rfc;
};

export const tiempoViviendaActual = function (meses, anios) {
  let valorvalido = false;
  if (
    meses !== undefined &&
    anios !== undefined &&
    meses !== "" &&
    anios !== ""
  ) {
    let sumaTiempo = parseInt(anios) + parseInt(meses);
    valorvalido = sumaTiempo > 0 ? true : false;
  }

  return valorvalido;
};

export const tiempoViviendaAnterior = function (meses, anios) {
  let valorvalido = false;
  if (
    meses !== undefined &&
    anios !== undefined &&
    meses !== "" &&
    anios !== ""
  ) {
    let sumaTiempo = parseInt(anios) + parseInt(meses);
    valorvalido = sumaTiempo >= 0 ? true : false;
  }

  return valorvalido;
};

export const formatoTelefono = function (telefono, telefonoActual) {
  var newTelefono = "";
  if (telefono !== "") {
    var numTelefono = cleanTelephone(telefono);
    var numValido = isValidNumberTelefono(numTelefono);
    if (numValido == true) {
      newTelefono = formatoNumeroTelefono(numTelefono);
    } else {
      newTelefono = telefonoActual;
    }
  }
  return newTelefono;
};

export const cleanTelephone = function (telefono) {
  var numTelefono = telefono.replace(/-/g, "").replace(/_/g, "");
  return numTelefono;
};

export const isValidNumberTelefono = function (numTelefono) {
  var validNumber = false;
  var valoresAceptados = /^[0-9]+$/;
  if (numTelefono.match(valoresAceptados)) {
    validNumber = true;
  }
  return validNumber;
};

export const formatoNumeroTelefono = function (numTelefono) {
  var telefonoFormat = "";
  if (numTelefono.length > 2) {
    for (var i = 0; i < numTelefono.length; i = i + 2) {
      if (i < numTelefono.length - 2) {
        telefonoFormat = telefonoFormat + numTelefono.substring(i, i + 2) + "-";
      } else {
        telefonoFormat = telefonoFormat + numTelefono.substring(i);
      }
    }
  } else {
    telefonoFormat = telefonoFormat + numTelefono;
  }
  return telefonoFormat;
};

export const formatoTelefonoXX_XXXX_XXXX = function (telefono, telefonoActual) {
  var newTelefono = "";
  if (telefono !== "") {
    var numTelefono = cleanTelephone(telefono);
    var numValido = isValidNumberTelefono(numTelefono);
    if (numValido == true) {
      newTelefono = formatoNumeroTelefonoXX_XXXX_XXXX(numTelefono);
    } else {
      newTelefono = telefonoActual;
    }
  }
  return newTelefono;
};

export const formatoNumeroTelefonoXX_XXXX_XXXX = function (numTelefono) {
  var telefonoFormat = "";
  if (numTelefono.length > 2) {
    telefonoFormat = telefonoFormat + numTelefono.substring(0, 0 + 2) + "-"; 
    for (var i = 2; i < numTelefono.length; i = i + 4) {
      if (i < numTelefono.length - 4) {          
        telefonoFormat = telefonoFormat + numTelefono.substring(i, i + 4) + "-"; 
      } else {
        telefonoFormat = telefonoFormat + numTelefono.substring(i);
      }
    }
  } else {
    telefonoFormat = telefonoFormat + numTelefono;
  }
  return telefonoFormat;
};

export const validarNumeroTelefono = function (numTelefono) {
  var telephoneValidation = false;
  if (numTelefono !== undefined && numTelefono !== "") {
    var telephone = cleanTelephone(numTelefono);
    if (telephone.length == 10) {
      telephoneValidation = true;
    }

    if (telephoneValidation) {
      telephoneValidation = repetivieNumbres(telephone);
    }

    if (telephoneValidation) {
      telephoneValidation = ascendingNumbers(telephone);
    }

    if (telephoneValidation) {
      telephoneValidation = descendingNumbers(telephone);
    }
  }

  return telephoneValidation;
};

export const repetivieNumbres = function (telephone) {
  var valid = false;
  var regexRepetitive = /^(\d)\1{9}$/;
  if (telephone && telephone.length == 10 && !regexRepetitive.test(telephone)) {
    valid = true;
  }
  return valid;
};

export const ascendingNumbers = function (telephone) {
  var ascendingNumbersValidation = true;
  if (!telephone || telephone.length < 10 || telephone.length > 10) {
    ascendingNumbersValidation = false;
  } else {
    var ascendingNumbersCount = 0;
    for (let i = 0; i < telephone.length; i++) {
      if (i == 0) {
        continue;
      } else {
        var lastNumber = parseInt(telephone[i - 1]) + 1;
        var actualNumber = parseInt(telephone[i]);
        if (lastNumber === actualNumber) {
          ascendingNumbersCount++;
        }
      }
    }

    if (ascendingNumbersCount === 9 || (ascendingNumbersCount === 8 && parseInt(telephone[9]) == 0)) {
      ascendingNumbersValidation = false;
    }
  }
  return ascendingNumbersValidation;
};

export const descendingNumbers = function (telephone) {
  var descendingNumbersValidation = true;

  if (!telephone || telephone.length < 10 || telephone.length > 10) {
    descendingNumbersValidation = false;
  } else {
    var descendingNumbersCount = 0;
    for (let i = 0; i < telephone.length; i++) {
      if (i == 0) {
        continue;
      } else {
        var lastNumber = parseInt(telephone[i - 1]) - 1;
        var actualNumber = parseInt(telephone[i]);
        if (lastNumber === actualNumber) {
          descendingNumbersCount++;
        }
      }
    }
    if (descendingNumbersCount === 9) {
      descendingNumbersValidation = false;
    }
  }

  return descendingNumbersValidation;
};

export const valorMinimo = function (value, montoMinimo) {
  var valid = false;
  if (value && value.length > 0) {
    if (isValidNumber(value)) {
      value = cleanValue(value);
      var valueFloat = parseFloat(value);
      if (valueFloat >= montoMinimo) {
        valid = true;
      }
    }
  }
  return valid;
};

export const rangoValorValido = function (value, montoMaximo, montoMinimo) {
  var valid = true;
  if (value && value.length > 0) {
    valid = false;
    if (isValidNumber(value)) {
      value = cleanValue(value);
      var valueFloat = parseFloat(value);
      if (valueFloat >= montoMinimo && valueFloat <= montoMaximo) {
        valid = true;
      }
    }
  }
  return valid;
};

export const validarSoloNumeros = function (valor) {
  var validNumber = false;
  var valoresAceptados = /^[0-9]+$/;
  if (valor.match(valoresAceptados)) {
    validNumber = true;
  }
  return validNumber;
};

export const formatNumber = function (value) {
  var formattedValue = "";
  if (isValidNumber(value)) {
    value = cleanValue(value);
    var valueFloat = parseFloat(value);
    var parts = valueFloat.toString().split(".");
    formattedValue = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    if (parts.length > 1) {
      var decimals = parts[1];
      if (decimals.length > 1) {
        formattedValue += "." + parts[1];
      } else {
        formattedValue += "." + parts[1] + "0";
      }
    } else {
      formattedValue += ".00";
    }
  }
  return formattedValue;
};

export const verificarSoloNumber = function (values, valorAnterior) {
  var numeroValido = valorAnterior;
  var valorNew = values?.value;
  if (valorNew !== "") {
    var valorLimpio = cleanValue(valorNew).split(".");
    var validNumber = validarSoloNumeros(valorLimpio[0]);
    var validNumberB = true;
    var parteDecimal = "";
    var parteDecimalValid;
    if (valorLimpio.length < 3) {
      if (valorLimpio.length == 2 && valorLimpio[1] != "") {
        validNumberB = validarSoloNumeros(valorLimpio[1]);
        parteDecimal = cleanValue(valorLimpio[1]);
        parteDecimalValid = valorNew.split(".")[1].includes(",");
      }
      if (validNumber && validNumberB) {
        if (parteDecimalValid == false || valorLimpio.length == 1) {
          numeroValido = valorNew;
        } else {
          numeroValido = valorLimpio[0] + "." + parteDecimal;
        }
      }
    }
  } else {
    numeroValido = valorNew;
  }
  return numeroValido;
};

export const darFormatNumber = function (value) {
  var formattedValue = "";
  var parts = value.toString().split(".");
  formattedValue = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  if (parts.length > 1) {
    var decimals = parts[1];
    if (decimals.length > 1) {
      formattedValue += "." + parts[1];
    } else {
      formattedValue += "." + parts[1] + "0";
    }
  } else {
    formattedValue += ".00";
  }
  return formattedValue;
};

export const formatSoloNumeros = function (numero) {
  var patterns = /[^0-9]/g;
  var validNumber = numero.replace(patterns, "");
  return validNumber;
};

export const validarFormatoEmail = function (value) {
  var formato = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
  var checkRegex = true;
  if(formato.test(value)) {
      checkRegex = true;
  }else{
      checkRegex = false;
  }
  return checkRegex;
};