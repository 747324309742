import React, { useState } from "react";
import LogoBanorte from "../../svg/LogoBanorte";
import "react-phone-input-2/lib/style.css";
import { animated, useTransition } from "react-spring";
import Swal from "sweetalert2";
import Popup from "../tools/Popup";
import Submenu from "../campos/components/Submenu/Submenu";
import IconClose from "./../../icons/crossIcon.png";
import {
  sessionStorageIdSolicitud,
  sessionStorageContinuarSolicitud,
  localStorageToken,
  localStorageRFC,
} from "../../config/Variables";
import { formatoTelefonoXX_XXXX_XXXX } from "../../utils/validaciones";
import { api_obtener_solicitud_existente,  api_guardar_token_autoservicio} from "../../api/api_servicios_rest_crediactivo";
import "./Navbar.css";
import InputWithError from "../basics/InputWithError/InputWithError";
import { PrimaryButton } from "../../components/basics/Buttons/Buttons";
import CrossIcon from "../../icons/crossIcon.png";

import { 
  generarToken,
} from "../../utils/tokenAutoservicio";

const Navbar_proceso = ({ phone = true, phase = null, steps }) => {
  const [numeroCelular, setNumeroCelular] = useState("");
  const [showNavbar, setShowNavbar] = useState(false);
  const [loadingInicio, setLoadingInicio] = useState(false);
  const [showEnRevision, setShowEnRevision] = useState(false);
  const [showSolicitudes, setShowSolicitudes] = useState(false);
  const [folioSolicitud, setFolioSolicitud] = useState("");
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [showPhone, setShowPhone] = useState(false);
  const [listaSolicitudes, setListaSolicitudes] = useState([]);
  const [tokenAutoservicio, setTokenAutoservicio] = useState("");

  const navBarTransition = useTransition(showNavbar, {
    from: {
      opacity: "0",
      position: "absolute",
      zIndex: "1000",
      width: "100%",
      top: "0px",
    },
    enter: {
      opacity: "1",
      position: "absolute",
      zIndex: "1000",
      width: "100%",
      top: "0px",
    },
    leave: {
      opacity: "0",
      position: "absolute",
      zIndex: "1000",
      width: "100%",
      top: "0px",
    },
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
  });

  function obtenerSolicitudExistente() {
    
  }

  return (
    <>
      {dimensions.width <= 991 ? (
        <>
          <div
            className="my-navbar bgc-red d-flex justify-content-between align-items-center position-fixed"
            style={{
              zIndex: "1000",
              top: "0px",
            }}
          >
            <a href="/crediactivo#">
              <LogoBanorte width={230} style={{ marginLeft: "12px" }} />
            </a>
            <i
              onClick={() => setShowNavbar(!showNavbar)}
              className="ri-menu-line pointer"
              style={{ fontSize: "44px", marginRight: "12px" }}
            ></i>
            {navBarTransition(
              (style, visible) =>
                visible && (
                  <animated.div style={style}>
                    <div
                      className="bgc-red"
                      style={{
                        position: "absolute",
                        top: "63px",
                        zIndex: "1000",
                        width: "100%",
                        padding: "24px 0px",
                      }}
                    >
                      <div className="d-flex justify-content-evenly mb-3">
                        <span>
                          <i
                            style={{ fontSize: "32px" }}
                            className="pointer ri-map-pin-2-fill white"
                          ></i>
                        </span>
                        <span>
                          <i
                            style={{ fontSize: "32px", marginLeft: "4px" }}
                            className="pointer white ri-search-line"
                          ></i>
                        </span>
                        <span>
                          <i
                            style={{ fontSize: "32px", marginLeft: "4px" }}
                            className="pointer white ri-lock-2-fill"
                          ></i>
                        </span>
                      </div>
                      {( steps < 3 )  &&
                        <div className="d-flex flex-column align-items-center">
                          <span className="pointer" style={{ margin: "4px" }}>
                            <a
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "17px",
                              }}
                              onClick={(e) => {
                                setShowPhone(false);
                              }}
                            >
                              YA TENGO UNA SOLICITUD
                            </a>
                          </span>
                        </div>
                      }
                    </div>
                  </animated.div>
                )
            )}
          </div>
        </>
      ) : (
        <div className="my-navbar bgc-red">
          <div
            style={{
              height: "63px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 100px",
              width: "100%",
              margin: "0px auto",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "12px",
              }}
              href="/crediactivo"
            >
              <LogoBanorte width={272} height={33} />
            </a>
            {( steps < 3 )  &&
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "400",
                }}
              >
                <div style={{ marginRight: "16px" }}>
                  <span className="pointer" style={{ margin: "4px" }}>
                    <a
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: "17px",
                      }}
                      onClick={(e) => {
                        setShowPhone(false);
                      }}
                    >
                      YA TENGO UNA SOLICITUD
                    </a>
                  </span>
                </div>
              </div>
            }
          </div>
        </div>
      )}

      {( phase === 0 || phase === 1 || phase === 2 || phase === 3 || phase === 4)  && <Submenu steps={steps} />}

      {phone === true && ( phase === 1 || phase === 2) && showPhone === true ? (
        <div
          className="bgc-white"
          style={{
            padding: window.innerWidth <= 991 ? "6rem 0 2rem 0" : "1rem 0",
          }}
        >
          <div className="navbar--continuar-solicitud">
            <div className="navbar--continuar-solicitud--item">
              <span>¿Ya tienes tu solicitud en proceso?</span>
              <InputWithError
                label="Número de teléfono"
                name="numeroTelefono"
                value={numeroCelular}
                onChange={(e) => {
                  let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                    e.target.value,
                    numeroCelular
                  );
                  setNumeroCelular(numTelefono);
                }}
                InputProps={{ maxLength: 12 }}
              />
              <PrimaryButton onClick={(e) => obtenerSolicitudExistente()}>
                Consultar
              </PrimaryButton>
              <sup>
              <img style={{
                objectFit: "cover",
                width: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                zIndex: "200",
              }}
                src={CrossIcon}
                onClick={() => {
                  setShowPhone(false);
                }}
              ></img></sup>
            </div>
          </div>
        </div>
      ) : (
        window.innerWidth <= 991 && <div style={{ paddingTop: "20px" }} />
      )}
    </>
  );
};

export default Navbar_proceso;
