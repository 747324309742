import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "moment/locale/es";
import "react-datetime/css/react-datetime.css";
import ErrorMessage from "../../../../helpers/ErrorMessage/ErrorMessage";
import {
  api_direccion_by_cp,
  api_save_conyuge_pfae,
} from "../../../../../api/api_servicios_rest_crediactivo";
import {
  dependeintesMajor23,
  fechaActualYYYMMDD,
  fechaMaxMayorEdadYYYMMDD,
  fechaMinEdadYYYMMDD,
  tiempoViviendaAnterior,
  tiempoViviendaActual,
  generaCurp,
  generaRFC,
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
  compareDates,
  compareDatesOrigin,
  validarRFCTipoPersona,
} from "../../../../../utils/validaciones";
import { mensajeMostrarUsuario } from "../../../../../utils/mensajesServicios";
import Select from "react-select";
import { selectStylesDouble } from "../../../../../styles/select-styles";
import CheckBoxInput from "../../../../basics/checkBox";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import SelectWithErrorTooltipCompuesto from "../../../../basics/SelectWithError/SelectWithErrorTooltipCompuesto";

import {
  NACIONALIDAD_MEXICAN0,
  ID_PF,
  ID_PFAE,
  EXCEPCIONES_RFC,
  SECCION_SOLICITANTE_CONYUGE,
  TIPO_VIVIENDA_EDIFICIO,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  TIPO_DEPARTAMENTO_APARTAMENTO,
  ID_CALCULO_RFC_NOMBRE,
  ID_CALCULO_RFC_APP,
  ID_CALCULO_RFC_APM,
  ID_CALCULO_RFC_FECHANAC,
  ID_CALCULO_RFC_GENERO,
  ID_CALCULO_RFC_ESTADONAC,
  ID_PAIS_MEXICO,
} from "../../../../../utils/constantes";
import {
  textToUpperCase,
  validateValue,
  isEmpty,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../../functions/fieldFunctions";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import SelectWithErrorActividad from "../../../../basics/SelectWithError/SelectWithErrorActividad";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";
import { sweetAlertError } from "../../alertas/alertas";
import { generarRfcCurpSC } from "./helpers/obligadoSolidarioHelper";

const DatosSolicitanteConyuge = ({
  modeloPfae,
  setModeloPfae,
  axiosData,
  aniosDomicilio,
  mesesDomicilo,
  anios,
  Swal,
  esObligadoSolidario,
  esObligadoSolidarioConyugeBD,
  setEsObligadoSolidarioConyugeBD,
  idTipoPersonaSolicitanteConyuge,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setObligadosSolidariosInformacionBasica,
  setLoading,
  setCantidadConyugeOS,
  cantidadConyugeOS,
  setConyugeOS,
}) => {
  const { innerWidth, innerHeight } = window;

  const [coloniasCP, setColoniasCP] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [startDateFechaSHCP, setStartDateFechaSHCP] = useState("");
  const [totalDependientes, setTotalDependientes] = useState("");
  const [totalDependientesMenores23, setTotalDependientesMenores23] =
    useState("");

  const formik = useFormik({
    initialValues: initialValues(
      modeloPfae,
      idTipoPersonaSolicitanteConyuge,
      esObligadoSolidario
    ),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(
      validationSchema(
        esObligadoSolidario,
        idTipoPersonaSolicitanteConyuge,
        totalDependientesMenores23,
        totalDependientes
      )
    ),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      formik.setFieldValue(
        "conyuge.generales.esObligadoSolidario",
        esObligadoSolidario
      );
      formik.setFieldValue(
        "conyuge.generales.tipoPersonaId",
        idTipoPersonaSolicitanteConyuge
      );
      salvarDatosConyugeSolicitante(formData);
    },
  });

  const obtenerDireccionByCp = async (codigoPostal) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK") {
        if (response.data.municipio !== undefined) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "conyuge.direccion.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue(
            "conyuge.direccion.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response.data.municipio.id
            )
          );
          formik.setFieldValue(
            "conyuge.direccion.estadoId",
            response.data.estado.id
          );
          formik.setFieldValue(
            "conyuge.direccion.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response.data.estado.id
            )
          );
          formik.setFieldValue(
            "conyuge.direccion.colonia",
            response.data.asentamientos[0]
          );

          let newErrors = formik.errors;
          delete newErrors?.conyuge?.direccion.municipioId;
          delete newErrors?.conyuge?.direccion?.estadoId;
          delete newErrors?.conyuge?.direccion?.colonia;
          formik.setErrors(newErrors);
        } else {
          limpiarDireccionByCp();
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue("conyuge.direccion.municipioId", "");
    formik.setFieldValue("conyuge.direccion.municipioObj", "");
    formik.setFieldValue("conyuge.direccion.estadoId", "");
    formik.setFieldValue("conyuge.direccion.estadoObj", "");
    formik.setFieldValue("conyuge.direccion.colonia", "");
  };

  const generarRfcCurp = (idValorCalculoRFC, valueCalculoRFC) => {
    const {
      nombre,
      apellidoMaterno,
      apellidoPaterno,
      fechaNacimiento,
      generoId,
      estadoNacimientoId,
    } = generarRfcCurpSC(idValorCalculoRFC, valueCalculoRFC, formik);
    if (
      nombre &&
      nombre.length > 0 &&
      apellidoPaterno &&
      apellidoPaterno.length > 0 &&
      generoId &&
      estadoNacimientoId &&
      fechaNacimiento &&
      fechaNacimiento.length > 0
    ) {
      const idEstado = Number(estadoNacimientoId);
      const persona = {
        nombre: nombre,
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
        genero: generoId === 1 ? "H" : "M",
        idEstado: idEstado - 1,
        fechaNacimiento: fechaNacimiento,
      };

      const curp = generaCurp(persona);
      if (curp && curp.length === 18) {
        formik.setFieldValue("conyuge.generales.curp", curp);
        let newErrors = formik.errors;
        delete newErrors?.conyuge?.generales?.curp;
        formik.setErrors(newErrors);
      }

      if (apellidoMaterno && apellidoMaterno.length > 0) {
        const rfc = generaRFC(persona);
        if (rfc) {
          formik.setFieldValue("conyuge.generales.rfc", rfc);
          let newErrors = formik.errors;
          delete newErrors?.conyuge?.generales?.rfc;
          formik.setErrors(newErrors);
        }
      }
    }
  };

  const salvarDatosConyugeSolicitante = async (formData) => {
    try {
      const response = await api_save_conyuge_pfae(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPfae({
            ...modeloPfae,
            idCliente: data.clienteId,
            conyuge: data.conyugeModelo,
          });

          if (
            data.conyugeEsObligadoSolidario &&
            esObligadoSolidarioConyugeBD == false
          ) {
            setCantidadConyugeOS(parseInt(cantidadConyugeOS) + 1);
          }

          if (
            data.conyugeEsObligadoSolidario == false &&
            esObligadoSolidarioConyugeBD == true
          ) {
            setCantidadConyugeOS(parseInt(cantidadConyugeOS) - 1);
          }

          setEsObligadoSolidarioConyugeBD(data.conyugeEsObligadoSolidario);

          setObligadosSolidariosInformacionBasica(
            data.obligadosSolidariosInformacionBasica
          );
          setConyugeOS(data.conyugeEsObligadoSolidario);
          setStyleHr("block");
          setLoading(false);
          handleSeccionPyme(SECCION_SOLICITANTE_CONYUGE);
        } else {
          setLoading(false);
          const mensajeError = mensajeMostrarUsuario(data.mensaje);
          sweetAlertError(mensajeError);
        }
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al guardar cónyuge del solicitante");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modeloPfae?.conyuge?.idConyuge !== null) {
      setStartDate(
        modeloPfae?.conyuge?.generales?.fechaNacimiento
          .split("-")
          .reverse()
          .join("-")
      );

      if (
        modeloPfae?.conyuge?.datosDelNegocio?.fechaAltaShcp !== null &&
        modeloPfae?.conyuge?.datosDelNegocio?.fechaAltaShcp !== undefined
      ) {
        setStartDateFechaSHCP(
          modeloPfae?.conyuge?.datosDelNegocio?.fechaAltaShcp
            .split("-")
            .reverse()
            .join("-")
        );
      }

      let calidadMigratoria = modeloPfae?.conyuge?.generales?.calidadMigratoria
        ? modeloPfae?.conyuge?.generales?.calidadMigratoria
        : "";
      formik.setFieldValue(
        "conyuge.generales.calidadMigratoria",
        calidadMigratoria
      );

      if (
        modeloPfae?.conyuge?.direccion !== null &&
        modeloPfae?.conyuge?.direccion?.idDireccion !== null
      ) {
        formik.setFieldValue(
          "conyuge.direccion.tipoDomicilioObj",
          axiosData?.domicilio?.tipoDeDomicilioOpciones?.filter(
            (e) => e.id === modeloPfae?.conyuge?.direccion?.tipoDomicilioId
          )
        );

        formik.setFieldValue(
          "conyuge.direccion.tipoViviendaObj",
          axiosData?.domicilio?.tipoDeViviendaOpciones?.filter(
            (e) => e.id === modeloPfae?.conyuge?.direccion?.tipoViviendaId
          )
        );

        obtenerDireccionByCp(modeloPfae?.conyuge?.direccion?.codigoPostal);

        if (modeloPfae?.conyuge?.direccion?.piso === null) {
          formik.setFieldValue("conyuge.direccion.piso", "");
        }

        if (modeloPfae?.conyuge?.direccion?.numeroInterior === null) {
          formik.setFieldValue("conyuge.direccion.numeroInterior", "");
        }
      } else {
        if (esObligadoSolidario) {
          formik.setFieldValue("conyuge.direccion.paisId", ID_PAIS_MEXICO);

          formik.setFieldValue(
            "conyuge.generales.estadoCivilId",
            ESTADO_CIVIL_SOCIEDAD_CONYUGAL
          );

          formik.setFieldValue(
            "conyuge.generales.estadoCivilObj",
            axiosData?.generales?.estadoCivilOpciones?.filter(
              (e) => e.id === ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            )
          );
        }
      }

      formik.setFieldValue(
        "conyuge.generales.paisNacimientoObj",
        axiosData?.generales?.paises?.filter(
          (e) => e.id === modeloPfae?.conyuge?.generales?.paisNacimientoId
        )
      );

      formik.setFieldValue(
        "conyuge.generales.estadoNacimientoObj",
        axiosData?.generales?.estados?.filter(
          (e) => e.id === modeloPfae?.conyuge?.generales?.estadoNacimientoId
        )
      );

      formik.setFieldValue(
        "conyuge.generales.generoObj",
        axiosData?.generales?.generoOpciones?.filter(
          (e) => e.id === modeloPfae?.conyuge?.generales?.generoId
        )
      );

      formik.setFieldValue(
        "conyuge.generales.nacionalidadObj",
        axiosData?.generales?.nacionalidades?.filter(
          (e) => e.id === modeloPfae?.conyuge?.generales?.nacionalidadId
        )
      );

      formik.setFieldValue(
        "conyuge.generales.estadoCivilObj",
        axiosData?.generales?.estadoCivilOpciones?.filter(
          (e) => e.id === modeloPfae?.conyuge?.generales?.estadoCivilId
        )
      );

      formik.setFieldValue(
        "conyuge.generales.nivelEducativoObj",
        axiosData?.generales?.nivelEducativoOpciones?.filter(
          (e) => e.id === modeloPfae?.conyuge?.generales?.nivelEducativoId
        )
      );

      formik.setFieldValue(
        "conyuge.generales.dependientesEconomicosMenores23Obj",
        axiosData?.generales?.dependientesMenores23Anios?.filter(
          (e) =>
            e.valor ===
            modeloPfae?.conyuge?.generales?.dependientesEconomicosMenores23
        )
      );

      formik.setFieldValue(
        "conyuge.generales.dependientesEconomicosObj",
        axiosData?.generales?.dependientes?.filter(
          (e) =>
            e.valor === modeloPfae?.conyuge?.generales?.dependientesEconomicos
        )
      );

      if (modeloPfae?.conyuge?.datosDelNegocio !== null) {
        formik.setFieldValue(
          "conyuge.datosDelNegocio.importaExportaObj",
          axiosData?.domicilioFiscal?.importaExportaOpciones?.filter(
            (e) =>
              e.id === modeloPfae?.conyuge?.datosDelNegocio?.importaExportaId
          )
        );

        formik.setFieldValue(
          "conyuge.datosDelNegocio.sectorObj",
          axiosData?.domicilioFiscal?.sectorOpciones?.filter(
            (e) => e.id === modeloPfae?.conyuge?.datosDelNegocio?.sectorId
          )
        );

        formik.setFieldValue(
          "conyuge.datosDelNegocio.giroEmpresarialObj",
          axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.filter(
            (e) =>
              e.id === modeloPfae?.conyuge?.datosDelNegocio?.giroEmpresarialId
          )
        );

        formik.setFieldValue(
          "conyuge.datosDelNegocio.actividadObj",
          axiosData?.domicilioFiscal?.actividadOpciones?.filter(
            (e) => e.id === modeloPfae?.conyuge?.datosDelNegocio?.actividadId
          )
        );
      }

      let dependientesMajor23Opc = modeloPfae?.conyuge?.generales
        ?.dependientesEconomicosMenores23
        ? axiosData?.generales?.dependientesMenores23Anios?.filter(
            (e) =>
              e.id ===
              modeloPfae?.conyuge?.generales?.dependientesEconomicosMenores23
          )
        : "";
      let dependientesEconomicoOpc = modeloPfae?.conyuge?.generales
        ?.dependientesEconomicos
        ? axiosData?.generales?.dependientes?.filter(
            (e) =>
              e.id === modeloPfae?.conyuge?.generales?.dependientesEconomicos
          )
        : "";
      setTotalDependientesMenores23(
        dependientesMajor23Opc !== ""
          ? dependientesMajor23Opc[0].descripcion
          : 0
      );
      setTotalDependientes(
        dependientesEconomicoOpc !== ""
          ? dependientesEconomicoOpc[0].descripcion
          : 0
      );

      if (modeloPfae?.conyuge?.generales?.calidadMigratoria === null) {
        formik.setFieldValue("conyuge.generales.calidadMigratoria", "");
      }

      if (
        modeloPfae?.conyuge?.generales?.esObligadoSolidario !==
        esObligadoSolidario
      ) {
        formik.setFieldValue(
          "conyuge.generales.esObligadoSolidario",
          esObligadoSolidario
        );

        if (esObligadoSolidario) {
          formik.setFieldValue(
            "conyuge.generales.tipoPersonaId",
            idTipoPersonaSolicitanteConyuge
          );
        } else {
          formik.setFieldValue("conyuge.generales.tipoPersonaId", "");
        }
      }

      if (modeloPfae?.conyuge?.generales?.nombreOtraEmpresaPfae === null) {
        formik.setFieldValue("conyuge.generales.nombreOtraEmpresaPfae", "");
      }

      if (modeloPfae?.conyuge?.generales?.nombreOtraEmpresa === null) {
        formik.setFieldValue("conyuge.generales.nombreOtraEmpresa", "");
      }

      if (modeloPfae?.conyuge?.generales?.paisResidenciaFiscal2Id === null) {
        formik.setFieldValue("conyuge.generales.paisResidenciaFiscal2Id", "");
      }

      if (modeloPfae?.conyuge?.generales?.numeroIdFiscal2 === null) {
        formik.setFieldValue("conyuge.generales.numeroIdFiscal2", "");
      }
    } else {
      formik.setFieldValue(
        "conyuge.generales.estadoCivilId",
        ESTADO_CIVIL_SOCIEDAD_CONYUGAL
      );
      formik.setFieldValue(
        "conyuge.generales.estadoCivilObj",
        axiosData?.generales?.estadoCivilOpciones?.filter(
          (e) => e.id === ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        )
      );
    }

    setModeloPfae({
      ...modeloPfae,
      conyuge: {
        ...modeloPfae.conyuge,
        generales: {
          ...modeloPfae.conyuge.generales,
          esObligadoSolidario: esObligadoSolidario,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (esObligadoSolidario && modeloPfae?.conyuge?.idConyuge !== null) {
      if (formik.values?.conyuge?.generales?.folioVerificacionId === null) {
        formik.setFieldValue("conyuge.generales.folioVerificacionId", "");
      }

      if (formik.values?.conyuge?.generales?.estadoCivilId === null) {
        formik.setFieldValue("conyuge.generales.estadoCivilId", "");
      }

      if (formik.values?.conyuge?.generales?.nivelEducativoId === null) {
        formik.setFieldValue("conyuge.generales.nivelEducativoId", "");
      }

      if (formik.values?.conyuge?.generales?.profesion === null) {
        formik.setFieldValue("conyuge.generales.profesion", "");
      }

      if (formik.values?.conyuge?.generales?.correoElectronico === null) {
        formik.setFieldValue("conyuge.generales.correoElectronico", "");
      }

      if (formik.values?.conyuge?.generales?.telefono === null) {
        formik.setFieldValue("conyuge.generales.telefono", "");
      }

      if (formik.values?.conyuge?.generales?.celular === null) {
        formik.setFieldValue("conyuge.generales.celular", "");
      }

      if (
        formik.values?.conyuge?.generales?.dependientesEconomicosMenores23 ===
        null
      ) {
        formik.setFieldValue(
          "conyuge.generales.dependientesEconomicosMenores23",
          ""
        );
      }

      if (formik.values?.conyuge?.generales?.dependientesEconomicos === null) {
        formik.setFieldValue("conyuge.generales.dependientesEconomicos", "");
      }

      if (formik.values?.conyuge?.direccion?.calle === null) {
        formik.setFieldValue("conyuge.direccion.calle", "");
      }
    }
  }, [esObligadoSolidario]);

  useEffect(() => {
    if (idTipoPersonaSolicitanteConyuge !== null) {
      formik.setFieldValue(
        "conyuge.generales.tipoPersonaId",
        idTipoPersonaSolicitanteConyuge
      );
    }
  }, [idTipoPersonaSolicitanteConyuge]);

  return (
    <>
      <form name="form-pa-conyuge" onSubmit={formik.handleSubmit}>
        <div>
          <h2 className="f-s-3 text-center">Información general</h2>

          <FormLayout>
            <InputWithError
              label="Nombre *"
              name="conyuge.generales.nombre"
              value={formik.values?.conyuge?.generales?.nombre}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.conyuge !== undefined &&
                  newErrors?.conyuge?.generales !== undefined &&
                  newErrors?.conyuge?.generales?.nombre
                ) {
                  delete newErrors?.conyuge?.generales?.nombre;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "conyuge.generales.nombre",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_NOMBRE, e.target.value);
              }}
              InputProps={{ maxLength: 30 }}
              showErrorMessage={formik.errors?.conyuge?.generales?.nombre}
              errorMessage={formik.errors?.conyuge?.generales?.nombre}
            />
            <InputWithError
              label="Apellido paterno *"
              name="conyuge.generales.apellidoPaterno"
              value={formik.values?.conyuge?.generales?.apellidoPaterno}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.conyuge !== undefined &&
                  newErrors?.conyuge?.generales !== undefined &&
                  newErrors?.conyuge?.generales?.apellidoPaterno
                ) {
                  delete newErrors?.conyuge?.generales?.apellidoPaterno;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "conyuge.generales.apellidoPaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APP, e.target.value);
              }}
              InputProps={{ maxLength: 20 }}
              showErrorMessage={
                formik.errors?.conyuge?.generales?.apellidoPaterno
              }
              errorMessage={formik.errors?.conyuge?.generales?.apellidoPaterno}
            />
            <InputWithError
              label="Apellido materno"
              name="conyuge.generales.apellidoMaterno"
              value={formik.values?.conyuge?.generales?.apellidoMaterno}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.conyuge !== undefined &&
                  newErrors?.conyuge?.generales !== undefined &&
                  newErrors?.conyuge?.generales?.apellidoMaterno
                ) {
                  delete newErrors?.conyuge?.generales?.apellidoMaterno;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "conyuge.generales.apellidoMaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APM, e.target.value);
              }}
              InputProps={{ maxLength: 20 }}
              showErrorMessage={
                formik.errors?.conyuge?.generales?.apellidoMaterno
              }
              errorMessage={formik.errors?.conyuge?.generales?.apellidoMaterno}
            />
            <InputWithError
              type="date"
              name="conyuge.generales.fechaNacimiento"
              value={startDate}
              label="Fecha de nacimiento *"
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.conyuge !== undefined &&
                  newErrors?.conyuge?.generales !== undefined &&
                  newErrors?.conyuge?.generales?.fechaNacimiento
                ) {
                  delete newErrors?.conyuge?.generales?.fechaNacimiento;
                  formik.setErrors(newErrors);
                }
                let array = e.target.value.split("-").reverse().join("-");
                formik.setFieldValue(
                  "conyuge.generales.fechaNacimiento",
                  array
                );
                setStartDate(e.target.value);
                generarRfcCurp(ID_CALCULO_RFC_FECHANAC, array);
              }}
              InputProps={{
                shrink: true,
                max: fechaMaxMayorEdadYYYMMDD(),
                min: fechaMinEdadYYYMMDD(),
              }}
              showErrorMessage={
                formik.errors?.conyuge?.generales?.fechaNacimiento
              }
              errorMessage={formik.errors?.conyuge?.generales?.fechaNacimiento}
            />
            <SelectWithError
              label="País de nacimiento *"
              name="conyuge.generales.paisNacimientoId"
              value={{
                label:
                  formik.values?.conyuge?.generales?.paisNacimientoObj?.[0]
                    .nombre,
                value:
                  formik.values?.conyuge?.generales?.paisNacimientoObj?.[0]
                    .nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.conyuge !== undefined &&
                  newErrors?.conyuge?.generales !== undefined &&
                  newErrors?.conyuge?.generales?.paisNacimientoId
                ) {
                  delete newErrors?.conyuge?.generales?.paisNacimientoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "conyuge.generales.paisNacimientoId",
                  e.id
                );
                formik.setFieldValue("conyuge.generales.paisNacimientoObj", [
                  e,
                ]);
              }}
              options={axiosData?.generales?.paises?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.conyuge?.generales?.paisNacimientoId
              }
              errorMessage={formik.errors?.conyuge?.generales?.paisNacimientoId}
            />
            <SelectWithError
              label="Nacionalidad *"
              name="conyuge.generales.nacionalidadId"
              value={{
                label:
                  formik?.values?.conyuge?.generales?.nacionalidadObj?.[0]
                    ?.nombre,
                value:
                  formik?.values?.conyuge?.generales?.nacionalidadObj?.[0]
                    ?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.conyuge !== undefined &&
                  newErrors?.conyuge?.generales !== undefined &&
                  newErrors?.conyuge?.generales?.nacionalidadId
                ) {
                  delete newErrors?.conyuge?.generales?.nacionalidadId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue("conyuge.generales.nacionalidadId", e.id);
                formik.setFieldValue("conyuge.generales.nacionalidadObj", [e]);
              }}
              options={axiosData?.generales?.nacionalidades?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.conyuge?.generales?.nacionalidadId
              }
              errorMessage={formik.errors?.conyuge?.generales?.nacionalidadId}
            />
            {formik.values?.conyuge?.generales?.paisNacimientoId !== "" &&
              parseInt(formik.values?.conyuge?.generales?.paisNacimientoId) === ID_PAIS_MEXICO && (
              <SelectWithError
                name="conyuge.generales.estadoNacimientoId"
                value={{
                  label:
                    formik?.values?.conyuge?.generales?.estadoNacimientoObj?.[0]
                      ?.nombre,
                  value:
                    formik?.values?.conyuge?.generales?.estadoNacimientoObj?.[0]
                      ?.nombre,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.conyuge !== undefined &&
                    newErrors?.conyuge?.generales !== undefined &&
                    newErrors?.conyuge?.generales?.estadoNacimientoId
                  ) {
                    delete newErrors?.conyuge?.generales?.estadoNacimientoId;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue(
                    "conyuge.generales.estadoNacimientoId",
                    e.id
                  );
                  formik.setFieldValue("conyuge.generales.estadoNacimientoObj", [
                    e,
                  ]);
                  generarRfcCurp(ID_CALCULO_RFC_ESTADONAC, e.id);
                }}
                label="Estado de nacimiento *"
                options={axiosData?.generales?.estados?.map((item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                })}
                showErrorMessage={
                  formik.errors?.conyuge?.generales?.estadoNacimientoId
                }
                errorMessage={
                  formik.errors?.conyuge?.generales?.estadoNacimientoId
                }
              />
            )}
            <SelectWithError
              name="conyuge.generales.generoId"
              value={{
                label:
                  formik?.values?.conyuge?.generales?.generoObj?.[0]?.nombre,
                value:
                  formik?.values?.conyuge?.generales?.generoObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.conyuge !== undefined &&
                  newErrors?.conyuge?.generales !== undefined &&
                  newErrors?.conyuge?.generales?.generoId
                ) {
                  delete newErrors?.conyuge?.generales?.generoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue("conyuge.generales.generoId", e.id);
                formik.setFieldValue("conyuge.generales.generoObj", [e]);
                generarRfcCurp(ID_CALCULO_RFC_GENERO, e.id);
              }}
              label="Género *"
              options={axiosData?.generales?.generoOpciones?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.conyuge?.generales?.generoId}
              errorMessage={formik.errors?.conyuge?.generales?.generoId}
            />
            <InputWithError
              name="conyuge.generales.rfc"
              value={formik.values?.conyuge?.generales?.rfc}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.conyuge !== undefined &&
                  newErrors?.conyuge?.generales !== undefined &&
                  newErrors?.conyuge?.generales?.rfc
                ) {
                  delete newErrors?.conyuge?.generales?.rfc;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue("conyuge.generales.rfc", newValue);
                }
              }}
              label="RFC *"
              InputProps={{ maxLength: 13 }}
              showErrorMessage={formik.errors?.conyuge?.generales?.rfc}
              errorMessage={formik.errors?.conyuge?.generales?.rfc}
            />
            <InputWithError
              name="conyuge.generales.curp"
              value={formik.values?.conyuge?.generales?.curp}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.conyuge !== undefined &&
                  newErrors?.conyuge?.generales !== undefined &&
                  newErrors?.conyuge?.generales?.curp
                ) {
                  delete newErrors?.conyuge?.generales?.curp;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue("conyuge.generales.curp", newValue);
                }
              }}
              label="CURP *"
              InputProps={{ maxLength: 18 }}
              showErrorMessage={formik.errors?.conyuge?.generales?.curp}
              errorMessage={formik.errors?.conyuge?.generales?.curp}
            />
            {esObligadoSolidario === true && (
              <InputWithError
                label="Número de identificación oficial *"
                name="conyuge.generales.folioVerificacionId"
                value={formik.values?.conyuge?.generales?.folioVerificacionId}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.conyuge !== undefined &&
                    newErrors?.conyuge?.generales !== undefined &&
                    newErrors?.conyuge?.generales?.folioVerificacionId
                  ) {
                    delete newErrors?.conyuge?.generales?.folioVerificacionId;
                    formik.setErrors(newErrors);
                  }

                  if (validateValue(e.target.value, "alphanumeric")) {
                    formik.setFieldValue(
                      "conyuge.generales.folioVerificacionId",
                      e.target.value
                    );
                  }
                }}
                InputProps={{ maxLength: 18 }}
                tooltip="Captura el número de folio de tu identificación, si es INE lo encontrarás en la parte posterior de la misma, después de las letras IDMEX"
                showErrorMessage={
                  formik.errors?.conyuge?.generales?.folioVerificacionId
                }
                errorMessage={
                  formik.errors?.conyuge?.generales?.folioVerificacionId
                }
              />
            )}
            {esObligadoSolidario === true && (
              <>
                {formik.values?.conyuge?.generales?.nacionalidadId !== "" &&
                  parseInt(
                    formik.values?.conyuge?.generales?.nacionalidadId
                  ) !== NACIONALIDAD_MEXICAN0 && (
                    <InputWithError
                      label="Calidad migratoria *"
                      name="conyuge.generales.calidadMigratoria"
                      value={
                        formik.values?.conyuge?.generales?.calidadMigratoria
                      }
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.conyuge !== undefined &&
                          newErrors?.conyuge?.generales !== undefined &&
                          newErrors?.conyuge?.generales?.calidadMigratoria
                        ) {
                          delete newErrors?.conyuge?.generales
                            ?.calidadMigratoria;
                          formik.setErrors(newErrors);
                        }

                        if (validarTextoConEnies(e.target.value, "text")) {
                          formik.setFieldValue(
                            "conyuge.generales.calidadMigratoria",
                            e.target.value
                          );
                        }
                      }}
                      InputProps={{ maxLength: 180 }}
                      showErrorMessage={
                        formik.errors?.conyuge?.generales?.calidadMigratoria
                      }
                      errorMessage={
                        formik.errors?.conyuge?.generales?.calidadMigratoria
                      }
                    />
                  )}
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.conyuge?.generales?.estadoCivilId
                  }
                  errorMessage={
                    formik.errors?.conyuge?.generales?.estadoCivilId
                  }
                  label="Estado civil *"
                  name="conyuge.generales.estadoCivilId"
                  value={{
                    label:
                      formik?.values?.conyuge?.generales?.estadoCivilObj?.[0]
                        ?.nombre,
                    value:
                      formik?.values?.conyuge?.generales?.estadoCivilObj?.[0]
                        ?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.generales !== undefined &&
                      newErrors?.conyuge?.generales?.estadoCivilId
                    ) {
                      delete newErrors?.conyuge?.generales?.estadoCivilId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "conyuge.generales.estadoCivilId",
                      e.id
                    );
                    formik.setFieldValue("conyuge.generales.estadoCivilObj", [
                      e,
                    ]);
                  }}
                  options={axiosData?.generales?.estadoCivilOpciones?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.nombre,
                        value: item.nombre,
                      };
                    }
                  )}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.conyuge?.generales?.nivelEducativoId
                  }
                  errorMessage={
                    formik.errors?.conyuge?.generales?.nivelEducativoId
                  }
                  name="conyuge.generales.nivelEducativoId"
                  value={{
                    label:
                      formik?.values?.conyuge?.generales?.nivelEducativoObj?.[0]
                        ?.nombre,
                    value:
                      formik?.values?.conyuge?.generales?.nivelEducativoObj?.[0]
                        ?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.generales !== undefined &&
                      newErrors?.conyuge?.generales?.nivelEducativoId
                    ) {
                      delete newErrors?.conyuge?.generales?.nivelEducativoId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "conyuge.generales.nivelEducativoId",
                      e.id
                    );
                    formik.setFieldValue(
                      "conyuge.generales.nivelEducativoObj",
                      [e]
                    );
                  }}
                  label="Nivel académico *"
                  options={axiosData?.generales?.nivelEducativoOpciones?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.nombre,
                        value: item.nombre,
                      };
                    }
                  )}
                />
                <InputWithError
                  label="Ocupación o profesión *"
                  name="conyuge.generales.profesion"
                  value={formik.values?.conyuge?.generales?.profesion}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.generales !== undefined &&
                      newErrors?.conyuge?.generales?.profesion
                    ) {
                      delete newErrors?.conyuge?.generales?.profesion;
                      formik.setErrors(newErrors);
                    }

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "conyuge.generales.profesion",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                  showErrorMessage={
                    formik.errors?.conyuge?.generales?.profesion
                  }
                  errorMessage={formik.errors?.conyuge?.generales?.profesion}
                />
                <InputWithError
                  name="conyuge.generales.correoElectronico"
                  value={formik.values?.conyuge?.generales?.correoElectronico}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.generales !== undefined &&
                      newErrors?.conyuge?.generales?.correoElectronico
                    ) {
                      delete newErrors?.conyuge?.generales?.correoElectronico;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "conyuge.generales.correoElectronico",
                      e.target.value
                    );
                  }}
                  label="Correo electrónico *"
                  InputProps={{ maxLength: 50 }}
                  showErrorMessage={
                    formik.errors?.conyuge?.generales?.correoElectronico
                  }
                  errorMessage={
                    formik.errors?.conyuge?.generales?.correoElectronico
                  }
                />
                <InputWithError
                  label="Teléfono *"
                  name="conyuge.generales.telefono"
                  value={formik.values?.conyuge?.generales?.telefono}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.generales !== undefined &&
                      newErrors?.conyuge?.generales?.telefono
                    ) {
                      delete newErrors?.conyuge?.generales?.telefono;
                      formik.setErrors(newErrors);
                    }

                    let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                      e.target.value,
                      formik.values?.conyuge?.generales?.telefono
                    );
                    formik.setFieldValue(
                      "conyuge.generales.telefono",
                      numTelefono
                    );
                  }}
                  type="tel"
                  InputProps={{ maxLength: 12 }}
                  showErrorMessage={formik.errors?.conyuge?.generales?.telefono}
                  errorMessage={formik.errors?.conyuge?.generales?.telefono}
                />
                <InputWithError
                  name="conyuge.generales.celular"
                  label="Celular *"
                  value={formik.values?.conyuge?.generales?.celular}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.generales !== undefined &&
                      newErrors?.conyuge?.generales?.celular
                    ) {
                      delete newErrors?.conyuge?.generales?.celular;
                      formik.setErrors(newErrors);
                    }

                    let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                      e.target.value,
                      formik.values?.conyuge?.generales?.celular
                    );
                    formik.setFieldValue(
                      "conyuge.generales.celular",
                      numTelefono
                    );
                  }}
                  type="tel"
                  InputProps={{ maxLength: 12 }}
                  showErrorMessage={formik.errors?.conyuge?.generales?.celular}
                  errorMessage={formik.errors?.conyuge?.generales?.celular}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.conyuge?.generales
                      ?.dependientesEconomicosMenores23
                  }
                  errorMessage={
                    formik.errors?.conyuge?.generales
                      ?.dependientesEconomicosMenores23
                  }
                  label="Cantidad de dependientes menores de 23 años *"
                  name="conyuge.generales.dependientesEconomicosMenores23"
                  value={{
                    label:
                      formik?.values?.conyuge?.generales
                        ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion,
                    value:
                      formik?.values?.conyuge?.generales
                        ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.generales !== undefined &&
                      newErrors?.conyuge?.generales
                        ?.dependientesEconomicosMenores23
                    ) {
                      delete newErrors?.conyuge?.generales
                        ?.dependientesEconomicosMenores23;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "conyuge.generales.dependientesEconomicosMenores23",
                      e.id
                    );
                    formik.setFieldValue(
                      "conyuge.generales.dependientesEconomicosMenores23Obj",
                      [e]
                    );
                    setTotalDependientesMenores23(e.descripcion);
                  }}
                  options={axiosData?.generales?.dependientesMenores23Anios?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.descripcion,
                        value: item.descripcion,
                      };
                    }
                  )}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.conyuge?.generales?.dependientesEconomicos
                  }
                  errorMessage={
                    formik.errors?.conyuge?.generales?.dependientesEconomicos
                  }
                  name="conyuge.generales.dependientesEconomicos"
                  value={{
                    label:
                      formik?.values?.conyuge?.generales
                        ?.dependientesEconomicosObj?.[0]?.descripcion,
                    value:
                      formik?.values?.conyuge?.generales
                        ?.dependientesEconomicosObj?.[0]?.descripcion,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.generales !== undefined &&
                      newErrors?.conyuge?.generales?.dependientesEconomicos
                    ) {
                      delete newErrors?.conyuge?.generales
                        ?.dependientesEconomicos;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "conyuge.generales.dependientesEconomicos",
                      e.id
                    );
                    formik.setFieldValue(
                      "conyuge.generales.dependientesEconomicosObj",
                      [e]
                    );
                    setTotalDependientes(e.descripcion);
                  }}
                  label="Cantidad total de dependientes *"
                  options={axiosData?.generales?.dependientes?.map((item) => {
                    return {
                      ...item,
                      label: item.descripcion,
                      value: item.descripcion,
                    };
                  })}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "25px",
                  }}
                >
                  <div
                    style={{
                      display: window.innerWidth < 700 && "flex",
                    }}
                  >
                    <CheckBoxInput
                      name="conyuge.generales.esAccionistaOtraEmpresa"
                      state=""
                      checked={
                        formik.values?.conyuge?.generales
                          ?.esAccionistaOtraEmpresa
                      }
                      onChange={(e) =>
                        formik.setFieldValue(
                          "conyuge.generales.esAccionistaOtraEmpresa",
                          !formik.values?.conyuge?.generales
                            ?.esAccionistaOtraEmpresa
                        )
                      }
                      typeOfCheck="normal"
                      id="obligadoSolidarioOtraEmpresa"
                    />
                    <label
                      className="form-check-label lable-margin"
                      htmlFor="obligadoSolidarioOtraEmpresa"
                    >
                      El obligado es accionista u obligado de otra empresa
                    </label>
                  </div>
                  {formik.values?.conyuge?.generales
                    ?.esAccionistaOtraEmpresa && (
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <InputWithError
                        label="Nombre de la empresa o PFAE *"
                        name="conyuge.generales.nombreOtraEmpresa"
                        value={
                          formik.values?.conyuge?.generales?.nombreOtraEmpresa
                            ? formik.values?.conyuge?.generales
                                ?.nombreOtraEmpresa
                            : ""
                        }
                        onChange={(e) => {
                          let newErrors = formik.errors;
                          if (
                            newErrors?.conyuge !== undefined &&
                            newErrors?.conyuge?.generales !== undefined &&
                            newErrors?.conyuge?.generales?.nombreOtraEmpresa
                          ) {
                            delete newErrors?.conyuge?.generales
                              ?.nombreOtraEmpresa;
                            formik.setErrors(newErrors);
                          }

                          if (validarTextoConEnies(e.target.value, "text")) {
                            formik.setFieldValue(
                              "conyuge.generales.nombreOtraEmpresa",
                              e.target.value
                            );
                          }
                        }}
                        InputProps={{ maxLength: 180 }}
                        showErrorMessage={
                          formik.errors?.conyuge?.generales?.nombreOtraEmpresa
                        }
                        errorMessage={
                          formik.errors?.conyuge?.generales?.nombreOtraEmpresa
                        }
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: window.innerWidth < 700 && "flex",
                    }}
                  >
                    <CheckBoxInput
                      name="conyuge.generales.esObligadoSolidarioOtraEmpresaPfae"
                      state=""
                      checked={
                        formik.values?.conyuge?.generales
                          ?.esObligadoSolidarioOtraEmpresaPfae
                      }
                      onChange={(e) =>
                        formik.setFieldValue(
                          "conyuge.generales.esObligadoSolidarioOtraEmpresaPfae",
                          !formik.values?.conyuge?.generales
                            ?.esObligadoSolidarioOtraEmpresaPfae
                        )
                      }
                      typeOfCheck="normal"
                      id="obligadoSolidarioOtraEmpresaPfae"
                    />
                    <label
                      className="form-check-label lable-margin"
                      htmlFor="obligadoSolidarioOtraEmpresaPfae"
                    >
                      Es obligado o fiador de otra empresa o PFAE
                    </label>
                  </div>
                  {formik.values?.conyuge?.generales
                    ?.esObligadoSolidarioOtraEmpresaPfae && (
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <InputWithError
                        name="conyuge.generales.nombreOtraEmpresaPfae"
                        label="Nombre de la empresa o PFAE *"
                        value={
                          formik.values?.conyuge?.generales
                            ?.nombreOtraEmpresaPfae
                            ? formik.values?.conyuge?.generales
                                ?.nombreOtraEmpresaPfae
                            : ""
                        }
                        onChange={(e) => {
                          let newErrors = formik.errors;
                          if (
                            newErrors?.conyuge !== undefined &&
                            newErrors?.conyuge?.generales !== undefined &&
                            newErrors?.conyuge?.generales?.nombreOtraEmpresaPfae
                          ) {
                            delete newErrors?.conyuge?.generales
                              ?.nombreOtraEmpresaPfae;
                            formik.setErrors(newErrors);
                          }

                          if (validarTextoConEnies(e.target.value, "text")) {
                            formik.setFieldValue(
                              "conyuge.generales.nombreOtraEmpresaPfae",
                              e.target.value
                            );
                          }
                        }}
                        InputProps={{ maxLength: 180 }}
                        showErrorMessage={
                          formik.errors?.conyuge?.generales
                            ?.nombreOtraEmpresaPfae
                        }
                        errorMessage={
                          formik.errors?.conyuge?.generales
                            ?.nombreOtraEmpresaPfae
                        }
                      />
                    </div>
                  )}
                </div>
                <h2
                  style={{ margin: "2rem 0 1rem 0" }}
                  className="f-s-3 text-center"
                >
                  Información de domicilio
                </h2>
                <InputWithError
                  label="Calle *"
                  name="conyuge.direccion.calle"
                  value={formik.values?.conyuge?.direccion?.calle}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.calle
                    ) {
                      delete newErrors?.conyuge?.direccion?.calle;
                      formik.setErrors(newErrors);
                    }

                    if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                      formik.setFieldValue(
                        "conyuge.direccion.calle",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 24 }}
                  showErrorMessage={formik.errors?.conyuge?.direccion?.calle}
                  errorMessage={formik.errors?.conyuge?.direccion?.calle}
                />
                <InputWithError
                  label="Número exterior *"
                  name="conyuge.direccion.numeroExterior"
                  value={formik.values?.conyuge?.direccion?.numeroExterior}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.numeroExterior
                    ) {
                      delete newErrors?.conyuge?.direccion?.numeroExterior;
                      formik.setErrors(newErrors);
                    }

                    if (notSpecialCaractersConGuion(e.target.value)) {
                      formik.setFieldValue(
                        "conyuge.direccion.numeroExterior",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 7 }}
                  showErrorMessage={
                    formik.errors?.conyuge?.direccion?.numeroExterior
                  }
                  errorMessage={
                    formik.errors?.conyuge?.direccion?.numeroExterior
                  }
                />
                <InputWithError
                  name="conyuge.direccion.numeroInterior"
                  value={formik.values?.conyuge?.direccion?.numeroInterior}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.numeroInterior
                    ) {
                      delete newErrors?.conyuge?.direccion?.numeroInterior;
                      formik.setErrors(newErrors);
                    }

                    if (validateValue(e.target.value, "alphanumeric")) {
                      formik.setFieldValue(
                        "conyuge.direccion.numeroInterior",
                        e.target.value
                      );
                    }
                  }}
                  required={
                    formik.values.conyuge?.direccion?.tipoViviendaId ===
                      parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                    formik.values.conyuge?.direccion?.tipoViviendaId ===
                      parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
                  }
                  label="Número interior"
                  InputProps={{ maxLength: 2 }}
                  tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu  “Número exterior” separados por un guion Ejemplo: 318-123B"
                  showErrorMessage={
                    formik.errors?.conyuge?.direccion?.numeroInterior
                  }
                  errorMessage={
                    formik.errors?.conyuge?.direccion?.numeroInterior
                  }
                />
                <InputWithError
                  name="conyuge.direccion.piso"
                  value={formik.values?.conyuge?.direccion?.piso}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.piso
                    ) {
                      delete newErrors?.conyuge?.direccion?.piso;
                      formik.setErrors(newErrors);
                    }

                    if (validateValue(e.target.value, "alphanumeric")) {
                      formik.setFieldValue(
                        "conyuge.direccion.piso",
                        e.target.value
                      );
                    }
                  }}
                  required={
                    formik.values.conyuge?.direccion?.tipoViviendaId ===
                      parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                    formik.values.conyuge?.direccion?.tipoViviendaId ===
                      parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
                  }
                  label="Piso"
                  InputProps={{ maxLength: 2 }}
                  showErrorMessage={formik.errors?.conyuge?.direccion?.piso}
                  errorMessage={formik.errors?.conyuge?.direccion?.piso}
                />
                <InputWithError
                  name="conyuge.direccion.codigoPostal"
                  value={formik.values?.conyuge?.direccion?.codigoPostal}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.codigoPostal
                    ) {
                      delete newErrors?.conyuge?.direccion?.codigoPostal;
                      formik.setErrors(newErrors);
                    }

                    if (
                      e.target.value.length <= 5 &&
                      validateValue(e.target.value, "number")
                    ) {
                      formik.setFieldValue(
                        "conyuge.direccion.codigoPostal",
                        e.target.value
                      );
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value.length === 5) {
                      obtenerDireccionByCp(e.target.value);
                    } else {
                      limpiarDireccionByCp();
                    }
                  }}
                  label="CP *"
                  InputProps={{ maxLength: 5 }}
                  showErrorMessage={
                    formik.errors?.conyuge?.direccion?.codigoPostal
                  }
                  errorMessage={formik.errors?.conyuge?.direccion?.codigoPostal}
                />
                <SelectWithError
                  showErrorMessage={formik.errors?.conyuge?.direccion?.colonia}
                  errorMessage={formik.errors?.conyuge?.direccion?.colonia}
                  name="conyuge.direccion.colonia"
                  value={{
                    label: formik.values?.conyuge?.direccion?.colonia,
                    value: formik.values?.conyuge?.direccion?.colonia,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.colonia
                    ) {
                      delete newErrors?.conyuge?.generales?.colonia;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue("conyuge.direccion.colonia", e.label);
                  }}
                  label="Colonia *"
                  options={coloniasCP?.map((item) => {
                    return {
                      ...item,
                      label: item,
                      value: item,
                    };
                  })}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.conyuge?.direccion?.municipioId
                  }
                  errorMessage={formik.errors?.conyuge?.direccion?.municipioId}
                  name="conyuge.direccion.municipioId"
                  state=""
                  value={{
                    label:
                      formik?.values?.conyuge?.direccion?.municipioObj?.[0]
                        ?.nombre,
                    value:
                      formik?.values?.conyuge?.direccion?.municipioObj?.[0]
                        ?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.municipioId
                    ) {
                      delete newErrors?.conyuge?.direccion?.municipioId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue("conyuge.direccion.municipioId", e.id);
                    formik.setFieldValue("conyuge.direccion.municipioObj", [e]);
                  }}
                  isDisabled={true}
                  label="Alcaldía / Municipio *"
                  placeholder="Delegación/Municipio *"
                  options={axiosData?.generales?.municipios?.map((item) => {
                    return {
                      ...item,
                      label: item.nombre,
                      value: item.nombre,
                    };
                  })}
                />
                <SelectWithError
                  showErrorMessage={formik.errors?.conyuge?.direccion?.estadoId}
                  errorMessage={formik.errors?.conyuge?.direccion?.estadoId}
                  name="conyuge.direccion.estadoId"
                  value={{
                    label:
                      formik?.values?.conyuge?.direccion?.estadoObj?.[0]
                        ?.nombre,
                    value:
                      formik?.values?.conyuge?.direccion?.estadoObj?.[0]
                        ?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.estadoId
                    ) {
                      delete newErrors?.conyuge?.direccion?.estadoId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue("conyuge.direccion.estadoId", e.id);
                    formik.setFieldValue("conyuge.direccion.estadoObj", [e]);
                  }}
                  placeholder="Estado *"
                  label="Estado *"
                  isDisabled={true}
                  options={axiosData?.generales?.estados?.map((item) => {
                    return {
                      ...item,
                      label: item.nombre,
                      value: item.nombre,
                    };
                  })}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.conyuge?.direccion?.tipoDomicilioId
                  }
                  errorMessage={
                    formik.errors?.conyuge?.direccion?.tipoDomicilioId
                  }
                  label="Tipo de domicilio *"
                  name="conyuge.direccion.tipoDomicilioId"
                  value={{
                    label:
                      formik?.values?.conyuge?.direccion?.tipoDomicilioObj?.[0]
                        ?.nombre,
                    value:
                      formik?.values?.conyuge?.direccion?.tipoDomicilioObj?.[0]
                        ?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.tipoDomicilioId
                    ) {
                      delete newErrors?.conyuge?.direccion?.tipoDomicilioId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "conyuge.direccion.tipoDomicilioId",
                      e.id
                    );
                    formik.setFieldValue("conyuge.direccion.tipoDomicilioObj", [
                      e,
                    ]);
                  }}
                  options={axiosData?.domicilio?.tipoDeDomicilioOpciones?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.nombre,
                        value: item.nombre,
                      };
                    }
                  )}
                />
                <SelectWithError
                  showErrorMessage={
                    formik.errors?.conyuge?.direccion?.tipoViviendaId
                  }
                  errorMessage={
                    formik.errors?.conyuge?.direccion?.tipoViviendaId
                  }
                  name="conyuge.direccion.tipoViviendaId"
                  value={{
                    label:
                      formik?.values?.conyuge?.direccion?.tipoViviendaObj?.[0]
                        ?.nombre,
                    value:
                      formik?.values?.conyuge?.direccion?.tipoViviendaObj?.[0]
                        ?.nombre,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.conyuge !== undefined &&
                      newErrors?.conyuge?.direccion !== undefined &&
                      newErrors?.conyuge?.direccion?.tipoViviendaId
                    ) {
                      delete newErrors?.conyuge?.direccion?.tipoViviendaId;
                      formik.setErrors(newErrors);
                    }

                    formik.setFieldValue(
                      "conyuge.direccion.tipoViviendaId",
                      e.id
                    );
                    formik.setFieldValue("conyuge.direccion.tipoViviendaObj", [
                      e,
                    ]);
                  }}
                  label="Tipo de vivienda *"
                  options={axiosData?.domicilio?.tipoDeViviendaOpciones?.map(
                    (item) => {
                      return {
                        ...item,
                        label: item.nombre,
                        value: item.nombre,
                      };
                    }
                  )}
                />
                <div
                  style={{
                    width: innerWidth < 700 ? "100%" : "55%",
                    minWidth: "290px",
                    maxWidth: "425px",
                  }}
                >
                  <label>Antigüedad en el domicilio actual *</label>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "10px",
                      flexDirection: innerWidth < 700 ? "column" : "row",
                    }}
                  >
                    <div
                      style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                      className="selectInputCont"
                    >
                      {formik.values?.conyuge?.direccion
                        ?.tiempoViviendaAnios ? (
                        <span className="select-label-no-null">Años *</span>
                      ) : (
                        <span className="select-label">Años *</span>
                      )}
                      <Select
                        isSearchable={false}
                        styles={selectStylesDouble}
                        menuPlacement="top"
                        name="conyuge.direccion.tiempoViviendaAnios"
                        value={{
                          label:
                            formik.values?.conyuge?.direccion
                              ?.tiempoViviendaAnios,
                          value:
                            formik.values?.conyuge?.direccion
                              ?.tiempoViviendaAnios,
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "conyuge.direccion.tiempoViviendaAnios",
                            e.value
                          );

                          let newErrors = formik.errors;
                          if (
                            newErrors?.conyuge !== undefined &&
                            newErrors?.conyuge?.direccion !== undefined &&
                            newErrors?.conyuge?.direccion?.tiempoViviendaAnios
                          ) {
                            delete newErrors?.conyuge?.direccion
                              ?.tiempoViviendaAnios;
                            formik.setErrors(newErrors);
                          }
                        }}
                        placeholder="Años *"
                        options={aniosDomicilio?.map((item, index) => {
                          return {
                            ...item,
                            label: index,
                            value: index,
                          };
                        })}
                        showErrorMessage={
                          formik.errors?.conyuge?.direccion?.tiempoViviendaMeses
                        }
                        errorMessage={
                          formik.errors?.conyuge?.direccion?.tiempoViviendaMeses
                        }
                        error={
                          formik.errors?.conyuge?.direccion?.tiempoViviendaMeses
                        }
                      />
                    </div>
                    <div
                      style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                      className="selectInputCont"
                    >
                      {formik.values?.conyuge?.direccion
                        ?.tiempoViviendaMeses ? (
                        <span className="select-label-no-null">Meses *</span>
                      ) : (
                        <span className="select-label">Meses *</span>
                      )}
                      <Select
                        isSearchable={false}
                        styles={selectStylesDouble}
                        menuPlacement="top"
                        name="conyuge.direccion.tiempoViviendaMeses"
                        value={{
                          label:
                            formik.values?.conyuge?.direccion
                              ?.tiempoViviendaMeses,
                          value:
                            formik.values?.conyuge?.direccion
                              ?.tiempoViviendaMeses,
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "conyuge.direccion.tiempoViviendaMeses",
                            e.value
                          );

                          let newErrors = formik.errors;
                          if (
                            newErrors?.conyuge !== undefined &&
                            newErrors?.conyuge?.direccion !== undefined &&
                            newErrors?.conyuge?.direccion?.tiempoViviendaMeses
                          ) {
                            delete newErrors?.conyuge?.direccion
                              ?.tiempoViviendaMeses;
                            formik.setErrors(newErrors);
                          }
                        }}
                        placeholder="Meses *"
                        options={mesesDomicilo?.map((item, index) => {
                          return {
                            ...item,
                            label: index,
                            value: index,
                          };
                        })}
                        error={
                          formik.errors?.conyuge?.direccion?.tiempoViviendaMeses
                        }
                      />
                    </div>
                  </div>
                  {formik.errors?.conyuge?.direccion?.tiempoViviendaMeses ? (
                    <ErrorMessage
                      message={
                        formik.errors?.conyuge?.direccion?.tiempoViviendaMeses
                      }
                      width={55}
                    />
                  ) : null}
                </div>
                <div
                  style={{
                    width: innerWidth < 700 ? "100%" : "55%",
                    minWidth: "290px",
                    maxWidth: "425px",
                  }}
                >
                  <label>Antigüedad en el domicilio anterior *</label>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "10px",
                      flexDirection: innerWidth < 700 ? "column" : "row",
                    }}
                  >
                    <div
                      style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                      className="selectInputCont"
                    >
                      {formik.values?.conyuge?.direccion
                        ?.tiempoViviendaAntAnios ? (
                        <span className="select-label-no-null">Años *</span>
                      ) : (
                        <span className="select-label">Años *</span>
                      )}
                      <Select
                        isSearchable={false}
                        styles={selectStylesDouble}
                        menuPlacement="top"
                        name="conyuge.direccion.tiempoViviendaAntAnios"
                        value={{
                          label:
                            formik.values?.conyuge?.direccion
                              ?.tiempoViviendaAntAnios,
                          value:
                            formik.values?.conyuge?.direccion
                              ?.tiempoViviendaAntAnios,
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "conyuge.direccion.tiempoViviendaAntAnios",
                            e.value
                          );

                          let newErrors = formik.errors;
                          if (
                            newErrors?.conyuge !== undefined &&
                            newErrors?.conyuge?.direccion !== undefined &&
                            newErrors?.conyuge?.direccion
                              ?.tiempoViviendaAntAnios
                          ) {
                            delete newErrors?.conyuge?.direccion
                              ?.tiempoViviendaAntAnios;
                            formik.setErrors(newErrors);
                          }
                        }}
                        placeholder="Años *"
                        options={aniosDomicilio?.map((item, index) => {
                          return {
                            ...item,
                            label: index,
                            value: index,
                          };
                        })}
                        showErrorMessage={
                          formik.errors?.conyuge?.direccion
                            ?.tiempoViviendaAntMeses
                        }
                        errorMessage={
                          formik.errors?.conyuge?.direccion
                            ?.tiempoViviendaAntMeses
                        }
                        error={
                          formik.errors?.conyuge?.direccion
                            ?.tiempoViviendaAntMeses
                        }
                      />
                    </div>
                    <div
                      style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                      className="selectInputCont"
                    >
                      {formik.values?.conyuge?.direccion
                        ?.tiempoViviendaAntMeses ? (
                        <span className="select-label-no-null">Meses *</span>
                      ) : (
                        <span className="select-label">Meses *</span>
                      )}
                      <Select
                        isSearchable={false}
                        styles={selectStylesDouble}
                        menuPlacement="top"
                        name="conyuge.direccion.tiempoViviendaAntMeses"
                        value={{
                          label:
                            formik.values?.conyuge?.direccion
                              ?.tiempoViviendaAntMeses,
                          value:
                            formik.values?.conyuge?.direccion
                              ?.tiempoViviendaAntMeses,
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "conyuge.direccion.tiempoViviendaAntMeses",
                            e.value
                          );

                          let newErrors = formik.errors;
                          if (
                            newErrors?.conyuge !== undefined &&
                            newErrors?.conyuge?.direccion !== undefined &&
                            newErrors?.conyuge?.direccion
                              ?.tiempoViviendaAntMeses
                          ) {
                            delete newErrors?.conyuge?.direccion
                              ?.tiempoViviendaAntMeses;
                            formik.setErrors(newErrors);
                          }
                        }}
                        placeholder="Meses *"
                        options={mesesDomicilo?.map((item, index) => {
                          return {
                            ...item,
                            label: index,
                            value: index,
                          };
                        })}
                        error={
                          formik.errors?.conyuge?.direccion
                            ?.tiempoViviendaAntMeses
                        }
                      />
                    </div>
                  </div>
                  {formik?.errors?.conyuge?.direccion
                    ?.tiempoViviendaAntMeses ? (
                    <div>
                      <span className="text__center text_red">
                        {
                          formik.errors?.conyuge?.direccion
                            ?.tiempoViviendaAntMeses
                        }
                      </span>
                    </div>
                  ) : null}
                </div>
                {idTipoPersonaSolicitanteConyuge === ID_PFAE && (
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3">
                      Información del negocio
                    </h2>
                    <SelectWithError
                      showErrorMessage={
                        formik.errors?.conyuge?.datosDelNegocio
                          ?.importaExportaId
                      }
                      errorMessage={
                        formik.errors?.conyuge?.datosDelNegocio
                          ?.importaExportaId
                      }
                      name="conyuge.datosDelNegocio.importaExportaId"
                      value={{
                        label:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.importaExportaObj?.[0]?.nombre,
                        value:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.importaExportaObj?.[0]?.nombre,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.conyuge !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio?.importaExportaId
                        ) {
                          delete newErrors?.conyuge?.datosDelNegocio
                            ?.importaExportaId;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.importaExportaId",
                          e.id
                        );
                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.importaExportaObj",
                          [e]
                        );
                      }}
                      label="¿Realizas actividades de importación o exportación? *"
                      options={axiosData?.domicilioFiscal?.importaExportaOpciones?.map(
                        (item) => {
                          return {
                            ...item,
                            label: item.nombre,
                            value: item.nombre,
                          };
                        }
                      )}
                    />
                    <SelectWithErrorTooltipCompuesto
                      showErrorMessage={
                        formik.errors?.conyuge?.datosDelNegocio
                          ?.giroEmpresarialId
                      }
                      errorMessage={
                        formik.errors?.conyuge?.datosDelNegocio
                          ?.giroEmpresarialId
                      }
                      name="conyuge.datosDelNegocio.giroEmpresarialId"
                      value={{
                        label:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.giroEmpresarialObj?.[0]?.nombre,
                        value:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.giroEmpresarialObj?.[0]?.nombre,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.conyuge !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio?.giroEmpresarialId
                        ) {
                          delete newErrors?.conyuge?.datosDelNegocio
                            ?.giroEmpresarialId;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.giroEmpresarialId",
                          e.id
                        );
                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.giroEmpresarialObj",
                          [e]
                        );
                      }}
                      label="Giro *"
                      options={axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.map(
                        (item) => {
                          return {
                            ...item,
                            label: item.nombre,
                            value: item.nombre,
                          };
                        }
                      )}
                      tooltipA="Comercio: Supermercados, tiendas, farmacias, ferreterías, etc."
                      tooltipB="Industria: Manufacturera, constructoras, textil, alimenticia, etc."
                      tooltipC="Servicios: Escuelas, hospitales, despachos, transporte, médicos, dentista, restaurantes, etc."
                    />
                    <SelectWithError
                      showErrorMessage={
                        formik.errors?.conyuge?.datosDelNegocio?.sectorId
                      }
                      errorMessage={
                        formik.errors?.conyuge?.datosDelNegocio?.sectorId
                      }
                      name="conyuge.datosDelNegocio.sectorId"
                      value={{
                        label:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.sectorObj?.[0]?.nombre,
                        value:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.sectorObj?.[0]?.nombre,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.conyuge !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio?.sectorId
                        ) {
                          delete newErrors?.conyuge?.datosDelNegocio?.sectorId;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.sectorId",
                          e.id
                        );
                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.sectorObj",
                          [e]
                        );
                      }}
                      label="Sector *"
                      options={axiosData?.domicilioFiscal?.sectorOpciones?.map(
                        (item) => {
                          return {
                            ...item,
                            label: item.nombre,
                            value: item.nombre,
                          };
                        }
                      )}
                    />
                    <SelectWithErrorActividad
                      showErrorMessage={
                        formik.errors?.conyuge?.datosDelNegocio?.actividadId
                      }
                      errorMessage={
                        formik.errors?.conyuge?.datosDelNegocio?.actividadId
                      }
                      menuPlacement="top"
                      label="Actividad *"
                      name="conyuge.datosDelNegocio.actividadId"
                      value={{
                        label:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.actividadObj?.[0]?.detalleClave,
                        value:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.actividadObj?.[0]?.detalleClave,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.conyuge !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio?.actividadId
                        ) {
                          delete newErrors?.conyuge?.datosDelNegocio
                            ?.actividadId;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.actividadId",
                          e.id
                        );
                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.actividadObj",
                          [e]
                        );
                      }}
                      options={axiosData?.domicilioFiscal?.actividadOpciones?.map(
                        (item) => {
                          return {
                            ...item,
                            label: item.detalleClave,
                            value: item.detalleClave,
                          };
                        }
                      )}
                      tooltip="Selecciona la actividad que mas se asemeje a tu alta en hacienda. En caso de que tengas varias actividades, selecciona la preponderante."
                    />
                    <InputWithError
                      label="Fecha de alta en SHCP *"
                      InputProps={{ shrink: true, max: fechaActualYYYMMDD() }}
                      type="date"
                      name="conyuge.datosDelNegocio.fechaAltaShcp"
                      value={startDateFechaSHCP}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.conyuge !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio?.fechaAltaShcp
                        ) {
                          delete newErrors?.conyuge?.datosDelNegocio
                            ?.fechaAltaShcp;
                          formik.setErrors(newErrors);
                        }

                        let array = e.target.value
                          .split("-")
                          .reverse()
                          .join("-");
                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.fechaAltaShcp",
                          array
                        );
                        setStartDateFechaSHCP(e.target.value);
                      }}
                      showErrorMessage={
                        formik.errors?.conyuge?.datosDelNegocio?.fechaAltaShcp
                      }
                      errorMessage={
                        formik.errors?.conyuge?.datosDelNegocio?.fechaAltaShcp
                      }
                    />
                    <InputWithError
                      label="Número de sucursales *"
                      name="conyuge.datosDelNegocio.numeroSucursales"
                      value={
                        formik.values?.conyuge?.datosDelNegocio
                          ?.numeroSucursales
                      }
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.conyuge !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio?.numeroSucursales
                        ) {
                          delete newErrors?.conyuge?.datosDelNegocio
                            ?.numeroSucursales;
                          formik.setErrors(newErrors);
                        }

                        if (validateValue(e.target.value, "number")) {
                          formik.setFieldValue(
                            "conyuge.datosDelNegocio.numeroSucursales",
                            e.target.value
                          );
                        }
                      }}
                      showErrorMessage={
                        formik.errors?.conyuge?.datosDelNegocio
                          ?.numeroSucursales
                      }
                      errorMessage={
                        formik.errors?.conyuge?.datosDelNegocio
                          ?.numeroSucursales
                      }
                    />
                    <SelectWithError
                      showErrorMessage={
                        formik.errors?.conyuge?.datosDelNegocio
                          ?.aniosExperienciaSector
                      }
                      errorMessage={
                        formik.errors?.conyuge?.datosDelNegocio
                          ?.aniosExperienciaSector
                      }
                      label="Años de experiencia en el sector *"
                      menuPlacement="top"
                      name="conyuge.datosDelNegocio.aniosExperienciaSector"
                      value={{
                        label:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.aniosExperienciaSector,
                        value:
                          formik?.values?.conyuge?.datosDelNegocio
                            ?.aniosExperienciaSector,
                      }}
                      onChange={(e) => {
                        let newErrors = formik.errors;
                        if (
                          newErrors?.conyuge !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio !== undefined &&
                          newErrors?.conyuge?.datosDelNegocio
                            ?.aniosExperienciaSector
                        ) {
                          delete newErrors?.conyuge?.datosDelNegocio
                            ?.aniosExperienciaSector;
                          formik.setErrors(newErrors);
                        }

                        formik.setFieldValue(
                          "conyuge.datosDelNegocio.aniosExperienciaSector",
                          e.value
                        );
                      }}
                      options={anios?.map((item, index) => {
                        return {
                          ...item,
                          label: index + 1,
                          value: index + 1,
                        };
                      })}
                    />
                  </div>
                )}
              </>
            )}
            <div className="button-siguiente my-3">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(SECCION_SOLICITANTE_CONYUGE);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit">Siguiente</PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default DatosSolicitanteConyuge;

function initialValues(modeloPfae, idTipoPersona, esObligadoSolidario) {
  if (
    modeloPfae?.idCliente != null &&
    modeloPfae?.conyuge?.idConyuge !== null
  ) {
    return {
      idCliente: modeloPfae.idCliente,
      conyuge: modeloPfae.conyuge,
    };
  } else {
    return {
      idCliente: modeloPfae.idCliente,
      conyuge: {
        generales: {
          tipoPersonaId: idTipoPersona,
          esObligadoSolidario: esObligadoSolidario,
          relacionRamaGobierno: false,
          paisResidenciaFiscal2: false,
          residenciaUsa: false,
          esAccionistaOtraEmpresa: false,
          esObligadoSolidarioOtraEmpresaPfae: false,
          excepcionesRfc: EXCEPCIONES_RFC,
          curp: "",
          rfc: "",
          folioVerificacionId: "",
          nacionalidadId: "",
          calidadMigratoria: "",
          estadoCivilId: "",
          nivelEducativoId: "",
          profesion: "",
          correoElectronico: "",
          telefono: "",
          celular: "",
          dependientesEconomicosMenores23: "",
          dependientesEconomicos: "",
          numeroIdFiscal2: "",
          paisResidenciaFiscal2Id: "",
          nombreOtraEmpresa: "",
          nombreOtraEmpresaPfae: "",
        },
        direccion: {
          ineCertificada: true,
          aplicaIneComprobante: true,
          calle: "",
          numeroExterior: "",
          numeroInterior: "",
          piso: "",
          paisId: ID_PAIS_MEXICO,
          codigoPostal: "",
          colonia: "",
          tipoDomicilioId: "",
          tipoViviendaId: "",
          tiempoViviendaAnios: "",
          tiempoViviendaMeses: "",
          tiempoViviendaAntAnios: "",
          tiempoViviendaAntMeses: "",
        },
        datosDelNegocio: {
          importaExportaId: "",
          sectorId: "",
          giroEmpresarialId: "",
          actividadId: "",
          aniosExperienciaSector: "",
          numeroSucursales: "",
          fechaAltaShcp: "",
        },
      },
    };
  }
}

function validationSchema(
  esObligadoSolidario,
  idTipoPersonaConyuge,
  totalDependientesMenores23,
  totalDependientes
) {
  if (esObligadoSolidario === true) {
    if (idTipoPersonaConyuge === ID_PFAE) {
      return {
        conyuge: Yup.object().shape({
          generales: Yup.object().shape({
            nombre: Yup.string()
              .required("Dato requerido.")
              .matches(/^[A-Za-zÑñ]*$/, "Solo se permiten letras "),
            apellidoPaterno: Yup.string()
              .required("Dato requerido.")
              .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
            apellidoMaterno: Yup.string().matches(
              /^[aA-zZÑñ\s]+$/,
              "Solo se permiten letras "
            ),
            fechaNacimiento: Yup.string()
              .required("Dato requerido.")
              .test(
                "fechaNacimiento",
                "Debe ser mayor a 18 años y menor a 70 años.",
                (item, testContext) => {
                  if (testContext.parent.fechaNacimiento !== undefined) {
                    return compareDates(
                      testContext.parent.fechaNacimiento
                        .split("-")
                        .reverse()
                        .join("-")
                    );
                  }
                }
              ),
            paisNacimientoId: Yup.number().required("Dato requerido."),
            estadoNacimientoId: Yup.string().when("paisNacimientoId", {
              is: (value) => value === ID_PAIS_MEXICO,
              then: Yup.string().required("Dato requerido."),
            }),
            generoId: Yup.number().required("Dato requerido."),
            rfc: Yup.string()
              .required("Dato requerido.")
              .test("rfc", "RFC inválido.", (item, testContext) => {
                return testContext.parent.rfc !== undefined
                  ? testContext.parent.rfc.trim().length > 0
                    ? validarRFCTipoPersona(
                        testContext.parent.rfc.trim(),
                        ID_PFAE
                      )
                    : false
                  : false;
              }),
            curp: Yup.string().required("Dato requerido."),
            folioVerificacionId: Yup.string().required("Dato requerido."),
            nacionalidadId: Yup.number().required("Dato requerido."),
            calidadMigratoria: Yup.string().when("nacionalidadId", {
              is: (value) => value !== NACIONALIDAD_MEXICAN0,
              then: Yup.string().required("Dato requerido."),
            }),
            estadoCivilId: Yup.number().required("Dato requerido."),
            nivelEducativoId: Yup.number().required("Dato requerido."),
            profesion: Yup.string().required("Dato requerido."),
            correoElectronico: Yup.string()
              .email("Correo eléctronico inválido")
              .required("Dato requerido."),
            telefono: Yup.string()
              .required("Dato requerido.")
              .test(
                "telefono",
                "Teléfono capturado de forma incorrecta.",
                (item, testContext) => {
                  return validarNumeroTelefono(testContext.parent.telefono);
                }
              ),
            celular: Yup.string()
              .required("Dato requerido.")
              .test(
                "celular",
                "Celular capturado de forma incorrecta.",
                (item, testContext) => {
                  return validarNumeroTelefono(testContext.parent.celular);
                }
              ),
            dependientesEconomicosMenores23:
              Yup.number().required("Dato requerido."),
            dependientesEconomicos: Yup.number()
              .required("Dato requerido.")
              .test(
                "dependientesEconomicos",
                "Debe ser Mayor o igual al número de dependientes menores de 23 años.",
                (item, testContext) => {
                  return dependeintesMajor23(
                    totalDependientesMenores23,
                    totalDependientes
                  );
                }
              ),
            nombreOtraEmpresa: Yup.string().when("esAccionistaOtraEmpresa", {
              is: true,
              then: Yup.string().required("Este campo es obligatorio."),
            }),
            nombreOtraEmpresaPfae: Yup.string().when(
              "esObligadoSolidarioOtraEmpresaPfae",
              {
                is: true,
                then: Yup.string().required("Este campo es obligatorio."),
              }
            ),
          }),
          direccion: Yup.object().shape({
            calle: Yup.string().required("Dato requerido."),
            numeroExterior: Yup.string().required("Dato requerido."),
            numeroInterior: Yup.string().when("tipoViviendaId", {
              is: TIPO_VIVIENDA_EDIFICIO,
              then: Yup.string().required("Dato requerido."),
              otherwise: Yup.string().when("tipoViviendaId", {
                is: TIPO_DEPARTAMENTO_APARTAMENTO,
                then: Yup.string().required("Dato requerido."),
              }),
            }),
            piso: Yup.string().when("tipoViviendaId", {
              is: TIPO_VIVIENDA_EDIFICIO,
              then: Yup.string().required("Dato requerido."),
              otherwise: Yup.string().when("tipoViviendaId", {
                is: TIPO_DEPARTAMENTO_APARTAMENTO,
                then: Yup.string().required("Dato requerido."),
              }),
            }),
            paisId: Yup.string().required("Dato requerido."),
            codigoPostal: Yup.string().required("Dato requerido."),
            colonia: Yup.string().required("Dato requerido."),
            municipioId: Yup.string().required("Dato requerido."),
            estadoId: Yup.string().required("Dato requerido."),
            tipoDomicilioId: Yup.string().required("Dato requerido."),
            tipoViviendaId: Yup.string().required("Dato requerido."),
            tiempoViviendaAnios: Yup.number().required("Dato requerido."),
            tiempoViviendaMeses: Yup.number()
              .required("Dato requerido.")
              .test(
                "tiempoViviendaMeses",
                "Antigüedad en el Domicilio Actual, debe ser mayor a cero.",
                (item, testContext) => {
                  return tiempoViviendaActual(
                    testContext.parent.tiempoViviendaMeses,
                    testContext.parent.tiempoViviendaAnios
                  );
                }
              ),
            tiempoViviendaAntAnios: Yup.number().required("Dato requerido."),
            tiempoViviendaAntMeses: Yup.number()
              .required("Dato requerido.")
              .test(
                "tiempoViviendaAntMeses",
                "Dato requerido.",
                (item, testContext) =>
                  tiempoViviendaAnterior(
                    testContext.parent.tiempoViviendaAntMeses,
                    testContext.parent.tiempoViviendaAntAnios
                  )
              ),
          }),
        }),
      };
    } else {
      return {
        conyuge: Yup.object().shape({
          generales: Yup.object().shape({
            nombre: Yup.string()
              .required("Dato requerido.")
              .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
            apellidoPaterno: Yup.string()
              .required("Dato requerido.")
              .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
            apellidoMaterno: Yup.string().matches(
              /^[aA-zZÑñ\s]+$/,
              "Solo se permiten letras "
            ),
            fechaNacimiento: Yup.string()
              .required("Dato requerido.")
              .test(
                "fechaNacimiento",
                "Debe ser mayor a 18 años y menor a 70 años.",
                (value) =>
                  compareDatesOrigin(
                    fechaMaxMayorEdadYYYMMDD(),
                    fechaMinEdadYYYMMDD(),
                    value
                  )
              ),
            paisNacimientoId: Yup.number().required("Dato requerido."),
            estadoNacimientoId: Yup.string().when("paisNacimientoId", {
              is: (value) => value === ID_PAIS_MEXICO,
              then: Yup.string().required("Dato requerido."),
            }),
            generoId: Yup.number().required("Dato requerido."),
            rfc: Yup.string()
              .required("Dato requerido.")
              .test("rfc", "RFC inválido.", (item, testContext) => {
                return testContext.parent.rfc !== undefined
                  ? testContext.parent.rfc.trim().length > 0
                    ? validarRFCTipoPersona(
                        testContext.parent.rfc.trim(),
                        ID_PF
                      )
                    : false
                  : false;
              }),
            curp: Yup.string().required("Dato requerido."),
            folioVerificacionId: Yup.string().required("Dato requerido."),
            nacionalidadId: Yup.number().required("Dato requerido."),
            calidadMigratoria: Yup.string().when("nacionalidadId", {
              is: (value) => value !== NACIONALIDAD_MEXICAN0,
              then: Yup.string().required("Dato requerido."),
            }),
            estadoCivilId: Yup.number().required("Dato requerido."),
            nivelEducativoId: Yup.number().required("Dato requerido."),
            profesion: Yup.string().required("Dato requerido."),
            correoElectronico: Yup.string()
              .email("Correo eléctronico inválido")
              .required("Dato requerido."),
            telefono: Yup.string()
              .required("Dato requerido.")
              .test(
                "telefono",
                "Teléfono capturado de forma incorrecta.",
                (item, testContext) => {
                  return validarNumeroTelefono(testContext.parent.telefono);
                }
              ),
            celular: Yup.string()
              .required("Dato requerido.")
              .test(
                "celular",
                "Celular capturado de forma incorrecta.",
                (item, testContext) => {
                  return validarNumeroTelefono(testContext.parent.celular);
                }
              ),
            dependientesEconomicosMenores23:
              Yup.number().required("Dato requerido."),
            dependientesEconomicos: Yup.number()
              .required("Dato requerido.")
              .test(
                "dependientesEconomicos",
                "Debe ser Mayor o igual al número de dependientes menores de 23 años.",
                (item, testContext) => {
                  return dependeintesMajor23(
                    totalDependientesMenores23,
                    totalDependientes
                  );
                }
              ),
            nombreOtraEmpresa: Yup.string().when("esAccionistaOtraEmpresa", {
              is: true,
              then: Yup.string().required("Este campo es obligatorio."),
            }),
            nombreOtraEmpresaPfae: Yup.string().when(
              "esObligadoSolidarioOtraEmpresaPfae",
              {
                is: true,
                then: Yup.string().required("Este campo es obligatorio."),
              }
            ),
          }),
          direccion: Yup.object().shape({
            calle: Yup.string().required("Dato requerido."),
            numeroExterior: Yup.string().required("Dato requerido."),
            numeroInterior: Yup.string().when("tipoViviendaId", {
              is: TIPO_VIVIENDA_EDIFICIO,
              then: Yup.string().required("Dato requerido."),
              otherwise: Yup.string().when("tipoViviendaId", {
                is: TIPO_DEPARTAMENTO_APARTAMENTO,
                then: Yup.string().required("Dato requerido."),
              }),
            }),
            piso: Yup.string().when("tipoViviendaId", {
              is: TIPO_VIVIENDA_EDIFICIO,
              then: Yup.string().required("Dato requerido."),
              otherwise: Yup.string().when("tipoViviendaId", {
                is: TIPO_DEPARTAMENTO_APARTAMENTO,
                then: Yup.string().required("Dato requerido."),
              }),
            }),
            paisId: Yup.string().required("Dato requerido."),
            codigoPostal: Yup.string().required("Dato requerido."),
            colonia: Yup.string().required("Dato requerido."),
            municipioId: Yup.string().required("Dato requerido."),
            estadoId: Yup.string().required("Dato requerido."),
            tipoDomicilioId: Yup.string().required("Dato requerido."),
            tipoViviendaId: Yup.string().required("Dato requerido."),
            tiempoViviendaAnios: Yup.number().required("Dato requerido."),
            tiempoViviendaMeses: Yup.number()
              .required("Dato requerido.")
              .test(
                "tiempoViviendaMeses",
                "Antigüedad en el Domicilio Actual, debe ser mayor a cero.",
                (item, testContext) => {
                  return tiempoViviendaActual(
                    testContext.parent.tiempoViviendaMeses,
                    testContext.parent.tiempoViviendaAnios
                  );
                }
              ),
            tiempoViviendaAntAnios: Yup.number().required("Dato requerido."),
            tiempoViviendaAntMeses: Yup.number()
              .required("Dato requerido.")
              .test(
                "tiempoViviendaAntMeses",
                "Dato requerido.",
                (item, testContext) => {
                  return tiempoViviendaAnterior(
                    testContext.parent.tiempoViviendaAntMeses,
                    testContext.parent.tiempoViviendaAntAnios
                  );
                }
              ),
          }),
          datosDelNegocio: Yup.object().shape({
            importaExportaId: Yup.number(),
            sectorId: Yup.string(),
            giroEmpresarialId: Yup.string(),
            actividadId: Yup.string(),
            aniosExperienciaSector: Yup.string(),
            numeroSucursales: Yup.number(),
            fechaAltaShcp: Yup.string(),
          }),
        }),
      };
    }
  } else {
    return {
      conyuge: Yup.object().shape({
        generales: Yup.object().shape({
          nombre: Yup.string()
            .required("Dato requerido.")
            .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
          apellidoPaterno: Yup.string()
            .required("Dato requerido.")
            .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
          apellidoMaterno: Yup.string().matches(
            /^[aA-zZÑñ\s]+$/,
            "Solo se permiten letras "
          ),
          fechaNacimiento: Yup.string()
            .required("Dato requerido.")
            .test(
              "fechaNacimiento",
              "Debe ser mayor a 18 años y menor a 70 años.",
              (value) =>
                compareDatesOrigin(
                  fechaMaxMayorEdadYYYMMDD(),
                  fechaMinEdadYYYMMDD(),
                  value
                )
            ),
          paisNacimientoId: Yup.number().required("Dato requerido."),
          estadoNacimientoId: Yup.string().when("paisNacimientoId", {
            is: (value) => value === ID_PAIS_MEXICO,
            then: Yup.string().required("Dato requerido."),
          }),
          generoId: Yup.number().required("Dato requerido."),
          rfc: Yup.string()
            .required("Dato requerido.")
            .test("rfc", "RFC inválido.", (item, testContext) => {
              return testContext.parent.rfc !== undefined
                ? testContext.parent.rfc.trim().length > 0
                  ? validarRFCTipoPersona(testContext.parent.rfc.trim(), ID_PF)
                  : false
                : false;
            }),
          curp: Yup.string().required("Dato requerido."),
          nacionalidadId: Yup.number().required("Dato requerido."),
        }),
      }),
    };
  }
}
