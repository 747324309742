import React, { useEffect, useRef, useState, Fragment } from "react";
import { animated, useTransition } from "react-spring";
import FormularioPersonaMoral from "./components/PM/FormularioPersonaMoral";
import FormularioPFAE from "./components/PFAE/FormularioPFAE";
import ResumenInformacionCIEC from "./components/ResumenInformacionCIEC/ResumenInformacionCIEC.js";
import {
  api_obtener_detalle_cliente,
  api_load_tablaAmortizacion,
  api_obtener_detalle_solicitud_crediactivo,
  api_eliminar_os_solicitud_pm,
  api_eliminar_os_solicitud_pfae,
  api_avanzar_historial_crediticio,
  api_catalogos_personales,
  api_limites_monto_credito,
  api_anios_negocio,
} from "../../api/api_servicios_rest_crediactivo";
import {
  ID_PFAE,
  ID_PM,
  OS_CERO,
  OS_UNO,
  OS_DOS,
  OS_TRES,
  OS_CUATRO,
  OS_CINCO,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  PESTANIA_SOLICITANTE_CONYUGE,
  SECCION_ZERO,
  SECCION_GENERALES,
  SECCION_DOMICILIO,
  SECCION_DOMICILIO_FISCAL,
  SECCION_DOMICILIO_COMERCIAL,
  SECCION_ECONOMICOS,
  SECCION_PRINCIPAL_ACCIONISTA,
  SECCION_SOLICITANTE_CONYUGE,
  SECCION_PATRIMONIO,
  SECCION_HISTORIAL_CREDITICIO,
  SECCION_CARGA_DOCUMENTOS,
  SECCION_REPRESENTANTE_LEGAL,
  SECCION_DOMICILIO_NEGOCIO,
  SECCION_DOMICILIO_COMERCIAL_PM,
  SECCION_INFORMACION_NEGOCIO,
  SECCION_SOCIOS,
  SECCION_PA_CONYUGE_PM,
  SECCION_OBLIGADO_SOLIDARIO,
  SECCION_OS_DOS,
  SECCION_OS_TRES,
  SECCION_OS_CUATRO,
  SECCION_OS_CINCO,
  SECCION_CONYUGE_OS_UNO,
  SECCION_CONYUGE_OS_DOS,
  SECCION_CONYUGE_OS_TRES,
  SECCION_CONYUGE_OS_CUATRO,
  SUB_MENU_CADENA_OS,
  SUB_MENU_CADENA_GRALES,
  SUB_MENU_CADENA_HISTORIAL,
  SUB_MENU_CADENA_CARGA_DOCUMENTOS,
  TIPO_PARTICIPANTE_OS,
  OS_MAX_PFAE,
  OS_MAX_PM,
  PASO_SOLICITUD_HISTORIAL_CREDITICIO,
  PASO_SOLICITUD_EVALUACION,
  PASO_SOLICITUD_RECABAR_DOCUMENTOS,
  EVALUACION_APROBADO,
  SOLICITUD_PRE_APROBADA,
  SOLICITUD_NO_AUTORIZADA,
} from "../../utils/constantes";
import Swal from "sweetalert2";
import Popup from "../tools/Popup";
import tarjeta from "../../img/tarjeta.png";
import {
  sessionStorageAutoServicio,
  sessionStorageDatosOperacion,
  sessionStorageIdSolicitud,
  sessionStorageContinuarSolicitud,
  sessionTasaAnualAts,
} from "../../config/Variables";
import StepProgressBar from "../helpers/ProgressBar/ProgressBar";
import "./Formulario.css";
import FormMenu from "./components/FormMenu/FormMenu";
import { PrimaryButton } from "../basics/Buttons/Buttons";
import IconClose from "./../../icons/crossIcon.png";
import {
  sweetAlertConfirmed,
  sweetAlertError,
  sweetAlertObligadoSolidario,
  sweetAlertSuccess,
  sweetAlertWarning,
  sweetAlertWithConfirmedCrediactivo,
} from "./components/alertas/alertas";
import { obtenerDatosOblidadoSolidario } from "./formularioHelper";

const Formulario = () => {
  const [cantidadObligadosSolidarios, setCantidadObligadosSolidarios] =
    useState(0);
  const [cantidadConyugeOS, setCantidadConyugeOS] = useState(0);
  const [, setStatePAConyuge] = useState(false);
  const [regresarCotizador, setRegresarCotizador] = useState(false);
  const [formSection, setFormSection] = useState("Generales");
  const [pago, setPago] = useState("0.0");

  // MENU
  const [subMenuSelected, setSubMenuSelected] = useState("generales");

  // tabla amortizacion
  const [plazo, setPlazo] = useState();
  const [showTabla, setShowTabla] = useState(false);
  const [tablaData, setTablaData] = useState([]);

  //Tabla simulador
  const [showBotonActualizar, setShowBotonActualizar] = useState(false);

  // POPUP
  const [showResult, setShowResult] = useState(false);
  const [showTarjeta, setShowTarjeta] = useState(false);
  const [loading, setLoading] = useState(false);

  // AXIOS DATA
  const [axiosData, setAxiosData] = useState();
  const [aniosSector, setAniosSector] = useState();

  //VARIABLES GLOBALES
  const [tipoPersona, setTipoPersona] = useState("");
  const [idTipoPersona, setIdTipoPersona] = useState();
  const [folioSolicitud, setFolioSolicitud] = useState();
  const [ultimoPasoSolicitud, setUltimoPasoSolicitud] = useState(1);
  const [, setAvanzarSolicitud] = useState(false);
  const [, setObligadosSolidariosInformacionBasica] = useState([]);
  const [domicilioFiscalIgual, setDomicilioFiscalIgual] = useState(false);
  const [, esObligadoSolidario] = useState("");
  const aniosDomicilio = Array.apply(null, Array(81));
  const mesesDomicilo = Array.apply(null, Array(12));
  const anios = Array.apply(null, Array(99));

  const [, setStyleHr] = useState("none");
  const [ultimoPasoUsuario, setUltimoPasoUsuario] = useState(1);

  const [conyugeOS, setConyugeOS] = useState(false);
  const [disabledBtnAgregarOs, setDisabledBtnAgregarOs] = useState(false);
  const [actualizarPA, setActualizarPA] = useState(false);

  const [mostrarSeccionPfae, setMostrarSeccionPfae] = useState({
    general: false,
    domicilio: false,
    domicilioFiscal: false,
    domicilioComercial: false,
    economicos: false,
    obligadosSolidario: false,
    historialCrediticio: false,
    solicitanteConyuge: false,
  });

  const [mostrarSeccionPm, setMostrarSeccionPm] = useState({
    general: false,
    representanteLegal: false,
    domicilioNegocio: false,
    domicilioComercial: false,
    informacionNegocio: false,
    economicos: false,
    socios: false,
    principalAccionista: false,
    principalAccionistaConyuge: false,
    obligadosSolidario: false,
    historialCrediticio: false,
  });

  const [resultadoEvaluacionRiesgos, setResultadoEvaluacionRiesgos] = useState({
    evaluacionRiesgos: true,
    folioSolicitud: "",
    mensajeEvaluacion: "",
    domicilioComercial: "",
    tasa: "",
    valorTasa: "",
  });

  const [datosOSUno, setDatosOSUno] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
    showConyugeOS: false,
  });

  const [datosOSDos, setDatosOSDos] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
    showConyugeOS: false,
  });

  const [datosOSTres, setDatosOSTres] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
    showConyugeOS: false,
  });

  const [datosOSCuatro, setDatosOSCuatro] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
    showConyugeOS: false,
  });

  const [datosOSCinco, setDatosOSCinco] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
  });

  const [modeloPfae, setModeloPfae] = useState({});

  const [modeloPm, setModeloPm] = useState({});

  useEffect(() => {
    const obtenerLimitesMonto = async () => {
      const response = await api_limites_monto_credito();
      sessionStorage.setItem(sessionTasaAnualAts, response?.data?.tasaAnual);
    };

    obtenerLimitesMonto();
  }, []);

  // REQUEST
  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        if (axiosData === undefined) {
          setLoading(true);
          const responseCatalogos = await api_catalogos_personales();
          setAxiosData(responseCatalogos.data);

          const responseNegocio = await api_anios_negocio();
          setAniosSector(responseNegocio.data);

          obtenerDatosSolicitud();
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    obtenerDatos();
  }, [axiosData]);

  useEffect(() => {
    if (showTabla) {
      obtenerTablaAmortizacion().then((response) => response);
    }
  }, [showTabla]);

  useEffect(() => {
    setCantidadObligadosSolidarios(cantidadObligadosSolidarios);
  }, [cantidadObligadosSolidarios]);

  const obtenerTablaAmortizacion = async () => {
    const data = {
      plazo: plazo,
      monto: creditoFloat,
    };

    try {
      const response = await api_load_tablaAmortizacion(data);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setTablaData(data.resumenSimulacion);
        } else {
          sweetAlertError("Ocurrió un error al obtener tabla amortización");
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const obtenerDatosSolicitud = async () => {
    let continuarSolicitud = sessionStorage.getItem(
      sessionStorageContinuarSolicitud
    );

    if (continuarSolicitud === "true") {
      let idSolicitud = sessionStorage.getItem(sessionStorageIdSolicitud);
      if (idSolicitud !== undefined && idSolicitud !== null) {
        await obtenerDetalleSolicitud(idSolicitud);
      }
    } else {
      await obtenerDetalleCliente();
    }
    setLoading(false);
  };

  const obtenerDetalleCliente = async () => {
    const data = {
      idDatosTemporalesAutoservicio: sessionStorage.getItem(
        sessionStorageAutoServicio
      ),
      idDatosOperacion: sessionStorage.getItem(sessionStorageDatosOperacion),
    };

    try {
      if (
        data?.idDatosTemporalesAutoservicio !== null &&
        data?.idDatosOperacion !== null
      ) {
        const response = await api_obtener_detalle_cliente(data);
        if (response.statusText === "OK") {
          const data = response.data;
          if (data.success) {
            const datosSolicitud = data.datosCliente;

            setPlazo(datosSolicitud.datosOperacion.plazo);
            setCredito(datosSolicitud.datosOperacion.montoCredito);
            setCreditoFloat(datosSolicitud.datosOperacion.montoCreditoFloat);
            setProductoPyme(datosSolicitud.datosOperacion.tipoDeCreditoNombre);
            setIdTipoPersona(data.idTipoPersona);
            calculoMensualidad(
              datosSolicitud?.datosOperacion?.montoCredito,
              datosSolicitud?.datosOperacion?.plazo
            );
            if (data.idTipoPersona === ID_PFAE) {
              setearDatosSolicitudPFAE(datosSolicitud, 1);
            } else {
              setearDatosSolicitudPM(datosSolicitud, 1);
            }
            handleSeccionPyme(SECCION_ZERO);
          } else {
            sweetAlertError("Ocurrió un error al obtener datos cliente");
          }
        } else {
          return false;
        }
        setLoading(false);
      } else {
        if (data.idDatosOperacion === null) {
          sweetAlertWithConfirmedCrediactivo(
            "No puede acceder a esta página, si no ha seleccionado un plazo y monto de crédito"
          );
        } else {
          sweetAlertWithConfirmedCrediactivo(
            "No puede acceder a esta página, si no ha solicitado su crédito"
          );
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const setearDatosSolicitudPFAE = (datosSolicitud, solicitudUltimoPaso) => {
    if (
      datosSolicitud?.conyuge &&
      datosSolicitud?.conyuge?.idConyuge !== null
    ) {
      if (datosSolicitud?.conyuge?.generales) {
        if (datosSolicitud?.conyuge?.generales?.nivelEducativoId == null) {
          datosSolicitud.conyuge.generales.nivelEducativoId = "";
        }
        if (datosSolicitud?.conyuge?.generales?.estadoCivilId == null) {
          datosSolicitud.conyuge.generales.estadoCivilId = "";
        }
        if (
          datosSolicitud?.conyuge?.generales?.dependientesEconomicos == null
        ) {
          datosSolicitud.conyuge.generales.dependientesEconomicos = "";
        }
        if (
          datosSolicitud?.conyuge?.generales?.dependientesEconomicosMenores23 ==
          null
        ) {
          datosSolicitud.conyuge.generales.dependientesEconomicosMenores23 = "";
        }
      }

      if (datosSolicitud?.conyuge?.direccion) {
        if (datosSolicitud?.conyuge?.direccion?.paisId == null) {
          datosSolicitud.conyuge.direccion.paisId = "";
        }
        if (datosSolicitud?.conyuge?.direccion?.tipoDomicilioId == null) {
          datosSolicitud.conyuge.direccion.tipoDomicilioId = "";
        }
        if (datosSolicitud?.conyuge?.direccion?.tipoViviendaId == null) {
          datosSolicitud.conyuge.direccion.tipoViviendaId = "";
        }
        if (datosSolicitud?.conyuge?.direccion?.tipoViviendaId == null) {
          datosSolicitud.conyuge.direccion.tipoViviendaId = "";
        }
      }

      if (datosSolicitud?.conyuge?.datosDelNegocio) {
        if (
          datosSolicitud?.conyuge?.datosDelNegocio?.importaExportaId == null
        ) {
          datosSolicitud.conyuge.datosDelNegocio.importaExportaId = "";
        }

        if (datosSolicitud?.conyuge?.datosDelNegocio?.fechaAltaShcp == null) {
          datosSolicitud.conyuge.datosDelNegocio.fechaAltaShcp = "";
        }

        if (datosSolicitud?.conyuge?.datosDelNegocio?.sectorId == null) {
          datosSolicitud.conyuge.datosDelNegocio.sectorId = "";
        }

        if (
          datosSolicitud?.conyuge?.datosDelNegocio?.giroEmpresarialId == null
        ) {
          datosSolicitud.conyuge.datosDelNegocio.giroEmpresarialId = "";
        }

        if (datosSolicitud?.conyuge?.datosDelNegocio?.actividadId == null) {
          datosSolicitud.conyuge.datosDelNegocio.actividadId = "";
        }

        if (
          datosSolicitud?.conyuge?.datosDelNegocio?.aniosExperienciaSector ==
          null
        ) {
          datosSolicitud.conyuge.datosDelNegocio.aniosExperienciaSector = "";
        }

        if (
          datosSolicitud?.conyuge?.datosDelNegocio?.numeroSucursales == null
        ) {
          datosSolicitud.conyuge.datosDelNegocio.numeroSucursales = "";
        }
      }
    }

    if (datosSolicitud?.idDatosTempAutoservicio == null) {
      let idDatosTemp = parseInt(
        sessionStorage.getItem(sessionStorageAutoServicio)
      );
      datosSolicitud.idDatosTempAutoservicio = idDatosTemp;
    }

    if (datosSolicitud.idDatosOperacion == null) {
      datosSolicitud.idDatosOperacion =
        datosSolicitud.datosOperacion.idDatosOperacion;
    }

    setModeloPfae(datosSolicitud);

    setTipoPersona("PFAE");
    let cantidadOsSolicitudConyuge = cantidadConyugeOS;
    if (
      datosSolicitud?.conyuge != null &&
      datosSolicitud?.conyuge?.idConyuge != null &&
      datosSolicitud?.conyuge?.generales?.esObligadoSolidario
    ) {
      cantidadOsSolicitudConyuge = cantidadOsSolicitudConyuge + 1;
    }

    if (
      datosSolicitud?.obligadosSolidarios !== undefined &&
      datosSolicitud?.obligadosSolidarios !== null &&
      datosSolicitud?.obligadosSolidarios?.length > 0
    ) {
      setCantidadObligadosSolidarios(
        datosSolicitud?.obligadosSolidarios?.length
      );
      obtenerDatosOblidadoSolidario(
        datosSolicitud?.obligadosSolidarios,
        cantidadOsSolicitudConyuge,
        setDatosOSUno,
        setDatosOSDos,
        setDatosOSTres,
        setDatosOSCuatro,
        setDatosOSCinco,
        setCantidadConyugeOS
      );
    } else {
      setCantidadConyugeOS(cantidadOsSolicitudConyuge);
    }

    verificarSeccionesSolicitud(datosSolicitud, ID_PFAE, solicitudUltimoPaso);
  };

  const setearDatosSolicitudPM = (datosSolicitud, solicitudUltimoPaso) => {
    if (datosSolicitud?.principalAccionista) {
      if (
        datosSolicitud?.principalAccionista.idPrincipalAccionista !== null &&
        (datosSolicitud?.principalAccionista.generales.rfc === null ||
          datosSolicitud?.principalAccionista.generales.rfc === "")
      ) {
        setActualizarPA(true);
      }

      if (
        datosSolicitud?.principalAccionista?.conyuge &&
        datosSolicitud?.principalAccionista?.conyuge?.idConyuge !== null
      ) {
        if (datosSolicitud?.principalAccionista?.conyuge?.generales) {
          if (
            datosSolicitud?.principalAccionista?.conyuge?.generales
              ?.nivelEducativoId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.generales.nivelEducativoId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.generales
              ?.estadoCivilId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.generales.estadoCivilId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.generales
              ?.dependientesEconomicos === null
          ) {
            datosSolicitud.principalAccionista.conyuge.generales.dependientesEconomicos =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.generales
              ?.dependientesEconomicosMenores23 === null
          ) {
            datosSolicitud.principalAccionista.conyuge.generales.dependientesEconomicosMenores23 =
              "";
          }
        }

        if (datosSolicitud?.principalAccionista?.conyuge?.direccion) {
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion?.paisId ===
            null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.paisId = "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tipoDomicilioId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tipoDomicilioId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tipoViviendaId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tipoViviendaId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tipoViviendaId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tipoViviendaId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tiempoViviendaMeses === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tiempoViviendaMeses =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tiempoViviendaAntMeses === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tiempoViviendaAntMeses =
              "";
          }
        }

        if (datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio) {
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.importaExportaId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.importaExportaId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.sectorId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.sectorId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.giroEmpresarialId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.giroEmpresarialId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.actividadId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.actividadId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.aniosExperienciaSector === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.aniosExperienciaSector =
              "";
          }
        }
      }
    }

    if (datosSolicitud?.idDatosTempAutoservicio === null) {
      let idDatosTemp = parseInt(
        sessionStorage.getItem(sessionStorageAutoServicio)
      );
      datosSolicitud.idDatosTempAutoservicio = idDatosTemp;
    }

    if (datosSolicitud.idDatosOperacion === null) {
      datosSolicitud.idDatosOperacion =
        datosSolicitud.datosOperacion.idDatosOperacion;
    }

    setModeloPm(datosSolicitud);

    let cantidadOsSolicitudConyuge = cantidadConyugeOS;
    if (datosSolicitud?.principalAccionista?.idPrincipalAccionista !== null) {
      if (
        datosSolicitud?.principalAccionista?.conyuge !== null &&
        datosSolicitud?.principalAccionista?.conyuge?.generales
          ?.esObligadoSolidario
      ) {
        cantidadOsSolicitudConyuge = cantidadOsSolicitudConyuge + 1;
      }
    }

    if (
      datosSolicitud?.obligadosSolidarios !== undefined &&
      datosSolicitud?.obligadosSolidarios !== null &&
      datosSolicitud?.obligadosSolidarios.length > 0
    ) {
      setCantidadObligadosSolidarios(
        datosSolicitud?.obligadosSolidarios.length
      );
      obtenerDatosOblidadoSolidario(
        datosSolicitud?.obligadosSolidarios,
        cantidadOsSolicitudConyuge,
        setDatosOSUno,
        setDatosOSDos,
        setDatosOSTres,
        setDatosOSCuatro,
        setDatosOSCinco,
        setCantidadConyugeOS
      );
    } else {
      setCantidadConyugeOS(cantidadOsSolicitudConyuge);
    }
    verificarSeccionesSolicitud(datosSolicitud, ID_PM, solicitudUltimoPaso);
    setTipoPersona("PM");
  };

  const verificarSeccionesSolicitud = (
    datosSolicitud,
    idTipoPersona,
    ultimoPasoSolicitud
  ) => {
    let ultimoPaso;

    let general = true;
    let representanteLegal = false;
    let domicilioNegocio = false;
    let domicilioComercial = false;
    let informacionNegocio = false;
    let economicos = false;
    let socios = false;
    let principalAccionista = false;
    let principalAccionistaConyuge = false;
    let obligadosSolidario = false;
    let historialCrediticio = false;
    let domicilio = false;
    let domicilioFiscal = false;
    let solicitanteConyuge = false;

    if (idTipoPersona === ID_PFAE) {
      if (datosSolicitud.idSolicitud == null) {
        general = true;
      } else {
        general = true;
        //Se quedo en la pantalla de Generales
        ultimoPaso = SECCION_GENERALES;
        setSubMenuSelected("generales");
        setFormSection("Generales");
        setSeccion(SECCION_GENERALES);

        if (datosSolicitud?.direccion?.idDireccion !== null) {
          domicilio = true;
          setSeccion(SECCION_DOMICILIO);

          if (
            datosSolicitud?.domicilioFiscal?.idDatosDelNegocio !== null &&
            datosSolicitud?.domicilioFiscal?.calle !== null &&
            datosSolicitud?.domicilioFiscal?.codigoPostal !== null
          ) {
            domicilioFiscal = true;
            setSeccion(SECCION_DOMICILIO_FISCAL);

            if (
              datosSolicitud?.domicilioComercial?.idDatosDelNegocio !== null
            ) {
              domicilioComercial = true;
              setSeccion(SECCION_DOMICILIO_COMERCIAL);

              if (datosSolicitud?.datosClaveCIEC?.idDatosClaveCIEC !== null) {
                economicos = true;
                setSeccion(SECCION_ECONOMICOS);

                if (
                  datosSolicitud.conyuge !== null &&
                  datosSolicitud.conyuge.idConyuge !== null
                ) {
                  solicitanteConyuge = true;
                  //Se quedo en la pantalla de Solicitante conyuge
                  ultimoPaso = SECCION_SOLICITANTE_CONYUGE;
                  setConyugeOS(
                    datosSolicitud.conyuge.generales.esObligadoSolidario
                  );
                  setSeccion(SECCION_SOLICITANTE_CONYUGE);
                  if (datosSolicitud.conyuge.generales.esObligadoSolidario) {
                    setSubMenuSelected("oSolidario");
                    setFormSection("Obligados Solidarios");
                  }
                }

                if (
                  datosSolicitud?.obligadosSolidarios !== undefined &&
                  datosSolicitud?.obligadosSolidarios !== null &&
                  datosSolicitud?.obligadosSolidarios?.length > 0
                ) {
                  obligadosSolidario = true;

                  //Se quedo en la pantalla de Obligados Solidarios
                  ultimoPaso = SECCION_OBLIGADO_SOLIDARIO;
                  setSubMenuSelected("oSolidario");
                  setFormSection("Obligados Solidarios");
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                }

                if (
                  ultimoPasoSolicitud == PASO_SOLICITUD_HISTORIAL_CREDITICIO ||
                  ultimoPasoSolicitud == PASO_SOLICITUD_EVALUACION
                ) {
                  historialCrediticio = true;

                  //Se quedo en la pantalla de Historial Crediticio
                  ultimoPaso = SECCION_HISTORIAL_CREDITICIO;
                  setSubMenuSelected("historialcrediticio");
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  if (
                    ultimoPasoSolicitud == PASO_SOLICITUD_HISTORIAL_CREDITICIO
                  ) {
                    setFormSection("Historial Crediticio");
                  } else {
                    setFormSection("Resultado pre evaluación");
                  }
                }

                if (ultimoPasoSolicitud == PASO_SOLICITUD_RECABAR_DOCUMENTOS) {
                  //Se quedo en la pantalla de Historial Crediticio
                  ultimoPaso = SECCION_CARGA_DOCUMENTOS;
                  setSeccion(SECCION_CARGA_DOCUMENTOS);
                  setSubMenuSelected(SUB_MENU_CADENA_CARGA_DOCUMENTOS);
                  setFormSection("Carga de documentos");
                }
              }
            }
          }
        }

        if (solicitanteConyuge == false) {
          if (obligadosSolidario == false) {
            if (domicilio == false) {
              setSeccion(SECCION_DOMICILIO);
              domicilio = true;
            } else {
              if (domicilioFiscal == false) {
                setSeccion(SECCION_DOMICILIO_FISCAL);
                domicilioFiscal = true;
              } else {
                if (domicilioComercial == false) {
                  setSeccion(SECCION_DOMICILIO_COMERCIAL);
                  domicilioComercial = true;
                } else {
                  if (
                    ultimoPasoSolicitud !=
                      PASO_SOLICITUD_HISTORIAL_CREDITICIO &&
                    ultimoPasoSolicitud != PASO_SOLICITUD_RECABAR_DOCUMENTOS
                  ) {
                    setSeccion(SECCION_ECONOMICOS);
                    economicos = true;
                  }
                }
              }
            }
          }
        }
      }

      setMostrarSeccionPfae({
        ...mostrarSeccionPfae,
        general: general,
        domicilio: domicilio,
        domicilioFiscal: domicilioFiscal,
        domicilioComercial: domicilioComercial,
        economicos: economicos,
        obligadosSolidario: obligadosSolidario,
        historialCrediticio: historialCrediticio,
        solicitanteConyuge: solicitanteConyuge,
      });
    } else {
      if (datosSolicitud.idSolicitud === null) {
        general = true;
      } else {
        general = true;

        //Se quedo en la pantalla de Generales
        ultimoPaso = SECCION_GENERALES;
        setSubMenuSelected("generales");
        setFormSection("Generales");
        setSeccion(SECCION_GENERALES);

        if (datosSolicitud?.representanteLegal?.idContactoDeLaEmpresa) {
          representanteLegal = true;
          setSeccion(SECCION_REPRESENTANTE_LEGAL);

          if (datosSolicitud.direccion?.idDireccion) {
            domicilioNegocio = true;
            setSeccion(SECCION_DOMICILIO_NEGOCIO);

            if (
              datosSolicitud?.domicilioComercial?.idDatosDelNegocio !== null &&
              datosSolicitud?.domicilioComercial?.calle !== null &&
              datosSolicitud?.domicilioComercial?.codigoPostal !== null
            ) {
              domicilioComercial = true;
              setSeccion(SECCION_DOMICILIO_COMERCIAL_PM);

              if (
                datosSolicitud?.datosDelNegocio?.idDatosDelNegocio &&
                datosSolicitud?.datosDelNegocio?.actividadId
              ) {
                informacionNegocio = true;
                setSeccion(SECCION_INFORMACION_NEGOCIO);

                if (
                  datosSolicitud?.sociosPrincipales != null &&
                  datosSolicitud?.sociosPrincipales.length
                ) {
                  socios = true;

                  //Se quedo en la pantalla de Socios
                  ultimoPaso = SECCION_SOCIOS;
                  setSeccion(SECCION_SOCIOS);

                  if (datosSolicitud?.datosClaveCIEC?.idDatosClaveCIEC) {
                    economicos = true;
                    setSeccion(SECCION_ECONOMICOS);

                    if (
                      datosSolicitud?.principalAccionista?.idPrincipalAccionista
                    ) {
                      principalAccionista = true;

                      //Se quedo en la pantalla de Principal Accionista
                      ultimoPaso = SECCION_PRINCIPAL_ACCIONISTA;
                      setSubMenuSelected("oSolidario");
                      setFormSection("Obligados Solidarios");
                      setSeccion(SECCION_PRINCIPAL_ACCIONISTA);

                      if (
                        datosSolicitud?.principalAccionista?.conyuge &&
                        datosSolicitud?.principalAccionista?.conyuge?.idConyuge
                      ) {
                        principalAccionistaConyuge = true;

                        //Se quédo en la pantalla de Principal Accionista conyuge
                        ultimoPaso = SECCION_PA_CONYUGE_PM;
                        setSeccion(SECCION_PA_CONYUGE_PM);
                      }
                    }

                    if (
                      datosSolicitud?.obligadosSolidarios !== undefined &&
                      datosSolicitud?.obligadosSolidarios !== null &&
                      datosSolicitud?.obligadosSolidarios.length > 0
                    ) {
                      obligadosSolidario = true;

                      //Se quedo en la pantalla de Obligados Solidarios
                      ultimoPaso = SECCION_OBLIGADO_SOLIDARIO;
                      setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                    }

                    if (
                      (ultimoPasoSolicitud ==
                        PASO_SOLICITUD_HISTORIAL_CREDITICIO ||
                        ultimoPasoSolicitud == PASO_SOLICITUD_EVALUACION) &&
                      datosSolicitud?.principalAccionista?.generales.rfc !=
                        "" &&
                      datosSolicitud?.principalAccionista?.generales.nombre ==
                        datosSolicitud?.representanteLegal?.nombre &&
                      datosSolicitud?.principalAccionista?.generales
                        .apellidoPaterno ==
                        datosSolicitud?.representanteLegal?.apellidoPaterno &&
                      datosSolicitud?.principalAccionista?.generales
                        .apellidoMaterno ==
                        datosSolicitud?.representanteLegal?.apellidoMaterno
                    ) {
                      historialCrediticio = true;

                      //Se quedo en la pantalla de Historial Crediticio
                      ultimoPaso = SECCION_HISTORIAL_CREDITICIO;
                      setSubMenuSelected("historialcrediticio");
                      setSeccion(SECCION_HISTORIAL_CREDITICIO);

                      if (
                        ultimoPasoSolicitud ==
                        PASO_SOLICITUD_HISTORIAL_CREDITICIO
                      ) {
                        setFormSection("Historial Crediticio");
                      } else {
                        setFormSection("Resultado pre evaluación");
                      }
                    }

                    if (
                      ultimoPasoSolicitud == PASO_SOLICITUD_RECABAR_DOCUMENTOS
                    ) {
                      //Se quedo en la pantalla de Historial Crediticio
                      ultimoPaso = SECCION_CARGA_DOCUMENTOS;
                      setSeccion(SECCION_CARGA_DOCUMENTOS);
                      setSubMenuSelected(SUB_MENU_CADENA_CARGA_DOCUMENTOS);
                      setFormSection("Carga de documentos");
                    }
                  }
                }
              }
            }
          }
        }

        if (principalAccionista === false) {
          if (representanteLegal === false) {
            setSeccion(SECCION_REPRESENTANTE_LEGAL);
            representanteLegal = true;
          } else {
            if (domicilioNegocio === false) {
              setSeccion(SECCION_DOMICILIO_NEGOCIO);
              domicilioNegocio = true;
            } else {
              if (domicilioComercial === false) {
                setSeccion(SECCION_DOMICILIO_COMERCIAL_PM);
                domicilioComercial = true;
              } else {
                if (informacionNegocio === false) {
                  setSeccion(SECCION_INFORMACION_NEGOCIO);
                  informacionNegocio = true;
                } else {
                  if (socios === false) {
                    setSeccion(SECCION_SOCIOS);
                    socios = true;
                  } else {
                    setSeccion(SECCION_ECONOMICOS);
                    economicos = true;
                  }
                }
              }
            }
          }
        }
      }

      setMostrarSeccionPm({
        ...mostrarSeccionPm,
        general: general,
        representanteLegal: representanteLegal,
        domicilioNegocio: domicilioNegocio,
        domicilioComercial: domicilioComercial,
        informacionNegocio: informacionNegocio,
        economicos: economicos,
        socios: socios,
        principalAccionista: principalAccionista,
        principalAccionistaConyuge: principalAccionistaConyuge,
        obligadosSolidario: obligadosSolidario,
        historialCrediticio: historialCrediticio,
      });
    }

    setUltimoPasoUsuario(ultimoPaso);
  };

  const obtenerDetalleSolicitud = async (idSolicitud) => {
    const data = {
      idSolicitud: idSolicitud,
    };

    if (idSolicitud !== null) {
      const response = await api_obtener_detalle_solicitud_crediactivo(data);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          const datosSolicitud = data.datosSolicitudCreditoPyME;
          setPlazo(datosSolicitud.datosOperacion.plazo);
          setCredito(datosSolicitud.datosOperacion.montoCredito);
          setCreditoFloat(datosSolicitud.datosOperacion.montoCreditoFloat);
          setProductoPyme(datosSolicitud.datosOperacion.tipoDeCreditoNombre);
          setIdTipoPersona(data.idTipoPersona);
          setUltimoPasoSolicitud(data.solicitudUltimoPaso);
          calculoMensualidad(
            datosSolicitud?.datosOperacion?.montoCredito,
            datosSolicitud?.datosOperacion?.plazo
          );
          if (data.solicitudFolio) setFolioSolicitud(data.solicitudFolio);
          if (datosSolicitud?.datosEconomicos?.bancos != null) {
            setCantidadBancos(datosSolicitud.datosEconomicos.bancos.length);
          }

          if (data.idTipoPersona === ID_PFAE) {
            setTipoPersona("PFAE");
            setearDatosSolicitudPFAE(datosSolicitud, data.solicitudUltimoPaso);
          } else {
            setTipoPersona("PM");
            if (
              datosSolicitud?.sociosPrincipales != null &&
              datosSolicitud?.sociosPrincipales?.length > 0
            ) {
              setCantidadSocios(datosSolicitud.sociosPrincipales.length);
            }

            setearDatosSolicitudPM(datosSolicitud, data.solicitudUltimoPaso);
          }
        } else {
          sweetAlertError("Ocurrió un error al obtener datos de la solicitud");
        }
      } else {
        sweetAlertError("Ocurrió un error al obtener datos de la solicitud");
      }
      setLoading(false);
    } else {
      sweetAlertWithConfirmedCrediactivo(
        "No puede acceder a esta página, si no ha seleccionado un plazo y monto de crédito"
      );
      setLoading(false);
    }
  };

  // SOCIOS PRINCIPALES
  const [cantidadSocios, setCantidadSocios] = useState(1);

  // REFS
  const refScrollUp = useRef();

  const refScrollUpBancos = useRef();

  const animatedHeightBoxUnoRef = useRef();
  const animatedHeightBoxDosRef = useRef();
  const animatedHeightBoxTresRef = useRef();

  // SECCION ROJA
  const [credito, setCredito] = useState("0");
  const [creditoFloat, setCreditoFloat] = useState("0");
  const [, setProductoPyme] = useState();

  // SECCION HANDLERS
  const [seccion, setSeccion] = useState(0);

  const [importeRentaFiscal, setImporteRentaFiscal] = useState("$");
  const [totalNominaMensual, setTotalNominaMensual] = useState("$");
  const [, setVentasAnuales] = useState("$");

  // DATOS ECONOMICOS
  const [cantidadBancos, setCantidadBancos] = useState(1);
  const [, setSelectedBanco] = useState(1);

  // TRANSITION TOGGLE
  const [transitionZeroToggle, setTransitionZeroToggle] = useState(true);
  const [transitionOneToggle, setTransitionOneToggle] = useState(true);
  const [transitionTwoToggle, setTransitionTwoToggle] = useState(false);
  const [transitionThreeToggle, setTransitionThreeToggle] = useState(false);
  const [transitionFourToggle, setTransitionFourToggle] = useState(false);
  const [transitionFiveToggle, setTransitionFiveToggle] = useState(false);
  const [transitionSixToggle, setTransitionSixToggle] = useState(false);
  const [transitionSevenToggle, setTransitionSevenToggle] = useState(false);
  const [transitionEightToggle, setTransitionEightToggle] = useState(false);
  const [transitionNineToggle, setTransitionNineToggle] = useState(false);
  const [transitionTenToggle, setTransitionTenToggle] = useState(false);
  const [transitionElevenToggle, setTransitionElevenToggle] = useState(false);
  const [transitionTwelveToggle, setTransitionTwelveToggle] = useState(false);
  const [transitionThirteenToggle, setTransitionThirteenToggle] =
    useState(false);
  const [transitionFourtheenToggle, setTransitionFourtheenToggle] =
    useState(false);
  const [transitionFivetheenToggle, setTransitionFivetheenToggle] =
    useState(false);
  const [transitionDiesiseisToggle, setTransitionDiesiseisToggle] =
    useState(false);
  const [transitionDiesisieteToggle, setTransitionDiesisieteToggle] =
    useState(false);
  const [transitionDiesiochoToggle, setTransitionDiesiochoToggle] =
    useState(false);
  const [transitionDiecinueveToggle, setTransitionDiecinueveToggle] =
    useState(false);
  const [transitionVeinteToggle, setTransitionVeinteToggle] = useState(false);
  const [transitionVeintidosToggle, setTransitionVeintidosToggle] =
    useState(false);
  const [transitionVeintitresToggle] = useState(false);

  const [movingBack, setMovingBack] = useState(false);

  // BANCOS TRANSITION TOGGLE
  const [transitionBancoOneToggle, setTransitionBancoOneToggle] =
    useState(true);
  const [transitionBancoTwoToggle, setTransitionBancoTwoToggle] =
    useState(false);
  const [transitionBancoThreeToggle, setTransitionBancoThreeToggle] =
    useState(false);

  const handlePesos = (e, handleChange) => {
    let { value } = e.target;

    if (value) {
      value = (Number(value.replace(/\D/g, "")) || "")
        .toLocaleString()
        .replaceAll(".", ",");
      handleChange(value);
    } else {
      handleChange("");
    }

    value = "$" + value;
    handleChange(value);
  };

  const calculoMensualidad = (montoCredito, plazo) => {
    if (montoCredito !== "") {
      let tasaConIva;
      let tasaAnual;
      let pagoCalculo;
      montoCredito = montoCredito.toString().replaceAll(",", "");
      if (Number.isNaN(montoCredito) === false) {
        montoCredito = parseFloat(montoCredito);
        tasaConIva = sessionStorage.getItem(sessionTasaAnualAts) * 1.16;
        tasaAnual = tasaConIva / 12;
        pagoCalculo =
          parseFloat(montoCredito) /
          ((1 - (1 + tasaAnual) ** -plazo) / tasaAnual);
        pagoCalculo = parseFloat(pagoCalculo.toFixed(2));
        pagoCalculo = pagoCalculo.toLocaleString("es-MX");
        setPago(pagoCalculo);
      }
    } else {
      setPago(0.0);
    }
  };

  const handleSeccionPyme = (valor) => {
    let maxSecciones = 26;
    setMovingBack(false);

    if (valor <= maxSecciones - 1 && valor >= 0) {
      if (idTipoPersona === ID_PFAE) {
        mostrarPestaniasPymePfae(valor);
      } else {
        mostrarPestaniasPymePm(valor);
      }
    }
    const element = document.getElementById("root");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const mostrarPestaniasPymePfae = async (seccionActual) => {
    setAvanzarSolicitud(true);

    let idSolicitud = modeloPfae.idSolicitud;

    switch (seccionActual) {
      case SECCION_ZERO:
        setSeccion(SECCION_GENERALES);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          general: true,
        });
        break;
      case SECCION_GENERALES:
        if (ultimoPasoUsuario == null) {
          setUltimoPasoUsuario(SECCION_GENERALES);
        }

        setSeccion(SECCION_DOMICILIO);
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          domicilio: true,
        });
        break;
      case SECCION_DOMICILIO:
        setSeccion(SECCION_DOMICILIO_FISCAL);
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          domicilioFiscal: true,
        });
        break;
      case SECCION_DOMICILIO_FISCAL:
        setSeccion(SECCION_DOMICILIO_COMERCIAL);
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          domicilioComercial: true,
        });
        break;
      case SECCION_DOMICILIO_COMERCIAL:
        setSeccion(SECCION_ECONOMICOS);
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          economicos: true,
        });
        break;
      case SECCION_ECONOMICOS:
        if (ultimoPasoSolicitud < PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
          cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
        } else {
          setSeccion(SECCION_HISTORIAL_CREDITICIO);
          setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
          setFormSection("Historial Crediticio");
          setMostrarSeccionPfae({
            ...mostrarSeccionPfae,
            historialCrediticio: true,
          });
          setLoading(false);
        }
        break;
      case SECCION_HISTORIAL_CREDITICIO:
        setSeccion(SECCION_CARGA_DOCUMENTOS);
        setSubMenuSelected(SUB_MENU_CADENA_CARGA_DOCUMENTOS);
        setFormSection("Carga de documentos");
        break;
      case SECCION_SOLICITANTE_CONYUGE:
        if (
          ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
          ultimoPasoUsuario === SECCION_GENERALES
        ) {
          setUltimoPasoUsuario(SECCION_SOLICITANTE_CONYUGE);
        }
        if (modeloPfae?.conyuge?.generales?.esObligadoSolidario) {
          if (datosOSUno?.datosOS?.length === 0) {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OBLIGADO_SOLIDARIO);
              setSubMenuSelected(SUB_MENU_CADENA_OS);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setFormSection("Obligados Solidarios");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                obligadosSolidario: true,
              });
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          } else {
            setSeccion(SECCION_OBLIGADO_SOLIDARIO);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            setMostrarSeccionPfae({
              ...mostrarSeccionPfae,
              obligadosSolidario: true,
            });
            mostrarSeccionPfae.obligadosSolidario = true;
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          }
        } else {
          setSeccion(SECCION_OBLIGADO_SOLIDARIO);
          setFormSection("Obligados Solidarios");
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          setMostrarSeccionPfae({
            ...mostrarSeccionPfae,
            obligadosSolidario: true,
          });
          if (datosOSUno?.datosOS?.length === 0) {
            setDisabledBtnAgregarOs(true);
          } else {
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        break;
      case SECCION_OBLIGADO_SOLIDARIO:
        if (
          ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
          (ultimoPasoUsuario === SECCION_GENERALES ||
            ultimoPasoUsuario === SECCION_SOLICITANTE_CONYUGE)
        ) {
          setUltimoPasoUsuario(SECCION_OBLIGADO_SOLIDARIO);
        }
        if (
          parseInt(datosOSUno.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_UNO);
          if (datosOSUno?.conyuge?.length === 0) {
            setDisabledBtnAgregarOs(true);
          } else {
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          }
        } else {
          if (datosOSDos?.datosOS?.length === 0) {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_DOS);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          } else {
            setSeccion(SECCION_OS_DOS);
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        break;
      case SECCION_OS_DOS:
        if (
          parseInt(datosOSDos.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_DOS);
          if (datosOSDos.conyuge.length === 0) {
            setDisabledBtnAgregarOs(true);
          } else {
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          }
        } else {
          if (datosOSTres.datosOS.length === 0) {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_TRES);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          } else {
            setSeccion(SECCION_OS_TRES);
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        break;
      case SECCION_OS_TRES:
        if (
          parseInt(datosOSTres.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_TRES);
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          if (datosOSTres.conyuge.length === 0) {
            setDisabledBtnAgregarOs(true);
          } else {
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          }
        } else {
          if (datosOSCuatro.datosOS.length === 0) {
            if (
              cantidadObligadosSolidarios + cantidadConyugeOS >=
              OS_MAX_PFAE
            ) {
              setLoading(true);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            } else {
              const response = await sweetAlertObligadoSolidario();
              if (response) {
                setSeccion(SECCION_OS_CUATRO);
                setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
                setDisabledBtnAgregarOs(true);
              } else {
                setLoading(true);
                setDisabledBtnAgregarOs(false);
                if (
                  ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
                ) {
                  cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
                } else {
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                  setFormSection("Historial Crediticio");
                  setMostrarSeccionPfae({
                    ...mostrarSeccionPfae,
                    historialCrediticio: true,
                  });
                  setLoading(false);
                }
              }
            }
          } else {
            setSeccion(SECCION_OS_CUATRO);
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        break;
      case SECCION_OS_CUATRO:
        if (
          parseInt(datosOSCuatro.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_CUATRO);
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          setFormSection("Obligados Solidarios");
          if (datosOSCuatro.conyuge.length === 0) {
            setDisabledBtnAgregarOs(true);
          } else {
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          }
        } else {
          if (datosOSCinco.datosOS.length === 0) {
            if (
              cantidadObligadosSolidarios + cantidadConyugeOS >=
              OS_MAX_PFAE
            ) {
              setLoading(true);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            } else {
              const response = await sweetAlertObligadoSolidario();
              if (response) {
                setSeccion(SECCION_OS_CINCO);
                setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
                setDisabledBtnAgregarOs(true);
              } else {
                setLoading(true);
                setDisabledBtnAgregarOs(false);
                if (
                  ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
                ) {
                  cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
                } else {
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                  setFormSection("Historial Crediticio");
                  setMostrarSeccionPfae({
                    ...mostrarSeccionPfae,
                    historialCrediticio: true,
                  });
                  setLoading(false);
                }
              }
            }
          } else {
            setSeccion(SECCION_OS_CINCO);
          }
        }
        break;
      case SECCION_OS_CINCO:
        setLoading(true);
        if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
          cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
        } else {
          setSeccion(SECCION_HISTORIAL_CREDITICIO);
          setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
          setFormSection("Historial Crediticio");
          setMostrarSeccionPfae({
            ...mostrarSeccionPfae,
            historialCrediticio: true,
          });
          setLoading(false);
        }
        break;
      case SECCION_CONYUGE_OS_UNO:
        if (datosOSDos.datosOS.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_DOS);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_DOS);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      case SECCION_CONYUGE_OS_DOS:
        if (datosOSTres?.datosOS?.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_TRES);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_TRES);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      case SECCION_CONYUGE_OS_TRES:
        if (datosOSCuatro.datosOS.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_CUATRO);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_CUATRO);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      case SECCION_CONYUGE_OS_CUATRO:
        if (datosOSCinco.datosOS.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_CINCO);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_CINCO);
        }
        break;
      default:
        setSeccion(ultimoPasoUsuario ? ultimoPasoUsuario : SECCION_GENERALES);
        break;
    }
  };

  const mostrarPestaniasPymePm = async (seccionActual) => {
    let idSolicitud = modeloPm.idSolicitud;
    switch (seccionActual) {
      case SECCION_GENERALES:
        if (ultimoPasoUsuario == null) {
          setUltimoPasoUsuario(SECCION_GENERALES);
        }

        setSeccion(SECCION_REPRESENTANTE_LEGAL);
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          representanteLegal: true,
        });
        break;
      case SECCION_REPRESENTANTE_LEGAL:
        if (
          actualizarPA ||
          (modeloPm?.principalAccionista?.idPrincipalAccionista !== null &&
            modeloPm.principalAccionista.generales.rfc == "")
        ) {
          Swal.fire({
            icon: "success",
            title:
              "Se ha modificado el nombre del representante legal, deberás revisar la información del obligado solidario.",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#dc3545",
          }).then((result) => {
            if (result.isConfirmed) {
              setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
              setSubMenuSelected(SUB_MENU_CADENA_OS);
              setFormSection("Obligados Solidarios");
              setMostrarSeccionPm({
                ...mostrarSeccionPm,
                principalAccionista: true,
              });
            }
          });
        } else {
          setSeccion(SECCION_DOMICILIO_NEGOCIO);
          setMostrarSeccionPm({
            ...mostrarSeccionPm,
            domicilioNegocio: true,
          });
        }

        break;
      case SECCION_DOMICILIO_NEGOCIO:
        setSeccion(SECCION_DOMICILIO_COMERCIAL_PM);
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          domicilioComercial: true,
        });
        break;
      case SECCION_DOMICILIO_COMERCIAL_PM:
        setSeccion(SECCION_INFORMACION_NEGOCIO);
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          informacionNegocio: true,
        });
        break;
      case SECCION_INFORMACION_NEGOCIO:
        setSeccion(SECCION_SOCIOS);
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          socios: true,
        });
        break;
      case SECCION_SOCIOS:
        setSeccion(SECCION_ECONOMICOS);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          economicos: true,
        });
        break;
      case SECCION_ECONOMICOS:
        setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          principalAccionista: true,
        });
        if (modeloPm.principalAccionista.idPrincipalAccionista == null) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(true);
          }
        } else {
          setDisabledBtnAgregarOs(false);
        }
        break;
      case SECCION_PRINCIPAL_ACCIONISTA:
        if (
          ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
          ultimoPasoUsuario === SECCION_GENERALES
        ) {
          setUltimoPasoUsuario(SECCION_PRINCIPAL_ACCIONISTA);
        }
        mostrarSeccionPm.principalAccionistaConyuge = false;

        if (datosOSUno.datosOS.length === 0) {
          setLoading(true);
          setDisabledBtnAgregarOs(false);
          if (ultimoPasoSolicitud < PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
            cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
          } else {
            setSeccion(SECCION_HISTORIAL_CREDITICIO);
            setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
            setFormSection("Historial Crediticio");
            setMostrarSeccionPfae({
              ...mostrarSeccionPfae,
              historialCrediticio: true,
            });
            setLoading(false);
          }
          //     }
          //      });
        } else {
          setSeccion(SECCION_OBLIGADO_SOLIDARIO);
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          setFormSection("Obligados Solidarios");
          setMostrarSeccionPm({
            ...mostrarSeccionPm,
            obligadosSolidario: true,
          });
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(false);
          }
        }
        //}
        break;
      case SECCION_HISTORIAL_CREDITICIO:
        setSeccion(SECCION_CARGA_DOCUMENTOS);
        setSubMenuSelected(SUB_MENU_CADENA_CARGA_DOCUMENTOS);
        setFormSection("Carga de documentos");
        break;
      case SECCION_PA_CONYUGE_PM:
        if (
          ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
          ultimoPasoUsuario === SECCION_PRINCIPAL_ACCIONISTA
        ) {
          setUltimoPasoUsuario(SECCION_PA_CONYUGE_PM);
        }
        if (datosOSUno?.datosOS?.length === 0) {
          const response = await sweetAlertObligadoSolidario();
          if (response) {
            setSeccion(SECCION_OBLIGADO_SOLIDARIO);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
            setMostrarSeccionPm({
              ...mostrarSeccionPm,
              obligadosSolidario: true,
            });
            setDisabledBtnAgregarOs(true);
          } else {
            setLoading(true);
            setDisabledBtnAgregarOs(false);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          }
        } else {
          setSeccion(SECCION_OBLIGADO_SOLIDARIO);
          setFormSection("Obligados Solidarios");
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          setMostrarSeccionPm({
            ...mostrarSeccionPm,
            obligadosSolidario: true,
          });
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      case SECCION_OBLIGADO_SOLIDARIO:
        if (
          ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
          (ultimoPasoUsuario === SECCION_PRINCIPAL_ACCIONISTA ||
            ultimoPasoUsuario === SECCION_PA_CONYUGE_PM)
        ) {
          setUltimoPasoUsuario(SECCION_OBLIGADO_SOLIDARIO);
        }
        if (
          parseInt(datosOSUno.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_UNO);
          if (datosOSUno?.conyuge?.length === 0) {
            setDisabledBtnAgregarOs(true);
          } else {
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        } else {
          if (datosOSDos?.datosOS?.length === 0) {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_DOS);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          } else {
            setSeccion(SECCION_OS_DOS);
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        break;
      case SECCION_OS_DOS:
        if (
          parseInt(datosOSDos.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_DOS);
          if (datosOSDos?.conyuge?.length === 0) {
            setDisabledBtnAgregarOs(true);
          } else {
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        } else {
          if (datosOSTres?.datosOS?.length === 0) {
            const response = await sweetAlertObligadoSolidario();

            if (response) {
              setSeccion(SECCION_OS_TRES);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          } else {
            setSeccion(SECCION_OS_TRES);
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        break;
      case SECCION_OS_TRES:
        if (
          parseInt(datosOSTres.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_TRES);
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          if (datosOSTres?.conyuge?.length === 0) {
            setDisabledBtnAgregarOs(true);
          } else {
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        } else {
          if (datosOSCuatro?.datosOS?.length === 0) {
            if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PM) {
              setLoading(true);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            } else {
              const response = await sweetAlertObligadoSolidario();
              if (response) {
                setSeccion(SECCION_OS_CUATRO);
                setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
                setDisabledBtnAgregarOs(true);
              } else {
                setLoading(true);
                setDisabledBtnAgregarOs(false);
                if (
                  ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
                ) {
                  cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
                } else {
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                  setFormSection("Historial Crediticio");
                  setMostrarSeccionPfae({
                    ...mostrarSeccionPfae,
                    historialCrediticio: true,
                  });
                  setLoading(false);
                }
              }
            }
          } else {
            setSeccion(SECCION_OS_CUATRO);
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        break;
      case SECCION_OS_CUATRO:
        setLoading(true);
        if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
          cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
        } else {
          setSeccion(SECCION_HISTORIAL_CREDITICIO);
          setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
          setFormSection("Historial Crediticio");
          setMostrarSeccionPfae({
            ...mostrarSeccionPfae,
            historialCrediticio: true,
          });
          setLoading(false);
        }
        break;
      case SECCION_CONYUGE_OS_UNO:
        if (datosOSDos?.datosOS?.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PM) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_DOS);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_DOS);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      case SECCION_CONYUGE_OS_DOS:
        if (datosOSTres?.datosOS?.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PM) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_TRES);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_TRES);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      case SECCION_CONYUGE_OS_TRES:
        if (datosOSCuatro?.datosOS?.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PM) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_CUATRO);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_CUATRO);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      default:
        setSeccion(ultimoPasoUsuario ? ultimoPasoUsuario : SECCION_GENERALES);
        break;
    }
  };

  const handleRegresarSeccionPyme = (valor) => {
    if (valor > 1) {
      if (idTipoPersona === ID_PFAE) {
        mostrarPestaniasAtrasPymePfae(valor);
      } else {
        mostrarPestaniasAtrasPymePm(valor);
      }
    }
    const element = document.getElementById("root");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const mostrarPestaniasAtrasPymePfae = (seccionActual) => {
    switch (seccionActual) {
      case SECCION_DOMICILIO:
        setSeccion(SECCION_GENERALES);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        break;
      case SECCION_DOMICILIO_FISCAL:
        setSeccion(SECCION_DOMICILIO);
        setFormSection("Generales");
        break;
      case SECCION_DOMICILIO_COMERCIAL:
        setSeccion(SECCION_DOMICILIO_FISCAL);
        setFormSection("Generales");
        break;
      case SECCION_ECONOMICOS:
        setSeccion(SECCION_DOMICILIO_COMERCIAL);
        setFormSection("Generales");
        break;
      case SECCION_SOLICITANTE_CONYUGE:
        setSeccion(SECCION_ECONOMICOS);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        break;
      case SECCION_OBLIGADO_SOLIDARIO:
        if (
          parseInt(modeloPfae.generales.estadoCivilId) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(PESTANIA_SOLICITANTE_CONYUGE);
          if (modeloPfae?.conyuge?.generales?.esObligadoSolidario) {
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
          } else {
            setSubMenuSelected(SUB_MENU_CADENA_GRALES);
            setFormSection("Generales");
          }
        } else {
          setSeccion(SECCION_ECONOMICOS);
          setSubMenuSelected(SUB_MENU_CADENA_GRALES);
          setFormSection("Generales");
        }
        break;
      case SECCION_OS_DOS:
        if (
          parseInt(datosOSUno.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_UNO);
        } else {
          setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_TRES:
        if (
          parseInt(datosOSDos.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_DOS);
        } else {
          setSeccion(SECCION_OS_DOS);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_CUATRO:
        if (
          parseInt(datosOSTres.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_TRES);
        } else {
          setSeccion(SECCION_OS_TRES);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_CINCO:
        if (
          parseInt(datosOSCuatro.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_CUATRO);
        } else {
          setSeccion(SECCION_OS_CUATRO);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_UNO:
        setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_DOS:
        setSeccion(SECCION_OS_DOS);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_TRES:
        setSeccion(SECCION_OS_TRES);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_CUATRO:
        setSeccion(SECCION_OS_CUATRO);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_HISTORIAL_CREDITICIO:
        setSeccion(SECCION_ECONOMICOS);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        break;
      case SECCION_CARGA_DOCUMENTOS:
        setSubMenuSelected("historialcrediticio");
        setSeccion(SECCION_HISTORIAL_CREDITICIO);
        break;
    }
  };

  const mostrarPestaniasAtrasPymePm = (seccionActual) => {
    switch (seccionActual) {
      case SECCION_REPRESENTANTE_LEGAL:
        setSeccion(SECCION_GENERALES);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        break;
      case SECCION_DOMICILIO_NEGOCIO:
        setSeccion(SECCION_GENERALES);
        setSeccion(SECCION_REPRESENTANTE_LEGAL);
        break;
      case SECCION_DOMICILIO_COMERCIAL_PM:
        setSeccion(SECCION_GENERALES);
        setSeccion(SECCION_DOMICILIO_NEGOCIO);
        break;
      case SECCION_INFORMACION_NEGOCIO:
        setSeccion(SECCION_GENERALES);
        setSeccion(SECCION_DOMICILIO_COMERCIAL_PM);
        break;
      case SECCION_SOCIOS:
        setSeccion(SECCION_INFORMACION_NEGOCIO);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        break;
      case SECCION_ECONOMICOS:
        setSeccion(SECCION_GENERALES);
        setSeccion(SECCION_SOCIOS);
        break;
      case SECCION_PRINCIPAL_ACCIONISTA:
        setSeccion(SECCION_ECONOMICOS);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        break;
      case SECCION_PA_CONYUGE_PM:
        setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OBLIGADO_SOLIDARIO:
        setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        //  }
        break;
      case SECCION_OS_DOS:
        if (
          parseInt(datosOSUno.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_UNO);
        } else {
          setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_TRES:
        if (
          parseInt(datosOSDos.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_DOS);
        } else {
          setSeccion(SECCION_OS_DOS);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_CUATRO:
        if (
          parseInt(datosOSTres.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_TRES);
        } else {
          setSeccion(SECCION_OS_TRES);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_UNO:
        setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_DOS:
        setSeccion(SECCION_OS_DOS);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_TRES:
        setSeccion(SECCION_OS_TRES);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_HISTORIAL_CREDITICIO:
        switch (cantidadObligadosSolidarios) {
          case OS_CERO:
            setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_UNO:
            if (
              parseInt(datosOSUno.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_UNO);
            } else {
              setSeccion(SECCION_OBLIGADO_SOLIDARIO);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_DOS:
            if (
              parseInt(datosOSDos.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_DOS);
            } else {
              setSeccion(SECCION_OS_DOS);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_TRES:
            if (
              parseInt(datosOSTres.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_TRES);
            } else {
              setSeccion(SECCION_OS_TRES);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_CUATRO:
            setSeccion(SECCION_OS_CUATRO);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case SECCION_CONYUGE_OS_DOS:
            setSeccion(SECCION_OS_DOS);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
        }
        break;
      case SECCION_CARGA_DOCUMENTOS:
        setSubMenuSelected("historialcrediticio");
        setSeccion(SECCION_HISTORIAL_CREDITICIO);
        break;
    }
  };

  const cambiarEstatusHistorialCredicio = async (idSolicitud) => {
    const response = await api_avanzar_historial_crediticio(idSolicitud);
    if (response.statusText === "OK") {
      const data = response.data;
      if (data.success) {
        setAvanzarSolicitud(true);
        setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
        setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
        setSeccion(SECCION_HISTORIAL_CREDITICIO);
        setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
        setFormSection("Historial Crediticio");
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          historialCrediticio: true,
        });
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertWarning("Ocurrió un error al avanzar a historial crediticio");
      }
    } else {
      setLoading(false);
      sweetAlertWarning("Ocurrió un error al avanzar a historial crediticio");
    }
  };

  useEffect(() => {
    if (esObligadoSolidario) {
      setStatePAConyuge(true);
    } else {
      setStatePAConyuge(false);
    }
  }, [esObligadoSolidario]);

  useEffect(() => {
    if (seccion === SECCION_ZERO) setTransitionZeroToggle(true);
    else setTransitionZeroToggle(false);
    if (seccion === SECCION_GENERALES) setTransitionOneToggle(true);
    else setTransitionOneToggle(false);
    if (
      seccion === SECCION_DOMICILIO ||
      seccion === SECCION_REPRESENTANTE_LEGAL
    )
      setTransitionTwoToggle(true);
    else setTransitionTwoToggle(false);
    if (
      seccion === SECCION_DOMICILIO_FISCAL ||
      seccion === SECCION_DOMICILIO_NEGOCIO
    )
      setTransitionThreeToggle(true);
    else setTransitionThreeToggle(false);
    if (
      seccion === SECCION_DOMICILIO_COMERCIAL ||
      seccion === SECCION_INFORMACION_NEGOCIO
    )
      setTransitionFourToggle(true);
    else setTransitionFourToggle(false);
    if (seccion === SECCION_ECONOMICOS) setTransitionFiveToggle(true);
    else setTransitionFiveToggle(false);
    if (seccion === SECCION_SOCIOS) setTransitionSixToggle(true);
    else setTransitionSixToggle(false);
    if (seccion === SECCION_PRINCIPAL_ACCIONISTA)
      setTransitionSevenToggle(true);
    else setTransitionSevenToggle(false);
    if (seccion === SECCION_OBLIGADO_SOLIDARIO) setTransitionEightToggle(true);
    else setTransitionEightToggle(false);
    if (seccion === SECCION_CONYUGE_OS_UNO) setTransitionNineToggle(true);
    else setTransitionNineToggle(false);
    if (seccion === SECCION_OS_DOS) setTransitionTenToggle(true);
    else setTransitionTenToggle(false);
    if (seccion === SECCION_CONYUGE_OS_DOS) setTransitionElevenToggle(true);
    else setTransitionElevenToggle(false);
    if (seccion === SECCION_OS_TRES) setTransitionTwelveToggle(true);
    else setTransitionTwelveToggle(false);
    if (seccion === SECCION_CONYUGE_OS_TRES) setTransitionThirteenToggle(true);
    else setTransitionThirteenToggle(false);
    if (seccion === SECCION_OS_CUATRO) setTransitionFourtheenToggle(true);
    else setTransitionFourtheenToggle(false);
    if (seccion === SECCION_CONYUGE_OS_CUATRO)
      setTransitionFivetheenToggle(true);
    else setTransitionFivetheenToggle(false);
    if (seccion === SECCION_OS_CINCO) setTransitionVeintidosToggle(true);
    else setTransitionVeintidosToggle(false);
    if (seccion === SECCION_PATRIMONIO) setTransitionDiesiseisToggle(true);
    else setTransitionDiesiseisToggle(false);
    if (seccion === SECCION_HISTORIAL_CREDITICIO)
      setTransitionDiesisieteToggle(true);
    else setTransitionDiesisieteToggle(false);
    if (seccion === SECCION_CARGA_DOCUMENTOS)
      setTransitionDiesiochoToggle(true);
    else setTransitionDiesiochoToggle(false);
    if (seccion === SECCION_DOMICILIO_COMERCIAL_PM)
      setTransitionDiecinueveToggle(true);
    else setTransitionDiecinueveToggle(false);
    if (
      seccion === SECCION_SOLICITANTE_CONYUGE ||
      seccion === SECCION_PA_CONYUGE_PM
    )
      setTransitionVeinteToggle(true);
    else setTransitionVeinteToggle(false);
  }, [seccion]);

  useEffect(() => {
    if (regresarCotizador) {
      window.location.replace("/cotizador");
    }
  }, [regresarCotizador]);

  const transitionConfig =
    movingBack === false
      ? {
          from: {
            opacity: 0,
            position: "absolute",
            transform: "translateX(100%)",
            pointerEvents: "all",
          },
          enter: {
            opacity: 1,
            position: "relative",
            transform: "translateX(0%)",
            top: "0px",
            pointerEvents: "all",
          },
          leave: {
            opacity: 0,
            position: "absolute",
            transform: "translateX(-100%)",
            top: "0px",
            pointerEvents: "none",
          },
        }
      : {
          from: {
            opacity: 0,
            position: "absolute",
            transform: "translateX(-100%)",
            pointerEvents: "all",
          },
          enter: {
            opacity: 1,
            position: "relative",
            transform: "translateX(0%)",
            top: "0px",
            pointerEvents: "all",
          },
          leave: {
            opacity: 0,
            position: "absolute",
            transform: "translateX(100%)",
            top: "0px",
            pointerEvents: "none",
          },
        };

  const transitionZero = useTransition(transitionZeroToggle, transitionConfig);
  const transitionOne = useTransition(transitionOneToggle, transitionConfig);
  const transitionTwo = useTransition(transitionTwoToggle, transitionConfig);
  const transitionThree = useTransition(
    transitionThreeToggle,
    transitionConfig
  );
  const transitionFour = useTransition(transitionFourToggle, transitionConfig);
  const transitionFive = useTransition(transitionFiveToggle, transitionConfig);
  const transitionSix = useTransition(transitionSixToggle, transitionConfig);
  const transitionSeven = useTransition(
    transitionSevenToggle,
    transitionConfig
  );
  const transitionEight = useTransition(
    transitionEightToggle,
    transitionConfig
  );
  const transitionNine = useTransition(transitionNineToggle, transitionConfig);
  const transitionTen = useTransition(transitionTenToggle, transitionConfig);
  const transitionEleven = useTransition(
    transitionElevenToggle,
    transitionConfig
  );
  const transitionTwelve = useTransition(
    transitionTwelveToggle,
    transitionConfig
  );
  const transitionThirteen = useTransition(
    transitionThirteenToggle,
    transitionConfig
  );
  const transitionFourtheen = useTransition(
    transitionFourtheenToggle,
    transitionConfig
  );
  const transitionFivetheen = useTransition(
    transitionFivetheenToggle,
    transitionConfig
  );
  const transitionDiesiseis = useTransition(
    transitionDiesiseisToggle,
    transitionConfig
  );
  const transitionDiesisiete = useTransition(
    transitionDiesisieteToggle,
    transitionConfig
  );
  const transitionDiesiocho = useTransition(
    transitionDiesiochoToggle,
    transitionConfig
  );
  const transitionDiecinueve = useTransition(
    transitionDiecinueveToggle,
    transitionConfig
  );
  const transitionVeinte = useTransition(
    transitionVeinteToggle,
    transitionConfig
  );
  const transitionVeintidos = useTransition(
    transitionVeintidosToggle,
    transitionConfig
  );
  const transitionVeintitres = useTransition(
    transitionVeintitresToggle,
    transitionConfig
  );

  const bancosTransitionConfig = {
    config: {
      // duration: 10000
    },
    from: {
      opacity: 0,
      position: "relative",
      width: "100%",
      transform: "translateX(-100%)",
      pointerEvents: "all",
    },
    enter: {
      opacity: 1,
      position: "relative",
      width: "100%",
      transform: "translateX(0%)",
      pointerEvents: "all",
    },
    leave: {
      opacity: 0,
      position: "relative",
      width: "100%",
      transform: "translateX(100%)",
      pointerEvents: "none",
    },
  };

  useEffect(() => {
    if (cantidadBancos >= 1) setTransitionBancoOneToggle(true);
    else setTransitionBancoOneToggle(false);
    if (cantidadBancos >= 2) setTransitionBancoTwoToggle(true);
    else setTransitionBancoTwoToggle(false);
    if (cantidadBancos >= 3) setTransitionBancoThreeToggle(true);
    else setTransitionBancoThreeToggle(false);
  }, [cantidadBancos]);

  const eliminarOSPm = async (numOsEliminar) => {
    let idParticipante = null;
    let conyugeOsEliminar = false;
    let seccionEliminada = null;
    setLoading(true);
    switch (numOsEliminar) {
      case OS_UNO:
        if (datosOSUno?.datosOS?.length !== 0) {
          idParticipante = datosOSUno.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OBLIGADO_SOLIDARIO;
        break;
      case OS_DOS:
        if (datosOSDos?.datosOS?.length !== 0) {
          idParticipante = datosOSDos.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_DOS;
        break;
      case OS_TRES:
        if (datosOSCuatro?.datosOS?.length !== 0) {
          idParticipante = datosOSCuatro.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_CUATRO;
        break;
    }

    if (idParticipante !== null) {
      const dataOs = {
        idCliente: modeloPm.idCliente,
        idPrincipalAccionista:
          modeloPm.principalAccionista.idPrincipalAccionista,
        idParticipante: idParticipante,
        tipoDeParticipanteId: TIPO_PARTICIPANTE_OS,
        numeroParticipante: numOsEliminar,
      };

      const response = await api_eliminar_os_solicitud_pm(dataOs);
      if (response.statusText === "OK") {
        switch (numOsEliminar) {
          case OS_UNO:
            if (
              datosOSUno?.conyuge !== null &&
              datosOSUno?.conyuge?.idConyuge !== null &&
              datosOSUno?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSUno({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_DOS:
            if (
              datosOSDos?.conyuge !== null &&
              datosOSDos?.conyuge?.idConyuge !== null &&
              datosOSDos?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSDos({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_TRES:
            if (
              datosOSTres?.conyuge !== null &&
              datosOSTres?.conyuge?.idConyuge !== null &&
              datosOSTres?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSTres({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_CUATRO:
            if (
              datosOSCuatro?.conyuge !== null &&
              datosOSCuatro?.conyuge?.idConyuge !== null &&
              datosOSCuatro?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSCuatro({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
        }
        if (conyugeOsEliminar) {
          setCantidadConyugeOS(cantidadConyugeOS - 1);
        }
        setCantidadObligadosSolidarios(cantidadObligadosSolidarios - 1);
        setLoading(false);
        const response = await sweetAlertConfirmed(
          "Obligado solidario eliminado correctamente"
        );
        if (response) {
          mostrarPestaniasAtrasPymePm(seccionEliminada);
        }
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al eliminar obligado solidario");
      }
    } else {
      setLoading(false);
      setCantidadObligadosSolidarios(cantidadObligadosSolidarios - 1);
      sweetAlertSuccess("Obligado solidario eliminado correctamente");
      mostrarPestaniasAtrasPymePm(seccionEliminada);
    }
  };

  const eliminarOSPfae = async (numOsEliminar) => {
    let idParticipante = null;
    let seccionEliminada = null;
    let conyugeOsEliminar = false;
    setLoading(true);
    switch (numOsEliminar) {
      case OS_UNO:
        if (datosOSUno?.datosOS?.length !== 0) {
          idParticipante = datosOSUno.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OBLIGADO_SOLIDARIO;
        break;
      case OS_DOS:
        if (datosOSDos?.datosOS?.length !== 0) {
          idParticipante = datosOSDos.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_DOS;
        break;
      case OS_TRES:
        if (datosOSTres?.datosOS?.length !== 0) {
          idParticipante = datosOSTres.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_TRES;
        break;
      case OS_CUATRO:
        if (datosOSCuatro?.datosOS?.length !== 0) {
          idParticipante = datosOSCuatro.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_CUATRO;
        break;
      case OS_CINCO:
        if (datosOSCinco?.datosOS?.length !== 0) {
          idParticipante = datosOSCinco.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_CINCO;
        break;
    }

    if (idParticipante !== null) {
      let dataOs = {
        idCliente: modeloPfae.idCliente,
        idParticipante: idParticipante,
        tipoDeParticipanteId: TIPO_PARTICIPANTE_OS,
        numeroParticipante: numOsEliminar,
      };

      const response = await api_eliminar_os_solicitud_pfae(dataOs);
      if (response.statusText === "OK") {
        switch (numOsEliminar) {
          case OS_UNO:
            if (
              datosOSUno?.conyuge !== null &&
              datosOSUno?.conyuge?.idConyuge !== null &&
              datosOSUno?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSUno({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_DOS:
            if (
              datosOSDos?.conyuge !== null &&
              datosOSDos?.conyuge?.idConyuge !== null &&
              datosOSDos?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSDos({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_TRES:
            if (
              datosOSTres?.conyuge !== null &&
              datosOSTres?.conyuge?.idConyuge !== null &&
              datosOSTres?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSTres({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_CUATRO:
            if (
              datosOSCuatro?.conyuge !== null &&
              datosOSCuatro?.conyuge?.idConyuge !== null &&
              datosOSCuatro?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSCuatro({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_CINCO:
            if (
              datosOSCinco?.conyuge !== null &&
              datosOSCinco?.conyuge?.idConyuge !== null &&
              datosOSCinco?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSCinco({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
        }
        if (conyugeOsEliminar) {
          setCantidadConyugeOS(cantidadConyugeOS - 1);
        }
        setCantidadObligadosSolidarios(cantidadObligadosSolidarios - 1);
        setLoading(false);
        const response = await sweetAlertConfirmed(
          "Obligado solidario eliminado correctamente"
        );

        if (response) {
          mostrarPestaniasAtrasPymePfae(seccionEliminada);
        }
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al eliminar obligado solidario");
      }
    } else {
      setLoading(false);
      setCantidadObligadosSolidarios(cantidadObligadosSolidarios - 1);
      sweetAlertSuccess("Obligado solidario eliminado correctamente");
      mostrarPestaniasAtrasPymePfae(seccionEliminada);
    }
  };

  return (
    <>
      <div ref={refScrollUp} className="pt-3 brian" />
      <Popup show={showResult}>
        <div className="bgc-gray-opacity popup--container">
          <div className="my-shadow radius bgc-white black popup--evaluacion_container__scroll">
            <center>
              {resultadoEvaluacionRiesgos.resultadoEvaluacion ===
              EVALUACION_APROBADO ? (
                <img src="/img/aprobado.png" className="evaluacion--logo" />
              ) : (
                <img src="/img/denegado.png" className="evaluacion--logo" />
              )}
              <br />
              <span className="evaluacion--resultado_titulo">
                Resultado de la evaluación:
              </span>{" "}
              <br />
              <span className="evaluacion--resultado_decreto">
                {resultadoEvaluacionRiesgos.resultadoEvaluacion ===
                EVALUACION_APROBADO
                  ? SOLICITUD_PRE_APROBADA
                  : SOLICITUD_NO_AUTORIZADA}
              </span>
              <br />
              {resultadoEvaluacionRiesgos.resultadoEvaluacion ===
              EVALUACION_APROBADO ? (
                <div className="evaluacion--texto_div">
                  <p className="evaluacion--texto_aclaracion">
                    En un lapsó no mayor a 5 días hábiles, serás contactado por
                    un ejecutivo para continuar con tu trámite. Para cualquier
                    aclaración por favor comunícate al 800 8902580 con tu folio{" "}
                    <span className="evaluacion--texto_folio">
                      {resultadoEvaluacionRiesgos.folioSolicitud}
                    </span>
                  </p>
                  <span className="evaluacion--texto_asterisco">
                    <p>
                      *La aprobación del crédito estará sujeta a la revisión del
                      resto de la documentación por parte del banco
                    </p>
                  </span>
                </div>
              ) : (
                <div className="evaluacion--texto_div">
                  <p className="evaluacion--texto_aclaracion">
                    Si tienes alguna duda sobre tu solicitud por favor
                    comunícate al 800 8902580 con tu folio{" "}
                    <span className="evaluacion--texto_folio">
                      {resultadoEvaluacionRiesgos.folioSolicitud}
                    </span>
                  </p>
                </div>
              )}
              <div className="evaluacion--button">
                <PrimaryButton
                  onClick={() => {
                    window.location.replace("/cotizador");
                  }}
                >
                  Siguiente
                </PrimaryButton>
              </div>
            </center>
          </div>
        </div>
        <div className="popup--shadow" />
      </Popup>

      <Popup show={showTarjeta}>
        <div className="bgc-gray-opacity popup--container">
          <img src={tarjeta} alt="" />
        </div>
        <div className="popup--shadow" onClick={() => setShowTarjeta(false)} />
      </Popup>

      <Popup show={loading}>
        <div className="bgc-gray-opacity popup--container">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <div className="popup--shadow" />
      </Popup>

      <Popup show={showTabla}>
        <div className="bgc-gray-opacity popup--container">
          <div className="my-shadow radius bgc-white black popup--container__scroll">
            <div className="d-flex justify-content-end gap-2">
              <img
                onClick={() => setShowTabla(false)}
                className="tbl-amortizacion--close"
                src={IconClose}
              ></img>
            </div>
            <h1>Tabla de amortización estimada</h1>
            <h4 className="text-start f-s-2">
              Calculada con una tasa de interés anual de referencia de 15.2%
            </h4>
            <hr />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Periodo</th>
                  <th scope="col">Saldo Capital</th>
                  <th scope="col">Intereses</th>
                  <th scope="col">Capital</th>
                  <th scope="col">Iva</th>
                  <th scope="col">Pago Total</th>
                </tr>
              </thead>
              <tbody>
                {tablaData.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <tr>
                        <td>{item.periodo}</td>
                        <td>{item.saldoCap}</td>
                        <td>{item.intereses}</td>
                        <td>{item.capital}</td>
                        <td>{item.iva}</td>
                        <td>{item.pagoTotal}</td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-end gap-2 my-3">
              <PrimaryButton
                onClick={() => {
                  setShowTabla(false);
                }}
              >
                Cerrar
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Popup>
      {idTipoPersona !== undefined && (
        <StepProgressBar
          subMenuSelected={subMenuSelected}
          setSubMenuSelected={setSubMenuSelected}
          setFormSection={setFormSection}
          setSeccion={setSeccion}
          ultimoPasoUsuario={ultimoPasoUsuario}
          conyugeOS={conyugeOS}
          idTipoPersona={idTipoPersona}
          actualizarPA={actualizarPA}
        />
      )}

      {formSection !== null && (
        <h1 className="text-center form--title">{formSection}</h1>
      )}

      <div className="container form-resumen--container">
        <div className="formulario-layout layout--form">
          <>
            {seccion !== SECCION_ZERO && (
              <div className="resumenCrediticio--container">
                <ResumenInformacionCIEC
                  plazo={plazo}
                  folioSolicitud={folioSolicitud}
                  seccion={seccion}
                />
                {seccion >= 23 && (
                  <div className="felicidades resumenCrediticio--felicidades" />
                )}
              </div>
            )}
            <div
              className={`${"formulario"} ${
                tipoPersona ? "outlined-formulario" : "non-outlined-formulario"
              } formulario-container`}
            >
              <FormMenu
                tipoPersona={tipoPersona}
                subMenuSelected={subMenuSelected}
                mostrarSeccionPfae={mostrarSeccionPfae}
                mostrarSeccionPm={mostrarSeccionPm}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                cantidadConyugeOS={cantidadConyugeOS}
                datosOSCuatro={datosOSCuatro}
                datosOSDos={datosOSDos}
                datosOSTres={datosOSTres}
                datosOSUno={datosOSUno}
                seccion={seccion}
                setSeccion={setSeccion}
                eliminarOSPfae={eliminarOSPfae}
                eliminarOSPm={eliminarOSPm}
                setSubMenuSelected={setSubMenuSelected}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                conyugeOS={conyugeOS}
                disabledBtnAgregarOs={disabledBtnAgregarOs}
                setDisabledBtnAgregarOs={setDisabledBtnAgregarOs}
              />
              {transitionZero(
                (style, visible) =>
                  visible && (
                    <animated.div style={style}>
                      <div>
                        <h2 className="f-s-4 text-center">
                          Un momento, estamos cargando tu información.
                        </h2>
                      </div>
                    </animated.div>
                  )
              )}
              {tipoPersona === "PM" && (
                <FormularioPersonaMoral
                  animated={animated}
                  transitionOne={transitionOne}
                  transitionTwo={transitionTwo}
                  transitionThree={transitionThree}
                  transitionFour={transitionFour}
                  transitionFive={transitionFive}
                  transitionSix={transitionSix}
                  transitionSeven={transitionSeven}
                  transitionEight={transitionEight}
                  transitionNine={transitionNine}
                  transitionTen={transitionTen}
                  transitionEleven={transitionEleven}
                  transitionTwelve={transitionTwelve}
                  transitionThirteen={transitionThirteen}
                  transitionFourtheen={transitionFourtheen}
                  transitionDiesiseis={transitionDiesiseis}
                  transitionDiesisiete={transitionDiesisiete}
                  transitionDiesiocho={transitionDiesiocho}
                  transitionDiecinueve={transitionDiecinueve}
                  transitionVeinte={transitionVeinte}
                  transitionVeintidos={transitionVeintidos}
                  transitionVeintitres={transitionVeintitres}
                  axiosData={axiosData}
                  modeloPm={modeloPm}
                  anios={anios}
                  cantidadSocios={cantidadSocios}
                  setCantidadSocios={setCantidadSocios}
                  aniosDomicilio={aniosDomicilio}
                  mesesDomicilo={mesesDomicilo}
                  handlePesos={handlePesos}
                  setShowResult={setShowResult}
                  tipoPersona={tipoPersona}
                  cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                  setCantidadObligadosSolidarios={
                    setCantidadObligadosSolidarios
                  }
                  setModeloPm={setModeloPm}
                  setTotalNominaMensual={setTotalNominaMensual}
                  setFolioSolicitud={setFolioSolicitud}
                  setStyleHr={setStyleHr}
                  setObligadosSolidariosInformacionBasica={
                    setObligadosSolidariosInformacionBasica
                  }
                  setImporteRentaFiscal={setImporteRentaFiscal}
                  setVentasAnuales={setVentasAnuales}
                  domicilioFiscalIgual={domicilioFiscalIgual}
                  setDomicilioFiscalIgual={setDomicilioFiscalIgual}
                  setRegresarCotizador={setRegresarCotizador}
                  regresarCotizador={regresarCotizador}
                  setLoading={setLoading}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  datosOSUno={datosOSUno}
                  setDatosOSUno={setDatosOSUno}
                  datosOSDos={datosOSDos}
                  setDatosOSDos={setDatosOSDos}
                  datosOSTres={datosOSTres}
                  setDatosOSTres={setDatosOSTres}
                  datosOSCuatro={datosOSCuatro}
                  setDatosOSCuatro={setDatosOSCuatro}
                  setCantidadConyugeOS={setCantidadConyugeOS}
                  cantidadConyugeOS={cantidadConyugeOS}
                  setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                  setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
                  setActualizarPA={setActualizarPA}
                  setFormSection={setFormSection}
                  ultimoPasoSolicitud={ultimoPasoSolicitud}
                />
              )}
              {tipoPersona === "PFAE" && (
                <FormularioPFAE
                  animated={animated}
                  importeRentaFiscal={importeRentaFiscal}
                  totalNominaMensual={totalNominaMensual}
                  transitionOne={transitionOne}
                  transitionTwo={transitionTwo}
                  transitionThree={transitionThree}
                  transitionFour={transitionFour}
                  transitionFive={transitionFive}
                  transitionEight={transitionEight}
                  transitionNine={transitionNine}
                  transitionTen={transitionTen}
                  transitionEleven={transitionEleven}
                  transitionTwelve={transitionTwelve}
                  transitionThirteen={transitionThirteen}
                  transitionFourtheen={transitionFourtheen}
                  transitionFivetheen={transitionFivetheen}
                  transitionDiesiseis={transitionDiesiseis}
                  transitionDiesisiete={transitionDiesisiete}
                  transitionDiesiocho={transitionDiesiocho}
                  transitionVeinte={transitionVeinte}
                  transitionVeintidos={transitionVeintidos}
                  modeloPfae={modeloPfae}
                  axiosData={axiosData}
                  aniosSector={aniosSector}
                  anios={anios}
                  aniosDomicilio={aniosDomicilio}
                  mesesDomicilo={mesesDomicilo}
                  setShowResult={setShowResult}
                  tipoPersona={tipoPersona}
                  cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                  setModeloPfae={setModeloPfae}
                  setObligadosSolidariosInformacionBasica={
                    setObligadosSolidariosInformacionBasica
                  }
                  setStyleHr={setStyleHr}
                  setFolioSolicitud={setFolioSolicitud}
                  domicilioFiscalIgual={domicilioFiscalIgual}
                  setDomicilioFiscalIgual={setDomicilioFiscalIgual}
                  setLoading={setLoading}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  datosOSUno={datosOSUno}
                  setDatosOSUno={setDatosOSUno}
                  datosOSDos={datosOSDos}
                  setDatosOSDos={setDatosOSDos}
                  datosOSTres={datosOSTres}
                  setDatosOSTres={setDatosOSTres}
                  datosOSCuatro={datosOSCuatro}
                  setDatosOSCuatro={setDatosOSCuatro}
                  datosOSCinco={datosOSCinco}
                  setDatosOSCinco={setDatosOSCinco}
                  setCantidadObligadosSolidarios={
                    setCantidadObligadosSolidarios
                  }
                  setCantidadConyugeOS={setCantidadConyugeOS}
                  cantidadConyugeOS={cantidadConyugeOS}
                  setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                  setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
                  setConyugeOS={setConyugeOS}
                  conyugeOS={conyugeOS}
                  setSeccion={setSeccion}
                  setFormSection={setFormSection}
                  ultimoPasoSolicitud={ultimoPasoSolicitud}
                />
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Formulario;
