import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";

import {
  ID_PM,
  SECCION_ECONOMICOS,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../../utils/constantes";
import { api_save_datos_clave_ciec_PM } from "../../../../api/api_servicios_rest_crediactivo";
import FormLayout from "../../../helpers/FormLayout/FormLayout";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import SelectWithErrorActividad from "../../../basics/SelectWithError/SelectWithErrorActividad";
import CheckBoxInput from "../../../basics/checkBox";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../basics/Buttons/Buttons";
import {
  validateValue,
  textToUpperCase,
  validarTextoConEnies,
} from "../../../../functions/fieldFunctions";
import { validarRFCTipoPersona } from "../../../../utils/validaciones";
import { pathAutorizacionBCHistorial } from "../../../../config/Path";
import { Link } from "react-router-dom";
import InputTextAreaWithError from "../../../basics/InputTextAreaWithError/InputTextAreaWithError";
import {
  sweetAlertError,
  sweetAlertWithConfirmedCrediactivo,
} from "../alertas/alertas";

const DatosCapturaClaveCIECPM = ({
  axiosData,
  modeloPm,
  setModeloPm,
  setStyleHr,
  setLoading,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setConyugeOS,
  setCantidadObligadosSolidarios,
  cantidadObligadosSolidarios,
}) => {
  const formik = useFormik({
    initialValues: initialValues(modeloPm, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosClaveCiecPM(formData).then((r) => setLoading(false));
    },
  });

  const salvarDatosClaveCiecPM = async (formData) => {
    try {
      const response = await api_save_datos_clave_ciec_PM(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPm({
            ...modeloPm,
            idCliente: data.clienteId,
            datosClaveCIEC: data.datosClaveCIEC,
          });
          setStyleHr("block");
          handleSeccionPyme(SECCION_ECONOMICOS);
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data.mensaje) {
            sweetAlertWithConfirmedCrediactivo(data?.mensaje);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al guardar datos clave ciec PFAE");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    var p = document.getElementById("accionCrediactivo");
    p.innerHTML = "Formulario";
  }, []);

  return (
    <>
      <form name="form-domicilio" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Captura clave CIEC</h2>
          <p className="p--texto_justify">
            Con tu facturación podemos diseñar una oferta a la medida de tu
            negocio.
          </p>
          <FormLayout>
            <div className="col-12">
              <h4 className="f-s-2">Cuéntanos sobre tu empresa </h4>
            </div>
            <InputWithError
              name="datosClaveCIEC.nombreEmpresa"
              label="Nombre de tu empresa *"
              value={formik.values?.datosClaveCIEC?.nombreEmpresa}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosClaveCIEC !== undefined &&
                  newErrors?.datosClaveCIEC?.nombreEmpresa
                ) {
                  delete newErrors?.datosClaveCIEC?.nombreEmpresa;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "datosClaveCIEC.nombreEmpresa",
                    e.target.value
                  );
                }
              }}
              type="text"
              InputProps={{ maxLength: 20 }}
              showErrorMessage={formik.errors?.datosClaveCIEC?.nombreEmpresa}
              errorMessage={formik.errors?.datosClaveCIEC?.nombreEmpresa}
            />
            <InputTextAreaWithError
              name="datosClaveCIEC.destinoCredito"
              label="Destino del crédito *"
              value={formik.values?.datosClaveCIEC?.destinoCredito}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosClaveCIEC !== undefined &&
                  newErrors?.datosClaveCIEC?.destinoCredito
                ) {
                  delete newErrors?.datosClaveCIEC?.destinoCredito;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "datosClaveCIEC.destinoCredito",
                    e.target.value
                  );
                }
              }}
              showErrorMessage={formik.errors?.datosClaveCIEC?.destinoCredito}
              errorMessage={formik.errors?.datosClaveCIEC?.destinoCredito}
            />
            <SelectWithErrorActividad
              menuPlacement="top"
              name="datosClaveCIEC.industriaId"
              label="Industria *"
              value={{
                label:
                  formik?.values?.datosClaveCIEC?.actividadObj?.[0]
                    ?.detalleClave,
                value:
                  formik?.values?.datosClaveCIEC?.actividadObj?.[0]
                    ?.detalleClave,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosClaveCIEC !== undefined &&
                  newErrors?.datosClaveCIEC.industriaId !== undefined
                ) {
                  delete newErrors?.datosClaveCIEC?.industriaId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("datosClaveCIEC.industriaId", e.id);
                formik.setFieldValue("datosClaveCIEC.actividadObj", [e]);
              }}
              options={axiosData?.domicilioFiscal?.actividadOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.detalleClave,
                    value: item.detalleClave,
                  };
                }
              )}
              showErrorMessage={formik.errors?.datosClaveCIEC?.industriaId}
              errorMessage={formik.errors?.datosClaveCIEC?.industriaId}
            />

            <div className="col-12">
              <h4 className="f-s-2">Cuéntanos sobre tu empresa </h4>
            </div>
            <InputWithError
              name="datosClaveCIEC.rfc"
              label="RFC con el que se factura *"
              value={formik.values?.datosClaveCIEC?.rfc}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosClaveCIEC !== undefined &&
                  newErrors?.datosClaveCIEC?.rfc
                ) {
                  delete newErrors?.datosClaveCIEC?.rfc;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);
                  formik.setFieldValue("datosClaveCIEC.rfc", newValue);
                }
              }}
              InputProps={{ maxLength: 12 }}
              showErrorMessage={formik.errors?.datosClaveCIEC?.rfc}
              errorMessage={formik.errors?.datosClaveCIEC?.rfc}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="datosClaveCIEC.aceptoConfidencialidad"
                  state=""
                  typeOfCheck="normal"
                  value={formik.values?.datosClaveCIEC?.aceptoConfidencialidad}
                  checked={
                    formik.values?.datosClaveCIEC?.aceptoConfidencialidad
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "datosClaveCIEC.aceptoConfidencialidad",
                      e.target.checked
                    )
                  }
                  id="datosClaveCIECAceptoConfidencialidad"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  className="form-check-label label-check-ciec"
                  htmlFor="datosClaveCIECAceptoConfidencialidad"
                >
                  Acepto términos y condiciones Banorte en específico el uso de
                  la CIEC para manifestar mi voluntad por medios electrónicos
                </label>
                <br />
                {formik.errors?.datosClaveCIEC?.aceptoConfidencialidad ? (
                  <span className="text_red">
                    {formik.errors?.datosClaveCIEC?.aceptoConfidencialidad}
                  </span>
                ) : null}
              </div>

              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="datosClaveCIEC.autorizacionConsulta"
                  state=""
                  typeOfCheck="normal"
                  value={formik.values?.datosClaveCIEC?.autorizacionConsulta}
                  checked={formik.values?.datosClaveCIEC?.autorizacionConsulta}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "datosClaveCIEC.autorizacionConsulta",
                      e.target.checked
                    )
                  }
                  id="autorizacionConsulta"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  className="form-check-label label-check-ciec"
                  htmlFor="autorizacionConsulta"
                >
                  Autorizo a Banco Mercantil del Norte, S.A., Institución de
                  Banca Múltiple, Grupo Financiero Banorte (Banorte) por única
                  ocasión el uso de la CIEC en el proceso de evaluación del
                  crédito con la única finalidad de validar la autenticidad de
                  la información. Lo anterior en el entendido que la CIEC no
                  será almacenada por Banorte ni será visible para ningún
                  empleado de la Institución.
                </label>{" "}
                <br />
                {formik.errors?.datosClaveCIEC?.autorizacionConsulta ? (
                  <span className="text_red">
                    {formik.errors?.datosClaveCIEC?.autorizacionConsulta}
                  </span>
                ) : null}
              </div>
            </div>
            <InputWithError
              name="datosClaveCIEC.contraseniaSat"
              label="Contraseña del SAT (CIEC) *"
              value={formik.values?.datosClaveCIEC?.contraseniaSat}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosClaveCIEC !== undefined &&
                  newErrors?.datosClaveCIEC?.contraseniaSat
                ) {
                  delete newErrors?.datosClaveCIEC?.contraseniaSat;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "datosClaveCIEC.contraseniaSat",
                    e.target.value
                  );
                }
              }}
              type="password"
              InputProps={{ maxLength: 20 }}
              showErrorMessage={formik.errors?.datosClaveCIEC?.contraseniaSat}
              errorMessage={formik.errors?.datosClaveCIEC?.contraseniaSat}
            />
            <InputWithError
              name="datosClaveCIEC.confirmacionContrasenia"
              label="Confirma tu contraseña CIEC *"
              value={formik.values?.datosClaveCIEC?.confirmacionContrasenia}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosClaveCIEC !== undefined &&
                  newErrors?.datosClaveCIEC?.confirmacionContrasenia
                ) {
                  delete newErrors?.datosClaveCIEC?.confirmacionContrasenia;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "datosClaveCIEC.confirmacionContrasenia",
                    e.target.value
                  );
                }
              }}
              type="password"
              InputProps={{ maxLength: 20 }}
              showErrorMessage={
                formik.errors?.datosClaveCIEC?.confirmacionContrasenia
              }
              errorMessage={
                formik.errors?.datosClaveCIEC?.confirmacionContrasenia
              }
            />
            {/*
            <span>¿No recuerdas tu contraseña?
              <Link to={pathAutorizacionBCHistorial}
                target="_blank" className="link">
                <span style={{ color: "#EB0029" }}> Olvidé mi contraseña</span>
              </Link>  
            </span>
            */}
            <div className="d-flex justify-content-center gap-3 mb-3">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(SECCION_ECONOMICOS);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit">Siguiente</PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default DatosCapturaClaveCIECPM;

function initialValues(modeloPm, axiosData) {
  let datosClaveCIEC = modeloPm.datosClaveCIEC;
  return {
    idCliente: modeloPm.idCliente,
    datosClaveCIEC: {
      idDatosClaveCIEC: datosClaveCIEC.idDatosClaveCIEC
        ? datosClaveCIEC.idDatosClaveCIEC
        : "",
      nombreEmpresa: datosClaveCIEC.nombreEmpresa
        ? datosClaveCIEC.nombreEmpresa
        : "",
      destinoCredito: datosClaveCIEC.destinoCredito
        ? datosClaveCIEC.destinoCredito
        : "",
      rfc: datosClaveCIEC.rfc ? datosClaveCIEC.rfc : "",
      contraseniaSat: datosClaveCIEC.contraseniaSat
        ? datosClaveCIEC.contraseniaSat
        : "",
      confirmacionContrasenia: datosClaveCIEC.contraseniaSat
        ? datosClaveCIEC.contraseniaSat
        : "",
      aceptoConfidencialidad: datosClaveCIEC.aceptoConfidencialidad
        ? datosClaveCIEC.aceptoConfidencialidad
        : false,
      autorizacionConsulta: datosClaveCIEC.autorizacionConsulta
        ? datosClaveCIEC.autorizacionConsulta
        : false,
      industriaId: datosClaveCIEC.industriaId ? datosClaveCIEC.industriaId : "",
      actividadObj: axiosData?.domicilioFiscal?.actividadOpciones.filter(
        (e) => e.id === datosClaveCIEC.industriaId
      ),
    },
  };
}

function validationSchema() {
  return {
    datosClaveCIEC: Yup.object().shape({
      nombreEmpresa: Yup.string().required("Dato requerido."),
      destinoCredito: Yup.string().required("Dato requerido."),
      industriaId: Yup.string().required("Dato requerido."),
      rfc: Yup.string()
        .required("Dato requerido.")
        .test("rfc", "RFC inválido.", (item, testContext) => {
          return testContext.parent.rfc !== undefined
            ? testContext.parent.rfc.trim().length > 0
              ? validarRFCTipoPersona(testContext.parent.rfc.trim(), ID_PM)
              : false
            : false;
        }),
      aceptoConfidencialidad: Yup.boolean().oneOf(
        [true],
        "Debe aceptar confidencialidad"
      ),
      autorizacionConsulta: Yup.boolean().oneOf(
        [true],
        "Debe autorizar consulta"
      ),
      contraseniaSat: Yup.string().required("Dato requerido."),
      confirmacionContrasenia: Yup.string()
        .required("Dato requerido.")
        .test(
          "confirmacionContrasenia",
          "Las contraseñas no son iguales",
          (item, testContext) => {
            var sonIguales = false;
            if (
              testContext.parent.contraseniaSat ==
              testContext.parent.confirmacionContrasenia
            ) {
              sonIguales = true;
            }
            return sonIguales;
          }
        ),
    }),
  };
}
