import React, { useEffect, useState } from "react";
import { ReactComponent as DownloadIcon } from "../../../icons/DownloadFecha.svg";
import { Link } from 'react-router-dom';
import { pathAvisoPrivacidadHistorial,  pathAutorizacionBCHistorial} from "../../../config/Path";

const HistorialCrediticioLinkFiles = ({ 
  modeloDatos
}) => {
  
  return (
    <>
      <div className="justify-content-center align-items-center gap-2 link--files-historial">
      <a
          href={`${pathAutorizacionBCHistorial}?idCliente=${modeloDatos?.idCliente}`}
          target="_blank"
          className="link"
          download
        >
          Autorización buró
        </a>
        
        <Link to={pathAvisoPrivacidadHistorial}
          target="_blank" className="link" download>
          <DownloadIcon /> <span style={{ color: "#EB0029" }}>Aviso privacidad</span>
        </Link>
      </div>
    </>
  );
};

export default HistorialCrediticioLinkFiles;
