import React, { useEffect, useState } from "react";
import LogoBanorte from "../../svg/LogoBanorte";
import { useNavigate, Link } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";
import "react-phone-input-2/lib/style.css";
import { animated, useTransition } from "react-spring";
import Popup from "../tools/Popup";
import Submenu from "../campos/components/Submenu/Submenu";
import IconClose from "./../../icons/crossIcon.png";
import {
  sessionStorageIdSolicitud,
  sessionStorageContinuarSolicitud,
  localStorageToken,
  localStorageRFC,
} from "../../config/Variables";
import { formatoTelefonoXX_XXXX_XXXX } from "../../utils/validaciones";
import {
  api_obtener_solicitud_existente,
  api_guardar_token_autoservicio,
  api_obtener_folio_solicitud_sms,
  api_obtener_folio_solicitud_llamada
} from "../../api/api_servicios_rest_crediactivo";
import "./Navbar.css";
import InputWithError from "../basics/InputWithError/InputWithError";
import { PrimaryButton, DisabledButton } from "../../components/basics/Buttons/Buttons";
import CrossIcon from "../../icons/crossIcon.png";

import { generarToken } from "../../utils/tokenAutoservicio";
import {
  sweetAlertError,
  sweetAlertWarning,
  sweetAlertInfoWithHtml,
  sweetAlertErrorWithHtml
} from "../campos/components/alertas/alertas";
import Swal from "sweetalert2";
import {
  validateValue
} from "../../functions/fieldFunctions";

const Navbar = ({ phone = true, phase = null, steps }) => {
  const [numeroCelular, setNumeroCelular] = useState("");
  const [showNavbar, setShowNavbar] = useState(false);
  const [loadingInicio, setLoadingInicio] = useState(false);
  const [showEnRevision, setShowEnRevision] = useState(false);
  const [showSolicitudes, setShowSolicitudes] = useState(false);
  const [folioSolicitud, setFolioSolicitud] = useState("");
  const [showPhone, setShowPhone] = useState(false);
  const [listaSolicitudes, setListaSolicitudes] = useState([]);
  const [tokenAutoservicio, setTokenAutoservicio] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showModal, setShowModal] = useState(false);
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [intentos, setIntentos] = useState(0);

  useEffect(() => {
    let interval;

    if (isButtonDisabled) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isButtonDisabled]);

  const navBarTransition = useTransition(showNavbar, {
    from: {
      opacity: "0",
      position: "absolute",
      zIndex: "1000",
      width: "100%",
      top: "0px",
    },
    enter: {
      opacity: "1",
      position: "absolute",
      zIndex: "1000",
      width: "100%",
      top: "0px",
    },
    leave: {
      opacity: "0",
      position: "absolute",
      zIndex: "1000",
      width: "100%",
      top: "0px",
    },
  });

  const obtenerSolicitudExistente = () => {
    setLoadingInicio(true);
    if (folioSolicitud.length == 7) {
      const data = {
        folioSolicitud: folioSolicitud,
        token: generarToken(),
      };
      setTokenAutoservicio(data.token);
      obtenerSolicitud(data);
    } else {
      sweetAlertWarning("Folio inválido");
      setLoadingInicio(false);
    }
  };

  const obtenerFolioConTelefono = () => {
    const numCelular = numeroCelular.split("-").join("");
    setLoadingInicio(true);
    if (numCelular.length == 10) {
      const data = {
        telefono: numCelular,
        token: generarToken(),
      };
      setTokenAutoservicio(data.token);
      obtenerSolicitudSms(data);
    } else {
      sweetAlertWarning("Número de celular inválido");
      setLoadingInicio(false);
    }
  };

  const obtenerFolioConTelefonoLlamada = () => {
    const numCelular = numeroCelular.split("-").join("");
    setLoadingInicio(true);
    if (numCelular.length == 10) {
      const data = {
        telefono: numCelular,
        token: generarToken(),
      };
      setTokenAutoservicio(data.token);
      obtenerSolicitudLlamada(data);
    } else {
      sweetAlertWarning("Número de celular inválido");
      setLoadingInicio(false);
    }
  };

  const obtenerSolicitud = async (data) => {
    try {
      const response = await api_obtener_solicitud_existente(data);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          if (data.continuarSolicitud) {
            sessionStorage.setItem(
              sessionStorageContinuarSolicitud,
              data.continuarSolicitud
            );

            if (data.listaSolicitudesCliente.length == 1) {
              const solicitud = data.listaSolicitudesCliente[0];
              sessionStorage.setItem(
                sessionStorageIdSolicitud,
                solicitud.idSolicitud
              );
              setFolioSolicitud(solicitud.solicitudFolio);

              const datosToken = {
                idSolicitud: solicitud.idSolicitud,
                tokenAutoservicio: tokenAutoservicio,
              };
              guardarTokenAutoservicio(datosToken);
            } else {
              setListaSolicitudes(data.listaSolicitudesCliente);
              setShowSolicitudes(true);
            }
          } else {
            setShowEnRevision(true);
            setNumeroCelular("");
          }
        } else {
          sweetAlertErrorWithHtml("<p>No se encuentra ninguna solicitud relacionada, por favor verifica el folio.<br>Si necesitas ayuda para recuperar tu folio, comunícate al 800 890 2580.<p/>");
        }
      } else {
        sweetAlertErrorWithHtml("<p>No se encuentra ninguna solicitud relacionada, por favor verifica el folio.<br>Si necesitas ayuda para recuperar tu folio, comunícate al 800 890 2580.<p/>");
        setLoadingInicio(false);
      }
      setLoadingInicio(false);
    } catch (error) {
      console.error(error);
      setLoadingInicio(false);
    }
  };

  const guardarTokenAutoservicio = async (data) => {
    try {
      const response = await api_guardar_token_autoservicio(data);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          localStorage.setItem(localStorageToken, data.tokenPymeAutoservicio);
          localStorage.setItem(localStorageRFC, data.rfcCliente);
          setShowPhone(false);
          setNumeroCelular("");
          navigate("/formularioCrediactivo");
        } else {
          sweetAlertError(data?.mensaje);
        }
      } else {
        sweetAlertError(response?.mensaje);
        setLoadingInicio(false);
      }
      setLoadingInicio(false);
    } catch (error) {
      console.error(error);
      setLoadingInicio(false);
    }
  };

  const verDetalleSolicitud = (idSolicitud, solicitudFolio) => {
    const datosToken = {
      idSolicitud: idSolicitud,
      tokenAutoservicio: tokenAutoservicio,
    };

    setShowSolicitudes(false);

    sessionStorage.setItem(sessionStorageIdSolicitud, idSolicitud);
    setFolioSolicitud(solicitudFolio);

    guardarTokenAutoservicio(datosToken);
  };

  const copyToClipBoard = (text) => {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  };

  const obtenerSolicitudSms = async (datosBusqueda) => {
    try {
      if (intentos >= 3) {
        // Si ya se superó el límite de intentos, no hacer nada
        return;
      }
      const response = await api_obtener_folio_solicitud_sms(datosBusqueda);
      console.log("response", response);
      if (response.statusText === "OK") {      
        const data = response.data;
        if (data.success) {
          // Deshabilita el botón
          setIsButtonDisabled(true);
          // Incrementa el contador de intentos
          setIntentos(intentos + 1);
          // Configura el contador en 60 segundos
          setCountdown(60);
          sweetAlertInfoWithHtml("<p>Tu folio fue enviado al número celular registrado.<br>Si no lo recibes o tienes alguna duda, comunícate al 800 890 2580.<p/>");
          // Habilita el botón después de 60 segundos
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 60000); // 60 segundos en milisegundos
        } else {
          sweetAlertErrorWithHtml("<p>No se encuentra ninguna solicitud relacionada, por favor verifica el número celular.<br>Si necesitas ayuda para recuperar tu folio, comunícate al 800 890 2580.<p/>");
        }
      } else {
        sweetAlertErrorWithHtml("<p>No se encuentra ninguna solicitud relacionada, por favor verifica el número celular.<br>Si necesitas ayuda para recuperar tu folio, comunícate al 800 890 2580.<p/>");
        setLoadingInicio(false);
      }
      setLoadingInicio(false);
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerSolicitudLlamada = async (datosBusqueda) => {
    try {
      if (intentos >= 3) {
        // Si ya se superó el límite de intentos, no hacer nada
        return;
      }
      const response = await api_obtener_folio_solicitud_llamada(datosBusqueda);
      console.log("response", response);
      if (response.statusText === "OK") {      
        const data = response.data;
        if (data.success) {
          // Deshabilita el botón
          setIsButtonDisabled(true);
          // Incrementa el contador de intentos
          setIntentos(intentos + 1);
          // Configura el contador en 60 segundos
          setCountdown(60);
          sweetAlertInfoWithHtml("<p>Has alcanzado los intentos permitidos.<br><br>En breve llamaremos al número registrado para decirte tu folio, por favor ten a la mano donde anotar.<br><br>Si tienes dudas, comunícate al 800 890 2580.<p/>");
          // Habilita el botón después de 60 segundos
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 60000); // 60 segundos en milisegundos
        } else {
          sweetAlertErrorWithHtml("<p>No se encuentra ninguna solicitud relacionada, por favor verifica el número celular.<br>Si necesitas ayuda para recuperar tu folio, comunícate al 800 890 2580.<p/>");
        }
      } else {
        sweetAlertErrorWithHtml("<p>No se encuentra ninguna solicitud relacionada, por favor verifica el número celular.<br>Si necesitas ayuda para recuperar tu folio, comunícate al 800 890 2580.<p/>");
        setLoadingInicio(false);
      }
      setLoadingInicio(false);
    } catch (error) {
      console.log(error);
    }
  };
  const sweetAlertInputRecuperarFolio = async () => {
    return await Swal.fire({
      title: "Recuperar Folio",
      showCancelButton: true,
      confirmButtonText: "Recuperar",
      cancelButtonText: "Cancelar",
      input: "text",
      inputPlaceholder: "Ingresa tu número de teléfono",
      inputValue: numeroCelular,
      inputAttributes: {
        maxlength: 10, // Limita la longitud del input a 10 caracteres
        type: "number", // Esto hace que el input solo acepte números
        step: "1", // Incremento/decremento en 1
        min: "0",   // Valor mínimo permitido
      },
      preConfirm: (value) => {
        // Realiza aquí la lógica para enviar el folio por SMS
        setLoadingInicio(true);
        if (value.length == 10) {
          const data = {
            telefono: value,
            token: generarToken(),
          };
          setTokenAutoservicio(data.token);
          obtenerSolicitudSms(data);
        } else {
          sweetAlertWarning("Numero inválido");
          setLoadingInicio(false);
        }
      },
      customClass: {
        confirmButton: "primary-button",
        cancelButton: "secondary-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Aquí puedes realizar acciones adicionales después de confirmar
        console.log(result);
      }
      
    })
  }

  return (
    <>
      {isMobile ? (
        <>
          <div
            className="my-navbar bgc-red d-flex justify-content-between align-items-center position-fixed"
            style={{
              zIndex: "1000",
              top: "0px",
            }}
          >
            <Link to={"/crediactivo"}>
              <LogoBanorte width={230} style={{ marginLeft: "12px" }} />
            </Link>
            <i
              onClick={() => setShowNavbar(!showNavbar)}
              className="ri-menu-line pointer"
              style={{ fontSize: "44px", marginRight: "12px" }}
            ></i>
            {navBarTransition(
              (style, visible) =>
                visible && (
                  <animated.div style={style}>
                    <div
                      className="bgc-red"
                      style={{
                        position: "absolute",
                        top: "63px",
                        zIndex: "1000",
                        width: "100%",
                        padding: "24px 0px",
                      }}
                    >
                      {steps < 3 && (
                        <div className="d-flex flex-column align-items-center">
                          <span className="pointer" style={{ margin: "4px" }}>
                            <a
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "17px",
                              }}
                              onClick={(e) => {
                                setShowPhone(true);
                              }}
                            >
                              YA TENGO UNA SOLICITUD
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                  </animated.div>
                )
            )}
          </div>
        </>
      ) : (
        <div className="my-navbar bgc-red">
          <div
            style={{
              height: "63px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 100px",
              width: "100%",
              margin: "0px auto",
            }}
          >
            <Link
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "12px",
              }}
              to={"/crediactivo"}
            >
              <LogoBanorte width={272} height={33} />
            </Link>
            {steps < 3 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "400",
                }}
              >
                <div style={{ marginRight: "16px" }}>
                  <span className="pointer" style={{ margin: "4px" }}>
                    <a
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: "17px",
                      }}
                      onClick={(e) => {
                        setShowPhone(true);
                      }}
                    >
                      YA TENGO UNA SOLICITUD
                    </a>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {(phase === 0 ||
        phase === 1 ||
        phase === 2 ||
        phase === 3 ||
        phase === 4) && <Submenu steps={steps} />}

      {phone === true && (phase === 1 || phase === 2) && showPhone === true ? (
        <div
          className="bgc-white"
          style={{
            padding: isMobile ? "6rem 0 2rem 0" : "1rem 0",
          }}
        >
          <div className="navbar--continuar-solicitud">
            <div className="navbar--continuar-solicitud--item">
              <span>¿Ya tienes tu solicitud en proceso?
                <br></br>
                    <a className="link"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPhoneInput(true);
                        }}
                      >
                        ¿Olvidaste tu folio?
                      </a>
              </span>
              {showPhoneInput ? (
                <>
                  <InputWithError
                    label="Número de teléfono"
                    name="numeroTelefono"
                    value={numeroCelular}
                    onChange={(e) => {
                      const numTelefono = formatoTelefonoXX_XXXX_XXXX(
                        e.target.value,
                        numeroCelular
                      );
                      setNumeroCelular(numTelefono);
                    }}
                    InputProps={{ maxLength: 12 }}
                  />
                  
                  {intentos >= 3 ? (
                    <DisabledButton>Recuperar {countdown}s</DisabledButton>
                  ) : (
                    isButtonDisabled ? (
                      <DisabledButton>Recuperar {countdown}s</DisabledButton>
                    ) : (
                      intentos === 2 ? (
                        <>
                        <PrimaryButton onClick={(e) => obtenerFolioConTelefonoLlamada()}>
                          Recuperar
                        </PrimaryButton>
                        </>
                      ) : (
                        <PrimaryButton onClick={(e) => obtenerFolioConTelefono()}>
                          Recuperar
                        </PrimaryButton>
                      )
                    )
                  )}
                  
                  <sup>
                    <img
                      style={{
                        objectFit: "cover",
                        width: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        zIndex: "200",
                        cursor: "pointer"
                      }}
                      src={CrossIcon}
                      onClick={() => {
                        setShowPhoneInput(false);
                      }}
                    ></img>
                  </sup>
                </> ) : (
                  <>
                    <InputWithError
                      label="Folio Solicitud"
                      name="folioSolicitud"
                      value={folioSolicitud}
                      onChange={(e) => {
                        if (validateValue(e.target.value, "alphanumeric")) {
                          const newValue = e.target.value;
                          setFolioSolicitud(newValue);
                        }
                      }}
                      InputProps={{ maxLength: 7 }}
                    />
                    <PrimaryButton onClick={(e) => obtenerSolicitudExistente()}>
                      Consultar
                    </PrimaryButton>
                    <sup>
                      <img
                        style={{
                          objectFit: "cover",
                          width: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                          zIndex: "200",
                          cursor: "pointer"
                        }}
                        src={CrossIcon}
                        onClick={() => {
                          setShowPhone(false);
                        }}
                      ></img>
                    </sup>
                  </> )}
            </div>
          </div>
        </div>
      ) : (
        isMobile && <div style={{ paddingTop: "20px" }} />
      )}
      {showModal && (
        sweetAlertInputRecuperarFolio(),
        setShowModal(false) // Cerrar el modal después de la confirmación o cancelación
      )}
      <Popup show={loadingInicio}>
        <div
          className="bgc-gray-opacity"
          style={{
            height: "100vh",
            width: "100%",
            display: "grid",
            placeContent: "center",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <div
          style={{
            top: "0",
            position: "absolute",
            height: "100vh",
            width: "100%",
            zIndex: "1",
          }}
        />
      </Popup>

      <Popup show={showEnRevision}>
        <div
          className="bgc-gray-opacity"
          style={{
            height: "100vh",
            width: "100%",
            display: "grid",
            placeContent: "center",
          }}
        >
          <div
            className="my-shadow radius bgc-white black"
            style={{
              padding: "24px",
              width: "80vw",
              maxWidth: "1300px",
              minWidth: "700px",
              zIndex: "2",
              maxHeight: "90vh",
              overflowY: "scroll",
              overflowX: "visible",
            }}
          >
            <div style={{ display: "flex", gap: "24px" }}>
              <div className="imgEnRevision" />
              <div style={{ width: "50%" }}>
                <h2 className="color-red text-center">EN REVISIÓN</h2>
                <br></br>
                <div className="revision--texto_div">
                  <h4 className="text-center mb-4">
                    Tu Solicitud se encuentra en Revisión.
                  </h4>
                  <h4 className="mb-4" style={{ fontSize: "15px" }}>
                    En un lapso no mayor a 5 días hábiles, serás contactado por
                    un ejecutivo para continuar con tu trámite.
                  </h4>
                  <p className="mt-3">
                    En caso de no ser contactado por favor comunícate al 800
                    8902580 con tu folio{" "}
                    <span className="color-red">{folioSolicitud}</span>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "12px",
                    marginBottom: "12px",
                  }}
                >
                  <button
                    className="bgc-red"
                    onClick={() => {
                      setShowEnRevision(false);
                    }}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => setShowEnRevision(false)}
          style={{
            top: "0",
            position: "absolute",
            height: "100vh",
            width: "100%",
            zIndex: "1",
          }}
        />
      </Popup>

      <Popup show={showSolicitudes}>
        <div
          className="bgc-gray-opacity"
          style={{
            height: "100vh",
            width: "100%",
            display: "grid",
            placeContent: "center",
          }}
        >
          <div
            className="my-shadow radius bgc-white black"
            style={{
              padding: "50px",
              width: "80vw",
              maxWidth: "800px",
              minWidth: "400px",
              zIndex: "2",
            }}
          >
            <div className="d-flex justify-content-end gap-2">
              <img
                onClick={() => setShowSolicitudes(false)}
                className="tbl-amortizacion--close"
                src={IconClose}
              ></img>
            </div>
            <h1>Solicitudes encontradas: </h1>
            <hr />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Folio</th>
                  <th scope="col">Estatus</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {listaSolicitudes.length == 2 && (
                  <>
                    <tr>
                      <td>{listaSolicitudes[0].solicitudFolio}</td>
                      <td>{listaSolicitudes[0].statusDeSolicitud}</td>
                      <td>
                        <PrimaryButton
                          onClick={(e) => {
                            verDetalleSolicitud(
                              listaSolicitudes[0].idSolicitud,
                              listaSolicitudes[0].solicitudFolio
                            );
                          }}
                        >
                          Ver detalle
                        </PrimaryButton>
                      </td>
                    </tr>
                    <tr>
                      <td>{listaSolicitudes[1].solicitudFolio}</td>
                      <td>{listaSolicitudes[1].statusDeSolicitud}</td>
                      <td>
                        <PrimaryButton
                          onClick={(e) => {
                            verDetalleSolicitud(
                              listaSolicitudes[1].idSolicitud,
                              listaSolicitudes[1].solicitudFolio
                            );
                          }}
                        >
                          Ver detalle
                        </PrimaryButton>
                      </td>
                    </tr>
                  </>
                )}
                {listaSolicitudes.length == 3 && (
                  <>
                    <tr>
                      <td>{listaSolicitudes[0].solicitudFolio}</td>
                      <td>{listaSolicitudes[0].statusDeSolicitud}</td>
                      <td>
                        <PrimaryButton
                          onClick={(e) => {
                            verDetalleSolicitud(
                              listaSolicitudes[0].idSolicitud,
                              listaSolicitudes[0].solicitudFolio
                            );
                          }}
                        >
                          Ver detalle
                        </PrimaryButton>
                      </td>
                    </tr>
                    <tr>
                      <td>{listaSolicitudes[1].solicitudFolio}</td>
                      <td>{listaSolicitudes[1].statusDeSolicitud}</td>
                      <td>
                        <PrimaryButton
                          onClick={(e) => {
                            verDetalleSolicitud(
                              listaSolicitudes[1].idSolicitud,
                              listaSolicitudes[1].solicitudFolio
                            );
                          }}
                        >
                          Ver detalle
                        </PrimaryButton>
                      </td>
                    </tr>
                    <tr>
                      <td>{listaSolicitudes[2].solicitudFolio}</td>
                      <td>{listaSolicitudes[2].statusDeSolicitud}</td>
                      <td>
                        <PrimaryButton
                          onClick={(e) => {
                            verDetalleSolicitud(
                              listaSolicitudes[2].idSolicitud,
                              listaSolicitudes[2].solicitudFolio
                            );
                          }}
                        >
                          Ver detalle
                        </PrimaryButton>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default Navbar;
