import React, { useState, useEffect } from "react";

import { ID_PF, ID_PFAE } from "../../../../../utils/constantes";
import RadioInput from "../../../../basics/radioButton";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import DatosPrincipalAccionistaPM from "./DatosPrincipalAccionistaPM";

const PrincipalAccionistaPM = ({
  modeloPm,
  setModeloPm,
  setObligadosSolidariosInformacionBasica,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  axiosData,
  mesesDomicilo,
  aniosDomicilio,
  anios,
  Swal,
  setLoading,
  webView,
}) => {
  const [principalAccionista, setPrincipalAccionista] = useState({
    tipoPersona: "",
    idTipoPersona: null,
  });

  useEffect(() => {
    if (modeloPm.principalAccionista.generales) {
      if (
        modeloPm.principalAccionista.generales.tipoPersonaId !== undefined &&
        modeloPm.principalAccionista.generales.tipoPersonaId !== null
      ) {
        if (modeloPm.principalAccionista.generales.tipoPersonaId == ID_PFAE) {
          setPrincipalAccionista({
            tipoPersona: "PFAE",
            idTipoPersona: modeloPm.principalAccionista.generales.tipoPersonaId,
          });
        } else {
          setPrincipalAccionista({
            tipoPersona: "PF",
            idTipoPersona: modeloPm.principalAccionista.generales.tipoPersonaId,
          });
        }
      }
    }
  }, []);

  const personTypes = [
    {
      title: "Persona física",
      rfc: 13,
      id: ID_PF,
      icon: "ri-user-fill",
      type: "PF",
    },
    {
      title: "Persona física con actividad empresarial",
      rfc: 13,
      id: ID_PFAE,
      icon: "ri-user-2-fill",
      type: "PFAE",
    },
  ];

  return (
    <>
      <div className="container">
        <h2 className="f-s-5 text-center my-4">
          Principal accionista
          <div className="input__tooltip_titulo top">
            <AiOutlineQuestionCircle className="input__tooltip_titulo" />
            <span className="tiptext">
              En caso de que se tengan dos socios con el mismo porcentaje
              accionario, deberás registrar aquí al que fungirá como aval
            </span>
          </div>
        </h2>
        <p className="p--texto_justify">
          En esta sección deberás agregar la información de la persona que será
          tu aval para el crédito. Esta persona debe de ser diferente al
          solicitante, y en caso de que el solicitante no tenga un bien inmueble
          que respalde el crédito, el obligado solidario deberá presentarlo
        </p>
        <h3 className="f-s-3 text-center my-4">Tipo de persona</h3>
        <div className="d-flex justify-content-center gap-3">
          {personTypes.map((person, index) => {
            return (
              <div
                className="d-flex flex-column align-items-center"
                key={index}
              >
                <RadioInput
                  style={{ padding: "0" }}
                  checked={principalAccionista.tipoPersona === person.type}
                  onChange={() => {
                    setPrincipalAccionista({
                      tipoPersona: person.type,
                      idTipoPersona: person.id,
                    });
                  }}
                />
                <label className="text_lightGray">{person.title}</label>
              </div>
            );
          })}
        </div>
        {principalAccionista.idTipoPersona && (
          <DatosPrincipalAccionistaPM
            modeloPm={modeloPm}
            setModeloPm={setModeloPm}
            axiosData={axiosData}
            mesesDomicilo={mesesDomicilo}
            aniosDomicilio={aniosDomicilio}
            anios={anios}
            Swal={Swal}
            idTipoPersonaPA={principalAccionista.idTipoPersona}
            setStyleHr={setStyleHr}
            setObligadosSolidariosInformacionBasica={
              setObligadosSolidariosInformacionBasica
            }
            setLoading={setLoading}
            handleSeccionPyme={handleSeccionPyme}
            handleRegresarSeccionPyme={handleRegresarSeccionPyme}
            webView={webView}
          />
        )}
      </div>
    </>
  );
};

export default PrincipalAccionistaPM;
