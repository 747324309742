import { api_avanzar_historial_crediticio } from "../../api/api_servicios_rest";
import {
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  OS_MAX_PFAE,
  PASO_SOLICITUD_HISTORIAL_CREDITICIO,
  PESTANIA_SOLICITANTE_CONYUGE,
  SECCION_CONYUGE_OS_CUATRO,
  SECCION_CONYUGE_OS_DOS,
  SECCION_CONYUGE_OS_TRES,
  SECCION_CONYUGE_OS_UNO,
  SECCION_DOMICILIO,
  SECCION_DOMICILIO_COMERCIAL,
  SECCION_DOMICILIO_FISCAL,
  SECCION_ECONOMICOS,
  SECCION_GENERALES,
  SECCION_HISTORIAL_CREDITICIO,
  SECCION_OBLIGADO_SOLIDARIO,
  SECCION_OS_CINCO,
  SECCION_OS_CUATRO,
  SECCION_OS_DOS,
  SECCION_OS_TRES,
  SECCION_SOLICITANTE_CONYUGE,
  SECCION_ZERO,
  SUB_MENU_CADENA_GRALES,
  SUB_MENU_CADENA_HISTORIAL,
  SUB_MENU_CADENA_OS,
} from "../../utils/constantes";
import {
  sweetAlertObligadoSolidario,
  sweetAlertWarning,
} from "../helpers/alertas/alertas";

export const mostrarPestaniasPymePfaeHelper = async(
  seccionActual,
  setAvanzarSolicitud,
  modeloPfae,
  setSeccion,
  setSubMenuSelected,
  setFormSection,
  setMostrarSeccionPfae,
  mostrarSeccionPfae,
  setUltimoPasoUsuario,
  ultimoPasoUsuario,
  setDisabledBtnAgregarOs,
  datosOSUno,
  cantidadObligadosSolidarios,
  setCantidadObligadosSolidarios,
  cantidadConyugeOS,
  setLoading,
  ultimoPasoSolicitud,
  datosOSDos,
  datosOSTres,
  datosOSCuatro,
  datosOSCinco,
  setUltimoPasoSolicitud
) => {
  setAvanzarSolicitud(true);

  let idSolicitud = modeloPfae.idSolicitud;

  switch (seccionActual) {
    case SECCION_ZERO:
      setSeccion(SECCION_GENERALES);
      setSubMenuSelected(SUB_MENU_CADENA_GRALES);
      setFormSection("Generales");
      setMostrarSeccionPfae({
        ...mostrarSeccionPfae,
        general: true,
      });
      break;
    case SECCION_GENERALES:
      if (ultimoPasoUsuario === null) {
        setUltimoPasoUsuario(SECCION_GENERALES);
      }

      setSeccion(SECCION_DOMICILIO);
      setMostrarSeccionPfae({
        ...mostrarSeccionPfae,
        domicilio: true,
      });
      break;
    case SECCION_DOMICILIO:
      setSeccion(SECCION_DOMICILIO_FISCAL);
      setMostrarSeccionPfae({
        ...mostrarSeccionPfae,
        domicilioFiscal: true,
      });
      break;
    case SECCION_DOMICILIO_FISCAL:
      setSeccion(SECCION_DOMICILIO_COMERCIAL);
      setMostrarSeccionPfae({
        ...mostrarSeccionPfae,
        domicilioComercial: true,
      });
      break;
    case SECCION_DOMICILIO_COMERCIAL:
      setSeccion(SECCION_ECONOMICOS);
      setMostrarSeccionPfae({
        ...mostrarSeccionPfae,
        economicos: true,
      });
      break;
    case SECCION_ECONOMICOS:
      if (
        parseInt(modeloPfae.generales.estadoCivilId) ===
        ESTADO_CIVIL_SOCIEDAD_CONYUGAL
      ) {
        setSeccion(PESTANIA_SOLICITANTE_CONYUGE);

        if (
          (modeloPfae?.conyuge !== undefined &&
            modeloPfae?.conyuge &&
            modeloPfae?.conyuge?.idConyuge !== null &&
            modeloPfae?.conyuge?.generales?.esObligadoSolidario) ||
          modeloPfae?.conyuge?.generales?.esObligadoSolidario === true
        ) {
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          setFormSection("Obligados Solidarios");

          if (
            modeloPfae?.conyuge !== undefined &&
            modeloPfae?.conyuge &&
            modeloPfae?.conyuge?.idConyuge !== null
          ) {
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
              setDisabledBtnAgregarOs(false);
            }
          } else {
            setDisabledBtnAgregarOs(true);
          }
        } else {
          setSubMenuSelected(SUB_MENU_CADENA_GRALES);
          setFormSection("Generales");
        }
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          solicitanteConyuge: true,
        });
      } else {
        setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          obligadosSolidario: true,
        });

        if (datosOSUno.datosOS.length === 0) {
          setDisabledBtnAgregarOs(true);
        } else {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      }
      break;
    case SECCION_SOLICITANTE_CONYUGE:
      if (
        ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
        ultimoPasoUsuario === SECCION_GENERALES
      ) {
        setUltimoPasoUsuario(SECCION_SOLICITANTE_CONYUGE);
      }
      if (modeloPfae?.conyuge?.generales?.esObligadoSolidario) {
        if (datosOSUno?.datosOS?.length === 0) {
          const response = await sweetAlertObligadoSolidario();
          if (response) {
            setSeccion(SECCION_OBLIGADO_SOLIDARIO);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
            setFormSection("Obligados Solidarios");
            setMostrarSeccionPfae({
              ...mostrarSeccionPfae,
              obligadosSolidario: true,
            });
            setDisabledBtnAgregarOs(true);
          } else {
            setLoading(true);
            setDisabledBtnAgregarOs(false);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                (response) => {
                  if (response.success) {
                    setAvanzarSolicitud(true);
                    setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                    setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                    setFormSection("Historial Crediticio");
                    setMostrarSeccionPfae({
                      ...mostrarSeccionPfae,
                      historialCrediticio: true,
                    });
                    setLoading(false);
                  } else {
                    setLoading(false);
                    sweetAlertWarning(
                      "Ocurrió un error al avanzar a historial crediticio"
                    );
                  }
                }
              );
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          }
        } else {
          setSeccion(SECCION_OBLIGADO_SOLIDARIO);
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          setFormSection("Obligados Solidarios");
          setMostrarSeccionPfae({
            ...mostrarSeccionPfae,
            obligadosSolidario: true,
          });
          mostrarSeccionPfae.obligadosSolidario = true;
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      } else {
        setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        setFormSection("Obligados Solidarios");
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          obligadosSolidario: true,
        });
        if (datosOSUno?.datosOS?.length === 0) {
          setDisabledBtnAgregarOs(true);
        } else {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      }
      break;
    case SECCION_OBLIGADO_SOLIDARIO:
      if (
        ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
        (ultimoPasoUsuario === SECCION_GENERALES ||
          ultimoPasoUsuario === SECCION_SOLICITANTE_CONYUGE)
      ) {
        setUltimoPasoUsuario(SECCION_OBLIGADO_SOLIDARIO);
      }
      if (
        parseInt(datosOSUno.idEstadoCivilOs) === ESTADO_CIVIL_SOCIEDAD_CONYUGAL
      ) {
        setSeccion(SECCION_CONYUGE_OS_UNO);
        if (datosOSUno?.conyuge?.length === 0) {
          setDisabledBtnAgregarOs(true);
        } else {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      } else {
        if (datosOSDos?.datosOS?.length === 0) {
          const response = await sweetAlertObligadoSolidario();
          if (response) {
            setSeccion(SECCION_OS_DOS);
            setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
            setDisabledBtnAgregarOs(true);
          } else {
            setLoading(true);
            setDisabledBtnAgregarOs(false);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                (response) => {
                  if (response.success) {
                    setAvanzarSolicitud(true);
                    setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                    setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                    setFormSection("Historial Crediticio");
                    setMostrarSeccionPfae({
                      ...mostrarSeccionPfae,
                      historialCrediticio: true,
                    });
                    setLoading(false);
                  } else {
                    setLoading(false);
                    sweetAlertWarning(
                      "Ocurrió un error al avanzar a historial crediticio"
                    );
                  }
                }
              );
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          }
        } else {
          setSeccion(SECCION_OS_DOS);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      }
      setSubMenuSelected(SUB_MENU_CADENA_OS);
      break;
    case SECCION_OS_DOS:
      if (
        parseInt(datosOSDos.idEstadoCivilOs) === ESTADO_CIVIL_SOCIEDAD_CONYUGAL
      ) {
        setSeccion(SECCION_CONYUGE_OS_DOS);
        if (datosOSDos.conyuge && datosOSDos.conyuge.length === 0) {
          setDisabledBtnAgregarOs(true);
        } else {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      } else {
        if (datosOSTres.datosOS.length === 0) {
          const response = await sweetAlertObligadoSolidario();
          if (response) {
            setSeccion(SECCION_OS_TRES);
            setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
            setDisabledBtnAgregarOs(true);
          } else {
            setLoading(true);
            setDisabledBtnAgregarOs(false);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                (response) => {
                  if (response.success) {
                    setAvanzarSolicitud(true);
                    setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                    setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                    setFormSection("Historial Crediticio");
                    setMostrarSeccionPfae({
                      ...mostrarSeccionPfae,
                      historialCrediticio: true,
                    });
                    setLoading(false);
                  } else {
                    setLoading(false);
                    sweetAlertWarning(
                      "Ocurrió un error al avanzar a historial crediticio"
                    );
                  }
                }
              );
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          }
        } else {
          setSeccion(SECCION_OS_TRES);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      }
      setSubMenuSelected(SUB_MENU_CADENA_OS);
      break;
    case SECCION_OS_TRES:
      if (
        parseInt(datosOSTres.idEstadoCivilOs) === ESTADO_CIVIL_SOCIEDAD_CONYUGAL
      ) {
        setSeccion(SECCION_CONYUGE_OS_TRES);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        if (datosOSTres.conyuge.length === 0) {
          setDisabledBtnAgregarOs(true);
        } else {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      } else {
        if (datosOSCuatro.datosOS.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                (response) => {
                  if (response.success) {
                    setAvanzarSolicitud(true);
                    setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                    setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                    setFormSection("Historial Crediticio");
                    setMostrarSeccionPfae({
                      ...mostrarSeccionPfae,
                      historialCrediticio: true,
                    });
                    setLoading(false);
                  } else {
                    setLoading(false);
                    sweetAlertWarning(
                      "Ocurrió un error al avanzar a historial crediticio"
                    );
                  }
                }
              );
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_CUATRO);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                //cambiarEstatusHistorialCredicio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_CUATRO);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      }
      break;
    case SECCION_OS_CUATRO:
      if (
        parseInt(datosOSCuatro.idEstadoCivilOs) ===
        ESTADO_CIVIL_SOCIEDAD_CONYUGAL
      ) {
        setSeccion(SECCION_CONYUGE_OS_CUATRO);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        if (datosOSCuatro.conyuge.length === 0) {
          setDisabledBtnAgregarOs(true);
        } else {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
            setDisabledBtnAgregarOs(false);
          }
        }
      } else {
        if (datosOSCinco.datosOS.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                (response) => {
                  if (response.success) {
                    setAvanzarSolicitud(true);
                    setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                    setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                    setFormSection("Historial Crediticio");
                    setMostrarSeccionPfae({
                      ...mostrarSeccionPfae,
                      historialCrediticio: true,
                    });
                    setLoading(false);
                  } else {
                    setLoading(false);
                    sweetAlertWarning(
                      "Ocurrió un error al avanzar a historial crediticio"
                    );
                  }
                }
              );
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = await sweetAlertObligadoSolidario();
            if (response) {
              setSeccion(SECCION_OS_CINCO);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            } else {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                  (response) => {
                    if (response.success) {
                      setAvanzarSolicitud(true);
                      setUltimoPasoSolicitud(
                        PASO_SOLICITUD_HISTORIAL_CREDITICIO
                      );
                      setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                      setSeccion(SECCION_HISTORIAL_CREDITICIO);
                      setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                      setFormSection("Historial Crediticio");
                      setMostrarSeccionPfae({
                        ...mostrarSeccionPfae,
                        historialCrediticio: true,
                      });
                      setLoading(false);
                    } else {
                      setLoading(false);
                      sweetAlertWarning(
                        "Ocurrió un error al avanzar a historial crediticio"
                      );
                    }
                  }
                );
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_CINCO);
        }
      }
      break;
    case SECCION_OS_CINCO:
      setLoading(true);
      if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
        cambiarEstatusHistorialCredicioHelper(idSolicitud).then((response) => {
          if (response.success) {
            setAvanzarSolicitud(true);
            setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
            setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
            setSeccion(SECCION_HISTORIAL_CREDITICIO);
            setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
            setFormSection("Historial Crediticio");
            setMostrarSeccionPfae({
              ...mostrarSeccionPfae,
              historialCrediticio: true,
            });
            setLoading(false);
          } else {
            setLoading(false);
            sweetAlertWarning(
              "Ocurrió un error al avanzar a historial crediticio"
            );
          }
        });
      } else {
        setSeccion(SECCION_HISTORIAL_CREDITICIO);
        setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
        setFormSection("Historial Crediticio");
        setMostrarSeccionPfae({
          ...mostrarSeccionPfae,
          historialCrediticio: true,
        });
        setLoading(false);
      }
      break;
    case SECCION_CONYUGE_OS_UNO:
      if (datosOSDos.datosOS.length === 0) {
        if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
          setLoading(true);
          if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
            cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
              (response) => {
                if (response.success) {
                  setAvanzarSolicitud(true);
                  setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                  setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                  setFormSection("Historial Crediticio");
                  setMostrarSeccionPfae({
                    ...mostrarSeccionPfae,
                    historialCrediticio: true,
                  });
                  setLoading(false);
                } else {
                  setLoading(false);
                  sweetAlertWarning(
                    "Ocurrió un error al avanzar a historial crediticio"
                  );
                }
              }
            );
          } else {
            setSeccion(SECCION_HISTORIAL_CREDITICIO);
            setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
            setFormSection("Historial Crediticio");
            setMostrarSeccionPfae({
              ...mostrarSeccionPfae,
              historialCrediticio: true,
            });
            setLoading(false);
          }
        } else {
          const response = await sweetAlertObligadoSolidario();
          if (response) {
            setSeccion(SECCION_OS_DOS);
            setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
            setDisabledBtnAgregarOs(true);
          } else {
            setLoading(true);
            setDisabledBtnAgregarOs(false);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                (response) => {
                  if (response.success) {
                    setAvanzarSolicitud(true);
                    setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                    setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                    setFormSection("Historial Crediticio");
                    setMostrarSeccionPfae({
                      ...mostrarSeccionPfae,
                      historialCrediticio: true,
                    });
                    setLoading(false);
                  } else {
                    setLoading(false);
                    sweetAlertWarning(
                      "Ocurrió un error al avanzar a historial crediticio"
                    );
                  }
                }
              );
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          }
        }
      } else {
        setSeccion(SECCION_OS_DOS);
        if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
          setDisabledBtnAgregarOs(false);
        }
      }
      break;
    case SECCION_CONYUGE_OS_DOS:
      if (datosOSTres?.datosOS?.length === 0) {
        if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
          setLoading(true);
          if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
            cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
              (response) => {
                if (response.success) {
                  setAvanzarSolicitud(true);
                  setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                  setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                  setFormSection("Historial Crediticio");
                  setMostrarSeccionPfae({
                    ...mostrarSeccionPfae,
                    historialCrediticio: true,
                  });
                  setLoading(false);
                } else {
                  setLoading(false);
                  sweetAlertWarning(
                    "Ocurrió un error al avanzar a historial crediticio"
                  );
                }
              }
            );
          } else {
            setSeccion(SECCION_HISTORIAL_CREDITICIO);
            setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
            setFormSection("Historial Crediticio");
            setMostrarSeccionPfae({
              ...mostrarSeccionPfae,
              historialCrediticio: true,
            });
            setLoading(false);
          }
        } else {
          const response = await sweetAlertObligadoSolidario();
          if (response) {
            setSeccion(SECCION_OS_TRES);
            setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
            setDisabledBtnAgregarOs(true);
          } else {
            setLoading(true);
            setDisabledBtnAgregarOs(false);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                (response) => {
                  if (response.success) {
                    setAvanzarSolicitud(true);
                    setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                    setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                    setFormSection("Historial Crediticio");
                    setMostrarSeccionPfae({
                      ...mostrarSeccionPfae,
                      historialCrediticio: true,
                    });
                    setLoading(false);
                  } else {
                    setLoading(false);
                    sweetAlertWarning(
                      "Ocurrió un error al avanzar a historial crediticio"
                    );
                  }
                }
              );
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          }
        }
      } else {
        setSeccion(SECCION_OS_TRES);
        if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
          setDisabledBtnAgregarOs(false);
        }
      }
      break;
    case SECCION_CONYUGE_OS_TRES:
      if (datosOSCuatro.datosOS.length === 0) {
        if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
          setLoading(true);
          if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
            cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
              (response) => {
                if (response.success) {
                  setAvanzarSolicitud(true);
                  setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                  setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                  setFormSection("Historial Crediticio");
                  setMostrarSeccionPfae({
                    ...mostrarSeccionPfae,
                    historialCrediticio: true,
                  });
                  setLoading(false);
                } else {
                  setLoading(false);
                  sweetAlertWarning(
                    "Ocurrió un error al avanzar a historial crediticio"
                  );
                }
              }
            );
          } else {
            setSeccion(SECCION_HISTORIAL_CREDITICIO);
            setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
            setFormSection("Historial Crediticio");
            setMostrarSeccionPfae({
              ...mostrarSeccionPfae,
              historialCrediticio: true,
            });
            setLoading(false);
          }
        } else {
          const response = await sweetAlertObligadoSolidario();

          if (response) {
            setSeccion(SECCION_OS_CUATRO);
            setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
            setDisabledBtnAgregarOs(true);
          } else {
            setLoading(true);
            setDisabledBtnAgregarOs(false);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                (response) => {
                  if (response.success) {
                    setAvanzarSolicitud(true);
                    setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                    setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                    setFormSection("Historial Crediticio");
                    setMostrarSeccionPfae({
                      ...mostrarSeccionPfae,
                      historialCrediticio: true,
                    });
                    setLoading(false);
                  } else {
                    setLoading(false);
                    sweetAlertWarning(
                      "Ocurrió un error al avanzar a historial crediticio"
                    );
                  }
                }
              );
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          }
        }
      } else {
        setSeccion(SECCION_OS_CUATRO);
        if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE) {
          setDisabledBtnAgregarOs(false);
        }
      }
      break;
    case SECCION_CONYUGE_OS_CUATRO:
      if (datosOSCinco.datosOS.length === 0) {
        if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PFAE) {
          setLoading(true);
          if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
            cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
              (response) => {
                if (response.success) {
                  setAvanzarSolicitud(true);
                  setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                  setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                  setFormSection("Historial Crediticio");
                  setMostrarSeccionPfae({
                    ...mostrarSeccionPfae,
                    historialCrediticio: true,
                  });
                  setLoading(false);
                } else {
                  setLoading(false);
                  sweetAlertWarning(
                    "Ocurrió un error al avanzar a historial crediticio"
                  );
                }
              }
            );
          } else {
            setSeccion(SECCION_HISTORIAL_CREDITICIO);
            setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
            setFormSection("Historial Crediticio");
            setMostrarSeccionPfae({
              ...mostrarSeccionPfae,
              historialCrediticio: true,
            });
            setLoading(false);
          }
        } else {
          const response = await sweetAlertObligadoSolidario();
          if (response) {
            setSeccion(SECCION_OS_CINCO);
            setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
            setDisabledBtnAgregarOs(true);
          } else {
            setLoading(true);
            setDisabledBtnAgregarOs(false);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCredicioHelper(idSolicitud).then(
                (response) => {
                  if (response.success) {
                    setAvanzarSolicitud(true);
                    setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
                    setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                    setFormSection("Historial Crediticio");
                    setMostrarSeccionPfae({
                      ...mostrarSeccionPfae,
                      historialCrediticio: true,
                    });
                    setLoading(false);
                  } else {
                    setLoading(false);
                    sweetAlertWarning(
                      "Ocurrió un error al avanzar a historial crediticio"
                    );
                  }
                }
              );
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          }
        }
      } else {
        setSeccion(SECCION_OS_CINCO);
      }
      break;
    default:
      setSeccion(ultimoPasoUsuario ? ultimoPasoUsuario : SECCION_GENERALES);
      break;
  }
};

export const cambiarEstatusHistorialCredicioHelper = async (idSolicitud) => {
  
  try {
    const response = await api_avanzar_historial_crediticio(idSolicitud);
  if (response.statusText === "OK") {
    const data = response.data;
    if (data.success) {
      return data;
    }
    return null;
  }
  } catch (error) {
    console.log(error);
    
  }
};


    export  const bancosTransitionConfig = {
      config: {
        // duration: 10000
      },
      from: {
        opacity: 0,
        position: "relative",
        width: "100%",
        transform: "translateX(-100%)",
        pointerEvents: "all",
      },
      enter: {
        opacity: 1,
        position: "relative",
        width: "100%",
        transform: "translateX(0%)",
        pointerEvents: "all",
      },
      leave: {
        opacity: 0,
        position: "relative",
        width: "100%",
        transform: "translateX(100%)",
        pointerEvents: "none",
      },
    };