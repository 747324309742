import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "moment/locale/es";
import "react-datetime/css/react-datetime.css";
import {
  NACIONALIDAD_MEXICAN0,
  INE_CERTIFICADA,
  APLICA_INE_COMPROBANTE_OS_NO,
  TIPO_VIVIENDA_EDIFICIO,
  TIPO_DEPARTAMENTO_APARTAMENTO,
  ID_CALCULO_RFC_NOMBRE,
  ID_CALCULO_RFC_APP,
  ID_CALCULO_RFC_APM,
  ID_CALCULO_RFC_FECHANAC,
  ID_CALCULO_RFC_GENERO,
  ID_CALCULO_RFC_ESTADONAC,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  ID_PAIS_MEXICO,
  ID_PFAE,
} from "../../../../../utils/constantes";
import {
  api_direccion_by_cp,
  api_save_os_pfae_pm,
} from "../../../../../api/api_servicios_rest";
import {
  dependeintesMajor23,
  fechaActualYYYMMDD,
  fechaMaxMayorEdadYYYMMDD,
  fechaMinEdadYYYMMDD,
  tiempoViviendaAnterior,
  tiempoViviendaActual,
  generaCurp,
  generaRFC,
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
  compareDatesOrigin,
  validarRFCTipoPersona,
} from "../../../../../utils/validaciones";
import { mensajeMostrarUsuario } from "../../../../../utils/mensajesServicios";
import Select from "react-select";
import { selectStylesDouble } from "../../../../../styles/select-styles";
import CheckBoxInput from "../../../../basics/checkBox";
import {
  textToUpperCase,
  validateValue,
  validDate,
  validarTextoConEnies,
  isEmpty,
  notSpecialCaractersConGuion,
} from "../../../../../functions/fieldFunctions";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import SelectWithErrorActividad from "../../../../basics/SelectWithError/SelectWithErrorActividad";
import SelectWithErrorTooltipCompuesto from "../../../../basics/SelectWithError/SelectWithErrorTooltipCompuesto";
import { sweetAlertError } from "../../../../helpers/alertas/alertas";
import { obtenerGenerarRfcCurpOS_PF_PM } from "./helpers/obligadoSolidarioHelper";

const ObligadoSolidarioPFAE_PM = ({
  modeloPm,
  axiosData,
  aniosDomicilio,
  mesesDomicilo,
  anios,
  NUM_OS,
  idTipoPersonaOS,
  setObligadosSolidariosInformacionBasica,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  Swal,
  setModeloPm,
  setLoading,
  seccionActual,
  datosOs,
  setDatosOs,
  setUltimoPasoSolicitud,
  webView,
}) => {
  const { innerWidth, innerHeight } = window;

  const [coloniasCP, setColoniasCP] = useState([]);
  const [startDateFechaNacimiento, setStartDateFechaNacimiento] = useState("");
  const [startDateFechaSHCP, setStartDateFechaSHCP] = useState("");
  const [totalDependientes, setTotalDependientes] = useState("");
  const [totalDependientesMenores23, setTotalDependientesMenores23] =
    useState("");

  const formik = useFormik({
    initialValues: initialValues(modeloPm, idTipoPersonaOS, NUM_OS, datosOs),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(
      validationSchema(totalDependientesMenores23, totalDependientes)
    ),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosOSPFAE_PM(formData);
    },
  });

  const obtenerDireccionByCp = async (codigoPostal) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK") {
        if (response.data.municipio !== undefined) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "principalAccionista.obligadoSolidario.direccion.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue(
            "principalAccionista.obligadoSolidario.direccion.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response.data.municipio.id
            )
          );
          formik.setFieldValue(
            "principalAccionista.obligadoSolidario.direccion.estadoId",
            response.data.estado.id
          );
          formik.setFieldValue(
            "principalAccionista.obligadoSolidario.direccion.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response.data.estado.id
            )
          );
          formik.setFieldValue(
            "principalAccionista.obligadoSolidario.direccion.colonia",
            response.data.asentamientos[0]
          );
        } else {
          limpiarDireccionByCp();
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue(
      "principalAccionista.obligadoSolidario.direccion.municipioId",
      ""
    );
    formik.setFieldValue(
      "principalAccionista.obligadoSolidario.direccion.municipioObj",
      ""
    );
    formik.setFieldValue(
      "principalAccionista.obligadoSolidario.direccion.estadoId",
      ""
    );
    formik.setFieldValue(
      "principalAccionista.obligadoSolidario.direccion.estadoObj",
      ""
    );
    formik.setFieldValue(
      "principalAccionista.obligadoSolidario.direccion.colonia",
      ""
    );
  };

  const generarRfcCurp = (idValorCalculoRFC, valueCalculoRFC) => {
    const {
      nombre,
      apellidoPaterno,
      apellidoMaterno,
      estadoNacimientoId,
      fechaNacimiento,
      generoId,
    } = obtenerGenerarRfcCurpOS_PF_PM(
      idValorCalculoRFC,
      valueCalculoRFC,
      formik
    );

    if (
      nombre &&
      nombre.length > 0 &&
      apellidoPaterno &&
      apellidoPaterno.length > 0 &&
      generoId &&
      estadoNacimientoId &&
      fechaNacimiento &&
      fechaNacimiento.length > 0
    ) {
      let idEstado = Number(estadoNacimientoId);
      const persona = {
        nombre: nombre,
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
        genero: generoId === 1 ? "H" : "M",
        idEstado: idEstado - 1,
        fechaNacimiento: fechaNacimiento,
      };

      const curp = generaCurp(persona);
      if (curp && curp.length === 18) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.generales.curp",
          curp
        );
        let newErrors = formik.errors;
        delete newErrors?.obligadoSolidario?.generales?.curp;
        formik.setErrors(newErrors);
      }

      if (apellidoMaterno && apellidoMaterno.length > 0) {
        const rfc = generaRFC(persona);
        if (rfc) {
          formik.setFieldValue(
            "principalAccionista.obligadoSolidario.generales.rfc",
            rfc
          );
          let newErrors = formik.errors;
          delete newErrors?.obligadoSolidario?.generales?.rfc;
          formik.setErrors(newErrors);
        }
      }
    }
  };

  const salvarDatosOSPFAE_PM = async (datosOS) => {
    try {
      const response = await api_save_os_pfae_pm(datosOS);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPm({
            ...modeloPm,
            idCliente: data.clienteId,
            principalAccionista: {
              ...modeloPm.principalAccionista,
              idPrincipalAccionista: data.principalAccionistaId,
              obligadoSolidario: {
                ...modeloPm.principalAccionista.obligadoSolidario,
                idObligadoSolidario: data.obligadoSolidarioPfaeId,
              },
            },
          });

          const conyuge = datosOs.conyuge;
          const showConyugeOS = false;

          if (
            data.obligadoSolidarioPfaeEstadoCivilId ===
            ESTADO_CIVIL_SOCIEDAD_CONYUGAL
          ) {
            showConyugeOS = true;
          }

          setDatosOs({
            datosOS: data.datosOS,
            idTipoPersonaOs: data.obligadoSolidarioIdTipoPersona,
            idEstadoCivilOs: data.obligadoSolidarioPfaeEstadoCivilId,
            conyuge: conyuge,
            showConyugeOS: showConyugeOS,
          });

          setObligadosSolidariosInformacionBasica(
            data.obligadosSolidariosInformacionBasica
          );
          setUltimoPasoSolicitud(data.solicitudUltimoPaso);
          setStyleHr("block");
          setLoading(false);
          handleSeccionPyme(seccionActual);
        } else {
          setLoading(false);
          const mensajeError = mensajeMostrarUsuario(data.mensaje);
          sweetAlertError(mensajeError);
        }
      } else {
        setLoading(false);
        sweetAlertError(
          "Ocurrió un error al guardar obligado solidario PF de PM"
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (datosOs.datosOS.length !== 0) {
      if (
        datosOs.datosOS.generales?.fechaNacimiento !== undefined &&
        datosOs.datosOS.generales?.fechaNacimiento !== null
      ) {
        setStartDateFechaNacimiento(
          datosOs?.datosOS?.generales?.fechaNacimiento
            .split("-")
            .reverse()
            .join("-")
        );
      }

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.paisNacimientoObj",
        axiosData?.generales?.paises?.filter(
          (e) => e.id === datosOs.datosOS.generales.paisNacimientoId
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.estadoNacimientoObj",
        axiosData?.generales?.estados?.filter(
          (e) => e.id === datosOs.datosOS.generales.estadoNacimientoId
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.generoObj",
        axiosData?.generales?.generoOpciones?.filter(
          (e) => e.id === datosOs.datosOS.generales.generoId
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.nacionalidadObj",
        axiosData?.generales?.nacionalidades?.filter(
          (e) => e.id === datosOs.datosOS.generales.nacionalidadId
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.estadoCivilObj",
        axiosData?.generales?.estadoCivilOpciones?.filter(
          (e) => e.id === datosOs.datosOS.generales.estadoCivilId
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.nivelEducativoObj",
        axiosData?.generales?.nivelEducativoOpciones?.filter(
          (e) => e.id === datosOs.datosOS.generales.nivelEducativoId
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.dependientesEconomicosMenores23Obj",
        axiosData?.generales?.dependientesMenores23Anios?.filter(
          (e) =>
            e.valor ===
            datosOs.datosOS.generales.dependientesEconomicosMenores23
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.dependientesEconomicosObj",
        axiosData?.generales?.dependientes?.filter(
          (e) => e.valor === datosOs.datosOS.generales.dependientesEconomicos
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.direccion.tipoDomicilioObj",
        axiosData?.domicilio?.tipoDeDomicilioOpciones?.filter(
          (e) => e.id === datosOs.datosOS.direccion.tipoDomicilioId
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.direccion.tipoViviendaObj",
        axiosData?.domicilio?.tipoDeViviendaOpciones?.filter(
          (e) => e.id === datosOs.datosOS.direccion.tipoViviendaId
        )
      );

      if (datosOs?.datosOS?.datosDelNegocio?.idDatosDelNegocio !== null) {
        if (
          datosOs?.datosOS?.datosDelNegocio?.fechaAltaShcp !== undefined &&
          datosOs?.datosOS?.datosDelNegocio?.fechaAltaShcp !== null
        ) {
          setStartDateFechaSHCP(
            datosOs?.datosOS?.datosDelNegocio?.fechaAltaShcp
              .split("-")
              .reverse()
              .join("-")
          );
        }

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.importaExportaObj",
          axiosData?.domicilioFiscal?.importaExportaOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.importaExportaId
          )
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.sectorObj",
          axiosData?.domicilioFiscal?.sectorOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.sectorId
          )
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.giroEmpresarialObj",
          axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.giroEmpresarialId
          )
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.actividadObj",
          axiosData?.domicilioFiscal?.actividadOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.actividadId
          )
        );
      } else {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.importaExportaId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.sectorId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.giroEmpresarialId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.actividadId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.numeroSucursales",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.aniosExperienciaSector",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.datosDelNegocio.fechaAltaShcp",
          ""
        );
      }

      obtenerDireccionByCp(datosOs.datosOS.direccion.codigoPostal);

      let dependientesMajor23Opc = datosOs.datosOS.generales
        ?.dependientesEconomicosMenores23
        ? axiosData?.generales?.dependientesMenores23Anios?.filter(
            (e) =>
              e.id ===
              datosOs.datosOS.generales?.dependientesEconomicosMenores23
          )
        : "";
      let dependientesEconomicoOpc = datosOs.datosOS.generales
        ?.dependientesEconomicos
        ? axiosData?.generales?.dependientes?.filter(
            (e) => e.id === datosOs.datosOS.generales?.dependientesEconomicos
          )
        : "";
      setTotalDependientesMenores23(
        dependientesMajor23Opc !== ""
          ? dependientesMajor23Opc[0].descripcion
          : 0
      );
      setTotalDependientes(
        dependientesEconomicoOpc !== ""
          ? dependientesEconomicoOpc[0].descripcion
          : 0
      );

      let calidadMigratoria = datosOs.datosOS.generales.calidadMigratoria
        ? datosOs.datosOS.generales.calidadMigratoria
        : "";
      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.calidadMigratoria",
        calidadMigratoria
      );

      if (datosOs.datosOS.direccion?.piso === null) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.direccion.piso",
          ""
        );
      }

      if (datosOs.datosOS.direccion?.numeroInterior === null) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.direccion.numeroInterior",
          ""
        );
      }

      if (datosOs?.datosOS?.generales?.nombreOtraEmpresaPfae === null) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.generales.nombreOtraEmpresaPfae",
          ""
        );
      }

      if (datosOs?.datosOS?.generales?.nombreOtraEmpresa === null) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.generales.nombreOtraEmpresa",
          ""
        );
      }

      if (datosOs?.datosOS?.generales?.paisResidenciaFiscal2Id === null) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.generales.paisResidenciaFiscal2Id",
          ""
        );
      }

      if (datosOs?.datosOS?.generales?.numeroIdFiscal2 === null) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidario.generales.numeroIdFiscal2",
          ""
        );
      }
    }
  }, []);

  useEffect(() => {
    if (idTipoPersonaOS !== null) {
      formik.setFieldValue(
        "principalAccionista.obligadoSolidario.generales.tipoPersonaId",
        idTipoPersonaOS
      );
    }
  }, [idTipoPersonaOS]);

  return (
    <>
      <form name="form-os-pfea-pm" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3">
            Información general
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.nombre"
              label="Nombre *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.nombre
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.nombre
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.nombre !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.nombre;
                    formik.setErrors(newErrors);
                  }
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.nombre",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_NOMBRE, e.target.value);
              }}
              InputProps={{ maxLength: 30 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.nombre
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.nombre
              }
            />
            <InputWithError
              label="Apellido paterno *"
              name="principalAccionista.obligadoSolidario.generales.apellidoPaterno"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.apellidoPaterno
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.apellidoPaterno
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.apellidoPaterno !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.apellidoPaterno;
                    formik.setErrors(newErrors);
                  }
                }
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.apellidoPaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APP, e.target.value);
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.apellidoPaterno
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.apellidoPaterno
              }
            />
            <InputWithError
              label="Apellido materno"
              name="principalAccionista.obligadoSolidario.generales.apellidoMaterno"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.apellidoMaterno
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.apellidoMaterno
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.apellidoMaterno !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.apellidoMaterno;
                    formik.setErrors(newErrors);
                  }
                }
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.apellidoMaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APM, e.target.value);
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.apellidoMaterno
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.apellidoMaterno
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.fechaNacimiento"
              type="date"
              label="Fecha de nacimiento *"
              value={startDateFechaNacimiento ? startDateFechaNacimiento : ""}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.fechaNacimiento !== undefined
                  ) {
                    delete newErrors?.principalAccionista.obligadoSolidario
                      .generales.fechaNacimiento;
                    formik.setErrors(newErrors);
                  }
                }

                if (validDate(e.target.value)) {
                  let array = e.target.value.split("-").reverse().join("-");
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.fechaNacimiento",
                    array
                  );
                  setStartDateFechaNacimiento(e.target.value);
                  generarRfcCurp(ID_CALCULO_RFC_FECHANAC, array);
                }
              }}
              InputProps={{
                max: fechaMaxMayorEdadYYYMMDD(),
                min: fechaMinEdadYYYMMDD(),
              }}
              InputLabelProps={{ shrink: true }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.fechaNacimiento
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.fechaNacimiento
              }
            />
            <SelectWithError
              name="principalAccionista.obligadoSolidario.generales.paisNacimientoId"
              label="País de nacimiento *"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.paisNacimientoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.paisNacimientoObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.paisNacimientoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.paisNacimientoObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.paisNacimientoId !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.paisNacimientoId;
                    formik.setErrors(newErrors);
                  }
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.paisNacimientoId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.paisNacimientoObj",
                  [e]
                );
              }}
              options={axiosData?.generales?.paises?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.paisNacimientoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.paisNacimientoId
              }
            />
            {formik.values?.principalAccionista?.obligadoSolidario?.generales
              ?.paisNacimientoId !== "" &&
              parseInt(
                formik.values?.principalAccionista?.obligadoSolidario?.generales?.paisNacimientoId
              ) === ID_PAIS_MEXICO && (
              <SelectWithError
                name="principalAccionista.obligadoSolidario.generales.estadoNacimientoId"
                label="Estado de nacimiento *"
                value={{
                  label: formik?.values?.principalAccionista?.obligadoSolidario
                    ?.generales?.estadoNacimientoObj?.[0]?.nombre
                    ? formik?.values?.principalAccionista?.obligadoSolidario
                        ?.generales?.estadoNacimientoObj?.[0]?.nombre
                    : "",
                  value: formik?.values?.principalAccionista?.obligadoSolidario
                    ?.generales?.estadoNacimientoObj?.[0]?.nombre
                    ? formik?.values?.principalAccionista?.obligadoSolidario
                        ?.generales?.estadoNacimientoObj?.[0]?.nombre
                    : "",
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (newErrors?.principalAccionista !== undefined) {
                    if (
                      newErrors?.principalAccionista?.obligadoSolidario !==
                        undefined &&
                      newErrors?.principalAccionista?.obligadoSolidario
                        ?.generales !== undefined &&
                      newErrors?.principalAccionista?.obligadoSolidario?.generales
                        ?.estadoNacimientoId !== undefined
                    ) {
                      delete newErrors?.principalAccionista?.obligadoSolidario
                        ?.generales?.estadoNacimientoId;
                      formik.setErrors(newErrors);
                    }
                  }
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.estadoNacimientoId",
                    e.id
                  );
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.estadoNacimientoObj",
                    [e]
                  );
                  generarRfcCurp(ID_CALCULO_RFC_ESTADONAC, e.id);
                }}
                options={axiosData?.generales?.estados?.map((item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                })}
                showErrorMessage={
                  formik.errors?.principalAccionista?.obligadoSolidario?.generales
                    ?.estadoNacimientoId
                }
                errorMessage={
                  formik.errors?.principalAccionista?.obligadoSolidario?.generales
                    ?.estadoNacimientoId
                }
              />
            )}
            
            <SelectWithError
              label="Género *"
              name="principalAccionista.obligadoSolidario.generales.generoId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.generoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.generoObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.generoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.generoObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.generoId !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.generoId;
                    formik.setErrors(newErrors);
                  }
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.generoId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.generoObj",
                  [e]
                );
                generarRfcCurp(ID_CALCULO_RFC_GENERO, e.id);
              }}
              options={axiosData?.generales?.generoOpciones?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.generoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.generoId
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.rfc"
              label="RFC *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.rfc
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.rfc
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                delete newErrors?.principalAccionista?.obligadoSolidario
                  ?.generales?.rfc;
                formik.setErrors(newErrors);
                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.rfc",
                    newValue
                  );
                }
              }}
              InputProps={{ maxLength: 13 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.rfc
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.rfc
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.curp"
              label="CURP *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.curp
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.curp
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                delete newErrors?.principalAccionista?.obligadoSolidario
                  ?.generales?.curp;
                formik.setErrors(newErrors);
                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.curp",
                    newValue
                  );
                }
              }}
              InputProps={{ maxLength: 18 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.curp
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.curp
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.folioVerificacionId"
              label="Número de identificación oficial *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.folioVerificacionId
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.folioVerificacionId
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.folioVerificacionId !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.folioVerificacionId;
                    formik.setErrors(newErrors);
                  }
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.folioVerificacionId",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 18 }}
              tooltip="Captura el número de folio de tu identificación, si es INE lo encontrarás en la parte posterior de la misma, después de las letras IDMEX"
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.folioVerificacionId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.folioVerificacionId
              }
            />
            <SelectWithError
              label="Nacionalidad *"
              name="principalAccionista.obligadoSolidario.generales.nacionalidadId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.nacionalidadObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.nacionalidadObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.nacionalidadObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.nacionalidadObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.nacionalidadId !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.nacionalidadId;
                    formik.setErrors(newErrors);
                  }
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.nacionalidadId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.nacionalidadObj",
                  [e]
                );
              }}
              options={axiosData?.generales?.nacionalidades?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.nacionalidadId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.nacionalidadId
              }
            />
            {formik.values?.principalAccionista?.obligadoSolidario?.generales
              ?.nacionalidadId !== "" &&
              parseInt(
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.nacionalidadId
              ) !== NACIONALIDAD_MEXICAN0 && (
                <InputWithError
                  label="Calidad migratoria"
                  name="principalAccionista.obligadoSolidario.generales.calidadMigratoria"
                  value={
                    formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.calidadMigratoria
                      ? formik.values?.principalAccionista?.obligadoSolidario
                          ?.generales?.calidadMigratoria
                      : ""
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (newErrors?.principalAccionista !== undefined) {
                      if (
                        newErrors?.principalAccionista?.obligadoSolidario !==
                          undefined &&
                        newErrors?.principalAccionista?.obligadoSolidario
                          ?.generales !== undefined &&
                        newErrors?.principalAccionista?.obligadoSolidario
                          ?.generales?.calidadMigratoria !== undefined
                      ) {
                        delete newErrors?.principalAccionista?.obligadoSolidario
                          ?.generales?.calidadMigratoria;
                        formik.setErrors(newErrors);
                      }
                    }
                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "principalAccionista.obligadoSolidario.generales.calidadMigratoria",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                  showErrorMessage={
                    formik.errors?.principalAccionista?.obligadoSolidario
                      ?.generales?.calidadMigratoria
                  }
                  errorMessage={
                    formik.errors?.principalAccionista?.obligadoSolidario
                      ?.generales?.calidadMigratoria
                  }
                />
              )}
            <SelectWithError
              name="principalAccionista.obligadoSolidario.generales.estadoCivilId"
              label="Estado civil *"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.estadoCivilObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.estadoCivilObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.estadoCivilObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.estadoCivilObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.estadoCivilId !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.estadoCivilId;
                    formik.setErrors(newErrors);
                  }
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.estadoCivilId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.estadoCivilObj",
                  [e]
                );

                setDatosOs({
                  ...datosOs,
                  idEstadoCivilOs: e.id,
                });
              }}
              options={axiosData?.generales?.estadoCivilOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.estadoCivilId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.estadoCivilId
              }
            />
            <SelectWithError
              label="Nivel académico *"
              name="principalAccionista.obligadoSolidario.generales.nivelEducativoId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.nivelEducativoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.nivelEducativoObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.nivelEducativoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.nivelEducativoObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.nivelEducativoId !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.nivelEducativoId;
                    formik.setErrors(newErrors);
                  }
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.nivelEducativoId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.nivelEducativoObj",
                  [e]
                );
              }}
              options={axiosData?.generales?.nivelEducativoOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.nivelEducativoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.nivelEducativoId
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.profesion"
              label="Ocupación o profesión *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.profesion
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.profesion
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.profesion !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.profesion;
                    formik.setErrors(newErrors);
                  }
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.profesion",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.profesion
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.profesion
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.correoElectronico"
              label="Correo electrónico *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.correoElectronico
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.correoElectronico
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.correoElectronico !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.correoElectronico;
                    formik.setErrors(newErrors);
                  }
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.correoElectronico",
                  e.target.value
                );
              }}
              type="email"
              InputProps={{ maxLength: 50 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.correoElectronico
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.correoElectronico
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.telefono"
              label="Teléfono *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.telefono
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.telefono
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.telefono !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.telefono;
                    formik.setErrors(newErrors);
                  }
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.principalAccionista?.obligadoSolidario
                    ?.generales?.telefono
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.telefono",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.telefono
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.telefono
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.celular"
              label="Celular *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.celular
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.celular
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (newErrors?.principalAccionista !== undefined) {
                  if (
                    newErrors?.principalAccionista?.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales !== undefined &&
                    newErrors?.principalAccionista?.obligadoSolidario?.generales
                      ?.celular !== undefined
                  ) {
                    delete newErrors?.principalAccionista?.obligadoSolidario
                      ?.generales?.celular;
                    formik.setErrors(newErrors);
                  }
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.principalAccionista?.obligadoSolidario
                    ?.generales?.celular
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.celular",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.celular
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.celular
              }
            />
            <SelectWithError
              name="principalAccionista.obligadoSolidario.generales.dependientesEconomicosMenores23"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.dependientesEconomicosMenores23Obj?.[0]
                  ?.descripcion
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.dependientesEconomicosMenores23Obj?.[0]
                      ?.descripcion
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.dependientesEconomicosMenores23Obj?.[0]
                  ?.descripcion
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.dependientesEconomicosMenores23Obj?.[0]
                      ?.descripcion
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.generales !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.generales
                    .dependientesEconomicosMenores23
                ) {
                  delete newErrors?.principalAccionista.obligadoSolidario
                    .generales.dependientesEconomicosMenores23;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.dependientesEconomicosMenores23",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.dependientesEconomicosMenores23Obj",
                  [e]
                );
                setTotalDependientesMenores23(e.descripcion);
              }}
              label="Cantidad de dependientes menores de 23 años *"
              options={axiosData?.generales?.dependientesMenores23Anios?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.descripcion,
                    value: item.descripcion,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.dependientesEconomicosMenores23
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.dependientesEconomicosMenores23
              }
            />
            <SelectWithError
              label="Cantidad total de dependientes *"
              name="principalAccionista.obligadoSolidario.generales.dependientesEconomicos"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.dependientesEconomicosObj?.[0]?.descripcion
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.dependientesEconomicosObj?.[0]?.descripcion
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.generales?.dependientesEconomicosObj?.[0]?.descripcion
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.dependientesEconomicosObj?.[0]?.descripcion
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.generales !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.generales
                    .dependientesEconomicos
                ) {
                  delete newErrors?.principalAccionista.obligadoSolidario
                    .generales.dependientesEconomicos;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.dependientesEconomicos",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.generales.dependientesEconomicosObj",
                  [e]
                );
                setTotalDependientes(e.descripcion);
              }}
              options={axiosData?.generales?.dependientes?.map((item) => {
                return {
                  ...item,
                  label: item.descripcion,
                  value: item.descripcion,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.dependientesEconomicos
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.dependientesEconomicos
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.generales.relacionSolicitante"
              label="Relación con el solicitante *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.generales
                  ?.relacionSolicitante
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.relacionSolicitante
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.generales !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.generales
                    .relacionSolicitante
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.generales?.relacionSolicitante;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.generales.relacionSolicitante",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.relacionSolicitante
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.generales
                  ?.relacionSolicitante
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="principalAccionista.obligadoSolidario.generales.esAccionistaOtraEmpresa"
                  state=""
                  typeOfCheck="normal"
                  value={
                    formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.esAccionistaOtraEmpresa
                  }
                  checked={
                    formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.esAccionistaOtraEmpresa
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "principalAccionista.obligadoSolidario.generales.esAccionistaOtraEmpresa",
                      e.target.checked
                    )
                  }
                  id="obligadoSolidarioOtraEmpresa"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  className="form-check-label"
                  htmlFor="obligadoSolidarioOtraEmpresa"
                >
                  El obligado es accionista u obligado de otra empresa
                </label>
              </div>
              {formik.values?.principalAccionista?.obligadoSolidario?.generales
                ?.esAccionistaOtraEmpresa && (
                <InputWithError
                  label="Nombre de la empresa o PFAE"
                  name="principalAccionista.obligadoSolidario.generales.nombreOtraEmpresa"
                  value={
                    formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.nombreOtraEmpresa
                      ? formik.values?.principalAccionista?.obligadoSolidario
                          ?.generales?.nombreOtraEmpresa
                      : ""
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista.obligadoSolidario !==
                        undefined &&
                      newErrors?.principalAccionista.obligadoSolidario
                        .generales !== undefined &&
                      newErrors?.principalAccionista.obligadoSolidario.generales
                        .nombreOtraEmpresa
                    ) {
                      delete newErrors?.principalAccionista.obligadoSolidario
                        .generales.nombreOtraEmpresa;
                      formik.setErrors(newErrors);
                    }
                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "principalAccionista.obligadoSolidario.generales.nombreOtraEmpresa",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                  showErrorMessage={
                    !isEmpty(formik.errors) &&
                    formik.errors?.principalAccionista?.obligadoSolidario
                      ?.generales?.nombreOtraEmpresa
                  }
                  errorMessage={
                    !isEmpty(formik.errors) &&
                    formik.errors?.principalAccionista?.obligadoSolidario
                      ?.generales?.nombreOtraEmpresa
                  }
                />
              )}
              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="principalAccionista.obligadoSolidario.generales.esObligadoSolidarioOtraEmpresaPfae"
                  state=""
                  value={
                    formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.esObligadoSolidarioOtraEmpresaPfae
                  }
                  checked={
                    formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.esObligadoSolidarioOtraEmpresaPfae
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "principalAccionista.obligadoSolidario.generales.esObligadoSolidarioOtraEmpresaPfae",
                      !formik.values?.principalAccionista?.obligadoSolidario
                        ?.generales?.esObligadoSolidarioOtraEmpresaPfae
                    )
                  }
                  type="checkbox"
                  id="obligadoSolidarioOtraEmpresaPfae"
                  typeOfCheck="normal"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  className="form-check-label"
                  htmlFor="obligadoSolidarioOtraEmpresaPfae"
                >
                  Es obligado o fiador de otra empresa o PFAE
                </label>
              </div>
              {formik.values?.principalAccionista?.obligadoSolidario?.generales
                ?.esObligadoSolidarioOtraEmpresaPfae && (
                <InputWithError
                  name="principalAccionista.obligadoSolidario.generales.nombreOtraEmpresaPfae"
                  label="Nombre de la empresa o PFAE"
                  value={
                    formik.values?.principalAccionista?.obligadoSolidario
                      ?.generales?.nombreOtraEmpresaPfae
                      ? formik.values?.principalAccionista?.obligadoSolidario
                          ?.generales?.nombreOtraEmpresaPfae
                      : ""
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.principalAccionista !== undefined &&
                      newErrors?.principalAccionista.obligadoSolidario !==
                        undefined &&
                      newErrors?.principalAccionista.obligadoSolidario
                        .generales !== undefined &&
                      newErrors?.principalAccionista.obligadoSolidario.generales
                        .nombreOtraEmpresaPfae
                    ) {
                      delete newErrors?.principalAccionista.obligadoSolidario
                        .generales.nombreOtraEmpresaPfae;
                      formik.setErrors(newErrors);
                    }
                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "principalAccionista.obligadoSolidario.generales.nombreOtraEmpresaPfae",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                  showErrorMessage={
                    !isEmpty(formik.errors) &&
                    formik.errors?.principalAccionista?.obligadoSolidario
                      ?.generales?.nombreOtraEmpresaPfae
                  }
                  errorMessage={
                    !isEmpty(formik.errors) &&
                    formik.errors?.principalAccionista?.obligadoSolidario
                      ?.generales?.nombreOtraEmpresaPfae
                  }
                />
              )}
            </div>

            <h2
              style={{
                textAlign: "left",
                width: "100%",
                margin: "1rem 0 ",
              }}
              className="f-s-3"
            >
              Información de domicilio
            </h2>
            <InputWithError
              name="principalAccionista.obligadoSolidario.direccion.calle"
              label="Calle *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.direccion
                  ?.calle
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.calle
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion
                    .calle
                ) {
                  delete newErrors?.principalAccionista.obligadoSolidario
                    .direccion.calle;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.direccion.calle",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 24 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.calle
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.calle
              }
            />
            <InputWithError
              label="Número exterior *"
              name="principalAccionista.obligadoSolidario.direccion.numeroExterior"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.direccion
                  ?.numeroExterior
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.numeroExterior
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion
                    .numeroExterior
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.direccion?.numeroExterior;
                  formik.setErrors(newErrors);
                }
                if (notSpecialCaractersConGuion(e.target.value)) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.direccion.numeroExterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 7 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.numeroExterior
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.numeroExterior
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.direccion.numeroInterior"
              label="Número interior"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.direccion
                  ?.numeroInterior
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.numeroInterior
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion
                    .numeroInterior
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.direccion?.numeroInterior;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.direccion.numeroInterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu  “Número exterior” separados por un guion Ejemplo: 318-123B"
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.numeroInterior
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.numeroInterior
              }
              required={
                formik.values.principalAccionista.obligadoSolidario.direccion
                  .tipoViviendaId === parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.principalAccionista.obligadoSolidario.direccion
                  .tipoViviendaId === parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
            />
            <InputWithError
              required={
                formik.values.principalAccionista.obligadoSolidario.direccion
                  .tipoViviendaId === parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.principalAccionista.obligadoSolidario.direccion
                  .tipoViviendaId === parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
              name="principalAccionista.obligadoSolidario.direccion.piso"
              label="Piso"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.direccion
                  ?.piso
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.piso
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion
                    .piso
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.direccion?.piso;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.direccion.piso",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.piso
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.piso
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.direccion.codigoPostal"
              label="CP *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario?.direccion
                  ?.codigoPostal
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.codigoPostal
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion
                    .codigoPostal
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.direccion?.codigoPostal;
                  formik.setErrors(newErrors);
                }

                if (
                  e.target.value.length <= 5 &&
                  validateValue(e.target.value, "number")
                ) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.direccion.codigoPostal",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length === 5) {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.principalAccionista !== undefined &&
                    newErrors?.principalAccionista.obligadoSolidario !==
                      undefined &&
                    newErrors?.principalAccionista.obligadoSolidario
                      .direccion !== undefined
                  ) {
                    if (
                      newErrors?.principalAccionista.obligadoSolidario.direccion
                        .colonia
                    ) {
                      delete newErrors?.principalAccionista?.obligadoSolidario
                        ?.direccion?.colonia;
                    }
                    if (
                      newErrors?.principalAccionista.obligadoSolidario.direccion
                        .municipioId
                    ) {
                      delete newErrors?.principalAccionista?.obligadoSolidario
                        ?.direccion?.municipioId;
                    }
                    if (
                      newErrors?.principalAccionista.obligadoSolidario.direccion
                        .estadoId
                    ) {
                      delete newErrors?.principalAccionista?.obligadoSolidario
                        ?.direccion?.estadoId;
                    }
                    formik.setErrors(newErrors);
                  }
                  obtenerDireccionByCp(e.target.value);
                } else {
                  limpiarDireccionByCp();
                }
              }}
              InputProps={{ maxLength: 5 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.codigoPostal
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.codigoPostal
              }
            />
            <SelectWithError
              name="principalAccionista.obligadoSolidario.direccion.colonia"
              label="Colonia *"
              value={{
                label: formik.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.colonia
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.colonia
                  : "",
                value: formik.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.colonia
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.colonia
                  : "",
              }}
              onChange={(e) => {
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.direccion.colonia",
                  e.label
                );
              }}
              options={coloniasCP?.map((item) => {
                return { ...item, label: item, value: item };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.colonia
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.colonia
              }
            />
            <SelectWithError
              placeholder="Alcaldía / Municipio *"
              label="Alcaldía / Municipio *"
              name="principalAccionista.obligadoSolidario.direccion.municipioId"
              isDisabled={true}
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.municipioObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.municipioObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.municipioObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.municipioObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.direccion.municipioId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.direccion.municipioObj",
                  [e]
                );
              }}
              options={axiosData?.generales?.municipios?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.municipioId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.municipioId
              }
            />
            <SelectWithError
              placeholder="Estado *"
              label="Estado *"
              name="principalAccionista.obligadoSolidario.direccion.estadoId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.estadoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.estadoObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.estadoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.estadoObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.direccion.estadoId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.direccion.estadoObj",
                  [e]
                );
              }}
              isDisabled={true}
              options={axiosData?.generales?.estados?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.estadoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.estadoId
              }
            />
            <SelectWithError
              label="Tipo de domicilio *"
              name="principalAccionista.obligadoSolidario.direccion.tipoDomicilioId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.tipoDomicilioObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.tipoDomicilioObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.tipoDomicilioObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.tipoDomicilioObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion
                    .tipoDomicilioId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.direccion?.tipoDomicilioId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.direccion.tipoDomicilioId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.direccion.tipoDomicilioObj",
                  [e]
                );
              }}
              options={axiosData?.domicilio?.tipoDeDomicilioOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.tipoDomicilioId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.tipoDomicilioId
              }
            />
            <SelectWithError
              label="Tipo de vivienda *"
              name="principalAccionista.obligadoSolidario.direccion.tipoViviendaId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.tipoViviendaObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.tipoViviendaObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.direccion?.tipoViviendaObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.direccion?.tipoViviendaObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario.direccion
                    .tipoViviendaId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.direccion?.tipoViviendaId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.direccion.tipoViviendaId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.direccion.tipoViviendaObj",
                  [e]
                );
              }}
              options={axiosData?.domicilio?.tipoDeViviendaOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.tipoViviendaId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario?.direccion
                  ?.tipoViviendaId
              }
            />
            <div
              style={{
                width: innerWidth < 700 ? "100%" : "55%",
                minWidth: "290px",
                maxWidth: "425px",
              }}
            >
              <span>Antigüedad en el domicilio actual *</span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "10px",
                  flexDirection: innerWidth < 700 ? "column" : "row",
                }}
              >
                <div
                  style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.principalAccionista?.obligadoSolidario
                    ?.direccion?.tiempoViviendaAnios >= 0 ? (
                    <span className="select-label-no-null">Años *</span>
                  ) : (
                    <span className="select-label">Años *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="principalAccionista.obligadoSolidario.direccion.tiempoViviendaAnios"
                    placeholder="Años *"
                    value={{
                      label: formik?.values?.principalAccionista
                        ?.obligadoSolidario?.direccion?.tiempoViviendaAnios
                        ? formik?.values?.principalAccionista?.obligadoSolidario
                            ?.direccion?.tiempoViviendaAnios
                        : 0,
                      value: formik?.values?.principalAccionista
                        ?.obligadoSolidario?.direccion?.tiempoViviendaAnios
                        ? formik?.values?.principalAccionista?.obligadoSolidario
                            ?.direccion?.tiempoViviendaAnios
                        : 0,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista.obligadoSolidario !==
                          undefined &&
                        newErrors?.principalAccionista.obligadoSolidario
                          .direccion !== undefined &&
                        newErrors?.principalAccionista.obligadoSolidario
                          .direccion.tiempoViviendaAnios
                      ) {
                        delete newErrors?.principalAccionista?.obligadoSolidario
                          ?.direccion?.tiempoViviendaAnios;
                        formik.setErrors(newErrors);
                      }
                      formik.setFieldValue(
                        "principalAccionista.obligadoSolidario.direccion.tiempoViviendaAnios",
                        e.label
                      );
                    }}
                    options={aniosDomicilio?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                  />
                </div>
                <div
                  style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.principalAccionista?.obligadoSolidario
                    ?.direccion?.tiempoViviendaMeses >= 0 ? (
                    <span className="select-label-no-null">Meses *</span>
                  ) : (
                    <span className="select-label">Meses *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="principalAccionista.obligadoSolidario.direccion.tiempoViviendaMeses"
                    placeholder="Meses *"
                    value={{
                      label: formik?.values?.principalAccionista
                        ?.obligadoSolidario?.direccion?.tiempoViviendaMeses
                        ? formik?.values?.principalAccionista?.obligadoSolidario
                            ?.direccion?.tiempoViviendaMeses
                        : 0,
                      value: formik?.values?.principalAccionista
                        ?.obligadoSolidario?.direccion?.tiempoViviendaMeses
                        ? formik?.values?.principalAccionista?.obligadoSolidario
                            ?.direccion?.tiempoViviendaMeses
                        : 0,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista.obligadoSolidario !==
                          undefined &&
                        newErrors?.principalAccionista.obligadoSolidario
                          .direccion !== undefined &&
                        newErrors?.principalAccionista.obligadoSolidario
                          .direccion.tiempoViviendaMeses
                      ) {
                        delete newErrors?.principalAccionista?.obligadoSolidario
                          ?.direccion?.tiempoViviendaMeses;
                      }
                      formik.setErrors(newErrors);
                      formik.setFieldValue(
                        "principalAccionista.obligadoSolidario.direccion.tiempoViviendaMeses",
                        e.label
                      );
                    }}
                    options={mesesDomicilo?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                  />
                </div>
              </div>
              {formik?.errors?.principalAccionista?.obligadoSolidario?.direccion
                ?.tiempoViviendaMeses ? (
                <div>
                  <span className="text__center text_red">
                    {
                      formik.errors?.principalAccionista?.obligadoSolidario
                        ?.direccion?.tiempoViviendaMeses
                    }
                  </span>
                </div>
              ) : null}
            </div>
            <div
              style={{
                width: innerWidth < 700 ? "100%" : "55%",
                minWidth: "300px",
                maxWidth: "425px",
              }}
            >
              <span>Antigüedad en el domicilio anterior</span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "10px",
                  flexDirection: innerWidth < 700 ? "column" : "row",
                }}
              >
                <div
                  style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.principalAccionista?.obligadoSolidario
                    ?.direccion?.tiempoViviendaAntAnios >= 0 ? (
                    <span className="select-label-no-null">Años *</span>
                  ) : (
                    <span className="select-label">Años *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="principalAccionista.obligadoSolidario.direccion.tiempoViviendaAntAnios"
                    placeholder="Años *"
                    value={{
                      label: formik?.values?.principalAccionista
                        ?.obligadoSolidario?.direccion?.tiempoViviendaAntAnios
                        ? formik?.values?.principalAccionista?.obligadoSolidario
                            ?.direccion?.tiempoViviendaAntAnios
                        : 0,
                      value: formik?.values?.principalAccionista
                        ?.obligadoSolidario?.direccion?.tiempoViviendaAntAnios
                        ? formik?.values?.principalAccionista?.obligadoSolidario
                            ?.direccion?.tiempoViviendaAntAnios
                        : 0,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista.obligadoSolidario !==
                          undefined &&
                        newErrors?.principalAccionista.obligadoSolidario
                          .direccion !== undefined &&
                        newErrors?.principalAccionista.obligadoSolidario
                          .direccion.tiempoViviendaAntAnios
                      ) {
                        delete newErrors?.principalAccionista?.obligadoSolidario
                          ?.direccion?.tiempoViviendaAntAnios;
                        formik.setErrors(newErrors);
                      }

                      formik.setFieldValue(
                        "principalAccionista.obligadoSolidario.direccion.tiempoViviendaAntAnios",
                        e.label
                      );
                    }}
                    options={aniosDomicilio?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                  />
                </div>
                <div
                  style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.principalAccionista?.obligadoSolidario
                    ?.direccion?.tiempoViviendaAntMeses >= 0 ? (
                    <span className="select-label-no-null">Meses *</span>
                  ) : (
                    <span className="select-label">Meses *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="principalAccionista.obligadoSolidario.direccion.tiempoViviendaAntMeses"
                    placeholder="Meses *"
                    value={{
                      label: formik?.values?.principalAccionista
                        ?.obligadoSolidario?.direccion?.tiempoViviendaAntMeses
                        ? formik?.values?.principalAccionista?.obligadoSolidario
                            ?.direccion?.tiempoViviendaAntMeses
                        : 0,
                      value: formik?.values?.principalAccionista
                        ?.obligadoSolidario?.direccion?.tiempoViviendaAntMeses
                        ? formik?.values?.principalAccionista?.obligadoSolidario
                            ?.direccion?.tiempoViviendaAntMeses
                        : 0,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista.obligadoSolidario !==
                          undefined &&
                        newErrors?.principalAccionista.obligadoSolidario
                          .direccion !== undefined &&
                        newErrors?.principalAccionista.obligadoSolidario
                          .direccion.tiempoViviendaAntMeses
                      ) {
                        delete newErrors?.principalAccionista?.obligadoSolidario
                          ?.direccion?.tiempoViviendaAntMeses;
                        formik.setErrors(newErrors);
                      }

                      formik.setFieldValue(
                        "principalAccionista.obligadoSolidario.direccion.tiempoViviendaAntMeses",
                        e.label
                      );
                    }}
                    options={mesesDomicilo?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                  />
                </div>
              </div>
              {formik?.errors?.principalAccionista?.obligadoSolidario?.direccion
                ?.tiempoViviendaAntMeses ? (
                <div>
                  <span className="text__center text_red">
                    {
                      formik.errors?.principalAccionista?.obligadoSolidario
                        ?.direccion?.tiempoViviendaAntMeses
                    }
                  </span>
                </div>
              ) : null}
            </div>
            <h2
              style={{
                textAlign: "left",
                width: "100%",
                margin: "1rem 0 ",
              }}
              className="f-s-3"
            >
              Información del negocio
            </h2>
            <SelectWithError
              label="¿Realizas actividades de importación o exportación? *"
              name="principalAccionista.obligadoSolidario.datosDelNegocio.importaExportaId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.importaExportaObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.importaExportaObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.importaExportaObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.importaExportaObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio.importaExportaId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.datosDelNegocio?.importaExportaId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.importaExportaId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.importaExportaObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.importaExportaOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.importaExportaId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.importaExportaId
              }
            />
            <SelectWithErrorTooltipCompuesto
              name="principalAccionista.obligadoSolidario.datosDelNegocio.giroEmpresarialId"
              label="Giro *"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.giroEmpresarialObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.giroEmpresarialObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.giroEmpresarialObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.giroEmpresarialObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio.giroEmpresarialId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.datosDelNegocio?.giroEmpresarialId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.giroEmpresarialId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.giroEmpresarialObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              tooltipA="Comercio: Supermercados, tiendas, farmacias, ferreterías, etc."
              tooltipB="Industria: Manufacturera, constructoras, textil, alimenticia, etc."
              tooltipC="Servicios: Escuelas, hospitales, despachos, transporte, médicos, dentista, restaurantes, etc."
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.giroEmpresarialId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.giroEmpresarialId
              }
            />
            <SelectWithError
              label="Sector *"
              name="principalAccionista.obligadoSolidario.datosDelNegocio.sectorId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.sectorObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.sectorObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.sectorObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.sectorObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio.sectorId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.datosDelNegocio?.sectorId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.sectorId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.sectorObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.sectorOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.sectorId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.sectorId
              }
            />
            <SelectWithErrorActividad
              menuPlacement="top"
              name="principalAccionista.obligadoSolidario.datosDelNegocio.actividadId"
              label="Actividad *"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.actividadObj?.[0]?.detalleClave
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.actividadObj?.[0]?.detalleClave
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.actividadObj?.[0]?.detalleClave
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.actividadObj?.[0]?.detalleClave
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio.actividadId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.datosDelNegocio?.actividadId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.actividadId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.actividadObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.actividadOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.detalleClave,
                    value: item.detalleClave,
                  };
                }
              )}
              tooltip="Selecciona la actividad que mas se asemeje a tu alta en hacienda. En caso de que tengas varias actividades, selecciona la preponderante."
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.actividadId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.actividadId
              }
            />
            <InputWithError
              label="Fecha de alta en SHCP *"
              name="principalAccionista.obligadoSolidario.datosDelNegocio.fechaAltaShcp"
              type="date"
              InputProps={{ max: fechaActualYYYMMDD() }}
              InputLabelProps={{ shrink: true }}
              value={startDateFechaSHCP ? startDateFechaSHCP : ""}
              onChange={(e) => {
                let array = e.target.value.split("-").reverse().join("-");
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio.fechaAltaShcp
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.datosDelNegocio?.fechaAltaShcp;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.fechaAltaShcp",
                  array
                );
                setStartDateFechaSHCP(e.target.value);
              }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.fechaAltaShcp
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.fechaAltaShcp
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidario.datosDelNegocio.numeroSucursales"
              label="Número de sucursales *"
              value={
                formik.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.numeroSucursales
                  ? formik.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.numeroSucursales
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio.numeroSucursales
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.datosDelNegocio?.numeroSucursales;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "number")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidario.datosDelNegocio.numeroSucursales",
                    e.target.value
                  );
                }
              }}
              InputProps={{maxLength: 15}}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.numeroSucursales
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.numeroSucursales
              }
            />
            <SelectWithError
              menuPlacement="top"
              label="Años de experiencia en el sector *"
              name="principalAccionista.obligadoSolidario.datosDelNegocio.aniosExperienciaSector"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.aniosExperienciaSector
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.aniosExperienciaSector
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.aniosExperienciaSector
                  ? formik?.values?.principalAccionista?.obligadoSolidario
                      ?.datosDelNegocio?.aniosExperienciaSector
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario !==
                    undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista.obligadoSolidario
                    .datosDelNegocio.aniosExperienciaSector
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidario
                    ?.datosDelNegocio?.aniosExperienciaSector;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidario.datosDelNegocio.aniosExperienciaSector",
                  e.value
                );
              }}
              options={anios?.map((item, index) => {
                return {
                  ...item,
                  label: index + 1,
                  value: index + 1,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.aniosExperienciaSector
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidario
                  ?.datosDelNegocio?.aniosExperienciaSector
              }
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            margin: "1rem 0",
          }}
        >
          <button
            className="button-secondary"
            onClick={(e) => {
              e.preventDefault();
              handleRegresarSeccionPyme(seccionActual);
            }}
          >
            Atrás
          </button>

          <button
            className="button-primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            <span>Siguiente</span>
          </button>
        </div>
      </form>
    </>
  );
};

export default ObligadoSolidarioPFAE_PM;

function initialValues(modeloPm, idTipoPersonaOS, NUM_OS, datosOs) {
  if (datosOs.datosOS.length == 0) {
    return {
      idCliente: modeloPm.idCliente,
      idSolicitud: modeloPm.idSolicitud,
      principalAccionista: {
        idPrincipalAccionista:
          modeloPm.principalAccionista.idPrincipalAccionista,
        obligadoSolidario: {
          generales: {
            tipoPersonaId: idTipoPersonaOS,
            numero: NUM_OS,
            relacionSolicitante: "",
            relacionRamaGobierno: false,
            paisResidenciaFiscal2: false,
            residenciaUsa: false,
            esAccionistaOtraEmpresa: false,
            esObligadoSolidarioOtraEmpresaPfae: false,
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            fechaNacimiento: "",
            paisNacimientoId: "",
            estadoNacimientoId: "",
            generoId: "",
            nacionalidadId: "",
            rfc: "",
            curp: "",
            folioVerificacionId: "",
            calidadMigratoria: "",
            estadoCivilId: "",
            nivelEducativoId: "",
            profesion: "",
            correoElectronico: "",
            celular: "",
            dependientesEconomicosMenores23: "",
            dependientesEconomicos: "",
            numeroIdFiscal2: "",
            nombreOtraEmpresa: "",
            nombreOtraEmpresaPfae: "",
          },
          direccion: {
            ineCertificada: INE_CERTIFICADA,
            aplicaIneComprobante: APLICA_INE_COMPROBANTE_OS_NO,
            calle: "",
            numeroExterior: "",
            numeroInterior: "",
            piso: "",
            paisId: ID_PAIS_MEXICO,
            codigoPostal: "",
            colonia: "",
            tipoDomicilioId: "",
            tipoViviendaId: "",
            tiempoViviendaAnios: "",
            tiempoViviendaMeses: "",
            tiempoViviendaAntAnios: "",
            tiempoViviendaAntMeses: "",
          },
          datosDelNegocio: {
            importaExportaId: "",
            sectorId: "",
            giroEmpresarialId: "",
            actividadId: "",
            aniosExperienciaSector: "",
            numeroSucursales: "",
            fechaAltaShcp: "",
          },
        },
      },
    };
  } else {
    return {
      idCliente: modeloPm.idCliente,
      idSolicitud: modeloPm.idSolicitud,
      principalAccionista: {
        idPrincipalAccionista:
          modeloPm.principalAccionista.idPrincipalAccionista,
        obligadoSolidario: datosOs.datosOS,
      },
    };
  }
}

function validationSchema(totalDependientesMenores23, totalDependientes) {
  return {
    principalAccionista: Yup.object().shape({
      obligadoSolidario: Yup.object().shape({
        generales: Yup.object().shape({
          nombre: Yup.string()
            .required("Dato requerido.")
            .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
          apellidoPaterno: Yup.string()
            .required("Dato requerido.")
            .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
          apellidoMaterno: Yup.string().matches(
            /^[aA-zZÑñ\s]+$/,
            "Solo se permiten letras "
          ),
          fechaNacimiento: Yup.string()
            .required("Dato requerido.")
            .test(
              "fechaNacimiento",
              "Debe ser mayor a 18 años y menor a 70 años.",
              (value) =>
                compareDatesOrigin(
                  fechaMaxMayorEdadYYYMMDD(),
                  fechaMinEdadYYYMMDD(),
                  value
                )
            ),
          paisNacimientoId: Yup.number().required("Dato requerido."),
          estadoNacimientoId: Yup.string().when("paisNacimientoId", {
            is: (value) => value === ID_PAIS_MEXICO,
            then: Yup.string().required("Dato requerido."),
          }),
          generoId: Yup.number().required("Dato requerido."),
          rfc: Yup.string()
            .required("Dato requerido.")
            .test("rfc", "RFC inválido.", (item, testContext) => {
              return testContext.parent.rfc !== undefined
                ? testContext.parent.rfc.trim().length > 0
                  ? validarRFCTipoPersona(
                      testContext.parent.rfc.trim(),
                      ID_PFAE
                    )
                  : false
                : false;
            }),
          curp: Yup.string().required("Dato requerido."),
          folioVerificacionId: Yup.string().required("Dato requerido."),
          nacionalidadId: Yup.number().required("Dato requerido."),
          calidadMigratoria: Yup.string().when("nacionalidadId", {
            is: (value) => value !== NACIONALIDAD_MEXICAN0,
            then: Yup.string().required("Dato requerido."),
          }),
          estadoCivilId: Yup.number().required("Dato requerido."),
          nivelEducativoId: Yup.number().required("Dato requerido."),
          profesion: Yup.string().required("Dato requerido."),
          correoElectronico: Yup.string()
            .email("Correo eléctronico inválido")
            .required("Dato requerido."),
          telefono: Yup.string()
            .required("Dato requerido.")
            .test(
              "telefono",
              "Teléfono capturado de forma incorrecta.",
              (item, testContext) => {
                return validarNumeroTelefono(testContext.parent.telefono);
              }
            ),
          celular: Yup.string()
            .required("Dato requerido.")
            .test(
              "celular",
              "Celular capturado de forma incorrecta.",
              (item, testContext) => {
                return validarNumeroTelefono(testContext.parent.celular);
              }
            ),
          dependientesEconomicosMenores23:
            Yup.number().required("Dato requerido."),
          dependientesEconomicos: Yup.number()
            .required("Dato requerido.")
            .test(
              "dependientesEconomicos",
              "Debe ser Mayor o igual al número de dependientes menores de 23 años.",
              (item, testContext) => {
                return dependeintesMajor23(
                  totalDependientesMenores23,
                  totalDependientes
                );
              }
            ),
          relacionSolicitante: Yup.string().required("Dato requerido."),
          nombreOtraEmpresa: Yup.string().when("esAccionistaOtraEmpresa", {
            is: true,
            then: Yup.string().required("Este campo es obligatorio."),
          }),
          nombreOtraEmpresaPfae: Yup.string().when(
            "esObligadoSolidarioOtraEmpresaPfae",
            {
              is: true,
              then: Yup.string().required("Este campo es obligatorio."),
            }
          ),
        }),
        direccion: Yup.object().shape({
          calle: Yup.string().required("Dato requerido."),
          numeroExterior: Yup.string().required("Dato requerido."),
          numeroInterior: Yup.string().when("tipoViviendaId", {
            is: TIPO_VIVIENDA_EDIFICIO,
            then: Yup.string().required("Dato requerido."),
            otherwise: Yup.string().when("tipoViviendaId", {
              is: TIPO_DEPARTAMENTO_APARTAMENTO,
              then: Yup.string().required("Dato requerido."),
            }),
          }),
          piso: Yup.string().when("tipoViviendaId", {
            is: TIPO_VIVIENDA_EDIFICIO,
            then: Yup.string().required("Dato requerido."),
            otherwise: Yup.string().when("tipoViviendaId", {
              is: TIPO_DEPARTAMENTO_APARTAMENTO,
              then: Yup.string().required("Dato requerido."),
            }),
          }),
          codigoPostal: Yup.string().required("Dato requerido."),
          colonia: Yup.string().required("Dato requerido."),
          municipioId: Yup.string().required("Dato requerido."),
          estadoId: Yup.string().required("Dato requerido."),
          tipoDomicilioId: Yup.string().required("Dato requerido."),
          tipoViviendaId: Yup.string().required("Dato requerido."),
          tiempoViviendaAnios: Yup.number().required("Dato requerido."),
          tiempoViviendaMeses: Yup.number()
            .required("Dato requerido.")
            .test(
              "tiempoViviendaMeses",
              "Antigüedad en el Domicilio Actual, debe ser mayor a cero.",
              (item, testContext) => {
                return tiempoViviendaActual(
                  testContext.parent.tiempoViviendaMeses,
                  testContext.parent.tiempoViviendaAnios
                );
              }
            ),
          tiempoViviendaAntAnios: Yup.number().required("Dato requerido."),
          tiempoViviendaAntMeses: Yup.number()
            .required("Dato requerido.")
            .test(
              "tiempoViviendaAntMeses",
              "Dato requerido.",
              (item, testContext) => {
                return tiempoViviendaAnterior(
                  testContext.parent.tiempoViviendaAntMeses,
                  testContext.parent.tiempoViviendaAntAnios
                );
              }
            ),
        }),
        datosDelNegocio: Yup.object().shape({
          importaExportaId: Yup.number().required("Dato requerido."),
          sectorId: Yup.string().required("Dato requerido."),
          giroEmpresarialId: Yup.string().required("Dato requerido."),
          actividadId: Yup.string().required("Dato requerido."),
          aniosExperienciaSector: Yup.string().required("Dato requerido."),
          numeroSucursales: Yup.number()
            .required("Dato requerido.")
            .min(0, "Por favor ingrese un valor mayor igual a 0.")
            .max(9999, "Por favor no ingrese más de 9,999 sucursales."),
          fechaAltaShcp: Yup.string().required("Dato requerido."),
        }),
      }),
    }),
  };
}
