import React from "react";
import "./Buttons.css";

export const Button = ({
  type = "button",
  children,
  className,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      className={className ? `button ${className}` : "button"}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const PrimaryButton = ({ type, children, onClick = () => {} }) => {
  return (
    <Button type={type} className="primary-button" onClick={onClick}>
      {children}
    </Button>
  );
};

export const SecondaryButton = ({ children, onClick = () => {} }) => {
  return (
    <Button className="secondary-button" onClick={onClick}>
      {children}
    </Button>
  );
};

export const ThirdButton = ({ type, children, onClick = () => {} }) => {
  return (
    <Button type={type} className="third-button" onClick={onClick}>
      {children}
    </Button>
  );
};

export const DisabledButton = ({ children }) => {
  return (
    <Button
      className="disabled-button"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </Button>
  );
};
