import React, { useState } from "react";
import {
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  KEY_PM,
  KEY_PFAE,
  ID_PM,
  SECCION_HISTORIAL_SOLICITANTE,
  SECCION_HISTORIAL_SOLICITANTE_COYUGE,
  SECCION_HISTORIAL_PA,
  SECCION_HISTORIAL_PA_COYUGE,
  SECCION_HISTORIAL_CREDITICIO
} from "../../../../../utils/constantes";
import ConsultaTradicionalSolicitantePM from "./ConsultaTradicionalSolicitantePM";
import ConsultaTradicionalPAPConyugePM from "./ConsultaTradicionalPAPConyugePM";
import ConsultaTradicionalPAPM from "./ConsultaTradicionalPAPM";
import ConsultaTradicionalOSPMPFAE from "./ConsultaTradicionalOSPMPFAE";
import ConsultaTradicionalOSPMPM from "./ConsultaTradicionalOSPMPM";
import ConsultaTradicionalConyugeOSPFAEPM from "./ConsultaTradicionalConyugeOSPFAEPM";
import './consultaTradicional.css'

const ConsultaTradicionalPM = ({
  modeloDatos,
  setLoading,
  selectedConsultaTradicional,
  setSelectedConsultaTradicional,
  setSelectedConsultaTradicionalPFAE,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setStyleHr,
  setShowResult,
  setContadorOS,
  obligadosSolidarios,
  principalAccionista,
  idEstadoCivilSolicitante,
  setResultadoEvaluacionRiesgos,
  docPendientesSolicitante,
  docPendientesSolicitanteConyuge,
  webView,
}) => {
  const [documento, setDocumento] = useState(modeloDatos?.idCliente);

  const obtenerAnteriorOSHistorialPM = (idCliente, numOS) => {
    let obligadoSolidarioAnterior = null;
    let retrocederSolicitud = true;

    if (obligadosSolidarios.length > 0) {
      let obligadoSolidarioActual = obligadosSolidarios?.filter(
        (e) => e.numero === numOS
      );

      if (numOS == 1) {
        if (parseInt(obligadoSolidarioActual[0].tipoPersonaKey) !== ID_PM) {
          if (obligadoSolidarioActual[0]?.obligadoSolidario?.idObligadoSolidario == idCliente) {
            if (principalAccionista?.generales?.estadoCivilId === ESTADO_CIVIL_SOCIEDAD_CONYUGAL) {
              if (principalAccionista?.conyuge?.documentosPendientes) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_PA_COYUGE;
              } else {
                if (principalAccionista?.documentosPendientes) {
                  obligadoSolidarioAnterior = SECCION_HISTORIAL_PA;
                } else {
                  if (docPendientesSolicitante === true) {
                    obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                  } else {
                    obligadoSolidarioAnterior = null;
                  }
                }
              }
            } else {
              if (principalAccionista?.documentosPendientes) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_PA;
              } else {
                if (docPendientesSolicitante === true) {
                  obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                } else {
                  obligadoSolidarioAnterior = null;
                }
              }
            }
          } else {
            if (obligadoSolidarioActual[0]?.obligadoSolidario?.conyuge?.idConyuge == idCliente) {
              if (obligadoSolidarioActual[0]?.obligadoSolidario?.documentosPendientes) {
                obligadoSolidarioAnterior = obligadoSolidarioActual[0]?.obligadoSolidario?.idObligadoSolidario;
              } else {
                if (principalAccionista?.generales?.estadoCivilId === ESTADO_CIVIL_SOCIEDAD_CONYUGAL) {
                  if (principalAccionista?.conyuge?.documentosPendientes) {
                    obligadoSolidarioAnterior = SECCION_HISTORIAL_PA_COYUGE;
                  } else {
                    if (principalAccionista?.documentosPendientes) {
                      obligadoSolidarioAnterior = SECCION_HISTORIAL_PA;
                    } else {
                      if (docPendientesSolicitante === true) {
                        obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                      } else {
                        obligadoSolidarioAnterior = null;
                      }
                    }
                  }
                } else {
                  if (principalAccionista?.documentosPendientes) {
                    obligadoSolidarioAnterior = SECCION_HISTORIAL_PA;
                  } else {
                    if (docPendientesSolicitante === true) {
                      obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                    } else {
                      obligadoSolidarioAnterior = null;
                    }
                  }
                }
              }
            }
          }
        } else {
          if (
            principalAccionista?.generales?.estadoCivilId ===
            ESTADO_CIVIL_SOCIEDAD_CONYUGAL
          ) {
            if (principalAccionista?.conyuge?.documentosPendientes) {
              obligadoSolidarioAnterior = SECCION_HISTORIAL_PA_COYUGE;
            } else {
              if (principalAccionista?.documentosPendientes) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_PA;
              } else {
                if (docPendientesSolicitante === true) {
                  obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                } else {
                  obligadoSolidarioAnterior = null;
                }
              }
            }
          } else {
            if (principalAccionista?.documentosPendientes) {
              obligadoSolidarioAnterior = SECCION_HISTORIAL_PA;
            } else {
              if (docPendientesSolicitante === true) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
              } else {
                obligadoSolidarioAnterior = null;
              }
            }
          }
        }
      } else {
        if (parseInt(obligadoSolidarioActual[0].tipoPersonaKey) !== ID_PM) {
          if (
            obligadoSolidarioActual[0]?.obligadoSolidario?.conyuge?.idConyuge ==
            idCliente
          ) {
            if (obligadoSolidarioActual[0]?.obligadoSolidario?.documentosPendientes) {
              obligadoSolidarioAnterior = obligadoSolidarioActual[0]?.obligadoSolidario?.idObligadoSolidario;
            } else {
              retrocederSolicitud = false;
            }
          } else {
            retrocederSolicitud = false;
          }
        } else {
          retrocederSolicitud = false;
        }

        if (retrocederSolicitud === false) {
          let retrocederOS = true;
          let indice = 1
          let numOsIndice;
          do {
            let anteriorObligadoSolidario = obligadosSolidarios?.filter(
              (e) => e.numero === numOS - indice
            );

            if (parseInt(anteriorObligadoSolidario[0].tipoPersonaKey) !== ID_PM) {
              if (
                anteriorObligadoSolidario[0]?.obligadoSolidario?.generales?.estadoCivilId == ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                anteriorObligadoSolidario[0]?.obligadoSolidario?.conyuge.generales?.esObligadoSolidario
              ) {
                if (anteriorObligadoSolidario[0]?.obligadoSolidario?.conyuge?.documentosPendientes) {
                  obligadoSolidarioAnterior = anteriorObligadoSolidario[0].obligadoSolidario?.conyuge?.idConyuge;
                } else {
                  if (anteriorObligadoSolidario[0]?.obligadoSolidario?.documentosPendientes) {
                    obligadoSolidarioAnterior = anteriorObligadoSolidario[0]?.obligadoSolidario?.idObligadoSolidario;
                  } else {
                    retrocederOS = false;
                  }
                }
              } else {
                if (anteriorObligadoSolidario[0]?.obligadoSolidario?.documentosPendientes) {
                  obligadoSolidarioAnterior = anteriorObligadoSolidario[0]?.obligadoSolidario?.idObligadoSolidario;
                } else {
                  retrocederOS = false;
                }
              }
            } else {
              if (anteriorObligadoSolidario[0]?.obligadoSolidarioPM?.documentosPendientes) {
                obligadoSolidarioAnterior = anteriorObligadoSolidario[0]?.obligadoSolidarioPM?.idObligadoSolidario;
              } else {
                retrocederOS = false;
              }
            }
            indice = indice + 1;
            numOsIndice = numOS - indice;
          } while (obligadoSolidarioAnterior === null && numOsIndice > 0);

          if (retrocederOS === false && obligadoSolidarioAnterior === null) {

            if (
              idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              modeloDatos?.conyuge?.generales?.esObligadoSolidario
            ) {
              if (docPendientesSolicitanteConyuge) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE_COYUGE;
              } else {
                if (docPendientesSolicitante) {
                  obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
                } else {
                  obligadoSolidarioAnterior = null;
                }
              }
            } else {
              if (docPendientesSolicitante) {
                obligadoSolidarioAnterior = SECCION_HISTORIAL_SOLICITANTE;
              } else {
                obligadoSolidarioAnterior = null;
              }
            }
          }
        }
      }
    }
    return obligadoSolidarioAnterior;
  };

  const redireccionBtnAtrasHistorialPM = (osAnterior) => {
    if (osAnterior !== null) {
      setSelectedConsultaTradicional(osAnterior);
    } else {
      handleRegresarSeccionPyme(SECCION_HISTORIAL_CREDITICIO);
    }
  }

  return (
    <>
      <>
        <div className="container navigation navigation-select formulario--menu-os">
          <>
            {docPendientesSolicitante && (
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  className={`${selectedConsultaTradicional ===
                    SECCION_HISTORIAL_SOLICITANTE &&
                    "bold tab__selected text__red pointer tab"
                    } p-2 mb-0`}
                  onClick={() => {
                    setSelectedConsultaTradicional(1);
                    setDocumento(modeloDatos?.idCliente);
                  }}
                >
                  Solicitante
                </p>
              </div>
            )}
            {principalAccionista?.documentosPendientes && (
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  className={`${selectedConsultaTradicional === SECCION_HISTORIAL_PA &&
                    "bold tab__selected text__red pointer tab"
                    } pointer p-2 mb-0`}
                  onClick={() =>
                    setSelectedConsultaTradicional(SECCION_HISTORIAL_PA)
                  }
                >
                  Principal accionista
                </p>
              </div>
            )}
            {(principalAccionista?.generales?.estadoCivilId ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              principalAccionista?.conyuge?.idConyuge &&
              principalAccionista?.conyuge?.generales?.esObligadoSolidario &&
              principalAccionista?.conyuge?.documentosPendientes) && (
                <p
                  className={`${selectedConsultaTradicional === SECCION_HISTORIAL_PA_COYUGE &&
                    "bold tab__selected text__red pointer tab"
                    } pointer p-2 mb-0`}
                  onClick={() =>
                    setSelectedConsultaTradicional(SECCION_HISTORIAL_PA_COYUGE)
                  }
                >
                  Cónyuge principal accionista
                </p>
              )}
            {obligadosSolidarios.map((item, index) => {
              return (
                <div key={index} className="obligados_solidarios_flex">
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {(item?.obligadoSolidario?.idObligadoSolidario !== undefined &&
                      item?.obligadoSolidario?.documentosPendientes) && (
                        <p
                          className={`${selectedConsultaTradicional ===
                              item?.obligadoSolidario?.idObligadoSolidario
                              ? "bold tab__selected text__red pointer tab"
                              : null
                            } pointer p-2 mb-0`}
                          onClick={() =>
                            setSelectedConsultaTradicional(
                              item?.obligadoSolidario?.idObligadoSolidario
                            )
                          }
                        >
                          Obligado solidario {index + 1}
                        </p>
                      )}
                    {(item?.obligadoSolidarioPM?.idObligadoSolidario !== undefined &&
                      item?.obligadoSolidarioPM?.documentosPendientes) && (
                        <p
                          className={`${selectedConsultaTradicional ===
                              item?.obligadoSolidarioPM?.idObligadoSolidario
                              ? "bold tab__selected text__red pointer tab"
                              : null
                            } pointer p-2 mb-0`}
                          onClick={() =>
                            setSelectedConsultaTradicional(
                              item?.obligadoSolidarioPM?.idObligadoSolidario
                            )
                          }
                        >
                          Obligado solidario {index + 1}
                        </p>
                      )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {(item?.obligadoSolidario?.conyuge.idConyuge !== undefined &&
                      item?.obligadoSolidario?.conyuge.generales?.esObligadoSolidario &&
                      item?.obligadoSolidario?.conyuge.documentosPendientes) && (
                        <p
                          className={`${selectedConsultaTradicional ===
                              item?.obligadoSolidario?.conyuge?.idConyuge
                              ? "bold tab__selected text__red pointer tab"
                              : null
                            } pointer p-2 mb-0`}
                          onClick={() =>
                            setSelectedConsultaTradicional(
                              item?.obligadoSolidario?.conyuge?.idConyuge
                            )
                          }
                        >
                          Cónyuge obligado solidario {index + 1}
                        </p>
                      )}
                  </div>
                </div>
              );
            })}
          </>
        </div>
        {selectedConsultaTradicional === SECCION_HISTORIAL_SOLICITANTE && (
          <ConsultaTradicionalSolicitantePM
            documento={documento}
            setStyleHr={setStyleHr}
            setLoading={setLoading}
            modeloDatos={modeloDatos}
            setContadorOS={setContadorOS}
            setShowResult={setShowResult}
            handleSeccionPyme={handleSeccionPyme}
            handleRegresarSeccionPyme={handleRegresarSeccionPyme}
            setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
            setSelectedConsultaTradicional={setSelectedConsultaTradicional}
            webView={webView}
          />
        )}
        {selectedConsultaTradicional === SECCION_HISTORIAL_PA && (
          <ConsultaTradicionalPAPM
            setStyleHr={setStyleHr}
            setLoading={setLoading}
            modeloDatos={modeloDatos}
            setContadorOS={setContadorOS}
            setShowResult={setShowResult}
            handleSeccionPyme={handleSeccionPyme}
            idCliente={principalAccionista.idPrincipalAccionista}
            setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
            setSelectedConsultaTradicional={setSelectedConsultaTradicional}
            nombrePA={
              principalAccionista?.generales?.nombre +
              " " +
              principalAccionista?.generales?.apellidoPaterno
            }
            docPendientesSolicitante={docPendientesSolicitante}
            redireccionBtnAtrasHistorialPM={redireccionBtnAtrasHistorialPM}
            webView={webView}
          />
        )}
        {selectedConsultaTradicional === SECCION_HISTORIAL_PA_COYUGE ||
          (selectedConsultaTradicional ===
            principalAccionista?.conyuge?.idConyuge &&
            principalAccionista?.conyuge?.generales?.esObligadoSolidario &&
            principalAccionista?.generales?.estadoCivilId ===
            ESTADO_CIVIL_SOCIEDAD_CONYUGAL) ? (
          <ConsultaTradicionalPAPConyugePM
            setStyleHr={setStyleHr}
            setLoading={setLoading}
            modeloDatos={modeloDatos}
            setContadorOS={setContadorOS}
            setShowResult={setShowResult}
            idCliente={principalAccionista?.conyuge?.idConyuge}
            setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
            setSelectedConsultaTradicional={setSelectedConsultaTradicional}
            nombreConyugePA={
              principalAccionista?.conyuge?.generales?.nombre +
              " " +
              principalAccionista?.conyuge?.generales?.apellidoPaterno
            }
            documentosPendientesPA={principalAccionista?.documentosPendientes}
            docPendientesSolicitante={docPendientesSolicitante}
            redireccionBtnAtrasHistorialPM={redireccionBtnAtrasHistorialPM}
            webView={webView}
          />
        ) : (
          <></>
        )}
        {obligadosSolidarios !== undefined &&
          obligadosSolidarios.map((item, index) => {
            return (
              <div key={index}>
                {selectedConsultaTradicional ===
                  item?.obligadoSolidario?.generales?.idCliente && (
                    <ConsultaTradicionalOSPMPFAE
                      numOS={index + 1}
                      setLoading={setLoading}
                      setStyleHr={setStyleHr}
                      modeloDatos={modeloDatos}
                      setShowResult={setShowResult}
                      setContadorOS={setContadorOS}
                      tipoPersonaKey={item.tipoPersonaKey}
                      handleSeccionPyme={handleSeccionPyme}
                      idCliente={item?.obligadoSolidario?.idObligadoSolidario}
                      selectedConsultaTradicional={selectedConsultaTradicional}
                      setResultadoEvaluacionRiesgos={
                        setResultadoEvaluacionRiesgos
                      }
                      setSelectedConsultaTradicional={
                        setSelectedConsultaTradicional
                      }
                      obtenerAnteriorOSHistorial={obtenerAnteriorOSHistorialPM}
                      nombreOs={
                        item?.obligadoSolidario?.generales?.nombre
                          .substring(0, 1)
                          .toUpperCase() +
                        item?.obligadoSolidario?.generales?.nombre
                          .substring(1)
                          .toLowerCase() +
                        " " +
                        item?.obligadoSolidario?.generales?.apellidoPaterno
                          .substring(0, 1)
                          .toUpperCase() +
                        item?.obligadoSolidario?.generales?.apellidoPaterno
                          .substring(1)
                          .toLowerCase()
                      }
                      redireccionBtnAtrasHistorialPM={redireccionBtnAtrasHistorialPM}
                      webView={webView}
                    />
                  )}
                {selectedConsultaTradicional ===
                  item?.obligadoSolidarioPM?.generales?.idCliente && (
                    <ConsultaTradicionalOSPMPM
                      numOS={index + 1}
                      setLoading={setLoading}
                      setStyleHr={setStyleHr}
                      modeloDatos={modeloDatos}
                      setContadorOS={setContadorOS}
                      setShowResult={setShowResult}
                      tipoPersonaKey={item.tipoPersonaKey}
                      handleSeccionPyme={handleSeccionPyme}
                      idCliente={item?.obligadoSolidarioPM?.idObligadoSolidario}
                      setResultadoEvaluacionRiesgos={
                        setResultadoEvaluacionRiesgos
                      }
                      setSelectedConsultaTradicional={
                        setSelectedConsultaTradicional
                      }
                      obtenerAnteriorOSHistorial={obtenerAnteriorOSHistorialPM}
                      razonSocialOs={
                        item?.obligadoSolidarioPM?.generales?.razonSocial
                      }
                      redireccionBtnAtrasHistorialPM={redireccionBtnAtrasHistorialPM}
                      webView={webView}
                    />
                  )}

                {selectedConsultaTradicional ===
                  item?.obligadoSolidario?.conyuge.generales?.idCliente && (
                    <ConsultaTradicionalConyugeOSPFAEPM
                      numOS={index + 1}
                      setLoading={setLoading}
                      setStyleHr={setStyleHr}
                      modeloDatos={modeloDatos}
                      setContadorOS={setContadorOS}
                      setShowResult={setShowResult}
                      tipoPersonaKey={item.tipoPersonaKey}
                      handleSeccionPyme={handleSeccionPyme}
                      idCliente={item?.obligadoSolidario?.conyuge?.idConyuge}
                      selectedConsultaTradicional={selectedConsultaTradicional}
                      setResultadoEvaluacionRiesgos={
                        setResultadoEvaluacionRiesgos
                      }
                      setSelectedConsultaTradicional={
                        setSelectedConsultaTradicional
                      }
                      obtenerAnteriorOSHistorial={obtenerAnteriorOSHistorialPM}
                      nombreOs={
                        item?.obligadoSolidario?.conyuge?.generales?.nombre +
                        " " +
                        item?.obligadoSolidario?.conyuge?.generales
                          ?.apellidoPaterno
                      }
                      redireccionBtnAtrasHistorialPM={redireccionBtnAtrasHistorialPM}
                      webView={webView}
                    />
                  )}
              </div>
            );
          })}
      </>
    </>
  );
};
export default ConsultaTradicionalPM;
