import React from "react";
import "./SelectDoubleWithError.css";
import Select from "react-select";
import { selectStylesDouble } from "../../../styles/select-styles";
import ErrorMessage from "../../helpers/ErrorMessage/ErrorMessage";

const SelectDoubleWithError = ({
  //General
  label,
  menuPlacement = "bottom",
  showErrorMessage,
  errorMessage,

  //Anios
  nameAnios,
  valueAnios,
  optionsAnios,
  errorAnios = false,
  onChangeAnios = () => {},

  //Meses
  nameMeses,
  valueMeses,
  optionsMeses,
  errorMeses = false,
  onChangeMeses = () => {},
}) => {
  return (
    <div className="select-double__container">
      <label>{label}</label>
      <div className="select-double__container--items">
        <div className="selectInputCont select__container__item">
          {valueAnios?.value >= 0 || valueAnios?.value !== "" ? (
            <span className="select-label-no-null">Años *</span>
          ) : (
            <span className="select-label">Años *</span>
          )}
          <Select
            isSearchable={false}
            styles={selectStylesDouble}
            menuPlacement={menuPlacement}
            name={nameAnios}
            value={valueAnios}
            onChange={onChangeAnios}
            error={errorAnios}
            options={optionsAnios}
          />
        </div>
        <div className="selectInputCont select__container__item">
          {valueMeses?.value >= 0 || valueMeses?.value !== "" ? (
            <span className="select-label-no-null">Meses *</span>
          ) : (
            <span className="select-label">Meses *</span>
          )}
          <Select
            isSearchable={false}
            styles={selectStylesDouble}
            menuPlacement={menuPlacement}
            name={nameMeses}
            value={valueMeses}
            onChange={onChangeMeses}
            error={errorMeses}
            options={optionsMeses}
          />
        </div>
      </div>
      {showErrorMessage && <ErrorMessage message={errorMessage} width={55} />}
    </div>
  );
};

export default SelectDoubleWithError;
