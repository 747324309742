import "./Dot.css";
import React from "react";

const Dot = ({
  content,
  onClick,
  active = false,
  hasBeenActivated = false,
}) => {
  return (
    <div
      className={`dot ${
        active ? "dot--activated" : hasBeenActivated ? "dot--bold" : null
      }`}
      style={{ pointerEvents: hasBeenActivated || active ? "auto" : "none" }}
      onClick={onClick}
    >
      <p
        className="dot--content"
        style={{
          color: active ? "#FFF" : "#323e48",
        }}
      >
        {content}
      </p>
    </div>
  );
};

export default Dot;
