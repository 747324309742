import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { BiShow } from "react-icons/bi";
import SelectWithError from "../../../basics/SelectWithError/SelectWithError";
import {
  sessionStorageMontoMinimoCredito,
  sessionStorageMontoMaximoCredito,
} from "../../../../config/Variables";
import { 
  PLAZO_CREDITO_REVOLVENTE, ID_PM, ID_PFAE, 
  MONTO_VALIDO_EXPRESS_SIMPLE_PFAE,
  MONTO_VALIDO_EXPRESS_SIMPLE_PM,
  PLAZO_NO_VALIDO_EXPRESS_SIMPLE,
  PLAZO_MAX_VALIDO_EXPRESS_SIMPLE } from "../../../../utils/constantes";
import { api_get_cat_simple } from "../../../../api/api_servicios_rest";
import { sweetAlertWarning } from "../../../helpers/alertas/alertas";

const ResumenCrediticio = ({
  showBotonActualizar,
  credito,
  setCredito,
  validarLimiteHandler,
  validarLimiteMesesHandler,
  pago,
  handlePesosCotizador,
  plazo,
  handleMensualidadCotizador,
  setPlazo,
  setShowTabla,
  actualizarDatosResumen,
  folioSolicitud,
  setShowBotonActualizar,
  idTipoPersona,
}) => {
  const [montoMinimoDeCredito, setMontoMinimoDeCredito] = useState("");
  const [montoMaximoDeCredito, setMontoMaximoDeCredito] = useState("");
  const [top, setTop] = useState(`${10}px`);
  const [cat, setCat] = useState("");
  const [showAllPlazoSimple, setShowAllPlazoSimple] = useState(true);

  const optionsPlazoSimple = [
    {
      label: 18,
      value: 18,
    },
    {
      label: 24,
      value: 24,
    },
    {
      label: 36,
      value: 36,
    },
    {
      label: 48,
      value: 48,
    },
    {
      label: 60,
      value: 60,
    },
  ];

  const optionsPlazoSimpleMin = [
    {
      label: 18,
      value: 18,
    },
    {
      label: 24,
      value: 24,
    },
    {
      label: 36,
      value: 36,
    }
  ];

  useEffect(() => {
    const montoMin = sessionStorage.getItem(sessionStorageMontoMinimoCredito);
    const montoMax = sessionStorage.getItem(sessionStorageMontoMaximoCredito);

    if (montoMin && montoMax) {
      setMontoMinimoDeCredito(montoMin);
      setMontoMaximoDeCredito(montoMax);
    }
    
    validarExpressSimplePorMonto(credito);
  }, []);

  useEffect(() => {
    obtenerCatCreditoSimple()
      .then((response) => response)
      .catch((error) => console.error(error));
  }, []);

  //Obtener el top del elemento en base al scroll que hace el usuario
  window.addEventListener("scroll", (e) => {
    let posicion = e.target.scrollingElement.scrollTop;
    let posicionMin = 460;
    if (plazo == PLAZO_CREDITO_REVOLVENTE) {
      posicionMin = 200;
    }
    if (posicion > posicionMin) {
      if (plazo == PLAZO_CREDITO_REVOLVENTE) {
        posicion = posicion - 50;
      } else {
        posicion = posicion - 240;
      }
      setTop(`${posicion}px`);
    } else {
      setTop(`10px`);
    }
  });

  const obtenerCatCreditoSimple = async () => {
    const datosCredito = {
      letPlazo: plazo,
      valorFactura: pago.replace(/,/g, ""),
    };
    try {
      const response = await api_get_cat_simple(datosCredito);
      if (response.statusText === "OK") {
        const data = response?.data;
        const cat = data?.cat * 100;
        setCat(cat);
      } else {
        sweetAlertWarning("Ocurrió un error al obtener el cat del crédito");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validarExpressSimplePorMonto = (value) => {
    if (value) {
      value = value.replaceAll("$", "");
      if (value !== "") {
        var monto = value.replace(/,/g, "");
        if(idTipoPersona === ID_PFAE && parseFloat(monto) < MONTO_VALIDO_EXPRESS_SIMPLE_PFAE){
          setShowAllPlazoSimple(false);          
        } else{
          if(idTipoPersona === ID_PM && parseFloat(monto) < MONTO_VALIDO_EXPRESS_SIMPLE_PM){
            setShowAllPlazoSimple(false);           
          }else{
            setShowAllPlazoSimple(true);
          }
        }
      }
    }
  };

  const moficarPlazoMaxExpressSimple = (value) => {
    if (value) {
      value = value.replaceAll("$", "");
      if (value !== "") {
        var monto = value.replace(/,/g, "");
        if(idTipoPersona === ID_PFAE && parseFloat(monto) < MONTO_VALIDO_EXPRESS_SIMPLE_PFAE){
          if(plazo === PLAZO_NO_VALIDO_EXPRESS_SIMPLE){
            setPlazo(PLAZO_MAX_VALIDO_EXPRESS_SIMPLE);
          }     
        } else{
          if(idTipoPersona === ID_PM && parseFloat(monto) < MONTO_VALIDO_EXPRESS_SIMPLE_PM){
            if(plazo === PLAZO_NO_VALIDO_EXPRESS_SIMPLE){
              setPlazo(PLAZO_MAX_VALIDO_EXPRESS_SIMPLE);
            }
          }
        }
      }
    }
  };

  return (
    <div className="card-cotizador-formulario" style={{ top }}>
      <h3 className="resumenCrediticio--titulo">Datos de la solicitud</h3>
      {folioSolicitud && (
        <>
          <h3>Folio: {folioSolicitud}</h3>
          <h6>Conserva este folio para el seguimiento de tu caso</h6>
        </>
      )}
      <div className="card-cotizador--info">
        <TextField
          disabled={showBotonActualizar ? false : true}
          label="Crédito"
          className="card-cotizador--input"
          value={"$" + credito}
          onChange={(e) => {
              handlePesosCotizador(e, setCredito);
              validarExpressSimplePorMonto(e.target.value);
            }
          }
          onBlur={(e) => {
           // moficarPlazoMaxExpressSimple(e.target.value);
            validarLimiteHandler(e);
          }}
          type="text"
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
            inputProps: {
              min: montoMinimoDeCredito,
              max: montoMaximoDeCredito,
              maxLength: 10,
            },
          }}
          required={true}
        />
        {plazo !== PLAZO_CREDITO_REVOLVENTE && (
          <div>
            <h3 className="resumenCrediticio--pago-text">
              Pago mensual estimado *
            </h3>
            <p className="resumenCrediticio--pago">${pago}</p>
          </div>
        )}
        {plazo === PLAZO_CREDITO_REVOLVENTE && (
          <>
            <p className="resumenCrediticio--pago-revolvente" />
            <TextField
              disabled={true}
              label="Mensualidades"
              className="card-cotizador--input"
              value={`${plazo ? plazo : ""}`}
              onChange={(e) => handleMensualidadCotizador(e, setPlazo)}
              onBlur={validarLimiteMesesHandler}
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">MESES</InputAdornment>
                ),
                inputProps: { maxLength: 2 },
              }}
              required={true}
            />
          </>
        )}
        {plazo !== PLAZO_CREDITO_REVOLVENTE && (
          <SelectWithError
            label="Plazo *"
            isDisabled={!showBotonActualizar}
            width="250px"
            isResumenCrediticio={true}
            value={{ label: plazo, value: plazo }}
            onChange={(e) => {
              setPlazo(e.value);
              validarLimiteMesesHandler(e);
            }}
            options={
              showAllPlazoSimple ?
              optionsPlazoSimple.map((item) => {
                return { ...item, label: item.label, value: item.value };
              }) : 
              optionsPlazoSimpleMin.map((item) => {
                return { ...item, label: item.label, value: item.value };
              })
            }
          />
        )}

        <div className="resumenCrediticio--buttons">
          {plazo !== PLAZO_CREDITO_REVOLVENTE && (
            <span>CAT promedio {cat} % sin IVA</span>
          )}
          {folioSolicitud !== undefined &&
            folioSolicitud !== null &&
            folioSolicitud !== "" && (
              <span
                onClick={() => setShowBotonActualizar(true)}
                className="pointer"
              >
                Modificar información del crédito &nbsp;&nbsp;
                <FiEdit size="20px" />
              </span>
            )}

          {plazo !== PLAZO_CREDITO_REVOLVENTE && (
            <>
              <span onClick={() => setShowTabla(true)} className="pointer">
                Tabla de amortización &nbsp;&nbsp;
                <BiShow size="20px" />
              </span>
              <span>
                *La información de esta tabla de amortización es estimada y puede variar al
                contratar.
              </span>
            </>
          )}
          <h5 className="resumenCrediticio--leyenda">
            En caso de que tengas alguna duda sobre el proceso, por favor
            comunícate al 800 890 2580 con tu folio {folioSolicitud}
          </h5>
        </div>
        {showBotonActualizar && (
          <div className="d-flex justify-content-center">
            <button
              className="button-primary"
              type="submit"
              onClick={() => {
                
                  actualizarDatosResumen();
                }
              }
            >
              <span> Actualizar</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumenCrediticio;
