import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

const CheckBoxInput = ({
  typeOfCheck = "circle",
  checked = true,
  onChange = () => {},
  required,
  disable = false,
  style = {},
}) => {
  return (
    <>
      {!disable ? (
        <>
          {typeOfCheck === "normal" ? (
            <Checkbox
              checked={checked}
              onChange={onChange}
              required={required}
              style={{
                ...style,
                color: "#eb0029",
              }}
            />
          ) : (
            <Checkbox
              checked={checked}
              onChange={onChange}
              required={required}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              style={{
                ...style,
                color: "#eb0029",
              }}
            />
          )}
        </>
      ) : (
        <>
          {typeOfCheck === "normal" ? (
            <Checkbox
              checked={checked}
              onChange={onChange}
              required={required}
              style={{
                ...style,
                color: "#eb0029",
              }}
              disabled
            />
          ) : (
            <Checkbox
              checked={checked}
              required={required}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              style={{
                ...style,
                color: "#eb0029",
              }}
              disabled
            />
          )}
        </>
      )}
    </>
  );
};

export default CheckBoxInput;
