import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import "moment/locale/es";
import "react-datetime/css/react-datetime.css";
import {
  api_direccion_by_cp,
  api_save_os_pm_pfae,
} from "../../../../../api/api_servicios_rest";
import {
  fechaActualYYYMMDD,
  pmFechaAltaSHCPVsRfc,
  obtenerFechaShcpByRfc,
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
  validarRFCTipoPersona,
} from "../../../../../utils/validaciones";
import {
  TIPO_DEPARTAMENTO_APARTAMENTO,
  TIPO_VIVIENDA_EDIFICIO,
  ID_PAIS_MEXICO,
  ID_PM,
  MENSAJE_ERROR_TOKEN_INVALIDO,
} from "../../../../../utils/constantes";
import { mensajeMostrarUsuario } from "../../../../../utils/mensajesServicios";
import CheckBoxInput from "../../../../basics/checkBox";
import {
  validateValue,
  textToUpperCase,
  validarTextoConPunto,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../../functions/fieldFunctions";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import SelectWithErrorActividad from "../../../../basics/SelectWithError/SelectWithErrorActividad";
import SelectWithErrorTooltipCompuesto from "../../../../basics/SelectWithError/SelectWithErrorTooltipCompuesto";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../../helpers/alertas/alertas";

const ObligadoSolidarioPM_PFAE = ({
  modeloPfae,
  axiosData,
  anios,
  NUM_OS,
  idTipoPersonaOS,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setObligadosSolidariosInformacionBasica,
  setLoading,
  seccionActual,
  datosOs,
  setDatosOs,
  setUltimoPasoSolicitud,
  webView,
}) => {
  const [coloniasCP, setColoniasCP] = useState([]);
  const [rfcCliente, setRfcCliente] = useState("");
  const [startDateFechaSHCP, setStartDateFechaSHCP] = useState("");

  const formik = useFormik({
    initialValues: initialValues(modeloPfae, idTipoPersonaOS, NUM_OS, datosOs),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema(rfcCliente)),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosOSPM_PFAE(formData)
        .then(() => setLoading(false))
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    },
  });

  const obtenerDireccionByCp = async (codigoPostal) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK") {
        if (response.data.municipio !== undefined) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "obligadoSolidarioPM.direccion.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue(
            "obligadoSolidarioPM.direccion.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response.data.municipio.id
            )
          );
          formik.setFieldValue(
            "obligadoSolidarioPM.direccion.estadoId",
            response.data.estado.id
          );
          formik.setFieldValue(
            "obligadoSolidarioPM.direccion.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response.data.estado.id
            )
          );
          formik.setFieldValue(
            "obligadoSolidarioPM.direccion.colonia",
            response.data.asentamientos[0]
          );

          let newErrors = formik.errors;
          delete newErrors?.obligadoSolidarioPM?.direccion?.municipioId;
          delete newErrors?.obligadoSolidarioPM?.direccion?.estadoId;
          delete newErrors?.obligadoSolidarioPM?.direccion?.colonia;
          formik.setErrors(newErrors);
        } else {
          limpiarDireccionByCp();
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue("obligadoSolidarioPM.direccion.municipioId", "");
    formik.setFieldValue("obligadoSolidarioPM.direccion.municipioObj", "");
    formik.setFieldValue("obligadoSolidarioPM.direccion.estadoId", "");
    formik.setFieldValue("obligadoSolidarioPM.direccion.estadoObj", "");
    formik.setFieldValue("obligadoSolidarioPM.direccion.colonia", "");
  };

  const obtenerFechaShcp = (rfc) => {
    let fechaAltaShcpByRfc = obtenerFechaShcpByRfc(rfc);
    setStartDateFechaSHCP(fechaAltaShcpByRfc);
    formik.setFieldValue(
      "obligadoSolidarioPM.datosDelNegocio.fechaAltaShcp",
      fechaAltaShcpByRfc.split("-").reverse().join("-")
    );
    let newErrors = formik.errors;
    delete newErrors?.obligadoSolidarioPM?.datosDelNegocio?.fechaAltaShcp;
    formik.setErrors(newErrors);
  };

  const salvarDatosOSPM_PFAE = async (datosOS) => {
    try {
      const response = await api_save_os_pm_pfae(datosOS);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setObligadosSolidariosInformacionBasica(
            data.obligadosSolidariosInformacionBasica
          );

          setDatosOs({
            datosOS: data.datosOS,
            idTipoPersonaOs: data.obligadoSolidarioPmIdTipoPersona,
          });
          setObligadosSolidariosInformacionBasica(
            data.obligadosSolidariosInformacionBasica
          );
          setUltimoPasoSolicitud(data.solicitudUltimoPaso);
          setStyleHr("block");
          setLoading(false);
          handleSeccionPyme(seccionActual);
        } else {
          setLoading(false);
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            const mensajeError = mensajeMostrarUsuario(data.mensaje);
            sweetAlertError(mensajeError);
          }
        }
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al guardar OS PM de PFAE");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (datosOs.datosOS.length !== 0) {
      setRfcCliente(datosOs.datosOS.generales.rfc);

      formik.setFieldValue(
        "obligadoSolidarioPM.direccion.tipoDomicilioObj",
        axiosData?.domicilio?.tipoDeDomicilioOpciones?.filter(
          (e) => e.id === datosOs.datosOS.direccion.tipoDomicilioId
        )
      );

      formik.setFieldValue(
        "obligadoSolidarioPM.direccion.tipoViviendaObj",
        axiosData?.domicilio?.tipoDeViviendaOpciones?.filter(
          (e) => e.id === datosOs.datosOS.direccion.tipoViviendaId
        )
      );

      if (datosOs?.datosOS?.generales?.paisResidenciaFiscal2Id === null) {
        formik.setFieldValue(
          "obligadoSolidarioPM.generales.paisResidenciaFiscal2Id",
          ""
        );
      }

      if (datosOs?.datosOS?.datosDelNegocio?.idDatosDelNegocio !== null) {
        if (datosOs?.datosOS?.datosDelNegocio?.fechaAltaShcp !== null) {
          setStartDateFechaSHCP(
            datosOs?.datosOS?.datosDelNegocio?.fechaAltaShcp
              .split("-")
              .reverse()
              .join("-")
          );
        }

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.importaExportaObj",
          axiosData?.domicilioFiscal?.importaExportaOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.importaExportaId
          )
        );

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.sectorObj",
          axiosData?.domicilioFiscal?.sectorOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.sectorId
          )
        );

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.giroEmpresarialObj",
          axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.giroEmpresarialId
          )
        );

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.actividadObj",
          axiosData?.domicilioFiscal?.actividadOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.actividadId
          )
        );
      } else {
        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.importaExportaId",
          ""
        );

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.sectorId",
          ""
        );

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.giroEmpresarialId",
          ""
        );

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.actividadId",
          ""
        );

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.numeroSucursales",
          ""
        );

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.aniosExperienciaSector",
          ""
        );

        formik.setFieldValue(
          "obligadoSolidarioPM.datosDelNegocio.fechaAltaShcp",
          ""
        );
      }

      obtenerDireccionByCp(datosOs.datosOS.direccion.codigoPostal);

      if (datosOs?.datosOS?.direccion?.piso === null) {
        formik.setFieldValue("obligadoSolidarioPM.direccion.piso", "");
      }

      if (datosOs?.datosOS?.direccion?.numeroInterior === null) {
        formik.setFieldValue(
          "obligadoSolidarioPM.direccion.numeroInterior",
          ""
        );
      }
    }
  }, []);

  useEffect(() => {
    if (idTipoPersonaOS !== null) {
      formik.setFieldValue(
        "obligadoSolidarioPM.generales.tipoPersonaId",
        idTipoPersonaOS
      );
    }
  }, [idTipoPersonaOS]);

  return (
    <>
      <form name="form-os-pm-pm" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Información general</h2>
          <FormLayout>
            <InputWithError
              label="Razón Social *"
              name="obligadoSolidarioPM.generales.razonSocial"
              value={
                formik.values?.obligadoSolidarioPM?.generales?.razonSocial
                  ? formik.values?.obligadoSolidarioPM?.generales?.razonSocial
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.generales !== undefined &&
                  newErrors?.obligadoSolidarioPM?.generales?.razonSocial
                ) {
                  delete newErrors?.obligadoSolidarioPM?.generales?.razonSocial;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConPunto(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "obligadoSolidarioPM.generales.razonSocial",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 13 }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.generales?.razonSocial
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.generales?.razonSocial
              }
            />
            <InputWithError
              name="obligadoSolidarioPM.generales.rfc"
              label="RFC *"
              value={
                formik.values?.obligadoSolidarioPM?.generales?.rfc
                  ? formik.values?.obligadoSolidarioPM?.generales?.rfc
                  : ""
              }
              onBlur={(e) => {
                if (e.target.value) {
                  obtenerFechaShcp(e.target.value);
                }
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.generales !== undefined &&
                  newErrors?.obligadoSolidarioPM?.generales?.rfc
                ) {
                  delete newErrors?.obligadoSolidarioPM?.generales?.rfc;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "obligadoSolidarioPM.generales.rfc",
                    newValue
                  );
                  setRfcCliente(e.target.value);
                }
              }}
              InputProps={{ maxLength: 13 }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.generales?.rfc
              }
              errorMessage={formik.errors?.obligadoSolidarioPM?.generales?.rfc}
            />
            <InputWithError
              name="obligadoSolidarioPM.generales.correoElectronico"
              value={
                formik.values?.obligadoSolidarioPM?.generales?.correoElectronico
                  ? formik.values?.obligadoSolidarioPM?.generales
                      ?.correoElectronico
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.generales !== undefined &&
                  newErrors?.obligadoSolidarioPM?.generales?.correoElectronico
                ) {
                  delete newErrors?.obligadoSolidarioPM?.generales
                    ?.correoElectronico;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.generales.correoElectronico",
                  e.target.value
                );
              }}
              type="email"
              label="Correo electrónico *"
              InputProps={{ maxLength: 50 }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.generales?.correoElectronico
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.generales?.correoElectronico
              }
            />
            <InputWithError
              name="obligadoSolidarioPM.datosDelNegocio.fechaAltaShcp"
              type="date"
              label="Fecha de alta en SHCP *"
              value={startDateFechaSHCP ? startDateFechaSHCP : ""}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio?.fechaAltaShcp
                ) {
                  delete newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.fechaAltaShcp;
                  formik.setErrors(newErrors);
                }

                let array = e.target.value.split("-").reverse().join("-");
                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.fechaAltaShcp",
                  array
                );
                setStartDateFechaSHCP(e.target.value);
              }}
              InputProps={{ max: fechaActualYYYMMDD() }}
              InputLabelProps={{ shrink: true }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.fechaAltaShcp
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.fechaAltaShcp
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="obligadoSolidarioPM.generales.esAccionistaOtraEmpresa"
                  state=""
                  value={
                    formik.values?.obligadoSolidarioPM?.generales
                      ?.esAccionistaOtraEmpresa
                      ? formik.values?.obligadoSolidarioPM?.generales
                          ?.esAccionistaOtraEmpresa
                      : ""
                  }
                  checked={
                    formik.values?.obligadoSolidarioPM?.generales
                      ?.esAccionistaOtraEmpresa
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "obligadoSolidarioPM.generales.esAccionistaOtraEmpresa",
                      !formik.values?.obligadoSolidarioPM?.generales
                        ?.esAccionistaOtraEmpresa
                    )
                  }
                  typeOfCheck="normal"
                  id="obligadoSolidarioOtraEmpresa"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  htmlFor="obligadoSolidarioOtraEmpresa"
                >
                  El obligado es accionista u obligado de otra empresa
                </label>
              </div>
              {formik.values?.obligadoSolidarioPM?.generales
                ?.esAccionistaOtraEmpresa && (
                <InputWithError
                  name="obligadoSolidarioPM.generales.nombreOtraEmpresa"
                  label="Nombre de la empresa o PFAE *"
                  value={
                    formik.values?.obligadoSolidarioPM?.generales
                      ?.nombreOtraEmpresa
                      ? formik.values?.obligadoSolidarioPM?.generales
                          ?.nombreOtraEmpresa
                      : ""
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.obligadoSolidarioPM !== undefined &&
                      newErrors?.obligadoSolidarioPM?.generales !== undefined &&
                      newErrors?.obligadoSolidarioPM?.generales
                        ?.nombreOtraEmpresa
                    ) {
                      delete newErrors?.obligadoSolidarioPM?.generales
                        ?.nombreOtraEmpresa;
                      formik.setErrors(newErrors);
                    }

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "obligadoSolidarioPM.generales.nombreOtraEmpresa",
                        e.target.value
                      );
                    }
                  }}
                />
              )}
              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="obligadoSolidarioPM.generales.esObligadoSolidarioOtraEmpresaPfae"
                  state=""
                  value={
                    formik.values?.obligadoSolidarioPM?.generales
                      ?.esObligadoSolidarioOtraEmpresaPfae
                  }
                  checked={
                    formik.values?.obligadoSolidarioPM?.generales
                      ?.esObligadoSolidarioOtraEmpresaPfae
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "obligadoSolidarioPM.generales.esObligadoSolidarioOtraEmpresaPfae",
                      !formik.values?.obligadoSolidarioPM?.generales
                        ?.esObligadoSolidarioOtraEmpresaPfae
                    )
                  }
                  type="checkbox"
                  id="obligadoSolidarioOtraEmpresaPfae"
                  typeOfCheck="normal"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  htmlFor="obligadoSolidarioOtraEmpresaPfae"
                >
                  Es obligado o fiador de otra empresa o PFAE
                </label>
              </div>
              {formik.values?.obligadoSolidarioPM?.generales
                ?.esObligadoSolidarioOtraEmpresaPfae && (
                <InputWithError
                  name="obligadoSolidarioPM.generales.nombreOtraEmpresaPfae"
                  label="Nombre de la empresa o PFAE *"
                  value={
                    formik.values?.obligadoSolidarioPM?.generales
                      ?.nombreOtraEmpresaPfae
                      ? formik.values?.obligadoSolidarioPM?.generales
                          ?.nombreOtraEmpresaPfae
                      : ""
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.obligadoSolidarioPM !== undefined &&
                      newErrors?.obligadoSolidarioPM?.generales !== undefined &&
                      newErrors?.obligadoSolidarioPM?.generales
                        ?.nombreOtraEmpresaPfae
                    ) {
                      delete newErrors?.obligadoSolidarioPM?.generales
                        ?.nombreOtraEmpresaPfae;
                      formik.setErrors(newErrors);
                    }

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "obligadoSolidarioPM.generales.nombreOtraEmpresaPfae",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                />
              )}
            </div>
            <h2
              style={{
                textAlign: "left",
                width: "100%",
                margin: "1rem 0 ",
              }}
              className="f-s-3"
            >
              Representante legal
            </h2>
            <InputWithError
              label="Nombre completo *"
              name="obligadoSolidarioPM.representanteLegal.nombre"
              value={
                formik.values?.obligadoSolidarioPM?.representanteLegal?.nombre
                  ? formik.values?.obligadoSolidarioPM?.representanteLegal
                      ?.nombre
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.representanteLegal !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.representanteLegal?.nombre
                ) {
                  delete newErrors?.obligadoSolidarioPM?.representanteLegal
                    ?.nombre;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "obligadoSolidarioPM.representanteLegal.nombre",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.representanteLegal?.nombre
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.representanteLegal?.nombre
              }
            />
            <InputWithError
              label="Cargo *"
              name="obligadoSolidarioPM.representanteLegal.cargo"
              value={
                formik.values?.obligadoSolidarioPM?.representanteLegal?.cargo
                  ? formik.values?.obligadoSolidarioPM?.representanteLegal
                      ?.cargo
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.representanteLegal !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.representanteLegal?.cargo
                ) {
                  delete newErrors?.obligadoSolidarioPM?.representanteLegal
                    ?.cargo;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "obligadoSolidarioPM.representanteLegal.cargo",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.representanteLegal?.cargo
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.representanteLegal?.cargo
              }
            />
            <InputWithError
              name="obligadoSolidarioPM.representanteLegal.telefono"
              label="Teléfono *"
              value={
                formik.values?.obligadoSolidarioPM?.representanteLegal?.telefono
                  ? formik.values?.obligadoSolidarioPM?.representanteLegal
                      ?.telefono
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.representanteLegal !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.representanteLegal?.telefono
                ) {
                  delete newErrors?.obligadoSolidarioPM?.representanteLegal
                    ?.telefono;
                  formik.setErrors(newErrors);
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.obligadoSolidarioPM?.representanteLegal
                    ?.telefono
                );
                formik.setFieldValue(
                  "obligadoSolidarioPM.representanteLegal.telefono",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.representanteLegal?.telefono
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.representanteLegal?.telefono
              }
            />
            <h2
              style={{
                textAlign: "left",
                width: "100%",
                margin: "1rem 0 ",
              }}
              className="f-s-3"
            >
              Información de domicilio
            </h2>
            <InputWithError
              label="Calle *"
              name="obligadoSolidarioPM.direccion.calle"
              value={
                formik.values?.obligadoSolidarioPM?.direccion?.calle
                  ? formik.values?.obligadoSolidarioPM?.direccion?.calle
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.calle
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion?.calle;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "obligadoSolidarioPM.direccion.calle",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 24 }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.calle
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.calle
              }
            />
            <InputWithError
              label="Número exterior *"
              name="obligadoSolidarioPM.direccion.numeroExterior"
              value={
                formik.values?.obligadoSolidarioPM?.direccion?.numeroExterior
                  ? formik.values?.obligadoSolidarioPM?.direccion
                      ?.numeroExterior
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.numeroExterior
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion
                    ?.numeroExterior;
                  formik.setErrors(newErrors);
                }

                if (notSpecialCaractersConGuion(e.target.value)) {
                  formik.setFieldValue(
                    "obligadoSolidarioPM.direccion.numeroExterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 7 }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.numeroExterior
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.numeroExterior
              }
            />
            <InputWithError
              name="obligadoSolidarioPM.direccion.numeroInterior"
              label="Número interior"
              value={
                formik.values?.obligadoSolidarioPM?.direccion?.numeroInterior
                  ? formik.values?.obligadoSolidarioPM?.direccion
                      ?.numeroInterior
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.numeroInterior
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion
                    ?.numeroInterior;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "obligadoSolidarioPM.direccion.numeroInterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu “Número exterior” separados por un guion Ejemplo: 318-123B"
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.numeroInterior
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.numeroInterior
              }
              required={
                formik.values.obligadoSolidarioPM.direccion.tipoViviendaId ===
                  parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.obligadoSolidarioPM.direccion.tipoViviendaId ===
                  parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
            />
            <InputWithError
              required={
                formik.values.obligadoSolidarioPM.direccion?.tipoViviendaId ===
                  parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.obligadoSolidarioPM.direccion?.tipoViviendaId ===
                  parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
              name="obligadoSolidarioPM.direccion.piso"
              label="Piso"
              value={
                formik.values?.obligadoSolidarioPM?.direccion?.piso
                  ? formik.values?.obligadoSolidarioPM?.direccion?.piso
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.piso
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion?.piso;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "obligadoSolidarioPM.direccion.piso",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.piso
              }
              errorMessage={formik.errors?.obligadoSolidarioPM?.direccion?.piso}
            />
            <InputWithError
              label="CP *"
              name="obligadoSolidarioPM.direccion.codigoPostal"
              value={
                formik.values?.obligadoSolidarioPM?.direccion?.codigoPostal
                  ? formik.values?.obligadoSolidarioPM?.direccion?.codigoPostal
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.codigoPostal
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion
                    ?.codigoPostal;
                  formik.setErrors(newErrors);
                }

                if (
                  e.target.value.length <= 5 &&
                  validateValue(e.target.value, "number")
                ) {
                  formik.setFieldValue(
                    "obligadoSolidarioPM.direccion.codigoPostal",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length === 5) {
                  obtenerDireccionByCp(e.target.value);
                } else {
                  limpiarDireccionByCp();
                }
              }}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.codigoPostal
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.codigoPostal
              }
            />
            <SelectWithError
              label="Colonia *"
              name="obligadoSolidarioPM.direccion.colonia"
              value={{
                label: formik.values?.obligadoSolidarioPM?.direccion?.colonia
                  ? formik.values?.obligadoSolidarioPM?.direccion?.colonia
                  : "",
                value: formik.values?.obligadoSolidarioPM?.direccion?.colonia
                  ? formik.values?.obligadoSolidarioPM?.direccion?.colonia
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.colonia
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion?.colonia;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.direccion.colonia",
                  e.label
                );
              }}
              options={coloniasCP?.map((e) => {
                return { ...e, label: e, value: e };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.colonia
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.colonia
              }
            />
            <SelectWithError
              label="Alcaldía / Municipio *"
              name="obligadoSolidarioPM.direccion.municipioId"
              value={{
                label: formik?.values?.obligadoSolidarioPM?.direccion
                  ?.municipioObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.direccion
                      ?.municipioObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidarioPM?.direccion
                  ?.municipioObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.direccion
                      ?.municipioObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.municipioId
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion?.municipioId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.direccion.municipioId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidarioPM.direccion.municipioObj",
                  [e]
                );
              }}
              isDisabled={true}
              options={axiosData?.generales?.municipios?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.municipioId
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.municipioId
              }
            />
            <SelectWithError
              label="Estado *"
              name="obligadoSolidarioPM.direccion.estadoId"
              value={{
                label: formik?.values?.obligadoSolidarioPM?.direccion
                  ?.estadoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.direccion
                      ?.estadoObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidarioPM?.direccion
                  ?.estadoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.direccion
                      ?.estadoObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.estadoId
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion?.estadoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.direccion.estadoId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidarioPM.direccion.estadoObj",
                  [e]
                );
              }}
              isDisabled={true}
              options={axiosData?.generales?.estados?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.estadoId
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.estadoId
              }
            />
            <SelectWithError
              label="Tipo de domicilio *"
              name="obligadoSolidarioPM.direccion.tipoDomicilioId"
              value={{
                label: formik?.values?.obligadoSolidarioPM?.direccion
                  ?.tipoDomicilioObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.direccion
                      ?.tipoDomicilioObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidarioPM?.direccion
                  ?.tipoDomicilioObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.direccion
                      ?.tipoDomicilioObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.tipoDomicilioId
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion
                    ?.tipoDomicilioId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.direccion.tipoDomicilioId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidarioPM.direccion.tipoDomicilioObj",
                  [e]
                );
              }}
              options={axiosData?.domicilio?.tipoDeDomicilioOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.tipoDomicilioId
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.tipoDomicilioId
              }
            />
            <SelectWithError
              label="Tipo de vivienda *"
              name="obligadoSolidarioPM.direccion.tipoViviendaId"
              value={{
                label: formik?.values?.obligadoSolidarioPM?.direccion
                  ?.tipoViviendaObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.direccion
                      ?.tipoViviendaObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidarioPM?.direccion
                  ?.tipoViviendaObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.direccion
                      ?.tipoViviendaObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion !== undefined &&
                  newErrors?.obligadoSolidarioPM?.direccion?.tipoViviendaId
                ) {
                  delete newErrors?.obligadoSolidarioPM?.direccion
                    ?.tipoViviendaId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.direccion.tipoViviendaId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidarioPM.direccion.tipoViviendaObj",
                  [e]
                );
              }}
              options={axiosData?.domicilio?.tipoDeViviendaOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.tipoViviendaId
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.direccion?.tipoViviendaId
              }
            />
            <h2
              style={{
                textAlign: "left",
                width: "100%",
                margin: "1rem 0 ",
              }}
              className="f-s-3"
            >
              Información del negocio
            </h2>
            <SelectWithError
              label="¿Realizas actividades de importación o exportación? *"
              name="obligadoSolidarioPM.datosDelNegocio.importaExportaId"
              value={{
                label: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.importaExportaObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.importaExportaObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.importaExportaObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.importaExportaObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.importaExportaId
                ) {
                  delete newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.importaExportaId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.importaExportaId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.importaExportaObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.importaExportaOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.importaExportaId
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.importaExportaId
              }
            />
            <SelectWithErrorTooltipCompuesto
              name="obligadoSolidarioPM.datosDelNegocio.giroEmpresarialId"
              label="Giro *"
              value={{
                label: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.giroEmpresarialObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.giroEmpresarialObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.giroEmpresarialObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.giroEmpresarialObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.giroEmpresarialId
                ) {
                  delete newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.giroEmpresarialId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.giroEmpresarialId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.giroEmpresarialObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              tooltipA="Comercio: Supermercados, tiendas, farmacias, ferreterías, etc."
              tooltipB="Industria: Manufacturera, constructoras, textil, alimenticia, etc."
              tooltipC="Servicios: Escuelas, hospitales, despachos, transporte, médicos, dentista, restaurantes, etc."
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.giroEmpresarialId
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.giroEmpresarialId
              }
            />
            <SelectWithError
              label="Sector *"
              name="obligadoSolidarioPM.datosDelNegocio.sectorId"
              value={{
                label: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.sectorObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.sectorObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.sectorObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.sectorObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio?.sectorId
                ) {
                  delete newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.sectorId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.sectorId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.sectorObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.sectorOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio?.sectorId
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio?.sectorId
              }
            />
            <SelectWithErrorActividad
              label="Actividad *"
              menuPlacement="top"
              name="obligadoSolidarioPM.datosDelNegocio.actividadId"
              value={{
                label: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.actividadObj?.[0]?.detalleClave
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.actividadObj?.[0]?.detalleClave
                  : "",
                value: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.actividadObj?.[0]?.detalleClave
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.actividadObj?.[0]?.detalleClave
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio?.actividadId
                ) {
                  delete newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.actividadId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.actividadId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.actividadObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.actividadOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.detalleClave,
                    value: item.detalleClave,
                  };
                }
              )}
              tooltip="Selecciona la actividad que mas se asemeje a tu alta en hacienda. En caso de que tengas varias actividades, selecciona la preponderante."
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio?.actividadId
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio?.actividadId
              }
            />
            <InputWithError
              label="Número de sucursales *"
              name="obligadoSolidarioPM.datosDelNegocio.numeroSucursales"
              value={
                formik.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.numeroSucursales
                  ? formik.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.numeroSucursales
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.numeroSucursales
                ) {
                  delete newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.numeroSucursales;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "number")) {
                  formik.setFieldValue(
                    "obligadoSolidarioPM.datosDelNegocio.numeroSucursales",
                    e.target.value
                  );
                }
              }}
              type="number"
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.numeroSucursales
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.numeroSucursales
              }
            />
            <SelectWithError
              label="Años de experiencia en el sector *"
              name="obligadoSolidarioPM.datosDelNegocio.aniosExperienciaSector"
              value={{
                label: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.aniosExperienciaSector
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.aniosExperienciaSector
                  : "",
                value: formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                  ?.aniosExperienciaSector
                  ? formik?.values?.obligadoSolidarioPM?.datosDelNegocio
                      ?.aniosExperienciaSector
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidarioPM !== undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio !==
                    undefined &&
                  newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.aniosExperienciaSector
                ) {
                  delete newErrors?.obligadoSolidarioPM?.datosDelNegocio
                    ?.aniosExperienciaSector;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidarioPM.datosDelNegocio.aniosExperienciaSector",
                  e.value
                );
              }}
              options={anios?.map((item, index) => {
                return {
                  ...item,
                  label: index + 1,
                  value: index + 1,
                };
              })}
              menuPlacement="top"
              showErrorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.aniosExperienciaSector
              }
              errorMessage={
                formik.errors?.obligadoSolidarioPM?.datosDelNegocio
                  ?.aniosExperienciaSector
              }
            />
            <div className="button-siguiente">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(seccionActual);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit">Siguiente</PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default ObligadoSolidarioPM_PFAE;

function initialValues(modeloPfae, idTipoPersonaOS, NUM_OS, datosOs) {
  if (datosOs.datosOS.length == 0) {
    return {
      idCliente: modeloPfae.idCliente,
      idSolicitud: modeloPfae.idSolicitud,
      obligadoSolidarioPM: {
        generales: {
          tipoPersonaId: idTipoPersonaOS,
          numero: NUM_OS,
          relacionRamaGobierno: false,
          paisResidenciaFiscal2: false,
          residenciaUsa: false,
          esAccionistaOtraEmpresa: false,
          esObligadoSolidarioOtraEmpresaPfae: false,
          razonSocial: "",
          rfc: "",
          correoElectronico: "",
        },
        representanteLegal: {
          nombre: "",
          cargo: "",
          telefono: "",
        },
        direccion: {
          calle: "",
          numeroExterior: "",
          numeroInterior: "",
          piso: "",
          paisId: ID_PAIS_MEXICO,
          codigoPostal: "",
          colonia: "",
          municipioId: "",
          estadoId: "",
          tipoDomicilioId: "",
          tipoViviendaId: "",
          calleUno: "",
          calleDos: "",
        },
        datosDelNegocio: {
          importaExportaId: "",
          sectorId: "",
          giroEmpresarialId: "",
          actividadId: "",
          aniosExperienciaSector: "",
          numeroSucursales: "",
          fechaAltaShcp: "",
        },
      },
    };
  } else {
    return {
      idCliente: modeloPfae.idCliente,
      idSolicitud: modeloPfae.idSolicitud,
      obligadoSolidarioPM: datosOs.datosOS,
    };
  }
}

function validationSchema(rfcCliente) {
  return {
    obligadoSolidarioPM: Yup.object().shape({
      generales: Yup.object().shape({
        razonSocial: Yup.string().required("Dato requerido."),
        rfc: Yup.string()
          .required("Dato requerido.")
          .test("rfc", "RFC inválido.", (item, testContext) => {
            return testContext.parent.rfc !== undefined
              ? testContext.parent.rfc.trim().length > 0
                ? validarRFCTipoPersona(testContext.parent.rfc.trim(), ID_PM)
                : false
              : false;
          }),
        correoElectronico: Yup.string()
          .email("Correo eléctronico inválido")
          .required("Dato requerido."),
      }),
      representanteLegal: Yup.object().shape({
        nombre: Yup.string().required("Dato requerido."),
        cargo: Yup.string().required("Dato requerido."),
        telefono: Yup.string()
          .required("Dato requerido.")
          .test(
            "telefono",
            "Teléfono capturado de forma incorrecta.",
            (item, testContext) => {
              return validarNumeroTelefono(testContext.parent.telefono);
            }
          ),
      }),
      direccion: Yup.object().shape({
        calle: Yup.string().required("Dato requerido."),
        numeroExterior: Yup.string().required("Dato requerido."),
        numeroInterior: Yup.string().when("tipoViviendaId", {
          is: TIPO_VIVIENDA_EDIFICIO,
          then: Yup.string().required("Dato requerido."),
          otherwise: Yup.string().when("tipoViviendaId", {
            is: TIPO_DEPARTAMENTO_APARTAMENTO,
            then: Yup.string().required("Dato requerido."),
          }),
        }),
        piso: Yup.string().when("tipoViviendaId", {
          is: TIPO_VIVIENDA_EDIFICIO,
          then: Yup.string().required("Dato requerido."),
          otherwise: Yup.string().when("tipoViviendaId", {
            is: TIPO_DEPARTAMENTO_APARTAMENTO,
            then: Yup.string().required("Dato requerido."),
          }),
        }),
        paisId: Yup.string().required("Dato requerido."),
        codigoPostal: Yup.string().required("Dato requerido."),
        colonia: Yup.string().required("Dato requerido."),
        municipioId: Yup.string().required("Dato requerido."),
        estadoId: Yup.string().required("Dato requerido."),
        tipoDomicilioId: Yup.string().required("Dato requerido."),
        tipoViviendaId: Yup.string().required("Dato requerido."),
      }),
      datosDelNegocio: Yup.object().shape({
        importaExportaId: Yup.number().required("Dato requerido."),
        sectorId: Yup.string().required("Dato requerido."),
        giroEmpresarialId: Yup.string().required("Dato requerido."),
        actividadId: Yup.string().required("Dato requerido."),
        aniosExperienciaSector: Yup.string().required("Dato requerido."),
        numeroSucursales: Yup.number().required("Dato requerido."),
        fechaAltaShcp: Yup.string()
          .required("Dato requerido.")
          .test(
            "fechaAltaShcp",
            "La fecha no coincide con el RFC",
            (item, testContext) => {
              return pmFechaAltaSHCPVsRfc(
                rfcCliente,
                testContext.parent.fechaAltaShcp
              );
            }
          ),
      }),
    }),
  };
}
