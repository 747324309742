import React from "react";
import {
  SECCION_GENERALES,
  SECCION_DOMICILIO,
  SECCION_DOMICILIO_FISCAL,
  SECCION_OBLIGADO_SOLIDARIO,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  SECCION_CONYUGE_OS_UNO,
  SECCION_CONYUGE_OS_DOS,
  SECCION_CONYUGE_OS_TRES,
  SECCION_CONYUGE_OS_CUATRO,
  SECCION_DOMICILIO_COMERCIAL,
  SECCION_ECONOMICOS,
  SECCION_OS_DOS,
  SECCION_OS_TRES,
  SECCION_OS_CUATRO,
  SECCION_OS_CINCO,
  OS_MAX_PFAE,
  SECCION_SOLICITANTE_CONYUGE,
} from "../../../../utils/constantes";
import {
  DisabledButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../basics/Buttons/Buttons";

const FormMenuPFAE = ({
  tipoPersona,
  subMenuSelected,
  seccion,
  setSeccion,
  cantidadObligadosSolidarios,
  setSubMenuSelected,
  datosOSUno,
  datosOSDos,
  datosOSTres,
  datosOSCuatro,
  cantidadConyugeOS,
  eliminarOSPfae,
  setCantidadObligadosSolidarios,
  conyugeOS,
  mostrarSeccionPfae,
  disabledBtnAgregarOs,
  setDisabledBtnAgregarOs,
}) => {
  return (
    <>
      {tipoPersona === "PFAE" && subMenuSelected === "generales" && (
        <>
          <span
            className={`${
              seccion === SECCION_GENERALES &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => setSeccion(SECCION_GENERALES)}
          >
            General
          </span>
          <span
            className={`${
              seccion === SECCION_DOMICILIO &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPfae.domicilio) {
                setSeccion(SECCION_DOMICILIO);
              }
            }}
          >
            Domicilio
          </span>

          <span
            className={`${
              seccion === SECCION_DOMICILIO_FISCAL &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPfae.domicilioFiscal) {
                setSeccion(SECCION_DOMICILIO_FISCAL);
              }
            }}
          >
            Domicilio fiscal
          </span>

          <span
            className={`${
              seccion === SECCION_DOMICILIO_COMERCIAL &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPfae.domicilioComercial) {
                setSeccion(SECCION_DOMICILIO_COMERCIAL);
              }
            }}
          >
            Domicilio comercial
          </span>

          <span
            className={`${
              seccion === SECCION_ECONOMICOS &&
              "radius bold tab__selected text__red"
            } pointer tab`}
            onClick={() => {
              if (mostrarSeccionPfae.economicos) {
                setSeccion(SECCION_ECONOMICOS);
              }
            }}
          >
            Clave CIEC
          </span>

          {conyugeOS === false && mostrarSeccionPfae.solicitanteConyuge ? (
            <span
              className={`${
                seccion === SECCION_SOLICITANTE_CONYUGE &&
                "radius bold tab__selected text__red"
              } pointer tab`}
              onClick={() => {
                setSeccion(SECCION_SOLICITANTE_CONYUGE);
              }}
            >
              Cónyuge del solicitante
            </span>
          ) : null}
        </>
      )}

      {tipoPersona === "PFAE" && subMenuSelected === "oSolidario" && (
        <>
          {conyugeOS && mostrarSeccionPfae.solicitanteConyuge ? (
            <span
              className={`${
                seccion === SECCION_SOLICITANTE_CONYUGE &&
                "radius bold tab__selected text__red"
              } pointer tab`}
              onClick={() => {
                setSeccion(SECCION_SOLICITANTE_CONYUGE);
                setSubMenuSelected("oSolidario");
              }}
            >
              Cónyuge del solicitante
            </span>
          ) : null}

          {cantidadObligadosSolidarios === 1 && (
            <>
              <span
                className={`${
                  seccion === SECCION_OBLIGADO_SOLIDARIO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => {
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                  setSubMenuSelected("oSolidario");
                }}
              >
                Obligado solidario 1
              </span>
              {datosOSUno.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              (datosOSUno?.conyuge?.idConyuge !== null || datosOSUno?.showConyugeOS === true) && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_UNO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_UNO)}
                  >
                    Obligado solidario 1 cónyuge
                  </span>
                )}
            </>
          )}
          {cantidadObligadosSolidarios === 2 && (
            <>
              <span
                className={`${
                  seccion === SECCION_OBLIGADO_SOLIDARIO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => {
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                  setSubMenuSelected("oSolidario");
                }}
              >
                {/* todo */}
                Obligado solidario 1
              </span>
              {datosOSUno.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSUno?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_UNO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_UNO)}
                  >
                    Obligado solidario 1 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_DOS &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_DOS)}
              >
                Obligado solidario 2
              </span>
              {datosOSDos.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              (datosOSDos?.conyuge?.idConyuge !== null || datosOSDos?.showConyugeOS === true) && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_DOS &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_DOS)}
                  >
                    Obligado solidario 2 cónyuge
                  </span>
                )}
            </>
          )}
          {cantidadObligadosSolidarios === 3 && (
            <>
              <span
                className={`${
                  seccion === SECCION_OBLIGADO_SOLIDARIO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => {
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                  setSubMenuSelected("oSolidario");
                }}
              >
                Obligado solidario 1
              </span>
              {datosOSUno.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSUno?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_UNO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_UNO)}
                  >
                    Obligado solidario 1 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_DOS &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_DOS)}
              >
                Obligado solidario 2
              </span>
              {datosOSDos.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSDos?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_DOS &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_DOS)}
                  >
                    Obligado solidario 2 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_TRES &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_TRES)}
              >
                Obligado solidario 3
              </span>
              {datosOSTres.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              (datosOSTres?.conyuge?.idConyuge !== null || datosOSTres?.showConyugeOS === true) && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_TRES &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_TRES)}
                  >
                    Obligado solidario 3 cónyuge
                  </span>
                )}
            </>
          )}
          {cantidadObligadosSolidarios === 4 && (
            <>
              <span
                className={`${
                  seccion === SECCION_OBLIGADO_SOLIDARIO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => {
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                  setSubMenuSelected("oSolidario");
                }}
              >
                Obligado solidario 1
              </span>
              {datosOSUno.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSUno?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_UNO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_UNO)}
                  >
                    Obligado solidario 1 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_DOS &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_DOS)}
              >
                Obligado solidario 2
              </span>
              {datosOSDos.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSDos?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_DOS &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_DOS)}
                  >
                    Obligado solidario 2 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_TRES &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_TRES)}
              >
                Obligado solidario 3
              </span>
              {datosOSTres.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSTres?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_TRES &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_TRES)}
                  >
                    Obligado solidario 3 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_CUATRO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_CUATRO)}
              >
                Obligado solidario 4
              </span>
              {datosOSCuatro.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              (datosOSCuatro?.conyuge?.idConyuge !== null || datosOSTres?.showConyugeOS === true) && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_CUATRO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_CUATRO)}
                  >
                    Obligado solidario 4 cónyuge
                  </span>
                )}
            </>
          )}
          {cantidadObligadosSolidarios === 5 && (
            <>
              <span
                className={`${
                  seccion === SECCION_OBLIGADO_SOLIDARIO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => {
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                  setSubMenuSelected("oSolidario");
                }}
              >
                Obligado solidario 1
              </span>
              {datosOSUno.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSUno?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_UNO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_UNO)}
                  >
                    Obligado solidario 1 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_DOS &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_DOS)}
              >
                Obligado solidario 2
              </span>
              {datosOSDos.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSDos?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_DOS &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_DOS)}
                  >
                    Obligado solidario 2 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_TRES &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_TRES)}
              >
                Obligado solidario 3
              </span>
              {datosOSTres.idEstadoCivilOs === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSTres?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_TRES &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_TRES)}
                  >
                    Obligado solidario 3 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_CUATRO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_CUATRO)}
              >
                Obligado solidario 4
              </span>
              {datosOSCuatro.idEstadoCivilOs ===
                ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
                datosOSCuatro?.conyuge?.idConyuge !== null && (
                  <span
                    className={`${
                      seccion === SECCION_CONYUGE_OS_CUATRO &&
                      "radius bold tab__selected text__red"
                    } pointer tab`}
                    onClick={() => setSeccion(SECCION_CONYUGE_OS_CUATRO)}
                  >
                    Obligado solidario 4 cónyuge
                  </span>
                )}
              <span
                className={`${
                  seccion === SECCION_OS_CINCO &&
                  "radius bold tab__selected text__red"
                } pointer tab`}
                onClick={() => setSeccion(SECCION_OS_CINCO)}
              >
                Obligado solidario 5
              </span>
            </>
          )}
          {cantidadObligadosSolidarios + cantidadConyugeOS > 1 && (
            <span
              className="position-relative pointer-btn tab"
              onClick={() => {
                eliminarOSPfae(cantidadObligadosSolidarios);
                setDisabledBtnAgregarOs(false);
              }}
            >
              Eliminar obligado solidario
            </span>
          )}

          {cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PFAE &&
            (!disabledBtnAgregarOs ? (
              <span
                className="text__red pointer-btn tab"
                onClick={(e) => {
                  e.preventDefault();
                  setCantidadObligadosSolidarios(
                    cantidadObligadosSolidarios + 1
                  );
                  setDisabledBtnAgregarOs(true);

                }}
              >
                Agregar obligado solidario
              </span>
            ) : (
              <span className="pointer tab">Agregar obligado solidario</span>
            ))}
        </>
      )}
    </>
  );
};

export default FormMenuPFAE;
