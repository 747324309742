import React, { Component } from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import ErrorMessage from "../../helpers/ErrorMessage/ErrorMessage";
import {
  selectStyles,
  selectStylesDouble,
} from "../../../styles/select-styles";
import "./SelectWithError.css";
import { isEmpty } from "../../../functions/fieldFunctions";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FixedSizeList as List } from "react-window";
import SearchIcon from "@mui/icons-material/Search";

const SelectWithErrorActividad = ({
  label,
  placeholder = "",
  name,
  value,
  onChange = () => {},
  onBlur = () => {},
  options,
  isDisabled = false,
  menuPlacement = "bottom",
  showErrorMessage = false,
  errorMessage = "",
  isDouble = false,
  width = "425px",
  isResumenCrediticio = false,
  tooltip = "",
}) => {
  const height = 35;

  const DropdownIndicator = (props: DropdownIndicatorProps<>) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    );
  };

  class MenuList extends Component {
    render() {
      const { options, children, maxHeight, getValue } = this.props;
      const [value] = getValue();
      const initialOffset = options.indexOf(value) * height;

      return (
        <List
          height={maxHeight}
          itemCount={children.length}
          itemSize={height}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
      );
    }
  }

  return (
    <div className="select__container" style={{ width }}>
      <div className="d-flex gap-2 w-100 align-items-center">
        <div
          className={
            isResumenCrediticio
              ? "selectInputContTemporalResumenCreiditicio"
              : "selectInputContTemporal"
          }
        >
          {value?.value ? (
            <span className="select-label-no-null">{label} </span>
          ) : (
            <span className="select-label">{label}</span>
          )}
          <Select
            isSearchable={true}
            components={{ DropdownIndicator }}
            placeholder={placeholder}
            styles={isDouble ? selectStylesDouble : selectStyles}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            isDisabled={isDisabled}
            menuPlacement={menuPlacement}
            error={showErrorMessage}
          />
        </div>
        {!isEmpty(tooltip) && (
          <div className="input__tooltip top">
            <AiOutlineQuestionCircle className="input__tooltip" />
            <span className="tiptext">{tooltip}</span>
          </div>
        )}
      </div>
      {showErrorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default SelectWithErrorActividad;
