import React, { useEffect, useState } from "react";
import Dot from "../Dot/Dot";
import Swal from "sweetalert2";
import "./ProgressBar.css";
import {
  SECCION_CARGA_DOCUMENTOS,
  SECCION_HISTORIAL_CREDITICIO,
  SECCION_GENERALES,
  SECCION_PRINCIPAL_ACCIONISTA,
  ID_PFAE,
  ID_PM
} from "../../../utils/constantes";

const StepProgressBar = ({
  subMenuSelected,
  setSubMenuSelected,
  setFormSection,
  setSeccion,
  ultimoPasoUsuario,
  conyugeOS,
  idTipoPersona,
  actualizarPA
}) => {
  const [enabledSteps, setEnabledSteps] = useState(1);

  useEffect(() => {   
    validarValorEnabledSteps();
  }, [ultimoPasoUsuario]);

  const validarValorEnabledSteps = () => {
    if (idTipoPersona === ID_PFAE) {
      if (ultimoPasoUsuario === SECCION_CARGA_DOCUMENTOS) {
        setEnabledSteps(3);      
      } else{
        if (ultimoPasoUsuario === SECCION_HISTORIAL_CREDITICIO) {
          setEnabledSteps(2);      
        }
      }
    }else{
      if (ultimoPasoUsuario === SECCION_CARGA_DOCUMENTOS) {
        setEnabledSteps(4);      
      } else{
        if (ultimoPasoUsuario === SECCION_HISTORIAL_CREDITICIO) {
          setEnabledSteps(3);      
        }else{
          if (ultimoPasoUsuario === SECCION_PRINCIPAL_ACCIONISTA) {
            setEnabledSteps(2)
          }
        }
      }
    }

  }

  return (
    <div className="navbar--progress-bar container navigation container-style">
      {idTipoPersona === ID_PM  && (
        <div className="stepper-wrapper">
          <>
            <div className="stepper-item">
              <div className="step-counter">
                <Dot
                  onClick={() => {
                    setSubMenuSelected("generales");
                    setFormSection("Generales");
                    setSeccion(SECCION_GENERALES);
                  }}
                  content={1}
                  active={subMenuSelected === "generales" ? true : false}
                  hasBeenActivated={enabledSteps >= 1 ? true : false}
                />
              </div>
            </div>

            <div className="stepper-item active">
              <div className="step-counter">
                <Dot
                  onClick={() => {
                    setSubMenuSelected("oSolidario");
                    setFormSection("Obligados Solidarios");
                    setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
                  }}
                  content={2}
                  active={subMenuSelected === "oSolidario" ? true : false}
                  hasBeenActivated={enabledSteps >= 2 ? true : false}
                />
              </div>
            </div>

            <div className="stepper-item">
              <div className="step-counter">
                <Dot
                  onClick={() => {
                    if(actualizarPA){
                      Swal.fire({
                        icon: "success",
                        title:
                          "Se ha modificado el nombre del representante legal, deberás revisar la información del obligado solidario.",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: "#dc3545"
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setSubMenuSelected("oSolidario");
                          setFormSection("Obligados Solidarios");
                          setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
                        } 
                      });                      
                    }else{
                      setSubMenuSelected("historialcrediticio");
                      setSeccion(SECCION_HISTORIAL_CREDITICIO);
                      setFormSection("Historial Crediticio");
                    }                    
                  }}
                  content={3}
                  active={
                    subMenuSelected === "historialcrediticio" ? true : false
                  }
                  hasBeenActivated={enabledSteps >= 3 ? true : false}
                />
              </div>
            </div>

            <div className="stepper-item">
              <div className="step-counter">
                <Dot
                  onClick={() => {
                    if(actualizarPA){
                      Swal.fire({
                        icon: "success",
                        title:
                          "Se ha modificado el nombre del representante legal, deberás revisar la información del obligado solidario.",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: "#dc3545"
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setSubMenuSelected("oSolidario");
                          setFormSection("Obligados Solidarios");
                          setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
                        } 
                      });                      
                    }else{
                      setSubMenuSelected("cargadocumentos");
                      setSeccion(SECCION_CARGA_DOCUMENTOS);
                      setFormSection("Carga de documentos");
                    }                    
                  }}
                  content={4}
                  active={
                    subMenuSelected === "cargadocumentos" ? true : false
                  }
                  hasBeenActivated={enabledSteps >= 4 ? true : false}
                />
              </div>
            </div>
          </>
        </div>
      )}
      {idTipoPersona === ID_PFAE && (
        <div className="stepper-wrapper">
          <>
            <div className="stepper-item">
              <div className="step-counter">
                <Dot
                  onClick={() => {
                    setSubMenuSelected("generales");
                    setFormSection("Generales");
                    setSeccion(SECCION_GENERALES);
                  }}
                  content={1}
                  active={subMenuSelected === "generales" ? true : false}
                  hasBeenActivated={enabledSteps >= 1 ? true : false}
                />
              </div>
            </div>

            <div className="stepper-item">
              <div className="step-counter">
                <Dot
                  onClick={() => {
                    setSubMenuSelected("historialcrediticio");
                    setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    setFormSection("Historial Crediticio");
                  }}
                  content={2}
                  active={
                    subMenuSelected === "historialcrediticio" ? true : false
                  }
                  hasBeenActivated={enabledSteps >= 2 ? true : false}
                />
              </div>
            </div>

            <div className="stepper-item">
              <div className="step-counter">
                <Dot
                  onClick={() => {
                    setSubMenuSelected("cargadocumentos");
                    setSeccion(SECCION_CARGA_DOCUMENTOS);
                    setFormSection("Carga de documentos");
                  }}
                  content={3}
                  active={
                    subMenuSelected === "cargadocumentos" ? true : false
                  }
                  hasBeenActivated={enabledSteps >= 3 ? true : false}
                />
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default StepProgressBar;
