import React, { useState, useEffect } from "react";
import { SECCION_CARGA_DOCUMENTOS_SOLICITANTE } from "../../../../../utils/constantes";
import CargarDocumentoSolicitantePFAE from "../../PFAE/CargaDocumentos/CargarDocumentoSolicitantePFAE";

const CargaDocumentosPFAE = ({
  modeloPfae,
  setLoading,
  handleRegresarSeccionPyme,
}) => {
  const [selectedCargaDocumentos, setSelectedCargaDocumentos] = useState(1);

  useEffect(() => {
    var p = document.getElementById("accionCrediactivo");
    p.innerHTML = "Carga de documentos";
  }, []);

  return (
    <>
      <div className="container navigation div-menu-participantes-historial navigation-select">
        <>
          <p
            className={`${
              selectedCargaDocumentos === SECCION_CARGA_DOCUMENTOS_SOLICITANTE
                ? "bold tab__selected text__red pointer tab"
                : null
            } pointer p-2 mb-0`}
            onClick={() => {
              setSelectedCargaDocumentos(SECCION_CARGA_DOCUMENTOS_SOLICITANTE);
            }}
          >
            Solicitante
          </p>
        </>
      </div>
      {selectedCargaDocumentos === SECCION_CARGA_DOCUMENTOS_SOLICITANTE && (
        <CargarDocumentoSolicitantePFAE
          setLoading={setLoading}
          modeloDatos={modeloPfae}
          handleRegresarSeccionPyme={handleRegresarSeccionPyme}
        />
      )}
    </>
  );
};
export default CargaDocumentosPFAE;
