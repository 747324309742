import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InformacionEmpresa from "../home/homeSections/registro/InformacionEmpresa";
import InformacionGeneral from "../home/homeSections/registro/InformacionGeneral";
import ValidacionDatos from "../home/homeSections/registro/ValidacionDatos";
import OfertaCampanias from "../home/OfertasCampanias/ofertaCampanias";
import { useFetchSelectData } from "../home/homeSections/hooks/useFetchSelectData";
import { isEmpty } from "../../functions/fieldFunctions";
import Popup from "../tools/Popup";
import {
  OFERTA_PERSONALIZADA_1,
  OFERTA_PERSONALIZADA_2,
  OFERTA_PERSONALIZADA_3,
  OFERTA_PERSONALIZADA_4,
  OFERTA_PERSONALIZADA_5,
} from "../../utils/constantes";

import { sessionStorageDatosOperacion } from "../../config/Variables";

const Prospeccion = () => {
  //1. InformacionEmpresa, 2. InformacionGeneral, 3. ValidacionDatos
  const [idTipoPersona, setIdTipoPersona] = useState("");

  const [steps, setSteps] = useState(1);
  const [rfc, setRfc] = useState("");
  const [curp, setCurp] = useState("");
  const [actividadNegocio, setActividadNegocio] = useState("");
  const [email, setEmail] = useState("");
  const [genero, setGenero] = useState("");
  const [edadCliente, setEdadCliente] = useState("");
  const [ofertaCampanias, setOfertaCampanias] = useState([]);
  const [ofertaAceptada, setOfertaAceptada] = useState(false);
  const [idOfertaSeleccionada, setIdOfertaSeleccionada] = useState(0);
  const [fechaNacimientoStr, setFechaNacimientoStr] = useState(false);

  const { dataActividad, dataTipoMotivoCredito, dataAniosNegocio } =
    useFetchSelectData();

  // POPUP
  const [popupRfcOcupado, setPopupRfcOcupado] = useState(false);
  const [popupRfcData, setPopupRfcData] = useState({
    informacionDeSolicitudEnTramite: "",
  });

  // AXIOS VARIABLES
  const [loadingInicio, setLoadingInicio] = useState(false);

  // HTTP
  const [selectedOferta, setSelectedOferta] = useState(0);
  const [oferta, setOferta] = useState(0);

  const navigate = useNavigate();

  const handleChangeOferta = (side) => {
    // RIGHT
    if (side) {
      if (selectedOferta + 1 < Object.keys(ofertaCampanias).length) {
        switch (selectedOferta) {
          case 0:
            setOferta(OFERTA_PERSONALIZADA_2);
            break;
          case 1:
            setOferta(OFERTA_PERSONALIZADA_3);
            break;
          case 2:
            setOferta(OFERTA_PERSONALIZADA_4);
            break;
          case 3:
            setOferta(OFERTA_PERSONALIZADA_5);
            break;
          default:
            break;
        }
        setSelectedOferta(selectedOferta + 1);
      }
    } else {
      if (selectedOferta - 1 >= 0) {
        switch (selectedOferta) {
          case 1:
            setOferta(OFERTA_PERSONALIZADA_1);
            break;
          case 2:
            setOferta(OFERTA_PERSONALIZADA_2);
            break;
          case 3:
            setOferta(OFERTA_PERSONALIZADA_3);
            break;
          case 4:
            setOferta(OFERTA_PERSONALIZADA_4);
            break;
          case 5:
            setOferta(OFERTA_PERSONALIZADA_5);
            break;
          default:
            break;
        }
        setSelectedOferta(selectedOferta - 1);
      } else if (selectedOferta - 1 === 0) {
        setOferta(OFERTA_PERSONALIZADA_1);
      }
    }
  };

  useEffect(() => {
    if (oferta === OFERTA_PERSONALIZADA_2) {
      setOferta(OFERTA_PERSONALIZADA_2);
    } else if (oferta === OFERTA_PERSONALIZADA_3) {
      setOferta(OFERTA_PERSONALIZADA_3);
    } else if (oferta === OFERTA_PERSONALIZADA_4) {
      setOferta(OFERTA_PERSONALIZADA_4);
    } else if (oferta === OFERTA_PERSONALIZADA_5) {
      setOferta(OFERTA_PERSONALIZADA_5);
    } else {
      setOferta(OFERTA_PERSONALIZADA_1);
    }
  }, [oferta]);

  useEffect(() => {
    const idDatosOperacion = sessionStorage.getItem(
      sessionStorageDatosOperacion
    );
    if (idDatosOperacion === null || idDatosOperacion === undefined) {
      navigate("/cotizador");
    }

    document.getElementById("root").scrollIntoView(true);
  }, []);

  return (
    <>
      {steps === 1 && (
        <InformacionGeneral
          setSteps={setSteps}
          setOfertaCampanias={setOfertaCampanias}
          setLoadingInicio={setLoadingInicio}
          setRfc={setRfc}
          setCurp={setCurp}
          actividad={dataActividad}
          setActividadNegocio={setActividadNegocio}
          setPopupRfcOcupado={setPopupRfcOcupado}
          setPopupRfcData={setPopupRfcData}
          popupRfcOcupado={popupRfcOcupado}
          popupRfcData={popupRfcData}
          setIdTipoPersona={setIdTipoPersona}
          setEdadCliente={setEdadCliente}
          setGenero={setGenero}
          setFechaNacimientoStr={setFechaNacimientoStr}
          setEmail={setEmail}
        />
      )}
      {steps === 2 && (
        <InformacionEmpresa
          setSteps={setSteps}
          rfc={rfc}
          curp={curp}
          actividadNegocio={actividadNegocio}
          setActividadNegocio={setActividadNegocio}
          actividad={dataActividad}
          tipoMotivoCredito={dataTipoMotivoCredito}
          aniosNegocio={dataAniosNegocio}
          genero={genero}
          edadCliente={edadCliente}
          setEdadCliente={setEdadCliente}
          fechaNacimientoStr={fechaNacimientoStr}
          idTipoPersona={idTipoPersona}
          setLoadingInicio={setLoadingInicio}
        />
      )}
      {steps === 3 && (
        <ValidacionDatos
          setLoadingInicio={setLoadingInicio}
          isEmpty={isEmpty}
          setEmail={setEmail}
        />
      )}
      {steps === 4 && (
        <OfertaCampanias
          handleChangeOferta={handleChangeOferta}
          oferta={oferta}
          selectedOferta={selectedOferta}
          setSteps={setSteps}
          ofertaCampanias={ofertaCampanias}
          steps={steps}
          setOfertaAceptada={setOfertaAceptada}
          ofertaAceptada={ofertaAceptada}
          setIdOfertaSeleccionada={setIdOfertaSeleccionada}
        />
      )}

      <Popup show={loadingInicio}>
        <div
          className="bgc-gray-opacity"
          style={{
            height: "100vh",
            width: "100%",
            display: "grid",
            placeContent: "center",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <div
          style={{
            top: "0",
            position: "absolute",
            height: "100vh",
            width: "100%",
            zIndex: "1",
          }}
        />
      </Popup>
    </>
  );
};

export default Prospeccion;
