import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../basics/SelectWithError/SelectWithError";
import * as Yup from "yup";
import { ID_PFAE } from "../../../../utils/constantes";
import CheckBoxInput from "../../../basics/checkBox";
import {
  validateValue,
  textToUpperCase,
} from "../../../../functions/fieldFunctions";
import { pathAvisoPrivacidad } from "../../../../config/Path";
import {
  actualizarDatosTemporalesAutoServicioEmpresa,
  initialValuesEmp,
  validationSchemaEmp,
} from "./helpers/informacionEmpresaHelper";

const InformacionEmpresa = ({
  setSteps,
  rfc,
  curp,
  actividadNegocio,
  setActividadNegocio,
  actividad,
  aniosNegocio,
  genero,
  edadCliente,
  fechaNacimientoStr,
  idTipoPersona,
  setLoadingInicio,
}) => {
  const [disabledCurp, setDisabledCurp] = useState(false);
  const [options, setOptions] = useState([]);

  const formikEmp = useFormik({
    initialValues: initialValuesEmp(idTipoPersona, curp, rfc),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchemaEmp(Yup)),
    onSubmit: (formData) => {
      actualizarDatosTemporalesAutoServicioEmpresa(
        formData,
        setSteps,
        setLoadingInicio
      );
    },
  });

  const obtenerDatosByCurp = (curp) => {
    if (curp.length === 18) {
      let m = curp.match(/^\w{4}(\w{2})(\w{2})(\w{2})/);
      let anyoCliente = parseInt(m[1], 10) + 1900;
      if (anyoCliente < 1950) anyoCliente += 100;
      let mesCliente = parseInt(m[2], 10) - 1;
      let diaCliente = parseInt(m[3], 10);
      let cumpleanos = new Date(anyoCliente, mesCliente, diaCliente);
      let hoy = new Date();
      let edad = hoy.getFullYear() - cumpleanos.getFullYear();
      let mEdad = hoy.getMonth() - cumpleanos.getMonth();
      let generoCliente = curp[10] === "H" ? 1 : 2;
      mesCliente = mesCliente + 1;
      if (mEdad < 0 || (mEdad === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }

      formikEmp.setFieldValue("idGenero", generoCliente);
      formikEmp.setFieldValue(
        "fechaNacimientoStr",
        diaCliente + "-" + mesCliente + "-" + anyoCliente
      );
      formikEmp.setFieldValue("edad", edad);
    }
  };

  useEffect(() => {
    const saveOptions = () => {
      const optionsActividad = actividad.map((act) => {
        return {
          ...act,
          value: act.id,
          label: act.detalleClave,
        };
      });

      setOptions(optionsActividad);
    };

    saveOptions();
  }, []);

  useEffect(() => {
    if (actividadNegocio) {
      formikEmp.setFieldValue("idActividad", actividadNegocio.id);
    }

    if (curp !== undefined && curp !== null && curp.trim().length > 0) {
      formikEmp.setFieldValue("curp", curp);
      formikEmp.setFieldValue("idGenero", genero);
      formikEmp.setFieldValue("fechaNacimientoStr", fechaNacimientoStr);
      formikEmp.setFieldValue("edad", edadCliente);
      setDisabledCurp(true);
    }
  }, []);

  return (
    <div
      className="bgc-white"
      style={{
        padding: "24px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
      }}
    >
      <form
        style={{
          width: "100%",
          display: "grid",
          placeItems: "center",
          gap: "1rem",
        }}
      >
        <h1 className="text-center" style={{ marginBottom: "24px" }}>
          Información de tu empresa
        </h1>
        {idTipoPersona === ID_PFAE && (
          <InputWithError
            value={formikEmp.values.curp}
            onChange={(e) => {
              let newErrors = formikEmp.errors;
              delete newErrors?.curp;
              delete newErrors?.rfc;
              formikEmp.setErrors(newErrors);

              if (validateValue(e.target.value, "alphanumeric")) {
                const newValue = textToUpperCase(e.target.value);
                formikEmp.setFieldValue("curp", newValue);
              }
            }}
            onBlur={(e) => {
              if (e.target.value.length === 18) {
                obtenerDatosByCurp(e.target.value);
              }
            }}
            InputProps={{ maxLength: 18 }}
            label="CURP *"
            showErrorMessage={formikEmp.errors.curp || formikEmp.errors.rfc}
            errorMessage={
              formikEmp.errors.curp !== undefined
                ? formikEmp.errors.curp
                : formikEmp.errors.rfc !== undefined
                ? formikEmp.errors.rfc
                : ""
            }
            disabled={disabledCurp}
          />
        )}

        <SelectWithError
          label="Actividad de tu negocio *"
          value={actividadNegocio}
          onChange={(e) => {
            let newErrors = formikEmp.errors;
            delete newErrors?.idActividad;
            formikEmp.setErrors(newErrors);

            formikEmp.setFieldValue("idActividad", e.id);
            setActividadNegocio(e);
          }}
          options={options}
          showErrorMessage={formikEmp.errors.idActividad}
          errorMessage={formikEmp.errors.idActividad}
        />
        <SelectWithError
          label="Años de tu negocio *"
          value={{
            label: formikEmp?.values?.anioNegocioObj?.nombre,
            value: formikEmp?.values?.anioNegocioObj?.nombre,
          }}
          onChange={(e) => {
            let newErrors = formikEmp.errors;
            delete newErrors?.idAnioNegocio;
            formikEmp.setErrors(newErrors);

            formikEmp.setFieldValue("idAnioNegocio", e.id);
            formikEmp.setFieldValue("anioNegocioObj", e);
          }}
          options={aniosNegocio.map((e) => {
            return {
              ...e,
              value: e.id,
              label: e.nombre,
            };
          })}
          showErrorMessage={formikEmp.errors.idAnioNegocio}
          errorMessage={formikEmp.errors.idAnioNegocio}
        />
        <div
          style={{
            width: "100%",
            margin: "0rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckBoxInput
            checked={formikEmp.values?.privacyCheck}
            onChange={(e) => {
              formikEmp.setFieldValue("privacyCheck", e.target.checked);
            }}
            required={true}
            typeOfCheck="normal"
          />
          <span>Acepto el&nbsp;</span>
          <Link to={pathAvisoPrivacidad} target="_blank" download>
            <span style={{ color: "#EB0029" }}>aviso de privacidad</span>
          </Link>
        </div>
        <div
          style={{
            width: "100%",
            margin: "-1rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="text__center text_red">
            {formikEmp.errors?.privacyCheck}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            margin: "2rem 0",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            className="button-primary"
            onClick={(e) => {
              e.preventDefault();
              formikEmp.handleSubmit();
            }}
          >
            Siguiente
          </button>
        </div>
      </form>
    </div>
  );
};

export default InformacionEmpresa;
