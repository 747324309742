import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatosPatrimonioPFAE from "./DatosPatrimonioPFAE";
import CaptacionPFAE from "./CaptacionPFAE";
import { api_save_patrimonio_principal_pfae } from "../../../../../api/api_servicios_rest";
import { SECCION_PATRIMONIO } from "../../../../../utils/constantes";
import { validateValue } from "../../../../../functions/fieldFunctions";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import { sweetAlertError } from "../../alertas/alertas";

const DatosPatrimonioPrincipalPFAE = ({
  modeloPfae,
  handleSeccionPyme,
  axiosData,
  setModeloPfae,
  setStyleHr,
  setLoading,
}) => {
  const [patrimonios, setPatrimonios] = useState({
    statePatrimonio1: true,
    statePatrimonio2: false,
    statePatrimonio3: false,
    statePatrimonio4: false,
    statePatrimonio5: false,
  });

  const actualizarMostrarPatrimonio = (valor) => {
    switch (valor) {
      case 1:
        patrimonios.statePatrimonio1 = true;
        patrimonios.statePatrimonio2 = false;
        patrimonios.statePatrimonio3 = false;
        patrimonios.statePatrimonio4 = false;
        patrimonios.statePatrimonio5 = false;
        break;
      case 2:
        patrimonios.statePatrimonio1 = true;
        patrimonios.statePatrimonio2 = true;
        patrimonios.statePatrimonio3 = false;
        patrimonios.statePatrimonio4 = false;
        patrimonios.statePatrimonio5 = false;
        break;
      case 3:
        patrimonios.statePatrimonio1 = true;
        patrimonios.statePatrimonio2 = true;
        patrimonios.statePatrimonio3 = true;
        patrimonios.statePatrimonio4 = false;
        patrimonios.statePatrimonio5 = false;
        break;
      case 4:
        patrimonios.statePatrimonio1 = true;
        patrimonios.statePatrimonio2 = true;
        patrimonios.statePatrimonio3 = true;
        patrimonios.statePatrimonio4 = true;
        patrimonios.statePatrimonio5 = false;
        break;
      case 5:
        patrimonios.statePatrimonio1 = true;
        patrimonios.statePatrimonio2 = true;
        patrimonios.statePatrimonio3 = true;
        patrimonios.statePatrimonio4 = true;
        patrimonios.statePatrimonio5 = true;
        break;
    }
    modeloPfae.patrimonioPrincipal.numeroBienes = valor;
  };

  const formik = useFormik({
    initialValues: initialValues(modeloPfae, axiosData),
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object(
      validationSchema(modeloPfae?.generales?.esClienteNuevo)
    ),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosPatrimonioPFAE(formData);
    },
  });

  const actualizarValorTotalManifiesto = (valor, numPatrimonio) => {
    let totaldelvalorestimado = 0;
    formik.setFieldValue(
      `patrimonioPrincipal.patrimonios.${numPatrimonio}.valorManifestado`,
      valor
    );

    let patrimonios = formik.values?.patrimonioPrincipal?.patrimonios;

    patrimonios.map((patrimonio) => {
      if (patrimonio.valorManifestado !== undefined) {
        let valorManifestado = Number(
          patrimonio.valorManifestado.toLocaleString().replaceAll(",", "")
        );
        totaldelvalorestimado = totaldelvalorestimado + valorManifestado;
      }
    });

    if (valor) {
      totaldelvalorestimado =
        totaldelvalorestimado +
        Number(valor.toLocaleString().replaceAll(",", ""));
    }

    formik.setFieldValue(
      "patrimonioPrincipal.valorManifestadoTotal",
      totaldelvalorestimado
    );
  };

  const salvarDatosPatrimonioPFAE = async (formData) => {
    try {
      const response = await api_save_patrimonio_principal_pfae(formData);
      if (response.statusText === "OK") {
        const data = response.data;
        if (data.success) {
          setModeloPfae({
            ...modeloPfae,
            idCliente: data.clienteId,
            patrimonioPrincipal: {
              ...modeloPfae.patrimonioPrincipal,
              idPatrimonioPrincipal: data.patrimonioPrincipalCliente.id,
              numeroBienes: data.patrimonioPrincipalCliente.numeroBienes,
              valorManifestadoTotalFloat:
                data.patrimonioPrincipalCliente.valorManifestadoTotal,
            },
            captacion: {
              ...modeloPfae.patrimonioPrincipal,
              idDatosCaptacion: data.datosCaptacionId,
            },
          });

          setStyleHr("block");
          handleSeccionPyme(SECCION_PATRIMONIO);
          formik.setFieldValue(
            "patrimonioPrincipal.idPatrimonioPrincipal",
            data.patrimonioPrincipalCliente.id
          );
        } else {
          sweetAlertError("Ocurrió un error al guardar datos patrimonio PFAE");
        }
      } else {
        sweetAlertError("Ocurrió un error al guardar datos patrimonio PFAE");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modeloPfae.patrimonioPrincipal.numeroBienes !== null) {
      actualizarMostrarPatrimonio(
        parseInt(modeloPfae.patrimonioPrincipal.numeroBienes)
      );
    }
  });

  return (
    <>
      <form name="form-patrimonio" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3">
            DATOS PATRIMONIO{" "}
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <SelectWithError
              label="Números de bienes a presentar *"
              name="patrimonioPrincipal.numeroBienes"
              value={{
                label: formik?.values?.patrimonioPrincipal?.numeroBienes,
                value: formik?.values?.patrimonioPrincipal?.numeroBienes,
              }}
              onChange={(e) => {
                formik.setFieldValue(
                  "patrimonioPrincipal.numeroBienes",
                  e.label
                );
                actualizarMostrarPatrimonio(parseInt(e.label));
              }}
              options={[
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 },
                { label: 5, value: 5 },
              ]}
            />
            <InputWithError
              name="patrimonioPrincipal.valorManifestadoTotal"
              label="Valor manifestado total"
              value={formik.values?.patrimonioPrincipal?.valorManifestadoTotal}
              onChange={(e) => {
                if (validateValue(e.target.value, "number")) {
                  formik.setFieldValue(
                    "patrimonioPrincipal.valorManifestadoTotal",
                    e.target.value
                  );
                }
              }}
              disabled={true}
            />

            {patrimonios.statePatrimonio1 && (
              <DatosPatrimonioPFAE
                numPatrimonio={0}
                formik={formik}
                actualizarValorTotalManifiesto={actualizarValorTotalManifiesto}
              />
            )}

            {patrimonios.statePatrimonio2 && (
              <DatosPatrimonioPFAE
                numPatrimonio={1}
                formik={formik}
                actualizarValorTotalManifiesto={actualizarValorTotalManifiesto}
              />
            )}

            {patrimonios.statePatrimonio3 && (
              <DatosPatrimonioPFAE
                numPatrimonio={2}
                formik={formik}
                actualizarValorTotalManifiesto={actualizarValorTotalManifiesto}
              />
            )}

            {patrimonios.statePatrimonio4 && (
              <DatosPatrimonioPFAE
                numPatrimonio={3}
                formik={formik}
                actualizarValorTotalManifiesto={actualizarValorTotalManifiesto}
              />
            )}

            {patrimonios.statePatrimonio4 && (
              <DatosPatrimonioPFAE
                numPatrimonio={4}
                formik={formik}
                actualizarValorTotalManifiesto={actualizarValorTotalManifiesto}
              />
            )}
          </div>
          {modeloPfae.generales.esClienteNuevo && (
            <>
              <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3">
                CAPTACIÓN
              </h2>
              <CaptacionPFAE formik={formik} axiosData={axiosData} />
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            margin: "2rem 0",
          }}
        >
          <button
            className="button-primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Siguiente
          </button>
        </div>
      </form>
    </>
  );
};

export default DatosPatrimonioPrincipalPFAE;

function initialValues(modeloPfae, axiosData) {
  if (
    modeloPfae.captacion.idDatosCaptacion != null &&
    modeloPfae.patrimonioPrincipal.idPatrimonioPrincipal != null
  ) {
    return {
      idCliente: modeloPfae.idCliente,
      patrimonioPrincipal: {
        idPatrimonioPrincipal:
          modeloPfae.patrimonioPrincipal.idPatrimonioPrincipal,
        numeroBienes: modeloPfae.patrimonioPrincipal.numeroBienes,
        numeroBienesInteger: modeloPfae.patrimonioPrincipal.numeroBienesInteger,
        valorManifestadoTotal:
          modeloPfae.patrimonioPrincipal.valorManifestadoTotal,
        valorManifestadoTotalFloat:
          modeloPfae.patrimonioPrincipal.valorManifestadoTotalFloat,
        patrimonios: modeloPfae.patrimonioPrincipal.patrimonios,
      },
      captacion: {
        idDatosCaptacion: modeloPfae.captacion.idDatosCaptacion,
        numeroDictamen: modeloPfae.captacion.numeroDictamen,
        nombreProducto: modeloPfae.captacion.nombreProducto,
        regimenCuentaId: modeloPfae.captacion.regimenCuentaId,
        nombreRegimenCuenta: modeloPfae.captacion.nombreRegimenCuenta,
        nombreApoderadoMancomunado:
          modeloPfae.captacion.nombreApoderadoMancomunado,
        nombreApoderadoMancomunado2:
          modeloPfae.captacion.nombreApoderadoMancomunado2,
        estatusFatcaCrs: modeloPfae.captacion.estatusFatcaCrs,
        cfdi: modeloPfae.captacion.cfdi,
        entregaCorrespondenciaId: modeloPfae.captacion.entregaCorrespondenciaId,
        nombreEntregaCorrespondencia:
          modeloPfae.captacion.nombreEntregaCorrespondencia,
        montoReferenciaInversion: modeloPfae.captacion.montoReferenciaInversion,
        envioRecepcionTransferenciasFondosSpid:
          modeloPfae.captacion.envioRecepcionTransferenciasFondosSpid,
        requiereChequera: modeloPfae.captacion.requiereChequera,
        requiereTarjetaDebito: modeloPfae.captacion.requiereTarjetaDebito,
        identificadorBancaElectronicaId:
          modeloPfae.captacion.identificadorBancaElectronicaId,
        nombreIdentificadorBanca: modeloPfae.captacion.nombreIdentificadorBanca,
        requiereServicioBancaElectronica:
          modeloPfae.captacion.requiereServicioBancaElectronica,
        bancaElectronicaId: modeloPfae.captacion.bancaElectronicaId,
        nombreBanca: modeloPfae.captacion.nombreBanca,
        plan: modeloPfae.captacion.plan,
        tipoCobro: modeloPfae.captacion.tipoCobro,
        cuentaCargo: modeloPfae.captacion.cuentaCargo,
        operacionMancomunadaBancaElectronica:
          modeloPfae.captacion.operacionMancomunadaBancaElectronica,
        operacionMancomunadaLibramientoCheques:
          modeloPfae.captacion.operacionMancomunadaLibramientoCheques,
      },
      datosObj: {
        regimenCuentaObj: modeloPfae?.captacion?.regimenCuentaId
          ? axiosData?.catalogosPfaeDatosCaptacion?.regimenCuentaOpciones?.filter(
              (e) => e.id === modeloPfae?.captacion?.regimenCuentaId
            )
          : "",
        entregaCorrespondenciaObj: modeloPfae?.captacion
          ?.entregaCorrespondenciaId
          ? axiosData?.catalogosPfaeDatosCaptacion?.entregaCorrespondenciaOpciones?.filter(
              (e) => e.id === modeloPfae?.captacion?.entregaCorrespondenciaId
            )
          : "",
        identificadorBancaElectronicaObj: modeloPfae?.captacion
          ?.identificadorBancaElectronicaId
          ? axiosData?.catalogosPfaeDatosCaptacion?.identificadorBancaElectronicaOpciones?.filter(
              (e) =>
                e.id === modeloPfae?.captacion?.identificadorBancaElectronicaId
            )
          : "",
        bancaElectronicaObj: modeloPfae?.captacion?.bancaElectronicaId
          ? axiosData?.catalogosPfaeDatosCaptacion?.bancaElectronicaOpciones?.filter(
              (e) => e.id === modeloPfae?.captacion?.bancaElectronicaId
            )
          : "",
      },
    };
  } else {
    if (
      modeloPfae.captacion.idDatosCaptacion == null &&
      modeloPfae.patrimonioPrincipal.idPatrimonioPrincipal == null
    ) {
      return {
        idCliente: modeloPfae.idCliente,
        patrimonioPrincipal: {
          idPatrimonioPrincipal:
            modeloPfae.patrimonioPrincipal.idPatrimonioPrincipal,
          patrimonios: [
            {
              numeroPatrimonio: 0,
              ubicacion: "",
              superficeMetrosCuadrados: "",
              descripcionInmueble: "",
              valorManifestado: "",
              valorPredial: "",
              datosRegistro: "",
            },
          ],
        },
        captacion: {
          idDatosCaptacion: modeloPfae.captacion.idDatosCaptacion,
          envioRecepcionTransferenciasFondosSpid: false,
          requiereChequera: false,
          requiereTarjetaDebito: false,
          requiereServicioBancaElectronica: false,
        },
      };
    } else {
      if (modeloPfae.captacion.idDatosCaptacion !== null) {
        return {
          idCliente: modeloPfae.idCliente,
          patrimonioPrincipal: {
            idPatrimonioPrincipal:
              modeloPfae.patrimonioPrincipal.idPatrimonioPrincipal,
            patrimonios: [
              {
                numeroPatrimonio: 0,
                ubicacion: "",
                superficeMetrosCuadrados: "",
                descripcionInmueble: "",
                valorManifestado: "",
                valorPredial: "",
                datosRegistro: "",
              },
            ],
          },
          captacion: {
            idDatosCaptacion: modeloPfae.captacion.idDatosCaptacion,
            numeroDictamen: modeloPfae.captacion.numeroDictamen,
            nombreProducto: modeloPfae.captacion.nombreProducto,
            regimenCuentaId: modeloPfae.captacion.regimenCuentaId,
            nombreRegimenCuenta: modeloPfae.captacion.nombreRegimenCuenta,
            nombreApoderadoMancomunado:
              modeloPfae.captacion.nombreApoderadoMancomunado,
            nombreApoderadoMancomunado2:
              modeloPfae.captacion.nombreApoderadoMancomunado2,
            estatusFatcaCrs: modeloPfae.captacion.estatusFatcaCrs,
            cfdi: modeloPfae.captacion.cfdi,
            entregaCorrespondenciaId:
              modeloPfae.captacion.entregaCorrespondenciaId,
            nombreEntregaCorrespondencia:
              modeloPfae.captacion.nombreEntregaCorrespondencia,
            montoReferenciaInversion:
              modeloPfae.captacion.montoReferenciaInversion,
            envioRecepcionTransferenciasFondosSpid:
              modeloPfae.captacion.envioRecepcionTransferenciasFondosSpid,
            requiereChequera: modeloPfae.captacion.requiereChequera,
            requiereTarjetaDebito: modeloPfae.captacion.requiereTarjetaDebito,
            identificadorBancaElectronicaId:
              modeloPfae.captacion.identificadorBancaElectronicaId,
            nombreIdentificadorBanca:
              modeloPfae.captacion.nombreIdentificadorBanca,
            requiereServicioBancaElectronica:
              modeloPfae.captacion.requiereServicioBancaElectronica,
            bancaElectronicaId: modeloPfae.captacion.bancaElectronicaId,
            nombreBanca: modeloPfae.captacion.nombreBanca,
            plan: modeloPfae.captacion.plan,
            tipoCobro: modeloPfae.captacion.tipoCobro,
            cuentaCargo: modeloPfae.captacion.cuentaCargo,
            operacionMancomunadaBancaElectronica:
              modeloPfae.captacion.operacionMancomunadaBancaElectronica,
            operacionMancomunadaLibramientoCheques:
              modeloPfae.captacion.operacionMancomunadaLibramientoCheques,
          },
        };
      }

      if (modeloPfae.patrimonioPrincipal.idPatrimonioPrincipal !== null) {
        return {
          idCliente: modeloPfae.idCliente,
          patrimonioPrincipal: {
            idPatrimonioPrincipal:
              modeloPfae.patrimonioPrincipal.idPatrimonioPrincipal,
            numeroBienes: modeloPfae.patrimonioPrincipal.numeroBienes,
            numeroBienesInteger:
              modeloPfae.patrimonioPrincipal.numeroBienesInteger,
            valorManifestadoTotal:
              modeloPfae.patrimonioPrincipal.valorManifestadoTotal,
            valorManifestadoTotalFloat:
              modeloPfae.patrimonioPrincipal.valorManifestadoTotalFloat,
            patrimonios: modeloPfae.patrimonioPrincipal.patrimonios,
          },
          captacion: {
            idDatosCaptacion: modeloPfae.captacion.idDatosCaptacion,
            envioRecepcionTransferenciasFondosSpid: false,
            requiereChequera: false,
            requiereTarjetaDebito: false,
            requiereServicioBancaElectronica: false,
          },
        };
      }
    }
  }
}

function validationSchema(esClienteNuevo) {
  if (esClienteNuevo) {
    return {
      patrimonioPrincipal: Yup.object().shape({
        numeroBienes: Yup.number().required("Dato requerido."),
        patrimonios: Yup.array().of(
          Yup.object().shape({
            numeroPatrimonio: Yup.number(),
            ubicacion: Yup.string().required("Dato requerido."),
            superficeMetrosCuadrados: Yup.string().required("Dato requerido."),
            descripcionInmueble: Yup.string().required("Dato requerido."),
            valorManifestado: Yup.string().required("Dato requerido."),
            valorPredial: Yup.string().required("Dato requerido."),
            datosRegistro: Yup.string().required("Dato requerido."),
          })
        ),
      }),
      captacion: Yup.object().shape({
        numeroDictamen: Yup.string(),
        nombreProducto: Yup.string().required("Dato requerido."),
        regimenCuentaId: Yup.string().required("Dato requerido."),
        nombreApoderadoMancomunado: Yup.string(),
        nombreApoderadoMancomunado2: Yup.string(),
        estatusFatcaCrs: Yup.string().required("Dato requerido."),
        cfdi: Yup.string().required("Dato requerido."),
        entregaCorrespondenciaId: Yup.string().required("Dato requerido."),
        montoReferenciaInversion: Yup.string().required("Dato requerido."),
        envioRecepcionTransferenciasFondosSpid:
          Yup.boolean().required("Dato requerido."),
        requiereChequera: Yup.boolean().required("Dato requerido."),
        requiereTarjetaDebito: Yup.boolean().required("Dato requerido."),
        identificadorBancaElectronicaId:
          Yup.string().required("Dato requerido."),
        requiereServicioBancaElectronica:
          Yup.boolean().required("Dato requerido."),
        bancaElectronicaId: Yup.string().required("Dato requerido."),
        plan: Yup.string().required("Dato requerido."),
        tipoCobro: Yup.string().required("Dato requerido."),
        cuentaCargo: Yup.string().required("Dato requerido."),
        operacionMancomunadaBancaElectronica:
          Yup.string().required("Dato requerido."),
        operacionMancomunadaLibramientoCheques:
          Yup.string().required("Dato requerido."),
      }),
    };
  } else {
    return {
      patrimonioPrincipal: Yup.object().shape({
        numeroBienes: Yup.number().required("Dato requerido."),
        patrimonios: Yup.array().of(
          Yup.object().shape({
            numeroPatrimonio: Yup.number(),
            ubicacion: Yup.string().required("Dato requerido."),
            superficeMetrosCuadrados: Yup.string().required("Dato requerido."),
            descripcionInmueble: Yup.string().required("Dato requerido."),
            valorManifestado: Yup.string().required("Dato requerido."),
            valorPredial: Yup.string().required("Dato requerido."),
            datosRegistro: Yup.string().required("Dato requerido."),
          })
        ),
      }),
    };
  }
}
