import { api_obtener_documentos_cargados } from "../../../../../../api/api_servicios_rest";
import { MENSAJE_ERROR_TOKEN_INVALIDO } from "../../../../../../utils/constantes";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../../../helpers/alertas/alertas";

export const obtenerDocumentosCargados = async (
  datosCliente,
  setLoading,
  setMostrarDrop,
  formik
) => {
  setLoading(true);
  try {
    const response = await api_obtener_documentos_cargados(datosCliente);
    if (response?.data?.success) {
      const arrayDocumentos = response?.data?.documentos;
      arrayDocumentos.map((doc, index) =>
        formik.setFieldValue(`documents.${doc.tipoDeDocumento}`, [
          {
            name: doc.nombreDocumento,
            valido: doc.valido,
          },
        ])
      );
      setMostrarDrop(true);
    } else if (response?.data?.success === false) {
      if (MENSAJE_ERROR_TOKEN_INVALIDO === response?.data?.mensaje) {
        sweetAlertWithConfirmed(response?.data?.mensaje);
      } else {
        sweetAlertError(response?.data?.mensaje);
      }
    }
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
